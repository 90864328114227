import React, { useRef, useState, useEffect } from 'react';
import ReactToPrint from 'react-to-print';
// import { BASE_URL_PREBID } from '../../config/axios';
import axios from 'axios';
import { BASE_URL } from '../config/axios';

const PrintPage = React.forwardRef((props, ref) => {
  const [invoiceData, setInvoiceData] = useState({})
  const projectData = [
    {
      name: "Max",
      food: 100.0,
      fill: "#fff",
    },
  ]

  const getInvoiceData = async () => {
    try {
      const res = await axios.get(`${BASE_URL}/wfm/incrementbyemployee/`);
      setInvoiceData(res.data)
    } catch (err) {
    }
  };

  useEffect(() => {
    getInvoiceData();

  }, []);


  return (
    <div className='printpage' ref={ref}>
      <div className='printpage'>
        <div className='printpage-header'>
          <div className='printpage-header-left'>
            <img src="https://spacecreator-aimantra.s3.ap-south-1.amazonaws.com/Logos/Cipl+Logo++(1).png" alt='logo' className='printpage-logo' />

            <div className='printpage-header-left-text'>
              TAX INVOICE
            </div>
          </div>
          <div className='printpage-header-right'>
            <div className='printpage-header-right-top'>
              Civilmantra infracon private limited
            </div>
            <div className='printpage-header-right-bottom'>
              3rd Floor, Tower 3A,<br /> DLF Corporate Greens, Sector 74A,<br /> Gurugram, Haryana 122004 <br /> services@civilmantra.com
            </div>
          </div>

        </div>
        <div className='printpage-hr'></div>
        <div className='printpage-header-second slip-column-2heads'>


          <div className='printpage-heading-black'>
            Recipient Detail
          </div>
          <div className=' printpage-heading-black'>
            Invoice Detail
          </div>
        </div>
        <div className='printpage-header-second'>


          <table className='salary-table'>
            <tbody>
              {/* <br /> */}

              <tr>
                <td className='printpage-heading-black'>Name</td>
                <td className='printpage-heading-black1 printpage-heading-black1'>PRA India Private Limited</td>
              </tr>
              <tr>
                <td className='printpage-heading-black'>Address</td>
                <td className='printpage-heading-black1 printpage-heading-black1'>Shri Ayush Aggrawal, Front of sub station purena, Telibandha, Raipur, Chhatisgarh - 492001</td>
              </tr>
              <tr>
                <td className='printpage-heading-black'>WO/PO</td>
                <td className='printpage-heading-black1 printpage-heading-black1 '></td>
              </tr>
              <tr>
                <td className='printpage-heading-black'>POS</td>
                <td className='printpage-heading-black1 printpage-heading-black1'>Chhattisgarh</td>
              </tr>
              <tr>
                <td className='printpage-heading-black'>GSTIN</td>
                <td className='printpage-heading-black1 printpage-heading-black1'>22AAKCP4877D1AC</td>
              </tr>


              <br />

              {/* <tr>
                    <td colSpan={65} className='printpage-horizontal-division'></td>
                  </tr> */}
              {/* <br /> */}

            </tbody>
          </table>

          <div className='printpage-vertical-division2'></div>
          {/* <div className='printpage-vertical-division'></div> */}


          <table className='salary-table'>
            <tbody>
              {/* <br /> */}

              <tr>
                <td className='printpage-heading-black'>Invoice no.</td>
                <td className='printpage-heading-black1'>CIPL/23-24/11778</td>
              </tr>
              <tr>
                <td className='printpage-heading-black'>Invoice Date</td>
                <td className='printpage-heading-black1'>5 March,2024</td>
              </tr>
              <tr>
                <td className='printpage-heading-black'>CIPL Project no.</td>
                <td className='printpage-heading-black1'>PRI 1983</td>
              </tr>
              <tr>
                <td className='printpage-heading-black'>Running Invoice</td>
                <td className='printpage-heading-black1'>1</td>
              </tr>
              <tr>
                <td className='printpage-heading-black'>PAN</td>
                <td className='printpage-heading-black1'>AAHCC0747L</td>
              </tr>
              <tr>
                <td className='printpage-heading-black'>Account No.</td>
                <td className='printpage-heading-black1'>50200026004051</td>
              </tr>
              <tr>
                <td className='printpage-heading-black'>IFSC</td>
                <td className='printpage-heading-black1'>HDFC0009090</td>
              </tr>
              <tr>
                <td className='printpage-heading-black'>GSTIN</td>
                <td className='printpage-heading-black1'>06AAHCC0747L1ZK</td>
              </tr>
              <tr>
                <td className='printpage-heading-black'>PAN</td>
                <td className='printpage-heading-black1'>22AAKCP4877D1AC</td>
              </tr>
            </tbody>
          </table>

        </div>
        <div className='printpage-hr'></div>
        {/* <br /> */}
        <tr className="printpage-division  ">
          <td ><th>Name of Work:</th>
            Const sdlfghhz kls hjfh dshdkohd flsdjfklhdsksf hlk hflaks akshfk
            <th>- Pre Tender Engineering Services</th></td>
        </tr>



        <div className='printpage-body'>
          <table className='salary-table'>
            <thead>
              <tr className='expenseslip-head'>
                <th className="expense-table-td expenseslip-head-data expenseslip-row-data1">Sn</th>
                <th className="expense-table-td expenseslip-row-data2">Project particulars</th>
                <th className="expense-table-td expenseslip-row-data3">Work Order</th>
                <th className="expense-table-td expenseslip-row-data4">Rate %</th>
                <th className="expense-table-td expenseslip-row-data4">Amount</th>

              </tr>
            </thead>
            <tbody>
              {projectData.map((i, index) => (
                <React.Fragment key={index}>
                  <tr className='expense-table-row'>
                    <td className='expense-table-td expenseslip-row-data1'>{index + 1}</td>
                    <td className='expenseslip-row-data2'>kjh oasdhfskdjfhkj hsk dssfd sdfs d dsfsd fsd fs df sd fjdhfdkjahjkdadhdskjhjkhsf</td>
                    <td className='expense-table-td expenseslip-row-data3'>28000</td>
                    <td className='expense-table-td expenseslip-row-data4'>20%</td>
                    <td className='expense-table-td-r expenseslip-row-data4'>200000</td>
                  </tr>
                  <tr><td colSpan={5} className='printpage-horizontal-division'></td></tr>


                </React.Fragment>
              ))}

              <tr><td colSpan={5} className='printpage-horizontal-division'></td></tr>
              <tr>
                <td ></td>
                <td className='expenseslip-row-data2'><b>Payment Stage -</b> Advance 25%</td>
              </tr>
              <tr><td colSpan={5} className='printpage-horizontal-division'></td></tr>
              <tr>
                <td ></td>
                <td className='expenseslip-row-data2'><b>Total Amount Before Tax -</b> </td>
                <td className="expense-table-td-r expenseslip-row-data3"></td>
                <td className="expense-table-td-r expenseslip-row-data4"></td>
                <td className="expense-table-td-r expenseslip-row-data4">75000</td>
              </tr>
              <tr><td colSpan={5} className='printpage-horizontal-division'></td></tr>
              <tr><td colSpan={5} className='printpage-horizontal-division'></td></tr>


            </tbody>
          </table>
          <table className='salary-table'>

            <tbody>
              <tr><td colSpan={5} className='printpage-horizontal-division'></td></tr>

              <tr className='expense-table-row'>
                <td className='expense-table-td expenseslip-head-data expenseslip-row-data1'></td>
                <td className='expenseslip-row-data5'><b>Work Order Summary -</b> </td>
                <td className='expense-table-td expenseslip-row-data6'><b>Amount</b> </td>
                <td className='expense-table-td-r expenseslip-row-data3'><b>IGST</b></td>
                <td className='expense-table-td-r expenseslip-row-data4'>18%</td>
                <td className="expense-table-td-r expenseslip-row-data4">13500</td>
              </tr>
              <tr><td colSpan={6} className='printpage-horizontal-division'></td></tr>
              <tr>
                <td className="expense-table-td expenseslip-row-data1"></td>
                <td className=' expenseslip-row-data5'>Total Value of Work Order</td>
                <td className='expense-table-td expenseslip-row-data6'>300000</td>
                <td className='expense-table-td-r expenseslip-row-data3'><b>SGST  </b></td>
                <td className='expense-table-td-r expenseslip-row-data4'>9%</td>
                <td className='expense-table-td expenseslip-row-data4'>-</td>
              </tr>
              <tr><td colSpan={6} className='printpage-horizontal-division'></td></tr>
              <tr>
                <td className="expense-table-td expenseslip-row-data1"></td>
                <td className=' expenseslip-row-data5'>Total Billing Up to Previous Invoice</td>
                <td className='expense-table-td expenseslip-row-data6'></td>
                <td className='expense-table-td-r expenseslip-row-data3'><b>CGST</b></td>
                <td className='expense-table-td-r expenseslip-row-data4'>9%</td>
                <td className='expense-table-td expenseslip-row-data4'>-</td>
              </tr>
              <tr><td colSpan={6} className='printpage-horizontal-division'></td></tr>
              <tr>
                <td className="expense-table-td expenseslip-row-data1"></td>
                <td className=' expenseslip-row-data5'>Total Billing in Current Invoice</td>
                <td className='expense-table-td expenseslip-row-data6'>75000</td>
                <td className='expense-table-td-r expenseslip-row-data3'><b>Total Tax</b></td>
                <td className='expense-table-td-r expenseslip-row-data4'></td>
                <td className='expense-table-td expenseslip-row-data4'>13,500</td>
              </tr>
              <tr><td colSpan={6} className='printpage-horizontal-division'></td></tr>
              <tr>
                <td className="expense-table-td expenseslip-row-data1"></td>
                <td className=' expenseslip-row-data5'><b>Balance to Bill</b></td>
                <td className='expense-table-td expenseslip-row-data6'><b>28000</b></td>
                <td className='expense-table-td-r expenseslip-row-data3'></td>
                <td className='expense-table-td-r expenseslip-row-data4'></td>
                <td className='expense-table-td-r expenseslip-row-data4'></td>
              </tr>
              <tr><td colSpan={6} className='printpage-horizontal-division'></td></tr>
              <tr>
                <td className="expense-table-td expenseslip-row-data1"></td>
                <td className=' expenseslip-row-data5'><b>Total Amount After Tax</b></td>
                <td className='expense-table-td expenseslip-row-data6'></td>
                <td className='expense-table-td-r expenseslip-row-data3'></td>
                <td className='expense-table-td-r expenseslip-row-data4'></td>
                <td className='expense-table-td-r expenseslip-row-data4'><b>88500</b></td>
              </tr>
              <tr><td colSpan={6} className='printpage-horizontal-division'></td></tr>
              <tr><td colSpan={6} className='printpage-horizontal-division'></td></tr>

            </tbody>


          </table>


          <tr className='expense-table-row'>
            <td className="expense-table-td expenseslip-row-data1"> </td>
            <td className=' '><b>Eighty Eight THousand And Five HUndred Only</b></td>
          </tr>
          <tr><td colSpan={6} className='printpage-horizontal-division'></td></tr>
          <tr><td colSpan={6} className='printpage-horizontal-division'></td></tr>


        </div>
        <div className='printpage-header-third'>

          <div>
            <th>Terms and Conditions:-</th>
            <h6 className='TnC'>1. Invoice is True and fair </h6>
            <h6 className='TnC'>2. All disputes belong to Haryana Jurisdiction </h6>
            <h6 className='TnC'>3. Payment Shall be made witin 5 Days of </h6>
            <h6 className='TnC'>4. We are Registered MSME Unit Vide No.- UDYAM-DL-11-0010217 </h6>
            <h6 className='TnC'><b>5. This is Software generated Invoice no signature required</b></h6>

          </div>

        </div>
      </div>
      <div className='slaryslip-bg'>
      </div>
    </div>
  );
});

const PrintableComponent = () => {
  const ref = useRef();

  return (
    <div>
      {/* Your other components or content */}
      <ReactToPrint
        bodyClass="print-agreement"
        content={() => ref.current}
        trigger={() => (
          <button>
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 20 18" fill="none">
              <path d="M17 5H3C1.34 5 0 6.34 0 8V12C0 13.1 0.9 14 2 14H4V16C4 17.1 4.9 18 6 18H14C15.1 18 16 17.1 16 16V14H18C19.1 14 20 13.1 20 12V8C20 6.34 18.66 5 17 5ZM13 16H7C6.45 16 6 15.55 6 15V11H14V15C14 15.55 13.55 16 13 16ZM17 9C16.45 9 16 8.55 16 8C16 7.45 16.45 7 17 7C17.55 7 18 7.45 18 8C18 8.55 17.55 9 17 9ZM15 0H5C4.45 0 4 0.45 4 1V3C4 3.55 4.45 4 5 4H15C15.55 4 16 3.55 16 3V1C16 0.45 15.55 0 15 0Z" fill="black" />
            </svg> Print
          </button>
        )}
      />

      <PrintPage ref={ref} />
    </div>
  );
};

export default PrintableComponent;
