import React, {useState} from 'react'
import Dashboardnavbarcopy from '../../layout/Dashboardnavbarcopy';
import Announcetable from './Announcetable';
import Birthdaytable from './Birthdaytable';
import Workanniversarytable from './Workanniversaytable';
import Importantmessagetable from './Importantmessagetable';
import Starperformancetables from './Starperformancetables';
import Eventtables from './Eventtables';
import Assettable from './Assettable';


const Settings = () => {

    const [toggleState, setToggleState] = useState(1);


    const getSelectedTitle = () => {
        switch (toggleState) {
          case 1:
            return 'Announcement';
          case 2:
            return 'Birthday';
          case 3:
            return 'Work anniversary';
          case 4:
            return 'Important message';
          case 5:
            return 'Star Performance';
          case 6:
            return 'Event';
          case 7: 
          return 'Asset';
          case 8: 
          default:
            return '';
        }
      };


    return (
        <>
            <div>
                <Dashboardnavbarcopy name={getSelectedTitle()} url="Settings" />
                <div className="bloc-tabss  wfm-margin">
                    <button
                        className={toggleState === 1 ? "dashboardcomponent-tabs dashboardcomponent-tabss dashboardcomponent-active-tabs" : "dashboardcomponent-tabs dashboardcomponent-tabss"}
                        onClick={() => setToggleState(1)}
                    >
                        Announcement
                    </button>
                    {/* <button
                        className={toggleState === 2 ? "dashboardcomponent-tabs dashboardcomponent-tabss dashboardcomponent-active-tabs" : "dashboardcomponent-tabs dashboardcomponent-tabss"}
                        onClick={() => setToggleState(2)}
                    >
                        Birthday
                    </button>
                    <button
                        className={toggleState === 3 ? "dashboardcomponent-tabs dashboardcomponent-tabss dashboardcomponent-active-tabs" : "dashboardcomponent-tabs dashboardcomponent-tabss"}
                        onClick={() => setToggleState(3)}
                    >
                        Work anniversary
                    </button> */}
                    <button
                        className={toggleState === 4 ? "dashboardcomponent-tabs dashboardcomponent-tabss dashboardcomponent-active-tabs" : "dashboardcomponent-tabs dashboardcomponent-tabss"}
                        onClick={() => setToggleState(4)}
                    >
                        Important message
                    </button>
                    <button
                        className={toggleState === 5 ? "dashboardcomponent-tabs dashboardcomponent-tabss dashboardcomponent-active-tabs" : "dashboardcomponent-tabs dashboardcomponent-tabss"}
                        onClick={() => setToggleState(5)}
                    >
                        Star Performance
                    </button>
                    <button
                        className={toggleState === 6 ? "dashboardcomponent-tabs dashboardcomponent-tabss dashboardcomponent-active-tabs" : "dashboardcomponent-tabs dashboardcomponent-tabss"}
                        onClick={() => setToggleState(6)}
                    >
                        Event
                    </button>
                    <button
                        className={toggleState === 7 ? "dashboardcomponent-tabs dashboardcomponent-tabss dashboardcomponent-active-tabs" : "dashboardcomponent-tabs dashboardcomponent-tabss"}
                        onClick={() => setToggleState(7)}
                    >
                        Asset
                    </button>



                </div>
                <div className="content-tabs">

{toggleState === 1 ? (
                       <Announcetable/>
                    ) : null}

                    {toggleState === 2 ? <Birthdaytable /> : null}

                    {toggleState === 3 ? <Workanniversarytable/> : null}

                    {toggleState === 4 ? <Importantmessagetable/> : null}

                    {toggleState === 5 ? <Starperformancetables /> : null}

                    {toggleState === 6 ? <Eventtables /> : null}

                    {toggleState === 7 ? <Assettable /> : null}




                </div>
            </div>

        </>
    )
}

export default Settings
