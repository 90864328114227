import React, { useState, useEffect } from "react";
// import HrmsFooter from '../../layout/HrmsFooter';
import Docs from "../../images/docs.svg";
import Imgs from "../../images/imgs.svg";
import Profile from "../../images/profile.svg";
import Modal from "react-bootstrap/Modal";

import axios from "axios";
import { BASE_URL } from "../../../config/axios"
import { useNavigate, useParams } from "react-router-dom";
import Careernavbar from "./Careernavbar";
import { Button } from "bootstrap";





const AppliedPage = () => {


    return (
        <>
            <center>  <br /><br /><br /><br /><br /><br /><br /><br /><h3>
                <br /><b>Thankyou for Applying, </b> <br />Please contanct Our HR Team for Further Process<br />
                {/* <img style={{ borderRadius: "20px", boxShadow: "0px 1px 38px 0px rgba(0, 0, 0, 0.10)" }} src={Gif} alt="" /> */}
            </h3></center>

        </>
    );
};

export default AppliedPage;



