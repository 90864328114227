import React, { useEffect, useState } from "react";
import "../../assets/css/components.css";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import Select from 'react-select';
import { BASE_URL } from "../../config/axios";
import { ErrorModal } from "../Alerts/AlertModals";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


// ! *************** Departments Crud Models Start ***************

const Department = ({ getDepartment }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [formData, setFormData] = useState({
    title: "",
  });

  // !  ************** Validation start **************  ! //

  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = ["title"];
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // ?  ************** Validation End **************  ! //

  const [loading, setLoading] = useState(false); //loading logic
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); //loading logic

    if (validateForm() && formData.title != null) {
      try {
        let res = await axios.post(`${BASE_URL}/wfm/department/`, formData);

        if (res.status === 200) {
          await getDepartment();
          setShow(false);
        } else {
          alert(res);
        }
      } catch (err) {
        alert(err);
      } finally {
        setLoading(false); //loading logic
      }
    }
  };

  return (
    <>
      <button className="models-button model-add" onClick={handleShow}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <g clip-path="url(#clip0_1415_3177)">
            <path
              d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM17 13H13V17H11V13H7V11H11V7H13V11H17V13Z"
              fill="#2576BC"
            />
          </g>
          <defs>
            <clipPath id="clip0_1415_3177">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="request-leave">
        {/* <Modal.Header closeButton>
              <Modal.Title>Request Leave</Modal.Title>
            </Modal.Header> */}
        <Modal.Body>
          <div className="parent-div">
            <div className="bdy-div">
              {/* <h6 className="announce-heading">Birthday </h6> */}
              <div className="flex-column">
                <label htmlFor="title" className="form-labels">
                  Title<span className="required">*</span>
                </label>
                <input
                  type="text"
                  id="title"
                  name="title"
                  placeholder="Department Name"
                  onChange={handleInputChange}
                  value={formData.title}
                  className={`form-input ${errors.title ? "error" : inputState.tile ? "success" : ""
                    }`}
                />
                {errors.title && (
                  <span className="error-message">{errors.title}</span>
                )}
              </div>

              <div className="button-models">
                <button className="model-button" onClick={handleClose}>
                  Cancel
                </button>
                <button
                  //   type="submit"
                  className="model-button model-button-leave"
                  onClick={handleFormSubmit}
                  disabled={loading}
                >
                  Add
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

const DepartmentUpdate = ({ i, getDepartment }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [formData, setFormData] = useState({
    title: "",
  });

  useEffect(() => {
    setFormData(i);
  }, [i]);

  // !  ************** Validation start **************  ! //

  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = ["title"];
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // ?  ************** Validation End **************  ! //

  const handleFormSubmitPut = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      try {
        let res = await axios.put(
          `${BASE_URL}/wfm/department/${i.id}/`,
          formData
        );

        if (res.status === 200) {
          await getDepartment();
          setShow(false);
        } else {
          alert(res);
        }
      } catch (err) {
        alert(err);
      }
    }
  };

  return (
    <>
      <button className="model-edit-button" onClick={handleShow}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M15.9834 5.1589L12.8412 2.01734C12.7367 1.91285 12.6127 1.82996 12.4762 1.7734C12.3397 1.71685 12.1933 1.68774 12.0456 1.68774C11.8978 1.68774 11.7515 1.71685 11.615 1.7734C11.4785 1.82996 11.3545 1.91285 11.25 2.01734L2.57977 10.6876C2.47485 10.7917 2.39167 10.9156 2.33506 11.0521C2.27844 11.1886 2.24953 11.335 2.25001 11.4828V14.6251C2.25001 14.9234 2.36853 15.2096 2.57951 15.4206C2.79049 15.6315 3.07664 15.7501 3.37501 15.7501H15.1875C15.3367 15.7501 15.4798 15.6908 15.5853 15.5853C15.6907 15.4798 15.75 15.3368 15.75 15.1876C15.75 15.0384 15.6907 14.8953 15.5853 14.7898C15.4798 14.6843 15.3367 14.6251 15.1875 14.6251H8.10844L15.9834 6.75007C16.0879 6.6456 16.1708 6.52157 16.2274 6.38507C16.2839 6.24856 16.313 6.10225 16.313 5.95449C16.313 5.80673 16.2839 5.66042 16.2274 5.52391C16.1708 5.3874 16.0879 5.26337 15.9834 5.1589ZM6.51727 14.6251H3.37501V11.4828L9.56251 5.29531L12.7048 8.43757L6.51727 14.6251ZM13.5 7.64234L10.3584 4.50007L12.0459 2.81257L15.1875 5.95484L13.5 7.64234Z"
            fill="#145650"
          />
        </svg>
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="request-leave">
        <Modal.Header closeButton>
          <Modal.Title>Update Department {i.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div">
            <div className="bdy-div">
              {/* <h6 className="announce-heading">Birthday </h6> */}
              <div className="flex-column">
                <label htmlFor="title" className="form-labels">
                  Title<span className="required">*</span>
                </label>
                <input
                  type="text"
                  id="title"
                  name="title"
                  placeholder="Department Name"
                  onChange={handleInputChange}
                  value={formData.title}
                  className={`form-input ${errors.title ? "error" : inputState.tile ? "success" : ""
                    }`}
                />
                {errors.title && (
                  <span className="error-message">{errors.title}</span>
                )}
              </div>

              <div className="button-models">
                <button className="model-button " onClick={handleClose}>
                  Cancel
                </button>
                <button
                  onClick={handleFormSubmitPut}
                  className="model-button model-button-leave"
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

const DepartmentDelete = ({ i, getDepartment }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleFormSubmitDelete = async (e) => {
    e.preventDefault();

    try {
      let res = await axios.delete(`${BASE_URL}/wfm/department/${i.id}/`);

      if (res.status === 200) {
        await getDepartment();
        setShow(false);
      } else {
        alert(res);
      }
    } catch (err) {
      alert(err);
    }
  };

  return (
    <>
      <button className="model-delete-button" onClick={handleShow}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M15.1875 3.375H12.375V2.8125C12.375 2.36495 12.1972 1.93572 11.8807 1.61926C11.5643 1.30279 11.1351 1.125 10.6875 1.125H7.3125C6.86495 1.125 6.43572 1.30279 6.11926 1.61926C5.80279 1.93572 5.625 2.36495 5.625 2.8125V3.375H2.8125C2.66332 3.375 2.52024 3.43426 2.41475 3.53975C2.30926 3.64524 2.25 3.78832 2.25 3.9375C2.25 4.08668 2.30926 4.22976 2.41475 4.33525C2.52024 4.44074 2.66332 4.5 2.8125 4.5H3.375V14.625C3.375 14.9234 3.49353 15.2095 3.7045 15.4205C3.91548 15.6315 4.20163 15.75 4.5 15.75H13.5C13.7984 15.75 14.0845 15.6315 14.2955 15.4205C14.5065 15.2095 14.625 14.9234 14.625 14.625V4.5H15.1875C15.3367 4.5 15.4798 4.44074 15.5852 4.33525C15.6907 4.22976 15.75 4.08668 15.75 3.9375C15.75 3.78832 15.6907 3.64524 15.5852 3.53975C15.4798 3.43426 15.3367 3.375 15.1875 3.375ZM6.75 2.8125C6.75 2.66332 6.80926 2.52024 6.91475 2.41475C7.02024 2.30926 7.16332 2.25 7.3125 2.25H10.6875C10.8367 2.25 10.9798 2.30926 11.0852 2.41475C11.1907 2.52024 11.25 2.66332 11.25 2.8125V3.375H6.75V2.8125ZM13.5 14.625H4.5V4.5H13.5V14.625ZM7.875 7.3125V11.8125C7.875 11.9617 7.81574 12.1048 7.71025 12.2102C7.60476 12.3157 7.46168 12.375 7.3125 12.375C7.16332 12.375 7.02024 12.3157 6.91475 12.2102C6.80926 12.1048 6.75 11.9617 6.75 11.8125V7.3125C6.75 7.16332 6.80926 7.02024 6.91475 6.91475C7.02024 6.80926 7.16332 6.75 7.3125 6.75C7.46168 6.75 7.60476 6.80926 7.71025 6.91475C7.81574 7.02024 7.875 7.16332 7.875 7.3125ZM11.25 7.3125V11.8125C11.25 11.9617 11.1907 12.1048 11.0852 12.2102C10.9798 12.3157 10.8367 12.375 10.6875 12.375C10.5383 12.375 10.3952 12.3157 10.2898 12.2102C10.1843 12.1048 10.125 11.9617 10.125 11.8125V7.3125C10.125 7.16332 10.1843 7.02024 10.2898 6.91475C10.3952 6.80926 10.5383 6.75 10.6875 6.75C10.8367 6.75 10.9798 6.80926 11.0852 6.91475C11.1907 7.02024 11.25 7.16332 11.25 7.3125Z"
            fill="#F72B50"
          />
        </svg>
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="request-leave">
        <Modal.Header closeButton>
          <Modal.Title>Delete Department {i.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div">
            Are You Sure You Want to delete department {i.title}
            <div className="button-models">
              <button className="model-button " onClick={handleClose}>
                Cancel
              </button>
              <button
                onClick={handleFormSubmitDelete}
                className="model-button model-button-delete"
              >
                Delete
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

// ? *************** Departments Crud Models End ***************

// ! *************** Designagtion Crud Models Start ***************



const DesignationName = ({ getDesignation, designationname }) => {
  const [show, setShow] = useState(false);
  const [formData, setFormData] = useState({ title: "" });
  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});
  const [loading, setLoading] = useState(false);
  const [filteredList, setFilteredList] = useState([]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = ["title"];
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = `${field.charAt(0).toUpperCase() + field.slice(1)} is required!`;
      }
    });
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  console.log(designationname)

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });

    setFormData({
      ...formData,
      [name]: value,
    });



    // Filter logic based on the input
    if (name === 'title') {
      const filtered = designationname.filter(item =>
        item.title.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredList(filtered);
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (validateForm()) {
      try {
        let res = await axios.post(`${BASE_URL}/wfm/designation/`, formData);
        if (res.status === 200) {
          await getDesignation();
          setShow(false);
        } else {
          alert(res);
        }
      } catch (err) {
        alert(err);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <>
      <button className="models-button model-add" onClick={handleShow}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <g clip-path="url(#clip0_1415_3177)">
            <path
              d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM17 13H13V17H11V13H7V11H11V7H13V11H17V13Z"
              fill="#2576BC"
            />
          </g>
          <defs>
            <clipPath id="clip0_1415_3177">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="request-leave">
        <Modal.Body>
          <div className="parent-div">
            <div className="bdy-div">
              <div className="flex-column">
                <label htmlFor="title" className="form-labels">
                  Title<span className="required">*</span>
                </label>
                <input
                  type="text"
                  id="title"
                  name="title"
                  placeholder="Designation Name"
                  onChange={handleInputChange}
                  value={formData.title}
                  className={`form-input ${errors.title ? "error" : inputState.title ? "success" : ""}`}
                />
                {errors.title && <span className="error-message">{errors.title}</span>}
                {filteredList.length > 0 && (
                  <div className="list-group">
                    {filteredList.map((item, index) => (
                      <div key={index} className="list-group-item">
                        {item.title}
                      </div>
                    ))}
                  </div>
                )}

                <div className="button-models">
                  <button className="model-button" onClick={handleClose}>Cancel</button>
                  <button className="model-button model-button-leave" onClick={handleFormSubmit} disabled={loading}>Submit</button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};


const DesignationUpdate = ({ i, getDesignation }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [formData, setFormData] = useState({
    title: "",
  });

  useEffect(() => {
    setFormData(i);
  }, [i]);

  // !  ************** Validation start **************  ! //

  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = ["title"];
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // ?  ************** Validation End **************  ! //

  const handleFormSubmitPut = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      try {
        let res = await axios.put(
          `${BASE_URL}/wfm/designation/${i.id}/`,
          formData
        );

        if (res.status === 200) {
          await getDesignation();
          setShow(false);
        } else {
          alert(res);
        }
      } catch (err) {
        alert(err);
      }
    }
  };

  return (
    <>
      <button className="model-edit-button" onClick={handleShow}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M15.9834 5.1589L12.8412 2.01734C12.7367 1.91285 12.6127 1.82996 12.4762 1.7734C12.3397 1.71685 12.1933 1.68774 12.0456 1.68774C11.8978 1.68774 11.7515 1.71685 11.615 1.7734C11.4785 1.82996 11.3545 1.91285 11.25 2.01734L2.57977 10.6876C2.47485 10.7917 2.39167 10.9156 2.33506 11.0521C2.27844 11.1886 2.24953 11.335 2.25001 11.4828V14.6251C2.25001 14.9234 2.36853 15.2096 2.57951 15.4206C2.79049 15.6315 3.07664 15.7501 3.37501 15.7501H15.1875C15.3367 15.7501 15.4798 15.6908 15.5853 15.5853C15.6907 15.4798 15.75 15.3368 15.75 15.1876C15.75 15.0384 15.6907 14.8953 15.5853 14.7898C15.4798 14.6843 15.3367 14.6251 15.1875 14.6251H8.10844L15.9834 6.75007C16.0879 6.6456 16.1708 6.52157 16.2274 6.38507C16.2839 6.24856 16.313 6.10225 16.313 5.95449C16.313 5.80673 16.2839 5.66042 16.2274 5.52391C16.1708 5.3874 16.0879 5.26337 15.9834 5.1589ZM6.51727 14.6251H3.37501V11.4828L9.56251 5.29531L12.7048 8.43757L6.51727 14.6251ZM13.5 7.64234L10.3584 4.50007L12.0459 2.81257L15.1875 5.95484L13.5 7.64234Z"
            fill="#145650"
          />
        </svg>
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="request-leave">
        <Modal.Header closeButton>
          <Modal.Title>Update Designation {i.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div">
            <div className="bdy-div">
              {/* <h6 className="announce-heading">Birthday </h6> */}
              <div className="flex-column">
                <label htmlFor="title" className="form-labels">
                  Title<span className="required">*</span>
                </label>
                <input
                  type="text"
                  id="title"
                  name="title"
                  placeholder="Department Name"
                  onChange={handleInputChange}
                  value={formData.title}
                  className={`form-input ${errors.title ? "error" : inputState.tile ? "success" : ""
                    }`}
                />
                {errors.title && (
                  <span className="error-message">{errors.title}</span>
                )}
              </div>

              <div className="button-models">
                <button className="model-button " onClick={handleClose}>
                  Cancel
                </button>
                <button
                  type="submit"
                  onClick={handleFormSubmitPut}
                  className="model-button model-button-leave"
                >
                  Add
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

const DesignationDelete = ({ i, getDesignation }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleFormSubmitDelete = async (e) => {
    e.preventDefault();

    try {
      let res = await axios.delete(`${BASE_URL}/wfm/designation/${i.id}/`);

      if (res.status === 200) {
        await getDesignation();
        setShow(false);
      } else {
        alert(res);
      }
    } catch (err) {
      alert(err);
    }
  };

  return (
    <>
      <button className="model-delete-button" onClick={handleShow}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M15.1875 3.375H12.375V2.8125C12.375 2.36495 12.1972 1.93572 11.8807 1.61926C11.5643 1.30279 11.1351 1.125 10.6875 1.125H7.3125C6.86495 1.125 6.43572 1.30279 6.11926 1.61926C5.80279 1.93572 5.625 2.36495 5.625 2.8125V3.375H2.8125C2.66332 3.375 2.52024 3.43426 2.41475 3.53975C2.30926 3.64524 2.25 3.78832 2.25 3.9375C2.25 4.08668 2.30926 4.22976 2.41475 4.33525C2.52024 4.44074 2.66332 4.5 2.8125 4.5H3.375V14.625C3.375 14.9234 3.49353 15.2095 3.7045 15.4205C3.91548 15.6315 4.20163 15.75 4.5 15.75H13.5C13.7984 15.75 14.0845 15.6315 14.2955 15.4205C14.5065 15.2095 14.625 14.9234 14.625 14.625V4.5H15.1875C15.3367 4.5 15.4798 4.44074 15.5852 4.33525C15.6907 4.22976 15.75 4.08668 15.75 3.9375C15.75 3.78832 15.6907 3.64524 15.5852 3.53975C15.4798 3.43426 15.3367 3.375 15.1875 3.375ZM6.75 2.8125C6.75 2.66332 6.80926 2.52024 6.91475 2.41475C7.02024 2.30926 7.16332 2.25 7.3125 2.25H10.6875C10.8367 2.25 10.9798 2.30926 11.0852 2.41475C11.1907 2.52024 11.25 2.66332 11.25 2.8125V3.375H6.75V2.8125ZM13.5 14.625H4.5V4.5H13.5V14.625ZM7.875 7.3125V11.8125C7.875 11.9617 7.81574 12.1048 7.71025 12.2102C7.60476 12.3157 7.46168 12.375 7.3125 12.375C7.16332 12.375 7.02024 12.3157 6.91475 12.2102C6.80926 12.1048 6.75 11.9617 6.75 11.8125V7.3125C6.75 7.16332 6.80926 7.02024 6.91475 6.91475C7.02024 6.80926 7.16332 6.75 7.3125 6.75C7.46168 6.75 7.60476 6.80926 7.71025 6.91475C7.81574 7.02024 7.875 7.16332 7.875 7.3125ZM11.25 7.3125V11.8125C11.25 11.9617 11.1907 12.1048 11.0852 12.2102C10.9798 12.3157 10.8367 12.375 10.6875 12.375C10.5383 12.375 10.3952 12.3157 10.2898 12.2102C10.1843 12.1048 10.125 11.9617 10.125 11.8125V7.3125C10.125 7.16332 10.1843 7.02024 10.2898 6.91475C10.3952 6.80926 10.5383 6.75 10.6875 6.75C10.8367 6.75 10.9798 6.80926 11.0852 6.91475C11.1907 7.02024 11.25 7.16332 11.25 7.3125Z"
            fill="#F72B50"
          />
        </svg>
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="request-leave">
        <Modal.Header closeButton>
          <Modal.Title>Delete Designatiion {i.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div">
            Are You Sure You Want to delete department {i.title}
            <div className="button-models">
              <button className="model-button " onClick={handleClose}>
                Cancel
              </button>
              <button type="submit" className="model-button model-button-leave" onClick={handleFormSubmitDelete}>
                Delete
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

// ? *************** Designagtion Crud Models End ***************

// ! *************** Sub Company Crud Models Start ***************

const Subcompanys = ({ getSubCompany }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [formData, setFormData] = useState({
    title: "",
  });

  // !  ************** Validation start **************  ! //

  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = ["title"];
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // ?  ************** Validation End **************  ! //
  const [loading, setLoading] = useState(false); //loading logic
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); //loading logic

    if (validateForm()) {
      try {
        let res = await axios.post(`${BASE_URL}/wfm/subcompany/`, formData);

        if (res.status === 200) {
          setShow(false);
          await getSubCompany();
        } else {
          alert(res);
        }
      } catch (err) {
        alert(err);
      } finally {
        setLoading(false); //loading logic
      }
    }
  };

  return (
    <>
      <button className="models-button model-add" onClick={handleShow}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <g clip-path="url(#clip0_1415_3177)">
            <path
              d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM17 13H13V17H11V13H7V11H11V7H13V11H17V13Z"
              fill="#2576BC"
            />
          </g>
          <defs>
            <clipPath id="clip0_1415_3177">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="request-leave">
        {/* <Modal.Header closeButton>
              <Modal.Title>Request Leave</Modal.Title>
            </Modal.Header> */}
        <Modal.Body>
          <div className="parent-div">
            <div className="bdy-div">
              {/* <h6 className="announce-heading">Birthday </h6> */}
              <div className="flex-column">
                <label htmlFor="title" className="form-labels">
                  Title<span className="required">*</span>
                </label>
                <input
                  type="text"
                  id="title"
                  name="title"
                  placeholder="Company Name"
                  onChange={handleInputChange}
                  value={formData.title}
                  className={`form-input ${errors.title ? "error" : inputState.tile ? "success" : ""
                    }`}
                />
                {errors.title && (
                  <span className="error-message">{errors.title}</span>
                )}
                <div className="button-models">
                  <button className="model-button" onClick={handleClose}>
                    Cancel
                  </button>
                  <button
                    //   type="submit"
                    className="model-button model-button-leave"
                    onClick={handleFormSubmit}
                    disabled={loading}
                  >
                    Submit
                  </button>
                </div>{" "}
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

const Subcompanyupdate = ({ i, getSubCompany }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [formData, setFormData] = useState({
    title: "",
  });

  useEffect(() => {
    setFormData(i);
  }, [i]);

  // !  ************** Validation start **************  ! //

  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = ["title"];
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // ?  ************** Validation End **************  ! //

  const handleFormSubmitPut = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      try {
        let res = await axios.put(
          `${BASE_URL}/wfm/subcompany/${i.id}/`,
          formData
        );

        if (res.status === 200) {
          setShow(false);
          await getSubCompany();
        } else {
          alert(res);
        }
      } catch (err) {
        alert(err);
      }
    }
  };

  return (
    <>
      <button className="model-edit-button" onClick={handleShow}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M15.9834 5.1589L12.8412 2.01734C12.7367 1.91285 12.6127 1.82996 12.4762 1.7734C12.3397 1.71685 12.1933 1.68774 12.0456 1.68774C11.8978 1.68774 11.7515 1.71685 11.615 1.7734C11.4785 1.82996 11.3545 1.91285 11.25 2.01734L2.57977 10.6876C2.47485 10.7917 2.39167 10.9156 2.33506 11.0521C2.27844 11.1886 2.24953 11.335 2.25001 11.4828V14.6251C2.25001 14.9234 2.36853 15.2096 2.57951 15.4206C2.79049 15.6315 3.07664 15.7501 3.37501 15.7501H15.1875C15.3367 15.7501 15.4798 15.6908 15.5853 15.5853C15.6907 15.4798 15.75 15.3368 15.75 15.1876C15.75 15.0384 15.6907 14.8953 15.5853 14.7898C15.4798 14.6843 15.3367 14.6251 15.1875 14.6251H8.10844L15.9834 6.75007C16.0879 6.6456 16.1708 6.52157 16.2274 6.38507C16.2839 6.24856 16.313 6.10225 16.313 5.95449C16.313 5.80673 16.2839 5.66042 16.2274 5.52391C16.1708 5.3874 16.0879 5.26337 15.9834 5.1589ZM6.51727 14.6251H3.37501V11.4828L9.56251 5.29531L12.7048 8.43757L6.51727 14.6251ZM13.5 7.64234L10.3584 4.50007L12.0459 2.81257L15.1875 5.95484L13.5 7.64234Z"
            fill="#145650"
          />
        </svg>
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="request-leave">
        <Modal.Header closeButton>
          <Modal.Title>Update SubCompany {i.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div">
            <div className="bdy-div">
              {/* <h6 className="announce-heading">Birthday </h6> */}
              <div className="flex-column">
                <label htmlFor="title" className="form-labels">
                  Title<span className="required">*</span>
                </label>
                <input
                  type="text"
                  id="title"
                  name="title"
                  placeholder="SubCompany Name"
                  onChange={handleInputChange}
                  value={formData.title}
                  className={`form-input ${errors.title ? "error" : inputState.tile ? "success" : ""
                    }`}
                />
                {errors.title && (
                  <span className="error-message">{errors.title}</span>
                )}
              </div>

              <div className="button-models">
                <button className="model-button " onClick={handleClose}>
                  Cancel
                </button>
                <button
                  onClick={handleFormSubmitPut}
                  className="model-button model-button-leave"
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

const SubcompanyDelete = ({ i, getSubCompany }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleFormSubmitDelete = async (e) => {
    e.preventDefault();

    try {
      let res = await axios.delete(`${BASE_URL}/wfm/subcompany/${i.id}/`);

      if (res.status === 200) {
        setShow(false);
        await getSubCompany();
      } else {
        alert(res);
      }
    } catch (err) {
      alert(err);
    }
  };

  return (
    <>
      <button className="model-delete-button" onClick={handleShow}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M15.1875 3.375H12.375V2.8125C12.375 2.36495 12.1972 1.93572 11.8807 1.61926C11.5643 1.30279 11.1351 1.125 10.6875 1.125H7.3125C6.86495 1.125 6.43572 1.30279 6.11926 1.61926C5.80279 1.93572 5.625 2.36495 5.625 2.8125V3.375H2.8125C2.66332 3.375 2.52024 3.43426 2.41475 3.53975C2.30926 3.64524 2.25 3.78832 2.25 3.9375C2.25 4.08668 2.30926 4.22976 2.41475 4.33525C2.52024 4.44074 2.66332 4.5 2.8125 4.5H3.375V14.625C3.375 14.9234 3.49353 15.2095 3.7045 15.4205C3.91548 15.6315 4.20163 15.75 4.5 15.75H13.5C13.7984 15.75 14.0845 15.6315 14.2955 15.4205C14.5065 15.2095 14.625 14.9234 14.625 14.625V4.5H15.1875C15.3367 4.5 15.4798 4.44074 15.5852 4.33525C15.6907 4.22976 15.75 4.08668 15.75 3.9375C15.75 3.78832 15.6907 3.64524 15.5852 3.53975C15.4798 3.43426 15.3367 3.375 15.1875 3.375ZM6.75 2.8125C6.75 2.66332 6.80926 2.52024 6.91475 2.41475C7.02024 2.30926 7.16332 2.25 7.3125 2.25H10.6875C10.8367 2.25 10.9798 2.30926 11.0852 2.41475C11.1907 2.52024 11.25 2.66332 11.25 2.8125V3.375H6.75V2.8125ZM13.5 14.625H4.5V4.5H13.5V14.625ZM7.875 7.3125V11.8125C7.875 11.9617 7.81574 12.1048 7.71025 12.2102C7.60476 12.3157 7.46168 12.375 7.3125 12.375C7.16332 12.375 7.02024 12.3157 6.91475 12.2102C6.80926 12.1048 6.75 11.9617 6.75 11.8125V7.3125C6.75 7.16332 6.80926 7.02024 6.91475 6.91475C7.02024 6.80926 7.16332 6.75 7.3125 6.75C7.46168 6.75 7.60476 6.80926 7.71025 6.91475C7.81574 7.02024 7.875 7.16332 7.875 7.3125ZM11.25 7.3125V11.8125C11.25 11.9617 11.1907 12.1048 11.0852 12.2102C10.9798 12.3157 10.8367 12.375 10.6875 12.375C10.5383 12.375 10.3952 12.3157 10.2898 12.2102C10.1843 12.1048 10.125 11.9617 10.125 11.8125V7.3125C10.125 7.16332 10.1843 7.02024 10.2898 6.91475C10.3952 6.80926 10.5383 6.75 10.6875 6.75C10.8367 6.75 10.9798 6.80926 11.0852 6.91475C11.1907 7.02024 11.25 7.16332 11.25 7.3125Z"
            fill="#F72B50"
          />
        </svg>
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="request-leave">
        <Modal.Header closeButton>
          <Modal.Title>Delete SubCompany {i.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div">
            Are You Sure You Want to delete SubCompany {i.title}
            <div className="button-models">
              <button className="model-button " onClick={handleClose}>
                Cancel
              </button>
              <button
                onClick={handleFormSubmitDelete}
                className="model-button model-button-delete"
              >
                Delete
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

// ? *************** SubComany Crud Models End ***************

// ! *************** Sub Company Crud Models Start ***************

const Woksiteadd = ({ getWoksiteList }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [formData, setFormData] = useState({
    title: "",
  });

  // !  ************** Validation start **************  ! //

  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = ["title"];
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // ?  ************** Validation End **************  ! //

  const [loading, setLoading] = useState(false); //loading logic
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); //loading logic

    if (validateForm()) {
      try {
        let res = await axios.post(`${BASE_URL}/wfm/worksite/`, formData);

        if (res.status === 200) {
          setShow(false);
          await getWoksiteList();
        } else {
          alert(res);
        }
      } catch (err) {
        alert(err);
      } finally {
        setLoading(false); //loading logic
      }
    }
  };

  return (
    <>
      <button className="models-button model-add" onClick={handleShow}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <g clip-path="url(#clip0_1415_3177)">
            <path
              d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM17 13H13V17H11V13H7V11H11V7H13V11H17V13Z"
              fill="#2576BC"
            />
          </g>
          <defs>
            <clipPath id="clip0_1415_3177">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="request-leave">
        {/* <Modal.Header closeButton>
              <Modal.Title>Request Leave</Modal.Title>
            </Modal.Header> */}
        <Modal.Body>
          <div className="parent-div">
            <div className="bdy-div">
              {/* <h6 className="announce-heading">Birthday </h6> */}
              <div className="flex-column">
                <label htmlFor="title" className="form-labels">
                  Worksite Name<span className="required">*</span>
                </label>
                <input
                  type="text"
                  id="title"
                  name="title"
                  placeholder="Worksite Name"
                  onChange={handleInputChange}
                  value={formData.title}
                  className={`form-input ${errors.title ? "error" : inputState.tile ? "success" : ""
                    }`}
                />
                {errors.title && (
                  <span className="error-message">{errors.title}</span>
                )}
                <div className="button-models">
                  <button className="model-button" onClick={handleClose}>
                    Cancel
                  </button>
                  <button
                    //   type="submit"
                    className="model-button model-button-leave"
                    onClick={handleFormSubmit}
                    disabled={loading}
                  >
                    Submit
                  </button>
                </div>{" "}
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

const Woksiteupdate = ({ i, getWoksiteList }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [formData, setFormData] = useState({
    title: "",
  });

  useEffect(() => {
    setFormData(i);
  }, [i]);

  // !  ************** Validation start **************  ! //

  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = ["title"];
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // ?  ************** Validation End **************  ! //

  const handleFormSubmitPut = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      try {
        let res = await axios.put(
          `${BASE_URL}/wfm/worksite/${i.id}/`,
          formData
        );

        if (res.status === 200) {
          setShow(false);
          await getWoksiteList();
        } else {
          alert(res);
        }
      } catch (err) {
        alert(err);
      }
    }
  };

  return (
    <>
      <button className="model-edit-button" onClick={handleShow}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M15.9834 5.1589L12.8412 2.01734C12.7367 1.91285 12.6127 1.82996 12.4762 1.7734C12.3397 1.71685 12.1933 1.68774 12.0456 1.68774C11.8978 1.68774 11.7515 1.71685 11.615 1.7734C11.4785 1.82996 11.3545 1.91285 11.25 2.01734L2.57977 10.6876C2.47485 10.7917 2.39167 10.9156 2.33506 11.0521C2.27844 11.1886 2.24953 11.335 2.25001 11.4828V14.6251C2.25001 14.9234 2.36853 15.2096 2.57951 15.4206C2.79049 15.6315 3.07664 15.7501 3.37501 15.7501H15.1875C15.3367 15.7501 15.4798 15.6908 15.5853 15.5853C15.6907 15.4798 15.75 15.3368 15.75 15.1876C15.75 15.0384 15.6907 14.8953 15.5853 14.7898C15.4798 14.6843 15.3367 14.6251 15.1875 14.6251H8.10844L15.9834 6.75007C16.0879 6.6456 16.1708 6.52157 16.2274 6.38507C16.2839 6.24856 16.313 6.10225 16.313 5.95449C16.313 5.80673 16.2839 5.66042 16.2274 5.52391C16.1708 5.3874 16.0879 5.26337 15.9834 5.1589ZM6.51727 14.6251H3.37501V11.4828L9.56251 5.29531L12.7048 8.43757L6.51727 14.6251ZM13.5 7.64234L10.3584 4.50007L12.0459 2.81257L15.1875 5.95484L13.5 7.64234Z"
            fill="#145650"
          />
        </svg>
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="request-leave">
        <Modal.Header closeButton>
          <Modal.Title>Worksite Name</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div">
            <div className="bdy-div">
              {/* <h6 className="announce-heading">Birthday </h6> */}
              <div className="flex-column">
                <label htmlFor="title" className="form-labels">
                  Worksite Name<span className="required">*</span>
                </label>
                <input
                  type="text"
                  id="title"
                  name="title"
                  placeholder="Worksite Name"
                  onChange={handleInputChange}
                  value={formData.title}
                  className={`form-input ${errors.title ? "error" : inputState.tile ? "success" : ""
                    }`}
                />
                {errors.title && (
                  <span className="error-message">{errors.title}</span>
                )}
              </div>

              <div className="button-models">
                <button className="model-button " onClick={handleClose}>
                  Cancel
                </button>
                <button
                  onClick={handleFormSubmitPut}
                  className="model-button model-button-leave"
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

const WoksiteDelete = ({ i, getWoksiteList }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleFormSubmitDelete = async (e) => {
    e.preventDefault();

    try {
      let res = await axios.delete(`${BASE_URL}/wfm/worksite/${i.id}/`);

      if (res.status === 200) {
        setShow(false);
        await getWoksiteList();
      } else {
        alert(res);
      }
    } catch (err) {
      alert(err);
    }
  };

  return (
    <>
      <button className="model-delete-button" onClick={handleShow}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M15.1875 3.375H12.375V2.8125C12.375 2.36495 12.1972 1.93572 11.8807 1.61926C11.5643 1.30279 11.1351 1.125 10.6875 1.125H7.3125C6.86495 1.125 6.43572 1.30279 6.11926 1.61926C5.80279 1.93572 5.625 2.36495 5.625 2.8125V3.375H2.8125C2.66332 3.375 2.52024 3.43426 2.41475 3.53975C2.30926 3.64524 2.25 3.78832 2.25 3.9375C2.25 4.08668 2.30926 4.22976 2.41475 4.33525C2.52024 4.44074 2.66332 4.5 2.8125 4.5H3.375V14.625C3.375 14.9234 3.49353 15.2095 3.7045 15.4205C3.91548 15.6315 4.20163 15.75 4.5 15.75H13.5C13.7984 15.75 14.0845 15.6315 14.2955 15.4205C14.5065 15.2095 14.625 14.9234 14.625 14.625V4.5H15.1875C15.3367 4.5 15.4798 4.44074 15.5852 4.33525C15.6907 4.22976 15.75 4.08668 15.75 3.9375C15.75 3.78832 15.6907 3.64524 15.5852 3.53975C15.4798 3.43426 15.3367 3.375 15.1875 3.375ZM6.75 2.8125C6.75 2.66332 6.80926 2.52024 6.91475 2.41475C7.02024 2.30926 7.16332 2.25 7.3125 2.25H10.6875C10.8367 2.25 10.9798 2.30926 11.0852 2.41475C11.1907 2.52024 11.25 2.66332 11.25 2.8125V3.375H6.75V2.8125ZM13.5 14.625H4.5V4.5H13.5V14.625ZM7.875 7.3125V11.8125C7.875 11.9617 7.81574 12.1048 7.71025 12.2102C7.60476 12.3157 7.46168 12.375 7.3125 12.375C7.16332 12.375 7.02024 12.3157 6.91475 12.2102C6.80926 12.1048 6.75 11.9617 6.75 11.8125V7.3125C6.75 7.16332 6.80926 7.02024 6.91475 6.91475C7.02024 6.80926 7.16332 6.75 7.3125 6.75C7.46168 6.75 7.60476 6.80926 7.71025 6.91475C7.81574 7.02024 7.875 7.16332 7.875 7.3125ZM11.25 7.3125V11.8125C11.25 11.9617 11.1907 12.1048 11.0852 12.2102C10.9798 12.3157 10.8367 12.375 10.6875 12.375C10.5383 12.375 10.3952 12.3157 10.2898 12.2102C10.1843 12.1048 10.125 11.9617 10.125 11.8125V7.3125C10.125 7.16332 10.1843 7.02024 10.2898 6.91475C10.3952 6.80926 10.5383 6.75 10.6875 6.75C10.8367 6.75 10.9798 6.80926 11.0852 6.91475C11.1907 7.02024 11.25 7.16332 11.25 7.3125Z"
            fill="#F72B50"
          />
        </svg>
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="request-leave">
        <Modal.Header closeButton>
          <Modal.Title>Delete this Worksite</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div">
            Are You Sure You Want to delete worksite with name {i.title}
            <div className="button-models">
              <button className="model-button " onClick={handleClose}>
                Cancel
              </button>
              <button
                onClick={handleFormSubmitDelete}
                className="model-button model-button-delete"
              >
                Delete
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

// ? *************** SubComany Crud Models End ***************

// ! *************** Attendance Policy Crud Models Start ***************

const AttendancePolicy = ({ getAttPolicyName }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [formData, setFormData] = useState({
    name: "",
    ontime: "",
    ontime_in_time_check: "",
    ontime_in_time: "",
    ontime_out_time: "",
    ontime_duration: "",
    ontime_durations: "",

    late_mark: "",
    late_in_time_check: "",
    late_in_time: "",
    late_out_time: "",
    late_duration: "",
    late_durations: "",

    halfday: "",
    halfday_in_time_check: "",
    halfday_in_time: "",
    halfday_out_time: "",
    halfday_duration: "",
    halfday_durations: "",
    shortleave_duration: "",
    shortleave_durations: "",
    weeklyoff_durations: "",
    weeklyoff_duration: "",
    rectified_late_mark_count: "",
  });

  // !  ************** Validation start **************  ! //

  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = ["name", "rectified_late_mark_count"];
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    setInputState({
      ...inputState,
      [name]: type === "checkbox" ? "green" : value.trim() ? "green" : "",
    });

    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  // ?  ************** Validation End **************  ! //

  const [loading, setLoading] = useState(false); //loading logic
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); //loading logic
    // console.log(formData)

    if (validateForm()) {
      try {
        let res = await axios.post(`${BASE_URL}/wfm/attendancepolicy/`, formData);

        if (res.status === 200) {

          setShow(false);
          await getAttPolicyName();
        } else {
          alert(res);
        }
      } catch (err) {
        alert(err);
      } finally {
        setLoading(false); //loading logic
      }
    }
  };

  return (
    <>
      <button className="models-button model-add" title="Add Attendance Policy" onClick={handleShow}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <g clip-path="url(#clip0_1415_3177)">
            <path
              d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM17 13H13V17H11V13H7V11H11V7H13V11H17V13Z"
              fill="#2576BC"
            />
          </g>
          <defs>
            <clipPath id="clip0_1415_3177">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="request-leave">
        {/* <Modal.Header closeButton>
              <Modal.Title>Request Leave</Modal.Title>
            </Modal.Header> */}
        <Modal.Body>
          <div className="parent-div">
            <div className="bdy-div">
              {/* <h6 className="announce-heading">Birthday </h6> */}
              <div className="flex-column">
                <label htmlFor="name" className="form-labels">
                  Name<span className="required">*</span>
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  placeholder="Attendance Policy Name"
                  onChange={handleInputChange}
                  value={formData.name}
                  className={`form-input ${errors.name ? "error" : inputState.tile ? "success" : ""
                    }`}
                />
                {errors.name && (
                  <span className="error-message">{errors.name}</span>
                )}
              </div>

              <div className="flex-column">
                <label htmlFor="rectified_late_mark_count" className="form-labels">
                  Rectified Late Mark<span className="required">*</span>
                </label>
                <input
                  type="number"
                  id="rectified_late_mark_count"
                  name="rectified_late_mark_count"
                  placeholder="Number of rectified late Marks"
                  onChange={handleInputChange}
                  value={formData.rectified_late_mark_count}
                  className={`form-input ${errors.rectified_late_mark_count ? "error" : inputState.tile ? "success" : ""
                    }`}
                />
                {errors.rectified_late_mark_count && (
                  <span className="error-message">{errors.rectified_late_mark_count}</span>
                )}
              </div>

              {/* <div className="flex-row">

                <div className="flex-column">

                  <div className="flex-row policy-input">
                    <div className="flex-column multi-day">
                      <label htmlFor="rectified_late_mark_count" className="form-labels">
                        Rectified Late Marks <span className="required">*</span>
                      </label>
                      <input
                        type="text"
                        id="rectified_late_mark_count"
                        name="rectified_late_mark_count"
                        placeholder="10.5"
                        onChange={handleInputChange}
                        value={formData.rectified_late_mark_count}
                        className={`multi-day-input1 form-input-background`}
                      />
                    </div>
                  </div>
                </div>
              </div> */}

              <div className="flex-row">
                <label htmlFor="name" className="form-labels">
                  Ontime Check
                </label>
                <input
                  type="checkbox"
                  name="ontime"
                  onChange={handleInputChange}
                  value={formData.ontime}
                  className={`form-checkbox`}
                />
              </div>

              <div className="flex-row">

                <div className="flex-column">
                  <div className="flex-row">
                    <label htmlFor="name" className="form-labels">
                      Ontime In-Time Check
                    </label>
                    <input
                      type="checkbox"
                      name="ontime_in_time_check"
                      onChange={handleInputChange}
                      checked={formData.ontime_in_time_check}
                      className={`form-checkbox`}
                    />
                  </div>
                  <div className="flex-row policy-input">
                    <div className="flex-column multi-day">
                      <label className="form-labels">Ontime In Time</label>
                      <input
                        className="multi-day-input1 form-input-background"
                        type="time"
                        step="1"
                        name="ontime_in_time"
                        value={formData.ontime_in_time}
                        onChange={handleInputChange}
                      />
                    </div>
                    {/* <div className="flex-column multi-day">
                  <label className="form-labels">Ontime Out Time</label>
                  <input
                    className="multi-day-input1 form-input-background"
                    name="ontime_out_time"
                    type="time"
                    step="1"
                    value={formData.ontime_out_time}
                    onChange={handleInputChange}
                  />
                </div> */}
                  </div>
                </div>

                <div className="flex-column">
                  <div className="flex-row">
                    <label htmlFor="name" className="form-labels">
                      Ontime Work Durations
                    </label>
                    <input
                      type="checkbox"
                      name="ontime_durations"
                      onChange={handleInputChange}
                      value={formData.ontime_durations}
                      className={`form-checkbox`}
                    />
                  </div>

                  <div className="flex-column">
                    <label htmlFor="ontime_duration" className="form-labels">
                      Ontime Work Durations
                    </label>
                    <input
                      type="text"
                      id="ontime_duration"
                      name="ontime_duration"
                      placeholder="8.84"
                      onChange={handleInputChange}
                      value={formData.ontime_duration}
                      className={`multi-day-input1 form-input-background`}
                    />
                  </div>
                </div>


              </div>


              <div className="flex-row">
                <label htmlFor="late_mark" className="form-labels">
                  Late Check
                </label>
                <input
                  type="checkbox"
                  name="late_mark"
                  onChange={handleInputChange}
                  value={formData.late_mark}
                  className={`form-checkbox`}
                />
              </div>

              <div className="flex-row">
                <div className="flex-column">
                  <div className="flex-row">
                    <label htmlFor="name" className="form-labels">
                      Late In-Time Check
                    </label>
                    <input
                      type="checkbox"
                      name="late_in_time_check"
                      onChange={handleInputChange}
                      checked={formData.late_in_time_check}
                      className={`form-checkbox`}
                    />
                  </div>
                  <div className="flex-row policy-input">
                    <div className="flex-column multi-day">
                      <label className="form-labels">Late In Time</label>
                      <input
                        className="multi-day-input1 form-input-background"
                        type="time"
                        step="1"
                        name="late_in_time"
                        value={formData.late_in_time}
                        onChange={handleInputChange}
                      />
                    </div>
                    {/* <div className="flex-column multi-day">
                  <label className="form-labels">Late Out Time</label>
                  <input
                    className="multi-day-input1 form-input-background"
                    name="late_out_time"
                    type="time"
                    step="1"
                    value={formData.late_out_time}
                    onChange={handleInputChange}
                  />
                </div> */}
                  </div>
                </div>

                <div className="flex-column">
                  <div className="flex-row">
                    <label htmlFor="name" className="form-labels">
                      Late Work Durations
                    </label>
                    <input
                      type="checkbox"
                      name="late_durations"
                      onChange={handleInputChange}
                      value={formData.late_durations}
                      className={`form-checkbox`}
                    />
                  </div>

                  <div className="flex-column">
                    <label htmlFor="late_duration" className="form-labels">
                      Late Work Durations
                    </label>
                    <input
                      type="text"
                      id="late_duration"
                      name="late_duration"
                      placeholder="8.84"
                      onChange={handleInputChange}
                      value={formData.late_duration}
                      className={`multi-day-input1 form-input-background`}
                    />
                  </div>
                </div>
              </div>

              <div className="flex-row">
                <label htmlFor="name" className="form-labels">
                  Halfday Check
                </label>
                <input
                  type="checkbox"
                  name="halfday"
                  onChange={handleInputChange}
                  value={formData.halfday}
                  className={`form-checkbox`}
                />
              </div>

              <div className="flex-row">
                <div className="flex-column">
                  <div className="flex-row">
                    <label htmlFor="name" className="form-labels">
                      Halfday In-Time Check
                    </label>
                    <input
                      type="checkbox"
                      name="halfday_in_time_check"
                      onChange={handleInputChange}
                      checked={formData.halfday_in_time_check}
                      className={`form-checkbox`}
                    />
                  </div>

                  <div className="flex-row policy-input">
                    <div className="flex-column multi-day">
                      <label className="form-labels">Halfday In Time</label>
                      <input
                        className="multi-day-input1 form-input-background"
                        type="time"
                        step="1"
                        name="halfday_in_time"
                        value={formData.halfday_in_time}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  {/* <div className="flex-column multi-day">
                  <label className="form-labels">Halfday Out Time</label>
                  <input
                    className="multi-day-input1 form-input-background"
                    name="halfday_out_time"
                    type="time"
                    step="1"
                    value={formData.halfday_out_time}
                    onChange={handleInputChange}
                  />
                </div> */}
                </div>

                <div className="flex-column">
                  <div className="flex-row">
                    <label htmlFor="halfday_durations" className="form-labels">
                      Halfday Work Durations
                    </label>
                    <input
                      type="checkbox"
                      name="halfday_durations"
                      onChange={handleInputChange}
                      value={formData.halfday_durations}
                      className={`form-checkbox`}
                    />
                  </div>

                  <div className="flex-column">
                    <label htmlFor="halfday_duration" className="form-labels">
                      Halfday Work Durations
                    </label>
                    <input
                      type="text"
                      id="halfday_duration"
                      name="halfday_duration"
                      placeholder="8.84"
                      onChange={handleInputChange}
                      value={formData.halfday_duration}
                      className={`multi-day-input1 form-input-background`}
                    />
                  </div>
                </div>

              </div>

              <div className="flex-row">

                <div className="flex-column">
                  <div className="flex-row">
                    <label htmlFor="shortleave_durations" className="form-labels">
                      Short Leave Eligibility
                    </label>
                    <input
                      type="checkbox"
                      name="shortleave_durations"
                      onChange={handleInputChange}
                      value={formData.shortleave_durations}
                      className={`form-checkbox`}
                    />
                  </div>

                  <div className="flex-row policy-input">
                    <div className="flex-column multi-day">
                      <label htmlFor="shortleave_duration" className="form-labels">
                        Short Leave Eligibility Work Durations
                      </label>
                      <input
                        type="text"
                        id="shortleave_duration"
                        name="shortleave_duration"
                        placeholder="10.5"
                        onChange={handleInputChange}
                        value={formData.shortleave_duration}
                        className={`multi-day-input1 form-input-background`}
                      />
                    </div>
                  </div>
                </div>

                <div className="flex-column">
                  <div className="flex-row">
                    <label htmlFor="weeklyoff_durations" className="form-labels">
                      WeeklyOff Eligibility
                    </label>
                    <input
                      type="checkbox"
                      name="weeklyoff_durations"
                      onChange={handleInputChange}
                      checked={formData.weeklyoff_durations}
                      className={`form-checkbox`}
                    />
                  </div>
                  <div className="flex-row">
                    <div className="flex-column multi-day">
                      <label htmlFor="weeklyoff_duration" className="form-labels">
                        WeeklyOff Eligibility Work Durations
                      </label>
                      <input
                        type="text"
                        id="weeklyoff_duration"
                        name="weeklyoff_duration"
                        placeholder="10.5"
                        onChange={handleInputChange}
                        value={formData.weeklyoff_duration}
                        className={`multi-day-input1 form-input-background`}
                      />
                    </div>
                  </div>
                </div>
              </div>


              <div className="button-models">
                <button className="model-button" onClick={handleClose}>
                  Cancel
                </button>
                <button
                  //   type="submit"
                  className="model-button model-button-leave"
                  onClick={handleFormSubmit}
                  disabled={loading}
                >
                  Add
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

const AttendancePolicyUpdate = ({ i, getAttPolicyName }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [formData, setFormData] = useState({
    name: "",
    ontime: "",
    ontime_in_time_check: "",
    ontime_in_time: "",
    ontime_out_time: "",
    ontime_duration: "",
    ontime_durations: "",

    late_mark: "",
    late_in_time_check: "",
    late_in_time: "",
    late_out_time: "",
    late_duration: "",
    late_durations: "",

    halfday: "",
    halfday_in_time_check: "",
    halfday_in_time: "",
    halfday_out_time: "",
    halfday_duration: "",
    halfday_durations: "",
    shortleave_duration: "",
    shortleave_durations: "",
    weeklyoff_durations: "",
    weeklyoff_duration: "",
    rectified_late_mark_count: "",
  });


  useEffect(() => {
    setFormData(i);
  }, [i]);

  console.log(formData)

  // !  ************** Validation start **************  ! //

  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = ["name", "rectified_late_mark_count"];
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  // ?  ************** Validation End **************  ! //

  const handleFormSubmitPut = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      try {
        let res = await axios.put(
          `${BASE_URL}/wfm/attendancepolicy/${i.id}/`,
          formData
        );

        if (res.status === 200) {
          await getAttPolicyName();
          setShow(false);
        } else {
          alert(res);
        }
      } catch (err) {
        alert(err);
      }
    }
  };

  return (
    <>
      <button className="model-edit-button" title="Update Attendance Policy" onClick={handleShow}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M15.9834 5.1589L12.8412 2.01734C12.7367 1.91285 12.6127 1.82996 12.4762 1.7734C12.3397 1.71685 12.1933 1.68774 12.0456 1.68774C11.8978 1.68774 11.7515 1.71685 11.615 1.7734C11.4785 1.82996 11.3545 1.91285 11.25 2.01734L2.57977 10.6876C2.47485 10.7917 2.39167 10.9156 2.33506 11.0521C2.27844 11.1886 2.24953 11.335 2.25001 11.4828V14.6251C2.25001 14.9234 2.36853 15.2096 2.57951 15.4206C2.79049 15.6315 3.07664 15.7501 3.37501 15.7501H15.1875C15.3367 15.7501 15.4798 15.6908 15.5853 15.5853C15.6907 15.4798 15.75 15.3368 15.75 15.1876C15.75 15.0384 15.6907 14.8953 15.5853 14.7898C15.4798 14.6843 15.3367 14.6251 15.1875 14.6251H8.10844L15.9834 6.75007C16.0879 6.6456 16.1708 6.52157 16.2274 6.38507C16.2839 6.24856 16.313 6.10225 16.313 5.95449C16.313 5.80673 16.2839 5.66042 16.2274 5.52391C16.1708 5.3874 16.0879 5.26337 15.9834 5.1589ZM6.51727 14.6251H3.37501V11.4828L9.56251 5.29531L12.7048 8.43757L6.51727 14.6251ZM13.5 7.64234L10.3584 4.50007L12.0459 2.81257L15.1875 5.95484L13.5 7.64234Z"
            fill="#145650"
          />
        </svg>
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="request-leave">
        <Modal.Header closeButton>
          <Modal.Title>Update Policy {i.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div">
            <div className="bdy-div">
              {/* <h6 className="announce-heading">Birthday </h6> */}
              <div className="flex-column">
                <label htmlFor="name" className="form-labels">
                  Name<span className="required">*</span>
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  placeholder="Attendance Policy Name"
                  onChange={handleInputChange}
                  value={formData.name}
                  className={`form-input ${errors.name ? "error" : inputState.tile ? "success" : ""
                    }`}
                />
                {errors.name && (
                  <span className="error-message">{errors.name}</span>
                )}
              </div>

              <div className="flex-column">
                <label htmlFor="rectified_late_mark_count" className="form-labels">
                  Rectified Late Mark<span className="required">*</span>
                </label>
                <input
                  type="number"
                  id="rectified_late_mark_count"
                  name="rectified_late_mark_count"
                  placeholder="Number of rectified late Marks"
                  onChange={handleInputChange}
                  value={formData.rectified_late_mark_count}
                  className={`form-input ${errors.rectified_late_mark_count ? "error" : inputState.tile ? "success" : ""
                    }`}
                />
                {errors.rectified_late_mark_count && (
                  <span className="error-message">{errors.rectified_late_mark_count}</span>
                )}
              </div>
              <div className="flex-row">
                <label htmlFor="name" className="form-labels">
                  Ontime Check
                </label>
                <input
                  type="checkbox"
                  name="ontime"
                  onChange={handleInputChange}
                  checked={formData.ontime}
                  className={`form-checkbox`}
                />
              </div>

              <div className="flex-row">

                <div className="flex-column">
                  <div className="flex-row">
                    <label htmlFor="name" className="form-labels">
                      Ontime In-Time Check
                    </label>
                    <input
                      type="checkbox"
                      name="ontime_in_time_check"
                      onChange={handleInputChange}
                      checked={formData.ontime_in_time_check}
                      className={`form-checkbox`}
                    />
                  </div>

                  <div className="flex-row policy-input">
                    <div className="flex-column multi-day">
                      <label className="form-labels">Ontime In Time</label>
                      <input
                        className="multi-day-input1 form-input-background"
                        type="time"
                        step="1"
                        name="ontime_in_time"
                        value={formData.ontime_in_time}
                        onChange={handleInputChange}
                      />
                    </div>
                    {/* <div className="flex-column multi-day">
                  <label className="form-labels">Ontime Out Time</label>
                  <input
                    className="multi-day-input1 form-input-background"
                    name="ontime_out_time"
                    type="time"
                    step="1"
                    value={formData.ontime_out_time}
                    onChange={handleInputChange}
                  />
                </div> */}
                  </div>
                </div>

                <div className="flex-column">
                  <div className="flex-row">
                    <label htmlFor="name" className="form-labels">
                      Ontime Work Durations
                    </label>
                    <input
                      type="checkbox"
                      name="ontime_durations"
                      onChange={handleInputChange}
                      checked={formData.ontime_durations}
                      className={`form-checkbox`}
                    />
                  </div>

                  <div className="flex-column">
                    <label htmlFor="ontime_duration" className="form-labels">
                      Ontime Work Durations
                    </label>
                    <input
                      type="text"
                      id="ontime_duration"
                      name="ontime_duration"
                      placeholder="8.84"
                      onChange={handleInputChange}
                      value={formData.ontime_duration}
                      className={`multi-day-input1 form-input-background`}
                    />
                  </div>
                </div>

              </div>

              <div className="flex-row">
                <label htmlFor="late_mark" className="form-labels">
                  Late Check
                </label>
                <input
                  type="checkbox"
                  name="late_mark"
                  onChange={handleInputChange}
                  checked={formData.late_mark}
                  className={`form-checkbox`}
                />
              </div>

              <div className="flex-row">
                <div className="flex-column">
                  <div className="flex-row">
                    <label htmlFor="name" className="form-labels">
                      Late In-Time Check
                    </label>
                    <input
                      type="checkbox"
                      name="late_in_time_check"
                      onChange={handleInputChange}
                      checked={formData.late_in_time_check}
                      className={`form-checkbox`}
                    />
                  </div>

                  <div className="flex-row policy-input">
                    <div className="flex-column multi-day">
                      <label className="form-labels">Late In Time</label>
                      <input
                        className="multi-day-input1 form-input-background"
                        type="time"
                        step="1"
                        name="late_in_time"
                        value={formData.late_in_time}
                        onChange={handleInputChange}
                      />
                    </div>
                    {/* <div className="flex-column multi-day">
                  <label className="form-labels">Late Out Time</label>
                  <input
                    className="multi-day-input1 form-input-background"
                    name="late_out_time"
                    type="time"
                    step="1"
                    value={formData.late_out_time}
                    onChange={handleInputChange}
                  />
                </div> */}
                  </div>
                </div>

                <div className="flex-column">
                  <div className="flex-row">
                    <label htmlFor="name" className="form-labels">
                      Late Work Durations
                    </label>
                    <input
                      type="checkbox"
                      name="late_durations"
                      onChange={handleInputChange}
                      checked={formData.late_durations}
                      className={`form-checkbox`}
                    />
                  </div>

                  <div className="flex-column">
                    <label htmlFor="late_duration" className="form-labels">
                      Late Work Durations
                    </label>
                    <input
                      type="text"
                      id="late_duration"
                      name="late_duration"
                      placeholder="8.84"
                      onChange={handleInputChange}
                      value={formData.late_duration}
                      className={`multi-day-input1 form-input-background`}
                    />
                  </div>
                </div>
              </div>

              <div className="flex-row">
                <label htmlFor="name" className="form-labels">
                  Halfday Check
                </label>
                <input
                  type="checkbox"
                  name="halfday"
                  onChange={handleInputChange}
                  checked={formData.halfday}
                  className={`form-checkbox`}
                />
              </div>

              <div className="flex-row">
                <div className="flex-column">
                  <div className="flex-row">
                    <label htmlFor="name" className="form-labels">
                      Halfday In-Time Check
                    </label>
                    <input
                      type="checkbox"
                      name="halfday_in_time_check"
                      onChange={handleInputChange}
                      checked={formData.halfday_in_time_check}
                      className={`form-checkbox`}
                    />
                  </div>

                  <div className="flex-row policy-input">
                    <div className="flex-column multi-day">
                      <label className="form-labels">Halfday In Time</label>
                      <input
                        className="multi-day-input1 form-input-background"
                        type="time"
                        step="1"
                        name="halfday_in_time"
                        value={formData.halfday_in_time}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  {/* <div className="flex-column multi-day">
                  <label className="form-labels">Halfday Out Time</label>
                  <input
                    className="multi-day-input1 form-input-background"
                    name="halfday_out_time"
                    type="time"
                    step="1"
                    value={formData.halfday_out_time}
                    onChange={handleInputChange}
                  />
                </div> */}
                </div>

                <div className="flex-column">
                  <div className="flex-row">
                    <label htmlFor="name" className="form-labels">
                      Halfday Work Durations
                    </label>
                    <input
                      type="checkbox"
                      name="halfday_durations"
                      onChange={handleInputChange}
                      checked={formData.halfday_durations}
                      className={`form-checkbox`}
                    />
                  </div>

                  <div className="flex-column">
                    <label htmlFor="halfday_duration" className="form-labels">
                      Halfday Work Durations
                    </label>
                    <input
                      type="text"
                      id="halfday_duration"
                      name="halfday_duration"
                      placeholder="8.84"
                      onChange={handleInputChange}
                      value={formData.halfday_duration}
                      className={`multi-day-input1 form-input-background`}
                    />
                  </div>
                </div>

              </div>

              <div className="flex-row">

                <div className="flex-column">
                  <div className="flex-row">
                    <label htmlFor="shortleave_durations" className="form-labels">
                      Short Leave Eligibility
                    </label>
                    <input
                      type="checkbox"
                      name="shortleave_durations"
                      onChange={handleInputChange}
                      checked={formData.shortleave_durations}
                      className={`form-checkbox`}
                    />
                  </div>

                  <div className="flex-row policy-input">
                    <div className="flex-column multi-day">
                      <label htmlFor="shortleave_duration" className="form-labels">
                        Short Leave Eligibility Work Durations
                      </label>
                      <input
                        type="text"
                        id="shortleave_duration"
                        name="shortleave_duration"
                        placeholder="10.5"
                        onChange={handleInputChange}
                        value={formData.shortleave_duration}
                        className={`multi-day-input1 form-input-background`}
                      />
                    </div>
                  </div>
                </div>

                <div className="flex-column">
                  <div className="flex-row">
                    <label htmlFor="weeklyoff_durations" className="form-labels">
                      WeeklyOff Eligibility
                    </label>
                    <input
                      type="checkbox"
                      name="weeklyoff_durations"
                      onChange={handleInputChange}
                      checked={formData.weeklyoff_durations}
                      className={`form-checkbox`}
                    />
                  </div>
                  <div className="flex-row">
                    <div className="flex-column multi-day">
                      <label htmlFor="weeklyoff_duration" className="form-labels">
                        WeeklyOff Eligibility Work Durations
                      </label>
                      <input
                        type="text"
                        id="weeklyoff_duration"
                        name="weeklyoff_duration"
                        placeholder="10.5"
                        onChange={handleInputChange}
                        value={formData.weeklyoff_duration}
                        className={`multi-day-input1 form-input-background`}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="button-models">
                <button className="model-button" onClick={handleClose}>
                  Cancel
                </button>
                <button
                  //   type="submit"
                  className="model-button model-button-leave"
                  onClick={handleFormSubmitPut}
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal >
    </>
  );
};

const AttendancePolicyDelete = ({ i, getAttPolicyName }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleFormSubmitDelete = async (e) => {
    e.preventDefault();

    try {
      let res = await axios.delete(`${BASE_URL}/wfm/attendancepolicy/${i.id}/`);

      if (res.status === 200) {
        await getAttPolicyName();
        setShow(false);
      } else {
        alert(res);
      }
    } catch (err) {
      alert(err);
    }
  };

  return (
    <>
      <button className="model-delete-button" title="Delete Aattendance Policy" onClick={handleShow}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M15.1875 3.375H12.375V2.8125C12.375 2.36495 12.1972 1.93572 11.8807 1.61926C11.5643 1.30279 11.1351 1.125 10.6875 1.125H7.3125C6.86495 1.125 6.43572 1.30279 6.11926 1.61926C5.80279 1.93572 5.625 2.36495 5.625 2.8125V3.375H2.8125C2.66332 3.375 2.52024 3.43426 2.41475 3.53975C2.30926 3.64524 2.25 3.78832 2.25 3.9375C2.25 4.08668 2.30926 4.22976 2.41475 4.33525C2.52024 4.44074 2.66332 4.5 2.8125 4.5H3.375V14.625C3.375 14.9234 3.49353 15.2095 3.7045 15.4205C3.91548 15.6315 4.20163 15.75 4.5 15.75H13.5C13.7984 15.75 14.0845 15.6315 14.2955 15.4205C14.5065 15.2095 14.625 14.9234 14.625 14.625V4.5H15.1875C15.3367 4.5 15.4798 4.44074 15.5852 4.33525C15.6907 4.22976 15.75 4.08668 15.75 3.9375C15.75 3.78832 15.6907 3.64524 15.5852 3.53975C15.4798 3.43426 15.3367 3.375 15.1875 3.375ZM6.75 2.8125C6.75 2.66332 6.80926 2.52024 6.91475 2.41475C7.02024 2.30926 7.16332 2.25 7.3125 2.25H10.6875C10.8367 2.25 10.9798 2.30926 11.0852 2.41475C11.1907 2.52024 11.25 2.66332 11.25 2.8125V3.375H6.75V2.8125ZM13.5 14.625H4.5V4.5H13.5V14.625ZM7.875 7.3125V11.8125C7.875 11.9617 7.81574 12.1048 7.71025 12.2102C7.60476 12.3157 7.46168 12.375 7.3125 12.375C7.16332 12.375 7.02024 12.3157 6.91475 12.2102C6.80926 12.1048 6.75 11.9617 6.75 11.8125V7.3125C6.75 7.16332 6.80926 7.02024 6.91475 6.91475C7.02024 6.80926 7.16332 6.75 7.3125 6.75C7.46168 6.75 7.60476 6.80926 7.71025 6.91475C7.81574 7.02024 7.875 7.16332 7.875 7.3125ZM11.25 7.3125V11.8125C11.25 11.9617 11.1907 12.1048 11.0852 12.2102C10.9798 12.3157 10.8367 12.375 10.6875 12.375C10.5383 12.375 10.3952 12.3157 10.2898 12.2102C10.1843 12.1048 10.125 11.9617 10.125 11.8125V7.3125C10.125 7.16332 10.1843 7.02024 10.2898 6.91475C10.3952 6.80926 10.5383 6.75 10.6875 6.75C10.8367 6.75 10.9798 6.80926 11.0852 6.91475C11.1907 7.02024 11.25 7.16332 11.25 7.3125Z"
            fill="#F72B50"
          />
        </svg>
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="request-leave">
        <Modal.Header closeButton>
          <Modal.Title>Delete Attendance Policy with {i.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div">
            Are You Sure You Want to Attendance Policy with {i.name}
            <div className="button-models">
              <button className="model-button " onClick={handleClose}>
                Cancel
              </button>
              <button
                onClick={handleFormSubmitDelete}
                className="model-button model-button-delete"
              >
                Delete
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

// ? *************** Attendance Policy Crud Models End ***************

// ! *************** WeeklyOffPolicy Crud Models Start ***************

const WeeklyOffPolicy = ({ getWeeklyOffPolicyName }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [formData, setFormData] = useState({
    name: "",
    off_days: "",

  });

  // !  ************** Validation start **************  ! //

  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = ["name"];
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    setInputState({
      ...inputState,
      [name]: type === "checkbox" ? "green" : value.trim() ? "green" : "",
    });

    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  // ?  ************** Validation End **************  ! //

  const [loading, setLoading] = useState(false); //loading logic
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); //loading logic
    // console.log(formData)

    if (validateForm()) {
      try {
        let res = await axios.post(`${BASE_URL}/wfm/weeklyoffPolicy/`, formData);

        if (res.status === 200) {

          setShow(false);
          await getWeeklyOffPolicyName();
        } else {
          alert(res);
        }
      } catch (err) {
        alert(err);
      } finally {
        setLoading(false); //loading logic
      }
    }
  };

  // const optionss = allemployeedata
  //   .sort((a, b) => parseInt(a.emp_code) - parseInt(b.emp_code))
  //   .map((e) => ({
  //     value: e.emp_code,
  //     label: `${e.emp_code} - ${e.name}`,
  //   }));
  const options = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday"
  ];

  const weekdays = [
    { code: 1, name: "Sunday" },
    { code: 2, name: "Monday" },
    { code: 3, name: "Tuesday" },
    { code: 4, name: "Wednesday" },
    { code: 5, name: "Thursday" },
    { code: 6, name: "Friday" },
    { code: 7, name: "Saturday" }
  ];

  const weekdaysOptions = weekdays.map(day => ({
    value: day.name,
    label: `${day.name}`
  }));

  const handleChangeDays = (selected) => {
    const offDays = selected.length > 0 ? selected.map((item) => item.value).join(", ") : "";
    setFormData((prevState) => ({
      ...prevState,
      off_days: offDays,
    }));
  };




  return (
    <>
      <button className="models-button model-add" title="Add WeekOff Policy" onClick={handleShow}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <g clip-path="url(#clip0_1415_3177)">
            <path
              d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM17 13H13V17H11V13H7V11H11V7H13V11H17V13Z"
              fill="#2576BC"
            />
          </g>
          <defs>
            <clipPath id="clip0_1415_3177">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="request-leave">
        {/* <Modal.Header closeButton>
              <Modal.Title>Request Leave</Modal.Title>
            </Modal.Header> */}
        <Modal.Body>
          <div className="parent-div">
            <div className="bdy-div">
              {/* <h6 className="announce-heading">Birthday </h6> */}
              <div className="flex-column">
                <label htmlFor="name" className="form-labels">
                  Name<span className="required">*</span>
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  placeholder="Attendance Policy Name"
                  onChange={handleInputChange}
                  value={formData.name}
                  className={`form-input ${errors.name ? "error" : inputState.tile ? "success" : ""
                    }`}
                />
                {errors.name && (
                  <span className="error-message">{errors.name}</span>
                )}
              </div>


              <div className="flex-column">
                <label htmlFor="remarks" className="form-labels announce-date">
                  Days to be Weekly Off
                </label>

                <Select
                  options={weekdaysOptions}
                  isMulti
                  onChange={handleChangeDays}
                />
                {errors.remarks && (
                  <span className="error-message">{errors.remarks}</span>
                )}
              </div>



              <div className="button-models">
                <button className="model-button" onClick={handleClose}>
                  Cancel
                </button>
                <button
                  //   type="submit"
                  className="model-button model-button-leave"
                  onClick={handleFormSubmit}
                  disabled={loading}
                >
                  Add
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

const WeeklyOffPolicyUpdate = ({ i, getWeeklyOffPolicyName }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setFormData(i);
    setShow(true);
    // console.log("formData -");
    // console.log(formData);
    // console.log("formData x");
    // console.log("I -");
    // console.log(i);
    // console.log("I x");
  };

  const [formData, setFormData] = useState({
    name: "",
    off_days: "",
  });

  const [selectedDays, setSelectedDays] = useState([]);

  // useEffect(() => {
  //   setFormData(i);
  // }, [i]);

  console.log(formData)

  // !  ************** Validation start **************  ! //

  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = ["name"];
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  // ?  ************** Validation End **************  ! //

  const handleFormSubmitPut = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      try {
        let res = await axios.put(
          `${BASE_URL}/wfm/weeklyoffPolicy/${i.id}/`,
          formData
        );

        if (res.status === 200) {
          await getWeeklyOffPolicyName();
          setShow(false);
        } else {
          alert(res);
        }
      } catch (err) {
        alert(err);
      }
    }
  };
  // const weekdays = [
  //   { code: 1, name: "Sunday" },
  //   { code: 2, name: "Monday" },
  //   { code: 3, name: "Tuesday" },
  //   { code: 4, name: "Wednesday" },
  //   { code: 5, name: "Thursday" },
  //   { code: 6, name: "Friday" },
  //   { code: 7, name: "Saturday" }
  // ];

  // const weekdaysOptions = weekdays.map(day => ({
  //   value: day.name,
  //   label: `${day.name}`
  // }));

  // const handleChangeDays = (selected) => {
  //   const offDays = selected.length > 0 ? selected.map((item) => item.value).join(", ") : "";
  //   setFormData((prevState) => ({
  //     ...prevState,
  //     off_days: offDays,
  //   }));
  //   setSelectedDays(selected);

  // };

  // useEffect(() => {
  //   // Assuming formData.off_type contains the value "Friday, Saturday, Sunday"
  //   const selectedDaysArray = formData.off_type?.split(", ").map((day) => ({
  //     value: day,
  //     label: day,
  //   }));
  //   setSelectedDays(selectedDaysArray);
  // }, [formData.off_type]);


  const weekdaysOptions = [
    { value: "Sunday", label: "Sunday" },
    { value: "Monday", label: "Monday" },
    { value: "Tuesday", label: "Tuesday" },
    { value: "Wednesday", label: "Wednesday" },
    { value: "Thursday", label: "Thursday" },
    { value: "Friday", label: "Friday" },
    { value: "Saturday", label: "Saturday" }
  ];

  const handleChangeDays = (selected) => {
    const offDays = selected.map((item) => item.value).join(", ");
    setFormData((prevState) => ({
      ...prevState,
      off_days: offDays,
    }));
  };

  useEffect(() => {
    if (formData.off_days) {
      const selectedDaysArray = formData.off_days.split(", ").map((day) => ({
        value: day,
        label: day,
      }));
      setSelectedDays(selectedDaysArray);
    }
  }, [formData.off_days]);

  return (
    <>
      <button className="model-edit-button" title="Update WeekOff Policy" onClick={handleShow}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M15.9834 5.1589L12.8412 2.01734C12.7367 1.91285 12.6127 1.82996 12.4762 1.7734C12.3397 1.71685 12.1933 1.68774 12.0456 1.68774C11.8978 1.68774 11.7515 1.71685 11.615 1.7734C11.4785 1.82996 11.3545 1.91285 11.25 2.01734L2.57977 10.6876C2.47485 10.7917 2.39167 10.9156 2.33506 11.0521C2.27844 11.1886 2.24953 11.335 2.25001 11.4828V14.6251C2.25001 14.9234 2.36853 15.2096 2.57951 15.4206C2.79049 15.6315 3.07664 15.7501 3.37501 15.7501H15.1875C15.3367 15.7501 15.4798 15.6908 15.5853 15.5853C15.6907 15.4798 15.75 15.3368 15.75 15.1876C15.75 15.0384 15.6907 14.8953 15.5853 14.7898C15.4798 14.6843 15.3367 14.6251 15.1875 14.6251H8.10844L15.9834 6.75007C16.0879 6.6456 16.1708 6.52157 16.2274 6.38507C16.2839 6.24856 16.313 6.10225 16.313 5.95449C16.313 5.80673 16.2839 5.66042 16.2274 5.52391C16.1708 5.3874 16.0879 5.26337 15.9834 5.1589ZM6.51727 14.6251H3.37501V11.4828L9.56251 5.29531L12.7048 8.43757L6.51727 14.6251ZM13.5 7.64234L10.3584 4.50007L12.0459 2.81257L15.1875 5.95484L13.5 7.64234Z"
            fill="#145650"
          />
        </svg>
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="request-leave">
        <Modal.Header closeButton>
          <Modal.Title>Update Policy {i.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div">
            <div className="bdy-div">
              {/* <h6 className="announce-heading">Birthday </h6> */}
              <div className="flex-column">
                <label htmlFor="name" className="form-labels">
                  Name<span className="required">*</span>
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  placeholder="Attendance Policy Name"
                  onChange={handleInputChange}
                  value={formData.name}
                  className={`form-input ${errors.name ? "error" : inputState.tile ? "success" : ""
                    }`}
                />
                {errors.name && (
                  <span className="error-message">{errors.name}</span>
                )}
              </div>


              <div className="flex-column">
                <label htmlFor="off_days" className="form-labels announce-date">
                  Days to be Weekly Off
                </label>

                <Select
                  options={weekdaysOptions}
                  isMulti
                  value={selectedDays}
                  onChange={handleChangeDays}
                />
                {errors.remarks && (
                  <span className="error-message">{errors.remarks}</span>
                )}
              </div>



              <div className="button-models">
                <button className="model-button" onClick={handleClose}>
                  Cancel
                </button>
                <button
                  //   type="submit"
                  className="model-button model-button-leave"
                  onClick={handleFormSubmitPut}
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal >
    </>
  );
};

const WeeklyOffPolicyDelete = ({ i, getWeeklyOffPolicyName }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleFormSubmitDelete = async (e) => {
    e.preventDefault();

    try {
      let res = await axios.delete(`${BASE_URL}/wfm/weeklyoffPolicy/${i.id}/`);

      if (res.status === 200) {
        await getWeeklyOffPolicyName();
        setShow(false);
      } else {
        alert(res);
      }
    } catch (err) {
      alert(err);
    }
  };

  return (
    <>
      <button className="model-delete-button" title="Delete WeekOff Policy" onClick={handleShow}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M15.1875 3.375H12.375V2.8125C12.375 2.36495 12.1972 1.93572 11.8807 1.61926C11.5643 1.30279 11.1351 1.125 10.6875 1.125H7.3125C6.86495 1.125 6.43572 1.30279 6.11926 1.61926C5.80279 1.93572 5.625 2.36495 5.625 2.8125V3.375H2.8125C2.66332 3.375 2.52024 3.43426 2.41475 3.53975C2.30926 3.64524 2.25 3.78832 2.25 3.9375C2.25 4.08668 2.30926 4.22976 2.41475 4.33525C2.52024 4.44074 2.66332 4.5 2.8125 4.5H3.375V14.625C3.375 14.9234 3.49353 15.2095 3.7045 15.4205C3.91548 15.6315 4.20163 15.75 4.5 15.75H13.5C13.7984 15.75 14.0845 15.6315 14.2955 15.4205C14.5065 15.2095 14.625 14.9234 14.625 14.625V4.5H15.1875C15.3367 4.5 15.4798 4.44074 15.5852 4.33525C15.6907 4.22976 15.75 4.08668 15.75 3.9375C15.75 3.78832 15.6907 3.64524 15.5852 3.53975C15.4798 3.43426 15.3367 3.375 15.1875 3.375ZM6.75 2.8125C6.75 2.66332 6.80926 2.52024 6.91475 2.41475C7.02024 2.30926 7.16332 2.25 7.3125 2.25H10.6875C10.8367 2.25 10.9798 2.30926 11.0852 2.41475C11.1907 2.52024 11.25 2.66332 11.25 2.8125V3.375H6.75V2.8125ZM13.5 14.625H4.5V4.5H13.5V14.625ZM7.875 7.3125V11.8125C7.875 11.9617 7.81574 12.1048 7.71025 12.2102C7.60476 12.3157 7.46168 12.375 7.3125 12.375C7.16332 12.375 7.02024 12.3157 6.91475 12.2102C6.80926 12.1048 6.75 11.9617 6.75 11.8125V7.3125C6.75 7.16332 6.80926 7.02024 6.91475 6.91475C7.02024 6.80926 7.16332 6.75 7.3125 6.75C7.46168 6.75 7.60476 6.80926 7.71025 6.91475C7.81574 7.02024 7.875 7.16332 7.875 7.3125ZM11.25 7.3125V11.8125C11.25 11.9617 11.1907 12.1048 11.0852 12.2102C10.9798 12.3157 10.8367 12.375 10.6875 12.375C10.5383 12.375 10.3952 12.3157 10.2898 12.2102C10.1843 12.1048 10.125 11.9617 10.125 11.8125V7.3125C10.125 7.16332 10.1843 7.02024 10.2898 6.91475C10.3952 6.80926 10.5383 6.75 10.6875 6.75C10.8367 6.75 10.9798 6.80926 11.0852 6.91475C11.1907 7.02024 11.25 7.16332 11.25 7.3125Z"
            fill="#F72B50"
          />
        </svg>
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="request-leave">
        <Modal.Header closeButton>
          <Modal.Title>Delete Weeklyoff Policy : {i.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div">
            Are You Sure You Want to Delete Weekly Off Policy : {i.name}
            <div className="button-models">
              <button className="model-button " onClick={handleClose}>
                Cancel
              </button>
              <button
                onClick={handleFormSubmitDelete}
                className="model-button model-button-delete"
              >
                Delete
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};


// ! *************** Calender Crud Models Start ***************
const CalenderModel = ({ i, getcalender }) => {

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [formData, setFormData] = useState({
    date: "",
    descritpion: "",
    offtype: "",
  });



  //******************Valedation Start********************* */

  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = ["date", "descritpion", "offtype"];
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  //******************Valedation End*********************** */

  const [loading, setLoading] = useState(false); //loading logic
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); //loading logic

    if (validateForm()) {
      try {
        let res = await axios.post(`${BASE_URL}/wfm/calander/`, formData);

        if (res.status === 200) {
          await getcalender();
          setShow(false);
        } else {
          alert(res);
        }
      } catch (err) {
        alert(err);
      } finally {
        setLoading(false); //loading logic
      }
    }
  };
  return (
    <>
      <button className="models-button model-add" onClick={handleShow}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <g clip-path="url(#clip0_1415_3177)">
            <path
              d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM17 13H13V17H11V13H7V11H11V7H13V11H17V13Z"
              fill="#2576BC"
            />
          </g>
          <defs>
            <clipPath id="clip0_1415_3177">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="request-leave">
        <Modal.Header closeButton>
          <Modal.Title>Calender</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div">
            <div className="bdy-div">
              {/* <h6 className="announce-heading">Birthday </h6> */}
              <div className="flex-column">
                <label htmlFor="date" className="form-labels">
                  Date
                </label>
                <input
                  type="date"
                  id="date"
                  name="date"
                  autoComplete="off"
                  onChange={handleInputChange}
                  value={formData.date}
                  className={`form-input form-input-background ${errors.date ? "error" : inputState.date ? "success" : ""
                    }`}
                />
                {errors.date && <span className="error-message">{errors.date}</span>}
              </div>
              <div className="flex-column form-group-select">
                <label className="form-labels">
                  Type<span className="required">*</span>
                </label>
                <br />
                <select
                  id="offtype"
                  name="offtype"
                  value={formData.offtype}
                  onChange={handleInputChange}
                  className={`form-input form-group-selection form-input-background ${errors.offtype ? "error" : inputState.offtype ? "success" : ""
                    }`}
                >
                  <option value="">Offtype</option>
                  {/* <option value="weeklyoff">Weekly off</option> */}
                  <option value="holiday">Holiday</option>
                </select>
                <div className="form-group-selection-arrow">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="25"
                    fill="currentColor"
                    class="bi bi-caret-down-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                  </svg>
                </div>
                <div>
                  {errors.offtype && (
                    <span className="error-message">{errors.offtype}</span>
                  )}
                </div>
              </div>
              <div className="flex-column">
                <label htmlFor="descritpion" className="form-labels">
                  Description<span className="required">*</span>
                </label>
                <input
                  type="descritpion"
                  id="descritpion"
                  name="descritpion"
                  placeholder="Description ..."
                  onChange={handleInputChange}
                  value={formData.descritpion}
                  className={`form-input ${errors.descritpion ? "error" : inputState.tile ? "success" : ""
                    }`}
                />
                {errors.descritpion && (
                  <span className="error-message">{errors.descritpion}</span>
                )}
              </div>


              <div className="button-models">
                <button className="model-button" onClick={handleClose}>
                  Cancel
                </button>
                <button
                  //   type="submit"
                  className="model-button model-button-leave"
                  onClick={handleFormSubmit}
                  disabled={loading}
                >
                  Add
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

const CalenderUpdate = ({ i, getcalender }) => {

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [formData, setFormData] = useState({
    date: "",
    descritpion: "",
    offtype: "",
  });
  console.log(formData)

  useEffect(() => {
    setFormData(i);
  }, [i]);

  //******************Valedation Start********************* */

  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = ["date", "descritpion", "offtype"];
    console.log(requiredFields)
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  //******************Valedation End*********************** */

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      try {
        let res = await axios.put(
          `${BASE_URL}/wfm/calander/${i.date}/`,
          formData);

        if (res.status === 200) {
          await getcalender();
          setShow(false);
        } else {
          alert(res);
        }
      } catch (err) {
        alert(err);
      }
    }
  };

  return (
    <>
      <button className="model-edit-button" onClick={handleShow}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M15.9834 5.1589L12.8412 2.01734C12.7367 1.91285 12.6127 1.82996 12.4762 1.7734C12.3397 1.71685 12.1933 1.68774 12.0456 1.68774C11.8978 1.68774 11.7515 1.71685 11.615 1.7734C11.4785 1.82996 11.3545 1.91285 11.25 2.01734L2.57977 10.6876C2.47485 10.7917 2.39167 10.9156 2.33506 11.0521C2.27844 11.1886 2.24953 11.335 2.25001 11.4828V14.6251C2.25001 14.9234 2.36853 15.2096 2.57951 15.4206C2.79049 15.6315 3.07664 15.7501 3.37501 15.7501H15.1875C15.3367 15.7501 15.4798 15.6908 15.5853 15.5853C15.6907 15.4798 15.75 15.3368 15.75 15.1876C15.75 15.0384 15.6907 14.8953 15.5853 14.7898C15.4798 14.6843 15.3367 14.6251 15.1875 14.6251H8.10844L15.9834 6.75007C16.0879 6.6456 16.1708 6.52157 16.2274 6.38507C16.2839 6.24856 16.313 6.10225 16.313 5.95449C16.313 5.80673 16.2839 5.66042 16.2274 5.52391C16.1708 5.3874 16.0879 5.26337 15.9834 5.1589ZM6.51727 14.6251H3.37501V11.4828L9.56251 5.29531L12.7048 8.43757L6.51727 14.6251ZM13.5 7.64234L10.3584 4.50007L12.0459 2.81257L15.1875 5.95484L13.5 7.64234Z"
            fill="#145650"
          />
        </svg>
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="request-leave">
        <Modal.Header closeButton>
          <Modal.Title>Calender</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div">
            <div className="bdy-div">
              {/* <h6 className="announce-heading">Birthday </h6> */}
              <div className="flex-column">
                <label htmlFor="date" className="form-labels">
                  Date<span className="required">*</span>
                </label>
                <input
                  type="date"
                  id="date"
                  name="date"
                  placeholder="Date"
                  onChange={handleInputChange}
                  value={formData.date}
                  className={`form-input ${errors.date ? "error" : inputState.tile ? "success" : ""
                    }`}
                />
                {errors.date && (
                  <span className="error-message">{errors.date}</span>
                )}
              </div>

              <div className="flex-column form-group-select">
                <label className="form-labels">
                  Type<span className="required">*</span>
                </label>
                <br />
                <select
                  id="offtype"
                  name="offtype"
                  value={formData.offtype}
                  onChange={handleInputChange}
                  className={`form-input form-group-selection form-input-background ${errors.offtype ? "error" : inputState.offtype ? "success" : ""
                    }`}
                >
                  <option value="">Offtype</option>
                  <option value="weeklyoff">Weekly off</option>
                  <option value="holiday">Holiday</option>
                </select>
                <div className="form-group-selection-arrow">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="25"
                    fill="currentColor"
                    class="bi bi-caret-down-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                  </svg>
                </div>
                <div>
                  {errors.offtype && (
                    <span className="error-message">{errors.offtype}</span>
                  )}
                </div>
              </div>

              <div className="flex-column">
                <label htmlFor="descritpion" className="form-labels">
                  Description<span className="required">*</span>
                </label>
                <input
                  type="text"
                  id="descritpion"
                  name="descritpion"
                  placeholder="Description..."
                  onChange={handleInputChange}
                  value={formData.descritpion}
                  className={`form-input ${errors.descritpion ? "error" : inputState.descritpion ? "success" : ""
                    }`}
                />
                {errors.descritpion && (
                  <span className="error-message">{errors.descritpion}</span>
                )}
              </div>

              <div className="button-models">
                <button className="model-button" onClick={handleClose}>
                  Cancel
                </button>
                <button
                  //   type="submit"
                  className="model-button model-button-leave"
                  onClick={handleFormSubmit}
                >
                  Add
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

const CalenderDelete = ({ i, getcalender }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleFormSubmitDelete = async (e) => {
    e.preventDefault();

    try {
      let res = await axios.delete(`${BASE_URL}/wfm/calander/${i.date}/`);

      if (res.status === 200) {
        await getcalender();
        setShow(false);
      } else {
        alert(res);
      }
    } catch (err) {
      alert(err);
    }
  };
  return (
    <>
      <button className="model-delete-button" onClick={handleShow}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M15.1875 3.375H12.375V2.8125C12.375 2.36495 12.1972 1.93572 11.8807 1.61926C11.5643 1.30279 11.1351 1.125 10.6875 1.125H7.3125C6.86495 1.125 6.43572 1.30279 6.11926 1.61926C5.80279 1.93572 5.625 2.36495 5.625 2.8125V3.375H2.8125C2.66332 3.375 2.52024 3.43426 2.41475 3.53975C2.30926 3.64524 2.25 3.78832 2.25 3.9375C2.25 4.08668 2.30926 4.22976 2.41475 4.33525C2.52024 4.44074 2.66332 4.5 2.8125 4.5H3.375V14.625C3.375 14.9234 3.49353 15.2095 3.7045 15.4205C3.91548 15.6315 4.20163 15.75 4.5 15.75H13.5C13.7984 15.75 14.0845 15.6315 14.2955 15.4205C14.5065 15.2095 14.625 14.9234 14.625 14.625V4.5H15.1875C15.3367 4.5 15.4798 4.44074 15.5852 4.33525C15.6907 4.22976 15.75 4.08668 15.75 3.9375C15.75 3.78832 15.6907 3.64524 15.5852 3.53975C15.4798 3.43426 15.3367 3.375 15.1875 3.375ZM6.75 2.8125C6.75 2.66332 6.80926 2.52024 6.91475 2.41475C7.02024 2.30926 7.16332 2.25 7.3125 2.25H10.6875C10.8367 2.25 10.9798 2.30926 11.0852 2.41475C11.1907 2.52024 11.25 2.66332 11.25 2.8125V3.375H6.75V2.8125ZM13.5 14.625H4.5V4.5H13.5V14.625ZM7.875 7.3125V11.8125C7.875 11.9617 7.81574 12.1048 7.71025 12.2102C7.60476 12.3157 7.46168 12.375 7.3125 12.375C7.16332 12.375 7.02024 12.3157 6.91475 12.2102C6.80926 12.1048 6.75 11.9617 6.75 11.8125V7.3125C6.75 7.16332 6.80926 7.02024 6.91475 6.91475C7.02024 6.80926 7.16332 6.75 7.3125 6.75C7.46168 6.75 7.60476 6.80926 7.71025 6.91475C7.81574 7.02024 7.875 7.16332 7.875 7.3125ZM11.25 7.3125V11.8125C11.25 11.9617 11.1907 12.1048 11.0852 12.2102C10.9798 12.3157 10.8367 12.375 10.6875 12.375C10.5383 12.375 10.3952 12.3157 10.2898 12.2102C10.1843 12.1048 10.125 11.9617 10.125 11.8125V7.3125C10.125 7.16332 10.1843 7.02024 10.2898 6.91475C10.3952 6.80926 10.5383 6.75 10.6875 6.75C10.8367 6.75 10.9798 6.80926 11.0852 6.91475C11.1907 7.02024 11.25 7.16332 11.25 7.3125Z"
            fill="#F72B50"
          />
        </svg>
      </button>
      <Modal show={show} onHide={handleClose} dialogClassName="request-leave">
        <Modal.Header closeButton>
          <Modal.Title>Delete Calender {i.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div">
            Are You Sure You Want to delete {i.title}
            <div className="button-models">
              <button className="model-button " onClick={handleClose}>
                Cancel
              </button>
              <button
                onClick={handleFormSubmitDelete}
                className="model-button model-button-delete"
              >
                Delete
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}
// ! *************** Calender Crud Models End ***************

// ! *************** InterCom Crud Models Start ***************

const InterCom = ({ getintercom }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);



  const [formData, setFormData] = useState({
    user_detail: "",
    work_email: "",
    contactno: "",
    extensionno: "",
  })
  //******************Valedation Start********************* */

  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = ["user_detail", "work_email", "contactno", "extensionno"];
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  //******************Valedation End*********************** */

  const [loading, setLoading] = useState(false); //loading logic
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); //loading logic

    if (validateForm()) {
      setLoading(true); // Ensure loading is set when the request starts
      try {
        let res = await axios.post(`${BASE_URL}/wfm/intercom/`, formData);

        if (res.status === 200) {
          await getintercom();
          setShow(false);
        }
      } catch (err) {
        if (err.response) {
          toast.error(`Error: ${err.response.data.error || 'Failed to add!'}`);
        } else {
          toast.error('Error processing your request.');
        }
      }  finally {
        setLoading(false); // Stop loading regardless of the outcome
      }
    }

  };
  return (
    <>
      <button className="models-button model-add" onClick={handleShow}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <g clip-path="url(#clip0_1415_3177)">
            <path
              d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM17 13H13V17H11V13H7V11H11V7H13V11H17V13Z"
              fill="#2576BC"
            />
          </g>
          <defs>
            <clipPath id="clip0_1415_3177">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </button>
      <Modal show={show} onHide={handleClose} dialogClassName="request-leave">
        <Modal.Header closeButton>
          <Modal.Title>InterCom</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div">
            <div className="bdy-div">
              {/* <h6 className="announce-heading">Birthday </h6> */}
              <div className="flex-column">
                <label htmlFor="user_detail" className="form-labels">
                  Employee Code<span className="required">*</span>
                </label>
                <input
                  type="number"
                  id="user_detail"
                  placeholder="Emp Code"
                  name="user_detail"
                  autoComplete="off"
                  onChange={handleInputChange}
                  value={formData.user_detail}
                  className={`form-input form-input-background ${errors.user_detail ? "error" : inputState.user_detail ? "success" : ""
                    }`}
                />
                {errors.user_detail && <span className="error-message">{errors.user_detail}</span>}
              </div>

              <div className="flex-column">
                <label htmlFor="work_email" className="form-labels">
                  Work Email<span className="required">*</span>
                </label>
                <input
                  type="emial"
                  id="work_email"
                  name="work_email"
                  placeholder="Email"
                  onChange={handleInputChange}
                  value={formData.work_email}
                  className={`form-input ${errors.work_email ? "error" : inputState.work_email ? "success" : ""
                    }`}
                />
                {errors.work_email && (
                  <span className="error-message">{errors.work_email}</span>
                )}
              </div>
              <div className="flex-column">
                <label htmlFor="contactno" className="form-labels">
                  Contact no.<span className="required">*</span>
                </label>
                <input
                  type="number"
                  id="contactno"
                  name="contactno"
                  placeholder="Contact no"
                  onChange={handleInputChange}
                  value={formData.contactno}
                  className={`form-input ${errors.contactno ? "error" : inputState.contactno ? "success" : ""
                    }`}
                />
                {errors.contactno && (
                  <span className="error-message">{errors.contactno}</span>
                )}
              </div>
              <div className="flex-column">
                <label htmlFor="extensionno" className="form-labels">
                  Extension no.<span className="required">*</span>
                </label>
                <input
                  type="number"
                  id="extensionno"
                  name="extensionno"
                  placeholder="Office extension no"
                  onChange={handleInputChange}
                  value={formData.extensionno}
                  className={`form-input ${errors.extensionno ? "error" : inputState.extensionno ? "success" : ""
                    }`}
                />
                {errors.extensionno && (
                  <span className="error-message">{errors.extensionno}</span>
                )}
              </div>


              <div className="button-models">
                <button className="model-button" onClick={handleClose}>
                  Cancel
                </button>
                <button
                  //   type="submit"
                  className="model-button model-button-leave"
                  onClick={handleFormSubmit}
                  disabled={loading}
                >
                  Add
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <ToastContainer position="top-center" autoClose={1000} hideProgressBar={false} newestOnTop={true} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
    </>
  )
}

const InterComUpdate = ({ i, getintercom }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [formData, setFormData] = useState({
    user_detail: "",
    work_email: "",
    contactno: "",
    extensionno: "",
  })

  useEffect(() => {
    setFormData(i);
  }, [i]);


  //******************Valedation Start********************* */

  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = ["user_detail", "work_email", "contactno", "extensionno"];
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  //******************Valedation End*********************** */
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      try {
        let res = await axios.put(`${BASE_URL}/wfm/intercom/${i.id}/`, formData);

        if (res.status === 200) {
          await getintercom();
          setShow(false);
        } else {
          alert(res);
        }
      } catch (err) {
        alert(err);
      }
    }
  };
  return (
    <>
      <button className="model-edit-button" onClick={handleShow}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M15.9834 5.1589L12.8412 2.01734C12.7367 1.91285 12.6127 1.82996 12.4762 1.7734C12.3397 1.71685 12.1933 1.68774 12.0456 1.68774C11.8978 1.68774 11.7515 1.71685 11.615 1.7734C11.4785 1.82996 11.3545 1.91285 11.25 2.01734L2.57977 10.6876C2.47485 10.7917 2.39167 10.9156 2.33506 11.0521C2.27844 11.1886 2.24953 11.335 2.25001 11.4828V14.6251C2.25001 14.9234 2.36853 15.2096 2.57951 15.4206C2.79049 15.6315 3.07664 15.7501 3.37501 15.7501H15.1875C15.3367 15.7501 15.4798 15.6908 15.5853 15.5853C15.6907 15.4798 15.75 15.3368 15.75 15.1876C15.75 15.0384 15.6907 14.8953 15.5853 14.7898C15.4798 14.6843 15.3367 14.6251 15.1875 14.6251H8.10844L15.9834 6.75007C16.0879 6.6456 16.1708 6.52157 16.2274 6.38507C16.2839 6.24856 16.313 6.10225 16.313 5.95449C16.313 5.80673 16.2839 5.66042 16.2274 5.52391C16.1708 5.3874 16.0879 5.26337 15.9834 5.1589ZM6.51727 14.6251H3.37501V11.4828L9.56251 5.29531L12.7048 8.43757L6.51727 14.6251ZM13.5 7.64234L10.3584 4.50007L12.0459 2.81257L15.1875 5.95484L13.5 7.64234Z"
            fill="#145650"
          />
        </svg>
      </button>
      <Modal show={show} onHide={handleClose} dialogClassName="request-leave">
        <Modal.Header closeButton>
          <Modal.Title>Update InterCom</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div">
            <div className="bdy-div">
              {/* <h6 className="announce-heading">Birthday </h6> */}
              <div className="flex-column">
                <label htmlFor="user_detail" className="form-labels">
                  Employee Code<span className="required">*</span>
                </label>
                <input
                  type="number"
                  id="user_detail"
                  placeholder="Emp Code"
                  name="user_detail"
                  autoComplete="off"
                  onChange={handleInputChange}
                  value={formData.user_detail}
                  className={`form-input form-input-background ${errors.user_detail ? "error" : inputState.user_detail ? "success" : ""
                    }`}
                />
                {errors.user_detail && <span className="error-message">{errors.user_detail}</span>}
              </div>

              <div className="flex-column">
                <label htmlFor="work_email" className="form-labels">
                  Work Email<span className="required">*</span>
                </label>
                <input
                  type="emial"
                  id="work_email"
                  name="work_email"
                  placeholder="Email"
                  onChange={handleInputChange}
                  value={formData.work_email}
                  className={`form-input ${errors.work_email ? "error" : inputState.work_email ? "success" : ""
                    }`}
                />
                {errors.work_email && (
                  <span className="error-message">{errors.work_email}</span>
                )}
              </div>
              <div className="flex-column">
                <label htmlFor="contactno" className="form-labels">
                  Contact no.<span className="required">*</span>
                </label>
                <input
                  type="number"
                  id="contactno"
                  name="contactno"
                  placeholder="Contact no"
                  onChange={handleInputChange}
                  value={formData.contactno}
                  className={`form-input ${errors.contactno ? "error" : inputState.contactno ? "success" : ""
                    }`}
                />
                {errors.contactno && (
                  <span className="error-message">{errors.contactno}</span>
                )}
              </div>
              <div className="flex-column">
                <label htmlFor="extensionno" className="form-labels">
                  Extension no.<span className="required">*</span>
                </label>
                <input
                  type="number"
                  id="extensionno"
                  name="extensionno"
                  placeholder="Office extension no"
                  onChange={handleInputChange}
                  value={formData.extensionno}
                  className={`form-input ${errors.extensionno ? "error" : inputState.extensionno ? "success" : ""
                    }`}
                />
                {errors.extensionno && (
                  <span className="error-message">{errors.extensionno}</span>
                )}
              </div>


              <div className="button-models">
                <button className="model-button" onClick={handleClose}>
                  Cancel
                </button>
                <button
                  //   type="submit"
                  className="model-button model-button-leave"
                  onClick={handleFormSubmit}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

const InterComDelete = ({ i, getintercom }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleFormSubmitDelete = async (e) => {
    e.preventDefault();

    try {
      let res = await axios.delete(`${BASE_URL}/wfm/intercom/${i.id}/`);

      if (res.status === 200) {
        await getintercom();
        setShow(false);
      } else {
        alert(res);
      }
    } catch (err) {
      alert(err);
    }
  };
  return (
    <>
      <button className="model-delete-button" onClick={handleShow}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M15.1875 3.375H12.375V2.8125C12.375 2.36495 12.1972 1.93572 11.8807 1.61926C11.5643 1.30279 11.1351 1.125 10.6875 1.125H7.3125C6.86495 1.125 6.43572 1.30279 6.11926 1.61926C5.80279 1.93572 5.625 2.36495 5.625 2.8125V3.375H2.8125C2.66332 3.375 2.52024 3.43426 2.41475 3.53975C2.30926 3.64524 2.25 3.78832 2.25 3.9375C2.25 4.08668 2.30926 4.22976 2.41475 4.33525C2.52024 4.44074 2.66332 4.5 2.8125 4.5H3.375V14.625C3.375 14.9234 3.49353 15.2095 3.7045 15.4205C3.91548 15.6315 4.20163 15.75 4.5 15.75H13.5C13.7984 15.75 14.0845 15.6315 14.2955 15.4205C14.5065 15.2095 14.625 14.9234 14.625 14.625V4.5H15.1875C15.3367 4.5 15.4798 4.44074 15.5852 4.33525C15.6907 4.22976 15.75 4.08668 15.75 3.9375C15.75 3.78832 15.6907 3.64524 15.5852 3.53975C15.4798 3.43426 15.3367 3.375 15.1875 3.375ZM6.75 2.8125C6.75 2.66332 6.80926 2.52024 6.91475 2.41475C7.02024 2.30926 7.16332 2.25 7.3125 2.25H10.6875C10.8367 2.25 10.9798 2.30926 11.0852 2.41475C11.1907 2.52024 11.25 2.66332 11.25 2.8125V3.375H6.75V2.8125ZM13.5 14.625H4.5V4.5H13.5V14.625ZM7.875 7.3125V11.8125C7.875 11.9617 7.81574 12.1048 7.71025 12.2102C7.60476 12.3157 7.46168 12.375 7.3125 12.375C7.16332 12.375 7.02024 12.3157 6.91475 12.2102C6.80926 12.1048 6.75 11.9617 6.75 11.8125V7.3125C6.75 7.16332 6.80926 7.02024 6.91475 6.91475C7.02024 6.80926 7.16332 6.75 7.3125 6.75C7.46168 6.75 7.60476 6.80926 7.71025 6.91475C7.81574 7.02024 7.875 7.16332 7.875 7.3125ZM11.25 7.3125V11.8125C11.25 11.9617 11.1907 12.1048 11.0852 12.2102C10.9798 12.3157 10.8367 12.375 10.6875 12.375C10.5383 12.375 10.3952 12.3157 10.2898 12.2102C10.1843 12.1048 10.125 11.9617 10.125 11.8125V7.3125C10.125 7.16332 10.1843 7.02024 10.2898 6.91475C10.3952 6.80926 10.5383 6.75 10.6875 6.75C10.8367 6.75 10.9798 6.80926 11.0852 6.91475C11.1907 7.02024 11.25 7.16332 11.25 7.3125Z"
            fill="#F72B50"
          />
        </svg>
      </button>
      <Modal show={show} onHide={handleClose} dialogClassName="request-leave">
        <Modal.Header closeButton>
          <Modal.Title>Delete InterCom {i.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div">
            Are You Sure You Want to delete nterCom {i.title}
            <div className="button-models">
              <button className="model-button " onClick={handleClose}>
                Cancel
              </button>
              <button
                onClick={handleFormSubmitDelete}
                className="model-button model-button-delete"
              >
                Delete
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

// ! *************** InterCom Crud Models End ***************



const EsslList = ({ getEssl }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [formData, setFormData] = useState({
    user_detail: "",
    work_email: "",
    contactno: "",
    extensionno: "",
  })
  //******************Valedation Start********************* */

  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = ["user_detail", "work_email", "contactno", "extensionno"];
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  //******************Valedation End*********************** */

  const [loading, setLoading] = useState(false); //loading logic
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); //loading logic

    if (validateForm()) {
      try {
        let res = await axios.post(`${BASE_URL}/wfm/intercom/`, formData);

        if (res.status === 200) {
          await getEssl();
          setShow(false);
        } else {
          alert(res);
        }
      } catch (err) {
        alert(err);
      } finally {
        setLoading(false); //loading logic
      }
    }
  };
  return (
    <>
      <button className="models-button model-add" onClick={handleShow}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <g clip-path="url(#clip0_1415_3177)">
            <path
              d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM17 13H13V17H11V13H7V11H11V7H13V11H17V13Z"
              fill="#2576BC"
            />
          </g>
          <defs>
            <clipPath id="clip0_1415_3177">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </button>
      <Modal show={show} onHide={handleClose} dialogClassName="request-leave">
        <Modal.Header closeButton>
          <Modal.Title>Essl Data</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div">
            <div className="bdy-div">
              {/* <h6 className="announce-heading">Birthday </h6> */}

              <div className="flex-column">
                <label htmlFor="user_detail" className="form-labels">
                  Employee Code<span className="required">*</span>
                </label>
                <input
                  type="number"
                  id="user_detail"
                  placeholder="Emp Code"
                  name="user_detail"
                  autoComplete="off"
                  onChange={handleInputChange}
                  value={formData.user_detail}
                  className={`form-input form-input-background ${errors.user_detail ? "error" : inputState.user_detail ? "success" : ""
                    }`}
                />
                {errors.user_detail && <span className="error-message">{errors.user_detail}</span>}
              </div>

              <div className="flex-column">
                <label htmlFor="work_email" className="form-labels">
                  Work Email<span className="required">*</span>
                </label>
                <input
                  type="emial"
                  id="work_email"
                  name="work_email"
                  placeholder="Email"
                  onChange={handleInputChange}
                  value={formData.work_email}
                  className={`form-input ${errors.work_email ? "error" : inputState.work_email ? "success" : ""
                    }`}
                />
                {errors.work_email && (
                  <span className="error-message">{errors.work_email}</span>
                )}
              </div>

              <div className="flex-column">
                <label htmlFor="contactno" className="form-labels">
                  Contact no.<span className="required">*</span>
                </label>
                <input
                  type="number"
                  id="contactno"
                  name="contactno"
                  placeholder="Contact no"
                  onChange={handleInputChange}
                  value={formData.contactno}
                  className={`form-input ${errors.contactno ? "error" : inputState.contactno ? "success" : ""
                    }`}
                />
                {errors.contactno && (
                  <span className="error-message">{errors.contactno}</span>
                )}
              </div>

              <div className="flex-column">
                <label htmlFor="extensionno" className="form-labels">
                  Extension no.<span className="required">*</span>
                </label>
                <input
                  type="number"
                  id="extensionno"
                  name="extensionno"
                  placeholder="Office extension no"
                  onChange={handleInputChange}
                  value={formData.extensionno}
                  className={`form-input ${errors.extensionno ? "error" : inputState.extensionno ? "success" : ""
                    }`}
                />
                {errors.extensionno && (
                  <span className="error-message">{errors.extensionno}</span>
                )}
              </div>


              <div className="button-models">
                <button className="model-button" onClick={handleClose}>
                  Cancel
                </button>
                <button
                  //   type="submit"
                  className="model-button model-button-leave"
                  onClick={handleFormSubmit}
                  disabled={loading}
                >
                  Add
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

const EsslUpdate = ({ i, getEssl }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [formData, setFormData] = useState({
    user_detail: "",
    work_email: "",
    contactno: "",
    extensionno: "",
  })

  useEffect(() => {
    setFormData(i);
  }, [i]);


  //******************Valedation Start********************* */

  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = ["user_detail", "work_email", "contactno", "extensionno"];
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  //******************Valedation End*********************** */
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      try {
        let res = await axios.put(`${BASE_URL}/wfm/intercom/${i.id}/`, formData);

        if (res.status === 200) {
          await getEssl();
          setShow(false);
        } else {
          alert(res);
        }
      } catch (err) {
        alert(err);
      }
    }
  };
  return (
    <>
      <button className="model-edit-button" onClick={handleShow}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M15.9834 5.1589L12.8412 2.01734C12.7367 1.91285 12.6127 1.82996 12.4762 1.7734C12.3397 1.71685 12.1933 1.68774 12.0456 1.68774C11.8978 1.68774 11.7515 1.71685 11.615 1.7734C11.4785 1.82996 11.3545 1.91285 11.25 2.01734L2.57977 10.6876C2.47485 10.7917 2.39167 10.9156 2.33506 11.0521C2.27844 11.1886 2.24953 11.335 2.25001 11.4828V14.6251C2.25001 14.9234 2.36853 15.2096 2.57951 15.4206C2.79049 15.6315 3.07664 15.7501 3.37501 15.7501H15.1875C15.3367 15.7501 15.4798 15.6908 15.5853 15.5853C15.6907 15.4798 15.75 15.3368 15.75 15.1876C15.75 15.0384 15.6907 14.8953 15.5853 14.7898C15.4798 14.6843 15.3367 14.6251 15.1875 14.6251H8.10844L15.9834 6.75007C16.0879 6.6456 16.1708 6.52157 16.2274 6.38507C16.2839 6.24856 16.313 6.10225 16.313 5.95449C16.313 5.80673 16.2839 5.66042 16.2274 5.52391C16.1708 5.3874 16.0879 5.26337 15.9834 5.1589ZM6.51727 14.6251H3.37501V11.4828L9.56251 5.29531L12.7048 8.43757L6.51727 14.6251ZM13.5 7.64234L10.3584 4.50007L12.0459 2.81257L15.1875 5.95484L13.5 7.64234Z"
            fill="#145650"
          />
        </svg>
      </button>
      <Modal show={show} onHide={handleClose} dialogClassName="request-leave">
        <Modal.Header closeButton>
          <Modal.Title>Update InterCom</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div">
            <div className="bdy-div">
              {/* <h6 className="announce-heading">Birthday </h6> */}
              <div className="flex-column">
                <label htmlFor="user_detail" className="form-labels">
                  Employee Code<span className="required">*</span>
                </label>
                <input
                  type="number"
                  id="user_detail"
                  placeholder="Emp Code"
                  name="user_detail"
                  autoComplete="off"
                  onChange={handleInputChange}
                  value={formData.user_detail}
                  className={`form-input form-input-background ${errors.user_detail ? "error" : inputState.user_detail ? "success" : ""
                    }`}
                />
                {errors.user_detail && <span className="error-message">{errors.user_detail}</span>}
              </div>

              <div className="flex-column">
                <label htmlFor="work_email" className="form-labels">
                  Work Email<span className="required">*</span>
                </label>
                <input
                  type="emial"
                  id="work_email"
                  name="work_email"
                  placeholder="Email"
                  onChange={handleInputChange}
                  value={formData.work_email}
                  className={`form-input ${errors.work_email ? "error" : inputState.work_email ? "success" : ""
                    }`}
                />
                {errors.work_email && (
                  <span className="error-message">{errors.work_email}</span>
                )}
              </div>
              <div className="flex-column">
                <label htmlFor="contactno" className="form-labels">
                  Contact no.<span className="required">*</span>
                </label>
                <input
                  type="number"
                  id="contactno"
                  name="contactno"
                  placeholder="Contact no"
                  onChange={handleInputChange}
                  value={formData.contactno}
                  className={`form-input ${errors.contactno ? "error" : inputState.contactno ? "success" : ""
                    }`}
                />
                {errors.contactno && (
                  <span className="error-message">{errors.contactno}</span>
                )}
              </div>
              <div className="flex-column">
                <label htmlFor="extensionno" className="form-labels">
                  Extension no.<span className="required">*</span>
                </label>
                <input
                  type="number"
                  id="extensionno"
                  name="extensionno"
                  placeholder="Office extension no"
                  onChange={handleInputChange}
                  value={formData.extensionno}
                  className={`form-input ${errors.extensionno ? "error" : inputState.extensionno ? "success" : ""
                    }`}
                />
                {errors.extensionno && (
                  <span className="error-message">{errors.extensionno}</span>
                )}
              </div>


              <div className="button-models">
                <button className="model-button" onClick={handleClose}>
                  Cancel
                </button>
                <button
                  //   type="submit"
                  className="model-button model-button-leave"
                  onClick={handleFormSubmit}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

const EsslDelete = ({ i, getEssl }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleFormSubmitDelete = async (e) => {
    e.preventDefault();

    try {
      let res = await axios.delete(`${BASE_URL}/wfm/intercom/${i.id}/`);

      if (res.status === 200) {
        await getEssl();
        setShow(false);
      } else {
        alert(res);
      }
    } catch (err) {
      alert(err);
    }
  };
  return (
    <>
      <button className="model-delete-button" onClick={handleShow}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M15.1875 3.375H12.375V2.8125C12.375 2.36495 12.1972 1.93572 11.8807 1.61926C11.5643 1.30279 11.1351 1.125 10.6875 1.125H7.3125C6.86495 1.125 6.43572 1.30279 6.11926 1.61926C5.80279 1.93572 5.625 2.36495 5.625 2.8125V3.375H2.8125C2.66332 3.375 2.52024 3.43426 2.41475 3.53975C2.30926 3.64524 2.25 3.78832 2.25 3.9375C2.25 4.08668 2.30926 4.22976 2.41475 4.33525C2.52024 4.44074 2.66332 4.5 2.8125 4.5H3.375V14.625C3.375 14.9234 3.49353 15.2095 3.7045 15.4205C3.91548 15.6315 4.20163 15.75 4.5 15.75H13.5C13.7984 15.75 14.0845 15.6315 14.2955 15.4205C14.5065 15.2095 14.625 14.9234 14.625 14.625V4.5H15.1875C15.3367 4.5 15.4798 4.44074 15.5852 4.33525C15.6907 4.22976 15.75 4.08668 15.75 3.9375C15.75 3.78832 15.6907 3.64524 15.5852 3.53975C15.4798 3.43426 15.3367 3.375 15.1875 3.375ZM6.75 2.8125C6.75 2.66332 6.80926 2.52024 6.91475 2.41475C7.02024 2.30926 7.16332 2.25 7.3125 2.25H10.6875C10.8367 2.25 10.9798 2.30926 11.0852 2.41475C11.1907 2.52024 11.25 2.66332 11.25 2.8125V3.375H6.75V2.8125ZM13.5 14.625H4.5V4.5H13.5V14.625ZM7.875 7.3125V11.8125C7.875 11.9617 7.81574 12.1048 7.71025 12.2102C7.60476 12.3157 7.46168 12.375 7.3125 12.375C7.16332 12.375 7.02024 12.3157 6.91475 12.2102C6.80926 12.1048 6.75 11.9617 6.75 11.8125V7.3125C6.75 7.16332 6.80926 7.02024 6.91475 6.91475C7.02024 6.80926 7.16332 6.75 7.3125 6.75C7.46168 6.75 7.60476 6.80926 7.71025 6.91475C7.81574 7.02024 7.875 7.16332 7.875 7.3125ZM11.25 7.3125V11.8125C11.25 11.9617 11.1907 12.1048 11.0852 12.2102C10.9798 12.3157 10.8367 12.375 10.6875 12.375C10.5383 12.375 10.3952 12.3157 10.2898 12.2102C10.1843 12.1048 10.125 11.9617 10.125 11.8125V7.3125C10.125 7.16332 10.1843 7.02024 10.2898 6.91475C10.3952 6.80926 10.5383 6.75 10.6875 6.75C10.8367 6.75 10.9798 6.80926 11.0852 6.91475C11.1907 7.02024 11.25 7.16332 11.25 7.3125Z"
            fill="#F72B50"
          />
        </svg>
      </button>
      <Modal show={show} onHide={handleClose} dialogClassName="request-leave">
        <Modal.Header closeButton>
          <Modal.Title>Delete InterCom {i.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div">
            Are You Sure You Want to delete nterCom {i.title}
            <div className="button-models">
              <button className="model-button " onClick={handleClose}>
                Cancel
              </button>
              <button
                onClick={handleFormSubmitDelete}
                className="model-button model-button-delete"
              >
                Delete
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

// ! *************** InterCom Crud Models End ***************



export {
  Department,
  DepartmentUpdate,
  DepartmentDelete,
  DesignationName,
  DesignationUpdate,
  DesignationDelete,
  Subcompanys,
  Subcompanyupdate,
  SubcompanyDelete,
  AttendancePolicy,
  AttendancePolicyUpdate,
  AttendancePolicyDelete,
  WeeklyOffPolicy,
  WeeklyOffPolicyUpdate,
  WeeklyOffPolicyDelete,
  Woksiteadd,
  Woksiteupdate,
  WoksiteDelete,
  CalenderModel,
  CalenderUpdate,
  CalenderDelete,
  InterCom,
  InterComUpdate,
  InterComDelete,
  EsslList,
  EsslUpdate,
  EsslDelete,
};
