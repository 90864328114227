import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import { BASE_URL } from "../../../config/axios";
import { formatDate, formattedDate } from "../../Date";

const Expensebutton = ({ getExpansee }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);



  const [formData, setFormData] = useState({
    name: "",
    imprest: "",
    date: formattedDate,
    empcode: sessionStorage.getItem("emp_code")
  });


  console.log(formData)
  //******************Valedation Start********************* */

  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = ["name", "imprest"];
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  //******************Valedation End*********************** */



  const handleFormSubmit = async (e) => {
    e.preventDefault();


    if (validateForm()) {
      try {
        let res = await axios.post(`${BASE_URL}/wfm/expense/`, formData);

        if (res.status === 200) {
          await getExpansee();
          setShow(false);
        } else {
          alert(res);
        }
      } catch (err) {
        alert(err);
      }
    }
  };


  useEffect(() => {
    getAllempdata();
  }, []);
  const [allemployeedata, setAllemployee] = useState([]);

  const getAllempdata = async () => {
    try {
      const res = await axios.get(`${BASE_URL}/wfm/impresetbyemployeedetails/${sessionStorage.getItem("emp_code")}/`);
      setAllemployee(res.data);
    } catch (err) {
      alert(err.message);
    }
  };


  console.log(allemployeedata)





  return (
    <>
      <button className="models-button model-add" onClick={handleShow}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <g clip-path="url(#clip0_1415_3177)">
            <path
              d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM17 13H13V17H11V13H7V11H11V7H13V11H17V13Z"
              fill="#2576BC"
            />
          </g>
          <defs>
            <clipPath id="clip0_1415_3177">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="request-leave">
        {/* <Modal.Header closeButton>
          <Modal.Title>Request Leave</Modal.Title>
        </Modal.Header> */}
        <Modal.Body>
          <div className="parent-div">
            <div className="bdy-div">
              <div className="flex-column">
                <label htmlFor="name" className="form-labels announce-date">
                  Expense Name
                </label>
                <input
                  id="name"
                  type="name"
                  name="name"
                  placeholder="Expense Name"
                  onChange={handleInputChange}
                  value={formData.name}
                  className={`form-input ${errors.name ? "error" : inputState.name ? "success" : ""
                    }`}
                />
                {errors.name && (
                  <span className="error-message">{errors.name}</span>
                )}
              </div>

              <div className="flex-column form-group-select">
                <label className="form-labels">
                  Imprest Against<span className="required">*</span>
                </label>
                <br />
                <select
                  name="imprest"
                  onChange={handleInputChange}
                  value={formData.imprest}
                  className={`form-input form-group-selection form-input-background ${errors.imprest ? "error" : inputState.imprest ? "success" : ""
                    }`}>
                  <option value="">Select Imprset</option>
                  {allemployeedata.map((e, index) => (<option value={e.id}>{e.project_name}</option>))}

                  {/* {emp_code.sort((a, b) => a.emp_code - b.emp_code).map((i, index) => (<option value={i.emp_code}>{i.emp_code} - {i.name}</option>))}; */}
                </select>
                <div className="form-group-selection-arrow">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="25"
                    fill="currentColor"
                    class="bi bi-caret-down-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                  </svg>
                </div>
              </div>




              <div className="button-models">
                <button className="model-button" onClick={handleClose}>
                  Cancel
                </button>
                <button
                  type="submit"
                  className="model-button model-button-leave"
                  onClick={handleFormSubmit}
                >
                  Add
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
// const DocumentAdd = ({getAnnouncement}) => {
//   const [show, setShow] = useState(false);

//   const handleClose = () => setShow(false);
//   const handleShow = () => setShow(true);

//   const today = new Date();
//   const year = today.getFullYear();
//   const month = today.getMonth() + 1;
//   const day = today.getDate();
//   const formattedDate = `${year}-${month.toString().padStart(2, "0")}-${day
//     .toString()
//     .padStart(2, "0")}`; 

//   const [formData, setFormData] = useState({
//     user: sessionStorage.getItem("email"),
//     document_name: "",
//     document_type: "",
//     document_file: "",

//   });

//   // !  ************** Validation start **************  ! //

//   const [errors, setErrors] = useState({});
//   const [inputState, setInputState] = useState({});

//   const validateForm = () => {
//     const newErrors = {};
//     const requiredFields = ["document_name", "document_type","document_file"];
//     requiredFields.forEach((field) => {
//       if (!formData[field]) {
//         newErrors[field] = ` ${
//           field.charAt(0).toUpperCase() + field.slice(1)
//         } is required !`;
//       }
//     });

//     setErrors(newErrors);

//     return Object.keys(newErrors).length === 0;
//   };  

//   // ?  ************** Validation End **************  ! //

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     if (validateForm()) {
//       const formDataa = new FormData();
//       formDataa.append("user", formData.user);

//       formDataa.append("document_name", formData.document_name);
//       formDataa.append("document_type", formData.document_type);
//       formDataa.append("document_file", formData.document_file);
//       try {
//         let res = await axios.post(`${BASE_URL}/userdata/userdocs/`, formDataa, {
//           headers: {
//             "Content-Type": "multipart/form-data",
//           },
//         });

//         if (res.status === 200) {
//           handleClose();
//         } else {
//           alert(res);
//         }
//       } catch (err) {
//         alert(err);
//       }
//     }
//   };

//   const handleInputChange = (e) => {
//     const { name, value } = e.target;

//     setInputState({
//       ...inputState,
//       [name]: value.trim() ? "green" : "",
//     });

//     setFormData({
//       ...formData,
//       [name]: value,
//     });
//   };

//   const handleFileChange = (e) => {
//     const { name } = e.target;
//     const file = e.target.files[0];

//     if (file) {
//       if (file.type === "application/pdf") {
//         setFormData({
//           ...formData,
//           [name]: file,
//         });
//       } else {
//         setErrors({
//           ...errors,
//           [name]: "Invalid file type. Please select a PDF file.",
//         });
//       }
//     } else {
//       setFormData({
//         ...formData,
//         [name]: null,
//       });
//     }
//   };

//   const handleFileClear = (name) => {
//     setFormData({
//       ...formData,
//       [name]: null,
//     });
//   };

//   return (
//     <>
//        <button className="models-button model-add" onClick={handleShow} >
//       <svg
//         xmlns="http://www.w3.org/2000/svg"
//         width="24"
//         height="24"
//         viewBox="0 0 24 24"
//         fill="none"
//       >
//         <g clip-path="url(#clip0_1415_3177)">
//           <path
//             d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM17 13H13V17H11V13H7V11H11V7H13V11H17V13Z"
//             fill="#2576BC"
//           />
//         </g>
//         <defs>
//           <clipPath id="clip0_1415_3177">
//             <rect width="24" height="24" fill="white" />
//           </clipPath>
//         </defs>
//       </svg>
//     </button>

//       <Modal show={show} onHide={handleClose} dialogClassName="request-leave">
//         {/* <Modal.Header closeButton>
//           <Modal.Title>Request Leave</Modal.Title>
//         </Modal.Header> */}
//         <Modal.Body>
//           <div className="parent-div">
//             <div className="bdy-div">
//               <h6 className="announce-heading">Document</h6>

//               <form>
//               <div className="flex-column">
//                   <label htmlFor="document_name" className="form-labels announce-date">
//                     Document Name
//                   </label>
//                   <input
//                     id="document_name"
//                     type="document_name"
//                     name="document_name"
//                     placeholder="Document Name"
//                     onChange={handleInputChange}
//                     value={formData.document_name}
//                     className={`form-input ${
//                       errors.document_name ? "error" : inputState.document_name ? "success" : ""
//                     }`}
//                   />
//                   {errors.document_name && (
//                     <span className="error-message">{errors.document_name}</span>
//                   )}
//                 </div>

//                 <div className="flex-column form-group-selectt">
//             <label htmlFor="document_type" className="form-labels">
//             Document Type<span className="required">*</span>
//             </label>
//             <br />
//             <select
//               id="document_type"
//               name="document_type"
//               value={formData.document_type}
//               onChange={handleInputChange}
//               className={`form-input form-group-selection ${errors.document_type ? "error" : inputState.document_type ? "success" : ""
//                 }`}
//             >
//               <option value="">Select a Document Type</option>
//               <option value="identity">Identity</option>
//               <option value="previousExperience">Previous Experience</option>
//               <option value="degree">Degree</option>


//             </select>
//             <div className="form-group-selection-arrow">
//               <svg
//                 xmlns="http://www.w3.org/2000/svg"
//                 width="20"
//                 height="25"
//                 fill="currentColor"
//                 class="bi bi-caret-down-fill"
//                 viewBox="0 0 16 16"
//               >
//                 <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
//               </svg>
//             </div>
//             <div>
//               {errors.document_type && (
//                 <span className="error-message">{errors.document_type}</span>
//               )}
//             </div>
//           </div>



//                 <div className="flex-column">
//                   <label htmlFor="attachment" className="form-labels announce-date">
//                     Attachment
//                   </label>
//                   <input
//                     id= "file"
//                     type="file"
//                     name="document_file"
//                     accept=".pdf"
//                     onChange={handleFileChange}
//                     className="file-input"
//                   />

//                   {formData.document_file ? (
//                     <span
//                       className="file-clear"
//                       onClick={() => handleFileClear("file")}
//                     >
//                       Clear
//                     </span>
//                   ) : null}
//                 </div>
//               </form>
//               <div className="button-models">
//                 <button className="model-button" onClick={handleClose}>
//                   Cancel
//                 </button>
//                 <button
//                   type="submit"
//                   className="model-button model-button-leave"
//                   onClick={handleSubmit}
//                 >
//                   Add
//                 </button>
//               </div>
//             </div>
//           </div>
//         </Modal.Body>
//       </Modal>
//     </>
//   );
// };

export { Expensebutton };
