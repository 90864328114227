import React from "react";

const Jobdetails = () => {
  return (
    <div>
      <div className="main-div-personaldetial">
        <div className="presonal-info">
          <div className="personal-detail">
            <h3 className="personal-detail-heading">Job Details</h3>
            <table>
              <tr>
                <td className="personal-detail-1">Employee ID</td>
                <th className="personal-detail-2">EF34F4</th>
              </tr>
              <tr>
                <td className="personal-detail-1">Date of joining</td>
                <th className="personal-detail-2">Sharma</th>
              </tr>
              <tr>
                <td className="personal-detail-1">Job Title-Primary</td>
                <th className="personal-detail-2">IT - Expert</th>
              </tr>
              <tr>
                <td className="personal-detail-1">Job Title-Secondary</td>
                <th className="personal-detail-2">UI/UX designer+</th>
              </tr>
              <tr>
                <td className="personal-detail-1">Pay Grade</td>
                <th className="personal-detail-2">B+</th>
              </tr>
            </table>
          </div>
          <div>
            <br></br>
            <table>
              <tr>
                <td className="personal-detail-1">Probation Period</td>
                <th className="personal-detail-2">
                  01 Sept, 2023 - 01 Dec, 2023
                </th>
              </tr>
              <tr>
                <td className="personal-detail-1">Worker Type</td>
                <th className="personal-detail-2">Permanent</th>
              </tr>
              <tr>
                <td className="personal-detail-1">Time Type</td>
                <th className="personal-detail-2">Full Time</th>
              </tr>
              <tr>
                <td className="personal-detail-1">Bond Agreement</td>
                <th className="personal-detail-2">6 Months</th>
              </tr>
            </table>
          </div>
        </div>

        {/* <div className="presonal-info">
          <div className="personal-detail">
            <h3 className="personal-detail-heading">Organization</h3>
            <table>
              <tr>
                <td className="personal-detail-1">Company Name</td>
                <th className="personal-detail-2">AIMantra Pvt. Ltd.</th>
              </tr>
              <tr>
                <td className="personal-detail-1">Department</td>
                <th className="personal-detail-2">Lab</th>
              </tr>
              <tr>
                <td className="personal-detail-1">Location</td>
                <th className="personal-detail-2">Haryana</th>
              </tr>
              <tr>
                <td className="personal-detail-1">Sr. Manager</td>
                <th className="personal-detail-2">@Manisha Yadav</th>
              </tr>
              <tr>
                <td className="personal-detail-1">Direct Employee</td>
                <th className="personal-detail-2">0 Employees</th>
              </tr>
              <tr>
                <td className="personal-detail-1">Legal Entity</td>
                <th className="personal-detail-2">AIMantra Pvt. Ltd.</th>
              </tr>
              <tr>
                <td className="personal-detail-1">Reports to</td>
                <th className="personal-detail-2">@Nisha Yadav</th>
              </tr>
            </table>
          </div>

          <div className="employeee-time">
          <h3 className="personal-detail-heading">Employee Time</h3>
            <table>
              <tr>
                <td className="personal-detail-1">Shift</td>
                <th className="personal-detail-2">Day Shift</th>
              </tr>
              <tr>
                <td className="personal-detail-1">Leave Plan</td>
                <th className="personal-detail-2">General leave</th>
              </tr>
              <tr>
                <td className="personal-detail-1">Attendance</td>
                <th className="personal-detail-2">720</th>
              </tr>
              <tr>
                <td className="personal-detail-1">Weekly off Policy</td>
                <th className="personal-detail-2">Weekly off</th>
              </tr>
              <tr>
                <td className="personal-detail-1">Bond Agreement</td>
                <th className="personal-detail-2">6 Months</th>
              </tr>
              <tr>
                <td className="personal-detail-1">Holiday Leave</td>
                <th className="personal-detail-2">General Holiday List</th>
              </tr>
              <tr>
                <td className="personal-detail-1">Overtime</td>
                <th className="personal-detail-2">36 Hours</th>
              </tr>
            </table>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default Jobdetails;
