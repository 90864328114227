import React, { useState, useEffect } from "react";
// import "../../assets/css/components.css";
import LeaveChart from "./userDetails-comp/LeaveChart";
import { BASE_URL } from "../../config/axios";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import { formatDate, formattedDate, formattedDateLong } from '../Date';
import OdChart from "./userDetails-comp/OdChart";
import OutDutyChart from "./userDetails-comp/OdChart";


const LeaveModelDelete = ({ i, getAllrequest }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleFormSubmitDelete = async (e) => {
    e.preventDefault();

    try {
      let res = await axios.delete(`${BASE_URL}/wfm/leavedelete/${i.id}/`);

      if (res.status === 200) {
        await getAllrequest();
        setShow(false);
      } else {
        alert(res);
      }
    } catch (err) {
      alert(err);
    }
  };

  return (
    <>
      <button className="model-delete-button" onClick={handleShow}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M15.1875 3.375H12.375V2.8125C12.375 2.36495 12.1972 1.93572 11.8807 1.61926C11.5643 1.30279 11.1351 1.125 10.6875 1.125H7.3125C6.86495 1.125 6.43572 1.30279 6.11926 1.61926C5.80279 1.93572 5.625 2.36495 5.625 2.8125V3.375H2.8125C2.66332 3.375 2.52024 3.43426 2.41475 3.53975C2.30926 3.64524 2.25 3.78832 2.25 3.9375C2.25 4.08668 2.30926 4.22976 2.41475 4.33525C2.52024 4.44074 2.66332 4.5 2.8125 4.5H3.375V14.625C3.375 14.9234 3.49353 15.2095 3.7045 15.4205C3.91548 15.6315 4.20163 15.75 4.5 15.75H13.5C13.7984 15.75 14.0845 15.6315 14.2955 15.4205C14.5065 15.2095 14.625 14.9234 14.625 14.625V4.5H15.1875C15.3367 4.5 15.4798 4.44074 15.5852 4.33525C15.6907 4.22976 15.75 4.08668 15.75 3.9375C15.75 3.78832 15.6907 3.64524 15.5852 3.53975C15.4798 3.43426 15.3367 3.375 15.1875 3.375ZM6.75 2.8125C6.75 2.66332 6.80926 2.52024 6.91475 2.41475C7.02024 2.30926 7.16332 2.25 7.3125 2.25H10.6875C10.8367 2.25 10.9798 2.30926 11.0852 2.41475C11.1907 2.52024 11.25 2.66332 11.25 2.8125V3.375H6.75V2.8125ZM13.5 14.625H4.5V4.5H13.5V14.625ZM7.875 7.3125V11.8125C7.875 11.9617 7.81574 12.1048 7.71025 12.2102C7.60476 12.3157 7.46168 12.375 7.3125 12.375C7.16332 12.375 7.02024 12.3157 6.91475 12.2102C6.80926 12.1048 6.75 11.9617 6.75 11.8125V7.3125C6.75 7.16332 6.80926 7.02024 6.91475 6.91475C7.02024 6.80926 7.16332 6.75 7.3125 6.75C7.46168 6.75 7.60476 6.80926 7.71025 6.91475C7.81574 7.02024 7.875 7.16332 7.875 7.3125ZM11.25 7.3125V11.8125C11.25 11.9617 11.1907 12.1048 11.0852 12.2102C10.9798 12.3157 10.8367 12.375 10.6875 12.375C10.5383 12.375 10.3952 12.3157 10.2898 12.2102C10.1843 12.1048 10.125 11.9617 10.125 11.8125V7.3125C10.125 7.16332 10.1843 7.02024 10.2898 6.91475C10.3952 6.80926 10.5383 6.75 10.6875 6.75C10.8367 6.75 10.9798 6.80926 11.0852 6.91475C11.1907 7.02024 11.25 7.16332 11.25 7.3125Z"
            fill="#F72B50"
          />
        </svg>
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="request-leave">
        <Modal.Header closeButton>
          <Modal.Title>Delete Leave</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div">
            Are You Sure You Want to delete Leave Request of Date {i.date}
            <div className="button-models">
              <button className="model-button " onClick={handleClose}>
                Cancel
              </button>
              <button
                onClick={handleFormSubmitDelete}
                className="model-button model-button-delete"
              >
                Delete
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

const OutdutyModelDelete = ({ i, getAllrequest }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleFormSubmitDelete = async (e) => {
    e.preventDefault();

    try {
      let res = await axios.delete(`${BASE_URL}/wfm/outdutydelete/${i.id}/`);

      if (res.status === 200) {
        await getAllrequest();
        setShow(false);
      } else {
        alert(res);
      }
    } catch (err) {
      alert(err);
    }
  };

  return (
    <>
      <button className="model-delete-button" onClick={handleShow}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M15.1875 3.375H12.375V2.8125C12.375 2.36495 12.1972 1.93572 11.8807 1.61926C11.5643 1.30279 11.1351 1.125 10.6875 1.125H7.3125C6.86495 1.125 6.43572 1.30279 6.11926 1.61926C5.80279 1.93572 5.625 2.36495 5.625 2.8125V3.375H2.8125C2.66332 3.375 2.52024 3.43426 2.41475 3.53975C2.30926 3.64524 2.25 3.78832 2.25 3.9375C2.25 4.08668 2.30926 4.22976 2.41475 4.33525C2.52024 4.44074 2.66332 4.5 2.8125 4.5H3.375V14.625C3.375 14.9234 3.49353 15.2095 3.7045 15.4205C3.91548 15.6315 4.20163 15.75 4.5 15.75H13.5C13.7984 15.75 14.0845 15.6315 14.2955 15.4205C14.5065 15.2095 14.625 14.9234 14.625 14.625V4.5H15.1875C15.3367 4.5 15.4798 4.44074 15.5852 4.33525C15.6907 4.22976 15.75 4.08668 15.75 3.9375C15.75 3.78832 15.6907 3.64524 15.5852 3.53975C15.4798 3.43426 15.3367 3.375 15.1875 3.375ZM6.75 2.8125C6.75 2.66332 6.80926 2.52024 6.91475 2.41475C7.02024 2.30926 7.16332 2.25 7.3125 2.25H10.6875C10.8367 2.25 10.9798 2.30926 11.0852 2.41475C11.1907 2.52024 11.25 2.66332 11.25 2.8125V3.375H6.75V2.8125ZM13.5 14.625H4.5V4.5H13.5V14.625ZM7.875 7.3125V11.8125C7.875 11.9617 7.81574 12.1048 7.71025 12.2102C7.60476 12.3157 7.46168 12.375 7.3125 12.375C7.16332 12.375 7.02024 12.3157 6.91475 12.2102C6.80926 12.1048 6.75 11.9617 6.75 11.8125V7.3125C6.75 7.16332 6.80926 7.02024 6.91475 6.91475C7.02024 6.80926 7.16332 6.75 7.3125 6.75C7.46168 6.75 7.60476 6.80926 7.71025 6.91475C7.81574 7.02024 7.875 7.16332 7.875 7.3125ZM11.25 7.3125V11.8125C11.25 11.9617 11.1907 12.1048 11.0852 12.2102C10.9798 12.3157 10.8367 12.375 10.6875 12.375C10.5383 12.375 10.3952 12.3157 10.2898 12.2102C10.1843 12.1048 10.125 11.9617 10.125 11.8125V7.3125C10.125 7.16332 10.1843 7.02024 10.2898 6.91475C10.3952 6.80926 10.5383 6.75 10.6875 6.75C10.8367 6.75 10.9798 6.80926 11.0852 6.91475C11.1907 7.02024 11.25 7.16332 11.25 7.3125Z"
            fill="#F72B50"
          />
        </svg>
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="request-leave">
        <Modal.Header closeButton>
          <Modal.Title>Delete Leave</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div">
            Are You Sure You Want to delete Leave Request of Date {i.date}
            <div className="button-models">
              <button className="model-button " onClick={handleClose}>
                Cancel
              </button>
              <button
                onClick={handleFormSubmitDelete}
                className="model-button model-button-delete"
              >
                Delete
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}




const AllRequest = ({ title }) => {


  const [allrequest, setAllRequest] = useState([])


  const getAllrequest = async () => {
    try {
      const res = await axios.get(`${BASE_URL}/wfm/leavebyemployee/${sessionStorage.getItem("emp_code")}/`);
      const ress = await axios.get(`${BASE_URL}/wfm/odbyemployee/${sessionStorage.getItem("emp_code")}/`);

      const combinedData = res.data.concat(ress.data);
      setAllRequest(combinedData);

    } catch (err) {
    }
  };

  console.log(allrequest)
  useEffect(() => {
    getAllrequest();
  }, []);


  return (
    <div className="custom-table-background">
      <div className="table-heading-flex">
        <div className="repo-heading"> {title} Request
        </div>
        <div>
          {/* <Department getDepartment={getDepartment}/> */}
        </div>
      </div>
      <table className="custom-table">
        <thead>
          <tr className="custom-table-head-tr">
            <th className="align-left">Sr no.</th>
            <th className="align-leftt">Date</th>
            <th className="align-center">Type</th>
            <th className="align-center">Duration</th>
            <th className="align-center">Status</th>
            {/* <th className="align-right">Action</th> */}
          </tr>
        </thead>
        <tbody>
          {allrequest?.sort((a, b) => new Date(a.date) - new Date(b.date)).map((i, index) => (
            <React.Fragment key={index}>
              <tr className="tr-border-bottom">
                <td colSpan="9"></td>
              </tr>
              <tr className="custom-table-head-td">
                <td className="align-left">{index + 1}</td>
                <td className="align-leftt">{formatDate(i.date)} &<br /> {formatDate(i.end_date)}</td>
                <td className="align-center">{i.leave_Duration ? "Leave" : ""} {i.outduty_Duration ? "OutDuty" : ""}</td>
                <td className="align-center form-text-trasformation-uppercase">{i.leave_Duration ? i.leave_Duration : i.outduty_Duration}</td>
                {/* <td request-status={i.rh_leave_status} className="request-status align-center" >{i.rh_leave_status}</td> */}
                <td className="align-center">
                  <span request-status={i.rh_leave_status} className="request-status align-center" >{i.rh_leave_status === "pending" ? "Pending For Rh Approval ⌛" : i.rh_leave_status === "rejected" ? "Rh Rejected ✖, " : "Rh Approved ✔, "}</span>
                  <br />
                  <span request-status={i.admin_leave_status} className="request-status align-center" >{(i.rh_leave_status === "rejected" && i.admin_leave_status === "rejected") ? "Admin Rejected ✖, " : (i.rh_leave_status === "approved" && i.admin_leave_status === "pending") ? "Pending For Admin Approval ⌛," : (i.rh_leave_status === "approved" && i.admin_leave_status === "rejected") ? "Admin Rejected ✖, " : (i.rh_leave_status === "approved" && i.admin_leave_status === "approved") ? "Admin Approved ✔, " : ""}</span>
                </td>
                {/* <td className="align-center">{i.rh_leave_status}</td> */}
                {/* <td className="align-right ">
                  {i.rh_leave_status === "pending" ? <LeaveModelDelete i={i} getAllrequest={getAllrequest} /> : <OutdutyModelDelete i={i} getAllrequest={getAllrequest} />}
                </td> */}

              </tr>
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  )
}

const LeaveTable = ({ title }) => {

  const today = new Date();
  today.setDate(today.getDate() - 30);

  const year = today.getFullYear();
  const month = today.getMonth() + 1;
  const day = today.getDate();

  const formattedDatee = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;

  const [allrequest, setAllRequest] = useState([])

  const [startdate, setStartDate] = useState(`${formattedDatee}`);
  const [enddate, setEndDate] = useState(`${formattedDate}`);
  const [selectedStatus, setSelectedStatus] = useState('pending/');

  const getAllrequest = async () => {
    try {
      const res = await axios.get(`${BASE_URL}/wfm/leavebyemployee/${sessionStorage.getItem("emp_code")}/`);
      setAllRequest(res.data);

    } catch (err) {
    }
  };

  console.log(allrequest)
  useEffect(() => {
    getAllrequest();
  }, [selectedStatus, startdate, enddate]);


  return (
    <>
      {/* <div className="attendance-history-cont">
        <div className="attendance-heading"> History</div>
        <div className="attendance-subcont">
          <div className="field-cont">
            <input className="attendance-input-field date-field" placeholder='From Date' type="date" value={startdate} onChange={(e) => setStartDate(e.target.value)} /><hr />
            <input className="attendance-input-field date-field" placeholder='To Date' type="date" value={enddate} onChange={(e) => setEndDate(e.target.value)} /><hr />
            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 14 14" fill="none">
              <g clip-path="url(#clip0_650_3324)">
                <path d="M2.625 10.2083H1.16667C0.857247 10.2083 0.560501 10.3312 0.341709 10.55C0.122916 10.7688 0 11.0655 0 11.3749L0 12.8333C0 13.1427 0.122916 13.4394 0.341709 13.6582C0.560501 13.877 0.857247 13.9999 1.16667 13.9999H2.625C2.93442 13.9999 3.23117 13.877 3.44996 13.6582C3.66875 13.4394 3.79167 13.1427 3.79167 12.8333V11.3749C3.79167 11.0655 3.66875 10.7688 3.44996 10.55C3.23117 10.3312 2.93442 10.2083 2.625 10.2083ZM2.625 12.8333H1.16667V11.3749H2.625V12.8333Z" fill="#707070" />
                <path d="M12.8333 10.2083H11.3749C11.0655 10.2083 10.7688 10.3312 10.55 10.55C10.3312 10.7688 10.2083 11.0655 10.2083 11.3749V12.8333C10.2083 13.1427 10.3312 13.4394 10.55 13.6582C10.7688 13.877 11.0655 13.9999 11.3749 13.9999H12.8333C13.1427 13.9999 13.4394 13.877 13.6582 13.6582C13.877 13.4394 13.9999 13.1427 13.9999 12.8333V11.3749C13.9999 11.0655 13.877 10.7688 13.6582 10.55C13.4394 10.3312 13.1427 10.2083 12.8333 10.2083ZM12.8333 12.8333H11.3749V11.3749H12.8333V12.8333Z" fill="#707070" />
                <path d="M2.625 5.10425H1.16667C0.857247 5.10425 0.560501 5.22716 0.341709 5.44596C0.122916 5.66475 0 5.9615 0 6.27091L0 7.72925C0 8.03867 0.122916 8.33541 0.341709 8.55421C0.560501 8.773 0.857247 8.89592 1.16667 8.89592H2.625C2.93442 8.89592 3.23117 8.773 3.44996 8.55421C3.66875 8.33541 3.79167 8.03867 3.79167 7.72925V6.27091C3.79167 5.9615 3.66875 5.66475 3.44996 5.44596C3.23117 5.22716 2.93442 5.10425 2.625 5.10425ZM2.625 7.72925H1.16667V6.27091H2.625V7.72925Z" fill="#707070" />
                <path d="M12.8333 5.10425H11.3749C11.0655 5.10425 10.7688 5.22716 10.55 5.44596C10.3312 5.66475 10.2083 5.9615 10.2083 6.27091V7.72925C10.2083 8.03867 10.3312 8.33541 10.55 8.55421C10.7688 8.773 11.0655 8.89592 11.3749 8.89592H12.8333C13.1427 8.89592 13.4394 8.773 13.6582 8.55421C13.877 8.33541 13.9999 8.03867 13.9999 7.72925V6.27091C13.9999 5.9615 13.877 5.66475 13.6582 5.44596C13.4394 5.22716 13.1427 5.10425 12.8333 5.10425ZM12.8333 7.72925H11.3749V6.27091H12.8333V7.72925Z" fill="#707070" />
                <path d="M2.625 0H1.16667C0.857247 0 0.560501 0.122916 0.341709 0.341709C0.122916 0.560501 0 0.857247 0 1.16667L0 2.625C0 2.93442 0.122916 3.23117 0.341709 3.44996C0.560501 3.66875 0.857247 3.79167 1.16667 3.79167H2.625C2.93442 3.79167 3.23117 3.66875 3.44996 3.44996C3.66875 3.23117 3.79167 2.93442 3.79167 2.625V1.16667C3.79167 0.857247 3.66875 0.560501 3.44996 0.341709C3.23117 0.122916 2.93442 0 2.625 0ZM2.625 2.625H1.16667V1.16667H2.625V2.625Z" fill="#707070" />
                <path d="M7.72925 10.2083H6.27091C5.9615 10.2083 5.66475 10.3312 5.44596 10.55C5.22716 10.7688 5.10425 11.0655 5.10425 11.3749V12.8333C5.10425 13.1427 5.22716 13.4394 5.44596 13.6582C5.66475 13.877 5.9615 13.9999 6.27091 13.9999H7.72925C8.03867 13.9999 8.33541 13.877 8.55421 13.6582C8.773 13.4394 8.89592 13.1427 8.89592 12.8333V11.3749C8.89592 11.0655 8.773 10.7688 8.55421 10.55C8.33541 10.3312 8.03867 10.2083 7.72925 10.2083ZM7.72925 12.8333H6.27091V11.3749H7.72925V12.8333Z" fill="#707070" />
                <path d="M7.72925 5.10425H6.27091C5.9615 5.10425 5.66475 5.22716 5.44596 5.44596C5.22716 5.66475 5.10425 5.9615 5.10425 6.27091V7.72925C5.10425 8.03867 5.22716 8.33541 5.44596 8.55421C5.66475 8.773 5.9615 8.89592 6.27091 8.89592H7.72925C8.03867 8.89592 8.33541 8.773 8.55421 8.55421C8.773 8.33541 8.89592 8.03867 8.89592 7.72925V6.27091C8.89592 5.9615 8.773 5.66475 8.55421 5.44596C8.33541 5.22716 8.03867 5.10425 7.72925 5.10425ZM7.72925 7.72925H6.27091V6.27091H7.72925V7.72925Z" fill="#707070" />
                <path d="M7.72925 0H6.27091C5.9615 0 5.66475 0.122916 5.44596 0.341709C5.22716 0.560501 5.10425 0.857247 5.10425 1.16667V2.625C5.10425 2.93442 5.22716 3.23117 5.44596 3.44996C5.66475 3.66875 5.9615 3.79167 6.27091 3.79167H7.72925C8.03867 3.79167 8.33541 3.66875 8.55421 3.44996C8.773 3.23117 8.89592 2.93442 8.89592 2.625V1.16667C8.89592 0.857247 8.773 0.560501 8.55421 0.341709C8.33541 0.122916 8.03867 0 7.72925 0ZM7.72925 2.625H6.27091V1.16667H7.72925V2.625Z" fill="#707070" />
                <path d="M12.8333 0H11.3749C11.0655 0 10.7688 0.122916 10.55 0.341709C10.3312 0.560501 10.2083 0.857247 10.2083 1.16667V2.625C10.2083 2.93442 10.3312 3.23117 10.55 3.44996C10.7688 3.66875 11.0655 3.79167 11.3749 3.79167H12.8333C13.1427 3.79167 13.4394 3.66875 13.6582 3.44996C13.877 3.23117 13.9999 2.93442 13.9999 2.625V1.16667C13.9999 0.857247 13.877 0.560501 13.6582 0.341709C13.4394 0.122916 13.1427 0 12.8333 0ZM12.8333 2.625H11.3749V1.16667H12.8333V2.625Z" fill="#707070" />
              </g>
              <defs>
                <clipPath id="clip0_650_3324">
                  <rect width="14" height="14" fill="white" />
                </clipPath>
              </defs>
            </svg>            <select className="attendance-input-field date-field" type="text"
              value={selectedStatus}
              onChange={(e) => setSelectedStatus(e.target.value)}
            >
              <option value="">All</option>

              <option value="approved/">Approved</option>
              <option value="rejected/">Rejected</option>
              <option value="pending/">Pending</option>




            </select>

          </div>

        </div>
      </div> */}
      <div className="custom-table-background">
        <div className="table-heading-flex">
          <div className="repo-heading"> {title} Request
          </div>
          <div>
            {/* <Department getDepartment={getDepartment}/> */}
          </div>
        </div>
        <table className="custom-table">
          <thead>
            <tr className="custom-table-head-tr">
              <th className="align-left">Sr no.</th>
              <th className="align-leftt">Date</th>
              <th className="align-center">Type</th>
              <th className="align-center">Duration</th>
              <th className="align-center">Status</th>
              <th className="align-right">Action</th>
            </tr>
          </thead>
          <tbody>
            {allrequest?.sort((a, b) => new Date(b.date) - new Date(a.date)).map((i, index) => (
              <React.Fragment key={index}>
                <tr className="tr-border-bottom">
                  <td colSpan="9"></td>
                </tr>
                <tr className="custom-table-head-td">
                  <td className="align-left">{index + 1}</td>
                  <td className="align-leftt">{formatDate(i.date)} &<br /> {formatDate(i.end_date)}</td>
                  <td className="align-center">{i.leave_Duration ? "Leave" : ""} {i.outduty_Duration ? "OutDuty" : ""}</td>
                  <td className="align-center form-text-trasformation-uppercase">{i.leave_Duration ? i.leave_Duration : i.outduty_Duration}</td>
                  {/* <td request-status={i.rh_leave_status} className="request-status align-center" >{i.rh_leave_status}</td> */}
                  <td className="align-center">
                    <span request-status={i.rh_leave_status} className="request-status align-center" >{i.rh_leave_status === "pending" ? "Pending For Rh Approval ⌛" : i.rh_leave_status === "rejected" ? "Rh Rejected ✖, " : "Rh Approved ✔, "}</span>
                    <br />
                    <span request-status={i.admin_leave_status} className="request-status align-center" >{(i.rh_leave_status === "rejected" && i.admin_leave_status === "rejected") ? "Admin Rejected ✖, " : (i.rh_leave_status === "approved" && i.admin_leave_status === "pending") ? "Pending For Admin Approval ⌛," : (i.rh_leave_status === "approved" && i.admin_leave_status === "rejected") ? "Admin Rejected ✖, " : (i.rh_leave_status === "approved" && i.admin_leave_status === "approved") ? "Admin Approved ✔, " : ""}</span>
                  </td>
                  {/* <td className="align-center">{i.rh_leave_status}</td> */}
                  <td className="align-right ">
                    {i.rh_leave_status === "pending" ? <LeaveModelDelete i={i} getAllrequest={getAllrequest} /> : ""}
                  </td>

                </tr>
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </>
  )
}

const OutdutyTable = ({ title }) => {

  const today = new Date();
  today.setDate(today.getDate() - 30);

  const year = today.getFullYear();
  const month = today.getMonth() + 1;
  const day = today.getDate();

  const formattedDatee = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;

  const [allrequest, setAllRequest] = useState([])

  const [startdate, setStartDate] = useState(`${formattedDatee}`);
  const [enddate, setEndDate] = useState(`${formattedDate}`);
  const [selectedStatus, setSelectedStatus] = useState('');
  const getAllrequest = async () => {
    try {
      const res = await axios.get(`${BASE_URL}/wfm/odbyemployee/${sessionStorage.getItem("emp_code")}/`);
      setAllRequest(res.data);

    } catch (err) {
    }
  };

  console.log(allrequest)
  useEffect(() => {
    getAllrequest();
  }, []);


  return (
    <>
      {/* <div className="attendance-history-cont">
        <div className="attendance-heading"> History</div>
        <div className="attendance-subcont">
          <div className="field-cont">
            <input className="attendance-input-field date-field" placeholder='From Date' type="date" value={startdate} onChange={(e) => setStartDate(e.target.value)} /><hr />
            <input className="attendance-input-field date-field" placeholder='To Date' type="date" value={enddate} onChange={(e) => setEndDate(e.target.value)} /><hr />
            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 14 14" fill="none">
              <g clip-path="url(#clip0_650_3324)">
                <path d="M2.625 10.2083H1.16667C0.857247 10.2083 0.560501 10.3312 0.341709 10.55C0.122916 10.7688 0 11.0655 0 11.3749L0 12.8333C0 13.1427 0.122916 13.4394 0.341709 13.6582C0.560501 13.877 0.857247 13.9999 1.16667 13.9999H2.625C2.93442 13.9999 3.23117 13.877 3.44996 13.6582C3.66875 13.4394 3.79167 13.1427 3.79167 12.8333V11.3749C3.79167 11.0655 3.66875 10.7688 3.44996 10.55C3.23117 10.3312 2.93442 10.2083 2.625 10.2083ZM2.625 12.8333H1.16667V11.3749H2.625V12.8333Z" fill="#707070" />
                <path d="M12.8333 10.2083H11.3749C11.0655 10.2083 10.7688 10.3312 10.55 10.55C10.3312 10.7688 10.2083 11.0655 10.2083 11.3749V12.8333C10.2083 13.1427 10.3312 13.4394 10.55 13.6582C10.7688 13.877 11.0655 13.9999 11.3749 13.9999H12.8333C13.1427 13.9999 13.4394 13.877 13.6582 13.6582C13.877 13.4394 13.9999 13.1427 13.9999 12.8333V11.3749C13.9999 11.0655 13.877 10.7688 13.6582 10.55C13.4394 10.3312 13.1427 10.2083 12.8333 10.2083ZM12.8333 12.8333H11.3749V11.3749H12.8333V12.8333Z" fill="#707070" />
                <path d="M2.625 5.10425H1.16667C0.857247 5.10425 0.560501 5.22716 0.341709 5.44596C0.122916 5.66475 0 5.9615 0 6.27091L0 7.72925C0 8.03867 0.122916 8.33541 0.341709 8.55421C0.560501 8.773 0.857247 8.89592 1.16667 8.89592H2.625C2.93442 8.89592 3.23117 8.773 3.44996 8.55421C3.66875 8.33541 3.79167 8.03867 3.79167 7.72925V6.27091C3.79167 5.9615 3.66875 5.66475 3.44996 5.44596C3.23117 5.22716 2.93442 5.10425 2.625 5.10425ZM2.625 7.72925H1.16667V6.27091H2.625V7.72925Z" fill="#707070" />
                <path d="M12.8333 5.10425H11.3749C11.0655 5.10425 10.7688 5.22716 10.55 5.44596C10.3312 5.66475 10.2083 5.9615 10.2083 6.27091V7.72925C10.2083 8.03867 10.3312 8.33541 10.55 8.55421C10.7688 8.773 11.0655 8.89592 11.3749 8.89592H12.8333C13.1427 8.89592 13.4394 8.773 13.6582 8.55421C13.877 8.33541 13.9999 8.03867 13.9999 7.72925V6.27091C13.9999 5.9615 13.877 5.66475 13.6582 5.44596C13.4394 5.22716 13.1427 5.10425 12.8333 5.10425ZM12.8333 7.72925H11.3749V6.27091H12.8333V7.72925Z" fill="#707070" />
                <path d="M2.625 0H1.16667C0.857247 0 0.560501 0.122916 0.341709 0.341709C0.122916 0.560501 0 0.857247 0 1.16667L0 2.625C0 2.93442 0.122916 3.23117 0.341709 3.44996C0.560501 3.66875 0.857247 3.79167 1.16667 3.79167H2.625C2.93442 3.79167 3.23117 3.66875 3.44996 3.44996C3.66875 3.23117 3.79167 2.93442 3.79167 2.625V1.16667C3.79167 0.857247 3.66875 0.560501 3.44996 0.341709C3.23117 0.122916 2.93442 0 2.625 0ZM2.625 2.625H1.16667V1.16667H2.625V2.625Z" fill="#707070" />
                <path d="M7.72925 10.2083H6.27091C5.9615 10.2083 5.66475 10.3312 5.44596 10.55C5.22716 10.7688 5.10425 11.0655 5.10425 11.3749V12.8333C5.10425 13.1427 5.22716 13.4394 5.44596 13.6582C5.66475 13.877 5.9615 13.9999 6.27091 13.9999H7.72925C8.03867 13.9999 8.33541 13.877 8.55421 13.6582C8.773 13.4394 8.89592 13.1427 8.89592 12.8333V11.3749C8.89592 11.0655 8.773 10.7688 8.55421 10.55C8.33541 10.3312 8.03867 10.2083 7.72925 10.2083ZM7.72925 12.8333H6.27091V11.3749H7.72925V12.8333Z" fill="#707070" />
                <path d="M7.72925 5.10425H6.27091C5.9615 5.10425 5.66475 5.22716 5.44596 5.44596C5.22716 5.66475 5.10425 5.9615 5.10425 6.27091V7.72925C5.10425 8.03867 5.22716 8.33541 5.44596 8.55421C5.66475 8.773 5.9615 8.89592 6.27091 8.89592H7.72925C8.03867 8.89592 8.33541 8.773 8.55421 8.55421C8.773 8.33541 8.89592 8.03867 8.89592 7.72925V6.27091C8.89592 5.9615 8.773 5.66475 8.55421 5.44596C8.33541 5.22716 8.03867 5.10425 7.72925 5.10425ZM7.72925 7.72925H6.27091V6.27091H7.72925V7.72925Z" fill="#707070" />
                <path d="M7.72925 0H6.27091C5.9615 0 5.66475 0.122916 5.44596 0.341709C5.22716 0.560501 5.10425 0.857247 5.10425 1.16667V2.625C5.10425 2.93442 5.22716 3.23117 5.44596 3.44996C5.66475 3.66875 5.9615 3.79167 6.27091 3.79167H7.72925C8.03867 3.79167 8.33541 3.66875 8.55421 3.44996C8.773 3.23117 8.89592 2.93442 8.89592 2.625V1.16667C8.89592 0.857247 8.773 0.560501 8.55421 0.341709C8.33541 0.122916 8.03867 0 7.72925 0ZM7.72925 2.625H6.27091V1.16667H7.72925V2.625Z" fill="#707070" />
                <path d="M12.8333 0H11.3749C11.0655 0 10.7688 0.122916 10.55 0.341709C10.3312 0.560501 10.2083 0.857247 10.2083 1.16667V2.625C10.2083 2.93442 10.3312 3.23117 10.55 3.44996C10.7688 3.66875 11.0655 3.79167 11.3749 3.79167H12.8333C13.1427 3.79167 13.4394 3.66875 13.6582 3.44996C13.877 3.23117 13.9999 2.93442 13.9999 2.625V1.16667C13.9999 0.857247 13.877 0.560501 13.6582 0.341709C13.4394 0.122916 13.1427 0 12.8333 0ZM12.8333 2.625H11.3749V1.16667H12.8333V2.625Z" fill="#707070" />
              </g>
              <defs>
                <clipPath id="clip0_650_3324">
                  <rect width="14" height="14" fill="white" />
                </clipPath>
              </defs>
            </svg>            <select className="attendance-input-field date-field" type="text"
              value={selectedStatus}
              onChange={(e) => setSelectedStatus(e.target.value)}
            >
              <option value="">All</option>

              <option value="approved/">Approved</option>
              <option value="rejected/">Rejected</option>
              <option value="pending/">Pending</option>




            </select>

          </div>

        </div>
      </div> */}
      <div className="custom-table-background">
        <div className="table-heading-flex">
          <div className="repo-heading"> {title} Request
          </div>
          <div>
            {/* <Department getDepartment={getDepartment}/> */}
          </div>
        </div>
        <table className="custom-table">
          <thead>
            <tr className="custom-table-head-tr">
              <th className="align-left">Sr no.</th>
              <th className="align-leftt">Date</th>
              <th className="align-center">Type</th>
              <th className="align-center">Duration</th>
              <th className="align-center">Status</th>
              <th className="align-right">Action</th>
            </tr>
          </thead>
          <tbody>
            {allrequest?.sort((a, b) => new Date(b.date) - new Date(a.date)).map((i, index) => (
              <React.Fragment key={index}>
                <tr className="tr-border-bottom">
                  <td colSpan="9"></td>
                </tr>
                <tr className="custom-table-head-td">
                  <td className="align-left">{index + 1}</td>
                  <td className="align-leftt">{formatDate(i.date)} &<br /> {formatDate(i.end_date)}</td>
                  <td className="align-center">{i.leave_Duration ? "Leave" : ""} {i.outduty_Duration ? "OutDuty" : ""}</td>
                  <td className="align-center form-text-trasformation-uppercase">{i.leave_Duration ? i.leave_Duration : i.outduty_Duration}</td>
                  {/* <td className="align-center">{i.rh_leave_status}</td> */}
                  {/* <td request-status={i.rh_leave_status} className="request-status align-center" >{i.rh_leave_status}</td> */}
                  <td className="align-center">
                    <span request-status={i.rh_leave_status} className="request-status align-center" >{i.rh_leave_status === "pending" ? "Pending For Rh Approval ⌛" : i.rh_leave_status === "rejected" ? "Rh Rejected ✖, " : "Rh Approved ✔, "}</span>
                    <br />
                    <span request-status={i.admin_leave_status} className="request-status align-center" >{(i.rh_leave_status === "rejected" && i.admin_leave_status === "rejected") ? "Admin Rejected ✖, " : (i.rh_leave_status === "approved" && i.admin_leave_status === "pending") ? "Pending For Admin Approval ⌛," : (i.rh_leave_status === "approved" && i.admin_leave_status === "rejected") ? "Admin Rejected ✖, " : (i.rh_leave_status === "approved" && i.admin_leave_status === "approved") ? "Admin Approved ✔, " : ""}</span>
                  </td>
                  <td className="align-right ">
                    {i.rh_leave_status === "pending" ? <OutdutyModelDelete i={i} getAllrequest={getAllrequest} /> : ""}
                  </td>

                </tr>
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </>
  )
}




const Leave = () => {
  const [toggleState, setToggleState] = useState(2);

  const [statData, setStatData] = useState({});
  const [leavesData, setLeavesData] = useState({});
  const getStatdata = async () => {
    try {
      const res = await axios.get(`${BASE_URL}/wfm/requestsapplied/${sessionStorage.getItem('emp_code')}`);
      const ress = await axios.get(`${BASE_URL}/wfm/leavebalance/${sessionStorage.getItem("emp_code")}/`);
      // console.log("res.data")
      // console.log(res.data)
      setStatData(res.data);
      setLeavesData(ress.data);
    } catch (err) {
      alert(err.message);
    }
  };

  const [graphData, setGraphData] = useState({});
  const getGraphdata = async () => {
    try {
      const res = await axios.get(`${BASE_URL}/wfm/requeststatus/${sessionStorage.getItem('emp_code')}`);
      console.log(res.data)
      setGraphData(res.data);
    } catch (err) {
      alert(err.message);
    }
  };


  useEffect(() => {
    getStatdata();
    getGraphdata();
  }, []);




  const getSelectedTitle = () => {
    switch (toggleState) {
      case 1:
        return 'All';
      case 2:
        return 'Leave';
      case 3:
        return 'Outduty';
      case 4:
        return 'Other';

      default:
        return 'All';
    }
  };

  return (
    <>
      {toggleState === 2
        ?
        (
          <div>

            <div className="attendance-top-cont">
              <div className="profile-leave-primary">

                <div className="profile-leave-status">
                  <span className="profile-leave-status-heading">Total Leave Requests</span>
                  <span>{graphData.allleaverequest} </span>
                </div>
                <div className="profile-leave-status">
                  <span className="profile-leave-status-heading">
                    Pending Requests
                  </span>
                  <span>{graphData.leave_details_pending} </span>
                </div>
                <div className="profile-leave-status">
                  <span className="profile-leave-status-heading">
                    Approved Requests
                  </span>
                  <span>{graphData.leave_details_approved} </span>
                </div>
                <div className="profile-leave-status">
                  <span className="profile-leave-status-heading">
                    Rejected Requests
                  </span>
                  <span>{graphData.leave_details_rejected} </span>
                </div>



              </div>

              <div className="profile-leave-secondary">
                <LeaveChart />
              </div>
            </div>
            <br />
            <div className="bloc-tabs-with-white-bg">
              <div className="field-container" style={{ display: 'flex', justifyContent: 'space-evenly' }}>

                <div className="bloc-tabs-lb">

                  <label className="age-label-main">
                    Previous Leaves:{" "}
                    <span className="age-label">{leavesData.leavebalance ? `${leavesData.leavebalance} leave` : "No Remaining"}</span>
                  </label>
                </div>
                <div className="bloc-tabs-lb">

                  <label className="age-label-main">
                    Earned Leaves:{" "}
                    <span className="age-label">{leavesData.earn_leave ? `${leavesData.earn_leave} leave` : "No Remaining"}</span>
                  </label>

                </div>
                <div className="bloc-tabs-lb">
                  <label className="age-label-main">
                    Casual Leaves:{" "}
                    <span className="age-label">{leavesData.casual_leave ? `${leavesData.casual_leave} leave` : "No Remaining"}</span>
                  </label>
                </div>

                <div className="bloc-tabs-lb">
                  <label className="age-label-main">
                    Sick Leaves:{" "}
                    <span className="age-label">{leavesData.sick_leave ? `${leavesData.sick_leave} leave` : "No Remaining"}</span>
                  </label>
                </div>

              </div>
              <div className="btn-cont">
              </div>
            </div>
          </div>)
        : (<div className="attendance-top-cont">
          <div className="profile-leave-primary">

            <div className="profile-leave-status">
              <span className="profile-leave-status-heading">Total OutDuty Requests</span>
              <span>{graphData.alloutdutyrequest} </span>
            </div>
            <div className="profile-leave-status">
              <span className="profile-leave-status-heading">
                Pending Requests
              </span>
              <span>{graphData.outduty_details_pending} </span>
            </div>
            <div className="profile-leave-status">
              <span className="profile-leave-status-heading">
                Approved Requests
              </span>
              <span>{graphData.outduty_details_approved} </span>
            </div>
            <div className="profile-leave-status">
              <span className="profile-leave-status-heading">
                Rejected Requests
              </span>
              <span>{graphData.outduty_details_rejected} </span>
            </div>
          </div>

          <div className="profile-leave-secondary">
            <OutDutyChart />
          </div>
        </div>)}
      {/* <div className="attendance-heading">Request History</div> */}

      <div className="bloc-tabss">
        {/* <button
          className={
            "leave-tab " +
            (toggleState === 1
              ? "dashboardcomponent-tabs dashboardcomponent-active-tabs dashboardcomponent-tabss"
              : "dashboardcomponent-tabs dashboardcomponent-tabss")
          }
          onClick={() => setToggleState(1)}
        >
          All
        </button> */}
        <button
          className={
            "leave-tab " +
            (toggleState === 2
              ? "dashboardcomponent-tabs dashboardcomponent-active-tabs dashboardcomponent-tabss"
              : "dashboardcomponent-tabs dashboardcomponent-tabss")
          }
          onClick={() => setToggleState(2)}
        >
          Leave
        </button>
        <button
          className={
            "leave-tab " +
            (toggleState === 3
              ? "dashboardcomponent-tabs dashboardcomponent-active-tabs dashboardcomponent-tabss"
              : "dashboardcomponent-tabs dashboardcomponent-tabss")
          }
          onClick={() => setToggleState(3)}
        >
          Outduty
        </button>

        {/* <button
            className={
              "leave-tab " +
              (toggleState === 4
                ? "dashboardcomponent-tabs dashboardcomponent-active-tabs dashboardcomponent-tabss"
                : "dashboardcomponent-tabs dashboardcomponent-tabss")
            }
            onClick={() => setToggleState(4)}
          >
            Other
          </button> */}
      </div>

      <div className="content-tab-nomargin">
        {toggleState === 1 ? <AllRequest title={getSelectedTitle()} /> : null}
        {toggleState === 2 ? <LeaveTable title={getSelectedTitle()} /> : null}
        {toggleState === 3 ? <OutdutyTable title={getSelectedTitle()} /> : null}
        {/* {toggleState === 4 ? <LeaveTable title={getSelectedTitle()}/>  : null} */}
      </div>

    </>
  );
};

export default Leave;