import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { BASE_URL } from "../../../config/axios"
import axios from "axios";
import Careernavbar from "./Careernavbar";
import TimelineItem from "../../Timeline";

const Workexprience = () => {

  let { id, email } = useParams()

  const [inputFields, setInputFields] = useState([
    { company: "", startDate: "", endDate: "", jobposition: "", jobtype: "", monthlyCtc: "", monthlyInhand: "", user: email },
  ]);
  const [errors, setErrors] = useState({});

  const handleChange = (index, e) => {
    const { name, value } = e.target;
    const updatedFields = [...inputFields];
    updatedFields[index][name] = value;
    setInputFields(updatedFields);
  };

  const handleAddInputField = () => {
    setInputFields([
      ...inputFields,
      { company: "", startDate: "", endDate: "", jobposition: "", jobtype: "", monthlyCtc: "", monthlyInhand: "", user: email },
    ]);
  };

  const handleRemoveInputField = (index) => {
    const updatedFields = [...inputFields];
    updatedFields.splice(index, 1);
    setInputFields(updatedFields);
  };

  const validateForm = () => {
    const newErrors = {};
    inputFields.length >= 2 && inputFields.forEach((field, index) => {
      const requiredFields = [
        "company",
        "jobposition",
        "startDate",
        "endDate",
        "jobtype",
        "monthlyCtc",
        "monthlyInhand",

      ];
      requiredFields.forEach((reqField) => {
        if (!field[reqField]) {
          newErrors[`${reqField}_${index}`] = `${reqField.charAt(0).toUpperCase() + reqField.slice(1)
            } is required!`;
        }
      });
    });
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  console.log(inputFields);
  console.log(inputFields.length)


  const navigate = useNavigate()
  const navigatee = useNavigate()
  const [loading, setLoading] = useState(false); //loading logic

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      setLoading(true); //loading logic

      try {
        let res = await axios.post(`${BASE_URL}/userdata/userworkexpbulkdata/`, inputFields,)
        if (res.status === 200) {
          navigate(`/document/${id}/${email}/`)

        } else {
          alert(res);
        }
      } catch (err) {
        alert(err);
      } finally {
        setLoading(false); //loading logic
      }
    }
  };




  const handleFormPut = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      setLoading(true); //loading logic
      try {
        // Assuming your backend supports updating existing education details via PUT
        await Promise.all(
          inputFields.map(async (workexperience) => {
            if (workexperience.id) {
              // Existing education detail, update it
              await axios.put(`${BASE_URL}/userdata/userworkexp/${workexperience.id}/`, workexperience);
            } else if (workexperience.company != "") {
              // New education detail, create it
              await axios.post(`${BASE_URL}/userdata/userworkexp/`, workexperience);
            }

          })
        );

        navigate(`/document/${id}/${email}/`)

      } catch (err) {
        alert(err);
      } finally {
        setLoading(false); //loading logic
      }
    }
  };

  const backroute = () => {
    navigatee(`/educationdetail/${id}/${email}/`)

  }


  const [data, setData] = useState(false)

  useEffect(() => {
    // const storedUserData = Cookies.get("userData");

    const getUSerDetails = async () => {
      try {
        const res = await axios.get(`${BASE_URL}/userdata/userworkexpallget/${email}/`);
        if (res.data.length !== 0) {
          setInputFields(res.data);
        }
        setData(true)
      } catch (err) {
        // alert(err.message);
      }
    };

    getUSerDetails();
  }, [email]);

  return (

    <>
      <Careernavbar id={id} />


      <div className="jd">
        {/*  ************** timeline Seaction  ************** */}

        <div className="jd-timeline">
        <TimelineItem heading="Personal Information" color="#2576BC" />
          <div className="jd-timeline-border timeline-color"></div>

          <TimelineItem heading="Position Information" color="#2576BC" />
          <div className="jd-timeline-border timeline-color"></div>

          <TimelineItem heading="Education Detail" color="#2576BC" />
          <div className="jd-timeline-border timeline-color"></div>
          <TimelineItem heading="Work Experience" color="#2576BC" />
          <div className="jd-timeline-border "></div>
          <TimelineItem heading="Documents Upload" color="#707070" />
        </div>


        <div className="jd-heading-outer">
          <div className="jd-heading-outer-flex">
            <h2 className="jd-heading-main">Work Experience *</h2>

            <h2 className="jd-heading-main-right">
              Please click here to add more{" "}
              <button
                type="button"
                onClick={handleAddInputField}
                className="jd-heading-main-right-button"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <g clipPath="url(#clip0_450_2151)">
                    <path
                      d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM17 13H13V17H11V13H7V11H11V7H13V11H17V13Z"
                      fill="#2576BC"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_450_2151">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </button>
            </h2>
          </div>
          <div>
            <div className="jd-heading-bottom-bold"></div>
            <div className="jd-heading-bottom-light"></div>
          </div>
        </div>

        <form className="register-form" >
          {inputFields.map((field, index) => (
            <div key={index} className="form-flex-wrap">
              <div className="flex-column">
                <label htmlFor={`company_${index}`} className="form-labels">
                  Company {index + 1}{inputFields[index].company != "" || inputFields.length > 1 ? <span className="required">*</span> : ""}
                </label>
                <input
                  type="text"
                  id={`company_${index}`}
                  maxLength={100}
                  name="company"
                  value={field.company}
                  onChange={(e) => handleChange(index, e)}
                  className={`form-text-trasformation-uppercase form-input ${errors[`company_${index}`]
                    ? "error"
                    : field.company
                      ? "success"
                      : ""
                    }`}
                />
                {errors[`company_${index}`] && (
                  <span className="error-message">
                    {errors[`company_${index}`]}
                  </span>
                )}
              </div>

              <div className="flex-column">
                <label htmlFor={`jobposition_${index}`} className="form-labels">
                  Job Position{inputFields[index].company != "" || inputFields.length > 1 ? <span className="required">*</span> : ""}
                </label>
                <input
                  type="text"
                  id={`jobposition_${index}`}
                  maxLength={100}
                  name="jobposition"
                  value={field.jobposition}
                  onChange={(e) => handleChange(index, e)}
                  className={`form-text-trasformation-uppercase form-input ${errors[`jobposition_${index}`]
                    ? "error"
                    : field.jobposition
                      ? "success"
                      : ""
                    }`}
                />
                {errors[`jobposition_${index}`] && (
                  <span className="error-message">
                    {errors[`jobposition_${index}`]}
                  </span>
                )}
              </div>

              <div className="flex-column">
                <label htmlFor={`startDate_${index}`} className="form-labels">
                  Start Date{inputFields[index].company != "" || inputFields.length > 1 ? <span className="required">*</span> : ""}
                </label>
                <input
                  type="date"
                  id={`startDate_${index}`}
                  name="startDate"
                  value={field.startDate}
                  onChange={(e) => handleChange(index, e)}
                  className={`form-input ${errors[`startDate_${index}`]
                    ? "error"
                    : field.startDate
                      ? "success"
                      : ""
                    }`}
                />
                {errors[`startDate_${index}`] && (
                  <span className="error-message">
                    {errors[`startDate_${index}`]}
                  </span>
                )}
              </div>

              <div className="flex-column">
                <label htmlFor={`endDate_${index}`} className="form-labels">
                  End Date{inputFields[index].company != "" || inputFields.length > 1 ? <span className="required">*</span> : ""}
                </label>
                <input
                  type="date"
                  id={`endDate_${index}`}
                  name="endDate"
                  value={field.endDate}
                  onChange={(e) => handleChange(index, e)}
                  className={`form-input ${errors[`endDate_${index}`]
                    ? "error"
                    : field.endDate
                      ? "success"
                      : ""
                    }`}
                />
                {errors[`endDate_${index}`] && (
                  <span className="error-message">
                    {errors[`endDate_${index}`]}
                  </span>
                )}
              </div>

              <div className="flex-column form-group-select">
                <label className="form-labels" htmlFor={`jobtype_${index}`}>
                  Job Type{inputFields[index].company != "" || inputFields.length > 1 ? <span className="required">*</span> : ""}
                </label>
                <br />
                <select
                  id={`jobtype_${index}`}
                  name="jobtype"
                  value={field.jobtype}
                  onChange={(e) => handleChange(index, e)}
                  className={`form-input form-group-selection ${errors[`jobtype_${index}`]
                    ? "error"
                    : field.jobtype
                      ? "success"
                      : ""
                    }`}
                >
                  <option value="">Select Job Type</option>
                  <option value="full-time">Full Time</option>
                  <option value="part-time">Part Time</option>
                  <option value="contract">Contract</option>
                  <option value="freelance">Freelance</option>
                  <option value="internship">Internship</option>
                </select>
                <div className="form-group-selection-arrow">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="25"
                    fill="currentColor"
                    class="bi bi-caret-down-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                  </svg>
                </div>
                <div>
                  {errors[`jobtype_${index}`] && (
                    <span className="error-message">
                      {errors[`jobtype_${index}`]}
                    </span>
                  )}
                </div>
              </div>
              {/* Similar blocks for Department, Start Date, End Date, and Grade */}
              <div className="flex-column">
                <label htmlFor={`monthlyCtc_${index}`} className="form-labels">
                  Monthly CTC{inputFields[index].company != "" || inputFields.length > 1 ? <span className="required">*</span> : ""}
                </label>
                <input
                  type="text"
                  id={`monthlyCtc_${index}`}
                  maxLength={15}
                  name="monthlyCtc"
                  value={field.monthlyCtc}
                  onChange={(e) => handleChange(index, e)}
                  className={`form-input ${errors[`monthlyCtc_${index}`]
                    ? "error"
                    : field.monthlyCtc
                      ? "success"
                      : ""
                    }`}
                />
                {errors[`monthlyCtc_${index}`] && (
                  <span className="error-message">
                    {errors[`monthlyCtc_${index}`]}
                  </span>
                )}
              </div>
              <div className="flex-column">
                <label htmlFor={`monthlyInhand_${index}`} className="form-labels">
                  Monthly In Hand{inputFields[index].company != "" || inputFields.length > 1 ? <span className="required">*</span> : ""}
                </label>
                <input
                  type="text"
                  maxLength={15}
                  id={`monthlyInhand_${index}`}
                  name="monthlyInhand"
                  value={field.monthlyInhand}
                  onChange={(e) => handleChange(index, e)}
                  className={`form-input ${errors[`monthlyInhand_${index}`]
                    ? "error"
                    : field.monthlyInhand
                      ? "success"
                      : ""
                    }`}
                />
                {errors[`monthlyInhand_${index}`] && (
                  <span className="error-message">
                    {errors[`monthlyInhand_${index}`]}
                  </span>
                )}
              </div>

              <div>
                {index > 0 ? (
                  <button
                    type="button"
                    onClick={() => handleRemoveInputField(index)}
                    className="remove-button"
                  >
                    Remove
                  </button>
                ) : (
                  ""
                )}
              </div>
            </div>
          ))}

          <div className="button-models">
            <button className="model-button" onClick={backroute}>Back</button>
            {data ? (<button onClick={handleFormPut} className="model-button model-button-submit" disabled={loading}>
              {inputFields[0].company != "" || inputFields.length > 1 ? "Next" : "Skip"}
            </button>) : (<button onClick={handleFormSubmit} className="model-button model-button-submit" disabled={loading}>
              {inputFields[0].company != "" || inputFields.length > 1 ? "Next" : "Skip"}
            </button>)}
          </div>
        </form>
      </div>

    </>
  );
};

export default Workexprience;
