
import React, {useState, useEffect  } from "react";
import {AssetModel, AssetModelUpdate, AssetModelDelete} from "./AssetModel";
import axios from "axios";
import { BASE_URL } from "../../config/axios";

const Assettable = () => {
  
  
    const [assetname, setAssetname] = useState([]) 

    const getAsset = async () => {
        try {
        const res = await axios.get(`${BASE_URL}/wfm/assets/`);
        setAssetname(res.data)
      } catch (err) {
            // alert(err.message);
      }
    };
    
    
    useEffect(() => {
        getAsset();
    }, []);


  return(
    <>
     <div className="custom-table-background">
        <div className="table-heading-flex">
            <div className="repo-heading">Asset</div>
               <div><AssetModel getAsset={getAsset}/></div>
        </div>
      <table className="custom-table">
        <thead>
          <tr className="custom-table-head-tr">
          <th className="align-left">Emp.Code</th>
          <th className="align-leftt">Name</th>
          <th className="align-center">Date</th>
          <th className="align-center">Discription</th>
          <th className="align-center">Quantity</th>
          <th className="align-center">Item</th>
          <th className="align-center">Action</th>

          </tr>
        </thead>
        <tbody>
          {assetname.map((i, index) => (
            <React.Fragment key={index}>
              <tr className="tr-border-bottom">
                <td colSpan="6"></td>
              </tr>
              <tr className="custom-table-head-td">

                <td className="align-left">{index+1}</td>
                <td className="align-leftt">{i.name}</td>
                <td className="align-center">{i.date }</td>
                <td className="align-center">{i.description}</td>
                <td className="align-center">{i.quantity}</td>
                <td className="align-center">{i.item}</td>
                <td className="align-right ">
                <AssetModelUpdate i={i} getAsset={getAsset}/>                     
                <AssetModelDelete i={i} getAsset={getAsset}/>
                </td>
              </tr>
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
    </>
  )
}

export default Assettable
