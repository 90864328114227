import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { BASE_URL } from "../../../config/axios"
import axios from "axios";
import Docs from "../../../components/images/docs.svg";
import Profile from "../../../components/images/profile.svg";
import TimelineItem from "../../../components/Timeline";

const DocumentAdd = ({ getUserDocs }) => {


  let { id, email } = useParams()


  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  const [formData, setFormData] = useState({
    user: email,
    document_name: "",
    document_type: "",
    document_file: "",

  });

  // !  ************** Validation start **************  ! //

  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = ["document_name", "document_type", "document_file"];
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  // ?  ************** Validation End **************  ! //
  const [loading, setLoading] = useState(false); //loading logic

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      setLoading(true); //loading logic

      const formDataa = new FormData();
      formDataa.append("user", formData.user);

      formDataa.append("document_name", formData.document_name);
      formDataa.append("document_type", formData.document_type);
      formDataa.append("document_file", formData.document_file);
      try {
        let res = await axios.post(`${BASE_URL}/userdata/userdocs/`, formDataa, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        if (res.status === 200) {
          handleClose();
          await getUserDocs();
        } else {
          alert(res);
        }
      } catch (err) {
        alert(err);
      } finally {
        setLoading(false); // Ensure loading state is stopped even in case of error //loading logic
      }
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFileChange = (e) => {
    const { name } = e.target;
    const file = e.target.files[0];

    if (file) {
      if (file.type === "application/pdf") {
        setFormData({
          ...formData,
          [name]: file,
        });
      } else {
        setErrors({
          ...errors,
          [name]: "Invalid file type. Please select a PDF file.",
        });
      }
    } else {
      setFormData({
        ...formData,
        [name]: null,
      });
    }
  };

  const handleFileClear = (name) => {
    setFormData({
      ...formData,
      [name]: null,
    });
  };

  return (
    <>
      <div className="models-button model-add" onClick={handleShow} >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <g clip-path="url(#clip0_1415_3177)">
            <path
              d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM17 13H13V17H11V13H7V11H11V7H13V11H17V13Z"
              fill="#2576BC"
            />
          </g>
          <defs>
            <clipPath id="clip0_1415_3177">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </div>

      <Modal show={show} onHide={handleClose} dialogClassName="request-leave">
        {/* <Modal.Header closeButton>
          <Modal.Title>Request Leave</Modal.Title>
        </Modal.Header> */}
        <Modal.Body>
          <div className="parent-div">
            <div className="bdy-div">
              <h6 className="announce-heading">Document</h6>

              <form>
                <div className="flex-column">
                  <label htmlFor="document_name" className="form-labels announce-date">
                    Document Name
                  </label>
                  <input
                    id="document_name"
                    type="document_name"
                    name="document_name"
                    placeholder="Document Name"
                    onChange={handleInputChange}
                    value={formData.document_name}
                    className={`form-input ${errors.document_name ? "error" : inputState.document_name ? "success" : ""
                      }`}
                  />
                  {errors.document_name && (
                    <span className="error-message">{errors.document_name}</span>
                  )}
                </div>

                <div className="flex-column form-group-selectt">
                  <label htmlFor="document_type" className="form-labels">
                    Document Type<span className="required">*</span>
                  </label>
                  <br />
                  <select
                    id="document_type"
                    name="document_type"
                    value={formData.document_type}
                    onChange={handleInputChange}
                    className={`form-input form-group-selection ${errors.document_type ? "error" : inputState.document_type ? "success" : ""
                      }`}
                  >
                    <option value="">Select a Document Type</option>
                    <option value="identity">Identity</option>
                    <option value="previousExperience">Previous Experience</option>
                    <option value="degree">Degree</option>


                  </select>
                  <div className="form-group-selection-arrow">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="25"
                      fill="currentColor"
                      class="bi bi-caret-down-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                    </svg>
                  </div>
                  <div>
                    {errors.document_type && (
                      <span className="error-message">{errors.document_type}</span>
                    )}
                  </div>
                </div>



                <div className="flex-column">
                  <label htmlFor="attachment" className="form-labels announce-date">
                    Attachment
                  </label>
                  <input
                    id="file"
                    type="file"
                    name="document_file"
                    accept=".pdf"
                    onChange={handleFileChange}
                    className="file-input"
                  />

                  {formData.document_file ? (
                    <span
                      className="file-clear"
                      onClick={() => handleFileClear("file")}
                    >
                      Clear
                    </span>
                  ) : null}
                </div>
              </form>
              <div className="button-models">
                <button className="model-button" onClick={handleClose}>
                  Cancel
                </button>
                <button
                  type="submit"
                  className="model-button model-button-leave"
                  onClick={handleSubmit}
                  disabled={loading}
                >
                  Add
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

const Jobapplicantdocuments = () => {
  let { email } = useParams()

  const [docData, setDocData] = useState([])

  const getUserDocs = async () => {
    try {
      const ress = await axios.get(`${BASE_URL}/userdata/userdocsfilter/${email}/`);
      setDocData(ress.data);
    } catch (err) {
      // alert(err.message);
    }
  };

  useEffect(() => {
    getUserDocs();
  }, []);


  const navigatee = useNavigate()


  const backroute = () => {
    navigatee(`/accountdetailss/${email}/`)
  }


  const [formData, setFormData] = useState({
    user: email,
    document_name: "",
    document_type: "",
    document_file: "",

  });

  const [loadingAadhar, setLoadingAadhar] = useState(false); //loading logic

  const handleSubmitaadhaar = async (e) => {
    e.preventDefault();


    const formDataa = new FormData();
    formDataa.append("user", email);

    formDataa.append("document_name", "Aadhaar");
    formDataa.append("document_type", "identity");
    formDataa.append("document_file", formData.document_file);
    if (formData.document_file != '') {
      setLoadingAadhar(true); //loading logic


      try {
        let res = await axios.post(`${BASE_URL}/userdata/userdocs/`, formDataa, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        if (res.status === 200) {
          window.location.reload();
          //   handleClose();
        } else {
          alert(res);
        }
      } catch (err) {
        alert(err);
      } finally {
        setLoadingAadhar(false); // Ensure loading state is stopped even in case of error //loading logic
      }
    }
    else {
      alert("Please upload a file")
    }
  };

  console.log(formData)

  const [loadingCV, setLoadingCV] = useState(false); //loading logic

  const handleSubmitcv = async (e) => {
    e.preventDefault();


    console.log(formData)
    const formDataa = new FormData();
    formDataa.append("user", email);

    formDataa.append("document_name", "CV");
    formDataa.append("document_type", "identity");
    formDataa.append("document_file", formData.document_file);
    if (formData.document_file != '') {
      setLoadingCV(true); //loading logic

      try {
        let res = await axios.post(`${BASE_URL}/userdata/userdocs/`, formDataa, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        if (res.status === 200) {
          window.location.reload();
          //   handleClose();
        } else {
          alert(res);
        }
      } catch (err) {
        alert(err);
      } finally {
        setLoadingCV(false); // Ensure loading state is stopped even in case of error //loading logic
      }
    }
    else {
      alert("Please upload a file")
    }

  };




  const handleFileChange = (e) => {
    const { name } = e.target;
    const file = e.target.files[0];

    setFormData({
      ...formData,
      [name]: file,
    });
  };





  /// Profile Pic Code


  const [formDataa, setFormDataa] = useState({
    profilepic: null,
  });

  const handleLogoChange = (e) => {
    const file = e.target.files[0];
    setFormDataa({
      profilepic: file,
    });

  };

  // useEffect(() => {
  // }, [formDataa])

  const uploadFile = async (e) => {
    e.preventDefault();
    const formDataaa = new FormData();
    formDataaa.append('profilepic', formDataa.profilepic);



    try {
      let res = await axios.put(`${BASE_URL}/userdata/userdata/${email}/`, formDataaa, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (res.status === 200) {
        // await getUSerDetails;
        window.location.reload();

      } else {
        alert("Please Upload a Proper Picture Or Check File Type");
      }
    } catch (err) {
      alert("Please Select a picture");
    }


  };
  const deleteProfilePicture = async () => {
    try {
      let res = await axios.put(`${BASE_URL}/userdata/userdata/${email}/`, { profilepic: null });

      if (res.status === 200) {
        console.log("Profile picture deleted successfully");
      } else {
        console.error("Failed to delete profile picture");
      }
    } catch (err) {
      console.error("An error occurred while deleting the profile picture:", err);
    }
  };
  const handleLogoClear = () => {

    setFormDataa({
      profilepic: null,
    });

  };


  const [ProfilePic, SetProfilePic] = useState("")







  useEffect(() => {
    // const storedUserData = Cookies.get("userData");

    const getUSerDetails = async () => {
      try {
        const res = await axios.get(`${BASE_URL}/userdata/userdataget/${email}/`);
        SetProfilePic(res.data.profilepic);

      } catch (err) {
        // setData(false)
        // alert(err.message);
      }
    };


    getUSerDetails();

  }, [email]);



  const navigate = useNavigate()


  const handleFormPut = async (e) => {
    e.preventDefault();
    // navigate("/educationDetails")
    try {
      navigate(`/processComplete/`)
    } catch (err) {
      alert(err);
    }
  }

  const DocDeleteButton = ({ id }) => {


    const handleDocSubmitDelete = async (e) => {
      // e.preventDefault();
      try {
        let res = await axios.delete(`${BASE_URL}/userdata/userdocs/${id}/`);

        if (res.status === 200) {
          // getDocuments();

          // await getUserDocs();
          window.location.reload();

          // setShow(false);
        } else {
          alert(res);
        }
      } catch (err) {
        alert(err);
      }
    };

    return (
      <>
        <button style={{ marginLeft: '10px' }} title="Delete Doc" onClick={(e) => handleDocSubmitDelete()} className="model-delete-button">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
          >
            <path
              d="M15.1875 3.375H12.375V2.8125C12.375 2.36495 12.1972 1.93572 11.8807 1.61926C11.5643 1.30279 11.1351 1.125 10.6875 1.125H7.3125C6.86495 1.125 6.43572 1.30279 6.11926 1.61926C5.80279 1.93572 5.625 2.36495 5.625 2.8125V3.375H2.8125C2.66332 3.375 2.52024 3.43426 2.41475 3.53975C2.30926 3.64524 2.25 3.78832 2.25 3.9375C2.25 4.08668 2.30926 4.22976 2.41475 4.33525C2.52024 4.44074 2.66332 4.5 2.8125 4.5H3.375V14.625C3.375 14.9234 3.49353 15.2095 3.7045 15.4205C3.91548 15.6315 4.20163 15.75 4.5 15.75H13.5C13.7984 15.75 14.0845 15.6315 14.2955 15.4205C14.5065 15.2095 14.625 14.9234 14.625 14.625V4.5H15.1875C15.3367 4.5 15.4798 4.44074 15.5852 4.33525C15.6907 4.22976 15.75 4.08668 15.75 3.9375C15.75 3.78832 15.6907 3.64524 15.5852 3.53975C15.4798 3.43426 15.3367 3.375 15.1875 3.375ZM6.75 2.8125C6.75 2.66332 6.80926 2.52024 6.91475 2.41475C7.02024 2.30926 7.16332 2.25 7.3125 2.25H10.6875C10.8367 2.25 10.9798 2.30926 11.0852 2.41475C11.1907 2.52024 11.25 2.66332 11.25 2.8125V3.375H6.75V2.8125ZM13.5 14.625H4.5V4.5H13.5V14.625ZM7.875 7.3125V11.8125C7.875 11.9617 7.81574 12.1048 7.71025 12.2102C7.60476 12.3157 7.46168 12.375 7.3125 12.375C7.16332 12.375 7.02024 12.3157 6.91475 12.2102C6.80926 12.1048 6.75 11.9617 6.75 11.8125V7.3125C6.75 7.16332 6.80926 7.02024 6.91475 6.91475C7.02024 6.80926 7.16332 6.75 7.3125 6.75C7.46168 6.75 7.60476 6.80926 7.71025 6.91475C7.81574 7.02024 7.875 7.16332 7.875 7.3125ZM11.25 7.3125V11.8125C11.25 11.9617 11.1907 12.1048 11.0852 12.2102C10.9798 12.3157 10.8367 12.375 10.6875 12.375C10.5383 12.375 10.3952 12.3157 10.2898 12.2102C10.1843 12.1048 10.125 11.9617 10.125 11.8125V7.3125C10.125 7.16332 10.1843 7.02024 10.2898 6.91475C10.3952 6.80926 10.5383 6.75 10.6875 6.75C10.8367 6.75 10.9798 6.80926 11.0852 6.91475C11.1907 7.02024 11.25 7.16332 11.25 7.3125Z"
              fill="#F72B50"
            />
          </svg>
        </button>
      </>
    )
  }

  return (

    <>

      <div className="jd">
        {/*  ************** timeline Seaction  ************** */}

        <div className="jd-timeline">
          <TimelineItem heading="Personal Information" color="#2576BC" />
          <div className="jd-timeline-borderr timeline-color"></div>
          <TimelineItem heading="Education Detail" color="#2576BC" />
          <div className="jd-timeline-borderr timeline-color"></div>
          <TimelineItem heading="Work Experience" color="#2576BC" />
          <div className="jd-timeline-borderr timeline-color"></div>
          <TimelineItem heading="Emergency Contact" color="#2576BC" />
          <div className="jd-timeline-borderr timeline-color"></div>
          <TimelineItem heading="Account Details" color="#2576BC" />
          <div className="jd-timeline-borderr timeline-color"></div>
          <TimelineItem heading="Documents Upload" color="#2576BC" />
        </div>


        <div className="jd-heading-outer">
          <h2 className="jd-heading-main">Documents Upload*</h2>
          <div>
            <div className="jd-heading-bottom-bold"></div>
            <div className="jd-heading-bottom-light"></div>
          </div>
        </div>



        <form className="register-form">
          <div className="form-flex-wrap" style={{ height: ((docData.find(document => document.document_name === 'Aadhaar') || docData.find(document => document.document_name === 'CV')) && ProfilePic) ? "210px" : ProfilePic ? "310px" : "" }}
          >

            {docData.find(document => document.document_name === 'Aadhaar') ?
              "" : (
                <div className="flex-column">
                  <label htmlFor="bestAchieve" className="form-labels">
                    Upload Aadhar<span className="required">*</span>
                  </label>


                  <div className="" key={"index"}>
                    <div className="document-icons">
                      <div className="document-name-div">
                        <img
                          src={Docs}
                          alt="Document Icon"
                          className="document-icon"
                        />
                        <div>
                          <div className="document-name">Aadhaar</div>
                          <input
                            id="file"
                            type="file"
                            name="document_file"
                            accept=".pdf"
                            onChange={handleFileChange}
                            className="file-input"
                          />
                        </div>
                      </div>

                      <button
                        className="document-download-button"
                        onClick={handleSubmitaadhaar}
                        disabled={loadingAadhar}
                      >
                        Upload
                      </button>
                    </div>
                  </div>


                </div>)
            }


            {docData.find(document => document.document_name === 'CV') ? "" :

              (<div className="flex-column">
                <label htmlFor="bestAchieve" className="form-labels">
                  Upload CV<span className="required">*</span>
                </label>

                <div className="" key={"index"}>
                  <div className="document-icons">
                    <div className="document-name-div">
                      <img
                        src={Docs}
                        alt="Document Icon"
                        className="document-icon"
                      />
                      <div>
                        <div className="document-name">CV</div>
                        <input
                          id="file"
                          type="file"
                          name="document_file"
                          accept=".pdf"
                          onChange={handleFileChange}
                          className="file-input"
                        />
                      </div>
                    </div>
                    <button
                      type="submit"
                      className="document-download-button"
                      onClick={handleSubmitcv}
                      disabled={loadingCV}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>)
            }


            {ProfilePic ?
              <div className="flex-column customHeightFix">
                <label htmlFor="bestAchieve" className="form-labels">
                  Profile Pic:
                </label>
                <div className="document-name-div" style={{ alignItems: 'center' }}>
                  <img
                    src={ProfilePic}
                    alt="Logo Preview"
                    className="document-iconss user_img_display"

                  />
                </div>
                <button onClick={(e) => deleteProfilePicture()} className="document-download-button">Delete</button>


              </div> :
              <div className="flex-column">
                <label htmlFor="bestAchieve" className="form-labels">
                  Upload Profile<span className="required">*</span>
                </label>

                <div className="" key={"index"}>
                  <div className="document-icons">
                    <div className="document-name-div">
                      <img
                        src={Profile}
                        alt="Document Icon"
                        className="document-icon"
                      />
                      <div>
                        <div className="document-name">Profile</div>
                        <input
                          // id="-"
                          type="file"
                          name="profilepic"
                          accept="image/*"
                          onChange={handleLogoChange}
                          className="file-input"
                        />
                      </div>
                      {formDataa.profilepic ?
                        < button className="file-clear" onClick={handleLogoClear}>
                          Clear
                        </button>
                        : <></>
                      }
                    </div>
                    <button
                      className="document-download-button"
                      onClick={uploadFile}
                    >
                      Submit
                    </button>
                  </div>
                </div>

              </div>
            }


          </div>
          {ProfilePic ?
            <>
              <span className="user_img_display"> </span>
              {/* <br />
              <br />
              <br />
              <button onClick={(e) => deleteProfilePicture()} className="document-download-button">Delete</button> */}

              <br />
              <br />
            </>
            : <></>}
          <div className="jd-heading-outer">
            <div className="jd-bloc-tabs">
              <h2 className="jd-heading-main">Upload Additonal Documents </h2>
              <div className="right-expense-bloc-tab">
              </div>
              <div><DocumentAdd getUserDocs={getUserDocs} /></div>

            </div>
            <div>
              <div className="jd-heading-bottom-bold"></div>
              <div className="jd-heading-bottom-light"></div>

              {docData.length === 0 ? (
                <div className="no-documents-message">
                  No documents available.
                </div>
              ) : (
                <div className="document-grid1">
                  {docData.map((doc, index) => (
                    (doc.document_name == null) ? "" :
                      <div className="document-item1" key={index}>
                        <div className="document-icons">
                          <div className="document-name-div">
                            <img
                              src={Docs}
                              alt="Document Icon"
                              className="document-icon"
                            />
                            <div>
                              <div className="document-name">{doc.document_name}</div>
                              <div className="document-upload-date">
                                Doc Type <b>{doc.document_type}</b>
                              </div>
                            </div>
                          </div>

                          {/* <a href={doc.document_file} target="_blank" className="document-download-button">

                            View

                          </a> */}
                          <div style={{ display: 'flex', }}>
                            <a style={{ marginLeft: '10px' }} href={doc.document_file} target="_blank" className="document-download-button">
                              View
                            </a>


                            <DocDeleteButton id={doc.id} />


                          </div>
                        </div>
                      </div>
                  ))}
                </div>
              )}
            </div>

          </div>
          <div className="button-models">
            <button className="model-button" onClick={backroute}>Back</button>

            {(docData.find(document => document.document_name === 'Aadhaar') && docData.find(document => document.document_name === 'CV') && ProfilePic)
              ? <button className="model-button model-button-submit" onClick={handleFormPut}>
                Complete
              </button>
              : <></>
            }
          </div>
        </form >
      </div>

    </>
  );
};

export default Jobapplicantdocuments;
