import React, { useState, useEffect } from "react";
import Plus from "../images/plus.svg";
import { ImpresetAdd, } from "../settings/model/Impreset";
import { Expensebutton } from "../settings/model/Expensebutton ";
import axios from "axios";
import { BASE_URL, FRONTEND_URL } from "../../config/axios";
import { formattedDateLong } from "../Date";
import Modal from "react-bootstrap/Modal";
import ExpenseModel from "../settings/model/Expense"
import { useNavigate } from "react-router-dom";


const ImprestModelDelete = ({ id, getImpreset }) => {
  const [show, setShow] = useState(false);

  console.log(id)

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleFormSubmitDelete = async (e) => {
    e.preventDefault();

    try {
      let res = await axios.delete(`${BASE_URL}/wfm/impreset/${id.id}/`);

      if (res.status === 200) {
        await getImpreset();
        setShow(false);
      } else {
        alert(res);
      }
    } catch (err) {
      alert(err);
    }
  };

  return (
    <>
      <button className="model-delete-button" onClick={handleShow}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M15.1875 3.375H12.375V2.8125C12.375 2.36495 12.1972 1.93572 11.8807 1.61926C11.5643 1.30279 11.1351 1.125 10.6875 1.125H7.3125C6.86495 1.125 6.43572 1.30279 6.11926 1.61926C5.80279 1.93572 5.625 2.36495 5.625 2.8125V3.375H2.8125C2.66332 3.375 2.52024 3.43426 2.41475 3.53975C2.30926 3.64524 2.25 3.78832 2.25 3.9375C2.25 4.08668 2.30926 4.22976 2.41475 4.33525C2.52024 4.44074 2.66332 4.5 2.8125 4.5H3.375V14.625C3.375 14.9234 3.49353 15.2095 3.7045 15.4205C3.91548 15.6315 4.20163 15.75 4.5 15.75H13.5C13.7984 15.75 14.0845 15.6315 14.2955 15.4205C14.5065 15.2095 14.625 14.9234 14.625 14.625V4.5H15.1875C15.3367 4.5 15.4798 4.44074 15.5852 4.33525C15.6907 4.22976 15.75 4.08668 15.75 3.9375C15.75 3.78832 15.6907 3.64524 15.5852 3.53975C15.4798 3.43426 15.3367 3.375 15.1875 3.375ZM6.75 2.8125C6.75 2.66332 6.80926 2.52024 6.91475 2.41475C7.02024 2.30926 7.16332 2.25 7.3125 2.25H10.6875C10.8367 2.25 10.9798 2.30926 11.0852 2.41475C11.1907 2.52024 11.25 2.66332 11.25 2.8125V3.375H6.75V2.8125ZM13.5 14.625H4.5V4.5H13.5V14.625ZM7.875 7.3125V11.8125C7.875 11.9617 7.81574 12.1048 7.71025 12.2102C7.60476 12.3157 7.46168 12.375 7.3125 12.375C7.16332 12.375 7.02024 12.3157 6.91475 12.2102C6.80926 12.1048 6.75 11.9617 6.75 11.8125V7.3125C6.75 7.16332 6.80926 7.02024 6.91475 6.91475C7.02024 6.80926 7.16332 6.75 7.3125 6.75C7.46168 6.75 7.60476 6.80926 7.71025 6.91475C7.81574 7.02024 7.875 7.16332 7.875 7.3125ZM11.25 7.3125V11.8125C11.25 11.9617 11.1907 12.1048 11.0852 12.2102C10.9798 12.3157 10.8367 12.375 10.6875 12.375C10.5383 12.375 10.3952 12.3157 10.2898 12.2102C10.1843 12.1048 10.125 11.9617 10.125 11.8125V7.3125C10.125 7.16332 10.1843 7.02024 10.2898 6.91475C10.3952 6.80926 10.5383 6.75 10.6875 6.75C10.8367 6.75 10.9798 6.80926 11.0852 6.91475C11.1907 7.02024 11.25 7.16332 11.25 7.3125Z"
            fill="#F72B50"
          />
        </svg>
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="request-leave">
        <Modal.Header closeButton>
          <Modal.Title>Delete Imprest</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div">
            Are You Sure You Want to Delete this Imprest Request
            <div className="button-models">
              <button className="model-button " onClick={handleClose}>
                Cancel
              </button>
              <button
                onClick={handleFormSubmitDelete}
                className="model-button model-button-delete"
              >
                Delete
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}
const ExpenseModelDelete = ({ id, getExpansee }) => {
  const [show, setShow] = useState(false);

  console.log(id)

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleFormSubmitDelete = async (e) => {
    e.preventDefault();

    try {
      let res = await axios.delete(`${BASE_URL}/wfm/expense/${id.id}/`);

      if (res.status === 200) {
        await getExpansee();
        setShow(false);
      } else {
        alert(res);
      }
    } catch (err) {
      alert(err);
    }
  };

  return (
    <>
      <button className="model-delete-button" onClick={handleShow}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M15.1875 3.375H12.375V2.8125C12.375 2.36495 12.1972 1.93572 11.8807 1.61926C11.5643 1.30279 11.1351 1.125 10.6875 1.125H7.3125C6.86495 1.125 6.43572 1.30279 6.11926 1.61926C5.80279 1.93572 5.625 2.36495 5.625 2.8125V3.375H2.8125C2.66332 3.375 2.52024 3.43426 2.41475 3.53975C2.30926 3.64524 2.25 3.78832 2.25 3.9375C2.25 4.08668 2.30926 4.22976 2.41475 4.33525C2.52024 4.44074 2.66332 4.5 2.8125 4.5H3.375V14.625C3.375 14.9234 3.49353 15.2095 3.7045 15.4205C3.91548 15.6315 4.20163 15.75 4.5 15.75H13.5C13.7984 15.75 14.0845 15.6315 14.2955 15.4205C14.5065 15.2095 14.625 14.9234 14.625 14.625V4.5H15.1875C15.3367 4.5 15.4798 4.44074 15.5852 4.33525C15.6907 4.22976 15.75 4.08668 15.75 3.9375C15.75 3.78832 15.6907 3.64524 15.5852 3.53975C15.4798 3.43426 15.3367 3.375 15.1875 3.375ZM6.75 2.8125C6.75 2.66332 6.80926 2.52024 6.91475 2.41475C7.02024 2.30926 7.16332 2.25 7.3125 2.25H10.6875C10.8367 2.25 10.9798 2.30926 11.0852 2.41475C11.1907 2.52024 11.25 2.66332 11.25 2.8125V3.375H6.75V2.8125ZM13.5 14.625H4.5V4.5H13.5V14.625ZM7.875 7.3125V11.8125C7.875 11.9617 7.81574 12.1048 7.71025 12.2102C7.60476 12.3157 7.46168 12.375 7.3125 12.375C7.16332 12.375 7.02024 12.3157 6.91475 12.2102C6.80926 12.1048 6.75 11.9617 6.75 11.8125V7.3125C6.75 7.16332 6.80926 7.02024 6.91475 6.91475C7.02024 6.80926 7.16332 6.75 7.3125 6.75C7.46168 6.75 7.60476 6.80926 7.71025 6.91475C7.81574 7.02024 7.875 7.16332 7.875 7.3125ZM11.25 7.3125V11.8125C11.25 11.9617 11.1907 12.1048 11.0852 12.2102C10.9798 12.3157 10.8367 12.375 10.6875 12.375C10.5383 12.375 10.3952 12.3157 10.2898 12.2102C10.1843 12.1048 10.125 11.9617 10.125 11.8125V7.3125C10.125 7.16332 10.1843 7.02024 10.2898 6.91475C10.3952 6.80926 10.5383 6.75 10.6875 6.75C10.8367 6.75 10.9798 6.80926 11.0852 6.91475C11.1907 7.02024 11.25 7.16332 11.25 7.3125Z"
            fill="#F72B50"
          />
        </svg>
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="request-leave">
        <Modal.Header closeButton>
          <Modal.Title>Delete Expense</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div">
            Are You Sure You Want to Delete Expense Request
            <div className="button-models">
              <button className="model-button " onClick={handleClose}>
                Cancel
              </button>
              <button
                onClick={handleFormSubmitDelete}
                className="model-button model-button-delete"
              >
                Delete
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

const Expenses = () => {

  const [impreset, setImpreset] = useState([])

  const getImpreset = async () => {
    try {
      const res = await axios.get(`${BASE_URL}/wfm/impresetbyemployee/${sessionStorage.getItem("emp_code")}/`);
      setImpreset(res.data)
    } catch (err) {
      // alert(err.message);
    }
  };


  useEffect(() => {
    getImpreset();
  }, []);

  const [expansee, setexpansee] = useState([])

  const getExpansee = async () => {
    try {
      const res = await axios.get(`${BASE_URL}/wfm/expensebyemployee/${sessionStorage.getItem("emp_code")}/`);
      setexpansee(res.data)
    } catch (err) {
      // alert(err.message);
    }
  };


  useEffect(() => {
    getExpansee();
  }, []);



  const [toggleState, setToggleState] = useState(1);




  const navigate = useNavigate()
  const currentUrl = window.location.href;
  const urlParts = currentUrl.split('/');
  const baseUrl = "/" + urlParts[3] + `/expenseSlip`;


  const openExenseSlip = (i) => {
    navigate(baseUrl, { state: { i } })
  }
  return (
    <div>
      <div className="expense-bloc-tabs">
        <div className="left-expense-bloc-tab">
          <button
            className={
              "expense-tab " +
              (toggleState === 1
                ? "dashboardcomponent-tabs dashboardcomponent-active-tabs dashboardcomponent-tabss expense-active-tab"
                : "dashboardcomponent-tabs dashboardcomponent-tabss expense-inactive-tab")
            }
            onClick={() => setToggleState(1)}
          >
            Imprest
          </button>
          <button
            className={
              "expense-tab " +
              (toggleState === 2
                ? "dashboardcomponent-tabs dashboardcomponent-active-tabs expense-active-tab"
                : "dashboardcomponent-tabs expense-inactive-tab")
            }
            onClick={() => setToggleState(2)}
          >
            Expense
          </button>
        </div>
        {toggleState === 1 ? (
          <>
            <div className="right-expense-bloc-tab">
              <div>
                <ImpresetAdd getImpreset={getImpreset} />
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="right-expense-bloc-tab">
              <div>
                {/* <ExpenseAdd /> */}
                <Expensebutton getExpansee={getExpansee} />
              </div>
            </div>
          </>
        )}
      </div>

      {toggleState === 1 ? (
        <>
          {/* Imprest History Details */}
          <div className="custom-table-background expense-table-cont">
            <table className="custom-table">
              <thead>
                <tr className="custom-table-head-tr">
                  <th className="align-left">S. No.</th>
                  <th className="align-leftt">Emp Code</th>
                  <th className="align-center">Date</th>
                  <th className="align-leftt">Project Name</th>
                  <th className="align-leftt">Total Amount</th>
                  <th className="align-center">No. of Days</th>
                  <th className="align-center">No. of People</th>
                  <th className="align-leftt">Person Name</th>
                  <th className="align-center">Remarks</th>
                  <th className="align-center">Status</th>
                  <th className="align-right">Action</th>



                </tr>
              </thead>
              <tbody>
                <tr className="tr-border-bottom">
                  <td colSpan={6}></td>
                </tr>
                {impreset.map((item, index) => {
                  return (
                    <>
                      <tr className="custom-table-head-td">
                        <td className="align-left">
                          {index + 1}
                        </td>
                        <td className="align-left">
                          {item.empcode}
                        </td>
                        <td className="align-center">
                          {formattedDateLong(item.date)}
                        </td>
                        <td className="align-leftt">
                          {item.project_name}
                        </td>

                        <td className="align-leftt" >
                          <b>{Number(item.transportation) + Number(item.accomodation) + Number(item.fooding) + Number(item.other)} Rs</b> <br />
                          Transportation: {item.transportation} <br />
                          Accomodation: {item.accomodation} <br />
                          Food: {item.fooding} <br />
                          Other: {item.other}
                        </td>
                        <td className="align-center">
                          {item.no_of_days}
                        </td>
                        <td className="align-center" >
                          {item.person}
                        </td>
                        <td className="align-leftt">
                          {item.multiple_person_details.map((items) => {
                            return (
                              <>{items.emp_code} - {items.name}<br />
                              </>)
                          })}
                        </td>
                        <td className="align-center">
                          {item.remarks}
                        </td>

                        {/* <td className="align-center">
                          {item.rh_leave_status === "pending" ? "Pending For Rh Approval" : ""}
                          {item.rh_leave_status === "approved" && item.admin_leave_status === "pending" ? "Pending For Admin Approval" : ""}
                          {item.rh_leave_status === "approved" && item.admin_leave_status === "approved" && item.account_leave_status === "pending" ? "Pending For Account Approval" : ""}
                        </td> */}

                        <td className="align-center">
                          <span request-status={item.rh_leave_status} className="request-status align-center" >{item.rh_leave_status === "pending" ? "Pending For Rh Approval ⌛" : item.rh_leave_status === "rejected" ? "Rh ✖, " : "Rh ✔, "}</span>
                          {/* <span request-status={item.rh_leave_status} className="request-status align-center" > {item.rh_leave_status === "pending" ? "Pending For Rh Approval ⌛" : "Rh ✔, "}</span> */}
                          <br />
                          <span request-status={item.admin_leave_status} className="request-status align-center" >{(item.rh_leave_status === "rejected" && item.admin_leave_status === "rejected") ? "Admin ✖, " : (item.rh_leave_status === "approved" && item.admin_leave_status === "pending") ? "Pending For Admin Approval ⌛," : (item.rh_leave_status === "approved" && item.admin_leave_status === "rejected") ? "Admin ✖, " : (item.rh_leave_status === "approved" && item.admin_leave_status === "approved") ? "Admin ✔, " : "Admin Status: " + item.admin_leave_status}</span>
                          {/* <span request-status={item.admin_leave_status} className="request-status align-center" > {item.rh_leave_status === "approved" && item.admin_leave_status === "pending" ? "Pending For Admin Approval ⌛," : "Admin ✔, "}</span> */}
                          <br />
                          <span request-status={item.account_leave_status} className="request-status align-center" >{(item.rh_leave_status === "rejected" && item.admin_leave_status === "rejected" && item.account_leave_status === "rejected") ? "Account ✖, " : (item.rh_leave_status === "approved" && item.admin_leave_status === "approved" && item.account_leave_status === "pending") ? "Pending For Account Approval ⌛" : (item.rh_leave_status === "approved" && item.admin_leave_status === "approved" && item.account_leave_status === "rejected") ? "Accounts ✖, " : (item.rh_leave_status === "approved" && item.admin_leave_status === "approved" && item.account_leave_status === "approved") ? "Accounts ✔, " : "Accounts Status: " + item.account_leave_status}</span>
                          {/* <span request-status={item.account_leave_status} className="request-status align-center" >{(item.rh_leave_status === "approved" && item.admin_leave_status === "approved" && item.account_leave_status === "pending") ? "Pending For Account Approval ⌛" : "Accounts ✔"}</span> */}
                        </td>
                        <td className="align-right">
                          <span request-status={item.rh_leave_status} className="request-status align-center" > {item.rh_leave_status === "pending" ? <ImprestModelDelete getImpreset={getImpreset} id={item} /> : "In process"}</span>



                        </td>

                      </tr>
                      <tr className="tr-border-bottom">
                        <td colSpan={6}></td>
                      </tr>
                    </>
                  );
                })}
              </tbody>
            </table>
          </div>
        </>
      ) : null}

      {toggleState === 2 ? (
        <>
          {/* Expense History Details */}
          <div className="custom-table-background expense-table-cont">
            <table className="custom-table">
              <thead>
                <tr className="custom-table-head-tr">
                  <th className="align-left">S. No.</th>
                  <th className="align-center">Date</th>
                  <th className="align-center">Site Name</th>
                  <th className="align-center">Expense Name</th>
                  <th className="align-center">Amount</th>
                  <th className="align-center">Spent Money</th>
                  <th className="align-center">View</th>
                  <th className="align-center">Status</th>
                  <th className="align-right">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr className="tr-border-bottom">
                  <td colSpan={7}></td>
                </tr>
                {expansee.map((item, index) => {

                  return (
                    <>
                      <tr className="custom-table-head-td">
                        <td className="align-left" >
                          {index + 1}
                        </td>
                        <td className="align-center">
                          {item.date}
                        </td>
                        <td className="align-center">
                          {item.project_name}
                        </td>
                        <td className="align-center">
                          {item.name}
                        </td>
                        <td className="align-center" >
                          <b>{Number(item.transportation) + Number(item.accomodation) + Number(item.fooding) + Number(item.other)} Rs</b> <br />
                          Transportation: {item.transportation} <br />
                          Accomodation: {item.accomodation} <br />
                          Food: {item.fooding} <br />
                          Other: {item.other}
                        </td>
                        {/* <td className="align-center" >
                          {item.utilize}
                        </td>
                        <td className="align-center" >
                          {item.remaining}
                        </td> */}

                        <td className="align-center" >

                          <ExpenseModel i={item} expansee={getExpansee} totall={Number(item.transportation) + Number(item.accomodation) + Number(item.fooding) + Number(item.other)} />
                        </td>
                        <td className="align-center">
                          {/* <a
                            href={`${FRONTEND_URL}/expenseapprovalbymonth/`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="40"
                              height="40"
                              viewBox="0 0 30 30"
                              fill="none"
                            >
                              <circle cx="15" cy="15" r="15" fill="#F6F7F9" />
                              <path
                                d="M23.8913 14.5437C22.1966 11.2372 18.8416 9 15 9C11.1584 9 7.80249 11.2388 6.10873 14.5441C6.03725 14.6855 6 14.8417 6 15.0002C6 15.1586 6.03725 15.3148 6.10873 15.4563C7.80342 18.7628 11.1584 21 15 21C18.8416 21 22.1975 18.7612 23.8913 15.4559C23.9628 15.3145 24 15.1583 24 14.9998C24 14.8414 23.9628 14.6852 23.8913 14.5437ZM15 19.5C14.11 19.5 13.24 19.2361 12.4999 18.7416C11.7599 18.2471 11.1831 17.5443 10.8425 16.7221C10.5019 15.8998 10.4128 14.995 10.5865 14.1221C10.7601 13.2492 11.1887 12.4474 11.818 11.818C12.4474 11.1887 13.2492 10.7601 14.1221 10.5865C14.995 10.4128 15.8998 10.5019 16.7221 10.8425C17.5443 11.1831 18.2472 11.7599 18.7416 12.4999C19.2361 13.24 19.5 14.11 19.5 15C19.5003 15.591 19.3841 16.1763 19.1581 16.7224C18.932 17.2685 18.6005 17.7647 18.1826 18.1826C17.7647 18.6005 17.2685 18.932 16.7224 19.158C16.1763 19.3841 15.591 19.5003 15 19.5ZM15 12C14.7322 12.0037 14.4662 12.0436 14.2091 12.1184C14.421 12.4065 14.5227 12.7609 14.4957 13.1175C14.4688 13.4741 14.3149 13.8092 14.062 14.062C13.8092 14.3149 13.4741 14.4688 13.1175 14.4957C12.7609 14.5227 12.4065 14.421 12.1184 14.2091C11.9544 14.8133 11.984 15.4538 12.2031 16.0403C12.4221 16.6269 12.8196 17.1299 13.3396 17.4787C13.8595 17.8275 14.4758 18.0045 15.1016 17.9847C15.7274 17.965 16.3312 17.7495 16.8281 17.3685C17.325 16.9876 17.6899 16.4604 17.8715 15.8612C18.0531 15.2621 18.0422 14.621 17.8404 14.0283C17.6386 13.4356 17.256 12.9211 16.7465 12.5573C16.2369 12.1934 15.6261 11.9985 15 12Z"
                                fill="#2576BC"
                              />

                            </svg>
                          </a> */}
                          <button className="allproject-dashboard" title="View using Navigate" onClick={(e) => openExenseSlip(item)}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="40"
                              height="40"
                              viewBox="0 0 30 30"
                              fill="none">

                              <circle cx="15" cy="15" r="15" fill="#F6F7F9" />
                              <path
                                d="M23.8913 14.5437C22.1966 11.2372 18.8416 9 15 9C11.1584 9 7.80249 11.2388 6.10873 14.5441C6.03725 14.6855 6 14.8417 6 15.0002C6 15.1586 6.03725 15.3148 6.10873 15.4563C7.80342 18.7628 11.1584 21 15 21C18.8416 21 22.1975 18.7612 23.8913 15.4559C23.9628 15.3145 24 15.1583 24 14.9998C24 14.8414 23.9628 14.6852 23.8913 14.5437ZM15 19.5C14.11 19.5 13.24 19.2361 12.4999 18.7416C11.7599 18.2471 11.1831 17.5443 10.8425 16.7221C10.5019 15.8998 10.4128 14.995 10.5865 14.1221C10.7601 13.2492 11.1887 12.4474 11.818 11.818C12.4474 11.1887 13.2492 10.7601 14.1221 10.5865C14.995 10.4128 15.8998 10.5019 16.7221 10.8425C17.5443 11.1831 18.2472 11.7599 18.7416 12.4999C19.2361 13.24 19.5 14.11 19.5 15C19.5003 15.591 19.3841 16.1763 19.1581 16.7224C18.932 17.2685 18.6005 17.7647 18.1826 18.1826C17.7647 18.6005 17.2685 18.932 16.7224 19.158C16.1763 19.3841 15.591 19.5003 15 19.5ZM15 12C14.7322 12.0037 14.4662 12.0436 14.2091 12.1184C14.421 12.4065 14.5227 12.7609 14.4957 13.1175C14.4688 13.4741 14.3149 13.8092 14.062 14.062C13.8092 14.3149 13.4741 14.4688 13.1175 14.4957C12.7609 14.5227 12.4065 14.421 12.1184 14.2091C11.9544 14.8133 11.984 15.4538 12.2031 16.0403C12.4221 16.6269 12.8196 17.1299 13.3396 17.4787C13.8595 17.8275 14.4758 18.0045 15.1016 17.9847C15.7274 17.965 16.3312 17.7495 16.8281 17.3685C17.325 16.9876 17.6899 16.4604 17.8715 15.8612C18.0531 15.2621 18.0422 14.621 17.8404 14.0283C17.6386 13.4356 17.256 12.9211 16.7465 12.5573C16.2369 12.1934 15.6261 11.9985 15 12Z"
                                fill="#2576BC"
                              />

                            </svg>
                          </button>
                        </td>


                        <td className="align-center">
                          <span request-status={item.rh_leave_status} className="request-status align-center" >{item.rh_leave_status === "pending" ? "Pending For Rh Approval ⌛" : item.rh_leave_status === "rejected" ? "Rh ✖, " : "Rh ✔, "}</span>
                          {/* <span request-status={item.rh_leave_status} className="request-status align-center" > {item.rh_leave_status === "pending" ? "Pending For Rh Approval ⌛" : "Rh ✔, "}</span> */}
                          <br />
                          <span request-status={item.admin_leave_status} className="request-status align-center" >{(item.rh_leave_status === "rejected" && item.admin_leave_status === "rejected") ? "Admin ✖, " : (item.rh_leave_status === "approved" && item.admin_leave_status === "pending") ? "Pending For Admin Approval ⌛," : (item.rh_leave_status === "approved" && item.admin_leave_status === "rejected") ? "Admin ✖, " : (item.rh_leave_status === "approved" && item.admin_leave_status === "approved") ? "Admin ✔, " : "Admin Status: " + item.admin_leave_status}</span>
                          {/* <span request-status={item.admin_leave_status} className="request-status align-center" > {item.rh_leave_status === "approved" && item.admin_leave_status === "pending" ? "Pending For Admin Approval ⌛," : "Admin ✔, "}</span> */}
                          <br />
                          <span request-status={item.account_leave_status} className="request-status align-center" >{(item.rh_leave_status === "rejected" && item.admin_leave_status === "rejected" && item.account_leave_status === "rejected") ? "Account ✖, " : (item.rh_leave_status === "approved" && item.admin_leave_status === "approved" && item.account_leave_status === "pending") ? "Pending For Account Approval ⌛" : (item.rh_leave_status === "approved" && item.admin_leave_status === "approved" && item.account_leave_status === "rejected") ? "Accounts ✖, " : (item.rh_leave_status === "approved" && item.admin_leave_status === "approved" && item.account_leave_status === "approved") ? "Accounts ✔, " : "Accounts Status: " + item.account_leave_status}</span>
                          {/* <span request-status={item.account_leave_status} className="request-status align-center" >{(item.rh_leave_status === "approved" && item.admin_leave_status === "approved" && item.account_leave_status === "pending") ? "Pending For Account Approval ⌛" : "Accounts ✔"}</span> */}
                        </td>
                        <td className="align-right" key={item.action}>
                          <span className="align-center" > {item.rh_leave_status === "pending" && item.admin_leave_status === "pending" && item.account_leave_status === "pending" ? <ExpenseModelDelete getExpansee={getExpansee} id={item} /> : "In process..."}</span>
                        </td>
                      </tr>
                      <tr className="tr-border-bottom">
                        <td colSpan={7}></td>
                      </tr>
                    </>
                  );
                })}
              </tbody>
            </table>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default Expenses;
