import React, { useState } from 'react'
import { RequestCompositortable, RequestExpanssetableAcc, RequestImpresttableAcc, RequestImpresttableHR } from './Requestoutdutytable';
import Dashboardnavbarcopy from '../../layout/Dashboardnavbarcopy';

const AccountRequestmangement = () => {
    const [toggleState, setToggleState] = useState(1);
    return (
        <>
            <div>
                <Dashboardnavbarcopy name={"Account"} url="Accounts Managment" />

                <div className="bloc-tabs-with-white-bg">

                    <button
                        className={toggleState === 1 ? "dashboardcomponent-tabs dashboardcomponent-active-tabs" : "dashboardcomponent-tabs white-tab"}
                        onClick={() => setToggleState(1)}
                    >
                        Imprest
                    </button>
                    <button
                        className={toggleState === 2 ? "dashboardcomponent-tabs dashboardcomponent-active-tabs" : "dashboardcomponent-tabs white-tab"}
                        onClick={() => setToggleState(2)}
                    >
                        Expense
                    </button>
                </div>
            </div>

            <div className="content-tabs-without-margin">


                {toggleState === 1 ? <RequestImpresttableAcc /> : null}
                {toggleState === 2 ? <RequestExpanssetableAcc /> : null}




            </div>
        </>
    )
}

export default AccountRequestmangement
