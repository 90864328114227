import React from 'react'
import LetterHeadPr from './LetterHeadPR'
import LetterHeadPrP from './LetterHeadPRP'
import LetterHead from './LetterHead'
import LetterHead4 from './LetterHead4'

const Letter = () => {
    return (
        <div>
            {sessionStorage.getItem("letter_type") === "1" ? <LetterHeadPr /> : ""}
            {sessionStorage.getItem("letter_type") === "2" ? <LetterHeadPrP /> : ""}

            {sessionStorage.getItem("letter_type") === "3" ? <LetterHead /> : ""}
            {sessionStorage.getItem("letter_type") === "4" ? <LetterHead4 /> : ""}



        </div>
    )
}

export default Letter
