import React, { useState } from "react";
import {
  TlCalenderTable,
  TLintercomTable,
 } from "./Organizationtables";
import Tree from "react-d3-tree";

// const Hierarchy = () => {

//     const data = {
//         name: 'CEO',
//         children: [
//           {
//             name: 'HR Manager',
//             children: [
//               { name: 'Recruiter' },
//               { name: 'HR Specialist' },
//             ],
//           },
//           {
//             name: 'IT Manager',
//             children: [
//               { name: 'Developer' },
//               { name: 'QA Engineer' },
//             ],
//           },
//         ],
//       };

//       const dataa = {
//         name: 'CEO',
//         attributes: { type: 'Executive' },
//         children: [
//           {
//             name: 'HR Manager',
//             attributes: { type: 'Manager' },
//             children: [
//               { name: 'Recruiter', attributes: { type: 'Specialist' } },
//               { name: 'HR Specialist', attributes: { type: 'Specialist' } },
//             ],
//           },
//           {
//             name: 'IT Manager',
//             attributes: { type: 'Manager' },
//             children: [
//               { name: 'Developer', attributes: { type: 'Professional' } },
//               { name: 'QA Engineer', attributes: { type: 'Professional' } },
//             ],
//           },
//         ],
//       };

//       const dataaa = {
//         name: 'CEO',
//         attributes: { position: 'Chief Executive Officer', department: 'Executive Team' },
//         children: [
//           {
//             name: 'HR Manager',
//             attributes: { position: 'HR Manager', department: 'Human Resources' },
//             children: [
//               { name: 'Recruiter', attributes: { position: 'Recruiter', department: 'Human Resources' } },
//               { name: 'HR Specialist', attributes: { position: 'HR Specialist', department: 'Human Resources' } },
//             ],
//           },
//           {
//             name: 'IT Manager',
//             attributes: { position: 'IT Manager', department: 'Information Technology' },
//             children: [
//               { name: 'Developer', attributes: { position: 'Software Developer', department: 'Information Technology' } },
//               { name: 'QA Engineer', attributes: { position: 'QA Engineer', department: 'Information Technology' } },
//             ],
//           },
//           {
//             name: 'Finance Manager',
//             attributes: { position: 'Finance Manager', department: 'Finance' },
//             children: [
//               { name: 'Accountant', attributes: { position: 'Accountant', department: 'Finance' } },
//               { name: 'Financial Analyst', attributes: { position: 'Financial Analyst', department: 'Finance' } },
//             ],
//           },
//         ],
//       };

//     return (
//         <>
//          <div style={{ width: '90vw', height: '50vh' }}>
//       <Tree data={dataa} orientation="vertical" translate={{ x: 100, y: 200 }} />
//     </div>
//         </>
//     )
// }

const data = {
  name: "CEO",
  attributes: {
    position: "Chief Executive Officer",
    department: "Executive Team",
  },
  children: [
    {
      name: "HR Manager",
      attributes: { position: "HR Manager", department: "Human Resources" },
      children: [
        {
          name: "Recruiter",
          attributes: { position: "Recruiter", department: "Human Resources" },
        },
        {
          name: "HR Specialist",
          attributes: {
            position: "HR Specialist",
            department: "Human Resources",
          },
        },
      ],
    },
    {
      name: "IT Manager",
      attributes: {
        position: "IT Manager",
        department: "Information Technology",
      },
      children: [
        {
          name: "Developer",
          attributes: {
            position: "Software Developer",
            department: "Information Technology",
          },
        },
        {
          name: "QA Engineer",
          attributes: {
            position: "QA Engineer",
            department: "Information Technology",
          },
        },
      ],
    },
    {
      name: "Finance Manager",
      attributes: { position: "Finance Manager", department: "Finance" },
      children: [
        {
          name: "Accountant",
          attributes: { position: "Accountant", department: "Finance" },
        },
        {
          name: "Financial Analyst",
          attributes: { position: "Financial Analyst", department: "Finance" },
        },
      ],
    },
  ],
};

const Hierarchy = () => {
  const myTreeData = [data];

  return (
    <div style={{ width: "100%", height: "500px" }}>
      <Tree
        data={myTreeData}
        orientation="vertical"
        translate={{ x: 100, y: 200 }}
        nodeSvgShape={{
          shape: "rect",
          shapeProps: { width: 120, height: 50, rx: 10, ry: 10 },
        }}
        nodeLabelComponent={{
          render: <CustomLabel />,
          foreignObjectWrapper: {
            y: -25,
            x: 0,
            width: 120,
            height: 50,
          },
        }}
      />
    </div>
  );
};

const CustomLabel = ({ nodeData }) => (
  <foreignObject width={120} height={50}>
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div>{nodeData.name}</div>
      <div style={{ fontSize: "10px", color: "gray" }}>
        {nodeData.attributes.position}
      </div>
    </div>
  </foreignObject>
);

const  HodOrgstructure = () => {
  const [toggleState, setToggleState] = useState(1);

  return (
    <>
      <div>
        <div>
          <div className="bloc-tabs-with-white-bg">
            <button
              className={
                toggleState === 1
                  ? "dashboardcomponent-tabs  dashboardcomponent-active-tabs"
                  : "dashboardcomponent-tabs white-tab"
              }
              onClick={() => setToggleState(1)}
            >
              Hierarchy
            </button>
            <button
              className={
                toggleState === 2
                  ? "dashboardcomponent-tabs dashboardcomponent-active-tabs"
                  : "dashboardcomponent-tabs white-tab"
              }
              onClick={() => setToggleState(2)}
            >
              Calender table
            </button>
            <button
              className={
                toggleState === 3
                  ? "dashboardcomponent-tabs dashboardcomponent-active-tabs"
                  : "dashboardcomponent-tabs white-tab"
              }
              onClick={() => setToggleState(3)}
            >
              InterCom
            </button>
            
            
           
           
            
          </div>
        </div>

        <div className="content-tabs-without-margin">
          {toggleState === 1 ? <Hierarchy /> : null}

          {toggleState === 2 ? <TlCalenderTable /> : null}

          {toggleState === 3 ? <TLintercomTable /> : null}

         

          
        </div>
      </div>
    </>
  );
};

export default HodOrgstructure;
