import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import Modal from "react-bootstrap/Modal";
import {
  Department,
  DepartmentUpdate,
  DepartmentDelete,
  DesignationName,
  DesignationUpdate,
  DesignationDelete,
  Subcompanys,
  Subcompanyupdate,
  SubcompanyDelete,
  AttendancePolicy,
  AttendancePolicyUpdate,
  AttendancePolicyDelete,
  Woksiteadd,
  Woksiteupdate,
  WoksiteDelete,
  CalenderModel,
  CalenderUpdate,
  CalenderDelete,
  InterCom,
  InterComUpdate,
  InterComDelete,
  WeeklyOffPolicy,
  WeeklyOffPolicyDelete,
  WeeklyOffPolicyUpdate,
  EsslList,
  EsslUpdate,
  EsslDelete,
}
  from "./Wfmmodels";
import { BASE_URL } from "../../config/axios";
import axios from "axios";
import { formattedDateLong } from "../Date";

const DepartmentTable = () => {
  const [departmentname, setDepartmentname] = useState([]);

  const getDepartment = async () => {
    try {
      const res = await axios.get(`${BASE_URL}/wfm/departmentwfm/`);
      setDepartmentname(res.data);
    } catch (err) {
      // alert(err.message);
    }
  };

  const [searchQuery, setSearchQuery] = useState("");
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  useEffect(() => {
    getDepartment();
  }, []);

  return (
    <div className="custom-table-background">
      <div className="table-heading-flex">
        <div className="repo-heading">Department</div>
        <div className="flex-row">
          <div className="table-searchh">
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="11"
                height="12"
                viewBox="0 0 11 12"
                fill="none"
              >
                <circle
                  cx="5"
                  cy="5"
                  r="4.3"
                  stroke="#2B3674"
                  strokeWidth="1.4"
                />
                <line
                  x1="10.0101"
                  y1="11"
                  x2="8"
                  y2="8.98995"
                  stroke="#2B3674"
                  strokeWidth="1.4"
                  strokeLinecap="round"
                />
              </svg>
            </div>
            <div>
              <input
                placeholder="Search"
                className="navbar-inputt"
                value={searchQuery}
                onChange={handleSearchChange}
              />
            </div>
          </div>
          <Department getDepartment={getDepartment} />
        </div>
      </div>
      <table className="custom-table">
        <thead>
          <tr className="custom-table-head-tr">
            <th className="align-left">ID</th>
            <th className="align-left">Department</th>
            <th className="align-center">Employee Count</th>
            <th className="align-center">Employee View</th>
            <th className="align-right">Action</th>
          </tr>
        </thead>
        <tbody>
          {departmentname
            ?.filter((e) =>
              e.title.toLowerCase().includes(searchQuery.toLowerCase())
            ).sort((a, b) =>
              a.title.localeCompare(b.title, "en", { ignorePunctuation: true })
            )
            .map((i, index) => (
              <React.Fragment key={index}>
                <tr className="tr-border-bottom">
                  <td colSpan="6"></td>
                </tr>
                <tr className="custom-table-head-td">
                  <td className="align-left">{index + 1}</td>
                  <td className="align-left">{i.title}</td>

                  <td className="align-center">{i?.employee_count}</td>
                  {/* <td className="align-center">View </td> */}
                  <td className="align-center"><EmployeebyDepartmentTable department={i} /> </td>
                  <td className="align-right ">
                    <DepartmentUpdate i={i} getDepartment={getDepartment} />

                    <DepartmentDelete i={i} getDepartment={getDepartment} />
                  </td>
                </tr>
              </React.Fragment>
            ))}
        </tbody>
      </table>
    </div>
  );
};

const EmployeebyDepartmentTable = ({ department }) => {

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true)
    getDepartmentEmployees(department.id);
  };



  const [departmentEmployees, setDepartmentEmployees] = useState([]);

  const getDepartmentEmployees = async (department) => {
    try {
      const res = await axios.get(`${BASE_URL}/wfm/employeedetailsbydepartment/${department}/`);
      setDepartmentEmployees(res.data);
    } catch (err) {
      // alert(err.message);
    }
  };

  const [searchQuery, setSearchQuery] = useState("");
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  // useEffect(() => {
  //   getDepartmentEmployees();
  // }, []);

  const navigate = useNavigate()
  const Viewdetails = (emp_code, user_detail) => {
    navigate(`/hr/userdetailscrud/${emp_code}/${user_detail}/`)
  }
  return (

    <>
      <button className="" title="View Employees" style={{ paddingLeft: "5px", color: 'blue', fontSize: '14px' }} onClick={handleShow}>
        View
      </button>
      <Modal show={show} onHide={handleClose} dialogClassName="request-leave">
        <Modal.Body>
          <>
            {/* <div className="attendance-history-cont">
              <div className="attendance-heading"></div>
            </div> */}
            <div className="custom-table-background">
              <div className="table-heading-flex">
                <div className="repo-heading">Employees in : <br />{department.title}
                </div>
                <div className="flex-row">
                  <div className="table-searchh">
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="11"
                        height="12"
                        viewBox="0 0 11 12"
                        fill="none"
                      >
                        <circle
                          cx="5"
                          cy="5"
                          r="4.3"
                          stroke="#2B3674"
                          strokeWidth="1.4"
                        />
                        <line
                          x1="10.0101"
                          y1="11"
                          x2="8"
                          y2="8.98995"
                          stroke="#2B3674"
                          strokeWidth="1.4"
                          strokeLinecap="round"
                        />
                      </svg>
                    </div>
                    <div>
                      <input
                        placeholder="Search"
                        className="navbar-inputt"
                        value={searchQuery}
                        onChange={handleSearchChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <table className="custom-table">
                <thead>
                  <tr className="custom-table-head-tr">
                    <th className="align-left">ID</th>
                    <th className="align-center">Emp Code</th>
                    <th className="align-center"> Name </th>
                    <th className="align-center">Employee View</th>
                  </tr>
                </thead>
                <tbody>
                  {departmentEmployees
                    ?.filter((e) =>
                      e.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
                      e.emp_code.toString().includes(searchQuery.toLowerCase())
                    ).sort((a, b) => a.emp_code - b.emp_code)
                    .map((i, index) => (
                      <React.Fragment key={index}>
                        {/* <tr className="tr-border-bottom">
                          <td colSpan="6"></td>
                        </tr> */}
                        <tr className="custom-table-head-td">
                          <td className="align-left">{index + 1}</td>
                          <td className="align-center">{i.emp_code}</td>
                          <td className="align-center">{i.name}</td>

                          <td className="align-center">
                            <button className='employee-edit' onClick={() => Viewdetails(i.emp_code, i.user_detail)} title="View Profile">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="40"
                                height="40"
                                viewBox="0 0 30 30"
                                fill="none">

                                <circle cx="15" cy="15" r="15" fill="#F6F7F9" />
                                <path
                                  d="M23.8913 14.5437C22.1966 11.2372 18.8416 9 15 9C11.1584 9 7.80249 11.2388 6.10873 14.5441C6.03725 14.6855 6 14.8417 6 15.0002C6 15.1586 6.03725 15.3148 6.10873 15.4563C7.80342 18.7628 11.1584 21 15 21C18.8416 21 22.1975 18.7612 23.8913 15.4559C23.9628 15.3145 24 15.1583 24 14.9998C24 14.8414 23.9628 14.6852 23.8913 14.5437ZM15 19.5C14.11 19.5 13.24 19.2361 12.4999 18.7416C11.7599 18.2471 11.1831 17.5443 10.8425 16.7221C10.5019 15.8998 10.4128 14.995 10.5865 14.1221C10.7601 13.2492 11.1887 12.4474 11.818 11.818C12.4474 11.1887 13.2492 10.7601 14.1221 10.5865C14.995 10.4128 15.8998 10.5019 16.7221 10.8425C17.5443 11.1831 18.2472 11.7599 18.7416 12.4999C19.2361 13.24 19.5 14.11 19.5 15C19.5003 15.591 19.3841 16.1763 19.1581 16.7224C18.932 17.2685 18.6005 17.7647 18.1826 18.1826C17.7647 18.6005 17.2685 18.932 16.7224 19.158C16.1763 19.3841 15.591 19.5003 15 19.5ZM15 12C14.7322 12.0037 14.4662 12.0436 14.2091 12.1184C14.421 12.4065 14.5227 12.7609 14.4957 13.1175C14.4688 13.4741 14.3149 13.8092 14.062 14.062C13.8092 14.3149 13.4741 14.4688 13.1175 14.4957C12.7609 14.5227 12.4065 14.421 12.1184 14.2091C11.9544 14.8133 11.984 15.4538 12.2031 16.0403C12.4221 16.6269 12.8196 17.1299 13.3396 17.4787C13.8595 17.8275 14.4758 18.0045 15.1016 17.9847C15.7274 17.965 16.3312 17.7495 16.8281 17.3685C17.325 16.9876 17.6899 16.4604 17.8715 15.8612C18.0531 15.2621 18.0422 14.621 17.8404 14.0283C17.6386 13.4356 17.256 12.9211 16.7465 12.5573C16.2369 12.1934 15.6261 11.9985 15 12Z"
                                  fill="#2576BC"
                                />

                              </svg></button>
                          </td>

                        </tr>
                      </React.Fragment>
                    ))}
                </tbody>
              </table>
            </div>

          </>
        </Modal.Body>
      </Modal>
    </>

  );
};



const DesignationTable = () => {
  const [designationname, setDesignationname] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  const getDesignation = async () => {
    try {
      const res = await axios.get(`${BASE_URL}/wfm/designationwfm/`);
      setDesignationname(res.data);
    } catch (err) {
      // alert(err.message);
    }
  };
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };
  useEffect(() => {
    getDesignation();
  }, []);

  return (
    <div className="custom-table-background">
      <div className="table-heading-flex">
        <div className="repo-heading">Designation</div>
        <div className="flex-row">
          <div className="table-searchh">
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="11"
                height="12"
                viewBox="0 0 11 12"
                fill="none"
              >
                <circle
                  cx="5"
                  cy="5"
                  r="4.3"
                  stroke="#2B3674"
                  strokeWidth="1.4"
                />
                <line
                  x1="10.0101"
                  y1="11"
                  x2="8"
                  y2="8.98995"
                  stroke="#2B3674"
                  strokeWidth="1.4"
                  strokeLinecap="round"
                />
              </svg>
            </div>
            <div>
              <input
                placeholder="Search"
                className="navbar-inputt"
                value={searchQuery}
                onChange={handleSearchChange}
              />
            </div>
          </div>
          <DesignationName getDesignation={getDesignation} designationname={designationname} />
        </div>
      </div>
      <table className="custom-table">
        <thead>
          <tr className="custom-table-head-tr">
            <th className="align-left">ID</th>
            <th className="align-center">Designation</th>
            <th className="align-center">Employee Count</th>
            <th className="align-center">Employee View</th>
            <th className="align-right">Action</th>
          </tr>
        </thead>
        <tbody>
          {designationname?.filter((e) =>
            e.title.toLowerCase().includes(searchQuery.toLowerCase())
          ).sort((a, b) =>
            a.title.localeCompare(b.title, "en", { ignorePunctuation: true })
          ).map((i, index) => (
            <React.Fragment key={index}>
              <tr className="tr-border-bottom">
                <td colSpan="6"></td>
              </tr>
              <tr className="custom-table-head-td">
                <td className="align-left">{index + 1}</td>
                <td className="align-center">{i.title}</td>
                <td className="align-center">{i.employee_count}</td>
                <td className="align-center"><EmployeebyDesignationTable designation={i} /></td>
                <td className="align-right ">
                  <DesignationUpdate i={i} getDesignation={getDesignation} />

                  <DesignationDelete i={i} getDesignation={getDesignation} />
                </td>
              </tr>
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const EmployeebyDesignationTable = ({ designation }) => {

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true)
    getDesignationEmployees(designation.id);
  };
  const [designationEmployees, setDesignationEmployees] = useState([]);

  const getDesignationEmployees = async (designationId) => {
    try {
      const res = await axios.get(`${BASE_URL}/wfm/employeedetailsbydesignation/${designationId}/`);
      setDesignationEmployees(res.data);
    } catch (err) {
      // alert(err.message);
    }
  };

  const [searchQuery, setSearchQuery] = useState("");
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  // useEffect(() => {
  //   getDesignationEmployees();
  // }, []);

  const navigate = useNavigate()
  const Viewdetails = (emp_code, user_detail) => {
    navigate(`/hr/userdetailscrud/${emp_code}/${user_detail}/`)
  }
  return (

    <>
      <button className="" title="View Employees" style={{ paddingLeft: "5px", color: 'blue', fontSize: '14px' }} onClick={handleShow}>
        View
      </button>
      <Modal show={show} onHide={handleClose} dialogClassName="request-leave">
        <Modal.Body>
          <>
            {/* <div className="attendance-history-cont">
              <div className="attendance-heading"></div>
            </div> */}
            <div className="custom-table-background">
              <div className="table-heading-flex">
                <div className="repo-heading">Employees as : <br />{designation.title}
                </div>
                <div className="flex-row">
                  <div className="table-searchh">
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="11"
                        height="12"
                        viewBox="0 0 11 12"
                        fill="none"
                      >
                        <circle
                          cx="5"
                          cy="5"
                          r="4.3"
                          stroke="#2B3674"
                          strokeWidth="1.4"
                        />
                        <line
                          x1="10.0101"
                          y1="11"
                          x2="8"
                          y2="8.98995"
                          stroke="#2B3674"
                          strokeWidth="1.4"
                          strokeLinecap="round"
                        />
                      </svg>
                    </div>
                    <div>
                      <input
                        placeholder="Search"
                        className="navbar-inputt"
                        value={searchQuery}
                        onChange={handleSearchChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <table className="custom-table">
                <thead>
                  <tr className="custom-table-head-tr">
                    <th className="align-left">ID</th>
                    <th className="align-center">Emp Code</th>
                    <th className="align-center"> Name </th>
                    <th className="align-center">Employee View</th>
                  </tr>
                </thead>
                <tbody>
                  {designationEmployees
                    ?.filter((e) =>
                      e.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
                      e.emp_code.toString().includes(searchQuery.toLowerCase())
                    ).sort((a, b) => a.emp_code - b.emp_code)
                    .map((i, index) => (
                      <React.Fragment key={index}>
                        {/* <tr className="tr-border-bottom">
                          <td colSpan="6"></td>
                        </tr> */}
                        <tr className="custom-table-head-td">
                          <td className="align-left">{index + 1}</td>
                          <td className="align-center">{i.emp_code}</td>
                          <td className="align-center">{i.name}</td>

                          <td className="align-center">
                            <button className='employee-edit' onClick={() => Viewdetails(i.emp_code, i.user_detail)} title="View Profile">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="40"
                                height="40"
                                viewBox="0 0 30 30"
                                fill="none">

                                <circle cx="15" cy="15" r="15" fill="#F6F7F9" />
                                <path
                                  d="M23.8913 14.5437C22.1966 11.2372 18.8416 9 15 9C11.1584 9 7.80249 11.2388 6.10873 14.5441C6.03725 14.6855 6 14.8417 6 15.0002C6 15.1586 6.03725 15.3148 6.10873 15.4563C7.80342 18.7628 11.1584 21 15 21C18.8416 21 22.1975 18.7612 23.8913 15.4559C23.9628 15.3145 24 15.1583 24 14.9998C24 14.8414 23.9628 14.6852 23.8913 14.5437ZM15 19.5C14.11 19.5 13.24 19.2361 12.4999 18.7416C11.7599 18.2471 11.1831 17.5443 10.8425 16.7221C10.5019 15.8998 10.4128 14.995 10.5865 14.1221C10.7601 13.2492 11.1887 12.4474 11.818 11.818C12.4474 11.1887 13.2492 10.7601 14.1221 10.5865C14.995 10.4128 15.8998 10.5019 16.7221 10.8425C17.5443 11.1831 18.2472 11.7599 18.7416 12.4999C19.2361 13.24 19.5 14.11 19.5 15C19.5003 15.591 19.3841 16.1763 19.1581 16.7224C18.932 17.2685 18.6005 17.7647 18.1826 18.1826C17.7647 18.6005 17.2685 18.932 16.7224 19.158C16.1763 19.3841 15.591 19.5003 15 19.5ZM15 12C14.7322 12.0037 14.4662 12.0436 14.2091 12.1184C14.421 12.4065 14.5227 12.7609 14.4957 13.1175C14.4688 13.4741 14.3149 13.8092 14.062 14.062C13.8092 14.3149 13.4741 14.4688 13.1175 14.4957C12.7609 14.5227 12.4065 14.421 12.1184 14.2091C11.9544 14.8133 11.984 15.4538 12.2031 16.0403C12.4221 16.6269 12.8196 17.1299 13.3396 17.4787C13.8595 17.8275 14.4758 18.0045 15.1016 17.9847C15.7274 17.965 16.3312 17.7495 16.8281 17.3685C17.325 16.9876 17.6899 16.4604 17.8715 15.8612C18.0531 15.2621 18.0422 14.621 17.8404 14.0283C17.6386 13.4356 17.256 12.9211 16.7465 12.5573C16.2369 12.1934 15.6261 11.9985 15 12Z"
                                  fill="#2576BC"
                                />

                              </svg></button>
                          </td>

                        </tr>
                      </React.Fragment>
                    ))}
                </tbody>
              </table>
            </div>

          </>
        </Modal.Body>
      </Modal>
    </>

  );
};

const Subcompany = () => {
  const [SubCompany, setSubCompany] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };
  const getSubCompany = async () => {
    try {
      const res = await axios.get(`${BASE_URL}/wfm/subcompanywfm/`);
      setSubCompany(res.data);
    } catch (err) {
      // alert(err.message);
    }
  };

  useEffect(() => {
    getSubCompany();
  }, []);

  return (
    <div className="custom-table-background">
      <div className="table-heading-flex">
        <div className="repo-heading">Sub Company</div>
        <div className="flex-row">
          <div className="table-searchh">
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="11"
                height="12"
                viewBox="0 0 11 12"
                fill="none"
              >
                <circle
                  cx="5"
                  cy="5"
                  r="4.3"
                  stroke="#2B3674"
                  strokeWidth="1.4"
                />
                <line
                  x1="10.0101"
                  y1="11"
                  x2="8"
                  y2="8.98995"
                  stroke="#2B3674"
                  strokeWidth="1.4"
                  strokeLinecap="round"
                />
              </svg>
            </div>
            <div>
              <input
                placeholder="Search"
                className="navbar-inputt"
                value={searchQuery}
                onChange={handleSearchChange}
              />
            </div>
          </div>
          <Subcompanys getSubCompany={getSubCompany} />
        </div>
      </div>
      <table className="custom-table">
        <thead>
          <tr className="custom-table-head-tr">
            <th className="align-left">ID</th>
            <th className="align-center">Company Name</th>
            <th className="align-center">Employee Count</th>
            <th className="align-center">Employee View</th>
            <th className="align-right">Action</th>
          </tr>
        </thead>
        <tbody>
          {SubCompany?.filter((e) =>
            e.title.toLowerCase().includes(searchQuery.toLowerCase())
          ).sort((a, b) =>
            a.title.localeCompare(b.title, "en", { ignorePunctuation: true })
          ).map((i, index) => (
            <React.Fragment key={index}>
              <tr className="tr-border-bottom">
                <td colSpan="6"></td>
              </tr>
              <tr className="custom-table-head-td">
                <td className="align-left">{index + 1}</td>
                <td className="align-center">{i.title}</td>
                <td className="align-center">{i.employee_count}</td>
                <td className="align-center"><EmployeebySubCompanyTable subcompany={i} /></td>
                <td className="align-right ">
                  <Subcompanyupdate i={i} getSubCompany={getSubCompany} />

                  <SubcompanyDelete i={i} getSubCompany={getSubCompany} />
                </td>
              </tr>
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const EmployeebySubCompanyTable = ({ subcompany }) => {

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true)
    getSubCompanyEmployees(subcompany.id);
  };

  const [subcompanyEmployees, setSubCompanyEmployees] = useState([]);

  const getSubCompanyEmployees = async (subcompanyId) => {
    try {
      const res = await axios.get(`${BASE_URL}/wfm/employeedetailsbysubcompany/${subcompanyId}/`);
      setSubCompanyEmployees(res.data);
    } catch (err) {
      // alert(err.message);
    }
  };

  const [searchQuery, setSearchQuery] = useState("");
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  // useEffect(() => {
  //   getSubCompanyEmployees();
  // }, []);

  const navigate = useNavigate()
  const Viewdetails = (emp_code, user_detail) => {
    navigate(`/hr/userdetailscrud/${emp_code}/${user_detail}/`)
  }
  return (

    <>
      <button className="" title="View Employees" style={{ paddingLeft: "5px", color: 'blue', fontSize: '14px' }} onClick={handleShow}>
        View
      </button>
      <Modal show={show} onHide={handleClose} dialogClassName="request-leave">
        <Modal.Body>
          <>
            {/* <div className="attendance-history-cont">
              <div className="attendance-heading"></div>
            </div> */}
            <div className="custom-table-background">
              <div className="table-heading-flex">
                <div className="repo-heading">Employees in : <br />{subcompany.title}
                </div>
                <div className="flex-row">
                  <div className="table-searchh">
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="11"
                        height="12"
                        viewBox="0 0 11 12"
                        fill="none"
                      >
                        <circle
                          cx="5"
                          cy="5"
                          r="4.3"
                          stroke="#2B3674"
                          strokeWidth="1.4"
                        />
                        <line
                          x1="10.0101"
                          y1="11"
                          x2="8"
                          y2="8.98995"
                          stroke="#2B3674"
                          strokeWidth="1.4"
                          strokeLinecap="round"
                        />
                      </svg>
                    </div>
                    <div>
                      <input
                        placeholder="Search"
                        className="navbar-inputt"
                        value={searchQuery}
                        onChange={handleSearchChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <table className="custom-table">
                <thead>
                  <tr className="custom-table-head-tr">
                    <th className="align-left">ID</th>
                    <th className="align-center">Emp Code</th>
                    <th className="align-center"> Name </th>
                    <th className="align-center">Employee View</th>
                  </tr>
                </thead>
                <tbody>
                  {subcompanyEmployees
                    ?.filter((e) =>
                      e.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
                      e.emp_code.toString().includes(searchQuery.toLowerCase())
                    ).sort((a, b) => a.emp_code - b.emp_code)
                    .map((i, index) => (
                      <React.Fragment key={index}>
                        {/* <tr className="tr-border-bottom">
                          <td colSpan="6"></td>
                        </tr> */}
                        <tr className="custom-table-head-td">
                          <td className="align-left">{index + 1}</td>
                          <td className="align-center">{i.emp_code}</td>
                          <td className="align-center">{i.name}</td>

                          <td className="align-center">
                            <button className='employee-edit' onClick={() => Viewdetails(i.emp_code, i.user_detail)} title="View Profile">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="40"
                                height="40"
                                viewBox="0 0 30 30"
                                fill="none">

                                <circle cx="15" cy="15" r="15" fill="#F6F7F9" />
                                <path
                                  d="M23.8913 14.5437C22.1966 11.2372 18.8416 9 15 9C11.1584 9 7.80249 11.2388 6.10873 14.5441C6.03725 14.6855 6 14.8417 6 15.0002C6 15.1586 6.03725 15.3148 6.10873 15.4563C7.80342 18.7628 11.1584 21 15 21C18.8416 21 22.1975 18.7612 23.8913 15.4559C23.9628 15.3145 24 15.1583 24 14.9998C24 14.8414 23.9628 14.6852 23.8913 14.5437ZM15 19.5C14.11 19.5 13.24 19.2361 12.4999 18.7416C11.7599 18.2471 11.1831 17.5443 10.8425 16.7221C10.5019 15.8998 10.4128 14.995 10.5865 14.1221C10.7601 13.2492 11.1887 12.4474 11.818 11.818C12.4474 11.1887 13.2492 10.7601 14.1221 10.5865C14.995 10.4128 15.8998 10.5019 16.7221 10.8425C17.5443 11.1831 18.2472 11.7599 18.7416 12.4999C19.2361 13.24 19.5 14.11 19.5 15C19.5003 15.591 19.3841 16.1763 19.1581 16.7224C18.932 17.2685 18.6005 17.7647 18.1826 18.1826C17.7647 18.6005 17.2685 18.932 16.7224 19.158C16.1763 19.3841 15.591 19.5003 15 19.5ZM15 12C14.7322 12.0037 14.4662 12.0436 14.2091 12.1184C14.421 12.4065 14.5227 12.7609 14.4957 13.1175C14.4688 13.4741 14.3149 13.8092 14.062 14.062C13.8092 14.3149 13.4741 14.4688 13.1175 14.4957C12.7609 14.5227 12.4065 14.421 12.1184 14.2091C11.9544 14.8133 11.984 15.4538 12.2031 16.0403C12.4221 16.6269 12.8196 17.1299 13.3396 17.4787C13.8595 17.8275 14.4758 18.0045 15.1016 17.9847C15.7274 17.965 16.3312 17.7495 16.8281 17.3685C17.325 16.9876 17.6899 16.4604 17.8715 15.8612C18.0531 15.2621 18.0422 14.621 17.8404 14.0283C17.6386 13.4356 17.256 12.9211 16.7465 12.5573C16.2369 12.1934 15.6261 11.9985 15 12Z"
                                  fill="#2576BC"
                                />

                              </svg></button>
                          </td>

                        </tr>
                      </React.Fragment>
                    ))}
                </tbody>
              </table>
            </div>

          </>
        </Modal.Body>
      </Modal>
    </>

  );
};

const Worksite = () => {
  const [worksite, setWorksite] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };
  const getWoksiteList = async () => {
    try {
      const res = await axios.get(`${BASE_URL}/wfm/worksitewfm/`);
      setWorksite(res.data);
    } catch (err) {
      // alert(err.message);
    }
  };

  useEffect(() => {
    getWoksiteList();
  }, []);
  return (
    <div className="custom-table-background">
      <div className="table-heading-flex">
        <div className="repo-heading">Work Site</div>
        <div className="flex-row">
          <div className="table-searchh">
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="11"
                height="12"
                viewBox="0 0 11 12"
                fill="none"
              >
                <circle
                  cx="5"
                  cy="5"
                  r="4.3"
                  stroke="#2B3674"
                  strokeWidth="1.4"
                />
                <line
                  x1="10.0101"
                  y1="11"
                  x2="8"
                  y2="8.98995"
                  stroke="#2B3674"
                  strokeWidth="1.4"
                  strokeLinecap="round"
                />
              </svg>
            </div>
            <div>
              <input
                placeholder="Search"
                className="navbar-inputt"
                value={searchQuery}
                onChange={handleSearchChange}
              />
            </div>
          </div>
          <Woksiteadd getWoksiteList={getWoksiteList} />
        </div>
      </div>
      <table className="custom-table">
        <thead>
          <tr className="custom-table-head-tr">
            <th className="align-left">ID</th>
            <th className="align-center">Woksite Name</th>
            <th className="align-center">Employee Count</th>
            <th className="align-center">Employee View</th>
            <th className="align-right">Action</th>
          </tr>
        </thead>
        <tbody>
          {worksite?.filter((e) =>
            e.title.toLowerCase().includes(searchQuery.toLowerCase())
          ).sort((a, b) =>
            a.title.localeCompare(b.title, "en", { ignorePunctuation: true })
          ).map((i, index) => (
            <React.Fragment key={index}>
              <tr className="tr-border-bottom">
                <td colSpan="6"></td>
              </tr>
              <tr className="custom-table-head-td">
                <td className="align-left">{index + 1}</td>
                <td className="align-center">{i.title}</td>
                <td className="align-center">{i.employee_count}</td>
                <td className="align-center"><EmployeebyWorkSiteTable worksite={i} /></td>
                <td className="align-right ">
                  <Woksiteupdate i={i} getWoksiteList={getWoksiteList} />

                  <WoksiteDelete i={i} getWoksiteList={getWoksiteList} />
                </td>
              </tr>
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const EmployeebyWorkSiteTable = ({ worksite }) => { 

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true)
    getWorkSiteEmployees(worksite.id);
  };

  const [worksiteEmployees, setWorkSiteEmployees] = useState([]);

  const getWorkSiteEmployees = async (worksiteId) => {
    try {
      const res = await axios.get(`${BASE_URL}/wfm/employeedetailsbyworksite/${worksiteId}/`);
      setWorkSiteEmployees(res.data);
    } catch (err) {
      // alert(err.message);
    }
  };

  const [searchQuery, setSearchQuery] = useState("");
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  // useEffect(() => {
  //   getWorkSiteEmployees();
  // }, []);

  const navigate = useNavigate()
  const Viewdetails = (emp_code, user_detail) => {
    navigate(`/hr/userdetailscrud/${emp_code}/${user_detail}/`)
  }
  return (

    <>
      <button className="" title="View Employees" style={{ paddingLeft: "5px", color: 'blue', fontSize: '14px' }} onClick={handleShow}>
        View
      </button>
      <Modal show={show} onHide={handleClose} dialogClassName="request-leave">
        <Modal.Body>
          <>
            {/* <div className="attendance-history-cont">
              <div className="attendance-heading"></div>
            </div> */}
            <div className="custom-table-background">
              <div className="table-heading-flex">
                <div className="repo-heading">Employees in : <br /> {worksite.title} </div>
                <div className="flex-row">
                  <div className="table-searchh">
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="11"
                        height="12"
                        viewBox="0 0 11 12"
                        fill="none"
                      >
                        <circle
                          cx="5"
                          cy="5"
                          r="4.3"
                          stroke="#2B3674"
                          strokeWidth="1.4"
                        />
                        <line
                          x1="10.0101"
                          y1="11"
                          x2="8"
                          y2="8.98995"
                          stroke="#2B3674"
                          strokeWidth="1.4"
                          strokeLinecap="round"
                        />
                      </svg>
                    </div>
                    <div>
                      <input
                        placeholder="Search"
                        className="navbar-inputt"
                        value={searchQuery}
                        onChange={handleSearchChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <table className="custom-table">
                <thead>
                  <tr className="custom-table-head-tr">
                    <th className="align-left">ID</th>
                    <th className="align-center">Emp Code</th>
                    <th className="align-center"> Name </th>
                    <th className="align-center">Employee View</th>
                  </tr>
                </thead>
                <tbody>
                  {worksiteEmployees
                    ?.filter((e) =>
                      e.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
                      e.emp_code.toString().includes(searchQuery.toLowerCase())
                    ).sort((a, b) => a.emp_code - b.emp_code)
                    .map((i, index) => (
                      <React.Fragment key={index}>
                        {/* <tr className="tr-border-bottom">
                          <td colSpan="6"></td>
                        </tr> */}
                        <tr className="custom-table-head-td">
                          <td className="align-left">{index + 1}</td>
                          <td className="align-center">{i.emp_code}</td>
                          <td className="align-center">{i.name}</td>

                          <td className="align-center">
                            <button className='employee-edit' onClick={() => Viewdetails(i.emp_code, i.user_detail)} title="View Profile">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="40"
                                height="40"
                                viewBox="0 0 30 30"
                                fill="none">

                                <circle cx="15" cy="15" r="15" fill="#F6F7F9" />
                                <path
                                  d="M23.8913 14.5437C22.1966 11.2372 18.8416 9 15 9C11.1584 9 7.80249 11.2388 6.10873 14.5441C6.03725 14.6855 6 14.8417 6 15.0002C6 15.1586 6.03725 15.3148 6.10873 15.4563C7.80342 18.7628 11.1584 21 15 21C18.8416 21 22.1975 18.7612 23.8913 15.4559C23.9628 15.3145 24 15.1583 24 14.9998C24 14.8414 23.9628 14.6852 23.8913 14.5437ZM15 19.5C14.11 19.5 13.24 19.2361 12.4999 18.7416C11.7599 18.2471 11.1831 17.5443 10.8425 16.7221C10.5019 15.8998 10.4128 14.995 10.5865 14.1221C10.7601 13.2492 11.1887 12.4474 11.818 11.818C12.4474 11.1887 13.2492 10.7601 14.1221 10.5865C14.995 10.4128 15.8998 10.5019 16.7221 10.8425C17.5443 11.1831 18.2472 11.7599 18.7416 12.4999C19.2361 13.24 19.5 14.11 19.5 15C19.5003 15.591 19.3841 16.1763 19.1581 16.7224C18.932 17.2685 18.6005 17.7647 18.1826 18.1826C17.7647 18.6005 17.2685 18.932 16.7224 19.158C16.1763 19.3841 15.591 19.5003 15 19.5ZM15 12C14.7322 12.0037 14.4662 12.0436 14.2091 12.1184C14.421 12.4065 14.5227 12.7609 14.4957 13.1175C14.4688 13.4741 14.3149 13.8092 14.062 14.062C13.8092 14.3149 13.4741 14.4688 13.1175 14.4957C12.7609 14.5227 12.4065 14.421 12.1184 14.2091C11.9544 14.8133 11.984 15.4538 12.2031 16.0403C12.4221 16.6269 12.8196 17.1299 13.3396 17.4787C13.8595 17.8275 14.4758 18.0045 15.1016 17.9847C15.7274 17.965 16.3312 17.7495 16.8281 17.3685C17.325 16.9876 17.6899 16.4604 17.8715 15.8612C18.0531 15.2621 18.0422 14.621 17.8404 14.0283C17.6386 13.4356 17.256 12.9211 16.7465 12.5573C16.2369 12.1934 15.6261 11.9985 15 12Z"
                                  fill="#2576BC"
                                />

                              </svg></button>
                          </td>

                        </tr>
                      </React.Fragment>
                    ))}
                </tbody>
              </table>
            </div>

          </>
        </Modal.Body>
      </Modal>
    </>

  );
};

const AttendancePolicyTable = () => {
  const [attpolicyname, setAttPolicyname] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };
  const getAttPolicyName = async () => {
    try {
      const res = await axios.get(`${BASE_URL}/wfm/attendancepolicy/`);
      setAttPolicyname(res.data);
    } catch (err) {
      // alert(err.message);
    }
  };

  useEffect(() => {
    getAttPolicyName();
  }, []);

  return (
    <div className="custom-table-background">
      <div className="table-heading-flex">
        <div className="repo-heading">Attendance Policy</div>
        <div className="flex-row">
          <div className="table-searchh">
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="11"
                height="12"
                viewBox="0 0 11 12"
                fill="none"
              >
                <circle
                  cx="5"
                  cy="5"
                  r="4.3"
                  stroke="#2B3674"
                  strokeWidth="1.4"
                />
                <line
                  x1="10.0101"
                  y1="11"
                  x2="8"
                  y2="8.98995"
                  stroke="#2B3674"
                  strokeWidth="1.4"
                  strokeLinecap="round"
                />
              </svg>
            </div>
            <div>
              <input
                placeholder="Search"
                className="navbar-inputt"
                value={searchQuery}
                onChange={handleSearchChange}
              />
            </div>
          </div>
          <AttendancePolicy getAttPolicyName={getAttPolicyName} />
        </div>
      </div>
      <table className="custom-table">
        <thead>
          <tr className="custom-table-head-tr">
            <th className="align-left">ID</th>
            <th className="align-center">Name</th>
            <th className="align-center">Employee Count</th>
            <th className="align-center">Employee View</th>
            <th className="align-right">Action</th>
          </tr>
        </thead>
        <tbody>
          {attpolicyname
            ?.filter((e) =>
              e.name.toLowerCase().includes(searchQuery.toLowerCase())
            ).sort((a, b) =>
              a.name.localeCompare(b.name, "en", { ignorePunctuation: true })
            )
            .map((i, index) => (
              <React.Fragment key={index}>
                <tr className="tr-border-bottom">
                  <td colSpan="6"></td>
                </tr>
                <tr className="custom-table-head-td">
                  <td className="align-left">{index + 1}</td>
                  <td className="align-center">{i.name}</td>

                  <td className="align-center">{i?.active_employee_count}</td>
                  <td className="align-center"><EmployeebyAttendancePolicyTable policy={i} /></td>
                  <td className="align-right ">
                    <AttendancePolicyUpdate
                      i={i}
                      getAttPolicyName={getAttPolicyName}
                    />

                    <AttendancePolicyDelete
                      i={i}
                      getAttPolicyName={getAttPolicyName}
                    />
                  </td>
                </tr>
              </React.Fragment>
            ))}
        </tbody>
      </table>
    </div>
  );
};

const WeeklyOffPolicyTable = () => {
  const [WeeklyOffpolicyname, setWeeklyOffPolicyname] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };
  const getWeeklyOffPolicyName = async () => {
    try {
      const res = await axios.get(`${BASE_URL}/wfm/weeklyoffPolicy/`);
      setWeeklyOffPolicyname(res.data);
    } catch (err) {
      alert(err.message);
    }
  };

  useEffect(() => {
    getWeeklyOffPolicyName();
  }, []);

  return (
    <div className="custom-table-background">
      <div className="table-heading-flex">
        <div className="repo-heading">Weekly Off Policy</div>
        <div className="flex-row">
          <div className="table-searchh">
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="11"
                height="12"
                viewBox="0 0 11 12"
                fill="none"
              >
                <circle
                  cx="5"
                  cy="5"
                  r="4.3"
                  stroke="#2B3674"
                  strokeWidth="1.4"
                />
                <line
                  x1="10.0101"
                  y1="11"
                  x2="8"
                  y2="8.98995"
                  stroke="#2B3674"
                  strokeWidth="1.4"
                  strokeLinecap="round"
                />
              </svg>
            </div>
            <div>
              <input
                placeholder="Search"
                className="navbar-inputt"
                value={searchQuery}
                onChange={handleSearchChange}
              />
            </div>
          </div>
          <WeeklyOffPolicy getWeeklyOffPolicyName={getWeeklyOffPolicyName} />
        </div>
      </div>
      <table className="custom-table">
        <thead>
          <tr className="custom-table-head-tr">
            <th className="align-left">ID</th>
            <th className="align-center">Name</th>
            <th className="align-center">Employee Count</th>
            <th className="align-center">Employee View</th>
            <th className="align-right">Action</th>
          </tr>
        </thead>
        <tbody>
          {WeeklyOffpolicyname
            ?.filter((e) =>
              e.name.toLowerCase().includes(searchQuery.toLowerCase())
            ).sort((a, b) =>
              a.name.localeCompare(b.name, "en", { ignorePunctuation: true })
            )
            .map((i, index) => (
              <React.Fragment key={index}>
                <tr className="tr-border-bottom">
                  <td colSpan="6"></td>
                </tr>
                <tr className="custom-table-head-td">
                  <td className="align-left">{index + 1}</td>
                  <td className="align-center">{i.name}</td>

                  <td className="align-center">{i?.active_employee_count}</td>
                  <td className="align-center"><EmployeebyWeeklyOffPolicyTable policy={i} /></td>
                  <td className="align-right ">
                    <WeeklyOffPolicyUpdate
                      i={i}
                      getWeeklyOffPolicyName={getWeeklyOffPolicyName}
                    />

                    <WeeklyOffPolicyDelete
                      i={i}
                      getWeeklyOffPolicyName={getWeeklyOffPolicyName}
                    />
                  </td>
                </tr>
              </React.Fragment>
            ))}
        </tbody>
      </table>
    </div>
  );
};


const EmployeebyAttendancePolicyTable = ({ policy }) => {

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true)
    getAttendancePolicyEmployees(policy.id);
  };
  const [attendancePolicyEmployees, setAttendancePolicyEmployees] = useState([]);

  const getAttendancePolicyEmployees = async (policyId) => {
    try {
      const res = await axios.get(`${BASE_URL}/wfm/employeedetailsbyattendancepolicy/${policyId}/`);
      setAttendancePolicyEmployees(res.data);
    } catch (err) {
      // alert(err.message);
    }
  };

  const [searchQuery, setSearchQuery] = useState("");
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  // useEffect(() => {
  //   getAttendancePolicyEmployees();
  // }, []);

  const navigate = useNavigate()
  const Viewdetails = (emp_code, user_detail) => {
    navigate(`/hr/userdetailscrud/${emp_code}/${user_detail}/`)
  }
  return (

    <>
      <button className="" title="View Employees" style={{ paddingLeft: "5px", color: 'blue', fontSize: '14px' }} onClick={handleShow}>
        View
      </button>
      <Modal show={show} onHide={handleClose} dialogClassName="request-leave">
        <Modal.Body>
          <>
            {/* <div className="attendance-history-cont">
              <div className="attendance-heading"></div>
            </div> */}
            <div className="custom-table-background">
              <div className="table-heading-flex">
                <div className="repo-heading">Employees with : <br /> {policy.name} Policy
                </div>
                <div className="flex-row">
                  <div className="table-searchh">
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="11"
                        height="12"
                        viewBox="0 0 11 12"
                        fill="none"
                      >
                        <circle
                          cx="5"
                          cy="5"
                          r="4.3"
                          stroke="#2B3674"
                          strokeWidth="1.4"
                        />
                        <line
                          x1="10.0101"
                          y1="11"
                          x2="8"
                          y2="8.98995"
                          stroke="#2B3674"
                          strokeWidth="1.4"
                          strokeLinecap="round"
                        />
                      </svg>
                    </div>
                    <div>
                      <input
                        placeholder="Search"
                        className="navbar-inputt"
                        value={searchQuery}
                        onChange={handleSearchChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <table className="custom-table">
                <thead>
                  <tr className="custom-table-head-tr">
                    <th className="align-left">ID</th>
                    <th className="align-center">Emp Code</th>
                    <th className="align-center"> Name </th>
                    <th className="align-center">Employee View</th>
                  </tr>
                </thead>
                <tbody>
                  {attendancePolicyEmployees
                    ?.filter((e) =>
                      e.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
                      e.emp_code.toString().includes(searchQuery.toLowerCase())
                    ).sort((a, b) => a.emp_code - b.emp_code)
                    .map((i, index) => (
                      <React.Fragment key={index}>
                        {/* <tr className="tr-border-bottom">
                          <td colSpan="6"></td>
                        </tr> */}
                        <tr className="custom-table-head-td">
                          <td className="align-left">{index + 1}</td>
                          <td className="align-center">{i.emp_code}</td>
                          <td className="align-center">{i.name}</td>
                          <td className="align-center">
                            <button className='employee-edit' onClick={() => Viewdetails(i.emp_code, i.user_detail)} title="View Profile">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="40"
                                height="40"
                                viewBox="0 0 30 30"
                                fill="none">

                                <circle cx="15" cy="15" r="15" fill="#F6F7F9" />
                                <path
                                  d="M23.8913 14.5437C22.1966 11.2372 18.8416 9 15 9C11.1584 9 7.80249 11.2388 6.10873 14.5441C6.03725 14.6855 6 14.8417 6 15.0002C6 15.1586 6.03725 15.3148 6.10873 15.4563C7.80342 18.7628 11.1584 21 15 21C18.8416 21 22.1975 18.7612 23.8913 15.4559C23.9628 15.3145 24 15.1583 24 14.9998C24 14.8414 23.9628 14.6852 23.8913 14.5437ZM15 19.5C14.11 19.5 13.24 19.2361 12.4999 18.7416C11.7599 18.2471 11.1831 17.5443 10.8425 16.7221C10.5019 15.8998 10.4128 14.995 10.5865 14.1221C10.7601 13.2492 11.1887 12.4474 11.818 11.818C12.4474 11.1887 13.2492 10.7601 14.1221 10.5865C14.995 10.4128 15.8998 10.5019 16.7221 10.8425C17.5443 11.1831 18.2472 11.7599 18.7416 12.4999C19.2361 13.24 19.5 14.11 19.5 15C19.5003 15.591 19.3841 16.1763 19.1581 16.7224C18.932 17.2685 18.6005 17.7647 18.1826 18.1826C17.7647 18.6005 17.2685 18.932 16.7224 19.158C16.1763 19.3841 15.591 19.5003 15 19.5ZM15 12C14.7322 12.0037 14.4662 12.0436 14.2091 12.1184C14.421 12.4065 14.5227 12.7609 14.4957 13.1175C14.4688 13.4741 14.3149 13.8092 14.062 14.062C13.8092 14.3149 13.4741 14.4688 13.1175 14.4957C12.7609 14.5227 12.4065 14.421 12.1184 14.2091C11.9544 14.8133 11.984 15.4538 12.2031 16.0403C12.4221 16.6269 12.8196 17.1299 13.3396 17.4787C13.8595 17.8275 14.4758 18.0045 15.1016 17.9847C15.7274 17.965 16.3312 17.7495 16.8281 17.3685C17.325 16.9876 17.6899 16.4604 17.8715 15.8612C18.0531 15.2621 18.0422 14.621 17.8404 14.0283C17.6386 13.4356 17.256 12.9211 16.7465 12.5573C16.2369 12.1934 15.6261 11.9985 15 12Z"
                                  fill="#2576BC"
                                />

                              </svg></button>
                          </td>

                        </tr>
                      </React.Fragment>
                    ))}
                </tbody>
              </table>
            </div>

          </>
        </Modal.Body>
      </Modal>
    </>

  );
};

const EmployeebyWeeklyOffPolicyTable = ({ policy }) => {

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true)
    getWeeklyOffPolicyEmployees(policy.id);
  };
  const [weekOffPolicyEmployees, setWeekOffPolicyEmployees] = useState([]);

  const getWeeklyOffPolicyEmployees = async (policyId) => {
    try {
      const res = await axios.get(`${BASE_URL}/wfm/employeedetailsbyweeklyoffpolicy/${policyId}/`);
      setWeekOffPolicyEmployees(res.data);
    } catch (err) {
      // alert(err.message);
    }
  };

  const [searchQuery, setSearchQuery] = useState("");
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  // useEffect(() => {
  //   getWeeklyOffPolicyEmployees();
  // }, []);

  const navigate = useNavigate()
  const Viewdetails = (emp_code, user_detail) => {
    navigate(`/hr/userdetailscrud/${emp_code}/${user_detail}/`)
  }
  return (

    <>
      <button className="" title="View Employees List" style={{ paddingLeft: "5px", color: 'blue', fontSize: '14px' }} onClick={handleShow}>
        View
      </button>
      <Modal show={show} onHide={handleClose} dialogClassName="request-leave">
        <Modal.Body>
          <>
            {/* <div className="attendance-history-cont">
              <div className="attendance-heading"></div>
            </div> */}
            <div className="custom-table-background">
              <div className="table-heading-flex">
                <div className="repo-heading">Employees with : <br /> {policy.name} Policy
                </div>
                <div className="flex-row">
                  <div className="table-searchh">
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="11"
                        height="12"
                        viewBox="0 0 11 12"
                        fill="none"
                      >
                        <circle
                          cx="5"
                          cy="5"
                          r="4.3"
                          stroke="#2B3674"
                          strokeWidth="1.4"
                        />
                        <line
                          x1="10.0101"
                          y1="11"
                          x2="8"
                          y2="8.98995"
                          stroke="#2B3674"
                          strokeWidth="1.4"
                          strokeLinecap="round"
                        />
                      </svg>
                    </div>
                    <div>
                      <input
                        placeholder="Search"
                        className="navbar-inputt"
                        value={searchQuery}
                        onChange={handleSearchChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <table className="custom-table">
                <thead>
                  <tr className="custom-table-head-tr">
                    <th className="align-left">ID</th>
                    <th className="align-center">Emp Code</th>
                    <th className="align-center"> Name </th>
                    <th className="align-center">Employee View</th>
                  </tr>
                </thead>
                <tbody>
                  {weekOffPolicyEmployees
                    ?.filter((e) =>
                      e.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
                      e.emp_code.toString().includes(searchQuery.toLowerCase())
                    ).sort((a, b) => a.emp_code - b.emp_code)
                    .map((i, index) => (
                      <React.Fragment key={index}>
                        {/* <tr className="tr-border-bottom">
                          <td colSpan="6"></td>
                        </tr> */}
                        <tr className="custom-table-head-td">
                          <td className="align-left">{index + 1}</td>
                          <td className="align-center">{i.emp_code}</td>
                          <td className="align-center">{i.name}</td>
                          <td className="align-center">
                            <button className='employee-edit' onClick={() => Viewdetails(i.emp_code, i.user_detail)} title="View Profile">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="40"
                                height="40"
                                viewBox="0 0 30 30"
                                fill="none">

                                <circle cx="15" cy="15" r="15" fill="#F6F7F9" />
                                <path
                                  d="M23.8913 14.5437C22.1966 11.2372 18.8416 9 15 9C11.1584 9 7.80249 11.2388 6.10873 14.5441C6.03725 14.6855 6 14.8417 6 15.0002C6 15.1586 6.03725 15.3148 6.10873 15.4563C7.80342 18.7628 11.1584 21 15 21C18.8416 21 22.1975 18.7612 23.8913 15.4559C23.9628 15.3145 24 15.1583 24 14.9998C24 14.8414 23.9628 14.6852 23.8913 14.5437ZM15 19.5C14.11 19.5 13.24 19.2361 12.4999 18.7416C11.7599 18.2471 11.1831 17.5443 10.8425 16.7221C10.5019 15.8998 10.4128 14.995 10.5865 14.1221C10.7601 13.2492 11.1887 12.4474 11.818 11.818C12.4474 11.1887 13.2492 10.7601 14.1221 10.5865C14.995 10.4128 15.8998 10.5019 16.7221 10.8425C17.5443 11.1831 18.2472 11.7599 18.7416 12.4999C19.2361 13.24 19.5 14.11 19.5 15C19.5003 15.591 19.3841 16.1763 19.1581 16.7224C18.932 17.2685 18.6005 17.7647 18.1826 18.1826C17.7647 18.6005 17.2685 18.932 16.7224 19.158C16.1763 19.3841 15.591 19.5003 15 19.5ZM15 12C14.7322 12.0037 14.4662 12.0436 14.2091 12.1184C14.421 12.4065 14.5227 12.7609 14.4957 13.1175C14.4688 13.4741 14.3149 13.8092 14.062 14.062C13.8092 14.3149 13.4741 14.4688 13.1175 14.4957C12.7609 14.5227 12.4065 14.421 12.1184 14.2091C11.9544 14.8133 11.984 15.4538 12.2031 16.0403C12.4221 16.6269 12.8196 17.1299 13.3396 17.4787C13.8595 17.8275 14.4758 18.0045 15.1016 17.9847C15.7274 17.965 16.3312 17.7495 16.8281 17.3685C17.325 16.9876 17.6899 16.4604 17.8715 15.8612C18.0531 15.2621 18.0422 14.621 17.8404 14.0283C17.6386 13.4356 17.256 12.9211 16.7465 12.5573C16.2369 12.1934 15.6261 11.9985 15 12Z"
                                  fill="#2576BC"
                                />

                              </svg></button>
                          </td>

                        </tr>
                      </React.Fragment>
                    ))}
                </tbody>
              </table>
            </div>

          </>
        </Modal.Body>
      </Modal>
    </>

  );
};

const CalenderTable = () => {

  const [calendername, setCalendername] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };
  const getcalender = async () => {
    try {
      const res = await axios.get(`${BASE_URL}/wfm/calander/`);
      setCalendername(res.data);
    } catch (err) {
      // alert(err.message);
    }
  };

  useEffect(() => {
    getcalender();
  }, []);

  return (
    <>
      <div className="custom-table-background">
        <div className="table-heading-flex">
          <div className="repo-heading">Calender </div>
          <div className="flex-row">
            <div className="table-searchh">
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="11"
                  height="12"
                  viewBox="0 0 11 12"
                  fill="none"
                >
                  <circle
                    cx="5"
                    cy="5"
                    r="4.3"
                    stroke="#2B3674"
                    strokeWidth="1.4"
                  />
                  <line
                    x1="10.0101"
                    y1="11"
                    x2="8"
                    y2="8.98995"
                    stroke="#2B3674"
                    strokeWidth="1.4"
                    strokeLinecap="round"
                  />
                </svg>
              </div>
              <div>
                <input
                  placeholder="Search"
                  className="navbar-inputt"
                  value={searchQuery}
                  onChange={handleSearchChange}
                  type="date"
                />
              </div>
            </div>
            <CalenderModel getcalender={getcalender} />
          </div>
        </div>
        <table className="custom-table">
          <thead>
            <tr className="custom-table-head-tr">
              <th className="align-left">Sr.no</th>
              <th className="align-center">Date</th>
              <th className="align-center">Description</th>
              <th className="align-center">type</th>
              <th className="align-right">Action</th>
            </tr>
          </thead>
          <tbody>
            {calendername?.filter((e) =>
              e.date.toLowerCase().includes(searchQuery.toLowerCase())
            ).sort((a, b) => new Date(b.date) - new Date(a.date)).map((i, index) => (
              <React.Fragment key={index}>
                <tr className="tr-border-bottom">
                  <td colSpan="6"></td>
                </tr>
                <tr className="custom-table-head-td">
                  <td className="align-left">{index + 1}</td>
                  <td className="align-center">{i.date}</td>
                  <td className="align-center">{i.descritpion}</td>
                  <td className="align-center">{i.offtype}</td>
                  <td className="align-right ">
                    <CalenderUpdate i={i} getcalender={getcalender} />
                    <CalenderDelete i={i} getcalender={getcalender} />
                  </td>
                </tr>
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

const TlCalenderTable = () => {
  const [calendername, setCalendername] = useState([]);

  const getcalender = async () => {
    try {
      const res = await axios.get(`${BASE_URL}/wfm/calander/`);
      setCalendername(res.data);
    } catch (err) {
      // alert(err.message);
    }
  };

  useEffect(() => {
    getcalender();
  }, []);

  return (
    <>
      <div className="custom-table-background">
        <div className="table-heading-flex">
          <div className="repo-heading">Calender </div>
          <div>
            {/* <CalenderModel getcalender={getcalender} /> */}
          </div>
        </div>
        <table className="custom-table">
          <thead>
            <tr className="custom-table-head-tr">
              <th className="align-left">Sr.no</th>
              <th className="align-center">Date</th>
              <th className="align-center">Description</th>
              <th className="align-center">Type</th>

            </tr>
          </thead>
          <tbody>
            {calendername.sort((a, b) => new Date(a.date) - new Date(b.date)).map((i, index) => (
              <React.Fragment key={index}>
                <tr className="tr-border-bottom">
                  <td colSpan="6"></td>
                </tr>
                <tr className="custom-table-head-td">
                  <td className="align-left">{index + 1}</td>
                  <td className="align-center">{formattedDateLong(i.date)}</td>
                  <td className="align-center">{i.descritpion}</td>
                  <td className="align-right">{i.offtype}</td>
                </tr>
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
}

const InterComTable = () => {
  const [show, setShow] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [interCom, setInterCom] = useState([])

  const getintercom = async () => {
    try {
      const res = await axios.get(`${BASE_URL}/wfm/intercom/`);
      setInterCom(res.data);
    } catch (err) {
      // alert(err.message);
    }
  };

  useEffect(() => {
    getintercom();
  }, []);

  return (
    <>
      <div className="custom-table-background">
        <div className="table-heading-flex">
          <div className="repo-heading">InterCom </div>
          <div className="flex-row">
            <div className="table-searchh">
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="11"
                  height="12"
                  viewBox="0 0 11 12"
                  fill="none"
                >
                  <circle
                    cx="5"
                    cy="5"
                    r="4.3"
                    stroke="#2B3674"
                    strokeWidth="1.4"
                  />
                  <line
                    x1="10.0101"
                    y1="11"
                    x2="8"
                    y2="8.98995"
                    stroke="#2B3674"
                    strokeWidth="1.4"
                    strokeLinecap="round"
                  />
                </svg>
              </div>
              <div>
                <input
                  placeholder="Search Extension No."
                  className="navbar-inputt"
                  value={searchQuery}
                  min={0}
                  onChange={handleSearchChange}
                  type="number"
                />
              </div>
            </div>
            <InterCom getintercom={getintercom} />
          </div>
        </div>
        <table className="custom-table">
          <thead>
            <tr className="custom-table-head-tr">
              <th className="align-left">Sr.no</th>
              <th className="align-center">User Detail</th>
              <th className="align-center">Work Email</th>
              <th className="align-center">Contact no.</th>
              <th className="align-center">Extension no.</th>
              <th className="align-right">Action</th>
            </tr>
          </thead>
          <tbody>
            {interCom?.filter((e) =>
              e.extensionno.toString().includes(searchQuery.toLowerCase())
            ).sort((a, b) =>
              a.extensionno.toString().localeCompare(b.title, "en", { ignorePunctuation: true })
            ).map((i, index) => (
              <React.Fragment key={index}>
                <tr className="tr-border-bottom">
                  <td colSpan="6"></td>
                </tr>
                <tr className="custom-table-head-td">
                  <td className="align-left">{index + 1}</td>
                  <td className="align-center">{i.user_detail}</td>
                  <td className="align-center">{i.work_email}</td>
                  <td className="align-center">{i.contactno}</td>
                  <td className="align-center">{i.extensionno}</td>
                  <td className="align-right ">
                    <InterComUpdate i={i} getintercom={getintercom} />
                    <InterComDelete i={i} getintercom={getintercom} />
                  </td>
                </tr>
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </>
  )
}



const TLintercomTable = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [interCom, setInterCom] = useState([])

  const getintercom = async () => {
    try {
      const res = await axios.get(`${BASE_URL}/wfm/intercom/`);
      setInterCom(res.data);
    } catch (err) {
      // alert(err.message);
    }
  };

  useEffect(() => {
    getintercom();
  }, []);

  return (
    <>
      <div className="custom-table-background">
        <div className="table-heading-flex">
          <div className="repo-heading">InterCom </div>
          <div>

          </div>
        </div>
        <table className="custom-table">
          <thead>
            <tr className="custom-table-head-tr">
              <th className="align-left">Sr.no</th>
              <th className="align-center">User Detail</th>
              <th className="align-center">Work Email</th>
              <th className="align-center">Contact no.</th>
              <th className="align-right">Extension no.</th>

            </tr>
          </thead>
          <tbody>
            {interCom.map((i, index) => (
              <React.Fragment key={index}>
                <tr className="tr-border-bottom">
                  <td colSpan="6"></td>
                </tr>
                <tr className="custom-table-head-td">
                  <td className="align-left">{index + 1}</td>
                  <td className="align-center">{i.user_detail}</td>
                  <td className="align-center">{i.work_email}</td>
                  <td className="align-center">{i.contactno}</td>
                  <td className="align-right">{i.extensionno}</td>

                </tr>
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </>
  )
}


const EssLMachineTable = () => {
  const [show, setShow] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [esslList, setEssslList] = useState([])

  const getEssl = async () => {
    try {
      const res = await axios.get(`${BASE_URL}/wfm/esslList/`);
      setEssslList(res.data);
    } catch (err) {
      // alert(err.message);
    }
  };

  useEffect(() => {
    getEssl();
  }, []);

  return (
    <>
      <div className="custom-table-background">
        <div className="table-heading-flex">
          <div className="repo-heading">esslList </div>
          <div className="flex-row">
            <div className="table-searchh">
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="11"
                  height="12"
                  viewBox="0 0 11 12"
                  fill="none"
                >
                  <circle
                    cx="5"
                    cy="5"
                    r="4.3"
                    stroke="#2B3674"
                    strokeWidth="1.4"
                  />
                  <line
                    x1="10.0101"
                    y1="11"
                    x2="8"
                    y2="8.98995"
                    stroke="#2B3674"
                    strokeWidth="1.4"
                    strokeLinecap="round"
                  />
                </svg>
              </div>
              <div>
                <input
                  placeholder="Search Extension No."
                  className="navbar-inputt"
                  value={searchQuery}
                  min={0}
                  onChange={handleSearchChange}
                  type="number"
                />
              </div>
            </div>
            <EsslList getEssl={getEssl} />
          </div>
        </div>
        <table className="custom-table">
          <thead>
            <tr className="custom-table-head-tr">
              <th className="align-left">Sr.no</th>
              <th className="align-center">User Detail</th>
              <th className="align-center">Work Email</th>
              <th className="align-center">Contact no.</th>
              <th className="align-center">Extension no.</th>
              <th className="align-right">Action</th>
            </tr>
          </thead>
          <tbody>
            {esslList?.filter((e) =>
              e.extensionno.toString().includes(searchQuery.toLowerCase())
            ).sort((a, b) =>
              a.extensionno.toString().localeCompare(b.title, "en", { ignorePunctuation: true })
            ).map((i, index) => (
              <React.Fragment key={index}>
                <tr className="tr-border-bottom">
                  <td colSpan="6"></td>
                </tr>
                <tr className="custom-table-head-td">
                  <td className="align-left">{index + 1}</td>
                  <td className="align-center">{i.user_detail}</td>
                  <td className="align-center">{i.work_email}</td>
                  <td className="align-center">{i.contactno}</td>
                  <td className="align-center">{i.extensionno}</td>
                  <td className="align-right ">
                    <EsslUpdate i={i} getEssl={getEssl} />
                    <EsslDelete i={i} getEssl={getEssl} />
                  </td>
                </tr>
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </>
  )
}

export {
  DepartmentTable,
  DesignationTable,
  Subcompany,
  Worksite,
  AttendancePolicyTable,
  WeeklyOffPolicyTable,
  CalenderTable,
  TlCalenderTable,
  InterComTable,
  TLintercomTable,
  EmployeebyDepartmentTable,
  EssLMachineTable
};
