import React, { useState } from 'react'
import { DailyAttendance, EmployeeAttendance, SummarySheet, SummarySheetExcel, Attendancebydept, Attendancebyrepo } from './TlAttendancetables';

const HodAttendance = () => {

    const [toggleState, setToggleState] = useState(1);


    return (
        <div>
            <div>
                <div >
                    <div className="bloc-tabs-with-white-bg">
                        <button
                            className={toggleState === 1 ? "dashboardcomponent-tabs  dashboardcomponent-active-tabs" : "dashboardcomponent-tabs white-tab"}
                            onClick={() => setToggleState(1)}
                        >
                            Daily Attendance
                        </button>
                        <button
                            className={toggleState === 2 ? "dashboardcomponent-tabs dashboardcomponent-active-tabs" : "dashboardcomponent-tabs white-tab"}
                            onClick={() => setToggleState(2)}
                        >
                            Team Attendance
                        </button>
                        <button
                            className={toggleState === 3 ? "dashboardcomponent-tabs dashboardcomponent-active-tabs" : "dashboardcomponent-tabs white-tab"}
                            onClick={() => setToggleState(3)}
                        >
                            Summary Sheet
                        </button>
                        <button
                            className={toggleState === 4 ? "dashboardcomponent-tabs dashboardcomponent-active-tabs" : "dashboardcomponent-tabs white-tab"}
                            onClick={() => setToggleState(4)}
                        >
                            Summary Sheet Excel
                        </button>
                        {/* <button
                            className={toggleState === 4 ? "dashboardcomponent-tabs dashboardcomponent-active-tabs" : "dashboardcomponent-tabs white-tab"}
                            onClick={() => setToggleState(4)}
                        >
                            Attendance by Dept
                        </button> */}
                        {/* <button
                            className={toggleState === 5 ? "dashboardcomponent-tabs dashboardcomponent-active-tabs" : "dashboardcomponent-tabs white-tab"}
                            onClick={() => setToggleState(5)}
                        >
                            Attendance By TL
                        </button> */}
                    </div>
                </div>

                <div className="content-tabs-without-margin">
                    {toggleState === 1 ? (
                        <DailyAttendance />
                    ) : null}

                    {toggleState === 2 ? <EmployeeAttendance /> : null}

                    {toggleState === 3 ? <SummarySheet /> : null}
                    {toggleState === 4 ? <SummarySheetExcel /> : null}

                    {/* {toggleState === 4 ? <Attendancebydept /> : null} */}

                    {/* {toggleState === 5 ? <Attendancebyrepo /> : null} */}


                </div>


            </div>

        </div>
    )
}

export default HodAttendance
