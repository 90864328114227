import React, { useState } from "react";
import axios from "axios";
import { BASE_URL } from "../../../config/axios";

const Outduty = ({ handleClose }) => {
  const [loading, setLoading] = useState(false); //loading logic

  const [age, setAge] = useState("");

  const [maxEndDate, setMaxEndDate] = useState(null);

  const [dataa, setdataa] = useState({
    empcode: sessionStorage.getItem("emp_code"),
    date: "",
    end_date: "",
    remarks: "",
  });

  const getEndTime = (intime) => {
    const start = new Date(`2023-03-25T${intime}`);
    const end = new Date(start.getTime() + 2 * 60 * 60 * 1000); // Add 2 hours in milliseconds
    const hh = String(end.getHours()).padStart(2, "0");
    const mm = String(end.getMinutes()).padStart(2, "0");
    const ss = String(end.getSeconds()).padStart(2, "0");

    setdataa({ ...dataa, in_time: intime, out_time: `${hh}:${mm}:${ss}` });
  };

  const date = new Date();

  const [remarksError, setRemarksError] = useState("");

  let lastDateOfPreviousMonth;
  if (date.getDate() > 20) {
    lastDateOfPreviousMonth = new Date(date.getFullYear(), date.getMonth(), 0);
  } else {
    lastDateOfPreviousMonth = new Date(
      date.getFullYear(),
      date.getMonth() - 1,
      +1
    );
  }
  const formattedLastDate = lastDateOfPreviousMonth.toISOString().substr(0, 10);

  let handleSubmit = async (e) => {
    const enteredDate = dataa.date;
    const minDate = formattedLastDate;

    if (enteredDate >= minDate) {
      e.preventDefault();
      setLoading(true); //loading logic

      const updatedFormData = {
        ...dataa,
        outduty_Duration: age,
      };
      try {
        let res = await axios.post(
          `${BASE_URL}/wfm/outduty/`,
          updatedFormData
        );

        if (res.status === 200) {
          handleClose()
        } else {
          alert("Please select a valid date");
        }
      } catch (err) {
        alert("Please select a valid date");
      } finally {
        setLoading(false); //loading logic
      }
      return;
    } else {
      alert("Your Request Already Submit ");
      return;
    }
  };

  return (
    <div>
      <form>
        <div className="flex-column model-age">

          <div className="leave-duration-heading">Outduty Duration </div>
          {/* <br/> */}

          <div onChange={(e) => setAge(e.target.value)} className="leave-radio-button">
            <div className="leave-radio-button-inner-div">
              <input
                name="age"
                type="radio"
                value="single"
                className="age-input"
              />
              <label className="age-label">Single Day</label></div>
            <div className="leave-radio-button-inner-div">
              <input
                name="age"
                type="radio"
                value="multiple"
                className="age-input"
              />
              <label className="age-label">Multi Day</label>
            </div>
            <div className="leave-radio-button-inner-div">

              <input
                name="age"
                type="radio"
                value="halfdayleave"
                className="age-input"
              />
              <label className="age-label">Half Day</label></div>
            <div className="leave-radio-button-inner-div">
              <input
                name="age"
                type="radio"
                value="shortleave"
                className="age-input"
              />
              <label className="age-label">Short Day</label></div>
          </div>
        </div>

        {/* <div>Leave Balance:  </div>
  <div>Short Leave Balance:  </div> */}


        {age === "single" && (
          <div className="flex-column">
            <label htmlFor="date" className="form-labels">
              Date
            </label>
            <input
              id="date"
              type="date"
              className="form-input form-input-background"
              onChange={(e) =>
                setdataa({
                  ...dataa,
                  date: e.target.value,
                  end_date: e.target.value,

                })
              }
              min={formattedLastDate}
            />
          </div>
        )}

        {age === "multiple" && (
          <div className="flex-row multi-day-input">
            <div className="flex-column multi-day">
              <label className="form-labels">From Date</label>
              <input
                type="date"
                className="multi-day-input1 form-input-background"
                onChange={(e) => {
                  const startDate = e.target.value;
                  const lastDate = new Date(
                    new Date(startDate).getFullYear(),
                    new Date(startDate).getMonth() + 1,
                    1
                  )
                    .toISOString()
                    .split("T")[0];
                  setdataa({
                    ...dataa,
                    date: startDate,
                  });
                  setMaxEndDate(lastDate);
                }}
                min={formattedLastDate}
              />
            </div>
            <div className="flex-column multi-day">
              <label className="form-labels">To Date</label>
              <input
                type="date"
                className="multi-day-input1 form-input-background"
                onChange={(e) =>
                  setdataa({ ...dataa, end_date: e.target.value })
                }
                min={dataa.date}
                max={maxEndDate}
              />
            </div>
          </div>
        )}

        {age === "halfdayleave" && (
          <div>
            <div className="flex-column single-day-date">
              <label className="form-labels">Date</label>
              <input
                type="date"
                className="form-input form-input-background"
                onChange={(e) =>
                  setdataa({
                    ...dataa,
                    date: e.target.value,
                    end_date: e.target.value,
                  })
                }
                min={formattedLastDate}
              />
            </div>
            <div

              required
              className="leave-radio-button"
            >
              <div className="leave-radio-button-inner-div">
                <input
                  name="halfday"
                  type="radio"
                  value="firsthalft"
                  className="age-input"
                  in_time="09:30:00"
                  out_time="13:30:00"
                />
                <label className="age-label">First Half</label>
              </div>
              <div className="leave-radio-button-inner-div">
                <input
                  name="halfday"
                  type="radio"
                  value="secondhalft"
                  className="age-input"
                  in_time="14:30:00"
                  out_time="18:30:00"
                />
                <label className="age-label">Second Half</label>
              </div>
            </div>
          </div>
        )}

        {age === "shortleave" && (
          <div>
            <div className="flex-column single-day-date">
              <label className="form-labels">Date</label>
              <input
                type="date"
                className="form-input form-input-background"
                onChange={(e) =>
                  setdataa({
                    ...dataa,
                    date: e.target.value,
                    end_date: e.target.value,
                  })
                }
              />
            </div>
            <div className="flex-row multi-day-input">
              <div className="flex-column multi-day">
                <label className="form-labels">Start Time</label>
                <input
                  className="multi-day-input1 form-input-background"
                  type="time"
                  step="1"
                  min="09:30:00"
                  max="16:30:00"
                  onChange={(e) => getEndTime(e.target.value)}
                />
              </div>
              <div className="flex-column multi-day">
                <label className="form-labels">End Time</label>
                <input
                  className="multi-day-input1 form-input-background"
                  type="time"
                  step="1"
                  min="09:30:00"
                  max="16:30:00"
                  value={dataa.out_time}
                  readOnly
                />
              </div>
            </div>
          </div>
        )}

        <br />

        <div className="flex-column">
          <label className="form-labels">Location & Work performed</label>
          <textarea
            className="form-input-textarea form-input-background"
            placeholder="Gurugram & Site Visit"
            type="text"
            value={dataa.text}
            onChange={(e) => {
              if (e.target.value.length <= 50) {
                setdataa({ ...dataa, remarks: e.target.value });
                setRemarksError(""); // Clear error message if within the limit
              } else {
                setRemarksError(
                  "Ensure this field has no more than 50 characters."
                );
              }
            }}
          />

          {remarksError && (
            <div style={{ color: "red", marginTop: "5px" }}>{remarksError}</div>
          )}
        </div>

        <div className="button-models">
          <button className="model-button " onClick={handleClose}>Cancel</button>
          <button className="model-button model-button-leave" onClick={handleSubmit} disabled={loading} >{/* //loading logic */}
            Outduty
          </button>
        </div>
      </form>
    </div>
  );
};

export default Outduty;
