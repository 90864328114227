import { useState } from "react";
import axios from "axios";
import { BASE_URL } from "../../config/axios";
import "../../assets/css/website/login.css"
import Logo from "../../assets/images/website/forgotpass.png"
import { Link } from 'react-router-dom'


const Login = () => {



  const [errMsg, setErrMsg] = useState("");



  const currentUrl = window.location.href;


  const urlParts = currentUrl.split('/');


  const baseUrl = urlParts[0] + `//` + urlParts[2];



  const [formData, setFormData] = useState({
    email: "",
    url: baseUrl
  });



  const [errors, setErrors] = useState({});

  const [inputState, setInputState] = useState({});



  const validateForm = () => {
    const newErrors = {};


    if (!formData.email.trim()) {
      newErrors.email = 'Email is required';
    } else if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(formData.email)) {
      newErrors.email = 'Invalid email format';
    }


    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const [loading, setLoading] = useState(false); //loading logic



  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      setLoading(true); //loading logic

      try {
        let res = await axios.post(`${BASE_URL}/user/forgotpassword/`, formData);


        if (res.status === 200) {
          // Open Gmail in a new tab/window
          window.open("https://mail.google.com/", "_blank");
        }
        else {
          setErrMsg(res.error)
        }
      } catch (err) {
        setErrMsg(err.response.data.error)
      } finally {
        setLoading(false); // Ensure loading state is stopped even in case of error //loading logic
      }
    } else {
      console.log('Form contains errors:', errors);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputState({
      ...inputState,
      [name]: value.trim() ? 'green' : '',
    });


    setFormData({
      ...formData,
      [name]: value,
    });
  };


  return (
    <>
      <div className='login-page'>
        <div>
          <Link to="/">
            <img src={Logo} alt='logo' className='login-page-img' /></Link>
        </div>
        <div className='login-page-right'>

          <div className='login-page-second-heading'>Forgot Password</div>

          <form onSubmit={handleSubmit} className="register-form">
            <div className="flex-column">
              <label htmlFor="email" className='form-labels'>Email</label>
              <input
                type="text"
                id="email"
                name="email"
                placeholder="example@gmail.com"
                autoComplete="off"
                onChange={handleInputChange}
                value={formData.email}
                onInput={(e) => {
                  e.target.value = e.target.value.toLowerCase(); // Convert input to lowercase
                }}
                className={`form-input ${errors.email ? 'error' : inputState.email ? 'success' : ''}`}
              />
              {errors.email && (
                <span className="error-message">{errors.email}</span>
              )}</div>


            <div>
              <button type="submit" className="login-button" disabled={loading} >{/* //loading logic */}
                Reset Password
              </button></div>
            <p
              style={{ color: "red" }}
              className={errMsg ? "errmsg" : "offscreen"}
              aria-live="assertive"
            >
              {errMsg}
            </p>

          </form>

        </div>
      </div>
    </>
  )
}

export default Login