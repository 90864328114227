import React, { useState, useEffect } from "react";
import "../../../assets/css/jdview.css";
import { useParams } from "react-router-dom";
import { BASE_URL } from "../../../config/axios";
import axios from "axios";
import Button from "react-bootstrap/Button";
import { formatDate } from "../../Date";
import { AppViewNavBar, EducationInfoTab, PersonalInfoTab, WorkInfoTab } from "./ApplicantViewComponents";

const ApplicantviewRound1 = () => {
  let { id, email } = useParams();

  // const [formData, setFormData] = useState({});

  const [allJobappdata, setAlljobappdata] = useState({});



  useEffect(() => {
    const getUSerDetails = async () => {
      try {

        const remark = await axios.get(`${BASE_URL}/jd/jobapplicant/${id}/${email}/`);

        setAlljobappdata(remark.data);
      } catch (err) {
        // alert(err.message);
      }
    };


    getUSerDetails();
  }, [email]);
  // const [allJobappdatadata, setAlljobappdatadata] = useState({});
  const [roound1_confidence, setRoound1_confidence] = useState("Low");
  const [round1_cs, setRound1_cs] = useState("Low");
  const [round1_sk, setround1_sk] = useState("Low");
  const [selectedFile, setSelectedFile] = useState(null);

  const [formData, setFormData] = useState({
    round1by: "",
    round1_cs: "",
    roound1_confidence: "",
    round1_tk: "",
    round1_sk: "",
    round1_recomendation: "",
    round1_prevexp: "",
    round1_teamwork: "",
    r1remark: "",
  });
  // const [round1, setRound1] = useState({

  // });

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  // !  ************** Validation start **************  ! //

  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = [
      "round1by",
      "round1_tk",
      // "round1_prevexp",
      // "round1_teamwork",
      "round1_recomendation",
      "r1remark",
    ];
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  let handleSubmitt = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      if (formData.round1by === null || allJobappdata.round1 === true) {
        alert("Please fill in all required fields.");
        return;
      }

      const updatedFormData = {
        ...formData,
        round1: "true",
        round1_cs: round1_cs,
        round1_confidence: roound1_confidence,
        round1_sk: round1_sk,
      };
      try {
        let res = await axios.put(
          `${BASE_URL}/jd/jobapplicant/${allJobappdata.id}/`,
          updatedFormData
        );
        if (res.status === 200) {
          window.location.reload();
        } else {
          alert(res);
        }
      } catch (err) {
        alert(err);
      }
    }
  };
  console.log(formData);

  return (
    <div className="jdview-outer">
      {/* <div className="jdview-section1">
        <div>
          <img
            className="jdview-section-profilepic"
            src="https://g-eng.s3.ap-south-1.amazonaws.com/template-img/%60logo.png"
            alt="profile-pic"
          />
        </div>
        <div className="jdview-section1-b">
          <div className="jdview-section1-b-a">
            <div className="jdview-section1-b-heading1">Experience</div>
            <div className="jdview-section1-b-heading2">
              {allJobappdata.yearofExp} years
            </div>
          </div>
          <div className="jdview-section1-b-a">
            <div className="jdview-section1-b-heading1">Applied department</div>
            <div className="jdview-section1-b-heading2">
              {allJobappdata.department_name}
            </div>
          </div>
          <div className="jdview-section1-b-a">
            <div className="jdview-section1-b-heading1">
              Applied designation
            </div>
            <div className="jdview-section1-b-heading2">
              {allJobappdata.position_name}
            </div>
          </div>
        </div>
        <div>
          <a href="https://g-eng.s3.ap-south-1.amazonaws.com/template-img/%60logo.png">
            {" "}
            <button className="jdview-seaction1-b-button">View CV</button>
          </a>
        </div>
      </div> */}
      <AppViewNavBar />
      <div className="jdview-section2">

        <PersonalInfoTab />
        <EducationInfoTab />
        <WorkInfoTab />

        <div className="jdfinal-section2">
          <div className="jdfinal-section2-a">
            <div className="jdfinal-section2-a-a">
              <div className="jdfinal-section2-a-b">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  className="jdfinal-section2-a-c"
                >
                  <path
                    d="M12 2.625C6.83063 2.625 2.625 6.83063 2.625 12C2.625 17.1694 6.83063 21.375 12 21.375C17.1694 21.375 21.375 17.1694 21.375 12C21.375 6.83063 17.1694 2.625 12 2.625ZM12 6.46875C12.241 6.46875 12.4767 6.54023 12.6771 6.67415C12.8775 6.80806 13.0337 6.99841 13.126 7.2211C13.2182 7.4438 13.2424 7.68885 13.1953 7.92527C13.1483 8.16168 13.0322 8.37884 12.8618 8.54929C12.6913 8.71973 12.4742 8.83581 12.2378 8.88283C12.0014 8.92986 11.7563 8.90572 11.5336 8.81348C11.3109 8.72123 11.1206 8.56502 10.9866 8.3646C10.8527 8.16418 10.7812 7.92855 10.7812 7.6875C10.7812 7.36427 10.9097 7.05427 11.1382 6.82571C11.3668 6.59715 11.6768 6.46875 12 6.46875ZM14.25 17.0625H10.125C9.92609 17.0625 9.73532 16.9835 9.59467 16.8428C9.45402 16.7022 9.375 16.5114 9.375 16.3125C9.375 16.1136 9.45402 15.9228 9.59467 15.7822C9.73532 15.6415 9.92609 15.5625 10.125 15.5625H11.4375V11.4375H10.6875C10.4886 11.4375 10.2978 11.3585 10.1572 11.2178C10.0165 11.0772 9.9375 10.8864 9.9375 10.6875C9.9375 10.4886 10.0165 10.2978 10.1572 10.1572C10.2978 10.0165 10.4886 9.9375 10.6875 9.9375H12.1875C12.3864 9.9375 12.5772 10.0165 12.7178 10.1572C12.8585 10.2978 12.9375 10.4886 12.9375 10.6875V15.5625H14.25C14.4489 15.5625 14.6397 15.6415 14.7803 15.7822C14.921 15.9228 15 16.1136 15 16.3125C15 16.5114 14.921 16.7022 14.7803 16.8428C14.6397 16.9835 14.4489 17.0625 14.25 17.0625Z"
                    fill="#EE5D50"
                  />
                </svg>
              </div>
              <span className="jdfinal-section2-a-d">Interviewer Remarks</span>
            </div>
          </div>
          <div className="jdfinal-section2-b"></div>

          {/* {allJobappdatadata.round1 ? (
            <>
              <div className="jdfinal-sub-heading">Round 1</div>
              <div className="jdfinal-section2-c-aa">
                <div className="jdfinal-section2-c-a-a">
                  <span className="jdheading">Assigned To:</span>{" "}
                  <span className="jdsubheading">
                    {allJobappdatadata.round1_assignedto}
                  </span>
                </div>
                <div
                  className="jdfinal-section2-c-a-a"
                  style={{ marginLeft: "50px" }}
                >
                  <span className="jdheading">Interview By:</span>{" "}
                  <span className="jdsubheading">
                    {allJobappdatadata.round1by}
                  </span>
                </div>
              </div>

              <div className="jdfinal-section2-bb"></div>

              <div className="jdfinal-section2-c-aa">
                <div className="jdfinal-section2-c-a-a">
                  <span className="jdheading">Commnication Skill:</span>{" "}
                  <span className="jdsubheading">
                    {allJobappdatadata.round1_cs}
                  </span>
                </div>
                <div
                  className="jdfinal-section2-c-a-a"
                  style={{ marginLeft: "50px" }}
                >
                  <span className="jdheading">Confidence Level:</span>{" "}
                  <span className="jdsubheading">
                    {allJobappdatadata.roound1_confidence}
                  </span>
                </div>
              </div>

              <div className="jdfinal-section2-bb"></div>

              <div className="jdfinal-section2-c-a-a">
                <span className="jdheading">Technical Knowledge:</span>{" "}
                <span className="jdsubheading">
                  {allJobappdatadata.round1_tk
                    ? allJobappdatadata.round1_tk
                    : allJobappdatadata.r1remark}
                </span>
              </div>

              <div className="jdfinal-section2-b"></div>

              <div className="jdfinal-section2-c-aa">
                <div className="jdfinal-section2-c-a-a">
                  <span className="jdheading">Software Knowledge:</span>{" "}
                  <span className="jdsubheading">
                    {allJobappdatadata.round1_sk}
                  </span>
                </div>
                <div
                  className="jdfinal-section2-c-a-a"
                  style={{ marginLeft: "50px" }}
                >
                  <span className="jdheading">Recommendation:</span>{" "}
                  <span className="jdsubheading">
                    {allJobappdatadata.round1_recomendation}
                  </span>
                </div>
              </div>

              <div className="jdfinal-section2-bb"></div>
            </>
          ) : (
            ""
          )} */}

          {allJobappdata.round1 ? (
            <>
              <div className="jdview-section3-d">
                <div className="section-d3">
                  <div className="personal-info">
                    <div classname="info-heading">Round 1</div>
                    <table>
                      <tr>
                        <th className="personal-0">Assign To</th>
                        <td className="personal-info-1">{allJobappdata.round1by}</td>
                      </tr>
                      <tr>
                        <th className="personal-0">Communication Skill</th>
                        <td className="personal-info-1">{allJobappdata.round1_cs}</td>
                      </tr>
                      <tr>
                        <th className="personal-0">Confidence Level</th>
                        <td className="personal-info-1">{allJobappdata.round1_confidence}</td>
                      </tr>
                      <tr>
                        <th className="personal-0"> Team work</th>
                        <td className="personal-info-1">{allJobappdata.round1_teamwork}</td>
                      </tr>
                      <tr></tr>
                      <tr>
                        <th className="personal-0">Technical Knowledge</th>
                        <td className="personal-info-1">{allJobappdata.round1_tk}</td>
                      </tr>
                      <tr>
                        <th className="personal-0">Software Knowledge</th>
                        <td className="personal-info-1">{allJobappdata.round1_sk}</td>
                      </tr>
                      <tr>
                        <th className="personal-0">Previous Experience</th>
                        <td className="personal-info-1">{allJobappdata.round1_prevexp}</td>
                      </tr>
                      <tr>
                        <th className="personal-0">Round Remarks</th>
                        <td className="personal-info-1 remark-display">{allJobappdata.r1remark}</td>
                      </tr>
                      <tr>
                        <th className="personal-0">Recommendation</th>
                        <td className="personal-info-1">{allJobappdata.round1_recomendation}</td>
                      </tr>

                    </table>
                  </div>
                </div>
                {/* add more */}
              </div>
              {/* <>
                <div className="jdview-section2-e">
                  <form className="register-form">
                    <div className="form-flex-wrap">
                      <div className="flex-column">
                        <label htmlFor="round1by" className="form-labels">
                          Interviewer Name<span className="required">*</span>
                        </label>
                        <input
                          className={`form-input `}
                          type="text"

                          value={allJobappdata.round1by}
                          readOnly
                        />
                      </div>
                      <br />
                      <div className="flex-column">
                        <label htmlFor="round1_tk" className="form-labels">
                          Technical Knowledge<span className="required">*</span>
                        </label>
                        <input
                          className={`form-input`}

                          type="text"

                          value={allJobappdata.round1_tk}
                          readOnly
                        />
                      </div>

                      <div Comments="flex-column" style={{ width: "50vw" }}>
                        <label htmlFor="round1_cs" className="form-labels">
                          Communication Skill<span className="required">*</span>
                        </label>
                        <div
                          className="form-inputt"


                        >
                          <input
                            className="age-input"
                            type="radio"
                            checked={allJobappdata.round1_cs === "Low"}
                            name="round1_cs"
                            value="Low"
                          />
                          <label className="age-label">Low</label>
                          <input
                            className="age-input"
                            type="radio"
                            checked={allJobappdata.round1_cs === "Average"}
                            name="round1_cs"
                            value="Average"
                          />
                          <label className="age-label">Average</label>
                          <input
                            className="age-input"
                            type="radio"
                            checked={allJobappdata.round1_cs === "High"}
                            name="round1_cs"
                            value="High"
                          />
                          <label className="age-label">High</label>
                        </div>
                      </div>

                      <div className="flex-column" style={{ width: "34vw" }}>
                        <label htmlFor="roound1_confidence" className="form-labels">
                          Confidence Level<span className="required">*</span>
                        </label>
                        <div
                          className="form-inputt"


                        >
                          <input
                            className={`age-label${errors.roound1_confidence
                              ? "error"
                              : inputState.roound1_confidence
                                ? "success"
                                : ""
                              }`}
                            type="radio"
                            checked={allJobappdata.round1_confidence === "Low"}
                            name="roound1_confidence"
                            value="Low"
                            onChange={handleInputChange}
                          />
                          <label className="age-label">Low</label>
                          <input
                            name={`age-label${errors.roound1_confidence
                              ? "error"
                              : inputState.roound1_confidence
                                ? "success"
                                : ""
                              }`}
                            type="radio"
                            checked={allJobappdata.round1_confidence === "Average"}
                            value="Average"
                            className="age-label"
                          />
                          <label className="age-label">Average</label>
                          <input
                            name={`age-label${errors.roound1_confidence
                              ? "error"
                              : inputState.roound1_confidence
                                ? "success"
                                : ""
                              }`}
                            type="radio"
                            checked={allJobappdata.round1_confidence === "High"}
                            value="High"
                            className="age-label"
                          />
                          <label className="age-label">High</label>
                        </div>
                      </div>

                      <div
                        className="flex-column"
                        style={{ width: "34vw", marginTop: "20px" }}
                      >
                        <label htmlFor="round1_sk" className="form-labels">
                          Software knowledge<span className="required">*</span>
                        </label>
                        <div
                          className="form-inputt"

                        >
                          <input
                            className="age-input"
                            type="radio"
                            checked={allJobappdata.round1_sk === "Low"}
                            name="roound1_sk"
                            value="Low"
                          />
                          <label className="age-label">Low</label>
                          <input
                            className="age-input"
                            type="radio"
                            checked={allJobappdata.round1_sk === "Average"}
                            name="roound1_sk"
                            value="Average"
                          />
                          <label className="age-label">Average</label>
                          <input
                            className="age-input"
                            type="radio"
                            checked={allJobappdata.round1_sk === "High"}
                            name="roound1_sk"
                            value="High"
                          />
                          <label className="age-label">High</label>
                        </div>
                      </div>

                      <div className="flex-column" style={{ marginTop: "20px" }}>
                        <label htmlFor="round1_prevexp" className="jd-heading">
                          Previous experience
                        </label>
                        <input
                          className={`form-input`}
                          placeholder="Previous Experience"
                          type="text"
                          name="round1_prevexp"
                          value={allJobappdata.round1_prevexp}
                          readOnly
                        />
                      </div>

                      <div className="flex-column">
                        <label htmlFor="round1_teamwork" className="jd-heading">
                          Team work
                        </label>
                        <input
                          className={`form-input `}
                          placeholder="Team work"
                          type="text"
                          name="round1_teamwork"
                          value={allJobappdata.round1_teamwork}
                          readOnly
                        />
                      </div>

                      <div className="flex-column">
                        <label
                          htmlFor="round1_recomendation"
                          className="jd-heading"
                        >
                          Recommendation
                        </label>
                        <input
                          className={`form-input`}
                          id="round1_recomendation "
                          placeholder="Recommendatiioin"
                          type="text"
                          name="round1_recomendation"
                          value={allJobappdata.round1_recomendation}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="flex-column">
                      <label htmlFor="r1remark" className="jd-heading">
                        Remarks:
                      </label>
                      <textarea
                        type="text"
                        id="r1remark"
                        name="r1remark"
                        placeholder="Remark"
                        readOnly
                        value={allJobappdata.r1remark}
                        className={`form-input-textarea`}
                      />
                      {errors.r1remark && (
                        <span className="error-message">{errors.r1remark}</span>
                      )}
                    </div>
                  </form>
                </div>
              </> */}
            </>
          ) : (
            <>
              <div className="jdfinal-sub-heading">Round 1</div>
              <form className="register-form">
                <div className="form-flex-wrap">
                  <div className="flex-column">
                    <label htmlFor="round1by" className="form-labels">
                      Interviewer Name<span className="required">*</span>
                    </label>
                    <input
                      id="round1by"
                      placeholder="Name"
                      maxLength={150}
                      type="text"
                      name="round1by"
                      value={formData.round1by}
                      onChange={handleInputChange}
                      className={`form-input ${errors.round1by
                        ? "error"
                        : inputState.round1by
                          ? "success"
                          : ""
                        }`}
                    />{errors.round1by && (
                      <span className="error-message">{errors.round1by}</span>
                    )}
                  </div>
                  <br />
                  <div className="flex-column">
                    <label htmlFor="round1_tk" className="form-labels">
                      Technical Knowledge<span className="required">*</span>
                    </label>
                    <input
                      className={`form-input ${errors.round1_tk
                        ? "error"
                        : inputState.round1_tk
                          ? "success"
                          : ""
                        }`}
                      placeholder="Technical knowledge"
                      type="text"
                      maxLength={150}
                      name="round1_tk"
                      id="round1_tk"
                      value={formData.round1_tk}
                      onChange={handleInputChange}
                    />{errors.round1_tk && (
                      <span className="error-message">{errors.round1_tk}</span>
                    )}
                  </div>

                  <div Comments="flex-column" style={{ width: "50vw" }}>
                    <label htmlFor="round1_cs" className="form-labels">
                      Communication Skill<span className="required">*</span>
                    </label>
                    <div
                      className="form-inputt"
                      value={round1_cs}
                      onChange={(e) => setRound1_cs(e.target.value)}
                    >
                      <input
                        className="age-input"
                        maxLength={20}
                        type="radio"
                        checked={round1_cs === "Low"}
                        name="round1_cs"
                        value="Low"
                      />
                      <label className="age-label">Low</label>
                      <input
                        className="age-input"
                        maxLength={20}
                        type="radio"
                        checked={round1_cs === "Average"}
                        name="round1_cs"
                        value="Average"
                      />
                      <label className="age-label">Average</label>
                      <input
                        className="age-input"
                        maxLength={20}
                        type="radio"
                        checked={round1_cs === "High"}
                        name="round1_cs"
                        value="High"
                      />
                      <label className="age-label">High</label>
                    </div>
                  </div>

                  <div className="flex-column" style={{ width: "34vw" }}>
                    <label htmlFor="roound1_confidence" className="form-labels">
                      Confidence Level<span className="required">*</span>
                    </label>
                    <div
                      className="form-inputt"
                      value={roound1_confidence}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          roound1_confidence: e.target.value,
                        });
                        setRoound1_confidence(e.target.value);
                      }}
                    >
                      <input
                        className={`age-label${errors.roound1_confidence
                          ? "error"
                          : inputState.roound1_confidence
                            ? "success"
                            : ""
                          }`}
                        type="radio"
                        checked={roound1_confidence === "Low"}
                        maxLength={20}
                        name="roound1_confidence"
                        value="Low"
                        onChange={handleInputChange}
                      />
                      <label className="age-label">Low</label>
                      <input
                        name={`age-label${errors.roound1_confidence
                          ? "error"
                          : inputState.roound1_confidence
                            ? "success"
                            : ""
                          }`}
                        type="radio"
                        maxLength={20}
                        checked={roound1_confidence === "Average"}
                        value="Average"
                        className="age-label"
                      />
                      <label className="age-label">Average</label>
                      <input
                        name={`age-label${errors.roound1_confidence
                          ? "error"
                          : inputState.roound1_confidence
                            ? "success"
                            : ""
                          }`}
                        type="radio"
                        maxLength={20}
                        checked={roound1_confidence === "High"}
                        value="High"
                        className="age-label"
                      />
                      <label className="age-label">High</label>
                    </div>
                  </div>

                  <div
                    className="flex-column"
                    style={{ width: "34vw", marginTop: "20px" }}
                  >
                    <label htmlFor="round1_sk" className="form-labels">
                      Software knowledge<span className="required">*</span>
                    </label>
                    <div
                      className="form-inputt"
                      value={round1_sk}
                      onChange={(e) => setround1_sk(e.target.value)}
                    >
                      <input
                        className="age-input"
                        type="radio"
                        maxLength={150}
                        checked={round1_sk === "Low"}
                        name="roound1_sk"
                        value="Low"
                      />
                      <label className="age-label">Low</label>
                      <input
                        className="age-input"
                        type="radio"
                        maxLength={150}
                        checked={round1_sk === "Average"}
                        name="roound1_sk"
                        value="Average"
                      />
                      <label className="age-label">Average</label>
                      <input
                        className="age-input"
                        type="radio"
                        maxLength={150}
                        checked={round1_sk === "High"}
                        name="roound1_sk"
                        value="High"
                      />
                      <label className="age-label">High</label>
                    </div>
                  </div>

                  <div className="flex-column" style={{ marginTop: "20px" }}>
                    <label htmlFor="round1_prevexp" className="jd-heading">
                      Previous experience
                    </label>
                    <input
                      className={`form-input ${errors.round1_prevexp
                        ? "error"
                        : inputState.round1_prevexp
                          ? "success"
                          : ""
                        }`}
                      placeholder="Previous Experience"
                      id="round1_prevexp"
                      type="text"
                      maxLength={150}
                      name="round1_prevexp"
                      value={formData.round1_prevexp}
                      onChange={handleInputChange}
                    />
                  </div>

                  <div className="flex-column">
                    <label htmlFor="round1_teamwork" className="jd-heading">
                      Team work
                    </label>
                    <input
                      className={`form-input ${errors.round1_teamwork
                        ? "error"
                        : inputState.round1_teamwork
                          ? "success"
                          : ""
                        }`}
                      placeholder="Team work"
                      type="text"
                      maxLength={150}
                      id="round1_teamwork"
                      name="round1_teamwork"
                      value={formData.round1_teamwork}
                      onChange={handleInputChange}
                    />
                    {errors.round1_teamwork && (
                      <span className="error-message">{errors.round1_teamwork}</span>
                    )}
                  </div>

                  <div className="flex-column">
                    <label
                      htmlFor="round1_recomendation"
                      className="jd-heading"
                    >
                      Recommendation<span className="required">*</span>
                    </label>
                    <input
                      type="text"
                      id="round1_recomendation"
                      name="round1_recomendation"
                      maxLength={150}
                      placeholder="Recommendation"
                      value={formData.round1_recomendation}
                      onChange={handleInputChange}
                      className={`form-input ${errors.round1_recomendation
                        ? "error"
                        : inputState.round1_recomendation
                          ? "success"
                          : ""
                        }`}
                    /> {errors.round1_recomendation && (
                      <span className="error-message">{errors.round1_recomendation}</span>
                    )}
                  </div>
                </div>
                <div className="flex-column">
                  <label htmlFor="r1remark" className="jd-heading">
                    Remarks:<span className="required">*</span>
                  </label>

                  <textarea
                    type="text"
                    id="r1remark"
                    name="r1remark"
                    maxLength={500}
                    placeholder="Remark"
                    onChange={handleInputChange}
                    value={formData.r1remark}
                    className={`form-input-textarea ${errors.r1remark
                      ? "error"
                      : inputState.r1remark
                        ? "success"
                        : ""
                      }`}
                  />
                  {errors.r1remark && (
                    <span className="error-message">{errors.r1remark}</span>
                  )}
                </div>
              </form>
              <br />
              <button
                style={{ marginTop: "20px", width: "100px" }}
                onClick={handleSubmitt}
                className="model-button"
              >
                Submit
              </button>{" "}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ApplicantviewRound1;
