import React, {useState} from 'react'
import Dashboardnavbarcopy from '../../layout/Dashboardnavbarcopy';
import Assettable from './Assettable';
import { InterComTable } from '../workforcemanagement/Organizationtables';


const Settingsit = () => {

    const [toggleState, setToggleState] = useState(1);


    const getSelectedTitle = () => {
        switch (toggleState) {
          case 1:
            return 'Assest';
          case 2:
            return 'InterCom';         
          case 3: 
          default:
            return '';
        }
      };


    return (
        <>
            <div>
                <Dashboardnavbarcopy name={getSelectedTitle()} url="Settings" />
                <div className="bloc-tabss  wfm-margin">
                    <button
                        className={toggleState === 1 ? "dashboardcomponent-tabs dashboardcomponent-tabss dashboardcomponent-active-tabs" : "dashboardcomponent-tabs dashboardcomponent-tabss"}
                        onClick={() => setToggleState(1)}
                    >
                        Assest
                    </button>
                    <button
                        className={toggleState === 2 ? "dashboardcomponent-tabs dashboardcomponent-tabss dashboardcomponent-active-tabs" : "dashboardcomponent-tabs dashboardcomponent-tabss"}
                        onClick={() => setToggleState(2)}
                    >
                        InterCom
                    </button>
                   

                </div>
                <div className="content-tabs">

{toggleState === 1 ? (
                       <Assettable/>
                    ) : null}

                    {toggleState === 2 ? <InterComTable /> : null}

                   




                </div>
            </div>

        </>
    )
}

export default Settingsit
