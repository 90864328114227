import { useState, useEffect } from "react";
import Docs from "../images/docs.svg";
import AnnouncentAdd from "../settings/model/Announcent"
import { BASE_URL } from "../../config/axios";
import axios from "axios";
import { useParams, useNavigate } from 'react-router-dom';
import { DocumentAdd, EmployeeDocumentAdd } from "../settings/model/Impreset";
// import "../../assets/css/components.css";

const employeeDocuments = [];

const Document = () => {
  let { emp_code, email } = useParams()
  const navigate = useNavigate();

  const [identityDocuments, setidentityDocuments] = useState([])


  const getDocuments = async () => {
    try {
      const res = await axios.get(`${BASE_URL}/userdata/userdocsfilter/${email}/`);
      setidentityDocuments(res.data)
    } catch (err) {
      // alert(err.message);
    }
  };


  useEffect(() => {
    getDocuments();
  }, []);

  const [toggleState, setToggleState] = useState(1);

  const downloadDocument = (url) => {
    // Implement download logic using provided URL
    // window.open(url, "_blank");

  };


  const filteredIdentityDocuments = identityDocuments.filter(
    (doc) => doc.document_type === "identity"
  );

  const filteredEmployeeDocuments = identityDocuments.filter(
    (doc) => doc.document_type === "employeeLetter"
  );

  const filteredPreExpDocuments = identityDocuments.filter(
    (doc) => doc.document_type === "previousExperience"
  );

  const filteredDegreeDocuments = identityDocuments.filter(
    (doc) => doc.document_type === "degree"
  );


  const currentUrl = window.location.href;
  const urlParts = currentUrl.split('/');


  const url1 = `letterhead`
  // const url2 = `letterheadPR`
  // const url3 = `letterheadPRP`

  console.log(url1)

  const handleLetterClick = () => {
    navigate(`letterhead/`)
    window.location.reload()
  };

  const DocDeleteButton = ({ i, getDocuments }) => {


    const handleDocSubmitDelete = async (e) => {
      // e.preventDefault();
      console.log(i)

      try {
        let res = await axios.delete(`${BASE_URL}/userdata/userdocs/${i.id}/`);

        if (res.status === 200) {
          // getDocuments();

          await getDocuments();
          // setShow(false);
        } else {
          alert(res);
        }
      } catch (err) {
        alert(err);
      }
    };

    return (
      <>
        <button style={{ marginLeft: '10px' }} onClick={(e) => handleDocSubmitDelete()} className="model-delete-button">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
          >
            <path
              d="M15.1875 3.375H12.375V2.8125C12.375 2.36495 12.1972 1.93572 11.8807 1.61926C11.5643 1.30279 11.1351 1.125 10.6875 1.125H7.3125C6.86495 1.125 6.43572 1.30279 6.11926 1.61926C5.80279 1.93572 5.625 2.36495 5.625 2.8125V3.375H2.8125C2.66332 3.375 2.52024 3.43426 2.41475 3.53975C2.30926 3.64524 2.25 3.78832 2.25 3.9375C2.25 4.08668 2.30926 4.22976 2.41475 4.33525C2.52024 4.44074 2.66332 4.5 2.8125 4.5H3.375V14.625C3.375 14.9234 3.49353 15.2095 3.7045 15.4205C3.91548 15.6315 4.20163 15.75 4.5 15.75H13.5C13.7984 15.75 14.0845 15.6315 14.2955 15.4205C14.5065 15.2095 14.625 14.9234 14.625 14.625V4.5H15.1875C15.3367 4.5 15.4798 4.44074 15.5852 4.33525C15.6907 4.22976 15.75 4.08668 15.75 3.9375C15.75 3.78832 15.6907 3.64524 15.5852 3.53975C15.4798 3.43426 15.3367 3.375 15.1875 3.375ZM6.75 2.8125C6.75 2.66332 6.80926 2.52024 6.91475 2.41475C7.02024 2.30926 7.16332 2.25 7.3125 2.25H10.6875C10.8367 2.25 10.9798 2.30926 11.0852 2.41475C11.1907 2.52024 11.25 2.66332 11.25 2.8125V3.375H6.75V2.8125ZM13.5 14.625H4.5V4.5H13.5V14.625ZM7.875 7.3125V11.8125C7.875 11.9617 7.81574 12.1048 7.71025 12.2102C7.60476 12.3157 7.46168 12.375 7.3125 12.375C7.16332 12.375 7.02024 12.3157 6.91475 12.2102C6.80926 12.1048 6.75 11.9617 6.75 11.8125V7.3125C6.75 7.16332 6.80926 7.02024 6.91475 6.91475C7.02024 6.80926 7.16332 6.75 7.3125 6.75C7.46168 6.75 7.60476 6.80926 7.71025 6.91475C7.81574 7.02024 7.875 7.16332 7.875 7.3125ZM11.25 7.3125V11.8125C11.25 11.9617 11.1907 12.1048 11.0852 12.2102C10.9798 12.3157 10.8367 12.375 10.6875 12.375C10.5383 12.375 10.3952 12.3157 10.2898 12.2102C10.1843 12.1048 10.125 11.9617 10.125 11.8125V7.3125C10.125 7.16332 10.1843 7.02024 10.2898 6.91475C10.3952 6.80926 10.5383 6.75 10.6875 6.75C10.8367 6.75 10.9798 6.80926 11.0852 6.91475C11.1907 7.02024 11.25 7.16332 11.25 7.3125Z"
              fill="#F72B50"
            />
          </svg>
        </button>
      </>
    )
  }




  return (
    <div>
      <div className="expense-bloc-tabs">
        <button
          className={
            toggleState === 1
              ? "dashboardcomponent-tabs dashboardcomponent-active-tabs"
              : "dashboardcomponent-tabs expense-inactive-tab"
          }
          onClick={() => setToggleState(1)}
        >
          Identity ({filteredIdentityDocuments.length})

        </button>
        <button
          className={
            toggleState === 2
              ? "dashboardcomponent-tabs dashboardcomponent-active-tabs"
              : "dashboardcomponent-tabs expense-inactive-tab"
          }
          onClick={() => setToggleState(2)}
        >
          Employee Letters ({filteredEmployeeDocuments.length})
        </button>
        <button
          className={
            toggleState === 3
              ? "dashboardcomponent-tabs dashboardcomponent-active-tabs"
              : "dashboardcomponent-tabs expense-inactive-tab"
          }
          onClick={() => setToggleState(3)}
        >
          Previous Experience ({filteredPreExpDocuments.length})
        </button>
        <button
          className={
            toggleState === 4
              ? "dashboardcomponent-tabs dashboardcomponent-active-tabs"
              : "dashboardcomponent-tabs expense-inactive-tab"
          }
          onClick={() => setToggleState(4)}
        >
          Degree & Certificates ({filteredDegreeDocuments.length})
        </button>

        <div className="right-expense-bloc-tab"></div>

        <div><EmployeeDocumentAdd email={email} /></div>

      </div>

      <div >
        {toggleState === 1 ? (
          <div className="document-content-tab">
            {filteredIdentityDocuments.length === 0 ? (
              <div className="no-documents-message">
                No documents available.
              </div>
            ) : (
              <div className="document-grid2">
                {filteredIdentityDocuments.map((doc, index) => (
                  <div className="document-item1" key={index}>
                    <div className="document-icons">
                      <div className="document-name-div">
                        <img
                          src={Docs}
                          alt="Document Icon"
                          className="document-icon"
                        />
                        <div >
                          <div className="document-name">{doc.document_name}</div>
                          <div className="document-upload-date">
                            Doc Type <b>{doc.document_type}</b>
                            {/* Uploaded on {doc.upload} */}
                          </div>
                        </div>
                      </div>
                      <div style={{ display: 'flex', }}>
                        <a style={{ marginLeft: '10px' }} href={doc.document_file} target="_blank" className="document-download-button">
                          View
                        </a>
                        {/* <button style={{ marginLeft: '10px' }} onClick={(e) => handleDocSubmitDelete(doc)} className="model-delete-button">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                            fill="none"
                          >
                            <path
                              d="M15.1875 3.375H12.375V2.8125C12.375 2.36495 12.1972 1.93572 11.8807 1.61926C11.5643 1.30279 11.1351 1.125 10.6875 1.125H7.3125C6.86495 1.125 6.43572 1.30279 6.11926 1.61926C5.80279 1.93572 5.625 2.36495 5.625 2.8125V3.375H2.8125C2.66332 3.375 2.52024 3.43426 2.41475 3.53975C2.30926 3.64524 2.25 3.78832 2.25 3.9375C2.25 4.08668 2.30926 4.22976 2.41475 4.33525C2.52024 4.44074 2.66332 4.5 2.8125 4.5H3.375V14.625C3.375 14.9234 3.49353 15.2095 3.7045 15.4205C3.91548 15.6315 4.20163 15.75 4.5 15.75H13.5C13.7984 15.75 14.0845 15.6315 14.2955 15.4205C14.5065 15.2095 14.625 14.9234 14.625 14.625V4.5H15.1875C15.3367 4.5 15.4798 4.44074 15.5852 4.33525C15.6907 4.22976 15.75 4.08668 15.75 3.9375C15.75 3.78832 15.6907 3.64524 15.5852 3.53975C15.4798 3.43426 15.3367 3.375 15.1875 3.375ZM6.75 2.8125C6.75 2.66332 6.80926 2.52024 6.91475 2.41475C7.02024 2.30926 7.16332 2.25 7.3125 2.25H10.6875C10.8367 2.25 10.9798 2.30926 11.0852 2.41475C11.1907 2.52024 11.25 2.66332 11.25 2.8125V3.375H6.75V2.8125ZM13.5 14.625H4.5V4.5H13.5V14.625ZM7.875 7.3125V11.8125C7.875 11.9617 7.81574 12.1048 7.71025 12.2102C7.60476 12.3157 7.46168 12.375 7.3125 12.375C7.16332 12.375 7.02024 12.3157 6.91475 12.2102C6.80926 12.1048 6.75 11.9617 6.75 11.8125V7.3125C6.75 7.16332 6.80926 7.02024 6.91475 6.91475C7.02024 6.80926 7.16332 6.75 7.3125 6.75C7.46168 6.75 7.60476 6.80926 7.71025 6.91475C7.81574 7.02024 7.875 7.16332 7.875 7.3125ZM11.25 7.3125V11.8125C11.25 11.9617 11.1907 12.1048 11.0852 12.2102C10.9798 12.3157 10.8367 12.375 10.6875 12.375C10.5383 12.375 10.3952 12.3157 10.2898 12.2102C10.1843 12.1048 10.125 11.9617 10.125 11.8125V7.3125C10.125 7.16332 10.1843 7.02024 10.2898 6.91475C10.3952 6.80926 10.5383 6.75 10.6875 6.75C10.8367 6.75 10.9798 6.80926 11.0852 6.91475C11.1907 7.02024 11.25 7.16332 11.25 7.3125Z"
                              fill="#F72B50"
                            />
                          </svg>
                        </button> */}
                        <DocDeleteButton i={doc} getDocuments={getDocuments} />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        ) : null}

        {/* {
          toggleState === 2 && sessionStorage.getItem("letter_type") != "None" ? (
            <div className="document-content-tab"> Increment Letter
              <button
                className="document-download-button"
                onClick={() => navigate(url1)}
              >
                View
              </button></div>
          ) : ""
        } */}
        {toggleState === 2 ? (
          <div className="document-content-tab">
            {filteredEmployeeDocuments.length === 0 && sessionStorage.getItem("letter_type") === "None" ? (
              <div className="no-documents-message">
                No documents available.
              </div>
            ) : (
              <div className="document-grid2">
                {/* {(sessionStorage.getItem("letter_type") != "None") ? (
                  <>
                    <div className="document-content-tab"> Increment Letter
                      <button
                        className="document-download-button"
                        onClick={() => navigate(url1)}
                      >
                        View
                      </button>
                    </div>
                    <div className="document-item">
                      <div className="document-icons">
                        <div className="document-name-div">
                          <img
                            src={Docs}
                            alt="Document Icon"
                            className="document-icon"
                          />
                          <div>
                            <div className="document-name">Increment Letter</div>
                            <div className="document-upload-date">
                              Doc Type <b>Employee Letter</b>
                              Uploaded on {doc.upload}
                            </div>
                          </div>
                        </div>
                        <button
                          className="document-download-button"
                          onClick={() => navigate(url1)}
                        >
                          View
                        </button>
                      </div>
                    </div>
                  </>
                ) : ""} */}
                {filteredEmployeeDocuments.map((doc, index) => (
                  <div className="document-item1" key={index}>
                    <div className="document-icons">
                      <div className="document-name-div">
                        <img
                          src={Docs}
                          alt="Document Icon"
                          className="document-icon"
                        />
                        <div>
                          <div className="document-name">{doc.document_name}</div>
                          <div className="document-upload-date">
                            Doc Type <b>{doc.document_type}</b>
                            {/* Uploaded on {doc.upload} */}
                          </div>
                        </div>
                      </div>
                      <div style={{ display: 'flex', }}>
                        <a style={{ marginLeft: '10px' }} href={doc.document_file} target="_blank" className="document-download-button">
                          View
                        </a>
                        {/* <button style={{ marginLeft: '10px' }} onClick={handleDocSubmitDelete(doc.id)} className="model-delete-button">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                            fill="none"
                          >
                            <path
                              d="M15.1875 3.375H12.375V2.8125C12.375 2.36495 12.1972 1.93572 11.8807 1.61926C11.5643 1.30279 11.1351 1.125 10.6875 1.125H7.3125C6.86495 1.125 6.43572 1.30279 6.11926 1.61926C5.80279 1.93572 5.625 2.36495 5.625 2.8125V3.375H2.8125C2.66332 3.375 2.52024 3.43426 2.41475 3.53975C2.30926 3.64524 2.25 3.78832 2.25 3.9375C2.25 4.08668 2.30926 4.22976 2.41475 4.33525C2.52024 4.44074 2.66332 4.5 2.8125 4.5H3.375V14.625C3.375 14.9234 3.49353 15.2095 3.7045 15.4205C3.91548 15.6315 4.20163 15.75 4.5 15.75H13.5C13.7984 15.75 14.0845 15.6315 14.2955 15.4205C14.5065 15.2095 14.625 14.9234 14.625 14.625V4.5H15.1875C15.3367 4.5 15.4798 4.44074 15.5852 4.33525C15.6907 4.22976 15.75 4.08668 15.75 3.9375C15.75 3.78832 15.6907 3.64524 15.5852 3.53975C15.4798 3.43426 15.3367 3.375 15.1875 3.375ZM6.75 2.8125C6.75 2.66332 6.80926 2.52024 6.91475 2.41475C7.02024 2.30926 7.16332 2.25 7.3125 2.25H10.6875C10.8367 2.25 10.9798 2.30926 11.0852 2.41475C11.1907 2.52024 11.25 2.66332 11.25 2.8125V3.375H6.75V2.8125ZM13.5 14.625H4.5V4.5H13.5V14.625ZM7.875 7.3125V11.8125C7.875 11.9617 7.81574 12.1048 7.71025 12.2102C7.60476 12.3157 7.46168 12.375 7.3125 12.375C7.16332 12.375 7.02024 12.3157 6.91475 12.2102C6.80926 12.1048 6.75 11.9617 6.75 11.8125V7.3125C6.75 7.16332 6.80926 7.02024 6.91475 6.91475C7.02024 6.80926 7.16332 6.75 7.3125 6.75C7.46168 6.75 7.60476 6.80926 7.71025 6.91475C7.81574 7.02024 7.875 7.16332 7.875 7.3125ZM11.25 7.3125V11.8125C11.25 11.9617 11.1907 12.1048 11.0852 12.2102C10.9798 12.3157 10.8367 12.375 10.6875 12.375C10.5383 12.375 10.3952 12.3157 10.2898 12.2102C10.1843 12.1048 10.125 11.9617 10.125 11.8125V7.3125C10.125 7.16332 10.1843 7.02024 10.2898 6.91475C10.3952 6.80926 10.5383 6.75 10.6875 6.75C10.8367 6.75 10.9798 6.80926 11.0852 6.91475C11.1907 7.02024 11.25 7.16332 11.25 7.3125Z"
                              fill="#F72B50"
                            />
                          </svg>
                        </button> */}
                        {/* <DocDeleteButton i={doc} /> */}

                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        ) : null}
        {toggleState === 3 ? (
          <div className="document-content-tab">
            {filteredPreExpDocuments.length === 0 ? (
              <div className="no-documents-message">
                No documents available.
              </div>
            ) : (
              <div className="document-grid2">
                {filteredPreExpDocuments.map((doc, index) => (
                  <div className="document-item1" key={index}>
                    <div className="document-icons">
                      <div className="document-name-div">
                        <img
                          src={Docs}
                          alt="Document Icon"
                          className="document-icon"
                        />
                        <div>
                          <div className="document-name">{doc.document_name}</div>
                          <div className="document-upload-date">
                            Doc Type <b>{doc.document_type}</b>
                            {/* Uploaded on {doc.upload} */}
                          </div>
                        </div>
                      </div>
                      <div style={{ display: 'flex', }}>
                        <a style={{ marginLeft: '10px' }} href={doc.document_file} target="_blank" className="document-download-button">
                          View
                        </a>
                        {/* <button style={{ marginLeft: '10px' }} onClick={handleDocSubmitDelete(doc.id)} className="model-delete-button">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                            fill="none"
                          >
                            <path
                              d="M15.1875 3.375H12.375V2.8125C12.375 2.36495 12.1972 1.93572 11.8807 1.61926C11.5643 1.30279 11.1351 1.125 10.6875 1.125H7.3125C6.86495 1.125 6.43572 1.30279 6.11926 1.61926C5.80279 1.93572 5.625 2.36495 5.625 2.8125V3.375H2.8125C2.66332 3.375 2.52024 3.43426 2.41475 3.53975C2.30926 3.64524 2.25 3.78832 2.25 3.9375C2.25 4.08668 2.30926 4.22976 2.41475 4.33525C2.52024 4.44074 2.66332 4.5 2.8125 4.5H3.375V14.625C3.375 14.9234 3.49353 15.2095 3.7045 15.4205C3.91548 15.6315 4.20163 15.75 4.5 15.75H13.5C13.7984 15.75 14.0845 15.6315 14.2955 15.4205C14.5065 15.2095 14.625 14.9234 14.625 14.625V4.5H15.1875C15.3367 4.5 15.4798 4.44074 15.5852 4.33525C15.6907 4.22976 15.75 4.08668 15.75 3.9375C15.75 3.78832 15.6907 3.64524 15.5852 3.53975C15.4798 3.43426 15.3367 3.375 15.1875 3.375ZM6.75 2.8125C6.75 2.66332 6.80926 2.52024 6.91475 2.41475C7.02024 2.30926 7.16332 2.25 7.3125 2.25H10.6875C10.8367 2.25 10.9798 2.30926 11.0852 2.41475C11.1907 2.52024 11.25 2.66332 11.25 2.8125V3.375H6.75V2.8125ZM13.5 14.625H4.5V4.5H13.5V14.625ZM7.875 7.3125V11.8125C7.875 11.9617 7.81574 12.1048 7.71025 12.2102C7.60476 12.3157 7.46168 12.375 7.3125 12.375C7.16332 12.375 7.02024 12.3157 6.91475 12.2102C6.80926 12.1048 6.75 11.9617 6.75 11.8125V7.3125C6.75 7.16332 6.80926 7.02024 6.91475 6.91475C7.02024 6.80926 7.16332 6.75 7.3125 6.75C7.46168 6.75 7.60476 6.80926 7.71025 6.91475C7.81574 7.02024 7.875 7.16332 7.875 7.3125ZM11.25 7.3125V11.8125C11.25 11.9617 11.1907 12.1048 11.0852 12.2102C10.9798 12.3157 10.8367 12.375 10.6875 12.375C10.5383 12.375 10.3952 12.3157 10.2898 12.2102C10.1843 12.1048 10.125 11.9617 10.125 11.8125V7.3125C10.125 7.16332 10.1843 7.02024 10.2898 6.91475C10.3952 6.80926 10.5383 6.75 10.6875 6.75C10.8367 6.75 10.9798 6.80926 11.0852 6.91475C11.1907 7.02024 11.25 7.16332 11.25 7.3125Z"
                              fill="#F72B50"
                            />
                          </svg>
                        </button> */}
                        <DocDeleteButton i={doc} getDocuments={getDocuments} />


                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        ) : null}
        {toggleState === 4 ? (
          <div className="document-content-tab">
            {filteredDegreeDocuments.length === 0 ? (
              <div className="no-documents-message">
                No documents available.
              </div>
            ) : (
              <div className="document-grid2">
                {filteredDegreeDocuments.map((doc, index) => (
                  <div className="document-item1" key={index}>
                    <div className="document-icons">
                      <div className="document-name-div">
                        <img
                          src={Docs}
                          alt="Document Icon"
                          className="document-icon"
                        />
                        <div>
                          <div className="document-name">{doc.document_name}</div>
                          <div className="document-upload-date">
                            Doc Type <b>{doc.document_type}</b>
                            {/* Uploaded on {doc.upload} */}
                          </div>
                        </div>
                      </div>
                      <div style={{ display: 'flex', }}>
                        <a style={{ marginLeft: '10px' }} href={doc.document_file} target="_blank" className="document-download-button">
                          View
                        </a>
                        {/* <button style={{ marginLeft: '10px' }} onClick={handleDocSubmitDelete(doc.id)} className="model-delete-button">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                            fill="none"
                          >
                            <path
                              d="M15.1875 3.375H12.375V2.8125C12.375 2.36495 12.1972 1.93572 11.8807 1.61926C11.5643 1.30279 11.1351 1.125 10.6875 1.125H7.3125C6.86495 1.125 6.43572 1.30279 6.11926 1.61926C5.80279 1.93572 5.625 2.36495 5.625 2.8125V3.375H2.8125C2.66332 3.375 2.52024 3.43426 2.41475 3.53975C2.30926 3.64524 2.25 3.78832 2.25 3.9375C2.25 4.08668 2.30926 4.22976 2.41475 4.33525C2.52024 4.44074 2.66332 4.5 2.8125 4.5H3.375V14.625C3.375 14.9234 3.49353 15.2095 3.7045 15.4205C3.91548 15.6315 4.20163 15.75 4.5 15.75H13.5C13.7984 15.75 14.0845 15.6315 14.2955 15.4205C14.5065 15.2095 14.625 14.9234 14.625 14.625V4.5H15.1875C15.3367 4.5 15.4798 4.44074 15.5852 4.33525C15.6907 4.22976 15.75 4.08668 15.75 3.9375C15.75 3.78832 15.6907 3.64524 15.5852 3.53975C15.4798 3.43426 15.3367 3.375 15.1875 3.375ZM6.75 2.8125C6.75 2.66332 6.80926 2.52024 6.91475 2.41475C7.02024 2.30926 7.16332 2.25 7.3125 2.25H10.6875C10.8367 2.25 10.9798 2.30926 11.0852 2.41475C11.1907 2.52024 11.25 2.66332 11.25 2.8125V3.375H6.75V2.8125ZM13.5 14.625H4.5V4.5H13.5V14.625ZM7.875 7.3125V11.8125C7.875 11.9617 7.81574 12.1048 7.71025 12.2102C7.60476 12.3157 7.46168 12.375 7.3125 12.375C7.16332 12.375 7.02024 12.3157 6.91475 12.2102C6.80926 12.1048 6.75 11.9617 6.75 11.8125V7.3125C6.75 7.16332 6.80926 7.02024 6.91475 6.91475C7.02024 6.80926 7.16332 6.75 7.3125 6.75C7.46168 6.75 7.60476 6.80926 7.71025 6.91475C7.81574 7.02024 7.875 7.16332 7.875 7.3125ZM11.25 7.3125V11.8125C11.25 11.9617 11.1907 12.1048 11.0852 12.2102C10.9798 12.3157 10.8367 12.375 10.6875 12.375C10.5383 12.375 10.3952 12.3157 10.2898 12.2102C10.1843 12.1048 10.125 11.9617 10.125 11.8125V7.3125C10.125 7.16332 10.1843 7.02024 10.2898 6.91475C10.3952 6.80926 10.5383 6.75 10.6875 6.75C10.8367 6.75 10.9798 6.80926 11.0852 6.91475C11.1907 7.02024 11.25 7.16332 11.25 7.3125Z"
                              fill="#F72B50"
                            />
                          </svg>
                        </button> */}

                        <DocDeleteButton i={doc} getDocuments={getDocuments} />


                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Document;