import axios from "axios";
import Bg1 from "../images/bgimage1.png";
import Bg2 from "../images/bgimage2.png";
import Modal from "react-bootstrap/Modal";
import Calaneder from "../images/desk.svg";
import { BASE_URL } from "../../config/axios";
import React, { useState, useEffect } from "react";
import { formattedDateLong } from "../Date";
import { useNavigate } from "react-router-dom";


// Announcements Tab
const Announcements = () => {
    const [allannouncement, setAllannouncement] = useState([]);

    useEffect(() => {
        const getAnnouncement = async () => {
            try {
                const res = await axios.get(`${BASE_URL}/settings/informationboardAPI/`);
                setAllannouncement(res.data);
            } catch (err) {
                // IMPORTANT: TURN THE BELOW COMMENT OFF!!!
                // alert(err.message);
            }
        };
        getAnnouncement();
    }, []);

    const createMarkup = (content) => {
        const linkRegex = /(?:https?|ftp):\/\/[^\s]+/g;
        return {
            __html: content.replace(linkRegex, (url) => {
                return `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`;
            }),
        };
    };

    const navigate = useNavigate();

    const currentUrl = window.location.href;
    const urlParts = currentUrl.split('/');

    const url = urlParts[2]

    const url1 = `letterhead`

    const description = " Your hard work and dedication has been recognized. Delighted to inform you of a well-deserved salary increment, reflecting our appreciation for your valuable contributions. Congratulations!"


    return (
        <div className="dashcomp-bg-cards EmployeeDashboad-notification-cards " >

            {/* For Testing Purpose Only! */}
            {/* <div className="d">
                <h5>1. Announcement</h5>
                <img src={Calaneder} alt="Desk Icon"
                            style={{ color: 'red', marginRight: '8px',
                            width: '16px', height: '16px', marginTop: "-4px" }}
                        />
            </div> */}
{/* 

            {sessionStorage.getItem("letter_type") != "None" ? (<div className="EmployeeDashboad-notification-card">
                <h3 className='dashcomp-announcement-heading'>1. {" "} Increment Letter</h3>
                <span className="dashcomp-announcement-date">
                    <img src={Calaneder} alt="Desk Icon"
                        style={{
                            color: 'red', marginRight: '8px',
                            width: '16px', height: '16px', marginTop: "-4px"
                        }}
                    />
                    February 22, 2024
                </span>
                <p className="dashcomp-announcement-p">
                    <div dangerouslySetInnerHTML={createMarkup(description)} />
                </p>
                <button style={{ color: 'blue' }} onClick={() => navigate(url1)}> View Increment Letter</button>

            </div>
            ) : ""} */}




            {allannouncement.map((data, index) => {
                const formattedDate = new Date(data.date).toLocaleString('en-US', {
                    month: 'long',
                    day: 'numeric',
                    year: 'numeric',
                });
                return (
                    <>
                        <div className="EmployeeDashboad-notification-card">
                            <h3 className='dashcomp-announcement-heading'>{(sessionStorage.getItem("letter_type") == "None") ? (index + 1) : (index + 2)}.{"  "}{data.title}</h3>
                            <span className="dashcomp-announcement-date">
                                <img src={Calaneder} alt="Desk Icon"
                                    style={{
                                        color: 'red', marginRight: '8px',
                                        width: '16px', height: '16px', marginTop: "-4px"
                                    }}
                                />
                                {formattedDate}
                            </span>
                            <p className="dashcomp-announcement-p">
                                <div dangerouslySetInnerHTML={createMarkup(data.description)} />
                            </p>
                            <a href={data.file} target="blank" ><svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor" class="bi bi-file-pdf" viewBox="0 0 16 16" color="#FF747C">
                                <path d="M4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm0 1h8a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1" />
                                <path d="M4.603 12.087a.8.8 0 0 1-.438-.42c-.195-.388-.13-.776.08-1.102.198-.307.526-.568.897-.787a7.7 7.7 0 0 1 1.482-.645 20 20 0 0 0 1.062-2.227 7.3 7.3 0 0 1-.43-1.295c-.086-.4-.119-.796-.046-1.136.075-.354.274-.672.65-.823.192-.077.4-.12.602-.077a.7.7 0 0 1 .477.365c.088.164.12.356.127.538.007.187-.012.395-.047.614-.084.51-.27 1.134-.52 1.794a11 11 0 0 0 .98 1.686 5.8 5.8 0 0 1 1.334.05c.364.065.734.195.96.465.12.144.193.32.2.518.007.192-.047.382-.138.563a1.04 1.04 0 0 1-.354.416.86.86 0 0 1-.51.138c-.331-.014-.654-.196-.933-.417a5.7 5.7 0 0 1-.911-.95 11.6 11.6 0 0 0-1.997.406 11.3 11.3 0 0 1-1.021 1.51c-.29.35-.608.655-.926.787a.8.8 0 0 1-.58.029m1.379-1.901q-.25.115-.459.238c-.328.194-.541.383-.647.547-.094.145-.096.25-.04.361q.016.032.026.044l.035-.012c.137-.056.355-.235.635-.572a8 8 0 0 0 .45-.606m1.64-1.33a13 13 0 0 1 1.01-.193 12 12 0 0 1-.51-.858 21 21 0 0 1-.5 1.05zm2.446.45q.226.244.435.41c.24.19.407.253.498.256a.1.1 0 0 0 .07-.015.3.3 0 0 0 .094-.125.44.44 0 0 0 .059-.2.1.1 0 0 0-.026-.063c-.052-.062-.2-.152-.518-.209a4 4 0 0 0-.612-.053zM8.078 5.8a7 7 0 0 0 .2-.828q.046-.282.038-.465a.6.6 0 0 0-.032-.198.5.5 0 0 0-.145.04c-.087.035-.158.106-.196.283-.04.192-.03.469.046.822q.036.167.09.346z" />
                            </svg></a>
                        </div>
                    </>
                )
            })
            }
        </div>
    )
}

// Birthday Tab
const Birthday = () => {
    const [birthday, setBirthday] = useState([]);
    const [selectedImage, setSelectedImage] = useState('');
    const [filteredBirthdays, setFilteredBirthdays] = useState([]);
    const [todayBirthdays, setTodayBirthdays] = useState([]);
    const [thisWeekBirthdays, setThisWeekBirthdays] = useState([]);
    const [thisMonthBirthdays, setThisMonthBirthdays] = useState([]);

    useEffect(() => {
        const getBirthdayData = async () => {
            try {
                const res = await axios.get(`${BASE_URL}/settings/birthday/`);
                setBirthday(res.data);
                filterBirthdays(res.data);
            } catch (err) {
                console.error(err.message);
            }
        };
        getBirthdayData();
    }, []);

    const getRandomBackgroundImage = () => {
        const backgrounds = [`url("https://civilmantra.s3.ap-south-1.amazonaws.com/Gold+White+Modern+Happy+Birthday+Your+Story.png")`, `url(${Bg2})`];
        const randomIndex = Math.floor(Math.random() * backgrounds.length);
        return backgrounds[randomIndex];
    };

    const filterBirthdays = (data) => {
        const today = new Date();
        const thisWeekEnd = new Date();
        const thisWeekEndd = new Date();
        const thisMonthEnd = new Date(today.getFullYear(), today.getMonth() + 1, 0);

        thisWeekEnd.setDate(today.getDate() + 7);
        thisWeekEndd.setDate(today.getDate() + 8);

        const todayBirthdays = data.filter((item) => {
            const dob = new Date(item.dob);
            return dob.getDate() === today.getDate() && dob.getMonth() === today.getMonth();
        });

        const thisWeekBirthdays = data.filter((item) => {
            const dob = new Date(item.dob);

            // Set time to midnight to only compare day and month
            dob.setHours(0, 0, 0, 0);
            today.setHours(0, 0, 0, 0);

            // Check if day and month are the same, ignore the year
            return dob.getUTCMonth() === today.getUTCMonth() &&
                (dob.getUTCDate() >= today.getUTCDate()) &&
                (dob.getUTCDate() <= thisWeekEnd.getUTCDate());
        });

        const thisMonthBirthdays = data.filter((item) => {
            const dob = new Date(item.dob);

            // Set time to midnight to only compare day and month
            dob.setHours(0, 0, 0, 0);
            today.setHours(0, 0, 0, 0);

            // Check if day and month are the same, ignore the  year
            return dob.getUTCMonth() === today.getUTCMonth() &&
                (dob.getUTCDate() >= thisWeekEndd.getUTCDate()) &&
                (dob.getUTCDate() <= thisMonthEnd.getUTCDate());
        });

        setTodayBirthdays(todayBirthdays);
        setThisWeekBirthdays(thisWeekBirthdays);
        setThisMonthBirthdays(thisMonthBirthdays);
    };

    // Modal Section
    const [show, setShow] = useState(false);

    const handleShow = (image) => {
        setSelectedImage(image);
        setShow(true);
    };

    const handleClose = () => setShow(false);
    console.log(thisWeekBirthdays)

    return (
        <div className="dashcomp-bg-cards EmployeeDashboad-notification-cards ">

            {/* TODAY's BIRTHDAY */}
            {todayBirthdays.length > 0 && (
                <>
                    <h2 className='dashcomp-bg-heading'>Birthdays Today</h2>

                    <div className='birthdaywrap'>
                        {todayBirthdays.map((data, index) => {
                            const formattedEndDate = new Date(data.dob).toLocaleString('en-US', {
                                month: 'short',
                                day: 'numeric',
                                // year: 'numeric',
                            });

                            const randomBackground = getRandomBackgroundImage();

                            return (
                                <div className="birthdaywrap-card">
                                    {/* <Modal show={show} onHide={handleClose} dialogClassName="birthdayimagemodel" >
                                        <Modal.Body>
                                            <div style={{ background: randomBackground, backgroundRepeat: "no-repeat", backgroundSize: "contain" }} className='dashcomp-bgimagee' >
                                                <img src={selectedImage ? `${`https://spacecreator-aimantra.s3.ap-south-1.amazonaws.com/${selectedImage}`}` : 'https://images.unsplash.com/photo-1511367461989-f85a21fda167?q=80&w=1931&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'} alt="profile1" className='dashcomp-imagee' />
                                            </div>
                                        </Modal.Body>
                                    </Modal> */}

                                    <div key={index} className="dashcomp-bg-card">
                                        {/* <img src={`${data.profilepic}` ? `https://spacecreator-aimantra.s3.ap-south-1.amazonaws.com/${data.profilepic}` : 'https://images.unsplash.com/photo-1511367461989-f85a21fda167?q=80&w=1931&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'} alt="profile" className='dashcomp-image' onClick={() => handleShow(data.profilepic)} /> */}
                                        <img src={data.profilepic ? `${`https://spacecreator-aimantra.s3.ap-south-1.amazonaws.com/${data.profilepic}`}` : 'https://images.unsplash.com/photo-1511367461989-f85a21fda167?q=80&w=1931&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'} alt="profile2" className='dashcomp-image'
                                            onClick={() => handleShow(data.profilepic)} />

                                        <h3 className='dashcomp-announcement-heading'>{data.name}</h3>
                                        <span className="dashcomp-announcement-date">
                                            <img src={Calaneder} alt="Desk Icon" style={{
                                                color: 'red',
                                                marginRight: '8px', width: '16px', height: '16px', marginTop: "-4px"
                                            }}
                                            />
                                            {formattedEndDate}
                                        </span>
                                        <br />
                                        <p className="dashcomp-announcement-p">{data.designation_name}</p>
                                        <p className="dashcomp-announcement-p">{data.department_name}</p>
                                        <br />
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </>
            )}

            {/* WEEK BIRTHDAY */}
            {thisWeekBirthdays.length > 0 && (
                <>
                    <h2 className='dashcomp-bg-heading'>Birthdays This Week</h2>

                    <div className='birthdaywrap'>
                        {thisWeekBirthdays.map((data, index) => {

                            const formattedEndDate = new Date(data.dob).toLocaleString('en-US', {
                                month: 'short',
                                day: 'numeric',
                                // year: 'numeric',
                            });

                            const randomBackground = getRandomBackgroundImage();

                            return (
                                <div className="birthdaywrap-card">
                                    {/* <Modal show={show} onHide={handleClose} dialogClassName="birthdayimagemodel" >
                                        <Modal.Body>
                                            <div style={{ background: randomBackground, backgroundRepeat: "no-repeat", backgroundSize: "contain" }} className='dashcomp-bgimagee' >
                                                <img src={selectedImage} alt="profile11" className='dashcomp-imagee' />
                                            </div>
                                        </Modal.Body>
                                    </Modal> */}
                                    <div key={index} className="dashcomp-bg-card">
                                        {/* <img src={`${data.profilepic}` ? `${data.profilepic}` : 'https://images.unsplash.com/photo-1511367461989-f85a21fda167?q=80&w=1931&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'} alt="profile" className='dashcomp-image' */}
                                        <img src={data.profilepic ? `${`https://spacecreator-aimantra.s3.ap-south-1.amazonaws.com/${data.profilepic}`}` : 'https://images.unsplash.com/photo-1511367461989-f85a21fda167?q=80&w=1931&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'} alt="profile2" className='dashcomp-image'
                                            onClick={() => handleShow(data.profilepic)} />
                                        <h3 className='dashcomp-announcement-heading' >{data.name}</h3>
                                        <span className="dashcomp-announcement-date">
                                            <img src={Calaneder} alt="Desk Icon" style={{
                                                color: 'red',
                                                marginRight: '8px', width: '16px', height: '16px', marginTop: "-4px"
                                            }}
                                            />
                                            {formattedEndDate}
                                        </span>
                                        <br />
                                        <p className="dashcomp-announcement-p">{data.designation_name}</p>
                                        <p className="dashcomp-announcement-p">{data.department_name}</p>
                                        <br />
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </>
            )}

            {/* MONTH BIRTHDAY */}
            {thisMonthBirthdays.length > 0 && (
                <>
                    <h2 className='dashcomp-bg-heading'>Birthdays This Month</h2>

                    <div className='birthdaywrap'>
                        {thisMonthBirthdays.map((data, index) => {
                            const formattedEndDate = new Date(data.dob).toLocaleString('en-US', {
                                month: 'short',
                                day: 'numeric',
                                year: 'numeric',
                            });
                            const randomBackground = getRandomBackgroundImage();
                            return (
                                <div className="birthdaywrap-card">
                                    {/* <Modal show={show} onHide={handleClose} dialogClassName="birthdayimagemodel" >
                                        <Modal.Body>
                                            <div style={{ background: randomBackground, backgroundRepeat: "no-repeat", backgroundSize: "contain" }} className='dashcomp-bgimagee' >
                                                <img src={selectedImage} alt="profile3" className='dashcomp-imagee' />
                                            </div>
                                        </Modal.Body>
                                    </Modal> */}

                                    <div key={index} className="dashcomp-bg-card">
                                        <img src={data.profilepic ? `${`https://spacecreator-aimantra.s3.ap-south-1.amazonaws.com/${data.profilepic}`}` : 'https://images.unsplash.com/photo-1511367461989-f85a21fda167?q=80&w=1931&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'} alt="profile4" className='dashcomp-image' onClick={() => handleShow(data.profilepic)} />
                                        {/* <img src={`${data.profilepic}` ? `${data.profilepic}` : 'https://images.unsplash.com/photo-1511367461989-f85a21fda167?q=80&w=1931&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'} alt="profile" className='dashcomp-image' onClick={() => handleShow(data.profilepic)} /> */}
                                        <h3 className='dashcomp-announcement-heading'>{data.name}</h3>
                                        <span className="dashcomp-announcement-date">
                                            <img src={Calaneder} alt="Desk Icon" style={{
                                                color: 'red',
                                                marginRight: '8px', width: '16px', height: '16px', marginTop: "-4px"
                                            }}
                                            />
                                            {formattedEndDate}
                                        </span>
                                        <br />
                                        <p className="dashcomp-announcement-p">{data.designation_name}</p>
                                        <p className="dashcomp-announcement-p">{data.department_name}</p>
                                        <br />
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </>
            )}
        </div>
    );
};


// Workanniversery Tab
const Workanniversery = () => {
    const [anniversary, setAnniversary] = useState([]);
    const [selectedImage, setSelectedImage] = useState('');
    const [filteredBirthdays, setFilteredBirthdays] = useState([]);
    const [todayAnniversaries, setTodayAnniversaries] = useState([]);
    const [thisWeekBirthdays, setThisWeekBirthdays] = useState([]);
    const [thisMonthBirthdays, setThisMonthBirthdays] = useState([]);

    useEffect(() => {
        const getBirthdayData = async () => {
            try {
                const res = await axios.get(`${BASE_URL}/settings/workaniversary/`);
                setAnniversary(res.data);
                filterBirthdays(res.data);
            } catch (err) {
                console.error(err.message);
            }
        };
        getBirthdayData();
    }, []);

    const getRandomBackgroundImage = () => {
        const backgrounds = [`url("https://civilmantra.s3.ap-south-1.amazonaws.com/Gold+White+Modern+Happy+Birthday+Your+Story.png")`, `url(${Bg2})`];
        const randomIndex = Math.floor(Math.random() * backgrounds.length);
        return backgrounds[randomIndex];
    };

    const filterBirthdays = (data) => {
        const today = new Date();
        const thisWeekEnd = new Date();
        const thisWeekEndd = new Date();
        const thisMonthEnd = new Date(today.getFullYear(), today.getMonth() + 1, 0);

        thisWeekEnd.setDate(today.getDate() + 7);
        thisWeekEndd.setDate(today.getDate() + 8);

        const todayAnniversaries = data.filter((item) => {
            const joining_date = new Date(item.joining_date);
            return joining_date.getDate() === today.getDate() && joining_date.getMonth() === today.getMonth();
        });

        const thisWeekBirthdays = data.filter((item) => {
            const joining_date = new Date(item.joining_date);

            // Set time to midnight to only compare day and month
            joining_date.setHours(0, 0, 0, 0);
            today.setHours(0, 0, 0, 0);

            // Check if day and month are the same, ignore the year
            return joining_date.getUTCMonth() === today.getUTCMonth() &&
                (joining_date.getUTCDate() >= today.getUTCDate()) &&
                (joining_date.getUTCDate() <= thisWeekEnd.getUTCDate());
        });

        const thisMonthBirthdays = data.filter((item) => {
            const joining_date = new Date(item.joining_date);

            // Set time to midnight to only compare day and month
            joining_date.setHours(0, 0, 0, 0);
            today.setHours(0, 0, 0, 0);

            // Check if day and month are the same, ignore the  year
            return joining_date.getUTCMonth() === today.getUTCMonth() &&
                (joining_date.getUTCDate() >= thisWeekEndd.getUTCDate()) &&
                (joining_date.getUTCDate() <= thisMonthEnd.getUTCDate());
        });

        setTodayAnniversaries(todayAnniversaries);
        setThisWeekBirthdays(thisWeekBirthdays);
        setThisMonthBirthdays(thisMonthBirthdays);
    };

    // calculate years
    // const [anniversaryDate, setanniversaryDate] = useState(new Date('1990-01-01'));
    const calculateAnniversary = (anniversaryDate) => {
        console.log(anniversaryDate);
        const currentDate = new Date();
        const startYear = anniversaryDate.getFullYear();
        const currentYear = currentDate.getFullYear();
        const workYears = currentYear - startYear;
        return workYears;
    };

    // Modal Section
    const [show, setShow] = useState(false);

    const handleShow = (image) => {
        setSelectedImage(image);
        setShow(true);
    };

    const handleClose = () => setShow(false);
    console.log(thisWeekBirthdays)

    return (
        <div className="dashcomp-bg-cards EmployeeDashboad-notification-cards ">

            {/* TODAY's BIRTHDAY */}
            {todayAnniversaries.length > 0 && (
                <>
                    <h2 className='dashcomp-bg-heading'>Anniversaries Today</h2>

                    <div className='birthdaywrap'>
                        {todayAnniversaries.map((data, index) => {
                            const formattedEndDate = new Date(data.joining_date).toLocaleString('en-US', {
                                month: 'short',
                                day: 'numeric',
                                year: 'numeric',
                            });


                            const randomBackground = getRandomBackgroundImage();

                            return (
                                <div className="birthdaywrap-card">
                                    {/* <Modal show={show} onHide={handleClose} dialogClassName="birthdayimagemodel" >
                                        <Modal.Body>
                                            <div style={{ background: randomBackground, backgroundRepeat: "no-repeat", backgroundSize: "contain" }} className='dashcomp-bgimagee' >
                                                <img src={data.profilepic ? `${`https://spacecreator-aimantra.s3.ap-south-1.amazonaws.com/${data.profilepic}`}` : 'https://images.unsplash.com/photo-1511367461989-f85a21fda167?q=80&w=1931&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'} alt="profile_for_anniversery" className='dashcomp-image-mod' />

                                            </div>
                                        </Modal.Body>
                                    </Modal> */}

                                    <div key={index} className="dashcomp-bg-card">
                                        <img src={`${data.profilepic}` ? `https://spacecreator-aimantra.s3.ap-south-1.amazonaws.com/${data.profilepic}` : 'https://images.unsplash.com/photo-1511367461989-f85a21fda167?q=80&w=1931&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'} alt="profile5" className='dashcomp-image' onClick={() => handleShow(data.profilepic)} />

                                        {/* <img src={`${data.profilepic}` ? `${data.profilepic}` : 'https://images.unsplash.com/photo-1511367461989-f85a21fda167?q=80&w=1931&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'} alt="profile" className='dashcomp-image' onClick={() => handleShow(data.profilepic?data.profilepic:'https://images.unsplash.com/photo-1511367461989-f85a21fda167?q=80&w=1931&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D')} /> */}


                                        <h3 className='dashcomp-announcement-heading'>{data.name}</h3>
                                        <span className="dashcomp-announcement-date">
                                            <img src={Calaneder} alt="Desk Icon" style={{
                                                color: 'red',
                                                marginRight: '8px', width: '16px', height: '16px', marginTop: "-4px"
                                            }}
                                            />
                                            {/* {calculateAnniversary(formattedEndDate)} */}
                                            {calculateAnniversary(new Date(data.joining_date))} yrs

                                        </span>
                                        <br />
                                        <p className="dashcomp-announcement-p">{data.designation_name}</p>
                                        <p className="dashcomp-announcement-p">{data.department_name}</p>
                                        <br />
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </>
            )}

            {/* WEEK BIRTHDAY */}
            {thisWeekBirthdays.length > 0 && (
                <>
                    <h2 className='dashcomp-bg-heading'>Anniversaries This Week</h2>

                    <div className='birthdaywrap'>
                        {thisWeekBirthdays.map((data, index) => {

                            const formattedEndDate = new Date(data.joining_date).toLocaleString('en-US', {
                                month: 'short',
                                day: 'numeric',
                                year: 'numeric',
                            });

                            const randomBackground = getRandomBackgroundImage();

                            return (
                                <div className="birthdaywrap-card">
                                    {/* <Modal show={show} onHide={handleClose} dialogClassName="birthdayimagemodel" >
                                        <Modal.Body>
                                            <div style={{ background: randomBackground, backgroundRepeat: "no-repeat", backgroundSize: "contain" }} className='dashcomp-bgimagee' >
                                                <img src={selectedImage} alt="profile6" className='dashcomp-imagee' />
                                            </div>
                                        </Modal.Body>
                                    </Modal> */}
                                    <div key={index} className="dashcomp-bg-card">
                                        <img src={`${data.profilepic}` ? `https://spacecreator-aimantra.s3.ap-south-1.amazonaws.com/${data.profilepic}` : 'https://images.unsplash.com/photo-1511367461989-f85a21fda167?q=80&w=1931&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'} alt="profile" className='dashcomp-image'
                                            onClick={() => handleShow(data.profilepic)} />
                                        <h3 className='dashcomp-announcement-heading' >{data.name}</h3>
                                        <span className="dashcomp-announcement-date">
                                            <img src={Calaneder} alt="Desk Icon" style={{
                                                color: 'red',
                                                marginRight: '8px', width: '16px', height: '16px', marginTop: "-4px"
                                            }}
                                            />
                                            {/* {calculateAnniversary(formattedEndDate)} */}
                                            {calculateAnniversary(new Date(data.joining_date))} yrs

                                        </span>
                                        <br />
                                        <p className="dashcomp-announcement-p">{data.designation_name}</p>
                                        <p className="dashcomp-announcement-p">{data.department_name}</p>
                                        <br />
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </>
            )}

            {/* MONTH BIRTHDAY */}
            {thisMonthBirthdays.length > 0 && (
                <>
                    <h2 className='dashcomp-bg-heading'>Anniversaries This Month</h2>

                    <div className='birthdaywrap'>
                        {thisMonthBirthdays.map((data, index) => {
                            const formattedEndDate = new Date(data.joining_date).toLocaleString('en-US', {
                                month: 'short',
                                day: 'numeric',
                                year: 'numeric',
                            });
                            const randomBackground = getRandomBackgroundImage();
                            return (
                                <div className="birthdaywrap-card">
                                    {/* <Modal show={show} onHide={handleClose} dialogClassName="birthdayimagemodel" >
                                        <Modal.Body>
                                            <div style={{ background: randomBackground, backgroundRepeat: "no-repeat", backgroundSize: "contain" }} className='dashcomp-bgimagee' >
                                                <img src={selectedImage} alt="profile" className='dashcomp-imagee' />
                                            </div>
                                        </Modal.Body>
                                    </Modal> */}

                                    <div key={index} className="dashcomp-bg-card">
                                        <img src={`${data.profilepic}` ? `https://spacecreator-aimantra.s3.ap-south-1.amazonaws.com/${data.profilepic}` : 'https://images.unsplash.com/photo-1511367461989-f85a21fda167?q=80&w=1931&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'} alt="profile" className='dashcomp-image' onClick={() => handleShow(data.profilepic)} />
                                        <h3 className='dashcomp-announcement-heading'>{data.name}</h3>
                                        <span className="dashcomp-announcement-date">
                                            <img src={Calaneder} alt="Desk Icon" style={{
                                                color: 'red',
                                                marginRight: '8px', width: '16px', height: '16px', marginTop: "-4px"
                                            }}
                                            />
                                            {/* {calculateAnniversary(formattedEndDate)} */}
                                            {calculateAnniversary(new Date(data.joining_date))} yrs

                                        </span>
                                        <br />
                                        <p className="dashcomp-announcement-p">{data.designation_name}</p>
                                        <p className="dashcomp-announcement-p">{data.department_name}</p>
                                        <br />
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </>
            )}
        </div>
    );
}


// ImportantMessage Tab
const ImportantMessage = () => {

    const [message, setImpoMessage] = useState([]);
    // const [selectedImage, setSelectedImage] = useState('');

    useEffect(() => {
        const getImportantInfoData = async () => {
            try {
                const res = await axios.get(`${BASE_URL}/settings/importantnotificationAPI/`);
                setImpoMessage(res.data);
            } catch (err) {
                console.error(err.message);
            }
        };
        getImportantInfoData();
    }, []);
    const createMarkup = (content) => {
        const linkRegex = /(?:https?|ftp):\/\/[^\s]+/g;
        return {
            __html: content.replace(linkRegex, (url) => {
                return `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`;
            }),
        };
    };

    return (
        <>
            <div className='birthdaywrap EmployeeDashboad-notification-cards'>
                {message.map((data, index) => {

                    console.log(data)
                    const formattedEndDate = new Date(data.date).toLocaleString('en-US', {
                        month: 'short',
                        day: 'numeric',
                        year: 'numeric',
                    });


                    return (
                        <>

                            <div key={index} className="dashcomp-bg-card ">

                                {/* <h3 className='dashcomp-announcement-heading' >title{data.title}</h3> */}


                                {/* <br/>
                                <img src={`${data.file}` ? `${data.file}` : 'https://images.unsplash.com/photo-1511367461989-f85a21fda167?q=80&w=1931&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'}  alt="profile" className='dashcomp-event-image' 
                                // onClick={() => handleShow(data.profilepic)} 
                                />
                                
                            <br/> */}
                                <p className="dashcomp-announcement-p">
                                    {/* {index + 1}.  */}
                                    <div dangerouslySetInnerHTML={createMarkup((index + 1) + ". " + data.message)} />
                                </p>
                                <span className="dashcomp-announcement-date">

                                    <img src={Calaneder} alt="Desk Icon" style={{
                                        color: 'red',
                                        marginRight: '8px', width: '16px', height: '16px', marginTop: "-4px"
                                    }}
                                    />
                                    {formattedEndDate}

                                </span>
                                <br />
                                <br />

                                {/* <p className="dashcomp-announcement-p">{data.designation}</p>
                            <p className="dashcomp-announcement-p">{data.department}</p> */}
                                <p style={{ borderBottom: '2px solid grey' }} />
                            </div>
                        </>
                    );
                })}
            </div>
        </>
    )
}


// Event Tab
const Event = () => {

    const [event, setEvent] = useState([]);
    // const [selectedImage, setSelectedImage] = useState('');

    useEffect(() => {
        const getEventData = async () => {
            try {
                const res = await axios.get(`${BASE_URL}/settings/eventAPI/`);
                setEvent(res.data);
            } catch (err) {
                console.error(err.message);
            }
        };
        getEventData();
    }, []);
    const createMarkup = (content) => {
        const linkRegex = /(?:https?|ftp):\/\/[^\s]+/g;
        return {
            __html: content.replace(linkRegex, (url) => {
                return `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`;
            }),
        };
    };
    return (
        <>
            <div className='dashcomp-task-cards birthdaywrap EmployeeDashboad-notification-cards '>
                {event.map((data, index) => {

                    console.log(data)
                    const formattedEndDate = new Date(data.date).toLocaleString('en-US', {
                        month: 'short',
                        day: 'numeric',
                        year: 'numeric',
                    });


                    return (
                        <>
                            {/* <Modal show={show} onHide={handleClose} dialogClassName="birthdayimagemodel" >
                            <Modal.Body>
                                <div style={{ background: randomBackground, backgroundRepeat: "no-repeat", backgroundSize: "contain" }} className='dashcomp-bgimagee' >
                                    <img src={selectedImage} alt="profile" className='dashcomp-imagee' />
                                </div>
                            </Modal.Body>
                        </Modal> */}
                            <div key={index} className="dashcomp-bg-card">
                                <div style={{ display: 'flex' }}>

                                    <h3 style={{ flex: 3 }} className='dashcomp-announcement-heading' >{index + 1}. {data.title}</h3>
                                    <span style={{ flex: 1 }} className="dashcomp-announcement-date">

                                        <img src={Calaneder} alt="Desk Icon" style={{
                                            color: 'red',
                                            marginRight: '8px', width: '16px', height: '16px', marginTop: "-4px"
                                        }}
                                        />
                                        {formattedEndDate}
                                    </span>
                                </div>
                                {/* <br /> */}
                                <p className="dashcomp-announcement-p">
                                    <div dangerouslySetInnerHTML={createMarkup(data.description)} />
                                </p>

                                {/* <br /> */}
                                <img src={`${data.file}` ? `${data.file}` : 'https://images.unsplash.com/photo-1511367461989-f85a21fda167?q=80&w=1931&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'} alt="profile" className='dashcomp-event-image'
                                // onClick={() => handleShow(data.profilepic)} 
                                />
                                <br />

                                {/* <p className="dashcomp-announcement-p">{data.designation}</p>
                            <p className="dashcomp-announcement-p">{data.department}</p> */}
                                <br />
                            </div>
                        </>
                    );
                })}
            </div>
        </>
    )
}


// Star Tab
const Star = () => {

    const [anniversary, setAnniversary] = useState([]);
    // const [selectedImage, setSelectedImage] = useState('');

    useEffect(() => {
        const getBirthdayData = async () => {
            try {
                const res = await axios.get(`${BASE_URL}/wfm/performance/`);
                setAnniversary(res.data);
            } catch (err) {
                console.error(err.message);
            }
        };
        getBirthdayData();
    }, []);


    return (
        <>
            <div className='birthdaywrap EmployeeDashboad-notification-cards'>
                {anniversary.map((data, index) => {

                    console.log(data)
                    const formattedEndDate = new Date(data.date).toLocaleString('en-US', {
                        month: 'short',
                        day: 'numeric',
                        year: 'numeric',
                    });


                    return (
                        <>
                            {/* <Modal show={show} onHide={handleClose} dialogClassName="birthdayimagemodel" >
                            <Modal.Body>
                                <div style={{ background: randomBackground, backgroundRepeat: "no-repeat", backgroundSize: "contain" }} className='dashcomp-bgimagee' >
                                    <img src={selectedImage} alt="profile" className='dashcomp-imagee' />
                                </div>
                            </Modal.Body>
                        </Modal> */}
                            <div className="awards-card">
                                <div className="primary-subcard">
                                    <img src={`https://spacecreator-aimantra.s3.ap-south-1.amazonaws.com/${data.profilepic}`} alt="Profile" className="performance-profile-img" />
                                    <img src={data.image_reward} alt="Badge" className="performance-reward-img" />
                                </div>
                                <div className="secondary-subcard">
                                    <div className="top-details">
                                        <div className='hr-sep' />
                                        <div className="award-title">{data.title}</div>
                                        <div className='hr-sep' />

                                    </div>
                                    <div className="middle-details">
                                        <div className='awards-cards-heading'>Reason for recognition</div>
                                        <div className='awards-cards-data'>{data.reason_for_recogntion}</div>
                                    </div>
                                    <div className="bottom-details">
                                        <div className="bottom-subcards">
                                            <div className='awards-cards-heading'>Recognized by</div>
                                            <div className='awards-cards-data'>{data.recognized_by}</div>
                                        </div>
                                        <div className="bottom-subcards">
                                            <div className='awards-cards-heading'>Date</div>
                                            <div className='awards-cards-data'> {formattedDateLong(data.date)}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    );
                })}
            </div>
        </>
    )
}

const Informationcomponent = () => {

    const [toggleState, setToggleState] = useState(1);
    return (
        <div className='backgroundmain'>
            <div className='dashboardcomponent'>
                <div >
                    <div className="bloc-tabs">
                        <button
                            className={toggleState === 1 ? "dashboardcomponent-tabs dashboardcomponent-active-tabs" : "dashboardcomponent-tabs"}
                            onClick={() => setToggleState(1)}
                        >
                            Announcements
                        </button>
                        <button
                            className={toggleState === 2 ? "dashboardcomponent-tabs dashboardcomponent-active-tabs" : "dashboardcomponent-tabs"}
                            onClick={() => setToggleState(2)}
                        >
                            Birthday
                        </button>
                        <button
                            className={toggleState === 3 ? "dashboardcomponent-tabs dashboardcomponent-active-tabs" : "dashboardcomponent-tabs"}
                            onClick={() => setToggleState(3)}
                        >
                            Work Anniversary
                        </button>
                        {/* <button
                            className={toggleState === 4 ? "dashboardcomponent-tabs dashboardcomponent-active-tabs" : "dashboardcomponent-tabs"}
                            onClick={() => setToggleState(4)}
                        >
                            Important Message
                        </button> */}
                        <button
                            className={toggleState === 5 ? "dashboardcomponent-tabs dashboardcomponent-active-tabs" : "dashboardcomponent-tabs"}
                            onClick={() => setToggleState(5)}
                        >
                            Event
                        </button>
                        <button
                            className={toggleState === 6 ? "dashboardcomponent-tabs dashboardcomponent-active-tabs" : "dashboardcomponent-tabs"}
                            onClick={() => setToggleState(6)}
                        >
                            Star
                        </button>
                    </div>
                </div>
                {/* <hr className='jobdesk-subseaction-hr' /> */}
                <div className="content-tabs">
                    {toggleState === 1 ? <Announcements /> : null}
                    {toggleState === 2 ? <Birthday /> : null}
                    {toggleState === 3 ? <Workanniversery /> : null}
                    {toggleState === 4 ? <ImportantMessage /> : null}
                    {toggleState === 5 ? <Event /> : null}
                    {/* {toggleState === 6 ? <Star /> : null} */}
                    {toggleState === 6 ? <Star /> : null}

                </div>

            </div>
        </div>
    )
}

export default Informationcomponent
