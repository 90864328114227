import axios from 'axios';
import Facebook from '../images/facebook.svg';
import { BASE_URL } from "../../config/axios";
import React, { useState, useEffect } from 'react';
import { formattedDateLong } from '../Date';
import { Flex } from 'antd';


// Announcements Tab
const Inprogresstask = () => {
    const [inprogresstask, setInprogresstask] = useState([]);

    useEffect(() => {
        const getInprogresstask = async () => {
            try {
                const res = await axios.get(`${BASE_URL}/wfm/taskbyempp/${sessionStorage.getItem('emp_code')}/pending/`);
                setInprogresstask(res.data);
            } catch (err) {
                // IMPORTANT: TURN THE BELOW COMMENT OFF!!!
                // alert(err.message);
            }
        };
        getInprogresstask();
    }, []);

    const createMarkup = (content) => {
        const linkRegex = /(?:https?|ftp):\/\/[^\s]+/g;
        return {
            __html: content.replace(linkRegex, (url) => {
                return `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`;
            }),
        };
    };

    function handlePriority(value) {

        if (value == 1) {
            return <button className="priority-button2 Color-tab-red">High</button>
            // return "High"
        }
        else if (value == 2) {
            return <button className="priority-button2 Color-tab-yellow">Medium</button>
            // return <td className="align-center Color-tab-blue">Medium</td>
            // return "Medium"
        }
        else if (value == 3) {
            return <button className="priority-button2 Color-tab-blue">Low</button>
            // return <td className="align-center Color-tab-yellow">Low</td>
        }
        else
            return <td className="align-center"></td>


        // return value;

    }
    return (
        <div className="dashcomp-task-cards" >

            {/* For Testing Purpose Only! */}
            {/* <div className="d">
              <h5>1. Announcement</h5>
              <img src={Calaneder} alt="Desk Icon"
                          style={{ color: 'red', marginRight: '8px',
                          width: '16px', height: '16px', marginTop: "-4px" }}
                      />
          </div> */}

            {inprogresstask.map((data, index) => {
                const formattedDate = new Date(data.date).toLocaleString('en-US', {
                    month: 'long',
                    day: 'numeric',
                    year: 'numeric',
                });
                return (
                    <div className=''>
                        <div className="dashcomp-task-card-data">
                            <div style={{ display: "flex" }}>
                                <h3 style={{ flex: 2 }} className='dashcomp-announcement-heading'>{index + 1}.{"  "} <span className="dashcomp-announcement-date"> By:</span> {data.assignedbyname}</h3>
                                <h3 style={{ flex: 1 }} className='dashcomp-announcement-heading'>{"     "} <span className="dashcomp-announcement-date"> To:</span> {data.concern_personname}</h3>
                            </div>
                            <div style={{ display: "flex" }}>
                                <span style={{ flex: 2 }} className="dashcomp-announcement-date">

                                    Due On:    {formattedDateLong(data.due_date)}
                                </span>
                                <span style={{ flex: 1 }} className="dashcomp-announcement-date">

                                    Priority:    {handlePriority(data.priority)}
                                </span>

                            </div>
                            <p className="dashcomp-announcement-p">
                                <div dangerouslySetInnerHTML={createMarkup(data.task)} />
                            </p>
                        </div>
                    </div>
                )
            })
            }
        </div>
    )
}


// Ondeadline Tab
const Ondeadline = () => {
    const [inprogresstask, setInprogresstask] = useState([]);

    useEffect(() => {
        const getInprogresstask = async () => {
            try {
                const res = await axios.get(`${BASE_URL}/wfm/taskbyempp/${sessionStorage.getItem('emp_code')}/inprocess/`);
                setInprogresstask(res.data);
            } catch (err) {
                // IMPORTANT: TURN THE BELOW COMMENT OFF!!!
                // alert(err.message);
            }
        };
        getInprogresstask();
    }, []);

    const createMarkup = (content) => {
        const linkRegex = /(?:https?|ftp):\/\/[^\s]+/g;
        return {
            __html: content.replace(linkRegex, (url) => {
                return `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`;
            }),
        };
    };

    function handlePriority(value) {

        if (value == 1) {
            return <button className="priority-button2 Color-tab-red">High</button>
            // return "High"
        }
        else if (value == 2) {
            return <button className="priority-button2 Color-tab-yellow">Medium</button>
            // return <td className="align-center Color-tab-blue">Medium</td>
            // return "Medium"
        }
        else if (value == 3) {
            return <button className="priority-button2 Color-tab-blue">Low</button>
            // return <td className="align-center Color-tab-yellow">Low</td>
        }
        else
            return <td className="align-center"></td>


        // return value;

    }
    return (
        <div className="dashcomp-task-cards" >

            {/* For Testing Purpose Only! */}
            {/* <div className="d">
              <h5>1. Announcement</h5>
              <img src={Calaneder} alt="Desk Icon"
                          style={{ color: 'red', marginRight: '8px',
                          width: '16px', height: '16px', marginTop: "-4px" }}
                      />
          </div> */}

            {inprogresstask.map((data, index) => {
                const formattedDate = new Date(data.date).toLocaleString('en-US', {
                    month: 'long',
                    day: 'numeric',
                    year: 'numeric',
                });
                return (
                    <>
                        <div className="dashcomp-task-card-data">
                            <div style={{ display: "flex" }}>
                                <h3 style={{ flex: 2 }} className='dashcomp-announcement-heading'>{index + 1}.{"  "} <span className="dashcomp-announcement-date"> By:</span> {data.assignedbyname}</h3>
                                <h3 style={{ flex: 1 }} className='dashcomp-announcement-heading'>{"     "} <span className="dashcomp-announcement-date"> To:</span> {data.concern_personname}</h3>
                            </div>
                            <div style={{ display: "flex" }}>
                                <span style={{ flex: 2 }} className="dashcomp-announcement-date">

                                    Due On:    {formattedDateLong(data.due_date)}
                                </span>
                                <span style={{ flex: 1 }} className="dashcomp-announcement-date">

                                    Priority:    {handlePriority(data.priority)}
                                </span>

                            </div>
                            <p className="dashcomp-announcement-p">
                                <div dangerouslySetInnerHTML={createMarkup(data.task)} />
                            </p>
                        </div>
                    </>
                )
            })
            }
        </div>
    )
}

// Completed Tab
const Completed = () => {
    const [inprogresstask, setInprogresstask] = useState([]);

    useEffect(() => {
        const getInprogresstask = async () => {
            try {
                const res = await axios.get(`${BASE_URL}/wfm/taskbyempp/${sessionStorage.getItem('emp_code')}/completed/`);
                setInprogresstask(res.data);
            } catch (err) {
                // IMPORTANT: TURN THE BELOW COMMENT OFF!!!
                // alert(err.message);
            }
        };
        getInprogresstask();
    }, []);

    const createMarkup = (content) => {
        const linkRegex = /(?:https?|ftp):\/\/[^\s]+/g;
        return {
            __html: content.replace(linkRegex, (url) => {
                return `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`;
            }),
        };
    };
    function handlePriority(value) {

        if (value == 1 || value == 'high') {
            return <button className="priority-button2 Color-tab-red">High</button>
            // return "High"
        }
        else if (value == 2 || value == 'medium') {
            return <button className="priority-button2 Color-tab-yellow">Medium</button>
            // return <td className="align-center Color-tab-blue">Medium</td>
            // return "Medium"
        }
        else if (value == 3 || value == 'low') {
            return <button className="priority-button2 Color-tab-blue">Low</button>
            // return <td className="align-center Color-tab-yellow">Low</td>
        }
        else
            return <td className="align-center"></td>


        // return value;

    }

    return (
        <div className="dashcomp-task-cards" >

            {/* For Testing Purpose Only! */}
            {/* <div className="d">
              <h5>1. Announcement</h5>
              <img src={Calaneder} alt="Desk Icon"
                          style={{ color: 'red', marginRight: '8px',
                          width: '16px', height: '16px', marginTop: "-4px" }}
                      />
          </div> */}

            {inprogresstask.map((data, index) => {
                const formattedDate = new Date(data.date).toLocaleString('en-US', {
                    month: 'long',
                    day: 'numeric',
                    year: 'numeric',
                });
                return (
                    <>
                        <div className="dashcomp-task-card-data">
                            <div style={{ display: "flex" }}>
                                <h3 style={{ flex: 2 }} className='dashcomp-announcement-heading'>{index + 1}.{"  "} <span className="dashcomp-announcement-date"> By:</span> {data.assignedbyname}</h3>
                                <h3 style={{ flex: 1 }} className='dashcomp-announcement-heading'>{"     "} <span className="dashcomp-announcement-date"> To:</span> {data.concern_personname}</h3>
                            </div>
                            <div style={{ display: "flex" }}>
                                <span style={{ flex: 2 }} className="dashcomp-announcement-date">

                                    Due On:    {formattedDateLong(data.due_date)}
                                </span>
                                <span style={{ flex: 1 }} className="dashcomp-announcement-date">

                                    Priority:    {handlePriority(data.priority)}
                                </span>

                            </div>
                            <p className="dashcomp-announcement-p">
                                <div dangerouslySetInnerHTML={createMarkup(data.task)} />
                            </p>
                        </div>
                    </>
                )
            })
            }
        </div>
    )
}

// View All Projects Tab
const Allprojects = () => {
    const [allprojects, setAllprojects] = useState([]);

    useEffect(() => {
        const getAllprojects = async () => {
            try {
                const res = await axios.get(`${BASE_URL}/attandance/in-progress-task/`);
                setAllprojects(res.data);
            } catch (err) {
                // IMPORTANT: TURN THE BELOW COMMENT OFF!!!
                // alert(err.message);
            }
        };
        getAllprojects();
    }, []);

    const createMarkup = (content) => {
        const linkRegex = /(?:https?|ftp):\/\/[^\s]+/g;
        return {
            __html: content.replace(linkRegex, (url) => {
                return `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`;
            }),
        };
    };


    return (
        <div className="dashcomp-bg-cards" >

            {/* For Testing Purpose Only! */}
            {/* <div className="d">
              <h5>1. Announcement</h5>
              <img src={Calaneder} alt="Desk Icon"
                          style={{ color: 'red', marginRight: '8px',
                          width: '16px', height: '16px', marginTop: "-4px" }}
                      />
          </div> */}

            {allprojects.map((data, index) => {
                const formattedDate = new Date(data.date).toLocaleString('en-US', {
                    month: 'long',
                    day: 'numeric',
                    year: 'numeric',
                });
                return (
                    <>
                        <div className="EmployeeDashboad-notification-card">
                            <h3 className='dashcomp-announcement-heading'>{index + 1}.{"  "}{data.title}</h3>
                            <span className="dashcomp-announcement-date">
                                <img src={Facebook} alt="Desk Icon"
                                    style={{
                                        color: 'red', marginRight: '8px',
                                        width: '16px', height: '16px', marginTop: "-4px"
                                    }}
                                />
                                {formattedDate}
                            </span>
                            <p className="dashcomp-announcement-p">
                                <div dangerouslySetInnerHTML={createMarkup(data.description)} />
                            </p>
                        </div>
                    </>
                )
            })
            }
        </div>
    )
}


// Main Component
const TaskInformation = () => {

    const [toggleState, setToggleState] = useState(1);
    return (
        <div className='backgroundmain-task '>
            <div className='dashboardcomponent-task '>
                <div className="task-heading">
                    <h6>View All Task</h6>
                </div>
                <div>
                    <div className="bloc-tabs-task">
                        <button
                            className={toggleState === 1 ? "dashboardcomponent-tabs dashboardcomponent-active-tabs" : "dashboardcomponent-tabs"}
                            onClick={() => setToggleState(1)}
                        >
                            Pending
                        </button>
                        <button
                            className={toggleState === 2 ? "dashboardcomponent-tabs dashboardcomponent-active-tabs" : "dashboardcomponent-tabs"}
                            onClick={() => setToggleState(2)}
                        >
                            In Progress
                        </button>
                        <button
                            className={toggleState === 3 ? "dashboardcomponent-tabs dashboardcomponent-active-tabs" : "dashboardcomponent-tabs"}
                            onClick={() => setToggleState(3)}
                        >
                            Completed
                        </button>
                        {/* <button
                        className={toggleState === 4 ? "dashboardcomponent-tabs dashboardcomponent-active-tabs" : "dashboardcomponent-tabs"}
                        onClick={() => setToggleState(4)}
                    >
                        View All Projects
                    </button> */}
                    </div>
                </div>
                <div className="content-tabs">
                    {toggleState === 1 ? <Inprogresstask /> : null}
                    {toggleState === 2 ? <Ondeadline /> : null}
                    {toggleState === 3 ? <Completed /> : null}
                    {toggleState === 4 ? <Allprojects /> : null}
                </div>
            </div>
        </div>
    )
}

export default TaskInformation