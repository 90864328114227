// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import './HierarchyChart.css'; // Make sure to create a CSS file for styling

// const HierarchyChart = () => {
//   const [data, setData] = useState(null);
//   const [expanded, setExpanded] = useState({});

//   useEffect(() => {
//     axios.get('https://cipl.aimantra.info/wfm/hera/')
//       .then(response => {
//         setData(response.data);
//       })
//       .catch(error => console.error('Error fetching data:', error));
//   }, []);

//   const toggleExpand = (id) => {
//     setExpanded(prevState => ({
//       ...prevState,
//       [id]: !prevState[id]
//     }));
//   };

//   const renderTree = (node) => (
//     <div key={node.id} className="node">
//       <div className="node-content">
//         <p>{node.name}</p>
//         {node.subordinates && (
//           <button onClick={() => toggleExpand(node.id)}>
//             {expanded[node.id] ? 'Hide Subordinates' : 'View Subordinates'}
//           </button>
//         )}
//       </div>
//       {node.subordinates && expanded[node.id] && (
//         <div className="children">
//           {node.subordinates.map(subordinate => renderTree(subordinate))}
//         </div>
//       )}
//     </div>
//   );

//   return (
//     <div className="hierarchy-chart">
//       <h2>AiMantra Hierarchy Chart</h2>
//       {data ? renderTree(data) : <p>Loading...</p>}
//     </div>
//   );
// };

// export default HierarchyChart;


import React, { useRef, useCallback, useState } from 'react';
import Webcam from 'react-webcam';

const WebcamCapture = () => {
  const webcamRef = useRef(null);
  const [imageSrc, setImageSrc] = useState(null);

  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImageSrc(imageSrc);
  }, [webcamRef]);

  return (
    <div>
      <Webcam
        audio={false}
        ref={webcamRef}
        screenshotFormat="image/jpeg"
        width={400}
        height={300}
      />
      <button onClick={capture}>Capture photo</button>
      {imageSrc && (
        <div>
          <h2>Captured Image:</h2>
          <img src={imageSrc} alt="Captured" />
        </div>
      )}
    </div>
  );
};

export default WebcamCapture;

