import React, { useState, useEffect } from "react";
import moment from "moment";
import axios from "axios";
import { BASE_URL } from "../../config/axios";
import "../../assets/css/calander.css"
import { datearray } from "../Date";


const Calaneder = () => {

    useEffect(() => {
        getJobapppdata();
    }, []);

    const [allJobappdatadatap, setAlljobappdata] = useState([]);

    const getJobapppdata = async () => {
        try {
            const res = await axios.get(
                `${BASE_URL}/wfm/attendancefilterbyEmpByMonth/${sessionStorage.getItem('emp_code')}/${datearray[1]}/${datearray[0]}/`
            );
            const modifiedData = res.data.map(item => {
                const datee = item.date.split("-");
                const day = datee[2];
                return {
                    ...item,
                    date: day
                };
            });
            setAlljobappdata(modifiedData);
        } catch (err) {
            alert(err.message);
        }
    };

    const state = {
        dateContext: moment(),
        today: moment(),
        selectedDay: null,
    };


    let weekdaysShort = moment.weekdaysShort();

    let daysInMonth = () => {
        return state.dateContext.daysInMonth();
    };


    const currentDay = () => {
        // setTodayDate(state.dateContext.format("D"));

        return state.dateContext.format("D");
    };


    const firstDayOfMonth = () => {
        let dateContext = state.dateContext;
        let firstDay = moment(dateContext).startOf("month").format("d"); // Day of week 0...1..5...6
        return firstDay;
    };



    let weekdayss = weekdaysShort.map((day) => {
        return (
            <td
                key={day}
                className="attendance-calander-heading attendance-calander-heading-td"
            >
                <div className="attendance-calander-heading">{day}</div>
            </td>
        );
    });




    let blanks = [];
    for (let i = 0; i < firstDayOfMonth(); i++) {
        blanks.push(
            <td key={i * 80} className="emptySlot">
                {""}
            </td>
        );
    }
    let daysInMonths = [];

    for (let d = 1; d <= daysInMonth(); d++) {
        let className = d === currentDay() ? "day current-day" : "day";
        let selectedClass = d === state.selectedDay ? " selected-day " : "";
        let da = d < 10 ? "0" + d : "" + d;
        daysInMonths.push(
            <td
                key={d}
                className={className + selectedClass} style={{
                    padding: "7px 5px",
                    textAlign: "center",
                }}

            >
                <span>
                    <div className="flex-column calander-height">
                        {/* <div>
                            <span className={`attendance-calander-span ${da === datearray[2] ? "color-red" : ""}`}>
                                {d}
                            </span>
                        </div> */}

                        <div>
                            <span className="attendance-calander-span" style={da === datearray[2] ? { background: "#2576BC", color: "white", padding: "5px", borderRadius: "50%" } : {}}>
                                {d}
                            </span>
                        </div>

                        <div>
                            {allJobappdatadatap.map((data) => {
                                const inTime = data ? data.in_time : "-";
                                const outTime = data ? data.out_time : "-";
                                const attstat = data ? data.attendence_status : "-";
                                const daystatus = data ? data.day_status : "-";
                                return (
                                    <>
                                        {data.date === da ? (
                                            <span
                                                data-status={data.attendence_status}
                                                className="attendance-calander"
                                            >
                                                <span style={{ padding: "0px 3px" }} title={`In Time: ${inTime}\nOut Time: ${outTime}\nAttendance Status: ${attstat}\nDay Status: ${daystatus}`}>
                                                    {data.attendence_status}
                                                </span>
                                            </span>
                                        ) : (
                                            ""
                                        )}
                                    </>
                                );
                            })}

                        </div>
                    </div>
                </span>
            </td >
        );
    }

    var totalSlots = [...blanks, ...daysInMonths];
    let rows = [];
    let cells = [];

    totalSlots.forEach((row, i) => {
        if (i % 7 !== 0) {
            cells.push(row);
        } else {
            let insertRow = cells.slice();
            rows.push(insertRow);
            cells = [];
            cells.push(row);
        }
        if (i === totalSlots.length - 1) {
            let insertRow = cells.slice();
            rows.push(insertRow);
        }
    });


    let trElems = rows.map((d, i) => {
        return <tr key={i * 100}>{d}</tr>;
    });



    return (
        <>
            <div className="attendance-calendar-container">
                <table className="calendar">
                    <tbody className="table-calander">
                        <tr>{weekdayss}</tr>
                        {trElems}
                    </tbody>
                </table>
            </div>
        </>
    );
}

export default Calaneder







