import React from 'react'
import { Outlet } from 'react-router-dom';
import {sidebarData} from './Admin_sidebar';

import Navbar from '../../layout/Dashboardnavbar';
import DashboardSidebar from "../../layout/DashboardSidebar";

const AdminDashboard = () => {
  return (
    <>

      <div className="dashboard-main-section">
          <div className="dashboard-main-sidebar-section">
            <DashboardSidebar sidebarData={sidebarData}  />
          </div>
          <div className="dashboard-main-content-section">
            <Navbar />
            <Outlet/>
        </div>
        </div>
    </>
  )
}

export default AdminDashboard