import React, { useEffect, useState } from "react";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import axios from "axios";
import { BASE_URL } from "../../../config/axios";

const FinanceChart = ({ year, code }) => {
  const [salaryGraph, setSalaryGraph] = useState([]);

  const getSalaryGraph = async () => {
    try {
      const res = await axios.get(`${BASE_URL}/wfm/graphbyyear/${year}/${code}/`);
      setSalaryGraph(res.data);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getSalaryGraph();
  }, [year, code]);

  const monthNames = [
    "Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
  ];
  // Create an array with all months
  const allMonths = Array.from({ length: 12 }, (_, i) => ({
    name: monthNames[i],
    Month: 0,
    Cash: 0,
  }));

  // Merge the fetched data with the allMonths array
  const processedData = allMonths.map((monthData, index) => {
    const monthRecord = salaryGraph.find(item => parseInt(item.month) === index + 1);
    return monthRecord ? {
      name: monthData.name,
      InAccount: monthRecord.salarypay,
      InCash: monthRecord.cash ? parseFloat(monthRecord.cash) : 0,
    } : monthData;
  });

  return (
    <div className='staffinancechart financebarchart-cont financebarchart-maincont'>
      <div className="financebarchart barchart-flex">
        <BarChart
          width={525}
          height={363}
          data={processedData}
        >
          <CartesianGrid strokeDasharray="10 6" horizontal="true" vertical="" />
          <XAxis dataKey="name" angle={-45} textAnchor="end" height={35} interval={0} />
          <YAxis strokeOpacity={0} />
          <Tooltip wrapperStyle={{ width: 150, backgroundColor: '#ffffff' }} />
          <Legend verticalAlign='top' />
          <Bar legendType='dot' dataKey="InAccount" barSize={7} radius={10} fill="#2576BC" />
          <Bar legendType='dot' dataKey="InCash" barSize={7} radius={10} fill="#82ca9d" />
        </BarChart>
      </div>
    </div>
  );
};

export default FinanceChart;
