

import Logo1 from "../../assets/images/job_applicant/sidebar/Vector (1).png"
import Logo2 from "../../assets/images/job_applicant/sidebar/Vector (2).png";
import Logo3 from "../../assets/images/job_applicant/sidebar/Vector (3).png";
import Logo4 from "../../assets/images/job_applicant/sidebar/Vector (4).png";
import Logo5 from "../../assets/images/job_applicant/sidebar/Vector (5).png";
import Logo6 from "../../assets/images/job_applicant/sidebar/Vector (6).png";
import Logo7 from "../../assets/images/job_applicant/sidebar/Vector (7).png";
import Logo8 from "../../assets/images/job_applicant/sidebar/Vector (8).png";
import Logo9 from "../../assets/images/job_applicant/sidebar/Vector (9).png";


export const sidebarData = [
  {
    userId: "adminDashboard",
    subMenu: null,
    name: "Dashboard",
    greyIcon: Logo1,
    whiteIcon: Logo6,
    color: "grey",
  },
  {
    userId: "adminJobdesk",
    subMenu: null,
    name: "Jobdesk",
    greyIcon: Logo5,
    whiteIcon: Logo5,
    color: "grey",
  },
  
  {
    userId: "adminAttendance",
    subMenu: [
      { name:"Daily Log", id :"adminDailyLog" },
      // { name:"Attendence Request", id : "adminAttendanceRequest" },
      { name:"Attendence details", id : "adminAttendanceDetails" },
      { name: "Misspunch" , id: "adminMisspunch"}
      // { name:"Summary", id : "adminSummary" },"adminSummary""adminAttendanceRequest"
    ],
    subList: ["adminDailyLog","adminAttendanceDetails", "adminMisspunch"],
    name: "Attendance",
    greyIcon: Logo3,
    whiteIcon: Logo8,
    color: "grey",
  },
  {
    userId: "adminLeave",
    subMenu: [
      { name:"Leave Status", id :"adminLeaveStatus" },
      { name:"Leave Request", id : "adminLeaveRequest" },
      { name: "Compensatory", id:"adminCompensatory"}
      // { name:"Calender", id : "adminLeaveCalander" },
      // { name:"Summary", id : "adminLeaveSummary" }"adminLeaveSummary","adminLeaveCalander"
    ],
    subList: ["adminLeaveStatus","adminLeaveRequest", "adminCompensatory"],
    name: "Leave",
    greyIcon: Logo5,
    whiteIcon: Logo5,
    color: "grey",
  },
  {
    userId: "adminOd",
    subMenu: [
      { name:"Outduty Status", id :"adminOdStatus" },
      { name:"Outduty Request", id : "adminOdRequest" },
      { name: "Work From Home", id: "adminWfh"}
    ],
    subList: ["adminOdStatus","adminOdRequest", "adminWfh"],
    name: "Out Duty",
    greyIcon: Logo5,
    whiteIcon: Logo5,
    color: "grey",
  },
  {
    userId: "adminEmployees",
    subMenu: [
      { name:"All Employees", id :"adminAllEmployees" },
      {name: "Mail", id: "adminMail"},
      {name: "Employee Experince", id: "adminEmployeeExperience"},
      // { name:"Department", id: "adminDepartment"},
      // { name:"Employment Status", id : "adminEmployementStatus" },
      
    ],
    subList: ["adminAllEmployees", "adminMail", "adminEmployeeExperience"],
    name: "Employees",
    greyIcon: Logo4,
    whiteIcon: Logo9,
    color: "grey",
  },
  // {
  //   userId: "adminpayroll",
  //   subMenu: [
  //     { name:"Payrun", id :"adminPayrun" },
  //     { name:"Payslip", id : "adminPayslip" },
  //     { name:"Summary", id : "adminPayrollSummary" },
  //     { name:"Beneficiary page", id : "adminBeneficiaryPage" },
  //   ],
  //   subList: ["adminPayrun","adminPayslip","adminPayrollSummary","adminBeneficiaryPage"],
  //   name: "Payroll",
  //   greyIcon: Logo5,
  //   whiteIcon: Logo5,
  //   color: "grey",
  // },
  {
    userId: "adminAdministartion",
    subMenu: [
      { name:"Users & Roles", id :"adminUserRoles" },
      // { name:"Work Shifts", id : "adminWorkShift" },
      { name:"Designation", id : "adminDesignation" },
      { name:"Departments", id : "adminDepartment" },
      { name:"Holiday", id : "adminHoliday" },
      { name:"Org. Structure", id : "adminOrgStructure" },
      { name:"Announcements", id : "adminAnnouncements" },
      { name: "Job Opening", id: "adminJobopening" },
      { name: "Events Picture", id: "adminEvents"}
    ],
    subList: ["adminUserRoles","adminDesignation","adminDepartment","adminHoliday","adminOrgStructure","adminAnnouncements", "adminJobopening", "adminEvents"],
    name: "Administartion",
    greyIcon: Logo2,
    whiteIcon: Logo7,
    color: "grey",
  },
  // {
  //   userId: "adminWebsite",
  //   subMenu: [
  //     { name:"Carousel", id :"adminCarousel"},
  //     { name:"Reviews", id : "adminReviews" },
  //     { name:"Projects", id : "adminProject" },
  //     { name:"Contact", id : "adminContact" },
  //     { name:"Jobs", id : "adminJobs" },
  //   ],
  //   subList: ["adminCarousel","adminReviews","adminProject","adminContact","adminJobs"],
  //   name: "Website",
  //   greyIcon: Logo4,
  //   whiteIcon: Logo9,
  //   color: "grey",
  // }, 

  {
    userId: "adminMisspunchrequest",
    subMenu: null,
    name: "Miss Punch",
    greyIcon: Logo5,
    whiteIcon: Logo5,
    color: "grey",
  },

  
  {
    userId: "adminShortleaveRequests",
    subMenu: null,
    name: "Short Leave",
    greyIcon: Logo5,
    whiteIcon: Logo5,
    color: "grey",
  },
  {
    userId: "adminJobapplicant",
    subMenu: null,
    name: "Job Applicant",
    greyIcon: Logo5,
    whiteIcon: Logo5,
    color: "grey",
  },
  // {
  //   userId: "adminSettings",
  //   subMenu: [
  //     { name:"App Settings", id :"adminAppSettings" },
  //     { name:"Leave Settings", id : "adminLeaveSetting"},
  //     { name:"Attendence Settings", id : "adminAttendanceSetting" },
  //     { name:"Payroll Settings", id : "adminPayrollSetting" },
  //     { name:"Data", id : "adminData" },
  //     { name:"Policies", id: "adminPolicies"}
  //   ],
  //   subList: ["adminAppSettings","adminLeaveSetting","adminAttendanceSetting","adminPayrollSetting","adminData","adminPolicies"],
  //   name: "Settings",
  //   greyIcon: Logo5,
  //   whiteIcon: Logo5,
  //   color: "grey",
  // },
  {
    userId: "adminTask",
    subMenu: null,
    name: "Task",
    greyIcon: Logo5,
    whiteIcon: Logo5,
    color: "grey",
  },
  {
    userId: "adminPersonalDetails",
    subMenu: null,
    name: "Personal Details",
    greyIcon: Logo5,
    whiteIcon: Logo5,
    color: "grey",
  },

  
];