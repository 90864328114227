import React, { useState, useEffect } from "react";
// import HrmsFooter from '../../layout/HrmsFooter';
import "../../assets/css/jd.css";
import axios from "axios";
import { BASE_URL } from "../../config/axios"
import { useNavigate, useParams } from "react-router-dom";
import TimelineItem from "../../components/Timeline";

const Jobapplicant_dashboard = () => {
  let { email } = useParams()

  const [formData, setFormData] = useState({

    name: "",
    email: email,
    father_name: "",
    mother_name: "",
    mobile: "",
    alt_mobile: null,
    gender: "",
    dob: "",
    blood_group: "",
    birth_mark: "",
    married_status: "",
    marriage_date: null,
    aadhaar: "",
    pan: "",
    uan: "",
    esi: "",
    permanent_address: "",
    current_address: "",
    current_addresss: false


  });

  // !  ************** Validation start **************  ! //

  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = [
      "name",
      "email",
      "father_name",
      "mother_name",
      "mobile",
      "gender",
      "dob",
      "blood_group",
      "birth_mark",
      "married_status",
      "aadhaar",
      "pan",
      "permanent_address",
      "current_address"

    ];
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });

    setFormData({
      ...formData,
      [name]: value,
    });
  };


  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: checked,
    }));

    // If checked, set permanent_address to current_address
    if (checked) {
      setFormData((prevData) => ({
        ...prevData,
        permanent_address: prevData.current_address,
      }));
    }

    if (!checked) {
      setFormData((prevData) => ({
        ...prevData,
        permanent_address: "",
      }));
    }
  };

  // ?  ************** Validation End **************  ! //
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false); //loading logic


  const handleFormSubmit = async (e) => {
    e.preventDefault();
    // navigate("/educationDetails")
    if (validateForm()) {
      setLoading(true); //loading logic
      // Cookies.set("userData", JSON.stringify(formData));
      // Navigate("/educationDetails")
      const updatedFormData = {
        ...formData,
        employee: formData.email,
      };


      try {
        let res = await axios.post(`${BASE_URL}/userdata/userdata/`, updatedFormData,)

        if (res.status === 200) {
          navigate(`/educationDetailss/${formData.email}/`)
        } else {
          alert(res);
        }
      } catch (err) {
        alert(err);
      } finally {
        setLoading(false); //loading logic
      }
    }
  };


  const handleFormPut = async (e) => {
    e.preventDefault();
    // navigate("/educationDetails")
    if (validateForm()) {
      // Cookies.set("userData", JSON.stringify(formData));
      // Navigate("/educationDetails")
      setLoading(true); //loading logic


      const { profilepic, ...formDataWithoutProfilePic } = formData; // Destructure to remove profilepic

      const updatedFormData = {
        ...formDataWithoutProfilePic,
        employee: formData.email,
      };


      try {
        let res = await axios.put(`${BASE_URL}/userdata/userdata/${email}/`, updatedFormData,)

        if (res.status === 200) {
          navigate(`/educationDetailss/${email}/`)
        } else {
          alert(res);
        }
      } catch (err) {
        alert(err);
      } finally {
        setLoading(false); //loading logic
      }
    }
  };



  const [data, setData] = useState(false)

  useEffect(() => {
    // const storedUserData = Cookies.get("userData");

    const getUSerDetails = async () => {
      try {
        const res = await axios.get(`${BASE_URL}/userdata/userdataget/${formData.email}/`);
        setFormData(res.data);
        setData(true)
      } catch (err) {
        // alert(err.message);
      }
    };

    getUSerDetails();
  }, [formData.email]);





  return (
    <div className="jd">
      {/*  ************** timeline Seaction  ************** */}

      <div className="jd-timeline">
        <TimelineItem heading="Personal Information" color="#2576BC" />
        <div className="jd-timeline-borderr"></div>

        <TimelineItem heading="Education Detail" color="#707070" />



        <div className="jd-timeline-borderr"></div>

        <TimelineItem heading="Work Experience" color="#707070" />


        <div className="jd-timeline-borderr"></div>

        <TimelineItem heading="Emergency Contact" color="#707070" />


        <div className="jd-timeline-borderr"></div>

        <TimelineItem heading="Account Details" color="#707070" />



        <div className="jd-timeline-borderr"></div>

        <TimelineItem heading="Documents Upload" color="#707070" />



      </div>

      <div className="jd-heading-outer">
        <h2 className="jd-heading-main">Personal Information*</h2>
        <div>
          <div className="jd-heading-bottom-bold"></div>
          <div className="jd-heading-bottom-light"></div>
        </div>
      </div>

      <form className="register-form">
        <div className="form-flex-wrap">


          <div className="flex-column">
            <label htmlFor="name" className="form-labels">
              Name<span className="required">*</span>
            </label>
            <input
              type="text"
              id="name"
              name="name"
              placeholder="Demo Demo"
              onChange={handleInputChange}
              value={formData.name}
              className={`form-input ${errors.name
                ? "error"
                : inputState.name
                  ? "success"
                  : ""
                }`}
            />
            {errors.name && (
              <span className="error-message">{errors.name}</span>
            )}
          </div>

          <div className="flex-column">
            <label htmlFor="email" className="form-labels">
              Email<span className="required">*</span>
            </label>
            <input
              type="email"
              id="email"
              name="email"
              autoComplete="off"
              readOnly={email != null ? true : false}
              placeholder="Demo@gmail.com"
              onChange={handleInputChange}
              value={email}
              onInput={(e) => {
                e.target.value = e.target.value.toLowerCase().trim(); // Convert input to lowercase
              }}
              className={`form-input ${errors.email
                ? "error"
                : inputState.email
                  ? "success"
                  : ""
                }`}
            />
            {errors.email && (
              <span className="error-message">{errors.email}</span>
            )}
          </div>


          <div className="flex-column">
            <label htmlFor="father_name" className="form-labels">
              Father Name<span className="required">*</span>
            </label>
            <input
              type="text"
              id="father_name"
              name="father_name"
              placeholder="Demo Demo"
              onChange={handleInputChange}
              value={formData.father_name}
              className={`form-input ${errors.father_name
                ? "error"
                : inputState.father_name
                  ? "success"
                  : ""
                }`}
            />
            {errors.father_name && (
              <span className="error-message">{errors.father_name}</span>
            )}
          </div>

          <div className="flex-column">
            <label htmlFor="mother_name" className="form-labels">
              Mother Name<span className="required">*</span>
            </label>
            <input
              type="text"
              id="mother_name"
              name="mother_name"
              placeholder="Demo Demo"
              onChange={handleInputChange}
              value={formData.mother_name}
              className={`form-input ${errors.mother_name
                ? "error"
                : inputState.mother_name
                  ? "success"
                  : ""
                }`}
            />
            {errors.mother_name && (
              <span className="error-message">{errors.mother_name}</span>
            )}
          </div>

          <div className="flex-column">
            <label htmlFor="mobile" className="form-labels">
              Mobile<span className="required">*</span>
            </label>
            <input
              type="number"
              id="mobile"
              name="mobile"
              placeholder="99999 88888"
              onChange={handleInputChange}
              value={formData.mobile}
              className={`form-input ${errors.mobile ? "error" : inputState.mobile ? "success" : ""
                }`}
            />
            {errors.mobile && (
              <span className="error-message">{errors.mobile}</span>
            )}
          </div>

          <div className="flex-column">
            <label htmlFor="alt_mobile" className="form-labels">
              Alternate Mobile
            </label>
            <input
              type="number"
              id="alt_mobile"
              name="alt_mobile"
              placeholder="99999 88888"
              onChange={handleInputChange}
              value={formData.alt_mobile}
              className={`form-input ${errors.alt_mobile
                ? "error"
                : inputState.alt_mobile
                  ? "success"
                  : ""
                }`}
            />
            {errors.alt_mobile && (
              <span className="error-message">{errors.alt_mobile}</span>
            )}
          </div>

          <div className="flex-column form-group-select">
            <label htmlFor="gender" className="form-labels">
              Gender<span className="required">*</span>
            </label>
            <br />
            <select
              id="gender"
              name="gender"
              value={formData.gender}
              onChange={handleInputChange}
              className={`form-input form-group-selection ${errors.gender ? "error" : inputState.gender ? "success" : ""
                }`}
            >
              <option value="">Select Gender</option>
              <option value="male">Male</option>
              <option value="female">Female</option>
              <option value="other">Other</option>
            </select>
            <div className="form-group-selection-arrow">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="25"
                fill="currentColor"
                class="bi bi-caret-down-fill"
                viewBox="0 0 16 16"
              >
                <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
              </svg>
            </div>
            <div>
              {errors.gender && (
                <span className="error-message">{errors.gender}</span>
              )}
            </div>
          </div>

          <div className="flex-column">
            <label htmlFor="dob" className="form-labels">
              Date of Birth<span className="required">*</span>
            </label>
            <input
              type="date"
              id="dob"
              name="dob"
              onChange={handleInputChange}
              value={formData.dob}
              className={`form-input ${errors.dob ? "error" : inputState.dob ? "success" : ""
                }`}
            />
            {errors.dob && <span className="error-message">{errors.dob}</span>}
          </div>

          <div className="flex-column form-group-select">
            <label htmlFor="blood_group" className="form-labels">
              Blood Group<span className="required">*</span>
            </label>
            <br />
            <select
              id="blood_group"
              name="blood_group"
              value={formData.blood_group}
              onChange={handleInputChange}
              className={`form-input form-group-selection ${errors.blood_group
                ? "error"
                : inputState.blood_group
                  ? "success"
                  : ""
                }`}
            >
              <option value="">Select a Blood Group</option>
              <option value="A+">A+</option>
              <option value="A-">A-</option>
              <option value="B+">B+</option>
              <option value="B-">B-</option>
              <option value="AB+">AB+</option>
              <option value="AB-">AB-</option>
              <option value="O+">O+</option>
              <option value="O-">O-</option>
              <option value="na">Not Know</option>
            </select>
            <div className="form-group-selection-arrow">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="25"
                fill="currentColor"
                class="bi bi-caret-down-fill"
                viewBox="0 0 16 16"
              >
                <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
              </svg>
            </div>
            <div>
              {errors.blood_group && (
                <span className="error-message">{errors.blood_group}</span>
              )}
            </div>
          </div>

          <div className="flex-column">
            <label htmlFor="birth_mark" className="form-labels">
              Birth Mark<span className="required">*</span>
            </label>
            <input
              type="text"
              id="birth_mark"
              name="birth_mark"
              placeholder="Demo Demo"
              onChange={handleInputChange}
              value={formData.birth_mark}
              className={`form-input ${errors.birth_mark
                ? "error"
                : inputState.birth_mark
                  ? "success"
                  : ""
                }`}
            />
            {errors.birth_mark && (
              <span className="error-message">{errors.birth_mark}</span>
            )}
          </div>

          <div className="flex-column form-group-select">
            <label htmlFor="married_status" className="form-labels">
              Marital Status<span className="required">*</span>
            </label>
            <br />
            <select
              id="married_status"
              name="married_status"
              value={formData.married_status}
              onChange={handleInputChange}
              className={`form-input form-group-selection ${errors.married_status
                ? "error"
                : inputState.married_status
                  ? "success"
                  : ""
                }`}
            >
              <option value="">Select a Married Status</option>
              <option value="Single">Single</option>
              <option value="Married">Married</option>
              <option value="Divorced">Divorced</option>
              <option value="Widowed">Widowed</option>
              <option value="Separated">Separated</option>
            </select>
            <div className="form-group-selection-arrow">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="25"
                fill="currentColor"
                class="bi bi-caret-down-fill"
                viewBox="0 0 16 16"
              >
                <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
              </svg>
            </div>
            <div>
              {errors.married_status && (
                <span className="error-message">{errors.married_status}</span>
              )}
            </div>
          </div>

          <div className="flex-column">
            <label htmlFor="marriage_date" className="form-labels">
              Marriage Date
            </label>
            <input
              type="date"
              id="marriage_date"
              name="marriage_date"
              placeholder="Demo Demo"
              onChange={handleInputChange}
              value={formData.marriage_date}
              className={`form-input ${errors.marriage_date
                ? "error"
                : inputState.marriage_date
                  ? "success"
                  : ""
                }`}
            />

          </div>

          <div className="flex-column">
            <label htmlFor="aadhaar" className="form-labels">
              Aadhaar<span className="required">*</span>
            </label>
            <input
              type="number"
              id="aadhaar"
              name="aadhaar"
              placeholder="1234 1234 1234"
              onChange={handleInputChange}
              value={formData.aadhaar}
              className={`form-input ${errors.aadhaar ? "error" : inputState.aadhaar ? "success" : ""
                }`}
            />
            {errors.aadhaar && (
              <span className="error-message">{errors.aadhaar}</span>
            )}
          </div>

          <div className="flex-column">
            <label htmlFor="pan" className="form-labels">
              Pan<span className="required">*</span>
            </label>
            <input
              type="text"
              id="pan"
              name="pan"
              placeholder="AAAAA1234A"

              onChange={handleInputChange}
              value={formData.pan}
              className={`form-input ${errors.pan ? "error" : inputState.pan ? "success" : ""
                }`}
            />
            {errors.pan && (
              <span className="error-message">{errors.pan}</span>
            )}
          </div>

          <div className="flex-column">
            <label htmlFor="uan" className="form-labels">
              Uan
            </label>
            <input
              type="text"
              id="uan"
              name="uan"
              // placeholder="Demo Demo"
              onChange={handleInputChange}
              value={formData.uan}
              className={`form-input ${errors.uan
                ? "error"
                : inputState.uan
                  ? "success"
                  : ""
                }`}
            />

          </div>

          <div className="flex-column">
            <label htmlFor="esi" className="form-labels">
              Esi
            </label>
            <input
              type="text"
              id="esi"
              name="esi"
              // placeholder="Demo Demo"
              onChange={handleInputChange}
              value={formData.esi}
              className={`form-input ${errors.esi
                ? "error"
                : inputState.esi
                  ? "success"
                  : ""
                }`}
            />
          </div>


          <div className="flex-column">
            <label htmlFor="current_address" className="form-labels">
              Current Address<span className="required">*</span>
            </label>
            <textarea
              type="text"
              id="current_address"
              name="current_address"
              // placeholder="Demo Demo"
              onChange={handleInputChange}
              value={formData.current_address}
              className={`form-input-textarea ${errors.current_address
                ? "error"
                : inputState.current_address
                  ? "success"
                  : ""
                }`}
            />
            {errors.current_address && (
              <span className="error-message">{errors.current_address}</span>
            )}
          </div>


          <div className="flex-column">
            <label htmlFor="current_addresss" className="form-labels">
              Permanent Address <span className="required">*</span><span className="label-sub-heading">( Use Default Address)</span>
              <input
                type="checkbox"
                id="current_addresss"
                name="current_addresss"
                checked={formData.current_addresss}
                onChange={handleCheckboxChange}
              />
            </label>

            <textarea
              type="text"
              id="permanent_address"
              name="permanent_address"
              placeholder="Permanent Address"

              onChange={handleInputChange}
              value={formData.permanent_address}
              className={`form-input-textarea ${errors.permanent_address ? "error" : inputState.permanent_address ? "success" : ""}`}
            />
          </div>

        </div>

        <div className="button-models">

          {data ? (<button onClick={handleFormPut} className="model-button model-button-submit" disabled={loading} >
            Update
          </button>) : (<button onClick={handleFormSubmit} className="model-button model-button-submit" disabled={loading}>
            Next
          </button>)}

        </div>
      </form>
    </div>
  );
};

export default Jobapplicant_dashboard;
