import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { BASE_URL, FRONTEND_URL } from '../../config/axios'
import { Application, Applicationupdate, ApplicationDelete } from './Application'
import { formatDate } from '../Date'

const Posting = () => {



  const [toggleState, setToggleState] = useState("null")
  const [jobposition, setJobPosition] = useState([])
  const [jobpositionList, setJobPositionList] = useState([])


  const getJobpositionlist = async (toggleState) => {
    try {
      const res = await axios.get(`${BASE_URL}/jd/jobpostfilter/${toggleState}/`);
      // const res = await axios.get(`${BASE_URL}/jd/jobpostfilter/null/`);
      setJobPosition(res.data)
    } catch (err) {
      // alert(err.message);
    }
  };

  const getCompleteJobpostlist = async () => {
    try {
      const res = await axios.get(`${BASE_URL}/jd/jobpost/`);
      // const res = await axios.get(`${BASE_URL}/jd/jobpostfilter/null/`);
      setJobPositionList(res.data)
    } catch (err) {
      // alert(err.message);
    }
  };



  const [designationname, setDesignationname] = useState([])

  const [departmentname, setDepartmentname] = useState([])


  const getDesignation = async () => {
    try {
      const res = await axios.get(`${BASE_URL}/wfm/designation/`);
      const ress = await axios.get(`${BASE_URL}/wfm/department/`);

      setDesignationname(res.data)
      setDepartmentname(ress.data)

    } catch (err) {
      // alert(err.message);
    }
  };

  useEffect(() => {
    getDesignation();
    getJobpositionlist(toggleState);
    getCompleteJobpostlist();
  }, [toggleState]);

  return (
    <div className="custom-table-background">
      <div className="table-heading-flex">
        <div className="repo-heading">Showing {jobposition.length} Jobs Post
          {/* <p className='career-para'>Based your performance</p>          */}
        </div>
        <div>
          <Application designationname={designationname} departmentname={departmentname} getJobpositionlist={getJobpositionlist} getCompleteJobpostlist={jobpositionList} status={toggleState} />
        </div>
      </div>
      <div className="bloc-tabs-with-white-bg bloc-tabs-with-lesswhite-bg">
        <button
          className={
            toggleState === "null"
              ? "dashboardcomponent-tabs  dashboardcomponent-active-tabs"
              : "dashboardcomponent-tabs white-tab"
          }
          onClick={() => setToggleState("null")}
        >
          All
        </button>
        <button
          className={
            toggleState === "active"
              ? "dashboardcomponent-tabs  dashboardcomponent-active-tabs"
              : "dashboardcomponent-tabs white-tab"
          }
          onClick={() => setToggleState("active")}
        >
          Active
        </button>
        <button
          className={
            toggleState === "inactive"
              ? "dashboardcomponent-tabs dashboardcomponent-active-tabs"
              : "dashboardcomponent-tabs white-tab"
          }
          onClick={() => setToggleState("inactive")}
        >
          Inactive
        </button>
      </div>
      <table className="custom-table">
        <thead>
          <tr className="custom-table-head-tr">
            <th className="align-left">ID</th>
            <th className="align-leftt">Department</th>
            <th className="align-leftt">Position</th>
            <th className="align-center">Experience</th>
            <th className="align-center">Job post</th>
            {/* <th className="align-center">Close Date</th> */}
            <th className="align-center">Type</th>
            <th className="align-center">Position</th>
            <th className="align-center">Status</th>
            <th className="align-center">Link</th>

            {toggleState === "null" ? (<th className="align-right">Action</th>) : ""}
          </tr>
        </thead>
        <tbody>
          {jobposition.map((i, index) => (
            <React.Fragment key={index}>
              <tr className="tr-border-bottom">
                <td colSpan="6"></td>
              </tr>
              <tr className="custom-table-head-td">
                <td className="align-left">{index + 1}</td>
                <td className="align-leftt">{i.department_name}</td>
                <td className="align-leftt">{i.position_name}</td>
                <td className="align-center">{i.experience}</td>
                <td className="align-center">{formatDate(i.start_date)} - {formatDate(i.end_date)}</td>
                {/* <td className="align-center">{i.end_date}</td> */}
                <td className="align-center form-text-trasformation-uppercase">{i.job_type}</td>
                <td className="align-center">{i.location}</td>
                <td className="align-center form-text-trasformation-uppercase">{i.status}</td>
                {/* <td className="align-center">{i.status === 'active' ?
                //   <a href={`${FRONTEND_URL}/personaldetails/${i.id}/`} target="_blank" rel="noopener noreferrer">View</a>
                //   : "Freezed"}
                // </td> */}


                {/* <td className="align-center">{i.status === 'active' ?
                  <button
                    onClick={() => {
                      navigator.clipboard.writeText(`${FRONTEND_URL}/personaldetails/${i.id}/`);
                      alert("Link copied to clipboard!");
                    }}
                  >
                    Copy
                  </button>
                  : "Freezed"}
                </td> */}

                <td className="align-center" >{i.status === 'active' ?
                  <button
                    onClick={() => {
                      navigator.clipboard.writeText(`${FRONTEND_URL}/personaldetails/${i.id}/`);
                      const notification = document.createElement("div");
                      notification.classList.add("notification");
                      notification.textContent = "Link copied to clipboard!";
                      document.body.appendChild(notification);
                      setTimeout(() => {
                        document.body.removeChild(notification);
                      }, 2000); // Remove notification after 2 seconds
                    }}
                    style={{ color: 'blue' }}
                  >
                    Link
                  </button>
                  : "Freezed"}
                </td>
                {toggleState === "null" ? (<td className="align-right ">
                  <Applicationupdate designationname={designationname} departmentname={departmentname} getJobpositionlist={getJobpositionlist} status={toggleState} i={i} />

                  {/* <ApplicationDelete getJobpositionlist={getJobpositionlist} i={i} /> */}
                </td>) : ""}
              </tr>
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default Posting
