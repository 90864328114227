import React, { useState, useEffect } from "react";
import { BASE_URL } from "../../config/axios"
import axios from "axios";
import { formattedDateLong } from "../Date";

const Personaldetails = () => {
  const [formData, setFormData] = useState({})

  const [edudata, setEduData] = useState([])
  const [workData, setWorkData] = useState([])
  const [emgData, setemgData] = useState([])
  const [perData, setPerData] = useState([])


  useEffect(() => {

    const getUSerDetails = async () => {
      try {
        const res = await axios.get(`${BASE_URL}/userdata/userdataget/${sessionStorage.getItem("email")}/`);

        const resedu = await axios.get(`${BASE_URL}/userdata/EmployeeEducationDetailsallget/${sessionStorage.getItem("email")}/`);

        const work = await axios.get(`${BASE_URL}/userdata/userworkexpallget/${sessionStorage.getItem("email")}/`);
        const Emg = await axios.get(`${BASE_URL}/userdata/userEmergencyContactallget/${sessionStorage.getItem("email")}/`);
        const per = await axios.get(`${BASE_URL}/wfm/ourcompanydetailsbyemp/${sessionStorage.getItem("emp_code")}/`);

        setFormData(res.data);
        setEduData(resedu.data);
        setWorkData(work.data)
        setemgData(Emg.data)
        setPerData(per.data)

      } catch (err) {
        // alert(err.message);
      }
    };

    getUSerDetails();
  }, []);


  console.log(formData)



  return (
    <div>
      <div className="main-div-personaldetial">
        <div className="presonal-info">
          <div className="personal-detail">
            <h3 className="personal-detail-heading">Primary Details</h3>
            <table>
              <tr>
                <td className="personal-detail-1">Name</td>
                <th className="personal-detail-2">{formData.name}</th>
              </tr>
              <tr>
                <td className="personal-detail-1">Marital Status</td>
                <th className="personal-detail-2">{formData.married_status}</th>
              </tr>
              <tr>
                <td className="personal-detail-1">Date of Birth</td>
                <th className="personal-detail-2">{formattedDateLong(formData.dob)}</th>
              </tr>
              <tr>
                <td className="personal-detail-1">Blood Group</td>
                <th className="personal-detail-2">{formData.blood_group}</th>
              </tr>
              <tr>
                <td className="personal-detail-1">Birth Mark</td>
                <th className="personal-detail-2">{formData.birth_mark}</th>
              </tr>

            </table>
          </div>

          <div className="contact-details">
            <h3 className="personal-detail-heading">Offical Details</h3>
            <tr>
              <td className="personal-detail-1">Designation</td>
              <th className="personal-detail-2">{perData.designation_name} </th>
            </tr>
            <tr>
              <td className="personal-detail-1">Empolyee Role</td>
              <th className="personal-detail-2">{perData.employee_type}</th>
            </tr>
            <tr>
              <td className="personal-detail-1">Joining Date</td>
              <th className="personal-detail-2">{formattedDateLong(perData.joining_date)}</th>
            </tr>
          </div>
        </div>

        <div className="address-details">
          <h3 className="personal-detail-heading">Address Details</h3>
          <div className="address-details-1 ">
            <div className="current-address-margin">
              <span className="current-address">
                <h5>Current Address</h5>
                <p>
                  {formData.current_address}
                </p>
              </span>
            </div>
            <div>
              <span className="current-address">
                <h5>Permanent Address</h5>
                <p>
                  {formData.permanent_address}
                </p>
              </span>
            </div>
          </div>
        </div>

        <div className="Education-details">
          <h3 className="personal-detail-heading">Education Details</h3>
          <div className="education-info">
            {edudata.map((e, index) => {
              return (
                <div className="education-info-1">
                  <h3 className="experiance-details">Education Details {index + 1}</h3>
                  <table>
                    <tr>
                      <td className="personal-detail-1">College</td>
                      <th className="personal-detail-2">{e.college}</th>
                    </tr>
                    <tr>
                      <td className="personal-detail-1">Course</td>
                      <th className="personal-detail-2">{e.department}</th>
                    </tr>
                    <tr>
                      <td className="personal-detail-1">Grade</td>
                      <th className="personal-detail-2">{e.grade}</th>
                    </tr>
                    <tr>
                      <td className="personal-detail-1">From Year</td>
                      <th className="personal-detail-2">{formattedDateLong(e.startDate)}</th>
                    </tr>
                    <tr>
                      <td className="personal-detail-1">End Year</td>
                      <th className="personal-detail-2">{formattedDateLong(e.endDate)}</th>
                    </tr>
                  </table>
                </div>
              )
            })}


          </div>
        </div>

        <div className="Education-details Experience Detials">
          <h3 className="personal-detail-heading">Experience Details</h3>
          <div className="education-info">
            {workData.map((a, index) => {
              return (
                <div className="education-info-1">
                  <h3 className="experiance-details">Company {index + 1}</h3>
                  <table>
                    <tr>
                      <td className="personal-detail-1">Company Name</td>
                      <th className="personal-detail-2">{a.company}</th>
                    </tr>
                    <tr>
                      <td className="personal-detail-1">Designation</td>
                      <th className="personal-detail-2">{a.jobposition}</th>
                    </tr>
                    <tr>
                      <td className="personal-detail-1">Job position</td>
                      <th className="personal-detail-2">{a.jobtype}</th>
                    </tr>
                    <tr>
                      <td className="personal-detail-1">Duration</td>
                      <th className="personal-detail-2">{formattedDateLong(a.startDate)}
                        {' '}to <br /> {formattedDateLong(a.endDate)}</th>
                    </tr>
                    <tr>
                      <td className="personal-detail-1">Monthly CTC</td>
                      <th className="personal-detail-2"> {a.monthlyCtc}</th>
                    </tr>
                    <tr>
                      <td className="personal-detail-1">Monthly in hand</td>
                      <th className="personal-detail-2">{a.monthlyInhand}</th>
                    </tr>
                  </table>
                </div>
              )
            })}


          </div>
        </div>

        <div className="Education-details Experience Detials">
          <h3 className="personal-detail-heading">Emergency Details</h3>
          <div className="education-info">
            {emgData.map((a, index) => {
              return (
                <div className="education-info-1">
                  <h3 className="experiance-details">Contact Person Detail {index + 1}</h3>
                  <table>
                    <tr>
                      <td className="personal-detail-1">Name</td>
                      <th className="personal-detail-2">{a.person_name}</th>
                    </tr>
                    <tr>
                      <td className="personal-detail-1">Relation</td>
                      <th className="personal-detail-2">{a.relation}</th>
                    </tr>
                    <tr>
                      <td className="personal-detail-1">Number</td>
                      <th className="personal-detail-2">{a.phone_number}</th>
                    </tr>
                  </table>
                </div>
              )
            })}


          </div>
        </div>
      </div>
    </div>
  );
};

export default Personaldetails;
