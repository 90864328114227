import React, { useState, useEffect } from "react";
import { formattedDate } from "../../Date";
import axios from "axios";
import { BASE_URL } from "../../../config/axios";

const AssignTask = ({ handleClose }) => {
  const [loading, setLoading] = useState(false); //loading logic

  // *********** Employee Code Api Start ***********
  const [emp_code, setEmp_code] = useState([]);
  useEffect(() => {
    const getAllEmp_code = async () => {
      try {
        const res = await axios.get(`${BASE_URL}/wfm/ourcompanyempdetailsactive/`);
        setEmp_code(res.data)
      } catch (err) {
        // alert(err.message);
      }
    };

    getAllEmp_code();
  }, []);


  const [formData, setFormData] = useState({
    task: "",
    assignedby: sessionStorage.getItem("emp_code"),
    assigndate: formattedDate,
    concern_person: "",
    status: "pending",
    priority: "",
    due_date: "",
    assignedtocc: [sessionStorage.getItem("emp_code")],
  });


  //******************Valedation Start********************* */

  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});
  const [priority, setPriority] = useState("3");

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = ["task", "due_date", "concern_person"];
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });

    setFormData({
      ...formData,
      [name]: value,
    });
  };


  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); //loading logic
    const updatedFormData = {
      ...formData,
      priority: priority,
    };
    if (validateForm()) {
      try {
        let res = await axios.post(`${BASE_URL}/wfm/tasks/`, updatedFormData,);

        if (res.status === 200) {
          handleClose()
        } else {
          alert(res);
        }
      } catch (err) {
        alert(err);
      }finally {
        setLoading(false); //loading logic
      }
    }
  };


  return (
    <div>
      <form>
        <div className="flex-column">
          <label className="form-labels">Task</label>
          <textarea
            placeholder="Task Description"
            type="text"
            name="task"
            onChange={handleInputChange}
            value={formData.task}
            className={` form-input-textarea form-input form-input-background ${errors.task ? "error" : inputState.task ? "success" : ""
              }`}
          // value={dataa.text}
          // onChange={(e) => {
          //   if (e.target.value.length <= 50) {
          //     setdataa({ ...dataa, remarks: e.target.value });
          //     setRemarksError(""); // Clear error message if within the limit
          //   } else {
          //     setRemarksError(
          //       "Ensure this field has no more than 50 characters."
          //     );
          //   }
          // }}
          />
        </div>

        <div className="flex-column">
          <label htmlFor="date" className="form-labels">
            Deadline Date
          </label>
          <input
            id="date"
            type="date"
            name="due_date"

            onChange={handleInputChange}
            value={formData.due_date}
            className={`form-input form-input-background ${errors.due_date ? "error" : inputState.due_date ? "success" : ""
              }`}

          />
        </div>

        <div className="leave-duration-heading">Priority </div>
        <div value={priority} onChange={(e) => setPriority(e.target.value)} className="leave-radio-button">
          <div className="leave-radio-button-inner-div">
            <input
              name="priority"
              type="radio"
              value="3"
              checked={priority === "3"}
              className="age-input"
            />
            <label className="age-label">Low</label></div>
          <div className="leave-radio-button-inner-div">
            <input
              name="priority"
              type="radio"
              value="2"
              checked={priority === "2"}

              className="age-input"
            />
            <label className="age-label">Medium</label>
          </div>
          <div className="leave-radio-button-inner-div">

            <input
              name="priority"
              type="radio"
              value="1"
              checked={priority === "1"}

              className="age-input"
            />
            <label className="age-label">High</label></div>
          {/* <div className="leave-radio-button-inner-div">
            <input
              name="age"
              type="radio"
              value="shortleave"
              className="age-input"
            />
            <label className="age-label">Short Day</label></div> */}
        </div>

        <div className="flex-column form-group-select">
          <label className="form-labels">
            Assign To<span className="required">*</span>
          </label>
          <br />
          <select
            name="concern_person"
            onChange={handleInputChange}
            value={formData.concern_person}
            className={`form-input form-group-selection form-input-background ${errors.concern_person ? "error" : inputState.concern_person ? "success" : ""
              }`}>
            <option value="">Select Employee</option>

            {emp_code.sort((a, b) => a.emp_code - b.emp_code).map((i, index) => (<option value={i.emp_code}>{i.emp_code} - {i.name}</option>))};
          </select>
          <div className="form-group-selection-arrow">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="25"
              fill="currentColor"
              class="bi bi-caret-down-fill"
              viewBox="0 0 16 16"
            >
              <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
            </svg>
          </div>
        </div>


        <div className="button-models">
          <button className="model-button ">Cancel</button>
          <button className="model-button model-button-leave" onClick={handleFormSubmit} disabled={loading} >{/* //loading logic */}
            Assign
          </button>
        </div>
      </form>
    </div>
  );
};

export default AssignTask;
