import axios from "axios";
import { BASE_URL } from "../../config/axios";
import React, { useState, useEffect } from 'react';
import { formattedDateLong } from "../Date";

const Performance = () => {

  const [performanceName, setPreformanceName] = useState([])

  const getperformancename = async () => {
    try {
      const res = await axios.get(`${BASE_URL}/wfm/performancebyemployee/${sessionStorage.getItem("emp_code")}`);
      setPreformanceName(res.data);
    } catch (err) {
      // alert(err.message);
    }
  };


  useEffect(() => {
    getperformancename();
  }, []);


  return (
    <div className='performance-table-cont'>
      <div className="table-mainheading">
        <div>Total Awards({performanceName.length})</div>
      </div>
      <div className="awards-main-cont">
        {performanceName.map((item) => {
          return (
            <>
              <div className="awards-card">
                <div className="primary-subcard">
                  <img src={item.profilepic} alt="Profile" className="performance-profile-img" />
                  <img src={item.image_reward} alt="Badge" className="performance-reward-img" />
                </div>
                <div className="secondary-subcard">
                  <div className="top-details">
                    <div className='hr-sep' />
                    <div className="award-title">{item.title}</div>
                    <div className='hr-sep' />
                  </div>
                  <div className="middle-details">
                    <div className='awards-cards-heading'>Reason for recognition</div>
                    <div className='awards-cards-data'>{item.reason_for_recogntion}</div>
                  </div>
                  <div className="bottom-details">
                    <div className="bottom-subcards">
                      <div className='awards-cards-heading'>Recognized by</div>
                      <div className='awards-cards-data'>{item.recognized_by}</div>
                    </div>
                    <div className="bottom-subcards">
                      <div className='awards-cards-heading'>Date</div>
                      <div className='awards-cards-data'> {formattedDateLong(item.date)}</div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )
        })}
      </div>
    </div>
  )
}

export default Performance;