import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import { BASE_URL } from "../../../config/axios";
import Select from 'react-select';

const ImpresetAdd = ({ getImpreset }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);



  const [formData, setFormData] = useState({
    date: "",
    empcode: sessionStorage.getItem("emp_code"),
    project_name: "",
    transportation: "",
    accomodation: "",
    fooding: "",
    other: "",
    no_of_days: "",
    person: "",
    remarks: ""
  });


  console.log(formData)
  //******************Valedation Start********************* */

  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = ["date"];
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  //******************Valedation End*********************** */



  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const updatedFormData = {
      ...formData,
      person: formData.multipleperson.length
    }

    if (validateForm()) {
      try {
        let res = await axios.post(`${BASE_URL}/wfm/impreset/`, updatedFormData);

        if (res.status === 200) {
          await getImpreset();
          setShow(false);
        } else {
          alert(res);
        }
      } catch (err) {
        alert(err);
      }
    }
  };


  useEffect(() => {
    getAllempdata();
  }, []);
  const [allemployeedata, setAllemployee] = useState([]);

  const getAllempdata = async () => {
    try {
      const res = await axios.get(`${BASE_URL}/wfm/ourcompanyempdetailsactive/`);
      setAllemployee(res.data);
    } catch (err) {
      alert(err.message);
    }
  };



  const options = allemployeedata
    .sort((a, b) => parseInt(a.emp_code) - parseInt(b.emp_code))
    .map((e) => ({
      value: e.emp_code,
      label: `${e.emp_code} - ${e.name}`,
    }));

  const handleChangecc = (selected) => {
    const multipleperson = selected.length > 0 ? selected.map((item) => item.value) : "";
    setFormData((prevState) => ({
      ...prevState,
      multipleperson,

    }));
  };



  return (
    <>
      <button className="models-button model-add" onClick={handleShow}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <g clip-path="url(#clip0_1415_3177)">
            <path
              d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM17 13H13V17H11V13H7V11H11V7H13V11H17V13Z"
              fill="#2576BC"
            />
          </g>
          <defs>
            <clipPath id="clip0_1415_3177">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="request-leave">
        {/* <Modal.Header closeButton>
          <Modal.Title>Request Leave</Modal.Title>
        </Modal.Header> */}
        <Modal.Body>
          <div className="parent-div">
            <div className="bdy-div">
              <h6 className="announce-heading">Imprest Request</h6>
              <div className="flex-column">
                <label htmlFor="date" className="form-labels announce-date">
                  Date<span className="required">*</span>
                </label>
                <input
                  id="date"
                  type="date"
                  name='date'
                  onChange={handleInputChange}
                  value={formData.date}
                  className={`form-input ${errors.date ? "error" : inputState.date ? "success" : ""
                    }`}
                />
                {errors.date && (
                  <span className="error-message">{errors.date}</span>
                )}
              </div>

              <div className="flex-column">
                <label htmlFor="project_name" className="form-labels announce-date">
                  Project Code & Name
                </label>
                <input
                  id="project_name"
                  type="text"
                  name="project_name"
                  placeholder="Project Code & Name"
                  onChange={handleInputChange}
                  value={formData.project_name}
                  className={`form-input ${errors.project_name ? "error" : inputState.project_name ? "success" : ""
                    }`}
                />
                {errors.project_name && (
                  <span className="error-message">{errors.project_name}</span>
                )}
              </div>

              <div className="flex-column">
                <label htmlFor="date" className="form-labels announce-date">
                  Total Expense
                </label>
                <input
                  id="date"
                  type="number"
                  placeholder="5000"

                  value={Number(formData.transportation) + Number(formData.accomodation) + Number(formData.fooding) + Number(formData.other)}

                  readOnly
                  className="form-input"
                />
              </div>

              <div className="flex-column">
                <label htmlFor="transportation" className="form-labels announce-date">
                  Transportation
                </label>
                <input
                  id="transportation"
                  type="number"
                  name="transportation"
                  placeholder="5000"
                  onChange={handleInputChange}
                  value={formData.transportation}
                  className={`form-input ${errors.transportation ? "error" : inputState.transportation ? "success" : ""
                    }`}
                />
                {errors.transportation && (
                  <span className="error-message">{errors.transportation}</span>
                )}
              </div>

              <div className="flex-column">
                <label htmlFor="accomodation" className="form-labels announce-date">
                  Accommodation
                </label>
                <input
                  id="accomodation"
                  type="number"
                  name="accomodation"
                  placeholder="5000"
                  onChange={handleInputChange}
                  value={formData.accomodation}
                  className={`form-input ${errors.accomodation ? "error" : inputState.accomodation ? "success" : ""
                    }`}
                />
                {errors.accomodation && (
                  <span className="error-message">{errors.accomodation}</span>
                )}
              </div>

              <div className="flex-column">
                <label htmlFor="fooding" className="form-labels announce-date">
                  Food
                </label>
                <input
                  id="fooding"
                  type="number"
                  name="fooding"
                  placeholder="5000"
                  onChange={handleInputChange}
                  value={formData.fooding}
                  className={`form-input ${errors.fooding ? "error" : inputState.fooding ? "success" : ""
                    }`}
                />
                {errors.fooding && (
                  <span className="error-message">{errors.fooding}</span>
                )}
              </div>

              <div className="flex-column">
                <label htmlFor="other" className="form-labels announce-date">
                  Others
                </label>
                <input
                  id="other"
                  type="number"
                  name="other"
                  placeholder="other expanse"
                  onChange={handleInputChange}
                  value={formData.other}
                  className={`form-input ${errors.other ? "error" : inputState.other ? "success" : ""
                    }`}
                />
                {errors.other && (
                  <span className="error-message">{errors.other}</span>
                )}
              </div>

              <div className="flex-column">
                <label htmlFor="no_of_days" className="form-labels announce-date">
                  Number's of days
                </label>
                <input
                  id="no_of_days"
                  type="number"
                  placeholder="Days"
                  name="no_of_days"
                  onChange={handleInputChange}
                  value={formData.no_of_days}
                  className={`form-input ${errors.no_of_days ? "error" : inputState.no_of_days ? "success" : ""
                    }`}
                />
                {errors.no_of_days && (
                  <span className="error-message">{errors.no_of_days}</span>
                )}
              </div>

              <div className="flex-column">
                <label htmlFor="person" className="form-labels announce-date">
                  Number's of Person
                </label>
                <input
                  id="person"
                  type="number"
                  name="person"
                  // onChange={handleInputChange}
                  readOnly

                  value={formData.multipleperson?.length}
                  className={`form-input ${errors.person ? "error" : inputState.person ? "success" : ""
                    }`}
                />
                {errors.person && (
                  <span className="error-message">{errors.person}</span>
                )}
              </div>

              <div className="flex-column">
                <label htmlFor="remarks" className="form-labels announce-date">
                  Name of Person
                </label>

                <Select
                  options={options}
                  isMulti
                  onChange={handleChangecc}
                />
                {errors.remarks && (
                  <span className="error-message">{errors.remarks}</span>
                )}
              </div>

              <div className="flex-column">
                <label htmlFor="remarks" className="form-labels announce-date">
                  Remarks
                </label>
                <textarea
                  id="remarks"
                  type="text"
                  name="remarks"
                  placeholder="Your feedback"
                  onChange={handleInputChange}
                  // maxLength={200}
                  value={formData.remarks}
                  className={`form-input-textarea ${errors.remarks ? "error" : inputState.remarks ? "success" : ""
                    }`}
                />
                {errors.remarks && (
                  <span className="error-message">{errors.remarks}</span>
                )}
              </div>




              <div className="button-models">
                <button className="model-button" onClick={handleClose}>
                  Cancel
                </button>
                <button
                  type="submit"
                  className="model-button model-button-leave"
                  onClick={handleFormSubmit}
                >
                  Add
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
const DocumentAdd = ({ getAnnouncement }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const today = new Date();
  const year = today.getFullYear();
  const month = today.getMonth() + 1;
  const day = today.getDate();
  const formattedDate = `${year}-${month.toString().padStart(2, "0")}-${day
    .toString()
    .padStart(2, "0")}`;

  const [formData, setFormData] = useState({
    user: sessionStorage.getItem("email"),
    document_name: "",
    document_type: "",
    document_file: "",

  });

  // !  ************** Validation start **************  ! //

  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = ["document_name", "document_type", "document_file"];
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  // ?  ************** Validation End **************  ! //
  const [loading, setLoading] = useState(false); //loading logic

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      setLoading(true); //loading logic
      const formDataa = new FormData();
      formDataa.append("user", formData.user);

      formDataa.append("document_name", formData.document_name);
      formDataa.append("document_type", formData.document_type);
      formDataa.append("document_file", formData.document_file);
      try {
        let res = await axios.post(`${BASE_URL}/userdata/userdocs/`, formDataa, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        if (res.status === 200) {
          handleClose();
        } else {
          alert(res);
        }
      } catch (err) {
        alert(err);
      } finally {
        setLoading(false); // Ensure loading state is stopped even in case of error //loading logic
      }
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFileChange = (e) => {
    const { name } = e.target;
    const file = e.target.files[0];

    if (file) {
      if (file.type === "application/pdf") {
        setFormData({
          ...formData,
          [name]: file,
        });
      } else {
        setErrors({
          ...errors,
          [name]: "Invalid file type. Please select a PDF file.",
        });
      }
    } else {
      setFormData({
        ...formData,
        [name]: null,
      });
    }
  };

  const handleFileClear = (name) => {
    setFormData({
      ...formData,
      [name]: null,
    });
  };

  return (
    <>
      <button className="models-button model-add" onClick={handleShow} >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <g clip-path="url(#clip0_1415_3177)">
            <path
              d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM17 13H13V17H11V13H7V11H11V7H13V11H17V13Z"
              fill="#2576BC"
            />
          </g>
          <defs>
            <clipPath id="clip0_1415_3177">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="request-leave">
        {/* <Modal.Header closeButton>
          <Modal.Title>Request Leave</Modal.Title>
        </Modal.Header> */}
        <Modal.Body>
          <div className="parent-div">
            <div className="bdy-div">
              <h6 className="announce-heading">Document</h6>

              <form>
                <div className="flex-column">
                  <label htmlFor="document_name" className="form-labels announce-date">
                    Document Name
                  </label>
                  <input
                    id="document_name"
                    type="document_name"
                    name="document_name"
                    placeholder="Document Name"
                    onChange={handleInputChange}
                    value={formData.document_name}
                    className={`form-input ${errors.document_name ? "error" : inputState.document_name ? "success" : ""
                      }`}
                  />
                  {errors.document_name && (
                    <span className="error-message">{errors.document_name}</span>
                  )}
                </div>

                <div className="flex-column form-group-selectt">
                  <label htmlFor="document_type" className="form-labels">
                    Document Type<span className="required">*</span>
                  </label>
                  <br />
                  <select
                    id="document_type"
                    name="document_type"
                    value={formData.document_type}
                    onChange={handleInputChange}
                    className={`form-input form-group-selection ${errors.document_type ? "error" : inputState.document_type ? "success" : ""
                      }`}
                  >
                    <option value="">Select a Document Type</option>
                    <option value="identity">Identity</option>
                    {/* {sessionStorage.getItem('role') === 'HR' ? <option value="employeeLetter">Employee Letter</option> : ""} */}
                    <option value="previousExperience">Previous Experience</option>
                    <option value="degree">Degree</option>


                  </select>
                  <div className="form-group-selection-arrow">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="25"
                      fill="currentColor"
                      class="bi bi-caret-down-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                    </svg>
                  </div>
                  <div>
                    {errors.document_type && (
                      <span className="error-message">{errors.document_type}</span>
                    )}
                  </div>
                </div>



                <div className="flex-column">
                  <label htmlFor="attachment" className="form-labels announce-date">
                    Attachment
                  </label>
                  <input
                    id="file"
                    type="file"
                    name="document_file"
                    accept=".pdf"
                    onChange={handleFileChange}
                    className="file-input"
                  />

                  {formData.document_file ? (
                    <span
                      className="file-clear"
                      onClick={() => handleFileClear("file")}
                    >
                      Clear
                    </span>
                  ) : null}
                </div>
              </form>
              <div className="button-models">
                <button className="model-button" onClick={handleClose}>
                  Cancel
                </button>
                <button
                  type="submit"
                  className="model-button model-button-leave"
                  onClick={handleSubmit}
                  disabled={loading}
                >
                  Adds
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

const EmployeeDocumentAdd = ({ getAnnouncement, email }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const today = new Date();
  const year = today.getFullYear();
  const month = today.getMonth() + 1;
  const day = today.getDate();
  const formattedDate = `${year}-${month.toString().padStart(2, "0")}-${day
    .toString()
    .padStart(2, "0")}`;

  const [formData, setFormData] = useState({
    user: email,
    document_name: "",
    document_type: "",
    document_file: "",

  });

  // !  ************** Validation start **************  ! //

  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = ["document_name", "document_type", "document_file"];
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  // ?  ************** Validation End **************  ! //
  const [loading, setLoading] = useState(false); //loading logic

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      setLoading(true); //loading logic
      const formDataa = new FormData();
      formDataa.append("user", formData.user);

      formDataa.append("document_name", formData.document_name);
      formDataa.append("document_type", formData.document_type);
      formDataa.append("document_file", formData.document_file);
      try {
        let res = await axios.post(`${BASE_URL}/userdata/userdocs/`, formDataa, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        if (res.status === 200) {
          handleClose();
        } else {
          alert(res);
        }
      } catch (err) {
        alert(err);
      } finally {
        setLoading(false); // Ensure loading state is stopped even in case of error //loading logic
      }
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFileChange = (e) => {
    const { name } = e.target;
    const file = e.target.files[0];

    if (file) {
      if (file.type === "application/pdf") {
        setFormData({
          ...formData,
          [name]: file,
        });
      } else {
        setErrors({
          ...errors,
          [name]: "Invalid file type. Please select a PDF file.",
        });
      }
    } else {
      setFormData({
        ...formData,
        [name]: null,
      });
    }
  };

  const handleFileClear = (name) => {
    setFormData({
      ...formData,
      [name]: null,
    });
  };

  return (
    <>
      <button className="models-button model-add" onClick={handleShow} >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <g clip-path="url(#clip0_1415_3177)">
            <path
              d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM17 13H13V17H11V13H7V11H11V7H13V11H17V13Z"
              fill="#2576BC"
            />
          </g>
          <defs>
            <clipPath id="clip0_1415_3177">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="request-leave">
        {/* <Modal.Header closeButton>
          <Modal.Title>Request Leave</Modal.Title>
        </Modal.Header> */}
        <Modal.Body>
          <div className="parent-div">
            <div className="bdy-div">
              <h6 className="announce-heading">Document</h6>

              <form>
                <div className="flex-column">
                  <label htmlFor="document_name" className="form-labels announce-date">
                    Document Name
                  </label>
                  <input
                    id="document_name"
                    type="document_name"
                    name="document_name"
                    placeholder="Document Name"
                    onChange={handleInputChange}
                    value={formData.document_name}
                    className={`form-input ${errors.document_name ? "error" : inputState.document_name ? "success" : ""
                      }`}
                  />
                  {errors.document_name && (
                    <span className="error-message">{errors.document_name}</span>
                  )}
                </div>

                <div className="flex-column form-group-selectt">
                  <label htmlFor="document_type" className="form-labels">
                    Document Type<span className="required">*</span>
                  </label>
                  <br />
                  <select
                    id="document_type"
                    name="document_type"
                    value={formData.document_type}
                    onChange={handleInputChange}
                    className={`form-input form-group-selection ${errors.document_type ? "error" : inputState.document_type ? "success" : ""
                      }`}
                  >
                    <option value="">Select a Document Type</option>
                    <option value="identity">Identity</option>
                    {sessionStorage.getItem('role') === 'HR' ? <option value="employeeLetter">Employee Letter</option> : ""}
                    <option value="previousExperience">Previous Experience</option>
                    <option value="degree">Degree</option>


                  </select>
                  <div className="form-group-selection-arrow">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="25"
                      fill="currentColor"
                      class="bi bi-caret-down-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                    </svg>
                  </div>
                  <div>
                    {errors.document_type && (
                      <span className="error-message">{errors.document_type}</span>
                    )}
                  </div>
                </div>



                <div className="flex-column">
                  <label htmlFor="attachment" className="form-labels announce-date">
                    Attachment
                  </label>
                  <input
                    id="file"
                    type="file"
                    name="document_file"
                    accept=".pdf"
                    onChange={handleFileChange}
                    className="file-input"
                  />

                  {formData.document_file ? (
                    <span
                      className="file-clear"
                      onClick={() => handleFileClear("file")}
                    >
                      Clear
                    </span>
                  ) : null}
                </div>
              </form>
              <div className="button-models">
                <button className="model-button" onClick={handleClose}>
                  Cancel
                </button>
                <button
                  type="submit"
                  className="model-button model-button-leave"
                  onClick={handleSubmit}
                  disabled={loading}
                >
                  Adds
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export { ImpresetAdd, DocumentAdd, EmployeeDocumentAdd };
