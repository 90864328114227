import React, { useEffect, useState } from 'react'
import {Event, EventUpdate, EventDelete } from './model/Event';
import { BASE_URL } from "./../../config/axios";
import axios from "axios"

function Eventtables() {
  const [eventename, setNamename] = useState([]);

  const getevent = async () => {
    try {
      const res = await axios.get(`${BASE_URL}/settings/eventAPI/`);
      setNamename(res.data);
    } catch (err) {
      // alert(err.message);
    }
  };

  useEffect(() => {
    getevent();
  }, []);

  return (
    <div className="custom-table-background">
    <div className="table-heading-flex">
          <div className="repo-heading">Event</div>
          <div>
            < Event getevent={getevent} />
          </div>
        </div>
    <table className="custom-table">
      <thead>
        <tr className="custom-table-head-tr">
          <th className="align-left">Sr no.</th>
          <th className="align-center">Event image </th>
          <th className="align-center">Date</th>
          <th className="align-center">Title</th>
          <th className="align-center">Description</th>
          <th className="align-right">Action</th>
        </tr>
      </thead>
      <tbody>
        {eventename.map((i, index) => (
          <React.Fragment key={index}>
            <tr className="tr-border-bottom">
              <td colSpan="6"></td>
            </tr>
            <tr className="custom-table-head-td">
              <td className="align-left">{index+1}</td>
              <td className="align-center"><img className='img-form' src={i.file} alt="" /></td>
              <td className="align-center">{i.date}</td>
              <td className="align-center">{i.title}</td>
              <td className="align-center">{i.description}</td>
              <td className="icon-right ">
              < EventUpdate i={i} getevent={getevent} />
              < EventDelete i={i} getevent={getevent} />
              </td>
            </tr>
          </React.Fragment>
        ))} 
      </tbody>
    </table>
  </div> 
  )
}

export default Eventtables
