import axios from 'axios';
import { BASE_URL } from "../../config/axios";
import React, { useState, useEffect } from 'react';
import Calaneder from "../images/desk.svg";


// Job Openings
const Newemployee = () => {
  const [newEmployee, setNewEmployee] = useState([]);

  useEffect(() => {
      const getNewEmployee = async () => {
          try {
              const res = await axios.get(`${BASE_URL}/attandance/jobopening/`);
              setNewEmployee(res.data);
          } catch (err) {
              // IMPORTANT: TURN THE BELOW COMMENT OFF!!!
              // alert(err.message);
          }
      };
      getNewEmployee();
  }, []);

  const createMarkup = (content) => {
      const linkRegex = /(?:https?|ftp):\/\/[^\s]+/g;
      return {
          __html: content.replace(linkRegex, (url) => {
              return `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`;
          }),
      };
  };


  return (
      <div className="dashcomp-bg-cards" >

          {newEmployee.map((data, index) => {
              const formattedDate = new Date(data.date).toLocaleString('en-US', {
                  month: 'long',
                  day: 'numeric',
                  year: 'numeric',
              });
              return (
              <>
              <div className="EmployeeDashboad-notification-card">
                  <h3 className='dashcomp-announcement-heading'>{index + 1}.{"  "}{data.title}</h3>
                  <span className="dashcomp-announcement-date">
                      {/* <img src={} alt="Desk Icon"
                          style={{ color: 'red', marginRight: '8px',
                          width: '16px', height: '16px', marginTop: "-4px" }}
                      /> */}
                      {formattedDate}
                  </span>
                  <p className="dashcomp-announcement-p">
                      <div dangerouslySetInnerHTML={createMarkup(data.description)} />
                  </p>
              </div>
              </>
              )
          })
          }
      </div>
  )
}


// Main Component
const NewEmployee = () => {

    const [anniversary, setAnniversary] = useState([]);
    // const [selectedImage, setSelectedImage] = useState('');

    useEffect(() => {
        const getBirthdayData = async () => {
            try {
                const res = await axios.get(`${BASE_URL}/wfm/joiniesrh/${sessionStorage.getItem("rh")}`);
                setAnniversary(res.data);
            } catch (err) {
                console.error(err.message);
            }
        };
        getBirthdayData();
    }, []);

  return (
      <div className='backgroundmain'>
          <div className='dashboardcomponent'>
            <div className="task-heading">
              <div className='task-sub-heading'>New Joining Employee</div>
              {Newemployee}
            </div>
            <div className='birthdaywrap EmployeeDashboad-notification-cards EmployeeDashboad-notification-cards-wrap'>
            {anniversary.map((data, index) => {
                
                console.log(data)
                    const formattedEndDate = new Date(data.joining_date).toLocaleString('en-US', {
                        month: 'short',
                        day: 'numeric',
                        year: 'numeric',
                    });


                    return (
                    <>
                        {/* <Modal show={show} onHide={handleClose} dialogClassName="birthdayimagemodel" >
                            <Modal.Body>
                                <div style={{ background: randomBackground, backgroundRepeat: "no-repeat", backgroundSize: "contain" }} className='dashcomp-bgimagee' >
                                    <img src={selectedImage} alt="profile" className='dashcomp-imagee' />
                                </div>
                            </Modal.Body>
                        </Modal> */}
                        <div key={index} className="dashcomp-bg-card dashcomp-bg-card-styling">
                            <img src={`${data.profilepic}` ? `${data.profilepic}` : 'https://images.unsplash.com/photo-1511367461989-f85a21fda167?q=80&w=1931&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'}  alt="profile" className='dashcomp-image' 
                            // onClick={() => handleShow(data.profilepic)} 
                            />
                            {/* <img src={`${data.image_reward}` ? `${data.image_reward}` : 'https://images.unsplash.com/photo-1578269174936-2709b6aeb913?q=80&w=2071&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'}  alt="profile" className='dashcomp-image' 
                            // onClick={() => handleShow(data.profilepic)} 
                            /> */}
                            <h3 className='task-sub-heading' >Emp. Code: {data.emp_code}</h3>
                            <h3 className='dashcomp-announcement-heading' >{data.name}</h3>
                            {/* <h3 className='dashcomp-announcement-heading' >{data.title}</h3> */}
                            <span className="dashcomp-announcement-date">
                                <img src={Calaneder} alt="Desk Icon" style={{ color: 'red',
                                marginRight: '8px', width: '16px', height: '16px', marginTop: "-4px" }}
                                />
                                {formattedEndDate}
                            
                            </span>
                            <br/>
                            <p className="dashcomp-announcement-p">{data.title}</p>
                            <p className="dashcomp-announcement-p">{data.designation_name}</p>
                            <p className="dashcomp-announcement-p">{data.department_name}</p>
                        </div>
                    </>
                );
                })}
            </div>
          </div>
      </div>
  )
}

export default NewEmployee;