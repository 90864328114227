
import React, {useState, useEffect  } from "react";
import { AnnouncentAdd, AnnouncementUpdate, AnnouncementDelete } from "./model/Announcent";
import axios from "axios";
import { BASE_URL } from "../../config/axios";

const Announcetable = () => {

  const [annoucncementname, setAnnouncementname] = useState([]) 


  const getAnnouncement = async () => {
    try {
    const res = await axios.get(`${BASE_URL}/settings/informationboardAPI/`);
    setAnnouncementname(res.data)
  } catch (err) {
        // alert(err.message);
  }
};


useEffect(() => {
  getAnnouncement();
}, []);
   
  return ( 
    <>
    <div className="custom-table-background">
        <div className="table-heading-flex">
            <div className="repo-heading">Announcement</div>
               <div><AnnouncentAdd getAnnouncement={getAnnouncement}/></div>
        </div>
      <table className="custom-table">
        <thead>
          <tr className="custom-table-head-tr">
            <th className="align-left">Sr no.</th>
            <th className="align-center">Date</th>
            <th className="align-center">Title</th>
            <th className="align-center">Description</th>             
            <th className="align-center">Attachment</th>
            <th className="align-center">Action</th>

          </tr>
        </thead>
        <tbody>
          {annoucncementname.map((i, index) => (
            <React.Fragment key={index}>
              <tr className="tr-border-bottom">
                <td colSpan="6"></td>
              </tr>
              <tr className="custom-table-head-td">

                <td className="align-left">{index+1}</td>
                <td className="align-center">{i.date}</td>
                <td className="align-center">{i.title}</td>
                 <td className="align-center">{i.description}</td>
                <td className="align-center"><a href={i.file} target="blank" >View</a></td>
                <td className="align-right ">
                <AnnouncementUpdate i={i} getAnnouncement={getAnnouncement}/>                     
                <AnnouncementDelete i={i} getAnnouncement={getAnnouncement}/>
                </td>
              </tr>
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>    
    </>
  );
};



export default Announcetable;
