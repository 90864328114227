import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { BASE_URL } from '../../../config/axios';
import { formatDate, formattedDate, formattedDateLong } from '../../Date';

const DailyAttendance = () => {


  // *********** Employee Details Api Start ***********
  const [allattendance, setAttendnace] = useState([])
  const [filteredEmployee, setFilteredEmployee] = useState([]);
  const [status_list, setStatus_list] = useState([])

  const [date, setDate] = useState(`${formattedDate}`);


  const getAllAttendance = async () => {
    try {
      const res = await axios.get(`${BASE_URL}/wfm/reportinghead/${sessionStorage.getItem("email")}/${date}/`);
      setAttendnace(res.data)
      const uniqueDayStatus = [...new Set(res.data.map(entry => entry.day_status))];
      setStatus_list(uniqueDayStatus)
    } catch (err) {
      // alert(err.message);
    }
  };


  useEffect(() => {

    getAllAttendance();
  }, [date]);
  // *********** Employee Details Api End ***********


  // *********** Filter Logic ***********

  const [selectedStatus, setSelectedStatus] = useState('');
  const [selectedName, setName] = useState('');



  const applyFilters = () => {
    // Filter employees based on selectedStatus and selectedName
    let filteredData = allattendance;

    if (selectedStatus !== '') {
      filteredData = filteredData.filter(employee => employee.day_status === selectedStatus);
    }

    if (selectedName !== '') {
      // Use includes for a case-insensitive search
      filteredData = filteredData.filter(employee => employee.name.toLowerCase().includes(selectedName.toLowerCase()));
    }
    setFilteredEmployee(filteredData);

  };

  useEffect(() => {

    applyFilters();
  }, [selectedStatus, selectedName, allattendance]);

  return (
    <>

      {/* Attendance History Container */}
      <div className="attendance-history-cont">
        <div className="attendance-heading"></div>
        <div className="attendance-subcont">
          <div className="field-cont">

            <input className="attendance-input-field date-uppercase" placeholder='Date' type="date" value={date}
              onChange={(e) => setDate(e.target.value)}
            />
            <hr />
            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="38" viewBox="0 0 17 17" fill="none">
              <g clip-path="url(#clip0_650_3351)">
                <mask id="mask0_650_3351" maskUnits="userSpaceOnUse" x="0" y="0" width="17" height="17">
                  <path d="M17 0H0V17H17V0Z" fill="white" />
                </mask>
                <g mask="url(#mask0_650_3351)">
                  <path d="M8.50009 17.0057L8.00425 16.5807C7.32071 16.0083 1.35229 10.8538 1.35229 7.08334C1.35229 5.18763 2.10536 3.36956 3.44583 2.02909C4.78631 0.688616 6.60437 -0.0644531 8.50009 -0.0644531C10.3958 -0.0644531 12.2139 0.688616 13.5543 2.02909C14.8948 3.36956 15.6479 5.18763 15.6479 7.08334C15.6479 10.8538 9.67946 16.0083 8.99875 16.5835L8.50009 17.0057ZM8.50009 1.48113C7.0148 1.48282 5.59084 2.07359 4.54059 3.12384C3.49033 4.1741 2.89956 5.59806 2.89787 7.08334C2.89787 9.452 6.56987 13.236 8.50009 14.9678C10.4303 13.2352 14.1023 9.44917 14.1023 7.08334C14.1006 5.59806 13.5098 4.1741 12.4596 3.12384C11.4093 2.07359 9.98537 1.48282 8.50009 1.48113Z" fill="#707070" />
                  <path d="M8.50008 9.91666C7.9397 9.91666 7.39191 9.7505 6.92597 9.43917C6.46003 9.12784 6.09687 8.68533 5.88242 8.16761C5.66798 7.64988 5.61186 7.08019 5.7212 6.53058C5.83052 5.98097 6.10036 5.47612 6.49662 5.07986C6.89286 4.68362 7.39771 4.41376 7.94734 4.30444C8.49695 4.19512 9.06663 4.25123 9.58436 4.46568C10.1021 4.68013 10.5446 5.04329 10.8559 5.50922C11.1672 5.97516 11.3334 6.52295 11.3334 7.08334C11.3334 7.83478 11.0349 8.55545 10.5036 9.0868C9.97221 9.61816 9.25154 9.91666 8.50008 9.91666ZM8.50008 5.66666C8.2199 5.66666 7.946 5.74975 7.71302 5.90542C7.48006 6.06109 7.29848 6.28234 7.19125 6.5412C7.08404 6.80007 7.05597 7.08491 7.11063 7.35972C7.1653 7.63452 7.30022 7.88694 7.49835 8.08507C7.69648 8.2832 7.9489 8.41811 8.2237 8.47279C8.49851 8.52745 8.78336 8.49939 9.04221 8.39217C9.30108 8.28494 9.52233 8.10337 9.678 7.8704C9.83367 7.63742 9.91675 7.36353 9.91675 7.08334C9.91675 6.70761 9.7675 6.34728 9.50181 6.08161C9.23614 5.81592 8.87581 5.66666 8.50008 5.66666Z" fill="#707070" />
                </g>
              </g>
              <defs>
                <clipPath id="clip0_650_3351">
                  <rect width="17" height="17" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <select className="attendance-input-field date-field" type="text"
              value={selectedStatus}
              onChange={(e) => setSelectedStatus(e.target.value)}
            >
              <option value="">All</option>
              {status_list.map(status => (<option value={status}>{status}</option>))};




            </select>
            <hr />
            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 14 14" fill="none">
              <g clip-path="url(#clip0_650_3324)">
                <path d="M2.625 10.2083H1.16667C0.857247 10.2083 0.560501 10.3312 0.341709 10.55C0.122916 10.7688 0 11.0655 0 11.3749L0 12.8333C0 13.1427 0.122916 13.4394 0.341709 13.6582C0.560501 13.877 0.857247 13.9999 1.16667 13.9999H2.625C2.93442 13.9999 3.23117 13.877 3.44996 13.6582C3.66875 13.4394 3.79167 13.1427 3.79167 12.8333V11.3749C3.79167 11.0655 3.66875 10.7688 3.44996 10.55C3.23117 10.3312 2.93442 10.2083 2.625 10.2083ZM2.625 12.8333H1.16667V11.3749H2.625V12.8333Z" fill="#707070" />
                <path d="M12.8333 10.2083H11.3749C11.0655 10.2083 10.7688 10.3312 10.55 10.55C10.3312 10.7688 10.2083 11.0655 10.2083 11.3749V12.8333C10.2083 13.1427 10.3312 13.4394 10.55 13.6582C10.7688 13.877 11.0655 13.9999 11.3749 13.9999H12.8333C13.1427 13.9999 13.4394 13.877 13.6582 13.6582C13.877 13.4394 13.9999 13.1427 13.9999 12.8333V11.3749C13.9999 11.0655 13.877 10.7688 13.6582 10.55C13.4394 10.3312 13.1427 10.2083 12.8333 10.2083ZM12.8333 12.8333H11.3749V11.3749H12.8333V12.8333Z" fill="#707070" />
                <path d="M2.625 5.10425H1.16667C0.857247 5.10425 0.560501 5.22716 0.341709 5.44596C0.122916 5.66475 0 5.9615 0 6.27091L0 7.72925C0 8.03867 0.122916 8.33541 0.341709 8.55421C0.560501 8.773 0.857247 8.89592 1.16667 8.89592H2.625C2.93442 8.89592 3.23117 8.773 3.44996 8.55421C3.66875 8.33541 3.79167 8.03867 3.79167 7.72925V6.27091C3.79167 5.9615 3.66875 5.66475 3.44996 5.44596C3.23117 5.22716 2.93442 5.10425 2.625 5.10425ZM2.625 7.72925H1.16667V6.27091H2.625V7.72925Z" fill="#707070" />
                <path d="M12.8333 5.10425H11.3749C11.0655 5.10425 10.7688 5.22716 10.55 5.44596C10.3312 5.66475 10.2083 5.9615 10.2083 6.27091V7.72925C10.2083 8.03867 10.3312 8.33541 10.55 8.55421C10.7688 8.773 11.0655 8.89592 11.3749 8.89592H12.8333C13.1427 8.89592 13.4394 8.773 13.6582 8.55421C13.877 8.33541 13.9999 8.03867 13.9999 7.72925V6.27091C13.9999 5.9615 13.877 5.66475 13.6582 5.44596C13.4394 5.22716 13.1427 5.10425 12.8333 5.10425ZM12.8333 7.72925H11.3749V6.27091H12.8333V7.72925Z" fill="#707070" />
                <path d="M2.625 0H1.16667C0.857247 0 0.560501 0.122916 0.341709 0.341709C0.122916 0.560501 0 0.857247 0 1.16667L0 2.625C0 2.93442 0.122916 3.23117 0.341709 3.44996C0.560501 3.66875 0.857247 3.79167 1.16667 3.79167H2.625C2.93442 3.79167 3.23117 3.66875 3.44996 3.44996C3.66875 3.23117 3.79167 2.93442 3.79167 2.625V1.16667C3.79167 0.857247 3.66875 0.560501 3.44996 0.341709C3.23117 0.122916 2.93442 0 2.625 0ZM2.625 2.625H1.16667V1.16667H2.625V2.625Z" fill="#707070" />
                <path d="M7.72925 10.2083H6.27091C5.9615 10.2083 5.66475 10.3312 5.44596 10.55C5.22716 10.7688 5.10425 11.0655 5.10425 11.3749V12.8333C5.10425 13.1427 5.22716 13.4394 5.44596 13.6582C5.66475 13.877 5.9615 13.9999 6.27091 13.9999H7.72925C8.03867 13.9999 8.33541 13.877 8.55421 13.6582C8.773 13.4394 8.89592 13.1427 8.89592 12.8333V11.3749C8.89592 11.0655 8.773 10.7688 8.55421 10.55C8.33541 10.3312 8.03867 10.2083 7.72925 10.2083ZM7.72925 12.8333H6.27091V11.3749H7.72925V12.8333Z" fill="#707070" />
                <path d="M7.72925 5.10425H6.27091C5.9615 5.10425 5.66475 5.22716 5.44596 5.44596C5.22716 5.66475 5.10425 5.9615 5.10425 6.27091V7.72925C5.10425 8.03867 5.22716 8.33541 5.44596 8.55421C5.66475 8.773 5.9615 8.89592 6.27091 8.89592H7.72925C8.03867 8.89592 8.33541 8.773 8.55421 8.55421C8.773 8.33541 8.89592 8.03867 8.89592 7.72925V6.27091C8.89592 5.9615 8.773 5.66475 8.55421 5.44596C8.33541 5.22716 8.03867 5.10425 7.72925 5.10425ZM7.72925 7.72925H6.27091V6.27091H7.72925V7.72925Z" fill="#707070" />
                <path d="M7.72925 0H6.27091C5.9615 0 5.66475 0.122916 5.44596 0.341709C5.22716 0.560501 5.10425 0.857247 5.10425 1.16667V2.625C5.10425 2.93442 5.22716 3.23117 5.44596 3.44996C5.66475 3.66875 5.9615 3.79167 6.27091 3.79167H7.72925C8.03867 3.79167 8.33541 3.66875 8.55421 3.44996C8.773 3.23117 8.89592 2.93442 8.89592 2.625V1.16667C8.89592 0.857247 8.773 0.560501 8.55421 0.341709C8.33541 0.122916 8.03867 0 7.72925 0ZM7.72925 2.625H6.27091V1.16667H7.72925V2.625Z" fill="#707070" />
                <path d="M12.8333 0H11.3749C11.0655 0 10.7688 0.122916 10.55 0.341709C10.3312 0.560501 10.2083 0.857247 10.2083 1.16667V2.625C10.2083 2.93442 10.3312 3.23117 10.55 3.44996C10.7688 3.66875 11.0655 3.79167 11.3749 3.79167H12.8333C13.1427 3.79167 13.4394 3.66875 13.6582 3.44996C13.877 3.23117 13.9999 2.93442 13.9999 2.625V1.16667C13.9999 0.857247 13.877 0.560501 13.6582 0.341709C13.4394 0.122916 13.1427 0 12.8333 0ZM12.8333 2.625H11.3749V1.16667H12.8333V2.625Z" fill="#707070" />
              </g>
              <defs>
                <clipPath id="clip0_650_3324">
                  <rect width="14" height="14" fill="white" />
                </clipPath>
              </defs>
            </svg><input className="attendance-input-field" placeholder='Name' type="text" value={selectedName}
              onChange={(e) => setName(e.target.value)} />
          </div>
          <div className="btn-cont">
            {/* <button className='attendance-btn' onClick={handleSearch}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="20" viewBox="0 0 11 12" fill="none">
              <circle cx="5" cy="5" r="4.3" stroke="#FFFFFF" stroke-width="1.4" />
              <line x1="10.0101" y1="11" x2="8" y2="8.98995" stroke="#FFFFFF" stroke-width="1.4" stroke-linecap="round" />
            </svg>Search</button> */}
          </div>
        </div>
      </div>

      {/* Attendance History Details */}
      <div className="custom-table-background">
        <div className="table-heading-flex">
          <div className="repo-heading">Attendance ( {filteredEmployee.length} of {allattendance.length})
          </div>
          <div>

          </div>
        </div>
        <table className="custom-table">
          <thead>
            <tr className="custom-table-head-tr">
              <th className="align-left">Sr No.</th>

              <th className="align-leftt">Emp Code</th>
              <th className="align-leftt">Name</th>
              <th className="align-center">Punch In</th>
              <th className="align-center">Punch Out</th>
              <th className="align-center">Total hours</th>
              <th className="align-right">Behaviour</th>
              <th className="align-right">Status</th>

            </tr>
          </thead>
          <tbody>
            {filteredEmployee.sort((a, b) => a.employee - b.employee).map((i, index) => (
              <React.Fragment key={index}>
                <tr className="tr-border-bottom">
                  <td colSpan="6"></td>
                </tr>
                <tr className="custom-table-head-td">
                  <td className="align-left">{index + 1}</td>

                  <td className="align-leftt">{i.employee}</td>
                  <td className="align-leftt">{i.name}</td>
                  <td className="align-center">{i.in_time}</td>
                  <td className="align-center">{i.out_time}</td>
                  <td className="align-center">{i.work_duration}</td>

                  <td className="align-right ">{i.day_status}</td>
                  <td className="align-right">{i.attendence_status}</td>
                </tr>
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </>
  )
}


const EmployeeAttendance = () => {

  // *********** Employee Code Api Start ***********
  const [emp_code, setEmp_code] = useState([]);
  useEffect(() => {
    const getAllEmp_code = async () => {
      try {
        const res = await axios.get(`${BASE_URL}/wfm/ourcompanysRH/${sessionStorage.getItem("email")}/`);
        setEmp_code(res.data)
      } catch (err) {
        // alert(err.message);
      }
    };

    getAllEmp_code();
  }, []);


  // *********** Attendance Get Request Api Start ***********


  const today = new Date();
  today.setDate(today.getDate() - 60);

  const year = today.getFullYear();
  const month = today.getMonth() + 1;
  const day = today.getDate();

  const formattedDatee = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
  // const datearrayy = formattedDate.split("-");

  const [allattendance, setAttendance] = useState([])
  const [status_list, setStatus_list] = useState([])

  const [startdate, setStartDate] = useState(
    `${formattedDatee}`
  );

  const [enddate, setEndDate] = useState(`${formattedDate}`);
  const [selectedEmpcode, setSelectedEmpcode] = useState(`${sessionStorage.getItem("emp_code")}`);




  const getAttendanceDetails = async () => {
    try {
      const res = await axios.get(`${BASE_URL}/wfm/attendancefilterbyEmpByMonthstatus/${selectedEmpcode}/${startdate}/${enddate}/`);
      // const ress = await axios.get(`${BASE_URL}/wfm/attendancefilterbyEmpByMonthstatusquick/${sessionStorage.getItem("emp_code")}/${startdate}/${enddate}/`)
      setAttendance(res.data)
      const uniqueDayStatus = [...new Set(res.data.map(entry => entry.day_status))];
      setStatus_list(uniqueDayStatus)
      // setAttendanceStatus(ress.data)
    } catch (err) {
      // alert(err.message);
    }
  };

  useEffect(() => {

    getAttendanceDetails();
  }, [startdate, enddate, selectedEmpcode]);


  const [filteredEmployee, setFilteredEmployee] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState('');


  const applyFilters = () => {
    // Filter employees based on selectedStatus and selectedName
    let filteredData = allattendance;

    if (selectedStatus !== '') {
      filteredData = filteredData.filter(employee => employee.day_status === selectedStatus);
    }

    setFilteredEmployee(filteredData);

  };

  console.log(status_list)
  useEffect(() => {

    applyFilters();

  }, [selectedStatus, allattendance]);

  return (
    <>
      <div className="attendance-history-cont">
        <div className="attendance-heading"></div>

        <div className="attendance-subcont">
          <div className="field-cont">
            <input className="attendance-input-field date-field" placeholder='From Date' type="date" value={startdate} onChange={(e) => setStartDate(e.target.value)} /><hr />
            <input className="attendance-input-field date-field" placeholder='To Date' type="date" value={enddate} onChange={(e) => setEndDate(e.target.value)} /><hr />
            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 14 14" fill="none">
              <g clip-path="url(#clip0_650_3324)">
                <path d="M2.625 10.2083H1.16667C0.857247 10.2083 0.560501 10.3312 0.341709 10.55C0.122916 10.7688 0 11.0655 0 11.3749L0 12.8333C0 13.1427 0.122916 13.4394 0.341709 13.6582C0.560501 13.877 0.857247 13.9999 1.16667 13.9999H2.625C2.93442 13.9999 3.23117 13.877 3.44996 13.6582C3.66875 13.4394 3.79167 13.1427 3.79167 12.8333V11.3749C3.79167 11.0655 3.66875 10.7688 3.44996 10.55C3.23117 10.3312 2.93442 10.2083 2.625 10.2083ZM2.625 12.8333H1.16667V11.3749H2.625V12.8333Z" fill="#707070" />
                <path d="M12.8333 10.2083H11.3749C11.0655 10.2083 10.7688 10.3312 10.55 10.55C10.3312 10.7688 10.2083 11.0655 10.2083 11.3749V12.8333C10.2083 13.1427 10.3312 13.4394 10.55 13.6582C10.7688 13.877 11.0655 13.9999 11.3749 13.9999H12.8333C13.1427 13.9999 13.4394 13.877 13.6582 13.6582C13.877 13.4394 13.9999 13.1427 13.9999 12.8333V11.3749C13.9999 11.0655 13.877 10.7688 13.6582 10.55C13.4394 10.3312 13.1427 10.2083 12.8333 10.2083ZM12.8333 12.8333H11.3749V11.3749H12.8333V12.8333Z" fill="#707070" />
                <path d="M2.625 5.10425H1.16667C0.857247 5.10425 0.560501 5.22716 0.341709 5.44596C0.122916 5.66475 0 5.9615 0 6.27091L0 7.72925C0 8.03867 0.122916 8.33541 0.341709 8.55421C0.560501 8.773 0.857247 8.89592 1.16667 8.89592H2.625C2.93442 8.89592 3.23117 8.773 3.44996 8.55421C3.66875 8.33541 3.79167 8.03867 3.79167 7.72925V6.27091C3.79167 5.9615 3.66875 5.66475 3.44996 5.44596C3.23117 5.22716 2.93442 5.10425 2.625 5.10425ZM2.625 7.72925H1.16667V6.27091H2.625V7.72925Z" fill="#707070" />
                <path d="M12.8333 5.10425H11.3749C11.0655 5.10425 10.7688 5.22716 10.55 5.44596C10.3312 5.66475 10.2083 5.9615 10.2083 6.27091V7.72925C10.2083 8.03867 10.3312 8.33541 10.55 8.55421C10.7688 8.773 11.0655 8.89592 11.3749 8.89592H12.8333C13.1427 8.89592 13.4394 8.773 13.6582 8.55421C13.877 8.33541 13.9999 8.03867 13.9999 7.72925V6.27091C13.9999 5.9615 13.877 5.66475 13.6582 5.44596C13.4394 5.22716 13.1427 5.10425 12.8333 5.10425ZM12.8333 7.72925H11.3749V6.27091H12.8333V7.72925Z" fill="#707070" />
                <path d="M2.625 0H1.16667C0.857247 0 0.560501 0.122916 0.341709 0.341709C0.122916 0.560501 0 0.857247 0 1.16667L0 2.625C0 2.93442 0.122916 3.23117 0.341709 3.44996C0.560501 3.66875 0.857247 3.79167 1.16667 3.79167H2.625C2.93442 3.79167 3.23117 3.66875 3.44996 3.44996C3.66875 3.23117 3.79167 2.93442 3.79167 2.625V1.16667C3.79167 0.857247 3.66875 0.560501 3.44996 0.341709C3.23117 0.122916 2.93442 0 2.625 0ZM2.625 2.625H1.16667V1.16667H2.625V2.625Z" fill="#707070" />
                <path d="M7.72925 10.2083H6.27091C5.9615 10.2083 5.66475 10.3312 5.44596 10.55C5.22716 10.7688 5.10425 11.0655 5.10425 11.3749V12.8333C5.10425 13.1427 5.22716 13.4394 5.44596 13.6582C5.66475 13.877 5.9615 13.9999 6.27091 13.9999H7.72925C8.03867 13.9999 8.33541 13.877 8.55421 13.6582C8.773 13.4394 8.89592 13.1427 8.89592 12.8333V11.3749C8.89592 11.0655 8.773 10.7688 8.55421 10.55C8.33541 10.3312 8.03867 10.2083 7.72925 10.2083ZM7.72925 12.8333H6.27091V11.3749H7.72925V12.8333Z" fill="#707070" />
                <path d="M7.72925 5.10425H6.27091C5.9615 5.10425 5.66475 5.22716 5.44596 5.44596C5.22716 5.66475 5.10425 5.9615 5.10425 6.27091V7.72925C5.10425 8.03867 5.22716 8.33541 5.44596 8.55421C5.66475 8.773 5.9615 8.89592 6.27091 8.89592H7.72925C8.03867 8.89592 8.33541 8.773 8.55421 8.55421C8.773 8.33541 8.89592 8.03867 8.89592 7.72925V6.27091C8.89592 5.9615 8.773 5.66475 8.55421 5.44596C8.33541 5.22716 8.03867 5.10425 7.72925 5.10425ZM7.72925 7.72925H6.27091V6.27091H7.72925V7.72925Z" fill="#707070" />
                <path d="M7.72925 0H6.27091C5.9615 0 5.66475 0.122916 5.44596 0.341709C5.22716 0.560501 5.10425 0.857247 5.10425 1.16667V2.625C5.10425 2.93442 5.22716 3.23117 5.44596 3.44996C5.66475 3.66875 5.9615 3.79167 6.27091 3.79167H7.72925C8.03867 3.79167 8.33541 3.66875 8.55421 3.44996C8.773 3.23117 8.89592 2.93442 8.89592 2.625V1.16667C8.89592 0.857247 8.773 0.560501 8.55421 0.341709C8.33541 0.122916 8.03867 0 7.72925 0ZM7.72925 2.625H6.27091V1.16667H7.72925V2.625Z" fill="#707070" />
                <path d="M12.8333 0H11.3749C11.0655 0 10.7688 0.122916 10.55 0.341709C10.3312 0.560501 10.2083 0.857247 10.2083 1.16667V2.625C10.2083 2.93442 10.3312 3.23117 10.55 3.44996C10.7688 3.66875 11.0655 3.79167 11.3749 3.79167H12.8333C13.1427 3.79167 13.4394 3.66875 13.6582 3.44996C13.877 3.23117 13.9999 2.93442 13.9999 2.625V1.16667C13.9999 0.857247 13.877 0.560501 13.6582 0.341709C13.4394 0.122916 13.1427 0 12.8333 0ZM12.8333 2.625H11.3749V1.16667H12.8333V2.625Z" fill="#707070" />
              </g>
              <defs>
                <clipPath id="clip0_650_3324">
                  <rect width="14" height="14" fill="white" />
                </clipPath>
              </defs>
            </svg>

            <select className="attendance-input-field date-field" type="text"
              value={selectedStatus}
              onChange={(e) => setSelectedStatus(e.target.value)}
            >
              <option value="">All</option>
              {status_list.map(status => (<option value={status}>{status}</option>))};




            </select>
            <hr />
            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 14 14" fill="none">
              <g clip-path="url(#clip0_650_3324)">
                <path d="M2.625 10.2083H1.16667C0.857247 10.2083 0.560501 10.3312 0.341709 10.55C0.122916 10.7688 0 11.0655 0 11.3749L0 12.8333C0 13.1427 0.122916 13.4394 0.341709 13.6582C0.560501 13.877 0.857247 13.9999 1.16667 13.9999H2.625C2.93442 13.9999 3.23117 13.877 3.44996 13.6582C3.66875 13.4394 3.79167 13.1427 3.79167 12.8333V11.3749C3.79167 11.0655 3.66875 10.7688 3.44996 10.55C3.23117 10.3312 2.93442 10.2083 2.625 10.2083ZM2.625 12.8333H1.16667V11.3749H2.625V12.8333Z" fill="#707070" />
                <path d="M12.8333 10.2083H11.3749C11.0655 10.2083 10.7688 10.3312 10.55 10.55C10.3312 10.7688 10.2083 11.0655 10.2083 11.3749V12.8333C10.2083 13.1427 10.3312 13.4394 10.55 13.6582C10.7688 13.877 11.0655 13.9999 11.3749 13.9999H12.8333C13.1427 13.9999 13.4394 13.877 13.6582 13.6582C13.877 13.4394 13.9999 13.1427 13.9999 12.8333V11.3749C13.9999 11.0655 13.877 10.7688 13.6582 10.55C13.4394 10.3312 13.1427 10.2083 12.8333 10.2083ZM12.8333 12.8333H11.3749V11.3749H12.8333V12.8333Z" fill="#707070" />
                <path d="M2.625 5.10425H1.16667C0.857247 5.10425 0.560501 5.22716 0.341709 5.44596C0.122916 5.66475 0 5.9615 0 6.27091L0 7.72925C0 8.03867 0.122916 8.33541 0.341709 8.55421C0.560501 8.773 0.857247 8.89592 1.16667 8.89592H2.625C2.93442 8.89592 3.23117 8.773 3.44996 8.55421C3.66875 8.33541 3.79167 8.03867 3.79167 7.72925V6.27091C3.79167 5.9615 3.66875 5.66475 3.44996 5.44596C3.23117 5.22716 2.93442 5.10425 2.625 5.10425ZM2.625 7.72925H1.16667V6.27091H2.625V7.72925Z" fill="#707070" />
                <path d="M12.8333 5.10425H11.3749C11.0655 5.10425 10.7688 5.22716 10.55 5.44596C10.3312 5.66475 10.2083 5.9615 10.2083 6.27091V7.72925C10.2083 8.03867 10.3312 8.33541 10.55 8.55421C10.7688 8.773 11.0655 8.89592 11.3749 8.89592H12.8333C13.1427 8.89592 13.4394 8.773 13.6582 8.55421C13.877 8.33541 13.9999 8.03867 13.9999 7.72925V6.27091C13.9999 5.9615 13.877 5.66475 13.6582 5.44596C13.4394 5.22716 13.1427 5.10425 12.8333 5.10425ZM12.8333 7.72925H11.3749V6.27091H12.8333V7.72925Z" fill="#707070" />
                <path d="M2.625 0H1.16667C0.857247 0 0.560501 0.122916 0.341709 0.341709C0.122916 0.560501 0 0.857247 0 1.16667L0 2.625C0 2.93442 0.122916 3.23117 0.341709 3.44996C0.560501 3.66875 0.857247 3.79167 1.16667 3.79167H2.625C2.93442 3.79167 3.23117 3.66875 3.44996 3.44996C3.66875 3.23117 3.79167 2.93442 3.79167 2.625V1.16667C3.79167 0.857247 3.66875 0.560501 3.44996 0.341709C3.23117 0.122916 2.93442 0 2.625 0ZM2.625 2.625H1.16667V1.16667H2.625V2.625Z" fill="#707070" />
                <path d="M7.72925 10.2083H6.27091C5.9615 10.2083 5.66475 10.3312 5.44596 10.55C5.22716 10.7688 5.10425 11.0655 5.10425 11.3749V12.8333C5.10425 13.1427 5.22716 13.4394 5.44596 13.6582C5.66475 13.877 5.9615 13.9999 6.27091 13.9999H7.72925C8.03867 13.9999 8.33541 13.877 8.55421 13.6582C8.773 13.4394 8.89592 13.1427 8.89592 12.8333V11.3749C8.89592 11.0655 8.773 10.7688 8.55421 10.55C8.33541 10.3312 8.03867 10.2083 7.72925 10.2083ZM7.72925 12.8333H6.27091V11.3749H7.72925V12.8333Z" fill="#707070" />
                <path d="M7.72925 5.10425H6.27091C5.9615 5.10425 5.66475 5.22716 5.44596 5.44596C5.22716 5.66475 5.10425 5.9615 5.10425 6.27091V7.72925C5.10425 8.03867 5.22716 8.33541 5.44596 8.55421C5.66475 8.773 5.9615 8.89592 6.27091 8.89592H7.72925C8.03867 8.89592 8.33541 8.773 8.55421 8.55421C8.773 8.33541 8.89592 8.03867 8.89592 7.72925V6.27091C8.89592 5.9615 8.773 5.66475 8.55421 5.44596C8.33541 5.22716 8.03867 5.10425 7.72925 5.10425ZM7.72925 7.72925H6.27091V6.27091H7.72925V7.72925Z" fill="#707070" />
                <path d="M7.72925 0H6.27091C5.9615 0 5.66475 0.122916 5.44596 0.341709C5.22716 0.560501 5.10425 0.857247 5.10425 1.16667V2.625C5.10425 2.93442 5.22716 3.23117 5.44596 3.44996C5.66475 3.66875 5.9615 3.79167 6.27091 3.79167H7.72925C8.03867 3.79167 8.33541 3.66875 8.55421 3.44996C8.773 3.23117 8.89592 2.93442 8.89592 2.625V1.16667C8.89592 0.857247 8.773 0.560501 8.55421 0.341709C8.33541 0.122916 8.03867 0 7.72925 0ZM7.72925 2.625H6.27091V1.16667H7.72925V2.625Z" fill="#707070" />
                <path d="M12.8333 0H11.3749C11.0655 0 10.7688 0.122916 10.55 0.341709C10.3312 0.560501 10.2083 0.857247 10.2083 1.16667V2.625C10.2083 2.93442 10.3312 3.23117 10.55 3.44996C10.7688 3.66875 11.0655 3.79167 11.3749 3.79167H12.8333C13.1427 3.79167 13.4394 3.66875 13.6582 3.44996C13.877 3.23117 13.9999 2.93442 13.9999 2.625V1.16667C13.9999 0.857247 13.877 0.560501 13.6582 0.341709C13.4394 0.122916 13.1427 0 12.8333 0ZM12.8333 2.625H11.3749V1.16667H12.8333V2.625Z" fill="#707070" />
              </g>
              <defs>
                <clipPath id="clip0_650_3324">
                  <rect width="14" height="14" fill="white" />
                </clipPath>
              </defs>
            </svg>

            <select className="attendance-input-field date-field" type="text"
              value={selectedEmpcode}
              onChange={(e) => setSelectedEmpcode(e.target.value)}
            >
              <option value="">Select Employee Code</option>
              {emp_code.sort((a, b) => a.emp_code - b.emp_code).map((i, index) => (<option value={i.emp_code}>{i.emp_code} - {i.name}</option>))};




            </select>
          </div>
          {/* <div className="btn-cont">
          <button className='attendance-btn' onClick={getAttendanceDetails}><img src={Searchicon} alt="Search" className='searchicon'/>Search</button>
        </div> */}
        </div>
      </div>
      <div className="custom-table-background">
        <div className="table-heading-flex">
          <div className="repo-heading">Employee Attendance  ( {filteredEmployee.length} of {allattendance.length})
          </div>

        </div>
        <table className="custom-table">
          <thead>
            <tr className="custom-table-head-tr">
              <th className="align-left">Sr No</th>
              <th className="align-center">Date</th>
              <th className="align-center">Punch In</th>
              <th className="align-center">Punch Out</th>
              <th className="align-center">Total hours</th>
              <th className="align-center">Behaviour</th>
              <th className="align-right">Status</th>
            </tr>
          </thead>
          <tbody>
            {filteredEmployee.sort((a, b) => new Date(a.date) - new Date(b.date)).map((i, index) => (
              <React.Fragment key={index}>
                <tr className="tr-border-bottom">
                  <td colSpan="6"></td>
                </tr>
                <tr className="custom-table-head-td">
                  <td className="align-left">{index + 1}</td>
                  <td className="align-center">{formattedDateLong(i.date)}</td>
                  <td className="align-center">{i.in_time}</td>
                  <td className="align-center">{i.out_time}</td>
                  <td className="align-center">{i.work_duration}</td>

                  <td className="align-center">{i.day_status}</td>
                  <td className="align-right">{i.attendence_status}</td>
                </tr>
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </>
  )
}



// const SummarySheet = () => {

//   const [show, setShow] = useState(false);


//   const [indexRow, setIndexRow] = useState({});

//   const handleClose = () => setShow(false);
//   const handleShow = (e) => {
//     setShow(true);
//     setIndexRow(e)
//   }


//   console.log(indexRow)


//   const today = new Date();
//   today.setDate(today.getDate() - 1);
//   const year = today.getFullYear();
//   const month = today.getMonth() + 1; 
//   const day = today.getDate();


//   const formattedDate = `${year}-${month.toString().padStart(2, "0")}-${day
//     .toString()
//     .padStart(2, "0")}`;
//   var datearray = formattedDate.split("-");

//   var newdate = datearray[0] + "-" + datearray[1] + "-" + datearray[2];
//   console.log(newdate)

//   // /attandance/employattandancessheet/222/2/2023/

//   const [monthh, setMonthh] = useState(`${month}`);

//   const [yearr, setYearr] = useState(`${year}`);
//   useEffect(() => {
//     getAllattendancedata();
//   }, [monthh, yearr]);

//   const [allattendance, setAllattendance] = useState([]);


//   let getAllattendancedata = async () => {
//     try {
//       const res = await axios.get(
//         `${BASE_URL}/wfm/employeeattandancessheetbyrh/${monthh}/${yearr}/${sessionStorage.getItem('email')}/`
//       );
//       setAllattendance(res.data);

//     } catch (err) {
//       alert(err.message);
//     }
//   };



//   const getAllDatesOfMonth = (month, year) => {
//     const numberOfDaysInMonth = new Date(year, month, 0).getDate();
//     const dates = [];
//     const datees = [];

//     for (let i = 1; i <= numberOfDaysInMonth; i++) {
//       const date = new Date(year, month - 1, i);
//       const day = date.getDate();
//       const formatttedDatee = `${day.toString().padStart(2, "0")}`;
//       datees.push({ date: formatttedDatee });
//       const formattedDate = `${year}-${month.toString().padStart(2, "0")}-${day
//         .toString()
//         .padStart(2, "0")}`;
//       dates.push({ date: formattedDate });
//     }

//     return dates;
//   };

//   const dates = getAllDatesOfMonth(monthh, yearr).map((date, index) => ({
//     ...date,
//     index,
//   }));

//   const getAllDatessOfMonth = (month, year) => {
//     const numberOfDaysInMonth = new Date(year, month, 0).getDate();
//     const datees = [];

//     for (let i = 1; i <= numberOfDaysInMonth; i++) {
//       const date = new Date(year, month - 1, i);
//       const day = date.getDate();
//       const formatttedDatee = `${day.toString().padStart(2, "0")}`;
//       datees.push({ date: formatttedDatee });
//     }

//     return datees;
//   };

//   const datees = getAllDatessOfMonth(monthh, yearr); // get all dates for March 2023



//     // *********** Filter Logic ***********
//   const [filteredEmployee, setFilteredEmployee] = useState([]);


//     // const [selectedStatus, setSelectedStatus] = useState('');
//     const [selectedName, setName] = useState('');
//     const [selectedDep, setDep] = useState('');

//     const [selectedStatus, setSelectedStatus] = useState('active');



//     const applyFilters = () => {
//       // Filter employees based on selectedStatus and selectedName
//       let filteredData = allattendance;

//       if (selectedDep !== '') {
//         filteredData = filteredData.filter(employee => {
//           const nameMatch = employee.department_name.toLowerCase().includes(selectedDep);
//           const empCodeMatch = employee.rh_name.toLowerCase().includes(selectedDep);
//           return nameMatch || empCodeMatch;
//       });
//       }

//         if (selectedStatus !== '') {
//           filteredData = filteredData.filter(employee => employee.employee_status === selectedStatus);
//         }

//       if (selectedName) {
//         // Use includes for a case-insensitive search for the name only
//         const lowercaseSelectedName = selectedName.toLowerCase();
//         filteredData = filteredData.filter(employee => {
//             const nameMatch = employee.name.toLowerCase().includes(lowercaseSelectedName);
//             const empCodeMatch = employee.emp_code.toString().includes(selectedName);
//             return nameMatch || empCodeMatch;
//         });
//     }


//       setFilteredEmployee(filteredData);

//     };

//     useEffect(() => {

//       applyFilters();
//     }, [ selectedName, selectedStatus, selectedDep,  allattendance]);


//   return (
//     <>

// <div className="attendance-history-cont">
//         <div className="attendance-heading"></div>

//         <div className="attendance-subcont">
//           <div className="field-cont">
//           <input type="number"
//               maxLength={4} value={yearr}
//               onChange={(e) => setYearr(e.target.value)} className="attendance-input-field date-field"/><hr />
//             <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 14 14" fill="none">
//               <g clip-path="url(#clip0_650_3324)">
//                 <path d="M2.625 10.2083H1.16667C0.857247 10.2083 0.560501 10.3312 0.341709 10.55C0.122916 10.7688 0 11.0655 0 11.3749L0 12.8333C0 13.1427 0.122916 13.4394 0.341709 13.6582C0.560501 13.877 0.857247 13.9999 1.16667 13.9999H2.625C2.93442 13.9999 3.23117 13.877 3.44996 13.6582C3.66875 13.4394 3.79167 13.1427 3.79167 12.8333V11.3749C3.79167 11.0655 3.66875 10.7688 3.44996 10.55C3.23117 10.3312 2.93442 10.2083 2.625 10.2083ZM2.625 12.8333H1.16667V11.3749H2.625V12.8333Z" fill="#707070" />
//                 <path d="M12.8333 10.2083H11.3749C11.0655 10.2083 10.7688 10.3312 10.55 10.55C10.3312 10.7688 10.2083 11.0655 10.2083 11.3749V12.8333C10.2083 13.1427 10.3312 13.4394 10.55 13.6582C10.7688 13.877 11.0655 13.9999 11.3749 13.9999H12.8333C13.1427 13.9999 13.4394 13.877 13.6582 13.6582C13.877 13.4394 13.9999 13.1427 13.9999 12.8333V11.3749C13.9999 11.0655 13.877 10.7688 13.6582 10.55C13.4394 10.3312 13.1427 10.2083 12.8333 10.2083ZM12.8333 12.8333H11.3749V11.3749H12.8333V12.8333Z" fill="#707070" />
//                 <path d="M2.625 5.10425H1.16667C0.857247 5.10425 0.560501 5.22716 0.341709 5.44596C0.122916 5.66475 0 5.9615 0 6.27091L0 7.72925C0 8.03867 0.122916 8.33541 0.341709 8.55421C0.560501 8.773 0.857247 8.89592 1.16667 8.89592H2.625C2.93442 8.89592 3.23117 8.773 3.44996 8.55421C3.66875 8.33541 3.79167 8.03867 3.79167 7.72925V6.27091C3.79167 5.9615 3.66875 5.66475 3.44996 5.44596C3.23117 5.22716 2.93442 5.10425 2.625 5.10425ZM2.625 7.72925H1.16667V6.27091H2.625V7.72925Z" fill="#707070" />
//                 <path d="M12.8333 5.10425H11.3749C11.0655 5.10425 10.7688 5.22716 10.55 5.44596C10.3312 5.66475 10.2083 5.9615 10.2083 6.27091V7.72925C10.2083 8.03867 10.3312 8.33541 10.55 8.55421C10.7688 8.773 11.0655 8.89592 11.3749 8.89592H12.8333C13.1427 8.89592 13.4394 8.773 13.6582 8.55421C13.877 8.33541 13.9999 8.03867 13.9999 7.72925V6.27091C13.9999 5.9615 13.877 5.66475 13.6582 5.44596C13.4394 5.22716 13.1427 5.10425 12.8333 5.10425ZM12.8333 7.72925H11.3749V6.27091H12.8333V7.72925Z" fill="#707070" />
//                 <path d="M2.625 0H1.16667C0.857247 0 0.560501 0.122916 0.341709 0.341709C0.122916 0.560501 0 0.857247 0 1.16667L0 2.625C0 2.93442 0.122916 3.23117 0.341709 3.44996C0.560501 3.66875 0.857247 3.79167 1.16667 3.79167H2.625C2.93442 3.79167 3.23117 3.66875 3.44996 3.44996C3.66875 3.23117 3.79167 2.93442 3.79167 2.625V1.16667C3.79167 0.857247 3.66875 0.560501 3.44996 0.341709C3.23117 0.122916 2.93442 0 2.625 0ZM2.625 2.625H1.16667V1.16667H2.625V2.625Z" fill="#707070" />
//                 <path d="M7.72925 10.2083H6.27091C5.9615 10.2083 5.66475 10.3312 5.44596 10.55C5.22716 10.7688 5.10425 11.0655 5.10425 11.3749V12.8333C5.10425 13.1427 5.22716 13.4394 5.44596 13.6582C5.66475 13.877 5.9615 13.9999 6.27091 13.9999H7.72925C8.03867 13.9999 8.33541 13.877 8.55421 13.6582C8.773 13.4394 8.89592 13.1427 8.89592 12.8333V11.3749C8.89592 11.0655 8.773 10.7688 8.55421 10.55C8.33541 10.3312 8.03867 10.2083 7.72925 10.2083ZM7.72925 12.8333H6.27091V11.3749H7.72925V12.8333Z" fill="#707070" />
//                 <path d="M7.72925 5.10425H6.27091C5.9615 5.10425 5.66475 5.22716 5.44596 5.44596C5.22716 5.66475 5.10425 5.9615 5.10425 6.27091V7.72925C5.10425 8.03867 5.22716 8.33541 5.44596 8.55421C5.66475 8.773 5.9615 8.89592 6.27091 8.89592H7.72925C8.03867 8.89592 8.33541 8.773 8.55421 8.55421C8.773 8.33541 8.89592 8.03867 8.89592 7.72925V6.27091C8.89592 5.9615 8.773 5.66475 8.55421 5.44596C8.33541 5.22716 8.03867 5.10425 7.72925 5.10425ZM7.72925 7.72925H6.27091V6.27091H7.72925V7.72925Z" fill="#707070" />
//                 <path d="M7.72925 0H6.27091C5.9615 0 5.66475 0.122916 5.44596 0.341709C5.22716 0.560501 5.10425 0.857247 5.10425 1.16667V2.625C5.10425 2.93442 5.22716 3.23117 5.44596 3.44996C5.66475 3.66875 5.9615 3.79167 6.27091 3.79167H7.72925C8.03867 3.79167 8.33541 3.66875 8.55421 3.44996C8.773 3.23117 8.89592 2.93442 8.89592 2.625V1.16667C8.89592 0.857247 8.773 0.560501 8.55421 0.341709C8.33541 0.122916 8.03867 0 7.72925 0ZM7.72925 2.625H6.27091V1.16667H7.72925V2.625Z" fill="#707070" />
//                 <path d="M12.8333 0H11.3749C11.0655 0 10.7688 0.122916 10.55 0.341709C10.3312 0.560501 10.2083 0.857247 10.2083 1.16667V2.625C10.2083 2.93442 10.3312 3.23117 10.55 3.44996C10.7688 3.66875 11.0655 3.79167 11.3749 3.79167H12.8333C13.1427 3.79167 13.4394 3.66875 13.6582 3.44996C13.877 3.23117 13.9999 2.93442 13.9999 2.625V1.16667C13.9999 0.857247 13.877 0.560501 13.6582 0.341709C13.4394 0.122916 13.1427 0 12.8333 0ZM12.8333 2.625H11.3749V1.16667H12.8333V2.625Z" fill="#707070" />
//               </g>
//               <defs>
//                 <clipPath id="clip0_650_3324">
//                   <rect width="14" height="14" fill="white" />
//                 </clipPath>
//               </defs>
//             </svg>

//             <select className="attendance-input-field date-field" type="text"
//           value={monthh}
//           onChange={(e) => setMonthh(e.target.value)}
//             >
//               {/* <option value="">All</option> */}
//               <option value="1">January</option>
//               <option value="2">February</option>
//               <option value="3">March</option>
//               <option value="4">April</option>
//               <option value="5">May</option>
//               <option value="6">June</option>
//               <option value="7">July</option>
//               <option value="8">August</option>
//               <option value="9">September</option>
//               <option value="10">October</option>
//               <option value="11">November</option>
//               <option value="12">December</option>





//             </select>
//             <hr />

//             <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 14 14" fill="none">
//               <g clip-path="url(#clip0_650_3324)">
//                 <path d="M2.625 10.2083H1.16667C0.857247 10.2083 0.560501 10.3312 0.341709 10.55C0.122916 10.7688 0 11.0655 0 11.3749L0 12.8333C0 13.1427 0.122916 13.4394 0.341709 13.6582C0.560501 13.877 0.857247 13.9999 1.16667 13.9999H2.625C2.93442 13.9999 3.23117 13.877 3.44996 13.6582C3.66875 13.4394 3.79167 13.1427 3.79167 12.8333V11.3749C3.79167 11.0655 3.66875 10.7688 3.44996 10.55C3.23117 10.3312 2.93442 10.2083 2.625 10.2083ZM2.625 12.8333H1.16667V11.3749H2.625V12.8333Z" fill="#707070" />
//                 <path d="M12.8333 10.2083H11.3749C11.0655 10.2083 10.7688 10.3312 10.55 10.55C10.3312 10.7688 10.2083 11.0655 10.2083 11.3749V12.8333C10.2083 13.1427 10.3312 13.4394 10.55 13.6582C10.7688 13.877 11.0655 13.9999 11.3749 13.9999H12.8333C13.1427 13.9999 13.4394 13.877 13.6582 13.6582C13.877 13.4394 13.9999 13.1427 13.9999 12.8333V11.3749C13.9999 11.0655 13.877 10.7688 13.6582 10.55C13.4394 10.3312 13.1427 10.2083 12.8333 10.2083ZM12.8333 12.8333H11.3749V11.3749H12.8333V12.8333Z" fill="#707070" />
//                 <path d="M2.625 5.10425H1.16667C0.857247 5.10425 0.560501 5.22716 0.341709 5.44596C0.122916 5.66475 0 5.9615 0 6.27091L0 7.72925C0 8.03867 0.122916 8.33541 0.341709 8.55421C0.560501 8.773 0.857247 8.89592 1.16667 8.89592H2.625C2.93442 8.89592 3.23117 8.773 3.44996 8.55421C3.66875 8.33541 3.79167 8.03867 3.79167 7.72925V6.27091C3.79167 5.9615 3.66875 5.66475 3.44996 5.44596C3.23117 5.22716 2.93442 5.10425 2.625 5.10425ZM2.625 7.72925H1.16667V6.27091H2.625V7.72925Z" fill="#707070" />
//                 <path d="M12.8333 5.10425H11.3749C11.0655 5.10425 10.7688 5.22716 10.55 5.44596C10.3312 5.66475 10.2083 5.9615 10.2083 6.27091V7.72925C10.2083 8.03867 10.3312 8.33541 10.55 8.55421C10.7688 8.773 11.0655 8.89592 11.3749 8.89592H12.8333C13.1427 8.89592 13.4394 8.773 13.6582 8.55421C13.877 8.33541 13.9999 8.03867 13.9999 7.72925V6.27091C13.9999 5.9615 13.877 5.66475 13.6582 5.44596C13.4394 5.22716 13.1427 5.10425 12.8333 5.10425ZM12.8333 7.72925H11.3749V6.27091H12.8333V7.72925Z" fill="#707070" />
//                 <path d="M2.625 0H1.16667C0.857247 0 0.560501 0.122916 0.341709 0.341709C0.122916 0.560501 0 0.857247 0 1.16667L0 2.625C0 2.93442 0.122916 3.23117 0.341709 3.44996C0.560501 3.66875 0.857247 3.79167 1.16667 3.79167H2.625C2.93442 3.79167 3.23117 3.66875 3.44996 3.44996C3.66875 3.23117 3.79167 2.93442 3.79167 2.625V1.16667C3.79167 0.857247 3.66875 0.560501 3.44996 0.341709C3.23117 0.122916 2.93442 0 2.625 0ZM2.625 2.625H1.16667V1.16667H2.625V2.625Z" fill="#707070" />
//                 <path d="M7.72925 10.2083H6.27091C5.9615 10.2083 5.66475 10.3312 5.44596 10.55C5.22716 10.7688 5.10425 11.0655 5.10425 11.3749V12.8333C5.10425 13.1427 5.22716 13.4394 5.44596 13.6582C5.66475 13.877 5.9615 13.9999 6.27091 13.9999H7.72925C8.03867 13.9999 8.33541 13.877 8.55421 13.6582C8.773 13.4394 8.89592 13.1427 8.89592 12.8333V11.3749C8.89592 11.0655 8.773 10.7688 8.55421 10.55C8.33541 10.3312 8.03867 10.2083 7.72925 10.2083ZM7.72925 12.8333H6.27091V11.3749H7.72925V12.8333Z" fill="#707070" />
//                 <path d="M7.72925 5.10425H6.27091C5.9615 5.10425 5.66475 5.22716 5.44596 5.44596C5.22716 5.66475 5.10425 5.9615 5.10425 6.27091V7.72925C5.10425 8.03867 5.22716 8.33541 5.44596 8.55421C5.66475 8.773 5.9615 8.89592 6.27091 8.89592H7.72925C8.03867 8.89592 8.33541 8.773 8.55421 8.55421C8.773 8.33541 8.89592 8.03867 8.89592 7.72925V6.27091C8.89592 5.9615 8.773 5.66475 8.55421 5.44596C8.33541 5.22716 8.03867 5.10425 7.72925 5.10425ZM7.72925 7.72925H6.27091V6.27091H7.72925V7.72925Z" fill="#707070" />
//                 <path d="M7.72925 0H6.27091C5.9615 0 5.66475 0.122916 5.44596 0.341709C5.22716 0.560501 5.10425 0.857247 5.10425 1.16667V2.625C5.10425 2.93442 5.22716 3.23117 5.44596 3.44996C5.66475 3.66875 5.9615 3.79167 6.27091 3.79167H7.72925C8.03867 3.79167 8.33541 3.66875 8.55421 3.44996C8.773 3.23117 8.89592 2.93442 8.89592 2.625V1.16667C8.89592 0.857247 8.773 0.560501 8.55421 0.341709C8.33541 0.122916 8.03867 0 7.72925 0ZM7.72925 2.625H6.27091V1.16667H7.72925V2.625Z" fill="#707070" />
//                 <path d="M12.8333 0H11.3749C11.0655 0 10.7688 0.122916 10.55 0.341709C10.3312 0.560501 10.2083 0.857247 10.2083 1.16667V2.625C10.2083 2.93442 10.3312 3.23117 10.55 3.44996C10.7688 3.66875 11.0655 3.79167 11.3749 3.79167H12.8333C13.1427 3.79167 13.4394 3.66875 13.6582 3.44996C13.877 3.23117 13.9999 2.93442 13.9999 2.625V1.16667C13.9999 0.857247 13.877 0.560501 13.6582 0.341709C13.4394 0.122916 13.1427 0 12.8333 0ZM12.8333 2.625H11.3749V1.16667H12.8333V2.625Z" fill="#707070" />
//               </g>
//               <defs>
//                 <clipPath id="clip0_650_3324">
//                   <rect width="14" height="14" fill="white" />
//                 </clipPath>
//               </defs>
//             </svg><input className="attendance-input-field" placeholder='Name or Code' type="text" value={selectedName}
//               onChange={(e) => setName(e.target.value)} />


//             <hr />


//             <svg xmlns="http://www.w3.org/2000/svg" width="40" height="38" viewBox="0 0 17 17" fill="none">
//               <g clip-path="url(#clip0_650_3351)">
//                 <mask id="mask0_650_3351" maskUnits="userSpaceOnUse" x="0" y="0" width="17" height="17">
//                   <path d="M17 0H0V17H17V0Z" fill="white" />
//                 </mask>
//                 <g mask="url(#mask0_650_3351)">
//                   <path d="M8.50009 17.0057L8.00425 16.5807C7.32071 16.0083 1.35229 10.8538 1.35229 7.08334C1.35229 5.18763 2.10536 3.36956 3.44583 2.02909C4.78631 0.688616 6.60437 -0.0644531 8.50009 -0.0644531C10.3958 -0.0644531 12.2139 0.688616 13.5543 2.02909C14.8948 3.36956 15.6479 5.18763 15.6479 7.08334C15.6479 10.8538 9.67946 16.0083 8.99875 16.5835L8.50009 17.0057ZM8.50009 1.48113C7.0148 1.48282 5.59084 2.07359 4.54059 3.12384C3.49033 4.1741 2.89956 5.59806 2.89787 7.08334C2.89787 9.452 6.56987 13.236 8.50009 14.9678C10.4303 13.2352 14.1023 9.44917 14.1023 7.08334C14.1006 5.59806 13.5098 4.1741 12.4596 3.12384C11.4093 2.07359 9.98537 1.48282 8.50009 1.48113Z" fill="#707070" />
//                   <path d="M8.50008 9.91666C7.9397 9.91666 7.39191 9.7505 6.92597 9.43917C6.46003 9.12784 6.09687 8.68533 5.88242 8.16761C5.66798 7.64988 5.61186 7.08019 5.7212 6.53058C5.83052 5.98097 6.10036 5.47612 6.49662 5.07986C6.89286 4.68362 7.39771 4.41376 7.94734 4.30444C8.49695 4.19512 9.06663 4.25123 9.58436 4.46568C10.1021 4.68013 10.5446 5.04329 10.8559 5.50922C11.1672 5.97516 11.3334 6.52295 11.3334 7.08334C11.3334 7.83478 11.0349 8.55545 10.5036 9.0868C9.97221 9.61816 9.25154 9.91666 8.50008 9.91666ZM8.50008 5.66666C8.2199 5.66666 7.946 5.74975 7.71302 5.90542C7.48006 6.06109 7.29848 6.28234 7.19125 6.5412C7.08404 6.80007 7.05597 7.08491 7.11063 7.35972C7.1653 7.63452 7.30022 7.88694 7.49835 8.08507C7.69648 8.2832 7.9489 8.41811 8.2237 8.47279C8.49851 8.52745 8.78336 8.49939 9.04221 8.39217C9.30108 8.28494 9.52233 8.10337 9.678 7.8704C9.83367 7.63742 9.91675 7.36353 9.91675 7.08334C9.91675 6.70761 9.7675 6.34728 9.50181 6.08161C9.23614 5.81592 8.87581 5.66666 8.50008 5.66666Z" fill="#707070" />
//                 </g>
//               </g>
//               <defs>
//                 <clipPath id="clip0_650_3351">
//                   <rect width="17" height="17" fill="white" />
//                 </clipPath>
//               </defs>
//             </svg>
//             <select className="attendance-input-field date-field" type="text"
//               value={selectedStatus}
//               onChange={(e) => setSelectedStatus(e.target.value)}
//             >
//               <option value="">All</option>

//               <option value="active">Active</option>
//               <option value="inactive">Inactive</option>



//             </select>
//           </div>

//         </div>
//       </div>

//       <div className="custom-table-width">
//       <div className="table-heading-flex">
//           <div className="repo-heading">Attendance ( {filteredEmployee.length} of {allattendance.length})
//           </div>
//           <div>

//           </div>
//         </div>
//       <table className="custom-table">


//         <thead >
//           <tr className="custom-table-head-tr">
//             <th className="align-left">Sr No. </th>
//             <th className="align-left">Emp Code</th>
//             <th className="align-left">Name</th>
//             <th className="align-left">Joining Date</th>
//             <th className="align-left">Total Days</th>
//             <th className="align-left">Pres.</th>
//             <th className="align-left">Abse.</th>
//             <th className="align-left">RLM</th>
//             <th className="align-left">Late</th>
//             <th className="align-left">Ont.</th>
//             <th className="align-left">HD</th>
//             <th className="align-left">WO</th>
//             <th className="align-left">WOP</th>
//             <th className="align-left">Hols</th>
//             <th className="align-left">OD.</th>
//             <th className="align-left">HD. OD.</th>
//             <th className="align-left">SD. OD.</th>
//             <th className="align-left">PL.</th>
//             <th className="align-left">HD. L.</th>
//             <th className="align-left">SD. L.</th>
//             <th className="align-left">UL.</th>
//             <th className="align-left">Hd. UL.</th>
//             <th className="align-left">Sd. UL.</th>


//             {/* <th className="align-left">Status</th> */}
//           </tr>

//         </thead>
//         <tbody>
//           {filteredEmployee
//             .sort((a, b) => a.emp_code - b.emp_code)
//             .map((data, index) => {

//               return (
//                 <>
//                   <tr className="custom-table-head-td"
//                     >
//                     <td className="align-left">{index+1}</td>
//                     <td className="align-left">{data.emp_code}</td>
//                     <td className="align-left">{data?.name}</td>
//                     <td className="align-left">{data.joining_date}</td>

//                     {/* {show ? <td className="align-left" onClick={handleClose(index+1)}>{data.total_days ? data.total_days : "-"}</td>  : <td className="align-left" onClick={handleShow(index+1)}>{data.total_days ? data.total_days : "-"}</td>} */}

//                     {show ? (
//   <td
//     className="align-left"
//     onClick={() => handleClose(index + 1)}
//   >
//     <a>{data.total_days ? data.total_days : "-"}</a>
//   </td>
// ) : (
//   <td
//     className="align-left"
//     onClick={() => handleShow(index + 1)}
//   >
//     <a>{data.total_days ? data.total_days : "-"}</a>
//   </td>
// )}


// {/* 
//                     <td className="align-left" onClick={handleShow}>{data.total_days ? data.total_days : "-"}</td> */}
//                     <td className="align-left">{data.present}</td>
//                     <td className="align-left">{data.absent}</td>
//                     <td className="align-left">{data.rlm}</td>
//                     <td className="align-left">{data.late}</td>
//                     <td className="align-left">{data.ontime}</td>
//                     <td className="align-left">{data.halfday}</td>
//                     <td className="align-left">{data.weeklyoff}</td>
//                     <td className="align-left">{data.wop}</td>
//                     <td className="align-left">{data.holiday}</td>
//                     <td className="align-left">{data.outduty}</td>
//                     <td className="align-left">{data.hdoutduty}</td>
//                     <td className="align-left">{data.shortoutduty}</td>
//                     <td className="align-left">{data?.PaidLeave}</td>
//                     <td className="align-left">{data.halfdayleave}</td>
//                     <td className="align-left">{data.shortleave}</td>
//                     <td className="align-left">{data.unpaidleave}</td>
//                     <td className="align-left">{data.unpaidleavehd}</td>
//                     <td className="align-left">{data.unpaidshortleave}</td>




//                   </tr>
//                   {(show === true ) && (index+1 === indexRow)  ? 
//                   <tr>
//                     <td colSpan="28">
//                       <table className="custom-table-subtable">


//                   <tr className="custom-table-head-tdd">
// {datees.map((date) => (
//         <td className='summarysheet-heading-circle' key={date.date}>{date.date}</td>
//       ))}
// </tr>

// <tr className="custom-table-head-tdd">


// {dates.map((date) => {
//         const attendanceForDate = data.attendances.find(
//           (attendance) => attendance.date === date.date
//         );
//         const attendanceStatus = attendanceForDate
//           ? attendanceForDate.day_status
//           : "-";

//         const attendanceStatuss = attendanceForDate
//           ? attendanceForDate.day_status === "outduty"
//             ? "OD"
//             : attendanceForDate.day_status === "ontime"
//               ? "P"
//               : attendanceForDate.day_status === "halfday"
//                 ? "HD"
//                 : attendanceForDate.day_status === "leave"
//                   ? "L"
//                   : attendanceForDate.day_status === "late"
//                     ? "Late"
//                     : attendanceForDate.day_status === "weeklyoff"
//                       ? "WO"
//                       : attendanceForDate.day_status === "wop"
//                         ? "WOP"
//                         : attendanceForDate.day_status === "rlm"
//                           ? "RLM"
//                           : attendanceForDate.day_status === "halfdayleave"
//                             ? "L"
//                             : attendanceForDate.day_status === "absent"
//                               ? "A"
//                               : attendanceForDate.day_status.slice(0, 2)
//           : "-";

//         // // Example usage:
//         // <span>{attendanceStatus}</span>
//         // {attendanceStatuss.slice(0, 2).toUpperCase()}



//         const inTime = attendanceForDate ? attendanceForDate.in_time : "-";
//         const outTime = attendanceForDate ? attendanceForDate.out_time : "-";
//         const attstat = attendanceForDate ? attendanceForDate.attendence_status : "-";
//         const daystatus = attendanceForDate ? attendanceForDate.day_status : "-";






//         return (
//           // <>
//           <td className='summarysheet-heading-circle' data-status={attendanceStatus}
//             title={`In Time: ${inTime}\nOut Time: ${outTime}\nAttendance Status: ${attstat}\nDay Status: ${daystatus}`} >
//             {attendanceStatuss}
//           </td>


//         );
//       })}
// </tr>
//    </table>                 
// </td>   </tr> : "" }           </>
//               )
//             })}
//         </tbody>
//       </table>
// </div>


//     </>
//   );
// };


const SummarySheet = () => {

  const [show, setShow] = useState(false);


  const [indexRow, setIndexRow] = useState({});

  const handleClose = () => setShow(false);
  const handleShow = (e) => {
    setShow(true);
    setIndexRow(e)
  }


  console.log(indexRow)


  const today = new Date();
  today.setDate(today.getDate() - 1);
  const year = today.getFullYear();
  const month = today.getMonth() + 1;
  const day = today.getDate();


  const formattedDate = `${year}-${month.toString().padStart(2, "0")}-${day
    .toString()
    .padStart(2, "0")}`;
  var datearray = formattedDate.split("-");

  var newdate = datearray[0] + "-" + datearray[1] + "-" + datearray[2];
  console.log(newdate)

  // /attandance/employattandancessheet/222/2/2023/

  const [monthh, setMonthh] = useState(`${month}`);

  const [yearr, setYearr] = useState(`${year}`);
  useEffect(() => {
    getAllattendancedata();
  }, [monthh, yearr]);

  const [allattendance, setAllattendance] = useState([]);


  let getAllattendancedata = async () => {
    try {
      const res = await axios.get(
        `${BASE_URL}/wfm/employeeattandancessheetbyrh/${monthh}/${yearr}/${sessionStorage.getItem('email')}/`
        // `${BASE_URL}/wfm/employeeattandancessheetall/${monthh}/${yearr}/`
      );
      setAllattendance(res.data);

    } catch (err) {
      alert(err.message);
    }
  };



  const getAllDatesOfMonth = (month, year) => {
    const numberOfDaysInMonth = new Date(year, month, 0).getDate();
    const dates = [];
    const datees = [];

    for (let i = 1; i <= numberOfDaysInMonth; i++) {
      const date = new Date(year, month - 1, i);
      const day = date.getDate();
      const formatttedDatee = `${day.toString().padStart(2, "0")}`;
      datees.push({ date: formatttedDatee });
      const formattedDate = `${year}-${month.toString().padStart(2, "0")}-${day
        .toString()
        .padStart(2, "0")}`;
      dates.push({ date: formattedDate });
    }

    return dates;
  };

  const dates = getAllDatesOfMonth(monthh, yearr).map((date, index) => ({
    ...date,
    index,
  }));

  const getAllDatessOfMonth = (month, year) => {
    const numberOfDaysInMonth = new Date(year, month, 0).getDate();
    const datees = [];

    for (let i = 1; i <= numberOfDaysInMonth; i++) {
      const date = new Date(year, month - 1, i);
      const day = date.getDate();
      const formatttedDatee = `${day.toString().padStart(2, "0")}`;
      datees.push({ date: formatttedDatee });
    }

    return datees;
  };

  const datees = getAllDatessOfMonth(monthh, yearr); // get all dates for March 2023



  // *********** Filter Logic ***********
  const [filteredEmployee, setFilteredEmployee] = useState([]);


  // const [selectedStatus, setSelectedStatus] = useState('');
  const [selectedName, setName] = useState('');
  const [selectedDep, setDep] = useState('');

  const [selectedStatus, setSelectedStatus] = useState('active');



  const applyFilters = () => {
    // Filter employees based on selectedStatus and selectedName
    let filteredData = allattendance;



    if (selectedStatus !== '') {
      filteredData = filteredData.filter(employee => employee.employee_status === selectedStatus);
    }

    if (selectedName) {
      // Use includes for a case-insensitive search for the name only
      const lowercaseSelectedName = selectedName.toLowerCase();
      filteredData = filteredData.filter(employee => {
        const nameMatch = employee.name.toLowerCase().includes(lowercaseSelectedName);
        const empCodeMatch = employee.emp_code.toString().includes(selectedName);
        return nameMatch || empCodeMatch;
      });
    }

    if (selectedDep) {
      const lowercaseSelectedName = selectedDep.toLowerCase();

      filteredData = filteredData.filter(employee => {
        const depMatch = employee.department_name.toLowerCase().includes(lowercaseSelectedName);
        const rhMatch = employee.rh_name.toLowerCase().includes(selectedDep);
        return depMatch || rhMatch;
      });
    }


    setFilteredEmployee(filteredData);

  };

  useEffect(() => {

    applyFilters();
  }, [selectedName, selectedStatus, selectedDep, allattendance]);


  return (
    <>

      <div className="attendance-history-cont">
        <div className="attendance-heading"></div>

        <div className="attendance-subcont">
          <div className="field-cont">
            <input type="number"
              maxLength={4} value={yearr}
              onChange={(e) => setYearr(e.target.value)} className="attendance-input-field date-field" /><hr />
            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 14 14" fill="none">
              <g clip-path="url(#clip0_650_3324)">
                <path d="M2.625 10.2083H1.16667C0.857247 10.2083 0.560501 10.3312 0.341709 10.55C0.122916 10.7688 0 11.0655 0 11.3749L0 12.8333C0 13.1427 0.122916 13.4394 0.341709 13.6582C0.560501 13.877 0.857247 13.9999 1.16667 13.9999H2.625C2.93442 13.9999 3.23117 13.877 3.44996 13.6582C3.66875 13.4394 3.79167 13.1427 3.79167 12.8333V11.3749C3.79167 11.0655 3.66875 10.7688 3.44996 10.55C3.23117 10.3312 2.93442 10.2083 2.625 10.2083ZM2.625 12.8333H1.16667V11.3749H2.625V12.8333Z" fill="#707070" />
                <path d="M12.8333 10.2083H11.3749C11.0655 10.2083 10.7688 10.3312 10.55 10.55C10.3312 10.7688 10.2083 11.0655 10.2083 11.3749V12.8333C10.2083 13.1427 10.3312 13.4394 10.55 13.6582C10.7688 13.877 11.0655 13.9999 11.3749 13.9999H12.8333C13.1427 13.9999 13.4394 13.877 13.6582 13.6582C13.877 13.4394 13.9999 13.1427 13.9999 12.8333V11.3749C13.9999 11.0655 13.877 10.7688 13.6582 10.55C13.4394 10.3312 13.1427 10.2083 12.8333 10.2083ZM12.8333 12.8333H11.3749V11.3749H12.8333V12.8333Z" fill="#707070" />
                <path d="M2.625 5.10425H1.16667C0.857247 5.10425 0.560501 5.22716 0.341709 5.44596C0.122916 5.66475 0 5.9615 0 6.27091L0 7.72925C0 8.03867 0.122916 8.33541 0.341709 8.55421C0.560501 8.773 0.857247 8.89592 1.16667 8.89592H2.625C2.93442 8.89592 3.23117 8.773 3.44996 8.55421C3.66875 8.33541 3.79167 8.03867 3.79167 7.72925V6.27091C3.79167 5.9615 3.66875 5.66475 3.44996 5.44596C3.23117 5.22716 2.93442 5.10425 2.625 5.10425ZM2.625 7.72925H1.16667V6.27091H2.625V7.72925Z" fill="#707070" />
                <path d="M12.8333 5.10425H11.3749C11.0655 5.10425 10.7688 5.22716 10.55 5.44596C10.3312 5.66475 10.2083 5.9615 10.2083 6.27091V7.72925C10.2083 8.03867 10.3312 8.33541 10.55 8.55421C10.7688 8.773 11.0655 8.89592 11.3749 8.89592H12.8333C13.1427 8.89592 13.4394 8.773 13.6582 8.55421C13.877 8.33541 13.9999 8.03867 13.9999 7.72925V6.27091C13.9999 5.9615 13.877 5.66475 13.6582 5.44596C13.4394 5.22716 13.1427 5.10425 12.8333 5.10425ZM12.8333 7.72925H11.3749V6.27091H12.8333V7.72925Z" fill="#707070" />
                <path d="M2.625 0H1.16667C0.857247 0 0.560501 0.122916 0.341709 0.341709C0.122916 0.560501 0 0.857247 0 1.16667L0 2.625C0 2.93442 0.122916 3.23117 0.341709 3.44996C0.560501 3.66875 0.857247 3.79167 1.16667 3.79167H2.625C2.93442 3.79167 3.23117 3.66875 3.44996 3.44996C3.66875 3.23117 3.79167 2.93442 3.79167 2.625V1.16667C3.79167 0.857247 3.66875 0.560501 3.44996 0.341709C3.23117 0.122916 2.93442 0 2.625 0ZM2.625 2.625H1.16667V1.16667H2.625V2.625Z" fill="#707070" />
                <path d="M7.72925 10.2083H6.27091C5.9615 10.2083 5.66475 10.3312 5.44596 10.55C5.22716 10.7688 5.10425 11.0655 5.10425 11.3749V12.8333C5.10425 13.1427 5.22716 13.4394 5.44596 13.6582C5.66475 13.877 5.9615 13.9999 6.27091 13.9999H7.72925C8.03867 13.9999 8.33541 13.877 8.55421 13.6582C8.773 13.4394 8.89592 13.1427 8.89592 12.8333V11.3749C8.89592 11.0655 8.773 10.7688 8.55421 10.55C8.33541 10.3312 8.03867 10.2083 7.72925 10.2083ZM7.72925 12.8333H6.27091V11.3749H7.72925V12.8333Z" fill="#707070" />
                <path d="M7.72925 5.10425H6.27091C5.9615 5.10425 5.66475 5.22716 5.44596 5.44596C5.22716 5.66475 5.10425 5.9615 5.10425 6.27091V7.72925C5.10425 8.03867 5.22716 8.33541 5.44596 8.55421C5.66475 8.773 5.9615 8.89592 6.27091 8.89592H7.72925C8.03867 8.89592 8.33541 8.773 8.55421 8.55421C8.773 8.33541 8.89592 8.03867 8.89592 7.72925V6.27091C8.89592 5.9615 8.773 5.66475 8.55421 5.44596C8.33541 5.22716 8.03867 5.10425 7.72925 5.10425ZM7.72925 7.72925H6.27091V6.27091H7.72925V7.72925Z" fill="#707070" />
                <path d="M7.72925 0H6.27091C5.9615 0 5.66475 0.122916 5.44596 0.341709C5.22716 0.560501 5.10425 0.857247 5.10425 1.16667V2.625C5.10425 2.93442 5.22716 3.23117 5.44596 3.44996C5.66475 3.66875 5.9615 3.79167 6.27091 3.79167H7.72925C8.03867 3.79167 8.33541 3.66875 8.55421 3.44996C8.773 3.23117 8.89592 2.93442 8.89592 2.625V1.16667C8.89592 0.857247 8.773 0.560501 8.55421 0.341709C8.33541 0.122916 8.03867 0 7.72925 0ZM7.72925 2.625H6.27091V1.16667H7.72925V2.625Z" fill="#707070" />
                <path d="M12.8333 0H11.3749C11.0655 0 10.7688 0.122916 10.55 0.341709C10.3312 0.560501 10.2083 0.857247 10.2083 1.16667V2.625C10.2083 2.93442 10.3312 3.23117 10.55 3.44996C10.7688 3.66875 11.0655 3.79167 11.3749 3.79167H12.8333C13.1427 3.79167 13.4394 3.66875 13.6582 3.44996C13.877 3.23117 13.9999 2.93442 13.9999 2.625V1.16667C13.9999 0.857247 13.877 0.560501 13.6582 0.341709C13.4394 0.122916 13.1427 0 12.8333 0ZM12.8333 2.625H11.3749V1.16667H12.8333V2.625Z" fill="#707070" />
              </g>
              <defs>
                <clipPath id="clip0_650_3324">
                  <rect width="14" height="14" fill="white" />
                </clipPath>
              </defs>
            </svg>

            <select className="attendance-input-field date-field" type="text"
              value={monthh}
              onChange={(e) => setMonthh(e.target.value)}
            >
              {/* <option value="">All</option> */}
              <option value="1">January</option>
              <option value="2">February</option>
              <option value="3">March</option>
              <option value="4">April</option>
              <option value="5">May</option>
              <option value="6">June</option>
              <option value="7">July</option>
              <option value="8">August</option>
              <option value="9">September</option>
              <option value="10">October</option>
              <option value="11">November</option>
              <option value="12">December</option>





            </select>
            <hr />

            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 14 14" fill="none">
              <g clip-path="url(#clip0_650_3324)">
                <path d="M2.625 10.2083H1.16667C0.857247 10.2083 0.560501 10.3312 0.341709 10.55C0.122916 10.7688 0 11.0655 0 11.3749L0 12.8333C0 13.1427 0.122916 13.4394 0.341709 13.6582C0.560501 13.877 0.857247 13.9999 1.16667 13.9999H2.625C2.93442 13.9999 3.23117 13.877 3.44996 13.6582C3.66875 13.4394 3.79167 13.1427 3.79167 12.8333V11.3749C3.79167 11.0655 3.66875 10.7688 3.44996 10.55C3.23117 10.3312 2.93442 10.2083 2.625 10.2083ZM2.625 12.8333H1.16667V11.3749H2.625V12.8333Z" fill="#707070" />
                <path d="M12.8333 10.2083H11.3749C11.0655 10.2083 10.7688 10.3312 10.55 10.55C10.3312 10.7688 10.2083 11.0655 10.2083 11.3749V12.8333C10.2083 13.1427 10.3312 13.4394 10.55 13.6582C10.7688 13.877 11.0655 13.9999 11.3749 13.9999H12.8333C13.1427 13.9999 13.4394 13.877 13.6582 13.6582C13.877 13.4394 13.9999 13.1427 13.9999 12.8333V11.3749C13.9999 11.0655 13.877 10.7688 13.6582 10.55C13.4394 10.3312 13.1427 10.2083 12.8333 10.2083ZM12.8333 12.8333H11.3749V11.3749H12.8333V12.8333Z" fill="#707070" />
                <path d="M2.625 5.10425H1.16667C0.857247 5.10425 0.560501 5.22716 0.341709 5.44596C0.122916 5.66475 0 5.9615 0 6.27091L0 7.72925C0 8.03867 0.122916 8.33541 0.341709 8.55421C0.560501 8.773 0.857247 8.89592 1.16667 8.89592H2.625C2.93442 8.89592 3.23117 8.773 3.44996 8.55421C3.66875 8.33541 3.79167 8.03867 3.79167 7.72925V6.27091C3.79167 5.9615 3.66875 5.66475 3.44996 5.44596C3.23117 5.22716 2.93442 5.10425 2.625 5.10425ZM2.625 7.72925H1.16667V6.27091H2.625V7.72925Z" fill="#707070" />
                <path d="M12.8333 5.10425H11.3749C11.0655 5.10425 10.7688 5.22716 10.55 5.44596C10.3312 5.66475 10.2083 5.9615 10.2083 6.27091V7.72925C10.2083 8.03867 10.3312 8.33541 10.55 8.55421C10.7688 8.773 11.0655 8.89592 11.3749 8.89592H12.8333C13.1427 8.89592 13.4394 8.773 13.6582 8.55421C13.877 8.33541 13.9999 8.03867 13.9999 7.72925V6.27091C13.9999 5.9615 13.877 5.66475 13.6582 5.44596C13.4394 5.22716 13.1427 5.10425 12.8333 5.10425ZM12.8333 7.72925H11.3749V6.27091H12.8333V7.72925Z" fill="#707070" />
                <path d="M2.625 0H1.16667C0.857247 0 0.560501 0.122916 0.341709 0.341709C0.122916 0.560501 0 0.857247 0 1.16667L0 2.625C0 2.93442 0.122916 3.23117 0.341709 3.44996C0.560501 3.66875 0.857247 3.79167 1.16667 3.79167H2.625C2.93442 3.79167 3.23117 3.66875 3.44996 3.44996C3.66875 3.23117 3.79167 2.93442 3.79167 2.625V1.16667C3.79167 0.857247 3.66875 0.560501 3.44996 0.341709C3.23117 0.122916 2.93442 0 2.625 0ZM2.625 2.625H1.16667V1.16667H2.625V2.625Z" fill="#707070" />
                <path d="M7.72925 10.2083H6.27091C5.9615 10.2083 5.66475 10.3312 5.44596 10.55C5.22716 10.7688 5.10425 11.0655 5.10425 11.3749V12.8333C5.10425 13.1427 5.22716 13.4394 5.44596 13.6582C5.66475 13.877 5.9615 13.9999 6.27091 13.9999H7.72925C8.03867 13.9999 8.33541 13.877 8.55421 13.6582C8.773 13.4394 8.89592 13.1427 8.89592 12.8333V11.3749C8.89592 11.0655 8.773 10.7688 8.55421 10.55C8.33541 10.3312 8.03867 10.2083 7.72925 10.2083ZM7.72925 12.8333H6.27091V11.3749H7.72925V12.8333Z" fill="#707070" />
                <path d="M7.72925 5.10425H6.27091C5.9615 5.10425 5.66475 5.22716 5.44596 5.44596C5.22716 5.66475 5.10425 5.9615 5.10425 6.27091V7.72925C5.10425 8.03867 5.22716 8.33541 5.44596 8.55421C5.66475 8.773 5.9615 8.89592 6.27091 8.89592H7.72925C8.03867 8.89592 8.33541 8.773 8.55421 8.55421C8.773 8.33541 8.89592 8.03867 8.89592 7.72925V6.27091C8.89592 5.9615 8.773 5.66475 8.55421 5.44596C8.33541 5.22716 8.03867 5.10425 7.72925 5.10425ZM7.72925 7.72925H6.27091V6.27091H7.72925V7.72925Z" fill="#707070" />
                <path d="M7.72925 0H6.27091C5.9615 0 5.66475 0.122916 5.44596 0.341709C5.22716 0.560501 5.10425 0.857247 5.10425 1.16667V2.625C5.10425 2.93442 5.22716 3.23117 5.44596 3.44996C5.66475 3.66875 5.9615 3.79167 6.27091 3.79167H7.72925C8.03867 3.79167 8.33541 3.66875 8.55421 3.44996C8.773 3.23117 8.89592 2.93442 8.89592 2.625V1.16667C8.89592 0.857247 8.773 0.560501 8.55421 0.341709C8.33541 0.122916 8.03867 0 7.72925 0ZM7.72925 2.625H6.27091V1.16667H7.72925V2.625Z" fill="#707070" />
                <path d="M12.8333 0H11.3749C11.0655 0 10.7688 0.122916 10.55 0.341709C10.3312 0.560501 10.2083 0.857247 10.2083 1.16667V2.625C10.2083 2.93442 10.3312 3.23117 10.55 3.44996C10.7688 3.66875 11.0655 3.79167 11.3749 3.79167H12.8333C13.1427 3.79167 13.4394 3.66875 13.6582 3.44996C13.877 3.23117 13.9999 2.93442 13.9999 2.625V1.16667C13.9999 0.857247 13.877 0.560501 13.6582 0.341709C13.4394 0.122916 13.1427 0 12.8333 0ZM12.8333 2.625H11.3749V1.16667H12.8333V2.625Z" fill="#707070" />
              </g>
              <defs>
                <clipPath id="clip0_650_3324">
                  <rect width="14" height="14" fill="white" />
                </clipPath>
              </defs>
            </svg><input className="attendance-input-field" placeholder='Name or Code' type="text" value={selectedName}
              onChange={(e) => setName(e.target.value)} />


            <hr />

            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 14 14" fill="none">
              <g clip-path="url(#clip0_650_3324)">
                <path d="M2.625 10.2083H1.16667C0.857247 10.2083 0.560501 10.3312 0.341709 10.55C0.122916 10.7688 0 11.0655 0 11.3749L0 12.8333C0 13.1427 0.122916 13.4394 0.341709 13.6582C0.560501 13.877 0.857247 13.9999 1.16667 13.9999H2.625C2.93442 13.9999 3.23117 13.877 3.44996 13.6582C3.66875 13.4394 3.79167 13.1427 3.79167 12.8333V11.3749C3.79167 11.0655 3.66875 10.7688 3.44996 10.55C3.23117 10.3312 2.93442 10.2083 2.625 10.2083ZM2.625 12.8333H1.16667V11.3749H2.625V12.8333Z" fill="#707070" />
                <path d="M12.8333 10.2083H11.3749C11.0655 10.2083 10.7688 10.3312 10.55 10.55C10.3312 10.7688 10.2083 11.0655 10.2083 11.3749V12.8333C10.2083 13.1427 10.3312 13.4394 10.55 13.6582C10.7688 13.877 11.0655 13.9999 11.3749 13.9999H12.8333C13.1427 13.9999 13.4394 13.877 13.6582 13.6582C13.877 13.4394 13.9999 13.1427 13.9999 12.8333V11.3749C13.9999 11.0655 13.877 10.7688 13.6582 10.55C13.4394 10.3312 13.1427 10.2083 12.8333 10.2083ZM12.8333 12.8333H11.3749V11.3749H12.8333V12.8333Z" fill="#707070" />
                <path d="M2.625 5.10425H1.16667C0.857247 5.10425 0.560501 5.22716 0.341709 5.44596C0.122916 5.66475 0 5.9615 0 6.27091L0 7.72925C0 8.03867 0.122916 8.33541 0.341709 8.55421C0.560501 8.773 0.857247 8.89592 1.16667 8.89592H2.625C2.93442 8.89592 3.23117 8.773 3.44996 8.55421C3.66875 8.33541 3.79167 8.03867 3.79167 7.72925V6.27091C3.79167 5.9615 3.66875 5.66475 3.44996 5.44596C3.23117 5.22716 2.93442 5.10425 2.625 5.10425ZM2.625 7.72925H1.16667V6.27091H2.625V7.72925Z" fill="#707070" />
                <path d="M12.8333 5.10425H11.3749C11.0655 5.10425 10.7688 5.22716 10.55 5.44596C10.3312 5.66475 10.2083 5.9615 10.2083 6.27091V7.72925C10.2083 8.03867 10.3312 8.33541 10.55 8.55421C10.7688 8.773 11.0655 8.89592 11.3749 8.89592H12.8333C13.1427 8.89592 13.4394 8.773 13.6582 8.55421C13.877 8.33541 13.9999 8.03867 13.9999 7.72925V6.27091C13.9999 5.9615 13.877 5.66475 13.6582 5.44596C13.4394 5.22716 13.1427 5.10425 12.8333 5.10425ZM12.8333 7.72925H11.3749V6.27091H12.8333V7.72925Z" fill="#707070" />
                <path d="M2.625 0H1.16667C0.857247 0 0.560501 0.122916 0.341709 0.341709C0.122916 0.560501 0 0.857247 0 1.16667L0 2.625C0 2.93442 0.122916 3.23117 0.341709 3.44996C0.560501 3.66875 0.857247 3.79167 1.16667 3.79167H2.625C2.93442 3.79167 3.23117 3.66875 3.44996 3.44996C3.66875 3.23117 3.79167 2.93442 3.79167 2.625V1.16667C3.79167 0.857247 3.66875 0.560501 3.44996 0.341709C3.23117 0.122916 2.93442 0 2.625 0ZM2.625 2.625H1.16667V1.16667H2.625V2.625Z" fill="#707070" />
                <path d="M7.72925 10.2083H6.27091C5.9615 10.2083 5.66475 10.3312 5.44596 10.55C5.22716 10.7688 5.10425 11.0655 5.10425 11.3749V12.8333C5.10425 13.1427 5.22716 13.4394 5.44596 13.6582C5.66475 13.877 5.9615 13.9999 6.27091 13.9999H7.72925C8.03867 13.9999 8.33541 13.877 8.55421 13.6582C8.773 13.4394 8.89592 13.1427 8.89592 12.8333V11.3749C8.89592 11.0655 8.773 10.7688 8.55421 10.55C8.33541 10.3312 8.03867 10.2083 7.72925 10.2083ZM7.72925 12.8333H6.27091V11.3749H7.72925V12.8333Z" fill="#707070" />
                <path d="M7.72925 5.10425H6.27091C5.9615 5.10425 5.66475 5.22716 5.44596 5.44596C5.22716 5.66475 5.10425 5.9615 5.10425 6.27091V7.72925C5.10425 8.03867 5.22716 8.33541 5.44596 8.55421C5.66475 8.773 5.9615 8.89592 6.27091 8.89592H7.72925C8.03867 8.89592 8.33541 8.773 8.55421 8.55421C8.773 8.33541 8.89592 8.03867 8.89592 7.72925V6.27091C8.89592 5.9615 8.773 5.66475 8.55421 5.44596C8.33541 5.22716 8.03867 5.10425 7.72925 5.10425ZM7.72925 7.72925H6.27091V6.27091H7.72925V7.72925Z" fill="#707070" />
                <path d="M7.72925 0H6.27091C5.9615 0 5.66475 0.122916 5.44596 0.341709C5.22716 0.560501 5.10425 0.857247 5.10425 1.16667V2.625C5.10425 2.93442 5.22716 3.23117 5.44596 3.44996C5.66475 3.66875 5.9615 3.79167 6.27091 3.79167H7.72925C8.03867 3.79167 8.33541 3.66875 8.55421 3.44996C8.773 3.23117 8.89592 2.93442 8.89592 2.625V1.16667C8.89592 0.857247 8.773 0.560501 8.55421 0.341709C8.33541 0.122916 8.03867 0 7.72925 0ZM7.72925 2.625H6.27091V1.16667H7.72925V2.625Z" fill="#707070" />
                <path d="M12.8333 0H11.3749C11.0655 0 10.7688 0.122916 10.55 0.341709C10.3312 0.560501 10.2083 0.857247 10.2083 1.16667V2.625C10.2083 2.93442 10.3312 3.23117 10.55 3.44996C10.7688 3.66875 11.0655 3.79167 11.3749 3.79167H12.8333C13.1427 3.79167 13.4394 3.66875 13.6582 3.44996C13.877 3.23117 13.9999 2.93442 13.9999 2.625V1.16667C13.9999 0.857247 13.877 0.560501 13.6582 0.341709C13.4394 0.122916 13.1427 0 12.8333 0ZM12.8333 2.625H11.3749V1.16667H12.8333V2.625Z" fill="#707070" />
              </g>
              <defs>
                <clipPath id="clip0_650_3324">
                  <rect width="14" height="14" fill="white" />
                </clipPath>
              </defs>
            </svg><input className="attendance-input-field" placeholder='Dep || Rep Head Name' type="text" value={selectedDep}
              onChange={(e) => setDep(e.target.value)} />

            <hr />
            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="38" viewBox="0 0 17 17" fill="none">
              <g clip-path="url(#clip0_650_3351)">
                <mask id="mask0_650_3351" maskUnits="userSpaceOnUse" x="0" y="0" width="17" height="17">
                  <path d="M17 0H0V17H17V0Z" fill="white" />
                </mask>
                <g mask="url(#mask0_650_3351)">
                  <path d="M8.50009 17.0057L8.00425 16.5807C7.32071 16.0083 1.35229 10.8538 1.35229 7.08334C1.35229 5.18763 2.10536 3.36956 3.44583 2.02909C4.78631 0.688616 6.60437 -0.0644531 8.50009 -0.0644531C10.3958 -0.0644531 12.2139 0.688616 13.5543 2.02909C14.8948 3.36956 15.6479 5.18763 15.6479 7.08334C15.6479 10.8538 9.67946 16.0083 8.99875 16.5835L8.50009 17.0057ZM8.50009 1.48113C7.0148 1.48282 5.59084 2.07359 4.54059 3.12384C3.49033 4.1741 2.89956 5.59806 2.89787 7.08334C2.89787 9.452 6.56987 13.236 8.50009 14.9678C10.4303 13.2352 14.1023 9.44917 14.1023 7.08334C14.1006 5.59806 13.5098 4.1741 12.4596 3.12384C11.4093 2.07359 9.98537 1.48282 8.50009 1.48113Z" fill="#707070" />
                  <path d="M8.50008 9.91666C7.9397 9.91666 7.39191 9.7505 6.92597 9.43917C6.46003 9.12784 6.09687 8.68533 5.88242 8.16761C5.66798 7.64988 5.61186 7.08019 5.7212 6.53058C5.83052 5.98097 6.10036 5.47612 6.49662 5.07986C6.89286 4.68362 7.39771 4.41376 7.94734 4.30444C8.49695 4.19512 9.06663 4.25123 9.58436 4.46568C10.1021 4.68013 10.5446 5.04329 10.8559 5.50922C11.1672 5.97516 11.3334 6.52295 11.3334 7.08334C11.3334 7.83478 11.0349 8.55545 10.5036 9.0868C9.97221 9.61816 9.25154 9.91666 8.50008 9.91666ZM8.50008 5.66666C8.2199 5.66666 7.946 5.74975 7.71302 5.90542C7.48006 6.06109 7.29848 6.28234 7.19125 6.5412C7.08404 6.80007 7.05597 7.08491 7.11063 7.35972C7.1653 7.63452 7.30022 7.88694 7.49835 8.08507C7.69648 8.2832 7.9489 8.41811 8.2237 8.47279C8.49851 8.52745 8.78336 8.49939 9.04221 8.39217C9.30108 8.28494 9.52233 8.10337 9.678 7.8704C9.83367 7.63742 9.91675 7.36353 9.91675 7.08334C9.91675 6.70761 9.7675 6.34728 9.50181 6.08161C9.23614 5.81592 8.87581 5.66666 8.50008 5.66666Z" fill="#707070" />
                </g>
              </g>
              <defs>
                <clipPath id="clip0_650_3351">
                  <rect width="17" height="17" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <select className="attendance-input-field date-field" type="text"
              value={selectedStatus}
              onChange={(e) => setSelectedStatus(e.target.value)}
            >
              <option value="">All</option>

              <option value="active">Active</option>
              <option value="inactive">Inactive</option>



            </select>
          </div>

        </div>
      </div>

      <div className="custom-table-container">
        <div className="table-heading-flex">
          <div className="repo-heading">Attendance ( {filteredEmployee.length} of {allattendance.length})
          </div>
          <div>

          </div>
        </div>
        {/* <div className='custom-table-width'> */}


        <table className="custom-tablee">


          <thead >
            <tr className="custom-table-head-trr">
              <th className="align-left">Sr No. </th>
              <th className="align-left">Emp Code</th>
              <th className="align-leftt">Name</th>
              <th className="align-left">Joining Date</th>
              <th className="align-left">Total Days</th>
              <th className="align-left">LB</th>
              <th className="align-left">Pres.</th>
              <th className="align-left">Abse.</th>
              <th className="align-left">RLM</th>
              <th className="align-left">Late</th>
              <th className="align-left">Ont.</th>
              <th className="align-left">HD</th>
              <th className="align-left">WO</th>
              <th className="align-left">WOP</th>
              <th className="align-left">Hols</th>
              <th className="align-left">OD.</th>
              <th className="align-left">HD. OD.</th>
              <th className="align-left">SD. OD.</th>
              <th className="align-left">PL.</th>
              <th className="align-left">HD. L.</th>
              <th className="align-left">SD. L.</th>
              <th className="align-left">UL.</th>
              <th className="align-left">Hd. UL.</th>
              <th className="align-left">Sd. UL.</th>


              {/* <th className="align-left">Status</th> */}
            </tr>

          </thead>
          <tbody>
            {filteredEmployee
              .sort((a, b) => a.emp_code - b.emp_code)
              .map((data, index) => {
                let total_style = dates.length


                return (
                  <>
                    <tr className="custom-table-head-tdd"
                    >
                      <td className="align-left">{index + 1}</td>
                      <td className="align-left">{data.emp_code}</td>
                      <td className="align-leftt">{data?.name}</td>
                      <td className="align-left">{formatDate(data.joining_date)}</td>

                      {/* {show ? <td className="align-left" onClick={handleClose(index+1)}>{data.total_days ? data.total_days : "-"}</td>  : <td className="align-left" onClick={handleShow(index+1)}>{data.total_days ? data.total_days : "-"}</td>} */}

                      {show ? (
                        <td
                          className="align-left"
                          onClick={() => handleClose(index + 1)}
                          style={{ color: total_style > data.total ? "red" : "" }}
                        >
                          {data.total ? data.total : "-"}
                        </td>
                      ) : (
                        <td
                          className="align-left"
                          onClick={() => handleShow(index + 1)}
                          style={{ color: total_style > data.total ? "red" : "" }}

                        >
                          {data.total ? data.total : "-"}
                        </td>
                      )}


                      {/* 
                    <td className="align-left" onClick={handleShow}>{data.total_days ? data.total_days : "-"}</td> */}
                      <td className="align-left">{data.leavebalance}</td>
                      <td className="align-left">{data.present}</td>
                      <td className="align-left" >{data.absent}</td>
                      <td className="align-left">{data.rlm}</td>
                      <td className="align-left">{data.late}</td>
                      <td className="align-left">{data.ontime}</td>
                      <td className="align-left">{data.halfday}</td>
                      <td className="align-left">{data.weeklyoff}</td>
                      <td className="align-left">{data.wop}</td>
                      <td className="align-left">{data.holiday}</td>
                      <td className="align-left">{data.outduty}</td>
                      <td className="align-left">{data.hdoutduty}</td>
                      <td className="align-left">{data.shortoutduty}</td>
                      <td className="align-left">{data.leave}</td>
                      <td className="align-left">{data.halfdayleave}</td>
                      <td className="align-left">{data.shortleave}</td>
                      <td className="align-left">{data.unpaidleave}</td>
                      <td className="align-left">{data.unpaidleavehd}</td>
                      <td className="align-left">{data.unpaidshortleave}</td>




                    </tr>
                    {(show === true) && (index + 1 === indexRow) ?
                      <tr>
                        <td colSpan="28">
                          <table className="custom-table-subtable">


                            <tr className="custom-table-head-tdd">
                              {datees.map((date) => (
                                <td className='summarysheet-heading-circle' key={date.date}>{date.date}</td>
                              ))}
                            </tr>

                            <tr className="custom-table-head-tdd">


                              {dates.map((date) => {
                                const attendanceForDate = data.attendances.find(
                                  (attendance) => attendance.date === date.date
                                );
                                const attendanceStatus = attendanceForDate
                                  ? attendanceForDate.day_status
                                  : "-";

                                const attendanceStatuss = attendanceForDate
                                  ? attendanceForDate.day_status === "ontime"
                                    ? "P"
                                    : attendanceForDate.day_status === "outduty"
                                      ? "OD"
                                      : attendanceForDate.day_status === "halfday"
                                        ? "HD"
                                        : attendanceForDate.day_status === "paidleave"
                                          ? "PL"
                                          : attendanceForDate.day_status === "late"
                                            ? "Late"
                                            : attendanceForDate.day_status === "weeklyoff"
                                              ? "WO"
                                              : attendanceForDate.day_status === "wop"
                                                ? "WOP"
                                                : attendanceForDate.day_status === "rlm"
                                                  ? "RLM"
                                                  : attendanceForDate.day_status === "halfdayleave"
                                                    ? "HDL"
                                                    : attendanceForDate.day_status === "unpaidleave"
                                                      ? "UL"
                                                      : attendanceForDate.day_status === "shortleave"
                                                        ? "SL"
                                                        : attendanceForDate.day_status === "unpaidshortleave"
                                                          ? "USL"
                                                          : attendanceForDate.day_status === "unpaidleavehd"
                                                            ? "UHD"
                                                            : attendanceForDate.day_status === "hdoutduty"
                                                              ? "HOD"
                                                              : attendanceForDate.day_status === "shortoutduty"
                                                                ? "SOD"
                                                                : attendanceForDate.day_status === "holiday"
                                                                  ? "Hol"
                                                                  : attendanceForDate.day_status === "absent"
                                                                    ? "A"
                                                                    : attendanceForDate.day_status.slice(0, 2)
                                  : "-";

                                // // Example usage:
                                // <span>{attendanceStatus}</span>
                                // {attendanceStatuss.slice(0, 2).toUpperCase()}

                                // const attendanceStatusCircle = attendanceForDate
                                //   ? attendanceForDate.attendence_status === "present"
                                //     ? "Color-tab-green"
                                //     : attendanceForDate.day_status === "absent"
                                //       ? "Color-tab-red"
                                //       : "summarysheet-circle-default" : '';



                                const inTime = attendanceForDate ? attendanceForDate.in_time : "-";
                                const outTime = attendanceForDate ? attendanceForDate.out_time : "-";
                                const attstat = attendanceForDate ? attendanceForDate.attendence_status : "-";
                                const daystatus = attendanceForDate ? attendanceForDate.day_status : "-";






                                return (
                                  // <>
                                  <td className='summarysheet-heading-circle behavior' data-status={attendanceStatus}
                                    title={`In Time: ${inTime}\nOut Time: ${outTime}\nAttendance Status: ${attstat}\nDay Status: ${daystatus}`} >
                                    {attendanceStatuss}
                                  </td>


                                );
                              })}
                            </tr>
                          </table>
                        </td>   </tr> : ""}           </>
                )
              })}
          </tbody>
        </table>



        {/* </div> */}
      </div>


    </>
  );
};

const SummarySheetExcel = () => {

  const [show, setShow] = useState(false);


  const [indexRow, setIndexRow] = useState({});

  const handleClose = () => setShow(false);
  const handleShow = (e) => {
    setShow(true);
    setIndexRow(e)
  }


  console.log(indexRow)


  const today = new Date();
  today.setDate(today.getDate() - 1);
  const year = today.getFullYear();
  const month = today.getMonth() + 1;
  const day = today.getDate();


  const formattedDate = `${year}-${month.toString().padStart(2, "0")}-${day
    .toString()
    .padStart(2, "0")}`;
  var datearray = formattedDate.split("-");

  var newdate = datearray[0] + "-" + datearray[1] + "-" + datearray[2];
  console.log(newdate)

  // /attandance/employattandancessheet/222/2/2023/

  const [monthh, setMonthh] = useState(`${month}`);

  const [yearr, setYearr] = useState(`${year}`);
  useEffect(() => {
    getAllattendancedata();
  }, [monthh, yearr]);

  const [allattendance, setAllattendance] = useState([]);


  let getAllattendancedata = async () => {
    try {
      const res = await axios.get(
        `${BASE_URL}/wfm/employeeattandancessheetbyrh/${monthh}/${yearr}/${sessionStorage.getItem('email')}/`
        // `${BASE_URL}/wfm/employeeattandancessheetall/${monthh}/${yearr}/`
      );
      setAllattendance(res.data);

    } catch (err) {
      alert(err.message);
    }
  };



  const getAllDatesOfMonth = (month, year) => {
    const numberOfDaysInMonth = new Date(year, month, 0).getDate();
    const dates = [];
    const datees = [];

    for (let i = 1; i <= numberOfDaysInMonth; i++) {
      const date = new Date(year, month - 1, i);
      const day = date.getDate();
      const formatttedDatee = `${day.toString().padStart(2, "0")}`;
      datees.push({ date: formatttedDatee });
      const formattedDate = `${year}-${month.toString().padStart(2, "0")}-${day
        .toString()
        .padStart(2, "0")}`;
      dates.push({ date: formattedDate });
    }

    return dates;
  };

  const dates = getAllDatesOfMonth(monthh, yearr).map((date, index) => ({
    ...date,
    index,
  }));

  const getAllDatessOfMonth = (month, year) => {
    const numberOfDaysInMonth = new Date(year, month, 0).getDate();
    const datees = [];

    for (let i = 1; i <= numberOfDaysInMonth; i++) {
      const date = new Date(year, month - 1, i);
      const day = date.getDate();
      const formatttedDatee = `${day.toString().padStart(2, "0")}`;
      datees.push({ date: formatttedDatee });
    }

    return datees;
  };

  const datees = getAllDatessOfMonth(monthh, yearr); // get all dates for March 2023



  // *********** Filter Logic ***********
  const [filteredEmployee, setFilteredEmployee] = useState([]);


  // const [selectedStatus, setSelectedStatus] = useState('');
  const [selectedName, setName] = useState('');
  const [selectedDep, setDep] = useState('');

  const [selectedStatus, setSelectedStatus] = useState('active');



  const applyFilters = () => {
    // Filter employees based on selectedStatus and selectedName
    let filteredData = allattendance;



    if (selectedStatus !== '') {
      filteredData = filteredData.filter(employee => employee.employee_status === selectedStatus);
    }

    if (selectedName) {
      // Use includes for a case-insensitive search for the name only
      const lowercaseSelectedName = selectedName.toLowerCase();
      filteredData = filteredData.filter(employee => {
        const nameMatch = employee.name.toLowerCase().includes(lowercaseSelectedName);
        const empCodeMatch = employee.emp_code.toString().includes(selectedName);
        return nameMatch || empCodeMatch;
      });
    }

    if (selectedDep) {
      const lowercaseSelectedName = selectedDep.toLowerCase();

      filteredData = filteredData.filter(employee => {
        const depMatch = employee.department_name.toLowerCase().includes(lowercaseSelectedName);
        const rhMatch = employee.rh_name.toLowerCase().includes(selectedDep);
        return depMatch || rhMatch;
      });
    }


    setFilteredEmployee(filteredData);

  };

  useEffect(() => {

    applyFilters();
  }, [selectedName, selectedStatus, selectedDep, allattendance]);

  const [selectedIndex, setSelectedIndex] = useState(-1);

  const handleClick = (index) => {
    setSelectedIndex(index);
  };



  return (
    <>

      <div className="attendance-history-cont">
        <div className="attendance-heading"></div>

        <div className="attendance-subcont">
          <div className="field-cont">
            <input type="number"
              maxLength={4} value={yearr}
              onChange={(e) => setYearr(e.target.value)} className="attendance-input-field date-field" /><hr />
            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 14 14" fill="none">
              <g clip-path="url(#clip0_650_3324)">
                <path d="M2.625 10.2083H1.16667C0.857247 10.2083 0.560501 10.3312 0.341709 10.55C0.122916 10.7688 0 11.0655 0 11.3749L0 12.8333C0 13.1427 0.122916 13.4394 0.341709 13.6582C0.560501 13.877 0.857247 13.9999 1.16667 13.9999H2.625C2.93442 13.9999 3.23117 13.877 3.44996 13.6582C3.66875 13.4394 3.79167 13.1427 3.79167 12.8333V11.3749C3.79167 11.0655 3.66875 10.7688 3.44996 10.55C3.23117 10.3312 2.93442 10.2083 2.625 10.2083ZM2.625 12.8333H1.16667V11.3749H2.625V12.8333Z" fill="#707070" />
                <path d="M12.8333 10.2083H11.3749C11.0655 10.2083 10.7688 10.3312 10.55 10.55C10.3312 10.7688 10.2083 11.0655 10.2083 11.3749V12.8333C10.2083 13.1427 10.3312 13.4394 10.55 13.6582C10.7688 13.877 11.0655 13.9999 11.3749 13.9999H12.8333C13.1427 13.9999 13.4394 13.877 13.6582 13.6582C13.877 13.4394 13.9999 13.1427 13.9999 12.8333V11.3749C13.9999 11.0655 13.877 10.7688 13.6582 10.55C13.4394 10.3312 13.1427 10.2083 12.8333 10.2083ZM12.8333 12.8333H11.3749V11.3749H12.8333V12.8333Z" fill="#707070" />
                <path d="M2.625 5.10425H1.16667C0.857247 5.10425 0.560501 5.22716 0.341709 5.44596C0.122916 5.66475 0 5.9615 0 6.27091L0 7.72925C0 8.03867 0.122916 8.33541 0.341709 8.55421C0.560501 8.773 0.857247 8.89592 1.16667 8.89592H2.625C2.93442 8.89592 3.23117 8.773 3.44996 8.55421C3.66875 8.33541 3.79167 8.03867 3.79167 7.72925V6.27091C3.79167 5.9615 3.66875 5.66475 3.44996 5.44596C3.23117 5.22716 2.93442 5.10425 2.625 5.10425ZM2.625 7.72925H1.16667V6.27091H2.625V7.72925Z" fill="#707070" />
                <path d="M12.8333 5.10425H11.3749C11.0655 5.10425 10.7688 5.22716 10.55 5.44596C10.3312 5.66475 10.2083 5.9615 10.2083 6.27091V7.72925C10.2083 8.03867 10.3312 8.33541 10.55 8.55421C10.7688 8.773 11.0655 8.89592 11.3749 8.89592H12.8333C13.1427 8.89592 13.4394 8.773 13.6582 8.55421C13.877 8.33541 13.9999 8.03867 13.9999 7.72925V6.27091C13.9999 5.9615 13.877 5.66475 13.6582 5.44596C13.4394 5.22716 13.1427 5.10425 12.8333 5.10425ZM12.8333 7.72925H11.3749V6.27091H12.8333V7.72925Z" fill="#707070" />
                <path d="M2.625 0H1.16667C0.857247 0 0.560501 0.122916 0.341709 0.341709C0.122916 0.560501 0 0.857247 0 1.16667L0 2.625C0 2.93442 0.122916 3.23117 0.341709 3.44996C0.560501 3.66875 0.857247 3.79167 1.16667 3.79167H2.625C2.93442 3.79167 3.23117 3.66875 3.44996 3.44996C3.66875 3.23117 3.79167 2.93442 3.79167 2.625V1.16667C3.79167 0.857247 3.66875 0.560501 3.44996 0.341709C3.23117 0.122916 2.93442 0 2.625 0ZM2.625 2.625H1.16667V1.16667H2.625V2.625Z" fill="#707070" />
                <path d="M7.72925 10.2083H6.27091C5.9615 10.2083 5.66475 10.3312 5.44596 10.55C5.22716 10.7688 5.10425 11.0655 5.10425 11.3749V12.8333C5.10425 13.1427 5.22716 13.4394 5.44596 13.6582C5.66475 13.877 5.9615 13.9999 6.27091 13.9999H7.72925C8.03867 13.9999 8.33541 13.877 8.55421 13.6582C8.773 13.4394 8.89592 13.1427 8.89592 12.8333V11.3749C8.89592 11.0655 8.773 10.7688 8.55421 10.55C8.33541 10.3312 8.03867 10.2083 7.72925 10.2083ZM7.72925 12.8333H6.27091V11.3749H7.72925V12.8333Z" fill="#707070" />
                <path d="M7.72925 5.10425H6.27091C5.9615 5.10425 5.66475 5.22716 5.44596 5.44596C5.22716 5.66475 5.10425 5.9615 5.10425 6.27091V7.72925C5.10425 8.03867 5.22716 8.33541 5.44596 8.55421C5.66475 8.773 5.9615 8.89592 6.27091 8.89592H7.72925C8.03867 8.89592 8.33541 8.773 8.55421 8.55421C8.773 8.33541 8.89592 8.03867 8.89592 7.72925V6.27091C8.89592 5.9615 8.773 5.66475 8.55421 5.44596C8.33541 5.22716 8.03867 5.10425 7.72925 5.10425ZM7.72925 7.72925H6.27091V6.27091H7.72925V7.72925Z" fill="#707070" />
                <path d="M7.72925 0H6.27091C5.9615 0 5.66475 0.122916 5.44596 0.341709C5.22716 0.560501 5.10425 0.857247 5.10425 1.16667V2.625C5.10425 2.93442 5.22716 3.23117 5.44596 3.44996C5.66475 3.66875 5.9615 3.79167 6.27091 3.79167H7.72925C8.03867 3.79167 8.33541 3.66875 8.55421 3.44996C8.773 3.23117 8.89592 2.93442 8.89592 2.625V1.16667C8.89592 0.857247 8.773 0.560501 8.55421 0.341709C8.33541 0.122916 8.03867 0 7.72925 0ZM7.72925 2.625H6.27091V1.16667H7.72925V2.625Z" fill="#707070" />
                <path d="M12.8333 0H11.3749C11.0655 0 10.7688 0.122916 10.55 0.341709C10.3312 0.560501 10.2083 0.857247 10.2083 1.16667V2.625C10.2083 2.93442 10.3312 3.23117 10.55 3.44996C10.7688 3.66875 11.0655 3.79167 11.3749 3.79167H12.8333C13.1427 3.79167 13.4394 3.66875 13.6582 3.44996C13.877 3.23117 13.9999 2.93442 13.9999 2.625V1.16667C13.9999 0.857247 13.877 0.560501 13.6582 0.341709C13.4394 0.122916 13.1427 0 12.8333 0ZM12.8333 2.625H11.3749V1.16667H12.8333V2.625Z" fill="#707070" />
              </g>
              <defs>
                <clipPath id="clip0_650_3324">
                  <rect width="14" height="14" fill="white" />
                </clipPath>
              </defs>
            </svg>

            <select className="attendance-input-field date-field" type="text"
              value={monthh}
              onChange={(e) => setMonthh(e.target.value)}
            >
              {/* <option value="">All</option> */}
              <option value="1">January</option>
              <option value="2">February</option>
              <option value="3">March</option>
              <option value="4">April</option>
              <option value="5">May</option>
              <option value="6">June</option>
              <option value="7">July</option>
              <option value="8">August</option>
              <option value="9">September</option>
              <option value="10">October</option>
              <option value="11">November</option>
              <option value="12">December</option>





            </select>
            <hr />

            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 14 14" fill="none">
              <g clip-path="url(#clip0_650_3324)">
                <path d="M2.625 10.2083H1.16667C0.857247 10.2083 0.560501 10.3312 0.341709 10.55C0.122916 10.7688 0 11.0655 0 11.3749L0 12.8333C0 13.1427 0.122916 13.4394 0.341709 13.6582C0.560501 13.877 0.857247 13.9999 1.16667 13.9999H2.625C2.93442 13.9999 3.23117 13.877 3.44996 13.6582C3.66875 13.4394 3.79167 13.1427 3.79167 12.8333V11.3749C3.79167 11.0655 3.66875 10.7688 3.44996 10.55C3.23117 10.3312 2.93442 10.2083 2.625 10.2083ZM2.625 12.8333H1.16667V11.3749H2.625V12.8333Z" fill="#707070" />
                <path d="M12.8333 10.2083H11.3749C11.0655 10.2083 10.7688 10.3312 10.55 10.55C10.3312 10.7688 10.2083 11.0655 10.2083 11.3749V12.8333C10.2083 13.1427 10.3312 13.4394 10.55 13.6582C10.7688 13.877 11.0655 13.9999 11.3749 13.9999H12.8333C13.1427 13.9999 13.4394 13.877 13.6582 13.6582C13.877 13.4394 13.9999 13.1427 13.9999 12.8333V11.3749C13.9999 11.0655 13.877 10.7688 13.6582 10.55C13.4394 10.3312 13.1427 10.2083 12.8333 10.2083ZM12.8333 12.8333H11.3749V11.3749H12.8333V12.8333Z" fill="#707070" />
                <path d="M2.625 5.10425H1.16667C0.857247 5.10425 0.560501 5.22716 0.341709 5.44596C0.122916 5.66475 0 5.9615 0 6.27091L0 7.72925C0 8.03867 0.122916 8.33541 0.341709 8.55421C0.560501 8.773 0.857247 8.89592 1.16667 8.89592H2.625C2.93442 8.89592 3.23117 8.773 3.44996 8.55421C3.66875 8.33541 3.79167 8.03867 3.79167 7.72925V6.27091C3.79167 5.9615 3.66875 5.66475 3.44996 5.44596C3.23117 5.22716 2.93442 5.10425 2.625 5.10425ZM2.625 7.72925H1.16667V6.27091H2.625V7.72925Z" fill="#707070" />
                <path d="M12.8333 5.10425H11.3749C11.0655 5.10425 10.7688 5.22716 10.55 5.44596C10.3312 5.66475 10.2083 5.9615 10.2083 6.27091V7.72925C10.2083 8.03867 10.3312 8.33541 10.55 8.55421C10.7688 8.773 11.0655 8.89592 11.3749 8.89592H12.8333C13.1427 8.89592 13.4394 8.773 13.6582 8.55421C13.877 8.33541 13.9999 8.03867 13.9999 7.72925V6.27091C13.9999 5.9615 13.877 5.66475 13.6582 5.44596C13.4394 5.22716 13.1427 5.10425 12.8333 5.10425ZM12.8333 7.72925H11.3749V6.27091H12.8333V7.72925Z" fill="#707070" />
                <path d="M2.625 0H1.16667C0.857247 0 0.560501 0.122916 0.341709 0.341709C0.122916 0.560501 0 0.857247 0 1.16667L0 2.625C0 2.93442 0.122916 3.23117 0.341709 3.44996C0.560501 3.66875 0.857247 3.79167 1.16667 3.79167H2.625C2.93442 3.79167 3.23117 3.66875 3.44996 3.44996C3.66875 3.23117 3.79167 2.93442 3.79167 2.625V1.16667C3.79167 0.857247 3.66875 0.560501 3.44996 0.341709C3.23117 0.122916 2.93442 0 2.625 0ZM2.625 2.625H1.16667V1.16667H2.625V2.625Z" fill="#707070" />
                <path d="M7.72925 10.2083H6.27091C5.9615 10.2083 5.66475 10.3312 5.44596 10.55C5.22716 10.7688 5.10425 11.0655 5.10425 11.3749V12.8333C5.10425 13.1427 5.22716 13.4394 5.44596 13.6582C5.66475 13.877 5.9615 13.9999 6.27091 13.9999H7.72925C8.03867 13.9999 8.33541 13.877 8.55421 13.6582C8.773 13.4394 8.89592 13.1427 8.89592 12.8333V11.3749C8.89592 11.0655 8.773 10.7688 8.55421 10.55C8.33541 10.3312 8.03867 10.2083 7.72925 10.2083ZM7.72925 12.8333H6.27091V11.3749H7.72925V12.8333Z" fill="#707070" />
                <path d="M7.72925 5.10425H6.27091C5.9615 5.10425 5.66475 5.22716 5.44596 5.44596C5.22716 5.66475 5.10425 5.9615 5.10425 6.27091V7.72925C5.10425 8.03867 5.22716 8.33541 5.44596 8.55421C5.66475 8.773 5.9615 8.89592 6.27091 8.89592H7.72925C8.03867 8.89592 8.33541 8.773 8.55421 8.55421C8.773 8.33541 8.89592 8.03867 8.89592 7.72925V6.27091C8.89592 5.9615 8.773 5.66475 8.55421 5.44596C8.33541 5.22716 8.03867 5.10425 7.72925 5.10425ZM7.72925 7.72925H6.27091V6.27091H7.72925V7.72925Z" fill="#707070" />
                <path d="M7.72925 0H6.27091C5.9615 0 5.66475 0.122916 5.44596 0.341709C5.22716 0.560501 5.10425 0.857247 5.10425 1.16667V2.625C5.10425 2.93442 5.22716 3.23117 5.44596 3.44996C5.66475 3.66875 5.9615 3.79167 6.27091 3.79167H7.72925C8.03867 3.79167 8.33541 3.66875 8.55421 3.44996C8.773 3.23117 8.89592 2.93442 8.89592 2.625V1.16667C8.89592 0.857247 8.773 0.560501 8.55421 0.341709C8.33541 0.122916 8.03867 0 7.72925 0ZM7.72925 2.625H6.27091V1.16667H7.72925V2.625Z" fill="#707070" />
                <path d="M12.8333 0H11.3749C11.0655 0 10.7688 0.122916 10.55 0.341709C10.3312 0.560501 10.2083 0.857247 10.2083 1.16667V2.625C10.2083 2.93442 10.3312 3.23117 10.55 3.44996C10.7688 3.66875 11.0655 3.79167 11.3749 3.79167H12.8333C13.1427 3.79167 13.4394 3.66875 13.6582 3.44996C13.877 3.23117 13.9999 2.93442 13.9999 2.625V1.16667C13.9999 0.857247 13.877 0.560501 13.6582 0.341709C13.4394 0.122916 13.1427 0 12.8333 0ZM12.8333 2.625H11.3749V1.16667H12.8333V2.625Z" fill="#707070" />
              </g>
              <defs>
                <clipPath id="clip0_650_3324">
                  <rect width="14" height="14" fill="white" />
                </clipPath>
              </defs>
            </svg><input className="attendance-input-field" placeholder='Name or Code' type="text" value={selectedName}
              onChange={(e) => setName(e.target.value)} />


            <hr />

            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 14 14" fill="none">
              <g clip-path="url(#clip0_650_3324)">
                <path d="M2.625 10.2083H1.16667C0.857247 10.2083 0.560501 10.3312 0.341709 10.55C0.122916 10.7688 0 11.0655 0 11.3749L0 12.8333C0 13.1427 0.122916 13.4394 0.341709 13.6582C0.560501 13.877 0.857247 13.9999 1.16667 13.9999H2.625C2.93442 13.9999 3.23117 13.877 3.44996 13.6582C3.66875 13.4394 3.79167 13.1427 3.79167 12.8333V11.3749C3.79167 11.0655 3.66875 10.7688 3.44996 10.55C3.23117 10.3312 2.93442 10.2083 2.625 10.2083ZM2.625 12.8333H1.16667V11.3749H2.625V12.8333Z" fill="#707070" />
                <path d="M12.8333 10.2083H11.3749C11.0655 10.2083 10.7688 10.3312 10.55 10.55C10.3312 10.7688 10.2083 11.0655 10.2083 11.3749V12.8333C10.2083 13.1427 10.3312 13.4394 10.55 13.6582C10.7688 13.877 11.0655 13.9999 11.3749 13.9999H12.8333C13.1427 13.9999 13.4394 13.877 13.6582 13.6582C13.877 13.4394 13.9999 13.1427 13.9999 12.8333V11.3749C13.9999 11.0655 13.877 10.7688 13.6582 10.55C13.4394 10.3312 13.1427 10.2083 12.8333 10.2083ZM12.8333 12.8333H11.3749V11.3749H12.8333V12.8333Z" fill="#707070" />
                <path d="M2.625 5.10425H1.16667C0.857247 5.10425 0.560501 5.22716 0.341709 5.44596C0.122916 5.66475 0 5.9615 0 6.27091L0 7.72925C0 8.03867 0.122916 8.33541 0.341709 8.55421C0.560501 8.773 0.857247 8.89592 1.16667 8.89592H2.625C2.93442 8.89592 3.23117 8.773 3.44996 8.55421C3.66875 8.33541 3.79167 8.03867 3.79167 7.72925V6.27091C3.79167 5.9615 3.66875 5.66475 3.44996 5.44596C3.23117 5.22716 2.93442 5.10425 2.625 5.10425ZM2.625 7.72925H1.16667V6.27091H2.625V7.72925Z" fill="#707070" />
                <path d="M12.8333 5.10425H11.3749C11.0655 5.10425 10.7688 5.22716 10.55 5.44596C10.3312 5.66475 10.2083 5.9615 10.2083 6.27091V7.72925C10.2083 8.03867 10.3312 8.33541 10.55 8.55421C10.7688 8.773 11.0655 8.89592 11.3749 8.89592H12.8333C13.1427 8.89592 13.4394 8.773 13.6582 8.55421C13.877 8.33541 13.9999 8.03867 13.9999 7.72925V6.27091C13.9999 5.9615 13.877 5.66475 13.6582 5.44596C13.4394 5.22716 13.1427 5.10425 12.8333 5.10425ZM12.8333 7.72925H11.3749V6.27091H12.8333V7.72925Z" fill="#707070" />
                <path d="M2.625 0H1.16667C0.857247 0 0.560501 0.122916 0.341709 0.341709C0.122916 0.560501 0 0.857247 0 1.16667L0 2.625C0 2.93442 0.122916 3.23117 0.341709 3.44996C0.560501 3.66875 0.857247 3.79167 1.16667 3.79167H2.625C2.93442 3.79167 3.23117 3.66875 3.44996 3.44996C3.66875 3.23117 3.79167 2.93442 3.79167 2.625V1.16667C3.79167 0.857247 3.66875 0.560501 3.44996 0.341709C3.23117 0.122916 2.93442 0 2.625 0ZM2.625 2.625H1.16667V1.16667H2.625V2.625Z" fill="#707070" />
                <path d="M7.72925 10.2083H6.27091C5.9615 10.2083 5.66475 10.3312 5.44596 10.55C5.22716 10.7688 5.10425 11.0655 5.10425 11.3749V12.8333C5.10425 13.1427 5.22716 13.4394 5.44596 13.6582C5.66475 13.877 5.9615 13.9999 6.27091 13.9999H7.72925C8.03867 13.9999 8.33541 13.877 8.55421 13.6582C8.773 13.4394 8.89592 13.1427 8.89592 12.8333V11.3749C8.89592 11.0655 8.773 10.7688 8.55421 10.55C8.33541 10.3312 8.03867 10.2083 7.72925 10.2083ZM7.72925 12.8333H6.27091V11.3749H7.72925V12.8333Z" fill="#707070" />
                <path d="M7.72925 5.10425H6.27091C5.9615 5.10425 5.66475 5.22716 5.44596 5.44596C5.22716 5.66475 5.10425 5.9615 5.10425 6.27091V7.72925C5.10425 8.03867 5.22716 8.33541 5.44596 8.55421C5.66475 8.773 5.9615 8.89592 6.27091 8.89592H7.72925C8.03867 8.89592 8.33541 8.773 8.55421 8.55421C8.773 8.33541 8.89592 8.03867 8.89592 7.72925V6.27091C8.89592 5.9615 8.773 5.66475 8.55421 5.44596C8.33541 5.22716 8.03867 5.10425 7.72925 5.10425ZM7.72925 7.72925H6.27091V6.27091H7.72925V7.72925Z" fill="#707070" />
                <path d="M7.72925 0H6.27091C5.9615 0 5.66475 0.122916 5.44596 0.341709C5.22716 0.560501 5.10425 0.857247 5.10425 1.16667V2.625C5.10425 2.93442 5.22716 3.23117 5.44596 3.44996C5.66475 3.66875 5.9615 3.79167 6.27091 3.79167H7.72925C8.03867 3.79167 8.33541 3.66875 8.55421 3.44996C8.773 3.23117 8.89592 2.93442 8.89592 2.625V1.16667C8.89592 0.857247 8.773 0.560501 8.55421 0.341709C8.33541 0.122916 8.03867 0 7.72925 0ZM7.72925 2.625H6.27091V1.16667H7.72925V2.625Z" fill="#707070" />
                <path d="M12.8333 0H11.3749C11.0655 0 10.7688 0.122916 10.55 0.341709C10.3312 0.560501 10.2083 0.857247 10.2083 1.16667V2.625C10.2083 2.93442 10.3312 3.23117 10.55 3.44996C10.7688 3.66875 11.0655 3.79167 11.3749 3.79167H12.8333C13.1427 3.79167 13.4394 3.66875 13.6582 3.44996C13.877 3.23117 13.9999 2.93442 13.9999 2.625V1.16667C13.9999 0.857247 13.877 0.560501 13.6582 0.341709C13.4394 0.122916 13.1427 0 12.8333 0ZM12.8333 2.625H11.3749V1.16667H12.8333V2.625Z" fill="#707070" />
              </g>
              <defs>
                <clipPath id="clip0_650_3324">
                  <rect width="14" height="14" fill="white" />
                </clipPath>
              </defs>
            </svg><input className="attendance-input-field" placeholder='Dep || Rep Head Name' type="text" value={selectedDep}
              onChange={(e) => setDep(e.target.value)} />

            <hr />
            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="38" viewBox="0 0 17 17" fill="none">
              <g clip-path="url(#clip0_650_3351)">
                <mask id="mask0_650_3351" maskUnits="userSpaceOnUse" x="0" y="0" width="17" height="17">
                  <path d="M17 0H0V17H17V0Z" fill="white" />
                </mask>
                <g mask="url(#mask0_650_3351)">
                  <path d="M8.50009 17.0057L8.00425 16.5807C7.32071 16.0083 1.35229 10.8538 1.35229 7.08334C1.35229 5.18763 2.10536 3.36956 3.44583 2.02909C4.78631 0.688616 6.60437 -0.0644531 8.50009 -0.0644531C10.3958 -0.0644531 12.2139 0.688616 13.5543 2.02909C14.8948 3.36956 15.6479 5.18763 15.6479 7.08334C15.6479 10.8538 9.67946 16.0083 8.99875 16.5835L8.50009 17.0057ZM8.50009 1.48113C7.0148 1.48282 5.59084 2.07359 4.54059 3.12384C3.49033 4.1741 2.89956 5.59806 2.89787 7.08334C2.89787 9.452 6.56987 13.236 8.50009 14.9678C10.4303 13.2352 14.1023 9.44917 14.1023 7.08334C14.1006 5.59806 13.5098 4.1741 12.4596 3.12384C11.4093 2.07359 9.98537 1.48282 8.50009 1.48113Z" fill="#707070" />
                  <path d="M8.50008 9.91666C7.9397 9.91666 7.39191 9.7505 6.92597 9.43917C6.46003 9.12784 6.09687 8.68533 5.88242 8.16761C5.66798 7.64988 5.61186 7.08019 5.7212 6.53058C5.83052 5.98097 6.10036 5.47612 6.49662 5.07986C6.89286 4.68362 7.39771 4.41376 7.94734 4.30444C8.49695 4.19512 9.06663 4.25123 9.58436 4.46568C10.1021 4.68013 10.5446 5.04329 10.8559 5.50922C11.1672 5.97516 11.3334 6.52295 11.3334 7.08334C11.3334 7.83478 11.0349 8.55545 10.5036 9.0868C9.97221 9.61816 9.25154 9.91666 8.50008 9.91666ZM8.50008 5.66666C8.2199 5.66666 7.946 5.74975 7.71302 5.90542C7.48006 6.06109 7.29848 6.28234 7.19125 6.5412C7.08404 6.80007 7.05597 7.08491 7.11063 7.35972C7.1653 7.63452 7.30022 7.88694 7.49835 8.08507C7.69648 8.2832 7.9489 8.41811 8.2237 8.47279C8.49851 8.52745 8.78336 8.49939 9.04221 8.39217C9.30108 8.28494 9.52233 8.10337 9.678 7.8704C9.83367 7.63742 9.91675 7.36353 9.91675 7.08334C9.91675 6.70761 9.7675 6.34728 9.50181 6.08161C9.23614 5.81592 8.87581 5.66666 8.50008 5.66666Z" fill="#707070" />
                </g>
              </g>
              <defs>
                <clipPath id="clip0_650_3351">
                  <rect width="17" height="17" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <select className="attendance-input-field date-field" type="text"
              value={selectedStatus}
              onChange={(e) => setSelectedStatus(e.target.value)}
            >
              <option value="">All</option>

              <option value="active">Active</option>
              <option value="inactive">Inactive</option>



            </select>
          </div>

        </div>
      </div>

      <div className="custom-table-widthh">
        <div className="table-heading-flex">
          <div className="repo-heading">Attendance ( {filteredEmployee.length} of {allattendance.length})
          </div>
          <div>

          </div>
        </div>
        {/* <div className='custom-table-width'> */}


        <table id="my-table" style={{ borderColor: "#323232", borderRadius: "5px", padding: "0px 5px", borderWidth: "1px", marginLeft: "10px", zoom: "50%", }}>

          <thead style={{
            position: "sticky",
            top: "0",
            backgroundColor: "white", borderColor: "#323232", borderRadius: "5px", padding: "0px 5px", borderWidth: "1px", marginLeft: "10px"
          }}>
            <tr>
              <th className='summary-excel-tab'>Emp</th>
              <th className='summary-excel-tab'>Name</th>
              <th className='summary-excel-tab'>Joining Date</th>
              {datees.map((date) => (
                <th className='summary-excel-tab' key={date.date}>{date.date}</th>
              ))}
              <th className='summary-excel-tab'>LB</th>
              <th className='summary-excel-tab'>P</th>
              <th className='summary-excel-tab'>OT</th>
              <th className='summary-excel-tab'>Late</th>
              <th className='summary-excel-tab'>RLM</th>
              <th className='summary-excel-tab'>HD</th>
              <th className='summary-excel-tab'>WOP</th>
              <th className='summary-excel-tab'>OD</th>
              <th className='summary-excel-tab'>A</th>
              <th className='summary-excel-tab'>HOL</th>
              <th className='summary-excel-tab'>WO</th>
              <th className='summary-excel-tab'>L</th>
              <th className='summary-excel-tab'>HDL</th>
              <th className='summary-excel-tab'>SDL</th>
              <th className='summary-excel-tab'>WFH</th>
              <th className='summary-excel-tab'>HDOD</th>
              <th className='summary-excel-tab'>SDOD</th>
              <th className='summary-excel-tab'>UL</th>
              <th className='summary-excel-tab'>HDUL</th>
              <th className='summary-excel-tab'>SDUL</th>
              <th className='summary-excel-tab'>Miss</th>
              <th className='summary-excel-tab'>Paid Days</th>

            </tr>
          </thead>
          <tbody>
            {filteredEmployee
              .sort((a, b) => a.emp_code - b.emp_code)
              .map((data, index) => {
                const background = data.late === 0 ? 'white' : '#f9935f';

                const inavtivename = data.employee_status === "inactive" ? '#ff1212' : "black";
                const present = data.present === 0 ? "white" : '#a3fc92';
                const ontime = data.ontime === 0 ? "white" : '#a3fc92';
                const late = data.late === 0 ? "white" : '#ffea72';
                const rlm = data.rlm === 0 ? "white" : '#a3fc92';
                const halfday = data.halfday === 0 ? "white" : '#ffea72';
                const wop = data.wop === 0 ? "white" : '#a3fc92';
                const od = data.outduty === 0 ? "white" : '#73DBDBCC';
                const bgabsent = data.absent === 0 ? 'white' : '#fccab1';
                const holiday = data.holiday === 0 ? "white" : '#83B8E6CC';
                const weeklyoff = data.weeklyoff === 0 ? "white" : '#83B8E6CC';
                const leave = data.leave === 0 ? "white" : '#a3fc92';
                const halfdayleave = data.halfdayleave === 0 ? "white" : '#a3fc92';
                const shortleave = data.shortleave === 0 ? "white" : '#a3fc92';

                const workfromhome = data.workfromhome === 0 ? "white" : '#a3fc92';
                const hdoutduty = data.hdoutduty === 0 ? "white" : '#73DBDBCC';
                const shortoutduty = data.shortoutduty === 0 ? "white" : '#73DBDBCC';
                const unpaidleave = data.unpaidleave === 0 ? 'white' : '#fccab1';
                const unpaidleavehd = data.unpaidleavehd === 0 ? 'white' : '#fccab1';
                const unpaidshortleave = data.unpaidshortleave === 0 ? 'white' : '#fccab1';
                const misspunch = data.misspunch === 0 ? 'white' : '#fccab1';

                const bgtotalpaiddays = data.total === dates.length ? "white" : '#fccab1';


                const hd = data.halfday === 0 ? "white" : '#fccab1';


                return (
                  <>
                    <tr style={{
                      backgroundColor: index === selectedIndex ? "yellow" : "white", color: inavtivename,
                    }}
                      onClick={() => handleClick(index)}>
                      <td className='summary-excel-tab'>{data.emp_code}</td>
                      <td className='summary-excel-tab'>{data.name}</td>
                      <td className='summary-excel-tab'>{data.joining_date}</td>
                      {dates.map((date) => {
                        const attendanceForDate = data.attendances.find(
                          (attendance) => attendance.date === date.date
                        );
                        const attendanceStatus = attendanceForDate
                          ? attendanceForDate.day_status
                          : "-";

                        const attendanceStatuss = attendanceForDate
                          ? attendanceForDate.day_status === "ontime"
                            ? "P"
                            : attendanceForDate.day_status === "outduty"
                              ? "OD"
                              : attendanceForDate.day_status === "halfday"
                                ? "HD"
                                : attendanceForDate.day_status === "paidleave"
                                  ? "PL"
                                  : attendanceForDate.day_status === "late"
                                    ? "Late"
                                    : attendanceForDate.day_status === "weeklyoff"
                                      ? "WO"
                                      : attendanceForDate.day_status === "wop"
                                        ? "WOP"
                                        : attendanceForDate.day_status === "rlm"
                                          ? "RLM"
                                          : attendanceForDate.day_status === "halfdayleave"
                                            ? "HDL"
                                            : attendanceForDate.day_status === "unpaidleave"
                                              ? "UL"
                                              : attendanceForDate.day_status === "shortleave"
                                                ? "SL"
                                                : attendanceForDate.day_status === "unpaidshortleave"
                                                  ? "USL"
                                                  : attendanceForDate.day_status === "unpaidleavehd"
                                                    ? "UHD"
                                                    : attendanceForDate.day_status === "hdoutduty"
                                                      ? "HOD"
                                                      : attendanceForDate.day_status === "shortoutduty"
                                                        ? "SOD"
                                                        : attendanceForDate.day_status === "holiday"
                                                          ? "Hol"
                                                          : attendanceForDate.day_status === "absent"
                                                            ? "A"
                                                            : attendanceForDate.day_status.slice(0, 2)
                          : "-";

                        // // Example usage:
                        // <span>{attendanceStatus}</span>
                        // {attendanceStatuss.slice(0, 2).toUpperCase()}



                        const inTime = attendanceForDate ? attendanceForDate.in_time : "-";
                        const outTime = attendanceForDate ? attendanceForDate.out_time : "-";
                        const attstat = attendanceForDate ? attendanceForDate.attendence_status : "-";
                        const daystatus = attendanceForDate ? attendanceForDate.day_status : "-";






                        return (
                          // <>
                          <td style={{ backgroundColor: index === selectedIndex ? "yellow" : "", color: index === selectedIndex ? "black" : "" }} data-status={attendanceStatus}
                            className="behavior summary-excel-tab" title={`In Time: ${inTime}\nOut Time: ${outTime}\nAttendance Status: ${attstat}\nDay Status: ${daystatus}`} >
                            {attendanceStatuss}
                          </td>


                        );
                      })}
                      <td className='summary-excel-tab' style={{ backgroundColor: '' }}>{data.leavebalance}</td>
                      <td className='summary-excel-tab' style={{ backgroundColor: present }}>{data.present}</td>
                      <td className='summary-excel-tab' style={{ backgroundColor: ontime }}>{data.ontime}</td>
                      <td className='summary-excel-tab' style={{ backgroundColor: late }}>{data.late}</td>
                      <td className='summary-excel-tab' style={{ backgroundColor: rlm }}>{data.rlm}</td>
                      <td className='summary-excel-tab' style={{ backgroundColor: halfday }}>{data.halfday}</td>
                      <td className='summary-excel-tab' style={{ backgroundColor: wop }}>{data.wop}</td>
                      <td className='summary-excel-tab' style={{ backgroundColor: od }}>{data.outduty}</td>
                      <td className='summary-excel-tab' style={{ backgroundColor: bgabsent }}>{data.absent}</td>
                      <td className='summary-excel-tab' style={{ backgroundColor: holiday }}>{data.holiday}</td>
                      <td className='summary-excel-tab' style={{ backgroundColor: weeklyoff }}>{data.weeklyoff}</td>
                      <td className='summary-excel-tab' style={{ backgroundColor: leave }}>{data.leave}</td>
                      <td className='summary-excel-tab' style={{ backgroundColor: halfdayleave }}>{data.halfdayleave}</td>
                      <td className='summary-excel-tab' style={{ backgroundColor: shortleave }}>{data.shortleave}</td>
                      <td className='summary-excel-tab' style={{ backgroundColor: workfromhome }}>{data.workfromhome}</td>
                      <td className='summary-excel-tab' style={{ backgroundColor: hdoutduty }}>{data.hdoutduty}</td>
                      <td className='summary-excel-tab' style={{ backgroundColor: shortoutduty }}>{data.shortoutduty}</td>
                      <td className='summary-excel-tab' style={{ backgroundColor: unpaidleave }}>{data.unpaidleave}</td>
                      <td className='summary-excel-tab' style={{ backgroundColor: unpaidleavehd }}>{data.unpaidleavehd}</td>
                      <td className='summary-excel-tab' style={{ backgroundColor: unpaidshortleave }}>{data.unpaidshortleave}</td>
                      <td className='summary-excel-tab' style={{ backgroundColor: misspunch }}  >{data.misspunch}</td>

                      <td className='summary-excel-tab' style={{ backgroundColor: bgtotalpaiddays }}>{data.total}</td>




                    </tr>
                  </>
                )
              })}
          </tbody>
        </table>
        {/* </div> */}
      </div>


    </>
  );
};

const Attendancebydept = () => {

  const data = [{
    date: "09/09/1998",
    name: "Anshuman",
    punchin: "09:00",
    punchout: "09:00",
    behaviour: "On Time",
    totalhours: "09:00",
    status: "Present",
  }, {
    date: "09/09/1998",
    name: "Ansh",
    punchin: "09:00",
    punchout: "09:00",
    behaviour: "On Time",
    totalhours: "09:00",
    status: "Present",
  }, {
    date: "09/09/1998",
    name: "Ansh Yadav",
    punchin: "09:00",
    punchout: "09:00",
    behaviour: "On Time",
    totalhours: "09:00",
    status: "Present",
  }, {
    date: "09/09/1998",
    name: "Archish",
    punchin: "09:00",
    punchout: "09:00",
    behaviour: "On Time",
    totalhours: "09:00",
    status: "Present",
  }]

  return (
    <>
      <div className="custom-table-background">
        <div className="table-heading-flex">
          <div className="repo-heading">Attendance by dept
          </div>
          <div>

          </div>
        </div>
        <table className="custom-table">
          <thead>
            <tr className="custom-table-head-tr">
              <th className="align-left">Data</th>
              <th className="align-left">Name</th>
              <th className="align-left">Punch In</th>
              <th className="align-left">Punch Out</th>
              <th className="align-left">Behaviour</th>
              <th className="align-left">Total hours</th>
              <th className="align-left">Status</th>
            </tr>
          </thead>
          <tbody>
            {data.map((i, index) => (
              <React.Fragment key={index}>
                <tr className="tr-border-bottom">
                  <td colSpan="6"></td>
                </tr>
                <tr className="custom-table-head-td">
                  <td className="align-left">{i.date}</td>
                  <td className="align-left">{i.name}</td>
                  <td className="align-left">{i.punchin}</td>
                  <td className="align-left">{i.punchout}</td>
                  <td className="align-left">{i.behaviour}</td>
                  <td className="align-left">{i.totalhours}</td>
                  <td className="align-left">{i.status}</td>
                </tr>
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </>
  )
}

const Attendancebyrepo = () => {

  const data = [{
    date: "09/09/1998",
    name: "Anshuman",
    punchin: "09:00",
    punchout: "09:00",
    behaviour: "On Time",
    totalhours: "09:00",
    status: "Present",
  }, {
    date: "09/09/1998",
    name: "Ansh",
    punchin: "09:00",
    punchout: "09:00",
    behaviour: "On Time",
    totalhours: "09:00",
    status: "Present",
  }, {
    date: "09/09/1998",
    name: "Ansh Yadav",
    punchin: "09:00",
    punchout: "09:00",
    behaviour: "On Time",
    totalhours: "09:00",
    status: "Present",
  }, {
    date: "09/09/1998",
    name: "Archish",
    punchin: "09:00",
    punchout: "09:00",
    behaviour: "On Time",
    totalhours: "09:00",
    status: "Present",
  }]

  return (
    <>
      <div className="custom-table-background">
        <div className="table-heading-flex">
          <div className="repo-heading">Attendance by TL
          </div>
          <div>

          </div>
        </div>
        <table className="custom-table">
          <thead>
            <tr className="custom-table-head-tr">
              <th className="align-left">Data</th>
              <th className="align-left">Name</th>
              <th className="align-left">Punch In</th>
              <th className="align-left">Punch Out</th>
              <th className="align-left">Behaviour</th>
              <th className="align-left">Total hours</th>
              <th className="align-left">Status</th>
            </tr>
          </thead>
          <tbody>
            {data.map((i, index) => (
              <React.Fragment key={index}>
                <tr className="tr-border-bottom">
                  <td colSpan="6"></td>
                </tr>
                <tr className="custom-table-head-td">
                  <td className="align-left">{i.date}</td>
                  <td className="align-left">{i.name}</td>
                  <td className="align-left">{i.punchin}</td>
                  <td className="align-left">{i.punchout}</td>
                  <td className="align-left">{i.behaviour}</td>
                  <td className="align-left">{i.totalhours}</td>
                  <td className="align-left">{i.status}</td>
                </tr>
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </>
  )
}

export { DailyAttendance, EmployeeAttendance, SummarySheet, SummarySheetExcel, Attendancebydept, Attendancebyrepo }
