// import React from 'react'
import Logo from './frame-10000027081.png'
import Signature from './Picture 1.png'
import { BASE_URL, FRONTEND_URL } from "../../config/axios";
// import React, { useEffect, useState } from "react";
import axios from "axios";


// import { BASE_URL } from "../../config/axios";
import React, { useEffect, useState } from "react";
// import axios from "axios";
import { formattedDate, formattedDateLong } from '../Date';
const LetterHead = ({emp}) => {

  const [letterData, setLetterData] = useState({})
  const [personalData, setPersonalData] = useState({})

  const date = formattedDate

  const getLetterData = async () => {
    try {
      const res = await axios.get(`${BASE_URL}/wfm/incrementbyemployee/${emp}`);
      const ress = await axios.get(`${BASE_URL}/wfm/ourcompanydetailsbyemp/${emp}/`);

      setLetterData(res.data[0])
      setPersonalData(ress.data)

    } catch (err) {
    }
  };

  // console.log(letterData)


  useEffect(() => {
    getLetterData();

  }, []);

  const dojdate = personalData?.joining_date

  const doj_date = dojdate?.split("-")
  return (
    <>
      <div className='salaryslip'>
        <div className='salaryslip-header'>
          <div className='salaryslip-header-left'>
            <img src="https://cipl-aimantra.s3.ap-south-1.amazonaws.com/Logos/Cipl+Logo++(1).png" alt='logo' className='salaryslip-logo' />
            {/* <div className='salaryslip-header-left-text'>
              SALARY SLIP FOR THE MONTH OF JUNE 2023
            </div> */}
          </div>
          <div className='salaryslip-header-right'>
            <div className='salaryslip-header-right-top'>
              Civilmantra infracon private limited
            </div>
            <div className='salaryslip-header-right-bottom'>
              3rd Floor, Tower 3A,<br /> DLF Corporate Greens, Sector 74A,<br /> Gurugram, Haryana 122004 <br /> services@civilmantra.com
            </div>
          </div>
        </div>
        <div className='salaryslip-hr'></div>

        <b>To</b>,  <span style={{ float: 'right' }}>Date – {formattedDateLong(date)}</span>
        <br />
        <b>{personalData.name}</b>
        <br />
        <b>Emp Code- </b>{personalData.emp_code}
        <br />
        <b> Department-</b> {personalData.department_name}
        <br />
        <b>Designation- </b>{letterData.old_designation}
        <br />
        <b>Grade – </b>{letterData.previous_grade}
        <br />
        <b>D.O.J. –</b> {formattedDateLong(personalData.joining_date)}
        <br />
        <br />
        <b>Subject: </b>Increment, Promotion & Employment Confirmation Letter.
        <br />


        <br />
        <b>Dear {personalData.name},</b>

        <br />
        <br />
        We congratulate you for your hard work, enthusiasm, dedication and continuous effort in meeting the organization objectives.

        <br />
        <br />
        On Successful completion of your probation period, we are glad to announce an increment of<b> Rs. {letterData.increment_amount}/-</b> pm i.e. <b>{letterData.increment_percentage}%</b> on your existing CTC Rs. {letterData.existing_ctc}/- and now your revised CTC will be Rs. {letterData.revised_ctc}/- with effect from <b>{formattedDateLong(letterData.wefdate)}</b>

        <br />
        <br />
        We are pleased to inform you that you have been promoted from the Designation {personalData.designation_name} to<b> {(letterData.updated_designation) ? letterData.updated_designation : "-"} </b>in accordance to which your grade is promoted from {letterData.previous_grade} to<b> {letterData.update_grade}</b> at CivilMantra Infracon Pvt. Ltd. We congratulate you for your achievement.

        <br />
        <br />
        We expect you to keep up your performance in the years to come and grow with the organization.


        <br />
        <br />
        Wish you all the best.

        <br />

        <div className='salaryslip-hr'></div>
        <br />
        <th>For CivilMatra Infracon Pvt. Ltd.</th>
        <div className='salaryslip-bottom'>
          <img src={Signature} alt='signature' className='slaryslip-bg-signature' />
          <th>AUTHORISED SIGNATORY</th>
        </div>
      </div>
      <div className='slaryslip-bg'>


      </div>
    </>
  )
}

export default LetterHead
