// import React from 'react'
import React, { useEffect, useState } from 'react'
import { BASE_URL, FRONTEND_URL } from "../../config/axios";
import axios from "axios";
import { formatDate, formattedDateLong } from '../Date';
import Dashboardnavbarcopy from '../../layout/Dashboardnavbarcopy';
import Letter from '../LetterHeadadmin/Letter';


const AppraisalSheet = () => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [appraisal, setAppraisal] = useState([]);

    const getappraisal = async () => {
        try {
            const res = await axios.get(`${BASE_URL}/wfm/increment/`);
            setAppraisal(res.data);
        } catch (err) {
            // alert(err.appraisal);
        }
    };

    useEffect(() => {
        getappraisal();
    }, []);

    return (
        <>
            <Dashboardnavbarcopy name={`Appraisal Sheet`} url="Appraisal Sheet" />

            <div className="custom-table-background">
                <div className="table-heading-flex">
                    <div className="repo-heading">Appraisal Data</div>
                    <div>
                    </div>
                </div>
                <table className="custom-table">
                    <thead>
                        <tr className="custom-table-head-tr">
                            <th className="align-left">Sr no.</th>
                            <th className="align-center">Emp Code</th>
                            <th className="align-center">Updated Designation</th>
                            <th className="align-center">existing_ctc</th>
                            <th className="align-center">revised_ctc</th>
                            <th className="align-center">increment_percentage</th>
                            <th className="align-center">increment_amount</th>
                            <th className="align-center">previous_grade</th>
                            <th className="align-center">update_grade</th>
                            <th className="align-center">letter_type</th>
                            <th className="align-right">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {appraisal.map((i, index) => (
                            <React.Fragment key={index}>
                                <tr className="tr-border-bottom">
                                    <td colSpan="6"></td>
                                </tr>
                                <tr className="custom-table-head-td">
                                    <td className="align-left">{index + 1}</td>
                                    <td className="align-center">{i.empcode}</td>
                                    <td className="align-center">{i.updated_designation}</td>
                                    <td className="align-center">{i.existing_ctc}</td>
                                    <td className="align-center">{i.revised_ctc}</td>
                                    <td className="align-center">{i.increment_percentage}</td>
                                    <td className="align-center">{i.increment_amount}</td>
                                    <td className="align-center">{i.previous_grade}</td>
                                    <td className="align-center">{i.update_grade}</td>
                                    <td className="align-center">{i.letter_type}</td>
                                    <td className="align-right">
                                        <a
                                            href={`${FRONTEND_URL}/letteradmin/${i.letter_type}/${i.empcode}/`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="40"
                                            height="40"
                                            viewBox="0 0 30 30"
                                            fill="none"
                                        >
                                            <circle cx="15" cy="15" r="15" fill="#F6F7F9" />
                                            <path
                                                d="M23.8913 14.5437C22.1966 11.2372 18.8416 9 15 9C11.1584 9 7.80249 11.2388 6.10873 14.5441C6.03725 14.6855 6 14.8417 6 15.0002C6 15.1586 6.03725 15.3148 6.10873 15.4563C7.80342 18.7628 11.1584 21 15 21C18.8416 21 22.1975 18.7612 23.8913 15.4559C23.9628 15.3145 24 15.1583 24 14.9998C24 14.8414 23.9628 14.6852 23.8913 14.5437ZM15 19.5C14.11 19.5 13.24 19.2361 12.4999 18.7416C11.7599 18.2471 11.1831 17.5443 10.8425 16.7221C10.5019 15.8998 10.4128 14.995 10.5865 14.1221C10.7601 13.2492 11.1887 12.4474 11.818 11.818C12.4474 11.1887 13.2492 10.7601 14.1221 10.5865C14.995 10.4128 15.8998 10.5019 16.7221 10.8425C17.5443 11.1831 18.2472 11.7599 18.7416 12.4999C19.2361 13.24 19.5 14.11 19.5 15C19.5003 15.591 19.3841 16.1763 19.1581 16.7224C18.932 17.2685 18.6005 17.7647 18.1826 18.1826C17.7647 18.6005 17.2685 18.932 16.7224 19.158C16.1763 19.3841 15.591 19.5003 15 19.5ZM15 12C14.7322 12.0037 14.4662 12.0436 14.2091 12.1184C14.421 12.4065 14.5227 12.7609 14.4957 13.1175C14.4688 13.4741 14.3149 13.8092 14.062 14.062C13.8092 14.3149 13.4741 14.4688 13.1175 14.4957C12.7609 14.5227 12.4065 14.421 12.1184 14.2091C11.9544 14.8133 11.984 15.4538 12.2031 16.0403C12.4221 16.6269 12.8196 17.1299 13.3396 17.4787C13.8595 17.8275 14.4758 18.0045 15.1016 17.9847C15.7274 17.965 16.3312 17.7495 16.8281 17.3685C17.325 16.9876 17.6899 16.4604 17.8715 15.8612C18.0531 15.2621 18.0422 14.621 17.8404 14.0283C17.6386 13.4356 17.256 12.9211 16.7465 12.5573C16.2369 12.1934 15.6261 11.9985 15 12Z"
                                                fill="#2576BC"
                                            />
                                           
                                        </svg>
                                        </a>
                                    </td>
                                </tr>
                            </React.Fragment>
                        ))}
                    </tbody>
                </table>
            </div>
        </>
    )
}

export default AppraisalSheet
