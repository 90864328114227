import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { BASE_URL } from "../../../config/axios"
import axios from "axios";
import TimelineItem from "../../../components/Timeline";


const Emergencycontactdetails = () => {
  let { email } = useParams()
  const [inputFields, setInputFields] = useState([
    { person_name: "", phone_number: "", relation: "", user: email },
  ]);
  const [errors, setErrors] = useState({});

  // const [empCode, setEmpCode] = useState()
  // const getCompanyDetails = async () => {
  //   try {
  //     const res = await axios.get(`${BASE_URL}/wfm/ourcompanyuserdetail/`);
  //     const emp = res.data.find(emp => emp.user_detail === email);
  //     setEmpCode(emp.emp_code)
  //   } catch (err) {
  //     // alert(err.message);
  //   }
  // };
  // console.log("empCode");
  // console.log(empCode);
  // useEffect(() => {
  //   getCompanyDetails();
  // }, [empCode]);



  const handleChange = (index, e) => {
    const { name, value } = e.target;
    const updatedFields = [...inputFields];
    updatedFields[index][name] = value;
    setInputFields(updatedFields);
  };

  const handleAddInputField = () => {
    setInputFields([
      ...inputFields,
      { person_name: "", phone_number: "", relation: "", user: email },
    ]);
  };

  const handleRemoveInputField = (index) => {
    const updatedFields = [...inputFields];
    updatedFields.splice(index, 1);
    setInputFields(updatedFields);
  };

  const validateForm = () => {
    const newErrors = {};
    inputFields.forEach((field, index) => {
      const requiredFields = [
        "person_name",
        "phone_number",
        "relation"

      ];
      requiredFields.forEach((reqField) => {
        if (!field[reqField]) {
          newErrors[`${reqField}_${index}`] = `${reqField.charAt(0).toUpperCase() + reqField.slice(1)
            } is required!`;
        }
      });
    });
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  console.log(inputFields);


  const navigate = useNavigate()
  const [loading, setLoading] = useState(false); //loading logic

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      setLoading(true); //loading logic
      try {
        let res = await axios.post(`${BASE_URL}/userdata/userEmergencyContactall/`, inputFields,)

        if (res.status === 200) {
          navigate(`/accountdetailss/${email}/`)
          // navigate(`/accountdetailss/${email}/`, { state: { empCode } })
        } else {
          alert(res);
        }
      } catch (err) {
        alert(err);
      } finally {
        setLoading(false); //loading logic
      }
    }
  };


  const handleFormPut = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      setLoading(true); //loading logic
      try {
        await Promise.all(
          inputFields.map(async (educationDetail) => {
            if (educationDetail.id) {
              await axios.put(`${BASE_URL}/userdata/userEmergencyContact/${educationDetail.id}/`, educationDetail);
            } else {
              await axios.post(`${BASE_URL}/userdata/userEmergencyContact/`, educationDetail);
            }
          })
        );

        navigate(`/accountdetailss/${email}/`)
        // navigate(`/accountdetailss/${email}/`, { state: { empCode } })
      } catch (err) {
        alert(err);
      } finally {
        setLoading(false); //loading logic
      }
    }
  };

  const backroute = () => {
    navigate(`/workexperiencee/${email}/`)
  }

  const [data, setData] = useState(false)




  useEffect(() => {
    // const storedUserData = Cookies.get("userData");

    const getUSerDetails = async () => {
      try {
        const res = await axios.get(`${BASE_URL}/userdata/userEmergencyContactallget/${email}/`);
        if (res.data.length > 0) {
          setInputFields(res.data);
          setData(true)
        }
      } catch (err) {
        // alert(err.message);
      }
    };

    getUSerDetails();
  }, []);



  return (
    // <div>

    //   <button type="button" onClick={handleAddInputField}>
    //     Add More Fields
    //   </button>

    // </div>

    <div className="jd">
      {/*  ************** timeline Seaction  ************** */}

      <div className="jd-timeline">
        <TimelineItem heading="Personal Information" color="#2576BC" />
        <div className="jd-timeline-borderr timeline-color"></div>
        <TimelineItem heading="Education Detail" color="#2576BC" />
        <div className="jd-timeline-borderr timeline-color"></div>
        <TimelineItem heading="Work Experience" color="#2576BC" />
        <div className="jd-timeline-borderr timeline-color"></div>
        <TimelineItem heading="Emergency Contact" color="#2576BC" />
        <div className="jd-timeline-borderr"></div>
        <TimelineItem heading="Account Details" color="#707070" />
        <div className="jd-timeline-borderr"></div>
        <TimelineItem heading="Documents Upload" color="#707070" />
      </div>

      <div className="jd-heading-outer">
        <div className="jd-heading-outer-flex">
          <h2 className="jd-heading-main">Emergency Contact Details *</h2>

          <h2 className="jd-heading-main-right">
            Please click here to add more{" "}
            <button
              type="button"
              onClick={handleAddInputField}
              className="jd-heading-main-right-button"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <g clipPath="url(#clip0_450_2151)">
                  <path
                    d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM17 13H13V17H11V13H7V11H11V7H13V11H17V13Z"
                    fill="#2576BC"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_450_2151">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </button>
          </h2>
        </div>
        <div>
          <div className="jd-heading-bottom-bold"></div>
          <div className="jd-heading-bottom-light"></div>
        </div>
      </div>

      <form className="register-form" >
        {inputFields.map((field, index) => (
          <div key={index} className="form-flex-wrap">
            <div className="flex-column">
              <label htmlFor={`person_name_${index}`} className="form-labels">
                Preson Name {index + 1}<span className="required">*</span>
              </label>
              <input
                type="text"
                id={`person_name_${index}`}
                name="person_name"
                value={field.person_name}
                onChange={(e) => handleChange(index, e)}
                className={`form-input ${errors[`person_name_${index}`]
                  ? "error"
                  : field.person_name
                    ? "success"
                    : ""
                  }`}
              />
              {errors[`person_name_${index}`] && (
                <span className="error-message">
                  {errors[`person_name_${index}`]}
                </span>
              )}
            </div>

            <div className="flex-column">
              <label htmlFor={`phone_number_${index}`} className="form-labels">
                Phone number<span className="required">*</span>
              </label>
              <input
                type="number"
                id={`phone_number_${index}`}
                name="phone_number"
                value={field.phone_number}
                onChange={(e) => handleChange(index, e)}
                className={`form-input ${errors[`phone_number_${index}`]
                  ? "error"
                  : field.phone_number
                    ? "success"
                    : ""
                  }`}
              />
              {errors[`phone_number_${index}`] && (
                <span className="error-message">
                  {errors[`phone_number_${index}`]}
                </span>
              )}
            </div>


            <div className="flex-column">
              <label htmlFor={`relation_${index}`} className="form-labels">
                Relation<span className="required">*</span>
              </label>
              <input
                type="text"
                id={`relation_${index}`}
                name="relation"
                value={field.relation}
                onChange={(e) => handleChange(index, e)}
                className={`form-input ${errors[`relation_${index}`]
                  ? "error"
                  : field.relation
                    ? "success"
                    : ""
                  }`}
              />
              {errors[`relation_${index}`] && (
                <span className="error-message">
                  {errors[`relation_${index}`]}
                </span>
              )}
            </div>

            <div className="flex-column"></div>

            <div>
              {index > 0 ? (
                <button
                  type="button"
                  onClick={() => handleRemoveInputField(index)}
                  className="remove-button"
                >
                  Remove
                </button>
              ) : (
                ""
              )}
            </div>
          </div>
        ))}
        <div className="button-models">
          <button className="model-button" onClick={backroute}>Back</button>
          {data ? (<button onClick={handleFormPut} className="model-button model-button-submit">
            Next
          </button>) : (<button onClick={handleFormSubmit} className="model-button model-button-submit">
            Next
          </button>)}
        </div>
      </form>
    </div>
  );
};

export default Emergencycontactdetails

