import React, { useState, useEffect } from "react";
import {
  AttendancePolicyTable,
  DepartmentTable,
  DesignationTable,
  Subcompany,
  Worksite,
  CalenderTable,
  InterComTable,
  WeeklyOffPolicyTable,
  EssLMachineTable
} from "./Organizationtables";
import Tree from "react-d3-tree";
import axios from "axios";
import { BASE_URL } from "../../config/axios";


import { OrganizationChart } from 'primereact/organizationchart';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';


const Hierarchy = () => {
  const [treeData, setTreeData] = useState([]);
  const [translate, setTranslate] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/wfm/hera/`);
        const transformedData = transformData(response.data);
        setTreeData([transformedData]);
        setInitialTranslate();
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const setInitialTranslate = () => {
    const dimensions = document.getElementById('treeWrapper').getBoundingClientRect();
    setTranslate({ x: dimensions.width / 2, y: dimensions.height / 2 });
  };

  const transformData = (data) => {
    const transformNode = (node) => {
      return {
        name: node.user_detail.name,
        attributes: {
          position: node.designation_name,
          department: node.department_name,
          profilepic: node.profilepic,
        },
        children: node.subordinates ? node.subordinates.map(transformNode) : [],
      };
    };

    return transformNode(data[0]);
  };

  return (
    <div id="treeWrapper" style={{ width: '100%', height: '80vh' }}>
      {treeData.length > 0 ? (
        <Tree
          data={treeData}
          orientation="vertical"
          translate={translate}
          nodeSvgShape={{
            shape: 'none',
          }}
          nodeLabelComponent={{
            render: <CustomLabel />,
            foreignObjectWrapper: {
              y: -25,
              x: -75,
              width: 150,
              height: 100,
            },
          }}
          collapsible={true}
          initialDepth={1}
        />
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};

const CustomLabel = ({ nodeData }) => {
  return (
    <div style={{
      border: '1px solid #ccc',
      borderRadius: '10px',
      padding: '10px',
      textAlign: 'center',
      background: '#fff',
      width: '150px',
      boxShadow: '0 2px 5px rgba(0,0,0,0.15)',
      fontFamily: 'Arial, sans-serif',
      fontSize: '14px'
    }}>
      {nodeData.attributes.profilepic ? (
        <img
          src={`https://spacecreator-aimantra.s3.ap-south-1.amazonaws.com/${nodeData.attributes.profilepic}`}
          alt={`${nodeData.name}'s profile`}
          style={{ borderRadius: '50%', width: '50px', height: '50px', marginBottom: '10px' }}
        />
      ) : (
        <div style={{ borderRadius: '50%', width: '50px', height: '50px', marginBottom: '10px', backgroundColor: '#ccc' }}></div>
      )}
      <strong>{nodeData.name}</strong>
      <div>{nodeData.attributes.position}</div>
      <div>{nodeData.attributes.department}</div>
    </div>
  );
};


const Hierarchyy = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://cipl.aimantra.info/wfm/hera/');
        console.log('API response:', response.data);
        const transformedData = transformData(response.data);
        console.log('Transformed data:', transformedData);
        setData([transformedData]);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const transformData = (data) => {
    const transformNode = (node) => {
      if (!node || !node.user_detail) {
        console.warn('Missing node or user_detail:', node);
        return null;
      }

      return {
        label: (
          <div style={{ textAlign: 'center', padding: '10px' }}>
            {node.profilepic ? (
              <img
                src={`https://spacecreator-aimantra.s3.ap-south-1.amazonaws.com/${node.profilepic}`}
                alt={`${node.user_detail.name}'s profile`}
                style={{ borderRadius: '50%', width: '50px', height: '50px', marginBottom: '10px' }}
              />
            ) : (
              <div style={{ borderRadius: '50%', width: '50px', height: '50px', marginBottom: '10px', backgroundColor: '#ccc' }}></div>
            )}
            <strong>{node.user_detail.name}</strong>
            <div>{node.designation_name}</div>
            <div>{node.department_name}</div>
          </div>
        ),
        children: node.subordinates ? node.subordinates.map(transformNode).filter(Boolean) : [],
      };
    };

    if (!data || data.length === 0) {
      console.warn('No data to transform');
      return {};
    }

    return transformNode(data[0]);
  };

  const nodeTemplate = (node) => {
    return node.label;
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <div style={{ width: '100%', height: '80vh' }}>
      {data.length > 0 ? (
        <OrganizationChart value={data} nodeTemplate={nodeTemplate} />
      ) : (
        <p>No data available</p>
      )}
    </div>
  );
};


const Orgstructure = () => {
  const [toggleState, setToggleState] = useState(1);

  return (
    <>
      <div>
        <div>
          <div className="bloc-tabs-with-white-bg">
            <button
              className={
                toggleState === 1
                  ? "dashboardcomponent-tabs  dashboardcomponent-active-tabs"
                  : "dashboardcomponent-tabs white-tab"
              }
              onClick={() => setToggleState(1)}
            >
              Hierarchy
            </button>
            <button
              className={
                toggleState === 2
                  ? "dashboardcomponent-tabs dashboardcomponent-active-tabs"
                  : "dashboardcomponent-tabs white-tab"
              }
              onClick={() => setToggleState(2)}
            >
              Department
            </button>
            <button
              className={
                toggleState === 3
                  ? "dashboardcomponent-tabs dashboardcomponent-active-tabs"
                  : "dashboardcomponent-tabs white-tab"
              }
              onClick={() => setToggleState(3)}
            >
              Designation
            </button>
            <button
              className={
                toggleState === 4
                  ? "dashboardcomponent-tabs dashboardcomponent-active-tabs"
                  : "dashboardcomponent-tabs white-tab"
              }
              onClick={() => setToggleState(4)}
            >
              Sub Company
            </button>
            <button
              className={
                toggleState === 5
                  ? "dashboardcomponent-tabs dashboardcomponent-active-tabs"
                  : "dashboardcomponent-tabs white-tab"
              }
              onClick={() => setToggleState(5)}
            >
              Work Sites
            </button>
            <button
              className={
                toggleState === 6
                  ? "dashboardcomponent-tabs dashboardcomponent-active-tabs"
                  : "dashboardcomponent-tabs white-tab"
              }
              onClick={() => setToggleState(6)}
            >
              Attendace Policy
            </button>
            <button
              className={
                toggleState === 7
                  ? "dashboardcomponent-tabs dashboardcomponent-active-tabs"
                  : "dashboardcomponent-tabs white-tab"
              }
              onClick={() => setToggleState(7)}
            >
              Weekly Off Policy
            </button>
            <button
              className={
                toggleState === 8
                  ? "dashboardcomponent-tabs dashboardcomponent-active-tabs"
                  : "dashboardcomponent-tabs white-tab"
              }
              onClick={() => setToggleState(8)}
            >
              CalenderTable
            </button>
            <button
              className={
                toggleState === 9
                  ? "dashboardcomponent-tabs dashboardcomponent-active-tabs"
                  : "dashboardcomponent-tabs white-tab"
              }
              onClick={() => setToggleState(9)}
            >
              InterCom
            </button>

            {
              sessionStorage.getItem('emp_code') === "1" ?
                <button
                  className={
                    toggleState === 10
                      ? "dashboardcomponent-tabs dashboardcomponent-active-tabs"
                      : "dashboardcomponent-tabs white-tab"
                  }
                  onClick={() => setToggleState(10)}
                >
                  ESSL
                </button>
                : null}
          </div>
        </div>

        <div className="content-tabs-without-margin">
          {toggleState === 1 ? <Hierarchy /> : null}

          {toggleState === 2 ? <DepartmentTable /> : null}

          {toggleState === 3 ? <DesignationTable /> : null}

          {toggleState === 4 ? <Subcompany /> : null}

          {toggleState === 5 ? <Worksite /> : null}

          {toggleState === 6 ? <AttendancePolicyTable /> : null}

          {toggleState === 7 ? <WeeklyOffPolicyTable /> : null}

          {toggleState === 8 ? <CalenderTable /> : null}

          {toggleState === 9 ? <InterComTable /> : null}

          {toggleState === 10 ? <EssLMachineTable /> : null}
        </div>
      </div>
    </>
  );
};

export default Orgstructure;