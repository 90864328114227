import React, { useEffect, useState } from "react";
import axios from "axios";
import { BASE_URL } from "../../config/axios";
import { formatDate, formattedDate } from "../Date";
import { Modal } from "react-bootstrap";
import Select from 'react-select';
import { RequisitionApprovedAdmin, RequisitionApprovedHR, RequisitionApprovedRH, RequisitionApprovedTLAdmin, RequisitionRejectAdmin, RequisitionRejectHR, RequisitionRejectRH, RequisitionRejectTLAdmin } from "../workforcemanagement/Requestapproved";


const HiredPeopleModel = ({ i, getRequisitions }) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => {
        setFormData(i);
        setShow(true);
    };
    const [formData, setFormData] = useState({
        name_of_hired_person: "",
    });



    useEffect(() => {
        setFormData(i);
    }, [i]);

    // !  ************** Validation start **************  ! //

    const [errors, setErrors] = useState({});
    const [inputState, setInputState] = useState({});

    const validateForm = () => {
        const newErrors = {};
        const requiredFields = ["name_of_hired_person"];
        requiredFields.forEach((field) => {
            if (!formData[field]) {
                newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
                    } is required !`;
            }
        });

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setInputState({
            ...inputState,
            [name]: value.trim() ? "green" : "",
        });

        setFormData({
            ...formData,
            [name]: value,
        });
    };

    // ?  ************** Validation End **************  ! //

    const [loading, setLoading] = useState(false); //loading logic
    const handleFormSubmit = async (e) => {
        e.preventDefault();
        setLoading(true); //loading logic

        if (validateForm()) {

            const updatedformvalue = ({
                ...formData,
                hr_status: "approved",
                hired: true,
                hired_date:formattedDate,
                name_of_hired_person: formData.name_of_hired_person,
            });

            try {
                let res = await axios.put(
                    `${BASE_URL}/jd/requisition/${i.id}/`,
                    updatedformvalue
                );

                if (res.status === 200) {
                    await getRequisitions();
                    setShow(false);
                } else {
                    alert(res);
                }
            } catch (err) {
                alert(err);
            }
        }
    };

    const [allemployee, setAllemployee] = useState([]);

    const getEmpcode = async () => {
        try {
            // const res = await axios.get(`${BASE_URL}/wfm/ourcompanyempdetailsactive/`);
            const res = await axios.get(`${BASE_URL}/wfm/ourcompanysRH/${sessionStorage.getItem("email")}/`);
            setAllemployee(res.data);
        } catch (err) {
            // alert(err.message);
        }
    };

    useEffect(() => {
        getEmpcode();
    }, []);

    const options = allemployee
        .sort((a, b) => parseInt(a.emp_code) - parseInt(b.emp_code))
        .map((e) => ({
            value: e.name,
            label: `${e.emp_code} - ${e.name}`,
        }));

    const handleSelectPeople = (selected) => {
        const hiredPeople = selected.length > 0 ? selected.map((item) => item.value).join(", ") : "";
        setFormData((prevState) => ({
            ...prevState,
            name_of_hired_person: hiredPeople,
        }));
    };

    console.log(allemployee)
    return (
        <>
            <button className="model-edit-button" title="Hired Employees" onClick={handleShow}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                >
                    <path
                        d="M15 4.5L6.75 12.75L3 9"
                        stroke="#145650"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            </button>
            {/* <input type="checkbox" checked={i.hired} readOnly onClick={handleShow} className="form-checkbox" /> */}
            {/* <button onClick={handleShow}>
                <input type="checkbox" title="Hired Employees" checked={false} readOnly className="form-checkbox" />
            </button> */}

            <Modal show={show} onHide={handleClose} dialogClassName="request-leave">
                <Modal.Header closeButton>
                    <Modal.Title>
                        Hired Personnel
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="parent-div">


                        <div className="flex-column">
                            <label htmlFor="off_days" className="form-labels announce-date">
                                Names of Person hired for {i.department_name} - {i.position_name}
                            </label>

                            <Select
                                options={options}
                                isMulti
                                // value={selectedDays}
                                onChange={handleSelectPeople}
                            />
                            {errors.remarks && (
                                <span className="error-message">{errors.remarks}</span>
                            )}
                        </div>
                        <div className="button-models">
                            <button className="model-button" onClick={handleClose}>Close</button>
                            <button className="model-button model-button-submit" onClick={handleFormSubmit} >
                                Submit
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};
const HiredPeopleModelCheck = ({ i, getRequisitions }) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => {
        setFormData(i);
        setShow(true);
    };
    const [formData, setFormData] = useState({
        name_of_hired_person: "",
    });



    useEffect(() => {
        setFormData(i);
    }, [i]);

    // !  ************** Validation start **************  ! //

    const [errors, setErrors] = useState({});
    const [inputState, setInputState] = useState({});

    const validateForm = () => {
        const newErrors = {};
        const requiredFields = ["name_of_hired_person"];
        requiredFields.forEach((field) => {
            if (!formData[field]) {
                newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
                    } is required !`;
            }
        });

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setInputState({
            ...inputState,
            [name]: value.trim() ? "green" : "",
        });

        setFormData({
            ...formData,
            [name]: value,
        });
    };

    // ?  ************** Validation End **************  ! //

    const [loading, setLoading] = useState(false); //loading logic
    const handleFormSubmit = async (e) => {
        e.preventDefault();
        setLoading(true); //loading logic

        if (validateForm()) {

            const updatedformvalue = ({
                ...formData,
                hired: true,
                name_of_hired_person: formData.name_of_hired_person,
            });

            try {
                let res = await axios.put(
                    `${BASE_URL}/jd/requisition/${i.id}/`,
                    updatedformvalue
                );

                if (res.status === 200) {
                    await getRequisitions();
                    setShow(false);
                } else {
                    alert(res);
                }
            } catch (err) {
                alert(err);
            }
        }
    };

    const [allemployee, setAllemployee] = useState([]);

    const getEmpcode = async () => {
        try {
            // const res = await axios.get(`${BASE_URL}/wfm/ourcompanyempdetailsactive/`);
            const res = await axios.get(`${BASE_URL}/wfm/ourcompanysRH/${sessionStorage.getItem("email")}/`);
            setAllemployee(res.data);
        } catch (err) {
            // alert(err.message);
        }
    };

    useEffect(() => {
        getEmpcode();
    }, []);

    const options = allemployee
        .sort((a, b) => parseInt(a.emp_code) - parseInt(b.emp_code))
        .map((e) => ({
            value: e.name,
            label: `${e.emp_code} - ${e.name}`,
        }));

    const handleSelectPeople = (selected) => {
        const hiredPeople = selected.length > 0 ? selected.map((item) => item.value).join(", ") : "";
        setFormData((prevState) => ({
            ...prevState,
            name_of_hired_person: hiredPeople,
        }));
    };

    console.log(allemployee)
    return (
        <>
            {/* <button className="model-edit-button" title="Schedule" onClick={handleShow}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="19"
                    height="19"
                    viewBox="0 0 19 19"
                    fill="none"
                >
                    <g clip-path="url(#clip0_719_1134)">
                        <path
                            d="M16.4127 2.5126C16.2712 2.37122 16.0946 2.27012 15.9011 2.21969C15.7076 2.16927 15.5041 2.17135 15.3116 2.22572H15.3011L1.80528 6.32072C1.58559 6.3839 1.39037 6.51251 1.24559 6.68941C1.10082 6.86631 1.01337 7.08312 0.994893 7.31096C0.976415 7.53881 1.02778 7.76687 1.14216 7.96479C1.25653 8.16271 1.42849 8.3211 1.63512 8.41885L7.65528 11.27L10.5065 17.2902C10.5965 17.483 10.7398 17.646 10.9194 17.76C11.0991 17.874 11.3076 17.9342 11.5204 17.9335C11.5527 17.9335 11.585 17.9321 11.6174 17.9293C11.8444 17.9109 12.0604 17.8236 12.2364 17.6789C12.4123 17.5342 12.5398 17.3392 12.6018 17.12L16.694 3.62424C16.694 3.62072 16.694 3.61721 16.694 3.61369C16.749 3.42171 16.752 3.21853 16.7026 3.02502C16.6532 2.83151 16.5531 2.65465 16.4127 2.5126ZM11.5267 16.798L11.5232 16.8078L8.75567 10.9656L12.0772 7.6433C12.1783 7.53695 12.2338 7.39534 12.2319 7.24867C12.23 7.10199 12.1709 6.96185 12.0672 6.85812C11.9635 6.75439 11.8233 6.69529 11.6766 6.69341C11.53 6.69154 11.3883 6.74703 11.282 6.84807L7.95973 10.1696L2.11677 7.40213H2.12661L15.6168 3.30854L11.5267 16.798Z"
                            fill="#2576BC"
                        />
                    </g>
                    <defs>
                        <clipPath id="clip0_719_1134">
                            <rect
                                width="18"
                                height="18"
                                fill="white"
                                transform="translate(0.429199 0.496094)"
                            />
                        </clipPath>
                    </defs>
                </svg>
            </button> */}
            {/* <input type="checkbox" checked={i.hired} readOnly onClick={handleShow} className="form-checkbox" /> */}
            <button onClick={handleShow}>
                <input type="checkbox" title="Hired Employees" checked={false} readOnly className="form-checkbox" />
            </button>

            <Modal show={show} onHide={handleClose} dialogClassName="request-leave">
                <Modal.Header closeButton>
                    <Modal.Title>
                        Hired Personnel
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="parent-div">


                        <div className="flex-column">
                            <label htmlFor="off_days" className="form-labels announce-date">
                                Names of Person hired for {i.department_name} - {i.position_name}
                            </label>

                            <Select
                                options={options}
                                isMulti
                                // value={selectedDays}
                                onChange={handleSelectPeople}
                            />
                            {errors.remarks && (
                                <span className="error-message">{errors.remarks}</span>
                            )}
                        </div>
                        <div className="button-models">
                            <button className="model-button" onClick={handleClose}>Close</button>
                            <button className="model-button model-button-submit" onClick={handleFormSubmit} >
                                Submit
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

const RequestRequisitionTableRH = () => {

    const [checkedItems, setCheckedItems] = useState([]);

    // *********** Employee Details Api Start ***********
    const [requisitionList, setRequisitionName] = useState([]);
    const [filteredList, setFilteredList] = useState([]);

    // const [allemployee, setAllemployee] = useState([]);

    const [selectedStatus, setSelectedStatus] = useState("pending");
    const [selectedDep, setDep] = useState('');
    const [selectedDesi, setDesi] = useState('');

    const [employeeGet, setEmployeeGet] = useState("null");
    const [fromdate, setFromDate] = useState("null");
    const [todate, setToDate] = useState("null");



    const getRequisitions = async () => {
        try {
            const res = await axios.get(`${BASE_URL}/jd/requisitionbyrh/${sessionStorage.getItem("emp_code")}/${selectedStatus}/${fromdate}/${todate}/${employeeGet}/`);
            setRequisitionName(res.data);
            console.log(res.data)
        } catch (err) {
            // alert(err.message);
        }
    };

    useEffect(() => {
        getRequisitions();
    }, [fromdate, todate, selectedStatus, employeeGet]);

    // const getEmpcode = async () => {
    //     try {
    //         const res = await axios.get(`${BASE_URL}/wfm/ourcompanysRH/${sessionStorage.getItem("email")}/`);
    //         setAllemployee(res.data);
    //     } catch (err) {
    //         // alert(err.message);
    //     }
    // };


    // useEffect(() => {
    //     getEmpcode();
    // }, []);

    const [designationname, setDesignationname] = useState([])

    const [departmentname, setDepartmentname] = useState([])

    const getDesignation = async () => {
        try {
            const res = await axios.get(`${BASE_URL}/wfm/designation/`);
            const ress = await axios.get(`${BASE_URL}/wfm/department/`);

            setDesignationname(res.data)
            setDepartmentname(ress.data)

        } catch (err) {
            // alert(err.message);
        }
    };

    useEffect(() => {
        getDesignation();

    }, []);

    const applyFilters = () => {
        let filteredData = requisitionList;

        if (selectedDep) {

            filteredData = filteredData.filter(employee => employee.department_name === selectedDep);
        }
        if (selectedDesi) {
            const lowercaseSelectedName = selectedDesi.toLowerCase();

            filteredData = filteredData.filter(employee => {
                const desiMatch = employee.position_name.toLowerCase().includes(lowercaseSelectedName);
                return desiMatch;
            });
        }

        setFilteredList(filteredData);

        console.log(filteredData)
    };


    useEffect(() => {

        applyFilters();
    }, [requisitionList, selectedDep, selectedDesi]);


    // *********** Employee Details Api End ***********

    return (
        <>
            <div className="attendance-history-cont">
                <div className="attendance-heading"> </div>
                <div className="attendance-subcont">
                    <div className="field-cont">
                        <div className="attendance-input-field date-uppercase ">

                            <input className="attendance-input-field date-field date-field-filter" placeholder='From Date' type="date" value={fromdate} onChange={(e) => setFromDate(e.target.value)} />
                        </div>

                        <hr />
                        <div className="attendance-input-field date-uppercase ">

                            <input className="attendance-input-field date-field date-field-filter" placeholder='From Date' type="date" value={todate} onChange={(e) => setToDate(e.target.value)} />
                        </div>
                        <hr />

                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="40"
                            height="38"
                            viewBox="0 0 17 17"
                            fill="none"
                        >
                            <g clip-path="url(#clip0_650_3351)">
                                <mask
                                    id="mask0_650_3351"
                                    maskUnits="userSpaceOnUse"
                                    x="0"
                                    y="0"
                                    width="17"
                                    height="17"
                                >
                                    <path d="M17 0H0V17H17V0Z" fill="white" />
                                </mask>
                                <g mask="url(#mask0_650_3351)">
                                    <path
                                        d="M8.50009 17.0057L8.00425 16.5807C7.32071 16.0083 1.35229 10.8538 1.35229 7.08334C1.35229 5.18763 2.10536 3.36956 3.44583 2.02909C4.78631 0.688616 6.60437 -0.0644531 8.50009 -0.0644531C10.3958 -0.0644531 12.2139 0.688616 13.5543 2.02909C14.8948 3.36956 15.6479 5.18763 15.6479 7.08334C15.6479 10.8538 9.67946 16.0083 8.99875 16.5835L8.50009 17.0057ZM8.50009 1.48113C7.0148 1.48282 5.59084 2.07359 4.54059 3.12384C3.49033 4.1741 2.89956 5.59806 2.89787 7.08334C2.89787 9.452 6.56987 13.236 8.50009 14.9678C10.4303 13.2352 14.1023 9.44917 14.1023 7.08334C14.1006 5.59806 13.5098 4.1741 12.4596 3.12384C11.4093 2.07359 9.98537 1.48282 8.50009 1.48113Z"
                                        fill="#707070"
                                    />
                                    <path
                                        d="M8.50008 9.91666C7.9397 9.91666 7.39191 9.7505 6.92597 9.43917C6.46003 9.12784 6.09687 8.68533 5.88242 8.16761C5.66798 7.64988 5.61186 7.08019 5.7212 6.53058C5.83052 5.98097 6.10036 5.47612 6.49662 5.07986C6.89286 4.68362 7.39771 4.41376 7.94734 4.30444C8.49695 4.19512 9.06663 4.25123 9.58436 4.46568C10.1021 4.68013 10.5446 5.04329 10.8559 5.50922C11.1672 5.97516 11.3334 6.52295 11.3334 7.08334C11.3334 7.83478 11.0349 8.55545 10.5036 9.0868C9.97221 9.61816 9.25154 9.91666 8.50008 9.91666ZM8.50008 5.66666C8.2199 5.66666 7.946 5.74975 7.71302 5.90542C7.48006 6.06109 7.29848 6.28234 7.19125 6.5412C7.08404 6.80007 7.05597 7.08491 7.11063 7.35972C7.1653 7.63452 7.30022 7.88694 7.49835 8.08507C7.69648 8.2832 7.9489 8.41811 8.2237 8.47279C8.49851 8.52745 8.78336 8.49939 9.04221 8.39217C9.30108 8.28494 9.52233 8.10337 9.678 7.8704C9.83367 7.63742 9.91675 7.36353 9.91675 7.08334C9.91675 6.70761 9.7675 6.34728 9.50181 6.08161C9.23614 5.81592 8.87581 5.66666 8.50008 5.66666Z"
                                        fill="#707070"
                                    />
                                </g>
                            </g>
                            <defs>
                                <clipPath id="clip0_650_3351">
                                    <rect width="17" height="17" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                        <select
                            className="attendance-input-field date-field"
                            type="text"
                            value={selectedStatus}
                            onChange={(e) => setSelectedStatus(e.target.value)}
                        >
                            <option value="null">All</option>

                            <option value="pending">Pending</option>
                            <option value="approved">Approved</option>
                            <option value="rejected">Rejected</option>

                        </select>
                        <hr />
                        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 14 14" fill="none">
                            <g clip-path="url(#clip0_650_3324)">
                                <path d="M2.625 10.2083H1.16667C0.857247 10.2083 0.560501 10.3312 0.341709 10.55C0.122916 10.7688 0 11.0655 0 11.3749L0 12.8333C0 13.1427 0.122916 13.4394 0.341709 13.6582C0.560501 13.877 0.857247 13.9999 1.16667 13.9999H2.625C2.93442 13.9999 3.23117 13.877 3.44996 13.6582C3.66875 13.4394 3.79167 13.1427 3.79167 12.8333V11.3749C3.79167 11.0655 3.66875 10.7688 3.44996 10.55C3.23117 10.3312 2.93442 10.2083 2.625 10.2083ZM2.625 12.8333H1.16667V11.3749H2.625V12.8333Z" fill="#707070" />
                                <path d="M12.8333 10.2083H11.3749C11.0655 10.2083 10.7688 10.3312 10.55 10.55C10.3312 10.7688 10.2083 11.0655 10.2083 11.3749V12.8333C10.2083 13.1427 10.3312 13.4394 10.55 13.6582C10.7688 13.877 11.0655 13.9999 11.3749 13.9999H12.8333C13.1427 13.9999 13.4394 13.877 13.6582 13.6582C13.877 13.4394 13.9999 13.1427 13.9999 12.8333V11.3749C13.9999 11.0655 13.877 10.7688 13.6582 10.55C13.4394 10.3312 13.1427 10.2083 12.8333 10.2083ZM12.8333 12.8333H11.3749V11.3749H12.8333V12.8333Z" fill="#707070" />
                                <path d="M2.625 5.10425H1.16667C0.857247 5.10425 0.560501 5.22716 0.341709 5.44596C0.122916 5.66475 0 5.9615 0 6.27091L0 7.72925C0 8.03867 0.122916 8.33541 0.341709 8.55421C0.560501 8.773 0.857247 8.89592 1.16667 8.89592H2.625C2.93442 8.89592 3.23117 8.773 3.44996 8.55421C3.66875 8.33541 3.79167 8.03867 3.79167 7.72925V6.27091C3.79167 5.9615 3.66875 5.66475 3.44996 5.44596C3.23117 5.22716 2.93442 5.10425 2.625 5.10425ZM2.625 7.72925H1.16667V6.27091H2.625V7.72925Z" fill="#707070" />
                                <path d="M12.8333 5.10425H11.3749C11.0655 5.10425 10.7688 5.22716 10.55 5.44596C10.3312 5.66475 10.2083 5.9615 10.2083 6.27091V7.72925C10.2083 8.03867 10.3312 8.33541 10.55 8.55421C10.7688 8.773 11.0655 8.89592 11.3749 8.89592H12.8333C13.1427 8.89592 13.4394 8.773 13.6582 8.55421C13.877 8.33541 13.9999 8.03867 13.9999 7.72925V6.27091C13.9999 5.9615 13.877 5.66475 13.6582 5.44596C13.4394 5.22716 13.1427 5.10425 12.8333 5.10425ZM12.8333 7.72925H11.3749V6.27091H12.8333V7.72925Z" fill="#707070" />
                                <path d="M2.625 0H1.16667C0.857247 0 0.560501 0.122916 0.341709 0.341709C0.122916 0.560501 0 0.857247 0 1.16667L0 2.625C0 2.93442 0.122916 3.23117 0.341709 3.44996C0.560501 3.66875 0.857247 3.79167 1.16667 3.79167H2.625C2.93442 3.79167 3.23117 3.66875 3.44996 3.44996C3.66875 3.23117 3.79167 2.93442 3.79167 2.625V1.16667C3.79167 0.857247 3.66875 0.560501 3.44996 0.341709C3.23117 0.122916 2.93442 0 2.625 0ZM2.625 2.625H1.16667V1.16667H2.625V2.625Z" fill="#707070" />
                                <path d="M7.72925 10.2083H6.27091C5.9615 10.2083 5.66475 10.3312 5.44596 10.55C5.22716 10.7688 5.10425 11.0655 5.10425 11.3749V12.8333C5.10425 13.1427 5.22716 13.4394 5.44596 13.6582C5.66475 13.877 5.9615 13.9999 6.27091 13.9999H7.72925C8.03867 13.9999 8.33541 13.877 8.55421 13.6582C8.773 13.4394 8.89592 13.1427 8.89592 12.8333V11.3749C8.89592 11.0655 8.773 10.7688 8.55421 10.55C8.33541 10.3312 8.03867 10.2083 7.72925 10.2083ZM7.72925 12.8333H6.27091V11.3749H7.72925V12.8333Z" fill="#707070" />
                                <path d="M7.72925 5.10425H6.27091C5.9615 5.10425 5.66475 5.22716 5.44596 5.44596C5.22716 5.66475 5.10425 5.9615 5.10425 6.27091V7.72925C5.10425 8.03867 5.22716 8.33541 5.44596 8.55421C5.66475 8.773 5.9615 8.89592 6.27091 8.89592H7.72925C8.03867 8.89592 8.33541 8.773 8.55421 8.55421C8.773 8.33541 8.89592 8.03867 8.89592 7.72925V6.27091C8.89592 5.9615 8.773 5.66475 8.55421 5.44596C8.33541 5.22716 8.03867 5.10425 7.72925 5.10425ZM7.72925 7.72925H6.27091V6.27091H7.72925V7.72925Z" fill="#707070" />
                                <path d="M7.72925 0H6.27091C5.9615 0 5.66475 0.122916 5.44596 0.341709C5.22716 0.560501 5.10425 0.857247 5.10425 1.16667V2.625C5.10425 2.93442 5.22716 3.23117 5.44596 3.44996C5.66475 3.66875 5.9615 3.79167 6.27091 3.79167H7.72925C8.03867 3.79167 8.33541 3.66875 8.55421 3.44996C8.773 3.23117 8.89592 2.93442 8.89592 2.625V1.16667C8.89592 0.857247 8.773 0.560501 8.55421 0.341709C8.33541 0.122916 8.03867 0 7.72925 0ZM7.72925 2.625H6.27091V1.16667H7.72925V2.625Z" fill="#707070" />
                                <path d="M12.8333 0H11.3749C11.0655 0 10.7688 0.122916 10.55 0.341709C10.3312 0.560501 10.2083 0.857247 10.2083 1.16667V2.625C10.2083 2.93442 10.3312 3.23117 10.55 3.44996C10.7688 3.66875 11.0655 3.79167 11.3749 3.79167H12.8333C13.1427 3.79167 13.4394 3.66875 13.6582 3.44996C13.877 3.23117 13.9999 2.93442 13.9999 2.625V1.16667C13.9999 0.857247 13.877 0.560501 13.6582 0.341709C13.4394 0.122916 13.1427 0 12.8333 0ZM12.8333 2.625H11.3749V1.16667H12.8333V2.625Z" fill="#707070" />
                            </g>
                            <defs>
                                <clipPath id="clip0_650_3324">
                                    <rect width="14" height="14" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>

                        <select className="attendance-input-field date-field" type="text"
                            value={selectedDep}
                            onChange={(e) => setDep(e.target.value)}
                        >
                            <option value=""> All Department</option>
                            {/* {emp_code.sort((a, b) => a.emp_code - b.emp_code).map((i, index) => (<option value={i.emp_code}>{i.emp_code} - {i.name}</option>))}; */}
                            {departmentname.sort((a, b) => a.title - b.title).map((i, index) => (<option value={i.title}>{i.title}</option>))};




                        </select>
                        <hr />

                        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 14 14" fill="none">
                            <g clip-path="url(#clip0_650_3324)">
                                <path d="M2.625 10.2083H1.16667C0.857247 10.2083 0.560501 10.3312 0.341709 10.55C0.122916 10.7688 0 11.0655 0 11.3749L0 12.8333C0 13.1427 0.122916 13.4394 0.341709 13.6582C0.560501 13.877 0.857247 13.9999 1.16667 13.9999H2.625C2.93442 13.9999 3.23117 13.877 3.44996 13.6582C3.66875 13.4394 3.79167 13.1427 3.79167 12.8333V11.3749C3.79167 11.0655 3.66875 10.7688 3.44996 10.55C3.23117 10.3312 2.93442 10.2083 2.625 10.2083ZM2.625 12.8333H1.16667V11.3749H2.625V12.8333Z" fill="#707070" />
                                <path d="M12.8333 10.2083H11.3749C11.0655 10.2083 10.7688 10.3312 10.55 10.55C10.3312 10.7688 10.2083 11.0655 10.2083 11.3749V12.8333C10.2083 13.1427 10.3312 13.4394 10.55 13.6582C10.7688 13.877 11.0655 13.9999 11.3749 13.9999H12.8333C13.1427 13.9999 13.4394 13.877 13.6582 13.6582C13.877 13.4394 13.9999 13.1427 13.9999 12.8333V11.3749C13.9999 11.0655 13.877 10.7688 13.6582 10.55C13.4394 10.3312 13.1427 10.2083 12.8333 10.2083ZM12.8333 12.8333H11.3749V11.3749H12.8333V12.8333Z" fill="#707070" />
                                <path d="M2.625 5.10425H1.16667C0.857247 5.10425 0.560501 5.22716 0.341709 5.44596C0.122916 5.66475 0 5.9615 0 6.27091L0 7.72925C0 8.03867 0.122916 8.33541 0.341709 8.55421C0.560501 8.773 0.857247 8.89592 1.16667 8.89592H2.625C2.93442 8.89592 3.23117 8.773 3.44996 8.55421C3.66875 8.33541 3.79167 8.03867 3.79167 7.72925V6.27091C3.79167 5.9615 3.66875 5.66475 3.44996 5.44596C3.23117 5.22716 2.93442 5.10425 2.625 5.10425ZM2.625 7.72925H1.16667V6.27091H2.625V7.72925Z" fill="#707070" />
                                <path d="M12.8333 5.10425H11.3749C11.0655 5.10425 10.7688 5.22716 10.55 5.44596C10.3312 5.66475 10.2083 5.9615 10.2083 6.27091V7.72925C10.2083 8.03867 10.3312 8.33541 10.55 8.55421C10.7688 8.773 11.0655 8.89592 11.3749 8.89592H12.8333C13.1427 8.89592 13.4394 8.773 13.6582 8.55421C13.877 8.33541 13.9999 8.03867 13.9999 7.72925V6.27091C13.9999 5.9615 13.877 5.66475 13.6582 5.44596C13.4394 5.22716 13.1427 5.10425 12.8333 5.10425ZM12.8333 7.72925H11.3749V6.27091H12.8333V7.72925Z" fill="#707070" />
                                <path d="M2.625 0H1.16667C0.857247 0 0.560501 0.122916 0.341709 0.341709C0.122916 0.560501 0 0.857247 0 1.16667L0 2.625C0 2.93442 0.122916 3.23117 0.341709 3.44996C0.560501 3.66875 0.857247 3.79167 1.16667 3.79167H2.625C2.93442 3.79167 3.23117 3.66875 3.44996 3.44996C3.66875 3.23117 3.79167 2.93442 3.79167 2.625V1.16667C3.79167 0.857247 3.66875 0.560501 3.44996 0.341709C3.23117 0.122916 2.93442 0 2.625 0ZM2.625 2.625H1.16667V1.16667H2.625V2.625Z" fill="#707070" />
                                <path d="M7.72925 10.2083H6.27091C5.9615 10.2083 5.66475 10.3312 5.44596 10.55C5.22716 10.7688 5.10425 11.0655 5.10425 11.3749V12.8333C5.10425 13.1427 5.22716 13.4394 5.44596 13.6582C5.66475 13.877 5.9615 13.9999 6.27091 13.9999H7.72925C8.03867 13.9999 8.33541 13.877 8.55421 13.6582C8.773 13.4394 8.89592 13.1427 8.89592 12.8333V11.3749C8.89592 11.0655 8.773 10.7688 8.55421 10.55C8.33541 10.3312 8.03867 10.2083 7.72925 10.2083ZM7.72925 12.8333H6.27091V11.3749H7.72925V12.8333Z" fill="#707070" />
                                <path d="M7.72925 5.10425H6.27091C5.9615 5.10425 5.66475 5.22716 5.44596 5.44596C5.22716 5.66475 5.10425 5.9615 5.10425 6.27091V7.72925C5.10425 8.03867 5.22716 8.33541 5.44596 8.55421C5.66475 8.773 5.9615 8.89592 6.27091 8.89592H7.72925C8.03867 8.89592 8.33541 8.773 8.55421 8.55421C8.773 8.33541 8.89592 8.03867 8.89592 7.72925V6.27091C8.89592 5.9615 8.773 5.66475 8.55421 5.44596C8.33541 5.22716 8.03867 5.10425 7.72925 5.10425ZM7.72925 7.72925H6.27091V6.27091H7.72925V7.72925Z" fill="#707070" />
                                <path d="M7.72925 0H6.27091C5.9615 0 5.66475 0.122916 5.44596 0.341709C5.22716 0.560501 5.10425 0.857247 5.10425 1.16667V2.625C5.10425 2.93442 5.22716 3.23117 5.44596 3.44996C5.66475 3.66875 5.9615 3.79167 6.27091 3.79167H7.72925C8.03867 3.79167 8.33541 3.66875 8.55421 3.44996C8.773 3.23117 8.89592 2.93442 8.89592 2.625V1.16667C8.89592 0.857247 8.773 0.560501 8.55421 0.341709C8.33541 0.122916 8.03867 0 7.72925 0ZM7.72925 2.625H6.27091V1.16667H7.72925V2.625Z" fill="#707070" />
                                <path d="M12.8333 0H11.3749C11.0655 0 10.7688 0.122916 10.55 0.341709C10.3312 0.560501 10.2083 0.857247 10.2083 1.16667V2.625C10.2083 2.93442 10.3312 3.23117 10.55 3.44996C10.7688 3.66875 11.0655 3.79167 11.3749 3.79167H12.8333C13.1427 3.79167 13.4394 3.66875 13.6582 3.44996C13.877 3.23117 13.9999 2.93442 13.9999 2.625V1.16667C13.9999 0.857247 13.877 0.560501 13.6582 0.341709C13.4394 0.122916 13.1427 0 12.8333 0ZM12.8333 2.625H11.3749V1.16667H12.8333V2.625Z" fill="#707070" />
                            </g>
                            <defs>
                                <clipPath id="clip0_650_3324">
                                    <rect width="14" height="14" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                        <input className="attendance-input-field" placeholder='Designation' type="text" value={selectedDesi}
                            onChange={(e) => setDesi(e.target.value)} />

                    </div>
                    <div className="btn-cont">

                    </div>
                </div>



                <div className="custom-table-background">
                    <div className="table-heading-flex">
                        <div className="repo-heading">Requisition Requests</div>
                        {/* <div className="flex-row">
                <button className="accept" onClick={handleFormSubmitApprovePut}>Accept</button>
                <button className="accept-rej" onClick={handleFormSubmitRejectedPut}>Rejected</button>
              </div> */}
                    </div>
                    <table className="custom-table">
                        <thead>
                            <tr className="custom-table-head-tr">
                                <th className="align-left">S. No.</th>
                                <th className="align-center">Requested On Date</th>
                                <th className="align-center">Department</th>
                                <th className="align-center">Designation</th>
                                <th className="align-center">Status</th>
                                {/* <th className="align-center">Hired</th> */}
                                <th className="align-right">Action</th>


                            </tr>
                        </thead>
                        <tbody>
                            {filteredList.map((i, index) => (
                                <React.Fragment key={index}>
                                    <tr className="tr-border-bottom">
                                        <td colSpan="6"></td>
                                    </tr>
                                    <tr className="custom-table-head-td">

                                        <td className="align-left">{index + 1}</td>

                                        <td className="align-center">{formatDate(i.date)}</td>
                                        <td className="align-center ">{i.department_name}</td>
                                        <td className="align-center ">{i.position_name}</td>

                                        <td className="align-center">
                                            <span request-status={i.rh_status} className="request-status align-center" >{i.rh_status === "pending" ? "Pending For Rh Approval ⌛" : i.rh_status === "rejected" ? "Rh ✖, " : "Rh ✔, "}</span>
                                            <br />
                                            <span request-status={i.admin_status} className="request-status align-center" >{(i.rh_status === "rejected" && i.admin_status === "rejected") ? "Admin ✖, " : (i.rh_status === "approved" && i.admin_status === "pending") ? "Pending For Admin Approval ⌛," : (i.rh_status === "approved" && i.admin_status === "rejected") ? "Admin ✖, " : (i.rh_status === "approved" && i.admin_status === "approved") ? "Admin ✔, " : "Admin Status: " + i.admin_status}</span>
                                            <br />
                                            <span request-status={i.hr_status} className="request-status align-center" >{(i.rh_status === "rejected" && i.admin_status === "rejected" && i.hr_status === "rejected") ? "HR ✖, " : (i.rh_status === "approved" && i.admin_status === "approved" && i.hr_status === "pending") ? "Pending For HR Approval ⌛" : (i.rh_status === "approved" && i.admin_status === "approved" && i.hr_status === "rejected") ? "HR ✖, " : (i.rh_status === "approved" && i.admin_status === "approved" && i.hr_status === "approved") ? "HR ✔, " : "HR Status: " + i.hr_status}</span>
                                        </td>
                                        {/* <td className="align-left ">
                        <input type="checkbox" checked={i.hired} readOnly className="form-checkbox" />
                      </td> */}

                                        <td className="align-right ">

                                            {i.rh_assigned === sessionStorage.getItem("emp_code")
                                                ?
                                                <>
                                                    <RequisitionApprovedTLAdmin i={i} getRequisitions={getRequisitions} />
                                                    <RequisitionRejectTLAdmin i={i} getRequisitions={getRequisitions} />
                                                </>

                                                :
                                                <>
                                                    <RequisitionApprovedRH i={i} getRequisitions={getRequisitions} />
                                                    <RequisitionRejectRH i={i} getRequisitions={getRequisitions} />
                                                </>
                                            }


                                        </td>
                                    </tr>
                                </React.Fragment>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
};


const RequestRequisitionTableAdmin = () => {


    const [checkedItems, setCheckedItems] = useState([]);

    // *********** Employee Details Api Start ***********
    const [requisitionList, setRequisitionName] = useState([]);


    const [selectedStatus, setSelectedStatus] = useState("pending");

    const [employeeGet, setEmployeeGet] = useState("null");
    const [fromdate, setFromDate] = useState("null");
    const [todate, setToDate] = useState("null");



    const getRequisitions = async () => {
        try {
            const res = await axios.get(`${BASE_URL}/jd/requisitionbyadmin/${selectedStatus}/${fromdate}/${todate}/${employeeGet}/`);
            setRequisitionName(res.data);
            console.log(res.data)
        } catch (err) {
            // alert(err.message);
        }
    };


    useEffect(() => {


        getRequisitions();
    }, [fromdate, todate, selectedStatus, employeeGet]);

    const [filteredList, setFilteredList] = useState([]);
    const [selectedDep, setDep] = useState('');
    const [selectedDesi, setDesi] = useState('');
    const [designationname, setDesignationname] = useState([])
    const [departmentname, setDepartmentname] = useState([])

    const getDesignation = async () => {
        try {
            const res = await axios.get(`${BASE_URL}/wfm/designation/`);
            const ress = await axios.get(`${BASE_URL}/wfm/department/`);

            setDesignationname(res.data)
            setDepartmentname(ress.data)

        } catch (err) {
            // alert(err.message);
        }
    };

    useEffect(() => {
        getDesignation();

    }, []);

    const applyFilters = () => {
        let filteredData = requisitionList;

        if (selectedDep) {

            filteredData = filteredData.filter(employee => employee.department_name === selectedDep);
        }
        if (selectedDesi) {
            const lowercaseSelectedName = selectedDesi.toLowerCase();

            filteredData = filteredData.filter(employee => {
                const desiMatch = employee.position_name.toLowerCase().includes(lowercaseSelectedName);
                return desiMatch;
            });
        }

        setFilteredList(filteredData);

        console.log(filteredData)
    };


    useEffect(() => {

        applyFilters();
    }, [requisitionList, selectedDep, selectedDesi]);


    // *********** Employee Details Api End ***********

    return (
        <>
            <div className="attendance-history-cont">
                <div className="attendance-heading"> </div>
                <div className="attendance-subcont">
                    <div className="field-cont">
                        <div className="attendance-input-field date-uppercase ">

                            <input className="attendance-input-field date-field date-field-filter" placeholder='From Date' type="date" value={fromdate} onChange={(e) => setFromDate(e.target.value)} />
                        </div>

                        <hr />
                        <div className="attendance-input-field date-uppercase ">

                            <input className="attendance-input-field date-field date-field-filter" placeholder='From Date' type="date" value={todate} onChange={(e) => setToDate(e.target.value)} />
                        </div>
                        <hr />

                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="40"
                            height="38"
                            viewBox="0 0 17 17"
                            fill="none"
                        >
                            <g clip-path="url(#clip0_650_3351)">
                                <mask
                                    id="mask0_650_3351"
                                    maskUnits="userSpaceOnUse"
                                    x="0"
                                    y="0"
                                    width="17"
                                    height="17"
                                >
                                    <path d="M17 0H0V17H17V0Z" fill="white" />
                                </mask>
                                <g mask="url(#mask0_650_3351)">
                                    <path
                                        d="M8.50009 17.0057L8.00425 16.5807C7.32071 16.0083 1.35229 10.8538 1.35229 7.08334C1.35229 5.18763 2.10536 3.36956 3.44583 2.02909C4.78631 0.688616 6.60437 -0.0644531 8.50009 -0.0644531C10.3958 -0.0644531 12.2139 0.688616 13.5543 2.02909C14.8948 3.36956 15.6479 5.18763 15.6479 7.08334C15.6479 10.8538 9.67946 16.0083 8.99875 16.5835L8.50009 17.0057ZM8.50009 1.48113C7.0148 1.48282 5.59084 2.07359 4.54059 3.12384C3.49033 4.1741 2.89956 5.59806 2.89787 7.08334C2.89787 9.452 6.56987 13.236 8.50009 14.9678C10.4303 13.2352 14.1023 9.44917 14.1023 7.08334C14.1006 5.59806 13.5098 4.1741 12.4596 3.12384C11.4093 2.07359 9.98537 1.48282 8.50009 1.48113Z"
                                        fill="#707070"
                                    />
                                    <path
                                        d="M8.50008 9.91666C7.9397 9.91666 7.39191 9.7505 6.92597 9.43917C6.46003 9.12784 6.09687 8.68533 5.88242 8.16761C5.66798 7.64988 5.61186 7.08019 5.7212 6.53058C5.83052 5.98097 6.10036 5.47612 6.49662 5.07986C6.89286 4.68362 7.39771 4.41376 7.94734 4.30444C8.49695 4.19512 9.06663 4.25123 9.58436 4.46568C10.1021 4.68013 10.5446 5.04329 10.8559 5.50922C11.1672 5.97516 11.3334 6.52295 11.3334 7.08334C11.3334 7.83478 11.0349 8.55545 10.5036 9.0868C9.97221 9.61816 9.25154 9.91666 8.50008 9.91666ZM8.50008 5.66666C8.2199 5.66666 7.946 5.74975 7.71302 5.90542C7.48006 6.06109 7.29848 6.28234 7.19125 6.5412C7.08404 6.80007 7.05597 7.08491 7.11063 7.35972C7.1653 7.63452 7.30022 7.88694 7.49835 8.08507C7.69648 8.2832 7.9489 8.41811 8.2237 8.47279C8.49851 8.52745 8.78336 8.49939 9.04221 8.39217C9.30108 8.28494 9.52233 8.10337 9.678 7.8704C9.83367 7.63742 9.91675 7.36353 9.91675 7.08334C9.91675 6.70761 9.7675 6.34728 9.50181 6.08161C9.23614 5.81592 8.87581 5.66666 8.50008 5.66666Z"
                                        fill="#707070"
                                    />
                                </g>
                            </g>
                            <defs>
                                <clipPath id="clip0_650_3351">
                                    <rect width="17" height="17" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                        <select
                            className="attendance-input-field date-field"
                            type="text"
                            value={selectedStatus}
                            onChange={(e) => setSelectedStatus(e.target.value)}
                        >
                            <option value="null">All</option>

                            <option value="pending">Pending</option>
                            <option value="approved">Approved</option>
                            <option value="rejected">Rejected</option>

                        </select>
                        <hr />
                        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 14 14" fill="none">
                            <g clip-path="url(#clip0_650_3324)">
                                <path d="M2.625 10.2083H1.16667C0.857247 10.2083 0.560501 10.3312 0.341709 10.55C0.122916 10.7688 0 11.0655 0 11.3749L0 12.8333C0 13.1427 0.122916 13.4394 0.341709 13.6582C0.560501 13.877 0.857247 13.9999 1.16667 13.9999H2.625C2.93442 13.9999 3.23117 13.877 3.44996 13.6582C3.66875 13.4394 3.79167 13.1427 3.79167 12.8333V11.3749C3.79167 11.0655 3.66875 10.7688 3.44996 10.55C3.23117 10.3312 2.93442 10.2083 2.625 10.2083ZM2.625 12.8333H1.16667V11.3749H2.625V12.8333Z" fill="#707070" />
                                <path d="M12.8333 10.2083H11.3749C11.0655 10.2083 10.7688 10.3312 10.55 10.55C10.3312 10.7688 10.2083 11.0655 10.2083 11.3749V12.8333C10.2083 13.1427 10.3312 13.4394 10.55 13.6582C10.7688 13.877 11.0655 13.9999 11.3749 13.9999H12.8333C13.1427 13.9999 13.4394 13.877 13.6582 13.6582C13.877 13.4394 13.9999 13.1427 13.9999 12.8333V11.3749C13.9999 11.0655 13.877 10.7688 13.6582 10.55C13.4394 10.3312 13.1427 10.2083 12.8333 10.2083ZM12.8333 12.8333H11.3749V11.3749H12.8333V12.8333Z" fill="#707070" />
                                <path d="M2.625 5.10425H1.16667C0.857247 5.10425 0.560501 5.22716 0.341709 5.44596C0.122916 5.66475 0 5.9615 0 6.27091L0 7.72925C0 8.03867 0.122916 8.33541 0.341709 8.55421C0.560501 8.773 0.857247 8.89592 1.16667 8.89592H2.625C2.93442 8.89592 3.23117 8.773 3.44996 8.55421C3.66875 8.33541 3.79167 8.03867 3.79167 7.72925V6.27091C3.79167 5.9615 3.66875 5.66475 3.44996 5.44596C3.23117 5.22716 2.93442 5.10425 2.625 5.10425ZM2.625 7.72925H1.16667V6.27091H2.625V7.72925Z" fill="#707070" />
                                <path d="M12.8333 5.10425H11.3749C11.0655 5.10425 10.7688 5.22716 10.55 5.44596C10.3312 5.66475 10.2083 5.9615 10.2083 6.27091V7.72925C10.2083 8.03867 10.3312 8.33541 10.55 8.55421C10.7688 8.773 11.0655 8.89592 11.3749 8.89592H12.8333C13.1427 8.89592 13.4394 8.773 13.6582 8.55421C13.877 8.33541 13.9999 8.03867 13.9999 7.72925V6.27091C13.9999 5.9615 13.877 5.66475 13.6582 5.44596C13.4394 5.22716 13.1427 5.10425 12.8333 5.10425ZM12.8333 7.72925H11.3749V6.27091H12.8333V7.72925Z" fill="#707070" />
                                <path d="M2.625 0H1.16667C0.857247 0 0.560501 0.122916 0.341709 0.341709C0.122916 0.560501 0 0.857247 0 1.16667L0 2.625C0 2.93442 0.122916 3.23117 0.341709 3.44996C0.560501 3.66875 0.857247 3.79167 1.16667 3.79167H2.625C2.93442 3.79167 3.23117 3.66875 3.44996 3.44996C3.66875 3.23117 3.79167 2.93442 3.79167 2.625V1.16667C3.79167 0.857247 3.66875 0.560501 3.44996 0.341709C3.23117 0.122916 2.93442 0 2.625 0ZM2.625 2.625H1.16667V1.16667H2.625V2.625Z" fill="#707070" />
                                <path d="M7.72925 10.2083H6.27091C5.9615 10.2083 5.66475 10.3312 5.44596 10.55C5.22716 10.7688 5.10425 11.0655 5.10425 11.3749V12.8333C5.10425 13.1427 5.22716 13.4394 5.44596 13.6582C5.66475 13.877 5.9615 13.9999 6.27091 13.9999H7.72925C8.03867 13.9999 8.33541 13.877 8.55421 13.6582C8.773 13.4394 8.89592 13.1427 8.89592 12.8333V11.3749C8.89592 11.0655 8.773 10.7688 8.55421 10.55C8.33541 10.3312 8.03867 10.2083 7.72925 10.2083ZM7.72925 12.8333H6.27091V11.3749H7.72925V12.8333Z" fill="#707070" />
                                <path d="M7.72925 5.10425H6.27091C5.9615 5.10425 5.66475 5.22716 5.44596 5.44596C5.22716 5.66475 5.10425 5.9615 5.10425 6.27091V7.72925C5.10425 8.03867 5.22716 8.33541 5.44596 8.55421C5.66475 8.773 5.9615 8.89592 6.27091 8.89592H7.72925C8.03867 8.89592 8.33541 8.773 8.55421 8.55421C8.773 8.33541 8.89592 8.03867 8.89592 7.72925V6.27091C8.89592 5.9615 8.773 5.66475 8.55421 5.44596C8.33541 5.22716 8.03867 5.10425 7.72925 5.10425ZM7.72925 7.72925H6.27091V6.27091H7.72925V7.72925Z" fill="#707070" />
                                <path d="M7.72925 0H6.27091C5.9615 0 5.66475 0.122916 5.44596 0.341709C5.22716 0.560501 5.10425 0.857247 5.10425 1.16667V2.625C5.10425 2.93442 5.22716 3.23117 5.44596 3.44996C5.66475 3.66875 5.9615 3.79167 6.27091 3.79167H7.72925C8.03867 3.79167 8.33541 3.66875 8.55421 3.44996C8.773 3.23117 8.89592 2.93442 8.89592 2.625V1.16667C8.89592 0.857247 8.773 0.560501 8.55421 0.341709C8.33541 0.122916 8.03867 0 7.72925 0ZM7.72925 2.625H6.27091V1.16667H7.72925V2.625Z" fill="#707070" />
                                <path d="M12.8333 0H11.3749C11.0655 0 10.7688 0.122916 10.55 0.341709C10.3312 0.560501 10.2083 0.857247 10.2083 1.16667V2.625C10.2083 2.93442 10.3312 3.23117 10.55 3.44996C10.7688 3.66875 11.0655 3.79167 11.3749 3.79167H12.8333C13.1427 3.79167 13.4394 3.66875 13.6582 3.44996C13.877 3.23117 13.9999 2.93442 13.9999 2.625V1.16667C13.9999 0.857247 13.877 0.560501 13.6582 0.341709C13.4394 0.122916 13.1427 0 12.8333 0ZM12.8333 2.625H11.3749V1.16667H12.8333V2.625Z" fill="#707070" />
                            </g>
                            <defs>
                                <clipPath id="clip0_650_3324">
                                    <rect width="14" height="14" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>

                        <select className="attendance-input-field date-field" type="text"
                            value={selectedDep}
                            onChange={(e) => setDep(e.target.value)}
                        >
                            <option value=""> All Department</option>
                            {/* {emp_code.sort((a, b) => a.emp_code - b.emp_code).map((i, index) => (<option value={i.emp_code}>{i.emp_code} - {i.name}</option>))}; */}
                            {departmentname.sort((a, b) => a.title - b.title).map((i, index) => (<option value={i.title}>{i.title}</option>))};




                        </select>
                        <hr />

                        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 14 14" fill="none">
                            <g clip-path="url(#clip0_650_3324)">
                                <path d="M2.625 10.2083H1.16667C0.857247 10.2083 0.560501 10.3312 0.341709 10.55C0.122916 10.7688 0 11.0655 0 11.3749L0 12.8333C0 13.1427 0.122916 13.4394 0.341709 13.6582C0.560501 13.877 0.857247 13.9999 1.16667 13.9999H2.625C2.93442 13.9999 3.23117 13.877 3.44996 13.6582C3.66875 13.4394 3.79167 13.1427 3.79167 12.8333V11.3749C3.79167 11.0655 3.66875 10.7688 3.44996 10.55C3.23117 10.3312 2.93442 10.2083 2.625 10.2083ZM2.625 12.8333H1.16667V11.3749H2.625V12.8333Z" fill="#707070" />
                                <path d="M12.8333 10.2083H11.3749C11.0655 10.2083 10.7688 10.3312 10.55 10.55C10.3312 10.7688 10.2083 11.0655 10.2083 11.3749V12.8333C10.2083 13.1427 10.3312 13.4394 10.55 13.6582C10.7688 13.877 11.0655 13.9999 11.3749 13.9999H12.8333C13.1427 13.9999 13.4394 13.877 13.6582 13.6582C13.877 13.4394 13.9999 13.1427 13.9999 12.8333V11.3749C13.9999 11.0655 13.877 10.7688 13.6582 10.55C13.4394 10.3312 13.1427 10.2083 12.8333 10.2083ZM12.8333 12.8333H11.3749V11.3749H12.8333V12.8333Z" fill="#707070" />
                                <path d="M2.625 5.10425H1.16667C0.857247 5.10425 0.560501 5.22716 0.341709 5.44596C0.122916 5.66475 0 5.9615 0 6.27091L0 7.72925C0 8.03867 0.122916 8.33541 0.341709 8.55421C0.560501 8.773 0.857247 8.89592 1.16667 8.89592H2.625C2.93442 8.89592 3.23117 8.773 3.44996 8.55421C3.66875 8.33541 3.79167 8.03867 3.79167 7.72925V6.27091C3.79167 5.9615 3.66875 5.66475 3.44996 5.44596C3.23117 5.22716 2.93442 5.10425 2.625 5.10425ZM2.625 7.72925H1.16667V6.27091H2.625V7.72925Z" fill="#707070" />
                                <path d="M12.8333 5.10425H11.3749C11.0655 5.10425 10.7688 5.22716 10.55 5.44596C10.3312 5.66475 10.2083 5.9615 10.2083 6.27091V7.72925C10.2083 8.03867 10.3312 8.33541 10.55 8.55421C10.7688 8.773 11.0655 8.89592 11.3749 8.89592H12.8333C13.1427 8.89592 13.4394 8.773 13.6582 8.55421C13.877 8.33541 13.9999 8.03867 13.9999 7.72925V6.27091C13.9999 5.9615 13.877 5.66475 13.6582 5.44596C13.4394 5.22716 13.1427 5.10425 12.8333 5.10425ZM12.8333 7.72925H11.3749V6.27091H12.8333V7.72925Z" fill="#707070" />
                                <path d="M2.625 0H1.16667C0.857247 0 0.560501 0.122916 0.341709 0.341709C0.122916 0.560501 0 0.857247 0 1.16667L0 2.625C0 2.93442 0.122916 3.23117 0.341709 3.44996C0.560501 3.66875 0.857247 3.79167 1.16667 3.79167H2.625C2.93442 3.79167 3.23117 3.66875 3.44996 3.44996C3.66875 3.23117 3.79167 2.93442 3.79167 2.625V1.16667C3.79167 0.857247 3.66875 0.560501 3.44996 0.341709C3.23117 0.122916 2.93442 0 2.625 0ZM2.625 2.625H1.16667V1.16667H2.625V2.625Z" fill="#707070" />
                                <path d="M7.72925 10.2083H6.27091C5.9615 10.2083 5.66475 10.3312 5.44596 10.55C5.22716 10.7688 5.10425 11.0655 5.10425 11.3749V12.8333C5.10425 13.1427 5.22716 13.4394 5.44596 13.6582C5.66475 13.877 5.9615 13.9999 6.27091 13.9999H7.72925C8.03867 13.9999 8.33541 13.877 8.55421 13.6582C8.773 13.4394 8.89592 13.1427 8.89592 12.8333V11.3749C8.89592 11.0655 8.773 10.7688 8.55421 10.55C8.33541 10.3312 8.03867 10.2083 7.72925 10.2083ZM7.72925 12.8333H6.27091V11.3749H7.72925V12.8333Z" fill="#707070" />
                                <path d="M7.72925 5.10425H6.27091C5.9615 5.10425 5.66475 5.22716 5.44596 5.44596C5.22716 5.66475 5.10425 5.9615 5.10425 6.27091V7.72925C5.10425 8.03867 5.22716 8.33541 5.44596 8.55421C5.66475 8.773 5.9615 8.89592 6.27091 8.89592H7.72925C8.03867 8.89592 8.33541 8.773 8.55421 8.55421C8.773 8.33541 8.89592 8.03867 8.89592 7.72925V6.27091C8.89592 5.9615 8.773 5.66475 8.55421 5.44596C8.33541 5.22716 8.03867 5.10425 7.72925 5.10425ZM7.72925 7.72925H6.27091V6.27091H7.72925V7.72925Z" fill="#707070" />
                                <path d="M7.72925 0H6.27091C5.9615 0 5.66475 0.122916 5.44596 0.341709C5.22716 0.560501 5.10425 0.857247 5.10425 1.16667V2.625C5.10425 2.93442 5.22716 3.23117 5.44596 3.44996C5.66475 3.66875 5.9615 3.79167 6.27091 3.79167H7.72925C8.03867 3.79167 8.33541 3.66875 8.55421 3.44996C8.773 3.23117 8.89592 2.93442 8.89592 2.625V1.16667C8.89592 0.857247 8.773 0.560501 8.55421 0.341709C8.33541 0.122916 8.03867 0 7.72925 0ZM7.72925 2.625H6.27091V1.16667H7.72925V2.625Z" fill="#707070" />
                                <path d="M12.8333 0H11.3749C11.0655 0 10.7688 0.122916 10.55 0.341709C10.3312 0.560501 10.2083 0.857247 10.2083 1.16667V2.625C10.2083 2.93442 10.3312 3.23117 10.55 3.44996C10.7688 3.66875 11.0655 3.79167 11.3749 3.79167H12.8333C13.1427 3.79167 13.4394 3.66875 13.6582 3.44996C13.877 3.23117 13.9999 2.93442 13.9999 2.625V1.16667C13.9999 0.857247 13.877 0.560501 13.6582 0.341709C13.4394 0.122916 13.1427 0 12.8333 0ZM12.8333 2.625H11.3749V1.16667H12.8333V2.625Z" fill="#707070" />
                            </g>
                            <defs>
                                <clipPath id="clip0_650_3324">
                                    <rect width="14" height="14" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                        <input className="attendance-input-field" placeholder='Designation' type="text" value={selectedDesi}
                            onChange={(e) => setDesi(e.target.value)} />

                    </div>
                    <div className="btn-cont">

                    </div>
                </div>



                <div className="custom-table-background">
                    <div className="table-heading-flex">
                        <div className="repo-heading">Requisition Request</div>
                    </div>
                    <table className="custom-table">
                        <thead>
                            <tr className="custom-table-head-tr">
                                <th className="align-left">S. No.</th>
                                <th className="align-center">Requested On Date</th>
                                <th className="align-center">Department</th>
                                <th className="align-center">Designation</th>
                                <th className="align-center">Status</th>
                                {/* <th className="align-center">Hired</th> */}
                                <th className="align-right">Action</th>


                            </tr>
                        </thead>
                        <tbody>
                            {filteredList.map((i, index) => (
                                <React.Fragment key={index}>
                                    <tr className="tr-border-bottom">
                                        <td colSpan="6"></td>
                                    </tr>
                                    <tr className="custom-table-head-td">

                                        <td className="align-left">{index + 1}</td>

                                        <td className="align-center">{formatDate(i.date)}</td>
                                        <td className="align-center ">{i.department_name}</td>
                                        <td className="align-center ">{i.position_name}</td>

                                        <td className="align-center">
                                            <span request-status={i.rh_status} className="request-status align-center" >{i.rh_status === "pending" ? "Pending For Rh Approval ⌛" : i.rh_status === "rejected" ? "Rh ✖, " : "Rh ✔, "}</span>
                                            <br />
                                            <span request-status={i.admin_status} className="request-status align-center" >{(i.rh_status === "rejected" && i.admin_status === "rejected") ? "Admin ✖, " : (i.rh_status === "approved" && i.admin_status === "pending") ? "Pending For Admin Approval ⌛," : (i.rh_status === "approved" && i.admin_status === "rejected") ? "Admin ✖, " : (i.rh_status === "approved" && i.admin_status === "approved") ? "Admin ✔, " : "Admin Status: " + i.admin_status}</span>
                                            <br />
                                            <span request-status={i.hr_status} className="request-status align-center" >{(i.rh_status === "rejected" && i.admin_status === "rejected" && i.hr_status === "rejected") ? "HR ✖, " : (i.rh_status === "approved" && i.admin_status === "approved" && i.hr_status === "pending") ? "Pending For HR Approval ⌛" : (i.rh_status === "approved" && i.admin_status === "approved" && i.hr_status === "rejected") ? "HR ✖, " : (i.rh_status === "approved" && i.admin_status === "approved" && i.hr_status === "approved") ? "HR ✔, " : "HR Status: " + i.hr_status}</span>
                                        </td>
                                        {/* <td className="align-left ">
                        <input type="checkbox" checked={i.hired} readOnly className="form-checkbox" />
                      </td> */}

                                        <td className="align-right ">

                                            <RequisitionApprovedAdmin i={i} getRequisitions={getRequisitions} />

                                            <RequisitionRejectAdmin i={i} getRequisitions={getRequisitions} />


                                        </td>
                                    </tr>
                                </React.Fragment>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
};

const RequestRequisitionTableHR = () => {


    const [checkedItems, setCheckedItems] = useState([]);

    // *********** Employee Details Api Start ***********
    const [requisitionList, setRequisitionName] = useState([]);


    const [selectedStatus, setSelectedStatus] = useState("pending");

    const [employeeGet, setEmployeeGet] = useState("null");
    const [fromdate, setFromDate] = useState("null");
    const [todate, setToDate] = useState("null");



    const getRequisitions = async () => {
        try {
            const res = await axios.get(`${BASE_URL}/jd/requisitionbyhr/${selectedStatus}/${fromdate}/${todate}/${employeeGet}/`);
            setRequisitionName(res.data);
            console.log(res.data)
        } catch (err) {
            // alert(err.message);
        }
    };


    useEffect(() => {


        getRequisitions();
    }, [fromdate, todate, selectedStatus, employeeGet]);

    // *********** Employee Details Api End ***********

    const [filteredList, setFilteredList] = useState([]);
    const [selectedDep, setDep] = useState('');
    const [selectedDesi, setDesi] = useState('');
    const [designationname, setDesignationname] = useState([])
    const [departmentname, setDepartmentname] = useState([])

    const getDesignation = async () => {
        try {
            const res = await axios.get(`${BASE_URL}/wfm/designation/`);
            const ress = await axios.get(`${BASE_URL}/wfm/department/`);

            setDesignationname(res.data)
            setDepartmentname(ress.data)

        } catch (err) {
            // alert(err.message);
        }
    };

    useEffect(() => {
        getDesignation();

    }, []);

    const applyFilters = () => {
        let filteredData = requisitionList;

        if (selectedDep) {

            filteredData = filteredData.filter(employee => employee.department_name === selectedDep);
        }
        if (selectedDesi) {
            const lowercaseSelectedName = selectedDesi.toLowerCase();

            filteredData = filteredData.filter(employee => {
                const desiMatch = employee.position_name.toLowerCase().includes(lowercaseSelectedName);
                return desiMatch;
            });
        }

        setFilteredList(filteredData);

        console.log(filteredData)
    };


    useEffect(() => {

        applyFilters();
    }, [requisitionList, selectedDep, selectedDesi]);



    return (
        <>
            <div className="attendance-history-cont">
                <div className="attendance-heading"> </div>
                <div className="attendance-subcont">
                    <div className="field-cont">
                        <div className="attendance-input-field date-uppercase ">

                            <input className="attendance-input-field date-field date-field-filter" placeholder='From Date' type="date" value={fromdate} onChange={(e) => setFromDate(e.target.value)} />
                        </div>

                        <hr />
                        <div className="attendance-input-field date-uppercase ">

                            <input className="attendance-input-field date-field date-field-filter" placeholder='From Date' type="date" value={todate} onChange={(e) => setToDate(e.target.value)} />
                        </div>
                        <hr />

                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="40"
                            height="38"
                            viewBox="0 0 17 17"
                            fill="none"
                        >
                            <g clip-path="url(#clip0_650_3351)">
                                <mask
                                    id="mask0_650_3351"
                                    maskUnits="userSpaceOnUse"
                                    x="0"
                                    y="0"
                                    width="17"
                                    height="17"
                                >
                                    <path d="M17 0H0V17H17V0Z" fill="white" />
                                </mask>
                                <g mask="url(#mask0_650_3351)">
                                    <path
                                        d="M8.50009 17.0057L8.00425 16.5807C7.32071 16.0083 1.35229 10.8538 1.35229 7.08334C1.35229 5.18763 2.10536 3.36956 3.44583 2.02909C4.78631 0.688616 6.60437 -0.0644531 8.50009 -0.0644531C10.3958 -0.0644531 12.2139 0.688616 13.5543 2.02909C14.8948 3.36956 15.6479 5.18763 15.6479 7.08334C15.6479 10.8538 9.67946 16.0083 8.99875 16.5835L8.50009 17.0057ZM8.50009 1.48113C7.0148 1.48282 5.59084 2.07359 4.54059 3.12384C3.49033 4.1741 2.89956 5.59806 2.89787 7.08334C2.89787 9.452 6.56987 13.236 8.50009 14.9678C10.4303 13.2352 14.1023 9.44917 14.1023 7.08334C14.1006 5.59806 13.5098 4.1741 12.4596 3.12384C11.4093 2.07359 9.98537 1.48282 8.50009 1.48113Z"
                                        fill="#707070"
                                    />
                                    <path
                                        d="M8.50008 9.91666C7.9397 9.91666 7.39191 9.7505 6.92597 9.43917C6.46003 9.12784 6.09687 8.68533 5.88242 8.16761C5.66798 7.64988 5.61186 7.08019 5.7212 6.53058C5.83052 5.98097 6.10036 5.47612 6.49662 5.07986C6.89286 4.68362 7.39771 4.41376 7.94734 4.30444C8.49695 4.19512 9.06663 4.25123 9.58436 4.46568C10.1021 4.68013 10.5446 5.04329 10.8559 5.50922C11.1672 5.97516 11.3334 6.52295 11.3334 7.08334C11.3334 7.83478 11.0349 8.55545 10.5036 9.0868C9.97221 9.61816 9.25154 9.91666 8.50008 9.91666ZM8.50008 5.66666C8.2199 5.66666 7.946 5.74975 7.71302 5.90542C7.48006 6.06109 7.29848 6.28234 7.19125 6.5412C7.08404 6.80007 7.05597 7.08491 7.11063 7.35972C7.1653 7.63452 7.30022 7.88694 7.49835 8.08507C7.69648 8.2832 7.9489 8.41811 8.2237 8.47279C8.49851 8.52745 8.78336 8.49939 9.04221 8.39217C9.30108 8.28494 9.52233 8.10337 9.678 7.8704C9.83367 7.63742 9.91675 7.36353 9.91675 7.08334C9.91675 6.70761 9.7675 6.34728 9.50181 6.08161C9.23614 5.81592 8.87581 5.66666 8.50008 5.66666Z"
                                        fill="#707070"
                                    />
                                </g>
                            </g>
                            <defs>
                                <clipPath id="clip0_650_3351">
                                    <rect width="17" height="17" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                        <select
                            className="attendance-input-field date-field"
                            type="text"
                            value={selectedStatus}
                            onChange={(e) => setSelectedStatus(e.target.value)}
                        >
                            <option value="null">All</option>

                            <option value="pending">Pending</option>
                            <option value="approved">Approved</option>
                            <option value="rejected">Rejected</option>

                        </select>
                        <hr />
                        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 14 14" fill="none">
                            <g clip-path="url(#clip0_650_3324)">
                                <path d="M2.625 10.2083H1.16667C0.857247 10.2083 0.560501 10.3312 0.341709 10.55C0.122916 10.7688 0 11.0655 0 11.3749L0 12.8333C0 13.1427 0.122916 13.4394 0.341709 13.6582C0.560501 13.877 0.857247 13.9999 1.16667 13.9999H2.625C2.93442 13.9999 3.23117 13.877 3.44996 13.6582C3.66875 13.4394 3.79167 13.1427 3.79167 12.8333V11.3749C3.79167 11.0655 3.66875 10.7688 3.44996 10.55C3.23117 10.3312 2.93442 10.2083 2.625 10.2083ZM2.625 12.8333H1.16667V11.3749H2.625V12.8333Z" fill="#707070" />
                                <path d="M12.8333 10.2083H11.3749C11.0655 10.2083 10.7688 10.3312 10.55 10.55C10.3312 10.7688 10.2083 11.0655 10.2083 11.3749V12.8333C10.2083 13.1427 10.3312 13.4394 10.55 13.6582C10.7688 13.877 11.0655 13.9999 11.3749 13.9999H12.8333C13.1427 13.9999 13.4394 13.877 13.6582 13.6582C13.877 13.4394 13.9999 13.1427 13.9999 12.8333V11.3749C13.9999 11.0655 13.877 10.7688 13.6582 10.55C13.4394 10.3312 13.1427 10.2083 12.8333 10.2083ZM12.8333 12.8333H11.3749V11.3749H12.8333V12.8333Z" fill="#707070" />
                                <path d="M2.625 5.10425H1.16667C0.857247 5.10425 0.560501 5.22716 0.341709 5.44596C0.122916 5.66475 0 5.9615 0 6.27091L0 7.72925C0 8.03867 0.122916 8.33541 0.341709 8.55421C0.560501 8.773 0.857247 8.89592 1.16667 8.89592H2.625C2.93442 8.89592 3.23117 8.773 3.44996 8.55421C3.66875 8.33541 3.79167 8.03867 3.79167 7.72925V6.27091C3.79167 5.9615 3.66875 5.66475 3.44996 5.44596C3.23117 5.22716 2.93442 5.10425 2.625 5.10425ZM2.625 7.72925H1.16667V6.27091H2.625V7.72925Z" fill="#707070" />
                                <path d="M12.8333 5.10425H11.3749C11.0655 5.10425 10.7688 5.22716 10.55 5.44596C10.3312 5.66475 10.2083 5.9615 10.2083 6.27091V7.72925C10.2083 8.03867 10.3312 8.33541 10.55 8.55421C10.7688 8.773 11.0655 8.89592 11.3749 8.89592H12.8333C13.1427 8.89592 13.4394 8.773 13.6582 8.55421C13.877 8.33541 13.9999 8.03867 13.9999 7.72925V6.27091C13.9999 5.9615 13.877 5.66475 13.6582 5.44596C13.4394 5.22716 13.1427 5.10425 12.8333 5.10425ZM12.8333 7.72925H11.3749V6.27091H12.8333V7.72925Z" fill="#707070" />
                                <path d="M2.625 0H1.16667C0.857247 0 0.560501 0.122916 0.341709 0.341709C0.122916 0.560501 0 0.857247 0 1.16667L0 2.625C0 2.93442 0.122916 3.23117 0.341709 3.44996C0.560501 3.66875 0.857247 3.79167 1.16667 3.79167H2.625C2.93442 3.79167 3.23117 3.66875 3.44996 3.44996C3.66875 3.23117 3.79167 2.93442 3.79167 2.625V1.16667C3.79167 0.857247 3.66875 0.560501 3.44996 0.341709C3.23117 0.122916 2.93442 0 2.625 0ZM2.625 2.625H1.16667V1.16667H2.625V2.625Z" fill="#707070" />
                                <path d="M7.72925 10.2083H6.27091C5.9615 10.2083 5.66475 10.3312 5.44596 10.55C5.22716 10.7688 5.10425 11.0655 5.10425 11.3749V12.8333C5.10425 13.1427 5.22716 13.4394 5.44596 13.6582C5.66475 13.877 5.9615 13.9999 6.27091 13.9999H7.72925C8.03867 13.9999 8.33541 13.877 8.55421 13.6582C8.773 13.4394 8.89592 13.1427 8.89592 12.8333V11.3749C8.89592 11.0655 8.773 10.7688 8.55421 10.55C8.33541 10.3312 8.03867 10.2083 7.72925 10.2083ZM7.72925 12.8333H6.27091V11.3749H7.72925V12.8333Z" fill="#707070" />
                                <path d="M7.72925 5.10425H6.27091C5.9615 5.10425 5.66475 5.22716 5.44596 5.44596C5.22716 5.66475 5.10425 5.9615 5.10425 6.27091V7.72925C5.10425 8.03867 5.22716 8.33541 5.44596 8.55421C5.66475 8.773 5.9615 8.89592 6.27091 8.89592H7.72925C8.03867 8.89592 8.33541 8.773 8.55421 8.55421C8.773 8.33541 8.89592 8.03867 8.89592 7.72925V6.27091C8.89592 5.9615 8.773 5.66475 8.55421 5.44596C8.33541 5.22716 8.03867 5.10425 7.72925 5.10425ZM7.72925 7.72925H6.27091V6.27091H7.72925V7.72925Z" fill="#707070" />
                                <path d="M7.72925 0H6.27091C5.9615 0 5.66475 0.122916 5.44596 0.341709C5.22716 0.560501 5.10425 0.857247 5.10425 1.16667V2.625C5.10425 2.93442 5.22716 3.23117 5.44596 3.44996C5.66475 3.66875 5.9615 3.79167 6.27091 3.79167H7.72925C8.03867 3.79167 8.33541 3.66875 8.55421 3.44996C8.773 3.23117 8.89592 2.93442 8.89592 2.625V1.16667C8.89592 0.857247 8.773 0.560501 8.55421 0.341709C8.33541 0.122916 8.03867 0 7.72925 0ZM7.72925 2.625H6.27091V1.16667H7.72925V2.625Z" fill="#707070" />
                                <path d="M12.8333 0H11.3749C11.0655 0 10.7688 0.122916 10.55 0.341709C10.3312 0.560501 10.2083 0.857247 10.2083 1.16667V2.625C10.2083 2.93442 10.3312 3.23117 10.55 3.44996C10.7688 3.66875 11.0655 3.79167 11.3749 3.79167H12.8333C13.1427 3.79167 13.4394 3.66875 13.6582 3.44996C13.877 3.23117 13.9999 2.93442 13.9999 2.625V1.16667C13.9999 0.857247 13.877 0.560501 13.6582 0.341709C13.4394 0.122916 13.1427 0 12.8333 0ZM12.8333 2.625H11.3749V1.16667H12.8333V2.625Z" fill="#707070" />
                            </g>
                            <defs>
                                <clipPath id="clip0_650_3324">
                                    <rect width="14" height="14" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>

                        <select className="attendance-input-field date-field" type="text"
                            value={selectedDep}
                            onChange={(e) => setDep(e.target.value)}
                        >
                            <option value=""> All Department</option>
                            {/* {emp_code.sort((a, b) => a.emp_code - b.emp_code).map((i, index) => (<option value={i.emp_code}>{i.emp_code} - {i.name}</option>))}; */}
                            {departmentname.sort((a, b) => a.title - b.title).map((i, index) => (<option value={i.title}>{i.title}</option>))};




                        </select>
                        <hr />

                        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 14 14" fill="none">
                            <g clip-path="url(#clip0_650_3324)">
                                <path d="M2.625 10.2083H1.16667C0.857247 10.2083 0.560501 10.3312 0.341709 10.55C0.122916 10.7688 0 11.0655 0 11.3749L0 12.8333C0 13.1427 0.122916 13.4394 0.341709 13.6582C0.560501 13.877 0.857247 13.9999 1.16667 13.9999H2.625C2.93442 13.9999 3.23117 13.877 3.44996 13.6582C3.66875 13.4394 3.79167 13.1427 3.79167 12.8333V11.3749C3.79167 11.0655 3.66875 10.7688 3.44996 10.55C3.23117 10.3312 2.93442 10.2083 2.625 10.2083ZM2.625 12.8333H1.16667V11.3749H2.625V12.8333Z" fill="#707070" />
                                <path d="M12.8333 10.2083H11.3749C11.0655 10.2083 10.7688 10.3312 10.55 10.55C10.3312 10.7688 10.2083 11.0655 10.2083 11.3749V12.8333C10.2083 13.1427 10.3312 13.4394 10.55 13.6582C10.7688 13.877 11.0655 13.9999 11.3749 13.9999H12.8333C13.1427 13.9999 13.4394 13.877 13.6582 13.6582C13.877 13.4394 13.9999 13.1427 13.9999 12.8333V11.3749C13.9999 11.0655 13.877 10.7688 13.6582 10.55C13.4394 10.3312 13.1427 10.2083 12.8333 10.2083ZM12.8333 12.8333H11.3749V11.3749H12.8333V12.8333Z" fill="#707070" />
                                <path d="M2.625 5.10425H1.16667C0.857247 5.10425 0.560501 5.22716 0.341709 5.44596C0.122916 5.66475 0 5.9615 0 6.27091L0 7.72925C0 8.03867 0.122916 8.33541 0.341709 8.55421C0.560501 8.773 0.857247 8.89592 1.16667 8.89592H2.625C2.93442 8.89592 3.23117 8.773 3.44996 8.55421C3.66875 8.33541 3.79167 8.03867 3.79167 7.72925V6.27091C3.79167 5.9615 3.66875 5.66475 3.44996 5.44596C3.23117 5.22716 2.93442 5.10425 2.625 5.10425ZM2.625 7.72925H1.16667V6.27091H2.625V7.72925Z" fill="#707070" />
                                <path d="M12.8333 5.10425H11.3749C11.0655 5.10425 10.7688 5.22716 10.55 5.44596C10.3312 5.66475 10.2083 5.9615 10.2083 6.27091V7.72925C10.2083 8.03867 10.3312 8.33541 10.55 8.55421C10.7688 8.773 11.0655 8.89592 11.3749 8.89592H12.8333C13.1427 8.89592 13.4394 8.773 13.6582 8.55421C13.877 8.33541 13.9999 8.03867 13.9999 7.72925V6.27091C13.9999 5.9615 13.877 5.66475 13.6582 5.44596C13.4394 5.22716 13.1427 5.10425 12.8333 5.10425ZM12.8333 7.72925H11.3749V6.27091H12.8333V7.72925Z" fill="#707070" />
                                <path d="M2.625 0H1.16667C0.857247 0 0.560501 0.122916 0.341709 0.341709C0.122916 0.560501 0 0.857247 0 1.16667L0 2.625C0 2.93442 0.122916 3.23117 0.341709 3.44996C0.560501 3.66875 0.857247 3.79167 1.16667 3.79167H2.625C2.93442 3.79167 3.23117 3.66875 3.44996 3.44996C3.66875 3.23117 3.79167 2.93442 3.79167 2.625V1.16667C3.79167 0.857247 3.66875 0.560501 3.44996 0.341709C3.23117 0.122916 2.93442 0 2.625 0ZM2.625 2.625H1.16667V1.16667H2.625V2.625Z" fill="#707070" />
                                <path d="M7.72925 10.2083H6.27091C5.9615 10.2083 5.66475 10.3312 5.44596 10.55C5.22716 10.7688 5.10425 11.0655 5.10425 11.3749V12.8333C5.10425 13.1427 5.22716 13.4394 5.44596 13.6582C5.66475 13.877 5.9615 13.9999 6.27091 13.9999H7.72925C8.03867 13.9999 8.33541 13.877 8.55421 13.6582C8.773 13.4394 8.89592 13.1427 8.89592 12.8333V11.3749C8.89592 11.0655 8.773 10.7688 8.55421 10.55C8.33541 10.3312 8.03867 10.2083 7.72925 10.2083ZM7.72925 12.8333H6.27091V11.3749H7.72925V12.8333Z" fill="#707070" />
                                <path d="M7.72925 5.10425H6.27091C5.9615 5.10425 5.66475 5.22716 5.44596 5.44596C5.22716 5.66475 5.10425 5.9615 5.10425 6.27091V7.72925C5.10425 8.03867 5.22716 8.33541 5.44596 8.55421C5.66475 8.773 5.9615 8.89592 6.27091 8.89592H7.72925C8.03867 8.89592 8.33541 8.773 8.55421 8.55421C8.773 8.33541 8.89592 8.03867 8.89592 7.72925V6.27091C8.89592 5.9615 8.773 5.66475 8.55421 5.44596C8.33541 5.22716 8.03867 5.10425 7.72925 5.10425ZM7.72925 7.72925H6.27091V6.27091H7.72925V7.72925Z" fill="#707070" />
                                <path d="M7.72925 0H6.27091C5.9615 0 5.66475 0.122916 5.44596 0.341709C5.22716 0.560501 5.10425 0.857247 5.10425 1.16667V2.625C5.10425 2.93442 5.22716 3.23117 5.44596 3.44996C5.66475 3.66875 5.9615 3.79167 6.27091 3.79167H7.72925C8.03867 3.79167 8.33541 3.66875 8.55421 3.44996C8.773 3.23117 8.89592 2.93442 8.89592 2.625V1.16667C8.89592 0.857247 8.773 0.560501 8.55421 0.341709C8.33541 0.122916 8.03867 0 7.72925 0ZM7.72925 2.625H6.27091V1.16667H7.72925V2.625Z" fill="#707070" />
                                <path d="M12.8333 0H11.3749C11.0655 0 10.7688 0.122916 10.55 0.341709C10.3312 0.560501 10.2083 0.857247 10.2083 1.16667V2.625C10.2083 2.93442 10.3312 3.23117 10.55 3.44996C10.7688 3.66875 11.0655 3.79167 11.3749 3.79167H12.8333C13.1427 3.79167 13.4394 3.66875 13.6582 3.44996C13.877 3.23117 13.9999 2.93442 13.9999 2.625V1.16667C13.9999 0.857247 13.877 0.560501 13.6582 0.341709C13.4394 0.122916 13.1427 0 12.8333 0ZM12.8333 2.625H11.3749V1.16667H12.8333V2.625Z" fill="#707070" />
                            </g>
                            <defs>
                                <clipPath id="clip0_650_3324">
                                    <rect width="14" height="14" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                        <input className="attendance-input-field" placeholder='Designation' type="text" value={selectedDesi}
                            onChange={(e) => setDesi(e.target.value)} />

                    </div>
                    <div className="btn-cont">

                    </div>
                </div>



                <div className="custom-table-background">
                    <div className="table-heading-flex">
                        <div className="repo-heading">Requisition Request</div>
                    </div>
                    <table className="custom-table">
                        <thead>
                            <tr className="custom-table-head-tr">
                                <th className="align-left">S. No.</th>
                                <th className="align-center">Requested On Date</th>
                                <th className="align-center">Department</th>
                                <th className="align-center">Designation</th>
                                <th className="align-center">Status</th>
                                {/* <th className="align-right">Job Post Added</th> */}
                                <th className="align-center">Hired</th>


                            </tr>
                        </thead>
                        <tbody>
                            {filteredList.map((i, index) => (
                                <React.Fragment key={index}>
                                    <tr className="tr-border-bottom">
                                        <td colSpan="6"></td>
                                    </tr>
                                    <tr className="custom-table-head-td">

                                        <td className="align-left">{index + 1}</td>

                                        <td className="align-center">{formatDate(i.date)}</td>
                                        <td className="align-center ">{i.department_name}</td>
                                        <td className="align-center ">{i.position_name}</td>

                                        <td className="align-center">
                                            <span request-status={i.rh_status} className="request-status align-center" >{i.rh_status === "pending" ? "Pending For Rh Approval ⌛" : i.rh_status === "rejected" ? "Rh ✖, " : "Rh ✔, "}</span>
                                            <br />
                                            <span request-status={i.admin_status} className="request-status align-center" >{(i.rh_status === "rejected" && i.admin_status === "rejected") ? "Admin ✖, " : (i.rh_status === "approved" && i.admin_status === "pending") ? "Pending For Admin Approval ⌛," : (i.rh_status === "approved" && i.admin_status === "rejected") ? "Admin ✖, " : (i.rh_status === "approved" && i.admin_status === "approved") ? "Admin ✔, " : "Admin Status: " + i.admin_status}</span>
                                            <br />
                                            <span request-status={i.hr_status} className="request-status align-center" >{(i.rh_status === "rejected" && i.admin_status === "rejected" && i.hr_status === "rejected") ? "HR ✖, " : (i.rh_status === "approved" && i.admin_status === "approved" && i.hr_status === "pending") ? "Pending For HR Approval ⌛" : (i.rh_status === "approved" && i.admin_status === "approved" && i.hr_status === "rejected") ? "HR ✖, " : (i.rh_status === "approved" && i.admin_status === "approved" && i.hr_status === "approved") ? "HR ✔, " : "HR Status: " + i.hr_status}</span>
                                        </td>
                                        <td className="align-center ">

                                            {i.hired ?

                                                i.name_of_hired_person :
                                                <>
                                                    {(i.hr_status === "pending") ?
                                                        <>
                                                            <HiredPeopleModel i={i} getRequisitions={getRequisitions} />
                                                            {/* <RequisitionApprovedHR i={i} getRequisitions={getRequisitions} /> */}
                                                            <RequisitionRejectHR i={i} getRequisitions={getRequisitions} />
                                                        </>
                                                        :
                                                        (i.hr_status === "approved") ?
                                                            <RequisitionRejectHR i={i} getRequisitions={getRequisitions} />
                                                            :
                                                            (i.hr_status === "rejected") ?
                                                                <HiredPeopleModel i={i} getRequisitions={getRequisitions} />
                                                                // <RequisitionApprovedHR i={i} getRequisitions={getRequisitions} /> 
                                                                : ""
                                                    }
                                                </>
                                            }
                                        </td>
                                        {/* <td className="align-left ">
                                            {i.hired ?
                                                i.name_of_hired_person
                                                :
                                                <HiredPeopleModelCheck i={i} getRequisitions={getRequisitions} />
                                            }
                                        </td> */}


                                    </tr>
                                </React.Fragment>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
};



export {
    RequestRequisitionTableRH,
    RequestRequisitionTableAdmin,
    RequestRequisitionTableHR,
};
