import React, { useState, useEffect } from "react";
import axios from "axios";
import { BASE_URL, FRONTEND_URL } from "../../../config/axios"
import Modal from "react-bootstrap/Modal";

const Employeeaddmodel = ({ getOnboarding, departmentname, designationname, rhname, SubCompany, attendpolicy, weeklypolicy, worksite }) => {

    const [show, setShow] = useState(false)

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [formData, setFormData] = useState({

        emp_code: "",
        machine_code: "",
        user_detail: "",
        department: "",
        designation: "",
        is_reporthead: "",
        leavebalance: "0",
        casual_leave: "0",
        sick_leave: "0",
        earn_leave: "0",
        employee_type: "",
        joining_date: "",
        location: "",
        division: "",
        attendance_policy: "",
        weeklyoff_policy: "",
        employee_status: "active",
        role: "",
        url: FRONTEND_URL
    });

    // !  ************** Validation start **************  ! //

    const [errors, setErrors] = useState({});
    const [inputState, setInputState] = useState({});

    const validateForm = () => {
        const newErrors = {};
        const requiredFields = [
            "emp_code",
            "department",
            'machine_code',
            "designation",
            "is_reporthead",
            // "leavebalance",
            // "casual_leave",
            // "sick_leave",
            // "earn_leave",
            "employee_type",
            "joining_date",
            "location",
            "division",
            "attendance_policy",
            "weeklyoff_policy",
            "role"

        ];
        requiredFields.forEach((field) => {
            if (!formData[field]) {
                newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
                    } is required !`;
            }
        });

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setInputState({
            ...inputState,
            [name]: value.trim() ? "green" : "",
        });

        setFormData({
            ...formData,
            [name]: value,
        });
    };


    const symbols = '!@#$%^&*()_-+=';
    const lowercaseLetters = 'abcdefghijklmnopqrstuvwxyz';
    const uppercaseLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const numbers = '0123456789';

    const allChars = symbols + lowercaseLetters + uppercaseLetters + numbers;

    let newPassword = '';
    for (let i = 0; i < 8; i++) {
        const randomIndex = Math.floor(Math.random() * allChars.length);
        newPassword += allChars[randomIndex];
    }



    // ?  ************** Validation End **************  ! //
    const [loading, setLoading] = useState(false); //loading logic


    const handleFormSubmit = async (e) => {
        e.preventDefault();

        if (validateForm()) {
            setLoading(true); //loading logic

            const updatedFormData = {
                ...formData,
                user_detail: formData.email,
                employee: formData.email,
                password: newPassword,

                // role: "Employee",
            };



            try {
                // let resss = await axios.post(`${BASE_URL}/user/register/`, Registeter,)
                // let ress = await axios.post(`${BASE_URL}/userdata/userdata/`, updatedFormData,)
                let res = await axios.post(`${BASE_URL}/wfm/ourcompanyuserdetaildirectadd/`, updatedFormData,)


                if (res.status === 200) {
                    handleClose()
                    await getOnboarding()
                }
            } catch (err) {
                // alert(err);
            } finally {
                setLoading(false); // Ensure loading state is stopped even in case of error //loading logic
            }
        }
    };







    return (
        <>

            <button className="models-button model-add" onClick={handleShow} style={{ margin: "2px" }}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                >
                    <g clip-path="url(#clip0_1415_3177)">
                        <path
                            d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM17 13H13V17H11V13H7V11H11V7H13V11H17V13Z"
                            fill="#2576BC"
                        />
                    </g>
                    <defs>
                        <clipPath id="clip0_1415_3177">
                            <rect width="24" height="24" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
            </button>

            <Modal show={show} onHide={handleClose} dialogClassName="ourcompany-model">
                <Modal.Header closeButton>
                    <Modal.Title>New Onboarding</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/*  ************** timeline Seaction  ************** */}

                    <>


                        <form className="register-form">
                            <div className="form-flex-wrap">

                                <div className="flex-column">

                                    <label htmlFor="name" className="form-labels">
                                        Name<span className="required">*</span>
                                    </label>

                                    <input
                                        type="text"
                                        id="name"
                                        name="name"
                                        placeholder="Candidate name"
                                        onChange={handleInputChange}
                                        value={formData.name}
                                        className={`form-input ${errors.name
                                            ? "error"
                                            : inputState.name
                                                ? "success"
                                                : ""
                                            }`}
                                    />
                                    {errors.name && (
                                        <span className="error-message">{errors.name}</span>
                                    )}
                                </div>

                                <div className="flex-column">
                                    <label htmlFor="email" className="form-labels">
                                        Email<span className="required">*</span>
                                    </label>
                                    <input
                                        type="email"
                                        id="email"
                                        name="email"
                                        placeholder="Demo@gmail.com"
                                        onChange={handleInputChange}
                                        value={formData.email}
                                        onInput={(e) => {
                                            e.target.value = e.target.value.toLowerCase().trim(); // Convert input to lowercase
                                        }}
                                        className={`form-input ${errors.email
                                            ? "error"
                                            : inputState.email
                                                ? "success"
                                                : ""
                                            }`}
                                    />
                                    {errors.email && (
                                        <span className="error-message">{errors.email}</span>
                                    )}
                                </div>



                                <div className="flex-column">
                                    <label htmlFor="emp_code" className="form-labels">
                                        Emp Code<span className="required">*</span>
                                    </label>
                                    <input
                                        type="text"
                                        id="emp_code"
                                        name="emp_code"
                                        placeholder="Emp Code"
                                        onChange={handleInputChange}
                                        value={formData.emp_code}
                                        className={`form-input ${errors.emp_code
                                            ? "error"
                                            : inputState.emp_code
                                                ? "success"
                                                : ""
                                            }`}
                                    />
                                    {errors.emp_code && (
                                        <span className="error-message">{errors.emp_code}</span>
                                    )}
                                </div>

                                <div className="flex-column">
                                    <label htmlFor="machine_code" className="form-labels">
                                        Machine Code<span className="required">*</span>
                                    </label>
                                    <input
                                        type="text"
                                        id="machine_code"
                                        name="machine_code"
                                        placeholder="Machine Code"
                                        onChange={handleInputChange}
                                        value={formData.machine_code}
                                        className={`form-input ${errors.machine_code
                                            ? "error"
                                            : inputState.machine_code
                                                ? "success"
                                                : ""
                                            }`}
                                    />
                                    {errors.machine_code && (
                                        <span className="error-message">{errors.machine_code}</span>
                                    )}
                                </div>


                                <div className="flex-column form-group-selectt">
                                    <label htmlFor="department" className="form-labels">
                                        Department<span className="required">*</span>
                                    </label>
                                    <br />
                                    <select
                                        id="department"
                                        name="department"
                                        value={formData.department}
                                        onChange={handleInputChange}
                                        className={`form-input form-group-selection ${errors.department ? "error" : inputState.department ? "success" : ""
                                            }`}
                                    >
                                        <option value="">Select Department</option>
                                        {departmentname?.sort((a, b) => a.title.localeCompare(b.title, 'en', { ignorePunctuation: true })).map((i, index) => (<>
                                            <option value={i.id}>{i.title}</option>
                                        </>))
                                        }
                                    </select>
                                    <div className="form-group-selection-arrow">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20"
                                            height="25"
                                            fill="currentColor"
                                            class="bi bi-caret-down-fill"
                                            viewBox="0 0 16 16"
                                        >
                                            <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                        </svg>
                                    </div>
                                    <div>
                                        {errors.department && (
                                            <span className="error-message">{errors.department}</span>
                                        )}
                                    </div>
                                </div>

                                <div className="flex-column form-group-selectt">
                                    <label htmlFor="designation" className="form-labels">
                                        Designation<span className="required">*</span>
                                    </label>
                                    <br />
                                    <select
                                        id="designation"
                                        name="designation"
                                        value={formData.designation}
                                        onChange={handleInputChange}
                                        className={`form-input form-group-selection ${errors.designation ? "error" : inputState.designation ? "success" : ""
                                            }`}
                                    >
                                        <option value="">Select Designation</option>
                                        {designationname?.sort((a, b) => a.title.localeCompare(b.title, 'en', { ignorePunctuation: true })).map((i, index) => (<>
                                            <option value={i.id}>{i.title}</option>
                                        </>))
                                        }
                                    </select>
                                    <div className="form-group-selection-arrow">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20"
                                            height="25"
                                            fill="currentColor"
                                            class="bi bi-caret-down-fill"
                                            viewBox="0 0 16 16"
                                        >
                                            <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                        </svg>
                                    </div>
                                    <div>
                                        {errors.designation && (
                                            <span className="error-message">{errors.designation}</span>
                                        )}
                                    </div>
                                </div>

                                <div className="flex-column form-group-selectt">
                                    <label htmlFor="is_reporthead" className="form-labels">
                                        Is Reporting Head<span className="required">*</span>
                                    </label>
                                    <br />
                                    <select
                                        id="is_reporthead"
                                        name="is_reporthead"
                                        value={formData.is_reporthead}
                                        onChange={handleInputChange}
                                        className={`form-input form-group-selection ${errors.is_reporthead ? "error" : inputState.is_reporthead ? "success" : ""
                                            }`}
                                    >
                                        <option value="">Select Option</option>
                                        <option value="True">Yes</option>
                                        <option value="False">No</option>




                                    </select>
                                    <div className="form-group-selection-arrow">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20"
                                            height="25"
                                            fill="currentColor"
                                            class="bi bi-caret-down-fill"
                                            viewBox="0 0 16 16"
                                        >
                                            <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                        </svg>
                                    </div>
                                    <div>
                                        {errors.is_reporthead && (
                                            <span className="error-message">{errors.is_reporthead}</span>
                                        )}
                                    </div>
                                </div>


                                <div className="flex-column form-group-selectt">
                                    <label htmlFor="reporting_head" className="form-labels">
                                        Reporting Head<span className="required">*</span>
                                    </label>
                                    <br />
                                    <select
                                        id="reporting_head"
                                        name="reporting_head"
                                        value={formData.reporting_head}
                                        onChange={handleInputChange}
                                        className={`form-input form-group-selection ${errors.reporting_head ? "error" : inputState.reporting_head ? "success" : ""
                                            }`}
                                    >
                                        <option value="">Select a Reporting Head</option>
                                        {rhname?.sort((a, b) => a.name.localeCompare(b.name, 'en', { ignorePunctuation: true })).map((i, index) => (<>
                                            <option value={i.user_detail}>{i.name}</option>
                                        </>))
                                        }




                                    </select>
                                    <div className="form-group-selection-arrow">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20"
                                            height="25"
                                            fill="currentColor"
                                            class="bi bi-caret-down-fill"
                                            viewBox="0 0 16 16"
                                        >
                                            <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                        </svg>
                                    </div>
                                    <div>
                                        {errors.reporting_head && (
                                            <span className="error-message">{errors.reporting_head}</span>
                                        )}
                                    </div>
                                </div>

                                <div className="flex-column">
                                    <label htmlFor="joining_date" className="form-labels">
                                        Joining Date<span className="required">*</span>
                                    </label>
                                    <input
                                        type="date"
                                        id="joining_date"
                                        name="joining_date"
                                        onChange={handleInputChange}
                                        value={formData.joining_date}
                                        className={`form-input ${errors.joining_date ? "error" : inputState.joining_date ? "success" : ""
                                            }`}
                                    />
                                    {errors.joining_date && <span className="error-message">{errors.joining_date}</span>}
                                </div>



                                <div className="flex-column form-group-selectt">
                                    <label htmlFor="employee_type" className="form-labels">
                                        Employee Role<span className="required">*</span>
                                    </label>
                                    <br />
                                    <select
                                        id="role"
                                        name="role"
                                        value={formData.role}
                                        onChange={handleInputChange}
                                        className={`form-input form-group-selection ${errors.role ? "error" : inputState.role ? "success" : ""
                                            }`}
                                    >
                                        <option value="">Select a Role</option>
                                        <option value="Employee">Employee</option>
                                        <option value="ReportingHead">Team Lead</option>
                                        <option value="HOD">HOD</option>
                                        <option value="HR">HR</option>
                                        <option value="Admin">Admin</option>
                                    </select>
                                    <div className="form-group-selection-arrow">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20"
                                            height="25"
                                            fill="currentColor"
                                            class="bi bi-caret-down-fill"
                                            viewBox="0 0 16 16"
                                        >
                                            <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                        </svg>
                                    </div>
                                    <div>
                                        {errors.role && (
                                            <span className="error-message">{errors.role}</span>
                                        )}
                                    </div>
                                </div>





                                {/* <div className="flex-column">
                                    <label htmlFor="leavebalance" className="form-labels">
                                        Previous Leave Balance<span className="required">*</span>
                                    </label>
                                    <input
                                        type="text"
                                        id="leavebalance"
                                        name="leavebalance"
                                        placeholder="Leave Balance"
                                        onChange={handleInputChange}
                                        value={formData.leavebalance}
                                        className={`form-input ${errors.leavebalance
                                            ? "error"
                                            : inputState.leavebalance
                                                ? "success"
                                                : ""
                                            }`}
                                    />
                                    {errors.leavebalance && (
                                        <span className="error-message">{errors.leavebalance}</span>
                                    )}
                                </div>

                                <div className="flex-column">
                                    <label htmlFor="casual_leave" className="form-labels">
                                        Casual Leave Balance<span className="required">*</span>
                                    </label>
                                    <input
                                        type="text"
                                        id="casual_leave"
                                        name="casual_leave"
                                        placeholder="Casual Leave Balance"
                                        onChange={handleInputChange}
                                        value={formData.casual_leave}
                                        className={`form-input ${errors.casual_leave
                                            ? "error"
                                            : inputState.casual_leave
                                                ? "success"
                                                : ""
                                            }`}
                                    />
                                    {errors.casual_leave && (
                                        <span className="error-message">{errors.casual_leave}</span>
                                    )}
                                </div>
                                <div className="flex-column">
                                    <label htmlFor="sick_leave" className="form-labels">
                                        Sick Leave Balance<span className="required">*</span>
                                    </label>
                                    <input
                                        type="text"
                                        id="sick_leave"
                                        name="sick_leave"
                                        placeholder="Sick Leave Balance"
                                        onChange={handleInputChange}
                                        value={formData.sick_leave}
                                        className={`form-input ${errors.sick_leave
                                            ? "error"
                                            : inputState.sick_leave
                                                ? "success"
                                                : ""
                                            }`}
                                    />
                                    {errors.sick_leave && (
                                        <span className="error-message">{errors.sick_leave}</span>
                                    )}
                                </div> */}

                                {/* <div className="flex-column">
                                    <label htmlFor="joining_date" className="form-labels">
                                        Joining Date<span className="required">*</span>
                                    </label>
                                    <input
                                        type="date"
                                        id="joining_date"
                                        name="joining_date"
                                        placeholder="Joining Date"
                                        onChange={handleInputChange}
                                        value={formData.joining_date}
                                        className={`form-input ${errors.joining_date
                                            ? "error"
                                            : inputState.joining_date
                                                ? "success"
                                                : ""
                                            }`}
                                    />
                                    {errors.joining_date && (
                                        <span className="error-message">{errors.joining_date}</span>
                                    )}
                                </div> */}
                                <div className="flex-column form-group-selectt">
                                    <label htmlFor="division" className="form-labels">
                                        Division<span className="required">*</span>
                                    </label>
                                    <br />
                                    <select
                                        id="division"
                                        name="division"
                                        value={formData.division}
                                        onChange={handleInputChange}
                                        className={`form-input form-group-selection ${errors.division ? "error" : inputState.division ? "success" : ""
                                            }`}
                                    >
                                        <option value="">Select a Division</option>
                                        {SubCompany?.sort((a, b) => a.title.localeCompare(b.title, 'en', { ignorePunctuation: true })).map((i, index) => (<>
                                            <option value={i.id}>{i.title}</option>
                                        </>))
                                        }




                                    </select>
                                    <div className="form-group-selection-arrow">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20"
                                            height="25"
                                            fill="currentColor"
                                            class="bi bi-caret-down-fill"
                                            viewBox="0 0 16 16"
                                        >
                                            <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                        </svg>
                                    </div>
                                    <div>
                                        {errors.division && (
                                            <span className="error-message">{errors.division}</span>
                                        )}
                                    </div>
                                </div>

                                <div className="flex-column form-group-selectt">
                                    <label htmlFor="location" className="form-labels">
                                        Work Site<span className="required">*</span>
                                    </label>
                                    <br />
                                    <select
                                        id="location"
                                        name="location"
                                        value={formData.location}
                                        onChange={handleInputChange}
                                        className={`form-input form-group-selection ${errors.location ? "error" : inputState.location ? "success" : ""
                                            }`}
                                    >
                                        <option value="">Select a location</option>
                                        {worksite?.sort((a, b) => a.title.localeCompare(b.title, 'en', { ignorePunctuation: true })).map((i, index) => (<>
                                            <option value={i.id}>{i.title}</option>
                                        </>))
                                        }




                                    </select>
                                    <div className="form-group-selection-arrow">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20"
                                            height="25"
                                            fill="currentColor"
                                            class="bi bi-caret-down-fill"
                                            viewBox="0 0 16 16"
                                        >
                                            <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                        </svg>
                                    </div>
                                    <div>
                                        {errors.location && (
                                            <span className="error-message">{errors.location}</span>
                                        )}
                                    </div>
                                </div>
                                <div className="flex-column form-group-selectt">
                                    <label htmlFor="attendance_policy" className="form-labels">
                                        Attendance Policy<span className="required">*</span>
                                    </label>
                                    <br />
                                    <select
                                        id="attendance_policy"
                                        name="attendance_policy"
                                        value={formData.attendance_policy}
                                        onChange={handleInputChange}
                                        className={`form-input form-group-selection ${errors.attendance_policy ? "error" : inputState.attendance_policy ? "success" : ""
                                            }`}
                                    >
                                        <option value="">Select a Attendance Policy</option>
                                        {attendpolicy?.sort((a, b) => a.name.localeCompare(b.name, 'en', { ignorePunctuation: true })).map((i, index) => (<>
                                            <option value={i.id}>{i.name}</option>
                                        </>))
                                        }
                                    </select>
                                    <div className="form-group-selection-arrow">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20"
                                            height="25"
                                            fill="currentColor"
                                            class="bi bi-caret-down-fill"
                                            viewBox="0 0 16 16"
                                        >
                                            <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                        </svg>
                                    </div>
                                    <div>
                                        {errors.attendance_policy && (
                                            <span className="error-message">{errors.attendance_policy}</span>
                                        )}
                                    </div>
                                </div>


                                <div className="flex-column form-group-selectt">
                                    <label htmlFor="weeklyoff_policy" className="form-labels">
                                        WeeklyOff Policy<span className="required">*</span>
                                    </label>
                                    <br />
                                    <select
                                        id="weeklyoff_policy"
                                        name="weeklyoff_policy"
                                        value={formData.weeklyoff_policy}
                                        onChange={handleInputChange}
                                        className={`form-input form-group-selection ${errors.weeklyoff_policy ? "error" : inputState.weeklyoff_policy ? "success" : ""
                                            }`}
                                    >
                                        <option value="">Select a Attendance Policy</option>
                                        {weeklypolicy?.sort((a, b) => a.name.localeCompare(b.name, 'en', { ignorePunctuation: true })).map((i, index) => (<>
                                            <option value={i.id}>{i.name}</option>
                                        </>))
                                        }
                                    </select>

                                    <div className="form-group-selection-arrow">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20"
                                            height="25"
                                            fill="currentColor"
                                            class="bi bi-caret-down-fill"
                                            viewBox="0 0 16 16"
                                        >
                                            <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                        </svg>
                                    </div>
                                    <div>
                                        {errors.weeklyoff_policy && (
                                            <span className="error-message">{errors.weeklyoff_policy}</span>
                                        )}
                                    </div>
                                </div>
                                <div className="flex-column form-group-selectt">
                                    <label htmlFor="employee_type" className="form-labels">
                                        Employee Type<span className="required">*</span>
                                    </label>
                                    <br />
                                    <select
                                        id="employee_type"
                                        name="employee_type"
                                        value={formData.employee_type}
                                        onChange={handleInputChange}
                                        className={`form-input form-group-selection ${errors.employee_type ? "error" : inputState.employee_type ? "success" : ""
                                            }`}
                                    >
                                        <option value="">Select a Employee</option>
                                        <option value="permanent">Permanent</option>
                                        <option value="temporary">Temporary</option>
                                        <option value="probation">Probation</option>
                                        <option value="termination">Termination</option>
                                        <option value="resigned">Resigned</option>
                                        <option value="absconding">Absconding</option>
                                        <option value="healthissue">Healthissue</option>
                                        <option value="intern">Intern</option>
                                        <option value="trainee">Trainee</option>











                                    </select>
                                    <div className="form-group-selection-arrow">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20"
                                            height="25"
                                            fill="currentColor"
                                            class="bi bi-caret-down-fill"
                                            viewBox="0 0 16 16"
                                        >
                                            <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                        </svg>
                                    </div>
                                    <div>
                                        {errors.employee_type && (
                                            <span className="error-message">{errors.employee_type}</span>
                                        )}
                                    </div>
                                </div>



                            </div>

                            <div className="button-models">


                                <button onClick={handleFormSubmit} className="model-button model-button-submit" disabled={loading} >{/* //loading logic */}
                                    Submit
                                </button>
                            </div>
                        </form></>

                </Modal.Body>
            </Modal>

        </>
    );
};

export default Employeeaddmodel;
