import axios from 'axios';
import { BASE_URL, FRONTEND_URL } from "../../config/axios";
import React, { useState, useEffect } from 'react';
import { formatDate } from '../Date';



// Main Component
const JobInformation = () => {

    const [alljobopenings, setAlljobopenings] = useState([]);

    useEffect(() => {
        const getAlljobopenings = async () => {
            try {
                const res = await axios.get(`${BASE_URL}/jd/jobpost/`);
                setAlljobopenings(res.data);
            } catch (err) {
                // IMPORTANT: TURN THE BELOW COMMENT OFF!!!
                // alert(err.message);
            }
        };
        getAlljobopenings();
    }, []);



    return (
        <div className='backgroundmain-task'>
            <div className='dashboardcomponent-task'>
                <div className="task-heading">
                    <div className='task-sub-heading'>Job Opening</div>

                    <div className='task-hr' ></div>

                    <div className=" EmployeeDashboad-notification-cards EmployeeDashboad-notification-cards-wrap" >

                        {alljobopenings.map((data, index) => {

                            return (
                                <>
                                    <div className="EmployeeDashboad-notification-card dashcomp-bg-card-style">
                                        <h3 className='dashcomp-announcement-heading'>{data.position_name}</h3>
                                        <span className="dashcomp-announcement-date">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" className='dashcomp-announcement-date-svg'>
                                                <rect x="2.66602" y="3.3335" width="10.6667" height="10.6667" rx="2" stroke="#2576BC" stroke-width="2" />
                                                <path d="M10.666 1.3335L10.666 3.3335C10.666 4.2763 10.666 4.74771 10.3731 5.0406C10.0802 5.33349 9.60882 5.33349 8.66602 5.33349L7.33268 5.33349C6.38987 5.3335 5.91847 5.3335 5.62558 5.0406C5.33268 4.74771 5.33268 4.2763 5.33268 3.33349L5.33268 1.3335" stroke="#2576BC" stroke-width="2" stroke-linecap="round" />
                                                <path d="M6 9.3335L10 9.3335" stroke="black" stroke-width="2" stroke-linecap="round" />
                                            </svg>
                                            {formatDate(data.start_date)} - {formatDate(data.end_date)}
                                        </span>
                                        <br/>
                                        <p className="dashcomp-announcement-p">{data.experience}</p>
                                        <p className="dashcomp-announcement-p">{data.job_type}</p>
                                        <p className="dashcomp-announcement-p">{data.status}</p>
                                        <p className="dashcomp-announcement-p">{data.location}</p>
                                        <div className="dashcomp-announcement-p">{data.description}</div>
                                        <a href={`${FRONTEND_URL}/personaldetails/${data.id}/`} target="_blank" rel="noopener noreferrer">Interview From Link</a>
                                    </div>
                                </>
                            )
                        })
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default JobInformation;