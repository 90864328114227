import React, { useState, useEffect } from "react";
// import HrmsFooter from '../../layout/HrmsFooter';
import Cookies from "js-cookie";
import axios from "axios";
import { BASE_URL } from "../../../config/axios"
import { useNavigate, useLocation, useParams } from "react-router-dom";
import TimelineItem from "../../../components/Timeline";

const Accountdetails = () => {

  let { email } = useParams()
  // const location = useLocation();
  // const { empCode } = location.state;

  const [empCode, setEmpCode] = useState()
  const getCompanyDetails = async () => {
    try {
      const res = await axios.get(`${BASE_URL}/wfm/ourcompanydetailsbyemail/${email}/`);
      // const emp = res.data.find(emp => emp.user_detail === email);
      // const emp = res.data.find(emp => emp.user_detail === email);
      setEmpCode(res.data.emp_code)
    } catch (err) {
      // alert(err.message);
    }
  };
  console.log("empCode");
  console.log(empCode);
  // useEffect(() => {
  //   getCompanyDetails();
  // }, [empCode]);

  const [formData, setFormData] = useState({

    // pan_name: "",
    // father_name_pan: "",
    // pan_dob: "",
    // pan_no: "",
    // bank_name: "",
    // name_require: "",
    // account_number: "",
    // ifsc_code: "",
    // branch_address: "",

    account_number: "",
    ifsc_code: "",
    bank_name: "",
    branch_address: "",
    user: empCode
  });

  // !  ************** Validation start **************  ! //

  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = [
      // "pan_name",
      // "father_name_pan",
      // "pan_dob",
      // "pan_no",
      "bank_name",
      // "name_require",
      "account_number",
      "ifsc_code",
      "branch_address"


    ];
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // ?  ************** Validation End **************  ! //


  const navigate = useNavigate()

  const [loading, setLoading] = useState(false); //loading logic
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      setLoading(true); //loading logic
      // Cookies.set("userData", JSON.stringify(formData));

      const updatedFormData = {
        ...formData,
        user: empCode
        // employee: formData.email,
      };


      try {
        let res = await axios.post(`${BASE_URL}/wfm/userBankDetails/`, updatedFormData)

        if (res.status === 200) {
          navigate(`/documentdetailss/${email}/`)
          // window.location.reload();
        } else {
          alert(res);
        }
      } catch (err) {
        alert(err);
      } finally {
        setLoading(false); //loading logic
      }
    }
  };

  const handleFormPut = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      setLoading(true); //loading logic

      try {
        let res = await axios.put(`${BASE_URL}/wfm/userBankDetails/${empCode}/`, formData,)

        if (res.status === 200) {
          navigate(`/documentdetailss/${email}/`)
          // window.location.reload();

          // navigate("/educationDetails")
        } else {
          alert(res);
        }
      } catch (err) {
        alert(err);
      } finally {
        setLoading(false); //loading logic
      }
    }
  };




  const backroute = () => {
    navigate(`/emergencycontacts/${email}/`)
  }



  const [data, setData] = useState(false)
  // const [companyDetails, setCompanyDetails] = useState([]);
  useEffect(() => {
    // const storedUserData = Cookies.get("userData");

    const getUSerDetails = async () => {
      try {
        const res = await axios.get(`${BASE_URL}/wfm/userBankDetailsgetall/${empCode}/`);
        setFormData(res.data);
        setData(true)
      } catch (err) {
        // alert(err.message);
      }
    };

    getCompanyDetails();
    getUSerDetails();
  }, [empCode]);


  return (
    <div className="jd">
      {/*  ************** timeline Seaction  ************** */}

      <div className="jd-timeline">
        <TimelineItem heading="Personal Information" color="#2576BC" />
        <div className="jd-timeline-borderr timeline-color"></div>
        <TimelineItem heading="Education Detail" color="#2576BC" />
        <div className="jd-timeline-borderr timeline-color"></div>
        <TimelineItem heading="Work Experience" color="#2576BC" />
        <div className="jd-timeline-borderr timeline-color"></div>
        <TimelineItem heading="Emergency Contact" color="#2576BC" />
        <div className="jd-timeline-borderr timeline-color"></div>
        <TimelineItem heading="Account Details" color="#2576BC" />
        <div className="jd-timeline-borderr"></div>
        <TimelineItem heading="Documents Upload" color="#707070" />
      </div>

      <div className="jd-heading-outer">
        <h2 className="jd-heading-main">Account Details*</h2>
        <div>
          <div className="jd-heading-bottom-bold"></div>
          <div className="jd-heading-bottom-light"></div>
        </div>
      </div>

      <form className="register-form">
        <div className="form-flex-wrap">


          {/* <div className="flex-column">
            <label htmlFor="pan-name" className="form-labels">
              Name<span className="required">*</span><span className="label-sub-heading">( As per Pan Card )</span>
            </label>
            <input
              type="text"
              id="pan-name"
              maxLength={30}
              name="pan_name"
              placeholder="Your name"
              onChange={handleInputChange}
              value={formData.pan_name}
              className={`form-input ${errors.pan_name
                ? "error"
                : inputState.pan_name
                  ? "success"
                  : ""
                }`}
            />
            {errors.pan_name && (
              <span className="error-message">{errors.pan_name}</span>
            )}
          </div>

          <div className="flex-column">
            <label htmlFor="pan_dob" className="form-labels">
              Date of Birth<span className="required">*</span><span className="label-sub-heading">( As per Pan Card )</span>
            </label>
            <input
              type="date"
              id="pan_dob"
              name="pan_dob"
              onChange={handleInputChange}
              value={formData.pan_dob}
              className={`form-input ${errors.pan_dob ? "error" : inputState.pan_dob ? "success" : ""
                }`}
            />
            {errors.pan_dob && <span className="error-message">{errors.pan_dob}</span>}
          </div>


          <div className="flex-column">
            <label htmlFor="father_name_pan" className="form-labels">
              Father Name<span className="required">*</span><span className="label-sub-heading">( As per Pan Card )</span>
            </label>
            <input
              type="text"
              id="father_name_pan"
              name="father_name_pan"
              maxLength={30}
              placeholder="Father' name"
              onChange={handleInputChange}
              value={formData.father_name_pan}
              className={`form-input ${errors.father_name_pan
                ? "error"
                : inputState.father_name_pan
                  ? "success"
                  : ""
                }`}
            />
            {errors.father_name_pan && (
              <span className="error-message">{errors.father_name_pan}</span>
            )}
          </div>

          <div className="flex-column">
            <label htmlFor="pan_no" className="form-labels">
              PAN No.<span className="required">*</span>
            </label>
            <input
              type="text"
              id="pan_no"
              name="pan_no"
              maxLength={10}
              placeholder="Demo Demo"
              onChange={handleInputChange}
              value={formData.pan_no}
              className={`form-input ${errors.pan_no
                ? "error"
                : inputState.pan_no
                  ? "success"
                  : ""
                }`}
            />
            {errors.pan_no && (
              <span className="error-message">{errors.pan_no}</span>
            )}
          </div> */}

          <div className="flex-column">
            <label htmlFor="bank_name" className="form-labels">
              Bank Name<span className="required">*</span>
            </label>
            <input
              type="write"
              id="bank_name"
              name="bank_name"
              maxLength={60}
              placeholder="Bank name"
              onChange={handleInputChange}
              value={formData.bank_name}
              className={`form-input ${errors.bank_name ? "error" : inputState.bank_name ? "success" : ""
                }`}
            />
            {errors.bank_name && (
              <span className="error-message">{errors.bank_name}</span>
            )}
          </div>

          <div className="flex-column">
            <label htmlFor="branch_address" className="form-labels">
              Branch address<span className="required">*</span>
            </label>
            <input
              type="write"
              id="branch_address"
              name="branch_address"
              maxLength={100}
              placeholder="Bank Address"
              onChange={handleInputChange}
              value={formData.branch_address}
              className={`form-input ${errors.branch_address ? "error" : inputState.branch_address ? "success" : ""
                }`}
            />
            {errors.branch_address && (
              <span className="error-message">{errors.branch_address}</span>
            )}
          </div>

          {/* <div className="flex-column">
            <label htmlFor="name_require" className="form-labels">
              Account Holder Name <span className="required">*</span>
            </label>
            <input
              type="text"
              id="name_require"
              name="name_require"
              maxLength={30}
              placeholder="Your name"
              onChange={handleInputChange}
              value={formData.name_require}
              className={`form-input ${errors.name_require ? "error" : inputState.name_require ? "success" : ""
                }`}
            />
            {errors.name_require && (
              <span className="error-message">{errors.name_require}</span>
            )}
          </div> */}

          <div className="flex-column">
            <label htmlFor="account_number" className="form-labels">
              Account Number<span className="required">*</span>
            </label>
            <input
              type="number"
              id="account_number"
              name="account_number"
              // maxLength={30}
              placeholder="1234 1234 1234"
              onChange={handleInputChange}
              value={formData.account_number}
              className={`form-input ${errors.account_number ? "error" : inputState.account_number ? "success" : ""
                }`}
            />
            {errors.account_number && (
              <span className="error-message">{errors.account_number}</span>
            )}
          </div>

          <div className="flex-column">
            <label htmlFor="ifsc_code" className="form-labels">
              IFSC Code<span className="required">*</span>
            </label>
            <input
              type="text"
              id="ifsc_code"
              name="ifsc_code"
              placeholder="AAAAA1234A"
              maxLength={15}
              onChange={handleInputChange}
              value={formData.ifsc_code}
              className={`form-input ${errors.ifsc_code ? "error" : inputState.ifsc_code ? "success" : ""
                }`}
            />
            {errors.ifsc_code && (
              <span className="error-message">{errors.ifsc_code}</span>
            )}
          </div>
        </div>


        <div className="button-models">
          <button className="model-button" onClick={backroute}>Back</button>
          {data ? (<button onClick={handleFormPut} className="model-button model-button-submit" disabled={loading}>
            Update
          </button>) : (<button onClick={handleFormSubmit} className="model-button model-button-submit" disabled={loading}>
            Next
          </button>)}
        </div>
      </form>
    </div>
  );
};

export default Accountdetails;
