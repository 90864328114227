import React, { useState } from "react";
import Posting from "./Posting";
import Appsubandtrack from "./Appsubandtrack";
import Onboarding from "./Onboarding";
import Dashboardnavbarcopy from "../../layout/Dashboardnavbarcopy";

const Career = () => {
  const [toggleState, setToggleState] = useState(1);

  const getSelectedTitle = () => {
    switch (toggleState) {
      case 1:
        return 'Job Posting';
      case 2:
        return 'Application Submission and Tracking';
      case 3:
        return 'Onboarding';

      default:
        return 'Job Posting';
    }
  };

  return (
    <>
      <div>
        <div>
          <Dashboardnavbarcopy name={getSelectedTitle()} url="Career" />
          <div className="bloc-tabss  wfm-margin">
            <button
              className={toggleState === 1 ? "dashboardcomponent-tabs dashboardcomponent-tabss dashboardcomponent-active-tabs" : "dashboardcomponent-tabs dashboardcomponent-tabss"}
              onClick={() => setToggleState(1)}
            >
              Job Posting
            </button>
            <button
              className={toggleState === 2 ? "dashboardcomponent-tabs dashboardcomponent-tabss dashboardcomponent-active-tabs" : "dashboardcomponent-tabs dashboardcomponent-tabss"}
              onClick={() => setToggleState(2)}
            >
              Application Submission and Tracking
            </button>
            <button
              className={toggleState === 3 ? "dashboardcomponent-tabs dashboardcomponent-tabss dashboardcomponent-active-tabs" : "dashboardcomponent-tabs dashboardcomponent-tabss"}
              onClick={() => setToggleState(3)}
            >
              Onboarding
            </button>


          </div>
        </div>

        <div className="content-tabs">
          {toggleState === 1 ? <Posting /> : null}

          {toggleState === 2 ? <Appsubandtrack /> : null}

          {toggleState === 3 ? <Onboarding /> : null}
        </div>
      </div>
    </>
  );
};

export default Career;
