// const BASE_URL = "http://127.0.0.1:8000";

//cipl
// const BASE_URL = "https://cipl.aimantra.info";

//g-eng
// const BASE_URL = "https://g-eng.aimantra.info";

//space
const BASE_URL = "https://sc.aimantra.info";

// Lenovo
// const BASE_URL = "https://lr2gx6jv-8000.inc1.devtunnels.ms";

const currentUrl = window.location.href;
const urlParts = currentUrl.split("/");
const FRONTEND_URL = urlParts[0] + `//` + urlParts[2];

const AUTH = {
  headers: {
    Authorization: sessionStorage.getItem("access_token")
      ? "Bearer " + sessionStorage.getItem("access_token")
      : null,
    "Content-Type": "application/json",
    accept: "application/json",
  },
};

const AUTHH = {
  headers: {
    Authorization: sessionStorage.getItem("access_token")
      ? "Bearer " + sessionStorage.getItem("access_token")
      : null,
    "Content-Type": "multipart/form-data",
  },
};

export { BASE_URL, AUTH, AUTHH, FRONTEND_URL };
