import { Outlet } from 'react-router-dom';
import { sidebarData } from './Hr_sidebar';
import DashboardNavbar from "../../layout/Dashboardnavbar"
import DashboardSidebar from "../../layout/DashboardSidebar";
import React, { useState } from 'react'
import UserDetails from '../../components/UserDetails/UserDetails';

import { Modal } from "react-bootstrap";


const HrDashboard = () => {
  const userProfilepic = sessionStorage.getItem('profilepic');
  console.log(userProfilepic);
  console.log(sessionStorage.getItem('profilepic'));
  const isExpanded = sessionStorage.getItem("isExpanded")
  console.log(isExpanded)


  const [isexpand, setExpanded] = useState(false);

  console.log(isexpand)



  const [show, setShow] = useState(false); //Announcement Notify

  const handleClose = () => setShow(false);

  return (
    <>
      {/* {sessionStorage.getItem('profilepic') ? (
        <div className="dashboard-main-section">
        <div className="dashboard-main-sidebar-section">
          <DashboardSidebar sidebarData={sidebarData} />
        </div>
        <div className="dashboard-main-content-section">
          <DashboardNavbar />
          <Outlet/>
      </div>
      </div> 
      ): (
        <Profilemodal />
      ) } */}


      <div className="dashboard-main-section">
        <DashboardSidebar sidebarData={sidebarData} isexpand={isexpand} setExpanded={setExpanded} />
        <div className={isexpand ? "dashboard-main-content-sectionn" : "dashboard-main-content-sectionnn"}>

          <Outlet />
        </div>

        <Modal show={show} onHide={handleClose} dialogClassName="model-annouce">
          <Modal.Header closeButton>

          </Modal.Header>
          <Modal.Body >
            <img src="https://cipl-aimantra.s3.ap-south-1.amazonaws.com/informationboard/Untitled+design+(10).png" alt="demo" className="model-annoucee" />

            {/* <img src="https://cipl-aimantra.s3.ap-south-1.amazonaws.com/employee_profilepics/White+Gold+black+Modern+Minimalist+Best+Employee+Greeting+Instagram+Story+(1200+x+1800+px)+(1800+x+1200+px).png" alt="demo" className="model-annoucee" /> */}
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};


export default HrDashboard
