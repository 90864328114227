import React from 'react';
import '../../../assets/css/Dashboard.css';
import '../../../assets/css/Dashboardcomponent.css';
import Halfarc from '../../../components/dashboard-comp/Halfarc';
import Calaneder from '../../../components/dashboard-comp/Calaneder';
import Dashboardnavbarcopy from '../../../layout/Dashboardnavbarcopy';
import Radicalchart from '../../../components/dashboard-comp/Radicalchart';
import NewEmployee from '../../../components/dashboard-comp/NewEmployee';
import JobInformation from '../../../components/dashboard-comp/JobInformation';
import TaskInformation from '../../../components/dashboard-comp/TaskInformation';
// import {NewJoiningEmployee, JobOpening} from '../../../components/UserDetails/Tables';
import Informationcomponent from '../../../components/dashboard-comp/Informationcomponent';
import TeamAttendanceBars from '../../../components/dashboard-comp/TeamAttendanceBar';
import GradientBarChart from '../../../components/dashboard-comp/GradientBarChart';

const Dashboard = () => {
  // Live time

  const [today, setDate] = React.useState(new Date());
  React.useEffect(() => {
    const timer = setInterval(() => {
      setDate(new Date());
    }, 60 * 1000);
    return () => {
      clearInterval(timer);
    };
  }, []);


  const hour = today.getHours();
  const wish = `Good ${(hour < 12 && "Morning") || (hour < 17 && "Afternoon") || "Evening"
    } `;



  return (
    <div className='dashboard-designs'>
      <Dashboardnavbarcopy url="Dashboard" name={wish} />
      <div className='dashboard-designs-flex'>
        <div>
          <Calaneder />
        </div>
        <div className='dashboard-designs-column'>
          <Halfarc />
          <Radicalchart />
          {/* <TeamAttendanceBars /> */}
        </div>
      </div>

      <div className="dashboard-designs-secondary-flex">
        <div className="dashboard-designs-left-flex">
          <Informationcomponent />
          <NewEmployee />
        </div>
        <div className="dashboard-designs-right-flex">
          <TaskInformation />
          <JobInformation />
        </div>
      </div>

    </div>
  )
}

export default Dashboard

