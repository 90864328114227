import React, { useState } from 'react'
import { Requestoutdutytable, RequestLeavetable, RequestMisspunchtable, RequestImpresttableHR, RequestExpanssetableHR, RequestClockInTableHR, LogCompositorytable } from './Requestoutdutytable';
import { RequestRequisitionTableHR } from '../RequisitionManagement/RequisitionManagementTables';

const Requestmanagment = () => {
    const [toggleState, setToggleState] = useState(1);
    return (
        <>
            <div>
                <div >
                    <div className="bloc-tabs-with-white-bg">
                        <button
                            className={toggleState === 1 ? "dashboardcomponent-tabs  dashboardcomponent-active-tabs" : "dashboardcomponent-tabs white-tab"}
                            onClick={() => setToggleState(1)}
                        >
                            Out Duty
                        </button>
                        <button
                            className={toggleState === 2 ? "dashboardcomponent-tabs dashboardcomponent-active-tabs" : "dashboardcomponent-tabs white-tab"}
                            onClick={() => setToggleState(2)}
                        >
                            Leave
                        </button>
                        {/* <button
                            className={toggleState === 3 ? "dashboardcomponent-tabs dashboardcomponent-active-tabs" : "dashboardcomponent-tabs white-tab"}
                            onClick={() => setToggleState(3)}
                        >
                            Miss Punch
                        </button> */}
                        <button
                            className={toggleState === 4 ? "dashboardcomponent-tabs dashboardcomponent-active-tabs" : "dashboardcomponent-tabs white-tab"}
                            onClick={() => setToggleState(4)}
                        >
                            Compensatory Log
                        </button>
                        {
                            sessionStorage.getItem('emp_code') === '1' ?
                                <button
                                    className={toggleState === 5 ? "dashboardcomponent-tabs dashboardcomponent-active-tabs" : "dashboardcomponent-tabs white-tab"}
                                    onClick={() => setToggleState(5)}
                                >
                                    Imprest
                                </button> : ''}
                        {
                            sessionStorage.getItem('emp_code') === '1' ?
                                <button
                                    className={toggleState === 6 ? "dashboardcomponent-tabs dashboardcomponent-active-tabs" : "dashboardcomponent-tabs white-tab"}
                                    onClick={() => setToggleState(6)}
                                >
                                    Expense
                                </button> : ''}

                        <button
                            className={toggleState === 7 ? "dashboardcomponent-tabs dashboardcomponent-active-tabs" : "dashboardcomponent-tabs white-tab"}
                            onClick={() => setToggleState(7)}
                        >
                            Requisition
                        </button>
                        <button
                            className={toggleState === 8 ? "dashboardcomponent-tabs dashboardcomponent-active-tabs" : "dashboardcomponent-tabs white-tab"}
                            onClick={() => setToggleState(8)}
                        >
                            Clock-In Log
                        </button>
                    </div>
                </div>

                <div className="content-tabs-without-margin">
                    {toggleState === 1 ? (<Requestoutdutytable />) : null}

                    {toggleState === 2 ? <RequestLeavetable /> : null}

                    {toggleState === 3 ? <RequestMisspunchtable /> : null}

                    {toggleState === 4 ? <LogCompositorytable /> : null}

                    {toggleState === 5 ? <RequestImpresttableHR /> : null}

                    {toggleState === 6 ? <RequestExpanssetableHR /> : null}

                    {toggleState === 7 ? <RequestRequisitionTableHR /> : null}

                    {toggleState === 8 ? <RequestClockInTableHR /> : null}



                </div>
            </div>
        </>
    )
}

export default Requestmanagment
