import React, { useEffect, useState } from 'react'
// import '../../assets/css/components.css';
import Searchicon from '../../components/images/search.svg';
import AttendanceChart from './userDetails-comp/AttendanceChart';
import { BASE_URL } from '../../config/axios';
import axios from 'axios';
import { formattedDate, formattedDateLong } from '../Date';

const Attendances = () => {


  // *********** Attendance Api Start ***********

  const today = new Date();
  today.setDate(today.getDate() - 30);

  const year = today.getFullYear();
  const month = today.getMonth() + 1;
  const day = today.getDate();

  const formattedDatee = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
  // const datearrayy = formattedDate.split("-");

  const [allattendance, setAttendance] = useState([])
  const [filteredAttendance, setFilteredAttendance] = useState([])
  const [allattendanceStatus, setAttendanceStatus] = useState({})

  const [startdate, setStartDate] = useState(
    `${formattedDatee}`
  );

  const [enddate, setEndDate] = useState(`${formattedDate}`);
  const [selectedStatus, setSelectedStatus] = useState('');
  const [selectedDayStatus, setSelectedDayStatus] = useState('');
  const [status_list, setStatus_list] = useState([])


  const getAttendanceDetails = async () => {
    try {
      const res = await axios.get(`${BASE_URL}/wfm/attendancefilterbyEmpByMonthstatus/${sessionStorage.getItem("emp_code")}/${startdate}/${enddate}/${selectedStatus}`);
      const ress = await axios.get(`${BASE_URL}/wfm/attendancefilterbyEmpByMonthstatusquick/${sessionStorage.getItem("emp_code")}/${startdate}/${enddate}/`)
      setAttendance(res.data)
      const uniqueDayStatus = [...new Set(res.data.map(entry => entry.day_status))];
      setStatus_list(uniqueDayStatus)
      setAttendanceStatus(ress.data)
    } catch (err) {
      // alert(err.message);
    }
  };
  useEffect(() => {


    getAttendanceDetails();
  }, [selectedStatus, startdate, enddate]);
  // *********** Attendance Api End ***********

  const applyFilters = () => {
    let filteredData = allattendance;
    if (selectedDayStatus !== '') {
      filteredData = filteredData.filter(employee => employee.day_status === selectedDayStatus);
    }
    setFilteredAttendance(filteredData);
  };
  useEffect(() => {
    applyFilters();
  }, [selectedDayStatus, allattendance]);

  return (
    <>
      {/* Attendance Details */}
      <div className="attendance-top-cont">
        <div className="attendance-primary-details">
          <div className="primary-left">
            <div className="ontime-items items">
              <span>On Time</span>
              <span>{allattendanceStatus.ontime}</span>
            </div>
            <div className="late-items items">
              <span>Late</span>
              <span>{allattendanceStatus.late}</span>
            </div>
            <div className="late-items items">
              <span>Halfday</span>
              <span>{allattendanceStatus.halfday}</span>
            </div>
            <div className="outduty-items items">
              <span>Out duty</span>
              <span>{allattendanceStatus.outduty}</span>
            </div>
          </div>
          <div className="secondary-right">

            <div className="present-items items">
              <span>Present</span>
              <span>{allattendanceStatus.present}</span>
            </div>
            <div className="absent-items items">
              <span>Absent</span>
              <span>{allattendanceStatus.absent}</span>
            </div>

            <div className="leave-items items">
              <span>Leave</span>
              <span>{allattendanceStatus.leave}</span>
            </div>
          </div>
        </div>

        {/* Attendance Chart */}
        <div className="primary-left-chart">
          <AttendanceChart allattendanceStatus={allattendanceStatus} />
        </div>
      </div>

      {/* Attendance History Container */}
      <div className="attendance-history-cont">
        <div className="attendance-heading">Attendance History</div>
        <div className="attendance-subcont">
          <div className="field-cont">
            <input className="attendance-input-field date-field" placeholder='From Date' type="date" value={startdate} onChange={(e) => setStartDate(e.target.value)} /><hr />
            <input className="attendance-input-field date-field" placeholder='To Date' type="date" value={enddate} onChange={(e) => setEndDate(e.target.value)} /><hr />
            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 14 14" fill="none">
              <g clip-path="url(#clip0_650_3324)">
                <path d="M2.625 10.2083H1.16667C0.857247 10.2083 0.560501 10.3312 0.341709 10.55C0.122916 10.7688 0 11.0655 0 11.3749L0 12.8333C0 13.1427 0.122916 13.4394 0.341709 13.6582C0.560501 13.877 0.857247 13.9999 1.16667 13.9999H2.625C2.93442 13.9999 3.23117 13.877 3.44996 13.6582C3.66875 13.4394 3.79167 13.1427 3.79167 12.8333V11.3749C3.79167 11.0655 3.66875 10.7688 3.44996 10.55C3.23117 10.3312 2.93442 10.2083 2.625 10.2083ZM2.625 12.8333H1.16667V11.3749H2.625V12.8333Z" fill="#707070" />
                <path d="M12.8333 10.2083H11.3749C11.0655 10.2083 10.7688 10.3312 10.55 10.55C10.3312 10.7688 10.2083 11.0655 10.2083 11.3749V12.8333C10.2083 13.1427 10.3312 13.4394 10.55 13.6582C10.7688 13.877 11.0655 13.9999 11.3749 13.9999H12.8333C13.1427 13.9999 13.4394 13.877 13.6582 13.6582C13.877 13.4394 13.9999 13.1427 13.9999 12.8333V11.3749C13.9999 11.0655 13.877 10.7688 13.6582 10.55C13.4394 10.3312 13.1427 10.2083 12.8333 10.2083ZM12.8333 12.8333H11.3749V11.3749H12.8333V12.8333Z" fill="#707070" />
                <path d="M2.625 5.10425H1.16667C0.857247 5.10425 0.560501 5.22716 0.341709 5.44596C0.122916 5.66475 0 5.9615 0 6.27091L0 7.72925C0 8.03867 0.122916 8.33541 0.341709 8.55421C0.560501 8.773 0.857247 8.89592 1.16667 8.89592H2.625C2.93442 8.89592 3.23117 8.773 3.44996 8.55421C3.66875 8.33541 3.79167 8.03867 3.79167 7.72925V6.27091C3.79167 5.9615 3.66875 5.66475 3.44996 5.44596C3.23117 5.22716 2.93442 5.10425 2.625 5.10425ZM2.625 7.72925H1.16667V6.27091H2.625V7.72925Z" fill="#707070" />
                <path d="M12.8333 5.10425H11.3749C11.0655 5.10425 10.7688 5.22716 10.55 5.44596C10.3312 5.66475 10.2083 5.9615 10.2083 6.27091V7.72925C10.2083 8.03867 10.3312 8.33541 10.55 8.55421C10.7688 8.773 11.0655 8.89592 11.3749 8.89592H12.8333C13.1427 8.89592 13.4394 8.773 13.6582 8.55421C13.877 8.33541 13.9999 8.03867 13.9999 7.72925V6.27091C13.9999 5.9615 13.877 5.66475 13.6582 5.44596C13.4394 5.22716 13.1427 5.10425 12.8333 5.10425ZM12.8333 7.72925H11.3749V6.27091H12.8333V7.72925Z" fill="#707070" />
                <path d="M2.625 0H1.16667C0.857247 0 0.560501 0.122916 0.341709 0.341709C0.122916 0.560501 0 0.857247 0 1.16667L0 2.625C0 2.93442 0.122916 3.23117 0.341709 3.44996C0.560501 3.66875 0.857247 3.79167 1.16667 3.79167H2.625C2.93442 3.79167 3.23117 3.66875 3.44996 3.44996C3.66875 3.23117 3.79167 2.93442 3.79167 2.625V1.16667C3.79167 0.857247 3.66875 0.560501 3.44996 0.341709C3.23117 0.122916 2.93442 0 2.625 0ZM2.625 2.625H1.16667V1.16667H2.625V2.625Z" fill="#707070" />
                <path d="M7.72925 10.2083H6.27091C5.9615 10.2083 5.66475 10.3312 5.44596 10.55C5.22716 10.7688 5.10425 11.0655 5.10425 11.3749V12.8333C5.10425 13.1427 5.22716 13.4394 5.44596 13.6582C5.66475 13.877 5.9615 13.9999 6.27091 13.9999H7.72925C8.03867 13.9999 8.33541 13.877 8.55421 13.6582C8.773 13.4394 8.89592 13.1427 8.89592 12.8333V11.3749C8.89592 11.0655 8.773 10.7688 8.55421 10.55C8.33541 10.3312 8.03867 10.2083 7.72925 10.2083ZM7.72925 12.8333H6.27091V11.3749H7.72925V12.8333Z" fill="#707070" />
                <path d="M7.72925 5.10425H6.27091C5.9615 5.10425 5.66475 5.22716 5.44596 5.44596C5.22716 5.66475 5.10425 5.9615 5.10425 6.27091V7.72925C5.10425 8.03867 5.22716 8.33541 5.44596 8.55421C5.66475 8.773 5.9615 8.89592 6.27091 8.89592H7.72925C8.03867 8.89592 8.33541 8.773 8.55421 8.55421C8.773 8.33541 8.89592 8.03867 8.89592 7.72925V6.27091C8.89592 5.9615 8.773 5.66475 8.55421 5.44596C8.33541 5.22716 8.03867 5.10425 7.72925 5.10425ZM7.72925 7.72925H6.27091V6.27091H7.72925V7.72925Z" fill="#707070" />
                <path d="M7.72925 0H6.27091C5.9615 0 5.66475 0.122916 5.44596 0.341709C5.22716 0.560501 5.10425 0.857247 5.10425 1.16667V2.625C5.10425 2.93442 5.22716 3.23117 5.44596 3.44996C5.66475 3.66875 5.9615 3.79167 6.27091 3.79167H7.72925C8.03867 3.79167 8.33541 3.66875 8.55421 3.44996C8.773 3.23117 8.89592 2.93442 8.89592 2.625V1.16667C8.89592 0.857247 8.773 0.560501 8.55421 0.341709C8.33541 0.122916 8.03867 0 7.72925 0ZM7.72925 2.625H6.27091V1.16667H7.72925V2.625Z" fill="#707070" />
                <path d="M12.8333 0H11.3749C11.0655 0 10.7688 0.122916 10.55 0.341709C10.3312 0.560501 10.2083 0.857247 10.2083 1.16667V2.625C10.2083 2.93442 10.3312 3.23117 10.55 3.44996C10.7688 3.66875 11.0655 3.79167 11.3749 3.79167H12.8333C13.1427 3.79167 13.4394 3.66875 13.6582 3.44996C13.877 3.23117 13.9999 2.93442 13.9999 2.625V1.16667C13.9999 0.857247 13.877 0.560501 13.6582 0.341709C13.4394 0.122916 13.1427 0 12.8333 0ZM12.8333 2.625H11.3749V1.16667H12.8333V2.625Z" fill="#707070" />
              </g>
              <defs>
                <clipPath id="clip0_650_3324">
                  <rect width="14" height="14" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <select className="attendance-input-field date-field" type="text"
              value={selectedDayStatus}
              onChange={(e) => setSelectedDayStatus(e.target.value)}
            >
              <option value="">All Behaviour</option>
              {status_list.map(status => (<option value={status}>{status}</option>))};
            </select>
            <hr />
            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 14 14" fill="none">
              <g clip-path="url(#clip0_650_3324)">
                <path d="M2.625 10.2083H1.16667C0.857247 10.2083 0.560501 10.3312 0.341709 10.55C0.122916 10.7688 0 11.0655 0 11.3749L0 12.8333C0 13.1427 0.122916 13.4394 0.341709 13.6582C0.560501 13.877 0.857247 13.9999 1.16667 13.9999H2.625C2.93442 13.9999 3.23117 13.877 3.44996 13.6582C3.66875 13.4394 3.79167 13.1427 3.79167 12.8333V11.3749C3.79167 11.0655 3.66875 10.7688 3.44996 10.55C3.23117 10.3312 2.93442 10.2083 2.625 10.2083ZM2.625 12.8333H1.16667V11.3749H2.625V12.8333Z" fill="#707070" />
                <path d="M12.8333 10.2083H11.3749C11.0655 10.2083 10.7688 10.3312 10.55 10.55C10.3312 10.7688 10.2083 11.0655 10.2083 11.3749V12.8333C10.2083 13.1427 10.3312 13.4394 10.55 13.6582C10.7688 13.877 11.0655 13.9999 11.3749 13.9999H12.8333C13.1427 13.9999 13.4394 13.877 13.6582 13.6582C13.877 13.4394 13.9999 13.1427 13.9999 12.8333V11.3749C13.9999 11.0655 13.877 10.7688 13.6582 10.55C13.4394 10.3312 13.1427 10.2083 12.8333 10.2083ZM12.8333 12.8333H11.3749V11.3749H12.8333V12.8333Z" fill="#707070" />
                <path d="M2.625 5.10425H1.16667C0.857247 5.10425 0.560501 5.22716 0.341709 5.44596C0.122916 5.66475 0 5.9615 0 6.27091L0 7.72925C0 8.03867 0.122916 8.33541 0.341709 8.55421C0.560501 8.773 0.857247 8.89592 1.16667 8.89592H2.625C2.93442 8.89592 3.23117 8.773 3.44996 8.55421C3.66875 8.33541 3.79167 8.03867 3.79167 7.72925V6.27091C3.79167 5.9615 3.66875 5.66475 3.44996 5.44596C3.23117 5.22716 2.93442 5.10425 2.625 5.10425ZM2.625 7.72925H1.16667V6.27091H2.625V7.72925Z" fill="#707070" />
                <path d="M12.8333 5.10425H11.3749C11.0655 5.10425 10.7688 5.22716 10.55 5.44596C10.3312 5.66475 10.2083 5.9615 10.2083 6.27091V7.72925C10.2083 8.03867 10.3312 8.33541 10.55 8.55421C10.7688 8.773 11.0655 8.89592 11.3749 8.89592H12.8333C13.1427 8.89592 13.4394 8.773 13.6582 8.55421C13.877 8.33541 13.9999 8.03867 13.9999 7.72925V6.27091C13.9999 5.9615 13.877 5.66475 13.6582 5.44596C13.4394 5.22716 13.1427 5.10425 12.8333 5.10425ZM12.8333 7.72925H11.3749V6.27091H12.8333V7.72925Z" fill="#707070" />
                <path d="M2.625 0H1.16667C0.857247 0 0.560501 0.122916 0.341709 0.341709C0.122916 0.560501 0 0.857247 0 1.16667L0 2.625C0 2.93442 0.122916 3.23117 0.341709 3.44996C0.560501 3.66875 0.857247 3.79167 1.16667 3.79167H2.625C2.93442 3.79167 3.23117 3.66875 3.44996 3.44996C3.66875 3.23117 3.79167 2.93442 3.79167 2.625V1.16667C3.79167 0.857247 3.66875 0.560501 3.44996 0.341709C3.23117 0.122916 2.93442 0 2.625 0ZM2.625 2.625H1.16667V1.16667H2.625V2.625Z" fill="#707070" />
                <path d="M7.72925 10.2083H6.27091C5.9615 10.2083 5.66475 10.3312 5.44596 10.55C5.22716 10.7688 5.10425 11.0655 5.10425 11.3749V12.8333C5.10425 13.1427 5.22716 13.4394 5.44596 13.6582C5.66475 13.877 5.9615 13.9999 6.27091 13.9999H7.72925C8.03867 13.9999 8.33541 13.877 8.55421 13.6582C8.773 13.4394 8.89592 13.1427 8.89592 12.8333V11.3749C8.89592 11.0655 8.773 10.7688 8.55421 10.55C8.33541 10.3312 8.03867 10.2083 7.72925 10.2083ZM7.72925 12.8333H6.27091V11.3749H7.72925V12.8333Z" fill="#707070" />
                <path d="M7.72925 5.10425H6.27091C5.9615 5.10425 5.66475 5.22716 5.44596 5.44596C5.22716 5.66475 5.10425 5.9615 5.10425 6.27091V7.72925C5.10425 8.03867 5.22716 8.33541 5.44596 8.55421C5.66475 8.773 5.9615 8.89592 6.27091 8.89592H7.72925C8.03867 8.89592 8.33541 8.773 8.55421 8.55421C8.773 8.33541 8.89592 8.03867 8.89592 7.72925V6.27091C8.89592 5.9615 8.773 5.66475 8.55421 5.44596C8.33541 5.22716 8.03867 5.10425 7.72925 5.10425ZM7.72925 7.72925H6.27091V6.27091H7.72925V7.72925Z" fill="#707070" />
                <path d="M7.72925 0H6.27091C5.9615 0 5.66475 0.122916 5.44596 0.341709C5.22716 0.560501 5.10425 0.857247 5.10425 1.16667V2.625C5.10425 2.93442 5.22716 3.23117 5.44596 3.44996C5.66475 3.66875 5.9615 3.79167 6.27091 3.79167H7.72925C8.03867 3.79167 8.33541 3.66875 8.55421 3.44996C8.773 3.23117 8.89592 2.93442 8.89592 2.625V1.16667C8.89592 0.857247 8.773 0.560501 8.55421 0.341709C8.33541 0.122916 8.03867 0 7.72925 0ZM7.72925 2.625H6.27091V1.16667H7.72925V2.625Z" fill="#707070" />
                <path d="M12.8333 0H11.3749C11.0655 0 10.7688 0.122916 10.55 0.341709C10.3312 0.560501 10.2083 0.857247 10.2083 1.16667V2.625C10.2083 2.93442 10.3312 3.23117 10.55 3.44996C10.7688 3.66875 11.0655 3.79167 11.3749 3.79167H12.8333C13.1427 3.79167 13.4394 3.66875 13.6582 3.44996C13.877 3.23117 13.9999 2.93442 13.9999 2.625V1.16667C13.9999 0.857247 13.877 0.560501 13.6582 0.341709C13.4394 0.122916 13.1427 0 12.8333 0ZM12.8333 2.625H11.3749V1.16667H12.8333V2.625Z" fill="#707070" />
              </g>
              <defs>
                <clipPath id="clip0_650_3324">
                  <rect width="14" height="14" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <select className="attendance-input-field date-field" type="text"
              value={selectedStatus}
              onChange={(e) => setSelectedStatus(e.target.value)}
            >
              <option value="">All Status</option>

              <option value="present/">Present</option>
              <option value="leave/">Leave</option>
              <option value="absent/">Absent</option>
            </select>

          </div>
          <div className="btn-cont">
            {/* <button className='attendance-btn' onClick={getAttendanceDetails}><img src={Searchicon} alt="Search" className='searchicon' />Search</button> */}
          </div>
        </div>
      </div>

      {/* Attendance History Details */}
      <div className='custom-table-background'>
        <div className="table-mainheading">
          <div>Attendance</div>
        </div>
        <table className='custom-table'>
          <thead>
            <tr className='custom-table-head-tr'>
              <th className='align-left'>Sr. No.</th>
              <th className='align-center'>Date</th>
              <th className='align-center'>Punch in</th>
              <th className='align-center'>Punch out</th>
              <th className='align-center'>Total hours</th>
              <th className='align-center'>Behaviour</th>
              <th className='align-right'>Status</th>
            </tr>
          </thead>
          <tbody>
            <tr className="tr-border-bottom">
              <td colSpan={6}></td>
            </tr>
            {filteredAttendance.sort((a, b) => new Date(a.date) - new Date(b.date)).map((item, index) => {
              return (<>
                <tr className='custom-table-head-td' key={index}>
                  <td className="align-left" >{index + 1}</td>
                  <td className="align-center" >{formattedDateLong(item.date)}</td>
                  <td className="align-center" >{item.in_time}</td>
                  <td className="align-center" >{item.out_time}</td>
                  <td className="align-center" >{item.work_duration
                  }</td>

                  <td className="align-center form-text-trasformation-uppercase">{item.day_status}</td>
                  <td data-status={item.attendence_status}
                    className="attendance-calander align-right" >{item.attendence_status}</td>
                </tr>
                <tr className="tr-border-bottom">
                  <td colSpan={6}></td>
                </tr>
              </>);
            })}
          </tbody>
        </table>
      </div>
    </>
  )
}

export default Attendances;
