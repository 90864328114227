// ************ ==== >>>> Page <<<< ==== ************//
import Login from "../pages/website/Login";
import Forgotpassword from "../pages/website/Forgotpassword.jsx";
import Resetpassword from "../pages/website/Resetpassword.jsx";

// ************ ==== >>>> Testing Components <<<< ==== ************//

import Dashboardnavbarcopy from "../layout/Dashboardnavbarcopy.jsx";

import { Tables } from "../components/UserDetails/Tables.jsx";
import Employeattendance from "../components/UserDetails/Employeattendance.jsx";
import Summarysheet from "../components/UserDetails/Summarysheet.jsx";
import Attendancedept from "../components/UserDetails/Attendancedept.jsx";
import Repo from "../components/UserDetails/Repo.jsx";
import Attendances from "../components/UserDetails/Attendances.jsx";
import Outduty from "../components/RequestManagement/Outduty.jsx";
import Leaverequests from "../components/RequestManagement/Leaverequests.jsx";
import Misspunch from "../components/RequestManagement/Misspunch.jsx";
import Compositary from "../components/RequestManagement/Compositary.jsx";
import Leavehistory from "../components/History/Leavehistory.jsx";
import Tatalassets from "../components/History/Tatalassets.jsx";
import Salarylist from "../components/History/Salarylist.jsx";
import Criticalissue from "../components/RequestManagement/Criticalissue.jsx";
import Leave from "../components/UserDetails/Leave.jsx";

import Calander from "../components/dashboard-comp/Calaneder.jsx";
import Informationcomponent from "../components/dashboard-comp/Informationcomponent.jsx";

import CriticalIssues from "../components/CriticalIssues.jsx";

import Accountdashboard from "../pages/Accountdashboard/Accountdashboard.jsx";
// import AccountRequestmangement from "../components/workforcemanagement/Requestmanagment.jsx";
// import Personaldetails from "../components/UserDetails/Personaldetails.jsx";

import Userdetailsmain from "../components/UserDetails/Userdetailsmain.jsx";
import Jobapplicanteducationdetails from "../pages/jobapplicant/Main/Educationdetails.jsx";

import Jdworkexp from "../pages/jobapplicant/Main/Workexprience.jsx";

import Jobdetails from "../components/UserDetails/Jobdetails.jsx";

// ************ ==== >>>> User Deatils Components <<<< ==== ************//
import PersonalDetails from "../pages/jobapplicant/Jobapplicantdashboard.jsx";
import EducationDetails from "../pages/jobapplicant/Main/Educationdetails.jsx";
import Workexprience from "../pages/jobapplicant/Main/Workexprience.jsx";
import Emergencycontact from "../pages/jobapplicant/Main/Emergencycontactdetails.jsx";
import Accountdetails from "../pages/jobapplicant/Main/Accountdetails.jsx";
import Applicantview from "../components/career/update_details/Applicantview.jsx";

// ************ ==== >>>> Components <<<< ==== ************//
import Userdetailscrud from "../components/UserDetails-crud/UserDetails.jsx";

// ************ ==== >>>> Interview Form <<<< ==== ************//
import Personaldetails from "../components/career/update_details/Personaldetails.jsx";
import Positiondetails from "../components/career/update_details/Positiondetails.jsx";
import JdEducationdetails from "../components/career/update_details/Educationdetails.jsx";
import Workexpriencedetails from "../components/career/update_details/Workexprience.jsx";
import Achivementdetails from "../components/career/update_details/Documentdetails.jsx";
import UserDetails from "../components/UserDetails/UserDetails.jsx";
import ApplicantviewRound1 from "../components/career/update_details/ApplicantviewRound1.jsx";
import ApplicantviewRound2 from "../components/career/update_details/ApplicantviewRound2.jsx";
import ApplicantviewRound3 from "../components/career/update_details/ApplicantviewRound3.jsx";
import ApplicantviewRound4 from "../components/career/update_details/ApplicantviewRound4.jsx";
import Workforcemangement from "../components/workforcemanagement/Workforcemangement.jsx";
import TlWorkfm from "../components/workforcemanagement/TlWorkfm.jsx";

// ************ ==== >>>> Job Applicant <<<< ==== ************//
import Jobapplicantdashboardd from "../pages/jobapplicant/Jobapplicantdashboard";
import Jobapplicantdataview from "../pages/jobapplicant/Main/Jobapplicantdataview.jsx";

// ************ ==== >>>> Employee Dashboard <<<< ==== ************//
import EmployeeDashboard from "../pages/employeedashboard/Employeedashboard.jsx";
import EmpDashboard from "../pages/employeedashboard/Main/Dashboard.jsx";

// ************ ==== >>>> Tl Dashboard <<<< ==== ************//
import TlDashboardd from "../pages/teamleaddashboard/TlDashboard.jsx";
import TlDashboard from "../pages/teamleaddashboard/Main/dashboard";

// ************ ==== >>>> Hod Dashboard <<<< ==== ************//
import HodDashboardd from "../pages/hoddashboard/HodDashboard";
import HodDashboard from "../pages/hoddashboard/Main/dashboard";
import HOD from "../components/workforcemanagement/HOD.jsx";

// ************ ==== >>>> Hr Dashboard <<<< ==== ************//
import HrDashboardd from "../pages/hrdashboard/HrDashboard";

import HrDashboard from "../pages/hrdashboard/Main/dashboard";

// ************ ==== >>>> Admin Dashboard <<<< ==== ************//
import AdminDashboardd from "../pages/admindashboard/AdminDashboard";
import AdminDashboard from "../pages/admindashboard/Main/dashboard.jsx";
import Employeemanagement from "../components/RequestManagement/Employeemanagement.jsx";
import Career from "../components/career/Career.jsx";
import Setting from "../components/settings/Settings.jsx";
import HierarchyChart from "../pages/website/Charts.jsx";

// ************ ==== >>>> IT Dashboard <<<< ==== ************//
import ItDashboardMain from "../pages/ITdashboard/IT_Dashboard.jsx";
import ItDashboard from "../pages/ITdashboard/Main/Dashboard.jsx";

import PerformanceManagement from "../components/workforcemanagement/PerformanceManagement.jsx";
import Settingsit from "../components/settings/Settingit.jsx";
import LetterHead from "../components/LetterHead/LetterHead.jsx";
import LetterHeadPr from "../components/LetterHead/LetterHeadPR.jsx";
import LetterHeadPrP from "../components/LetterHead/LetterHeadPRP.jsx";
import Letter from "../components/LetterHead/Letter.jsx";
import AppraisalSheet from "../components/AppraisalManagement/AppraisalSheet.jsx";
import LetterFormat from "../components/LetterHeadadmin/Letter.jsx";
import Documentdetails from "../components/career/update_details/Documentdetails.jsx";
import ExpenseSlipByMonth from "../components/ExpenseSheet/ExpenseSlipByMonth.jsx";
import ExpenseSlipByYear from "../components/ExpenseSheet/ExpenseSlipByYear.jsx";
import AccountRequestmangement from "../components/workforcemanagement/AccountRequestmangement.jsx";
import AppliedPage from "../components/career/update_details/AppliedSuccessPage.jsx";
import PrintableComponent from "../components/PrintPage.jsx";
import Jobapplicantdocuments from "../pages/jobapplicant/Main/DocumentDetails.jsx";
import CompletePage from "../pages/jobapplicant/Main/completePage.jsx";
import SalarySlip from "../components/salryslip/SalarySlip.jsx";
import PayrollManagement from "../components/PayrollManagement/PayrollManagement.jsx";
import RequisitionManagement from "../components/RequisitionManagement/RequisitionManagement.jsx";
import PrintSalarySlipByEmployee from "../components/salryslip/SalarySlipbyEmployee";
import ExpenseSlipByExpense from "../components/ExpenseSheet/ExpenseSlipByExpense.jsx";
import ExpenseSlipByRequest from "../components/ExpenseSheet/ExpenseSlipByRequest.jsx";
import { components } from "react-select";
import Hierarchy from "../pages/website/Letterhead.jsx";
// import ExpenseSlip from "../components/ExpenseSheet/ExpenseSlip.jsx";

export const routingData = [
  {
    role: "",
    isProtected: false,
    defaultRoute: "/",
    routes: [
      {
        path: "",
        component: <Login />,
      },

      {
        path: "login",
        component: <Login />,
      },
      {
        path: "forgotpassword/",
        component: <Forgotpassword />,
      },
      {
        path: "resetpassword/:uidb64/:token/",
        component: <Resetpassword />,
      },

      {
        path: "calander",
        component: <Calander />,
      },
      {
        path: "informationcomponent",
        component: <Informationcomponent />,
      },
      {
        path: "dashboardnavbarcopy",
        component: <Dashboardnavbarcopy />,
      },
      {
        path: "personaldetails/",
        component: <Personaldetails />,
      },
      {
        path: "personaldetails/:id/",
        component: <Personaldetails />,
      },
      {
        path: "personaldetails/:id/:email/",
        component: <Personaldetails />,
      },
      {
        path: "positiondetails/:id/:email/",
        component: <Positiondetails />,
      },
      {
        path: "educationdetail/:id/:email/",
        component: <JdEducationdetails />,
      },

      {
        path: "workexprience/:id/:email/",
        component: <Workexpriencedetails />,
      },
      {
        path: "document/:id/:email/",
        component: <Documentdetails />,
      },
      {
        path: "jobApplied/",
        component: <AppliedPage />,
      },
      {
        path: "processComplete/",
        component: <CompletePage />,
      },

      {
        path: "applicantview/:id/:email/",
        component: <Applicantview />,
      },
      {
        path: "applicantview/round1/:id/:email/",
        component: <ApplicantviewRound1 />,
      },
      {
        path: "applicantview/round2/:id/:email/",
        component: <ApplicantviewRound2 />,
      },
      {
        path: "applicantview/round3/:id/:email/",
        component: <ApplicantviewRound3 />,
      },
      {
        path: "applicantview/round4/:id/:email/",
        component: <ApplicantviewRound4 />,
      },
      {
        path: "letterhead",
        component: <LetterHead />,
      },

      {
        path: "herarchy",
        component: <Hierarchy/>,
      },
      {
        path: "letterheadPR",
        component: <LetterHeadPr />,
      },
      {
        path: "letterheadPRP",
        component: <LetterHeadPrP />,
      },
      {
        path: "letteradmin/:id/:emp/",
        component: <LetterFormat />,
      },
      {
        path: "expenseapprovalbymonth",
        component: <ExpenseSlipByMonth />,
      },

      {
        path: "expenseapprovalbyyear",
        component: <ExpenseSlipByYear />,
      },
      {
        path: "expensePage",
        component: <ExpenseSlipByMonth />,
      },
      {
        path: "printPage",
        component: <PrintableComponent />,
      },
      {
        path: "salarySlip",
        component: <SalarySlip />,
      },
      {
        path: "salarySlip/:email/",
        component: <SalarySlip />,
      },

      {
        path: "salarySlip/:year/:month/:id/",
        component: <SalarySlip />,
      },
      {
        path: "requestExpenseSlip/:id/",
        component: <ExpenseSlipByRequest />,
      },
    ],
  },
  {
    role: "",
    isProtected: false,
    defaultRoute: "jd/jdmain",
    nestedRoutes: [
      {
        routes: [
          {
            path: "",
            component: <Jobapplicantdashboardd />,
          },

          {
            path: "jdmain",
            component: <Jobapplicantdashboardd />,
          },

          {
            path: "jdperonaldetails",
            component: <Jobapplicanteducationdetails />,
          },

          {
            path: "jobdetails",
            component: <Jobdetails />,
          },
          // {
          //   path: "accountdetails",
          //   component: <Accountdetails/>
          // },
          // {
          //   path: "emergencycontact",
          //   component: <Emergencycontactdetails/>
          // },

          {
            path: "userdetails",
            component: <Userdetailsmain />,
          },

          {
            path: "jdview",
            component: <Jobapplicantdataview />,
          },

          {
            path: "jdworkexp",
            component: <Jdworkexp />,
          },

          {
            path: "personalDetailss",
            component: <PersonalDetails />,
          },
          {
            path: "personalDetailss/:email/",
            component: <PersonalDetails />,
          },
          {
            path: "personalDetailss-hr/:email/",
            component: <PersonalDetails />,
          },
          {
            path: "educationDetailss",
            component: <EducationDetails />,
          },
          {
            path: "educationDetailss/:email/",
            component: <EducationDetails />,
          },
          {
            path: "educationDetailss-hr/:email/",
            component: <EducationDetails />,
          },
          {
            path: "workexperiencee/",
            component: <Workexprience />,
          },
          {
            path: "workexperiencee/:email/",
            component: <Workexprience />,
          },
          {
            path: "emergencycontacts",
            component: <Emergencycontact />,
          },
          {
            path: "emergencycontacts/:email/",
            component: <Emergencycontact />,
          },
          {
            path: "accountdetailss",
            component: <Accountdetails />,
          },
          {
            path: "accountdetailss/:email/",
            component: <Accountdetails />,
          },
          {
            path: "documentdetailss",
            component: <Jobapplicantdocuments />,
          },
          {
            path: "documentdetailss/:email/",
            component: <Jobapplicantdocuments />,
          },
          {
            path: "userdetail",
            component: <UserDetails />,
          },

          {
            path: "userdetail/letterhead",
            component: <LetterHead />,
          },
          {
            path: "userdetail/letterheadPR",
            component: <LetterHeadPr />,
          },
          {
            path: "userdetail/letterheadPRP",
            component: <LetterHeadPrP />,
          },
        ],
        // path: "jd",
        // component: <Jobapplicantdashboardd />,
      },
    ],
  },
  {
    role: "Employee",
    isProtected: true,
    defaultRoute: "ed/empDashboard",
    nestedRoutes: [
      {
        routes: [
          {
            path: "",
            component: <EmpDashboard />,
          },
          {
            path: "empDashboard",
            component: <EmpDashboard />,
          },

          {
            path: "criticalIssues",
            component: <CriticalIssues />,
          },

          {
            path: "personalDetails",
            component: <PersonalDetails />,
          },
          {
            path: "educationDetails",
            component: <EducationDetails />,
          },
          {
            path: "workexperience",
            component: <Workexprience />,
          },
          {
            path: "emergencycontact",
            component: <Emergencycontact />,
          },
          {
            path: "accountdetails",
            component: <Accountdetails />,
          },
          {
            path: "userdetail",
            component: <UserDetails />,
          },

          {
            path: "empDashboard/letterhead",
            component: <Letter />,
          },
          {
            path: "userdetail/letterhead",
            component: <Letter />,
          },
          {
            path: "salarySlipEmployee",
            component: <PrintSalarySlipByEmployee />,
          },
          {
            path: "expenseSlip",
            component: <ExpenseSlipByExpense />,
          },
          {
            path: "requestExpenseSlip/:id/",
            component: <ExpenseSlipByRequest />,
          },
        ],
        path: "ed",
        component: <EmployeeDashboard />,
      },
    ],
  },

  {
    role: "Account",
    isProtected: true,
    defaultRoute: "acc/account",
    nestedRoutes: [
      {
        routes: [
          {
            path: "",
            component: <EmpDashboard />,
          },
          {
            path: "account",
            component: <EmpDashboard />,
          },

          {
            path: "criticalIssues",
            component: <CriticalIssues />,
          },

          {
            path: "personalDetails",
            component: <PersonalDetails />,
          },
          {
            path: "educationDetails",
            component: <EducationDetails />,
          },
          {
            path: "workexperience",
            component: <Workexprience />,
          },
          {
            path: "emergencycontact",
            component: <Emergencycontact />,
          },
          {
            path: "accountdetails",
            component: <Accountdetails />,
          },
          {
            path: "userdetail",
            component: <UserDetails />,
          },

          {
            path: "wfm",
            component: <AccountRequestmangement />,
          },

          {
            path: "empDashboard/letterhead",
            component: <Letter />,
          },
          {
            path: "userdetail/letterhead",
            component: <Letter />,
          },

          {
            path: "salarySlipEmployee",
            component: <PrintSalarySlipByEmployee />,
          },
          {
            path: "expenseSlip",
            component: <ExpenseSlipByExpense />,
          },
        ],
        path: "acc",
        component: <Accountdashboard />,
      },
    ],
  },

  {
    role: "IT",
    isProtected: true,
    defaultRoute: "it/itDashboard",
    nestedRoutes: [
      {
        routes: [
          {
            path: "",
            component: <ItDashboard />,
          },
          {
            path: "itDashboard",
            component: <ItDashboard />,
          },
          {
            path: "setting",
            component: <Settingsit />,
          },

          {
            path: "userdetail",
            component: <UserDetails />,
          },
          {
            path: "criticalIssues",
            component: <CriticalIssues />,
          },
          {
            path: "itDashboard/letterhead",
            component: <Letter />,
          },
          {
            path: "userdetail/letterhead",
            component: <Letter />,
          },
          {
            path: "salarySlipEmployee",
            component: <PrintSalarySlipByEmployee />,
          },
          {
            path: "expenseSlip",
            component: <ExpenseSlipByExpense />,
          },
        ],
        path: "it",
        component: <ItDashboardMain />,
      },
    ],
  },

  {
    role: "ReportingHead",
    isProtected: true,
    defaultRoute: "tl/tlDashboard",
    nestedRoutes: [
      {
        routes: [
          {
            path: "",
            component: <TlDashboard />,
          },
          {
            path: "tlDashboard",
            component: <TlDashboard />,
          },
          {
            path: "criticalIssues",
            component: <CriticalIssues />,
          },

          {
            path: "personalDetails",
            component: <PersonalDetails />,
          },
          {
            path: "educationDetails",
            component: <EducationDetails />,
          },
          {
            path: "workexperience",
            component: <Workexprience />,
          },
          {
            path: "emergencycontact",
            component: <Emergencycontact />,
          },
          {
            path: "accountdetails",
            component: <Accountdetails />,
          },
          {
            path: "userdetail",
            component: <UserDetails />,
          },
          {
            path: "wfm",
            component: <TlWorkfm />,
          },

          {
            path: "userdetailscrud/:emp_code/:email/",
            component: <Userdetailscrud />,
          },
          {
            path: "tlDashboard/letterhead",
            component: <Letter />,
          },
          {
            path: "userdetail/letterhead",
            component: <Letter />,
          },

          {
            path: "RequisitionManagement",
            component: <RequisitionManagement />,
          },
          {
            path: "salarySlipEmployee",
            component: <PrintSalarySlipByEmployee />,
          },
          {
            path: "expenseSlip",
            component: <ExpenseSlipByExpense />,
          },
          {
            path: "requestExpenseSlip/:id/",
            component: <ExpenseSlipByRequest />,
          },
        ],
        path: "tl",
        component: <TlDashboardd />,
      },
    ],
  },
  {
    role: "HOD",
    isProtected: true,
    defaultRoute: "hod/hodDashboard",
    nestedRoutes: [
      {
        routes: [
          {
            path: "",
            component: <HodDashboard />,
          },
          {
            path: "hodDashboard",
            component: <HodDashboard />,
          },
          {
            path: "userdetail",
            component: <UserDetails />,
          },
          {
            path: "wfm",
            component: <HOD />,
          },
          {
            path: "criticalIssues",
            component: <CriticalIssues />,
          },
          //
          {
            path: "userdetailscrud/:emp_code/:email/",
            component: <Userdetailscrud />,
          },
          {
            path: "hodDashboard/letterhead",
            component: <Letter />,
          },
          {
            path: "userdetail/letterhead",
            component: <Letter />,
          },

          {
            path: "RequisitionManagement",
            component: <RequisitionManagement />,
          },
          {
            path: "salarySlipEmployee",
            component: <PrintSalarySlipByEmployee />,
          },
          {
            path: "expenseSlip",
            component: <ExpenseSlipByExpense />,
          },

          {
            path: "requestExpenseSlip/:id/",
            component: <ExpenseSlipByRequest />,
          },
        ],
        path: "hod",
        component: <HodDashboardd />,
      },
    ],
  },
  {
    role: "HR",
    isProtected: true,
    defaultRoute: "hr/hrDashboard",
    nestedRoutes: [
      {
        routes: [
          {
            path: "",
            component: <HrDashboard />,
          },
          {
            path: "hrDashboard",
            component: <HrDashboard />,
          },
          {
            path: "criticalIssues",
            component: <CriticalIssues />,
          },
          {
            path: "setting",
            component: <Setting />,
          },

          {
            path: "wfm",
            component: <Workforcemangement />,
          },
          {
            path: "career",
            component: <Career />,
          },
          {
            path: "userdetail",
            component: <UserDetails />,
          },
          {
            path: "performancemanagement",
            component: <PerformanceManagement />,
          },
          {
            path: "payrollmanagement",
            component: <PayrollManagement />,
          },
          {
            path: "userdetailscrud/:emp_code/:email/",
            component: <Userdetailscrud />,
          },

          {
            path: "hrDashboard/letterhead",
            component: <Letter />,
          },
          {
            path: "userdetail/letterhead",
            component: <Letter />,
          },

          {
            path: "AppraisalSheet",
            component: <AppraisalSheet />,
          },

          // {
          //   path: "RequisitionManagement",
          //   component: <RequisitionManagement/>
          // },

          {
            path: "salarySlip/:year/:month/:id/",
            component: <SalarySlip />,
          },
          {
            path: "salarySlip/",
            component: <SalarySlip />,
          },
          {
            path: "salarySlipEmployee",
            component: <PrintSalarySlipByEmployee />,
          },
          {
            path: "expenseSlip",
            component: <ExpenseSlipByExpense />,
          },

          {
            path: "requestExpenseSlip/:id/",
            component: <ExpenseSlipByRequest />,
          },
          // ------------------=------------------------
          // {
          //   path: "personalDetailss",
          //   component: <PersonalDetails />,
          // },
          {
            path: "personalDetailss/:email/",
            component: <PersonalDetails />,
          },
          // {
          //   path: "personalDetailss-hr/:email/",
          //   component: <PersonalDetails />,
          // },
          // {
          //   path: "educationDetailss",
          //   component: <EducationDetails />,
          // },
          {
            path: "educationDetailss/:email/",
            component: <EducationDetails />,
          },
          // {
          //   path: "educationDetailss-hr/:email/",
          //   component: <EducationDetails />,
          // },
          // {
          //   path: "workexperiencee/",
          //   component: <Workexprience />,
          // },
          {
            path: "workexperiencee/:email/",
            component: <Workexprience />,
          },
          // {
          //   path: "emergencycontacts",
          //   component: <Emergencycontact />,
          // },
          {
            path: "emergencycontacts/:email/",
            component: <Emergencycontact />,
          },
          // {
          //   path: "accountdetailss",
          //   component: <Accountdetails />,
          // },
          {
            path: "accountdetailss/:email/",
            component: <Accountdetails />,
          },
          // {
          //   path: "documentdetailss",
          //   component: <Jobapplicantdocuments />,
          // },
          {
            path: "documentdetailss/:email/",
            component: <Jobapplicantdocuments />,
          },
        ],
        path: "hr",
        component: <HrDashboardd />,
      },
    ],
  },

  {
    role: "Admin",
    isProtected: true,
    defaultRoute: "admin/adminDashboard",
    nestedRoutes: [
      {
        routes: [
          {
            path: "",
            component: <AdminDashboard />,
          },
          {
            path: "adminDashboard",
            component: <AdminDashboard />,
          },
        ],
        path: "admin",
        component: <AdminDashboardd />,
      },
    ],
  },
];

// nestedRoutes: {
//   path: "",
//   component: "",
//   routes: [{
//     path: "",
//     component:"",
//   }],
//   nestedRoutes: [{}]
// }
