import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchProducts } from "../productSlice";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.min.css";
import "../assets/css/layout/Modal.css";
import { BASE_URL, AUTH } from "../config/axios";
import axios from "axios";
import { useGeolocated } from "react-geolocated";

const Employee_Navbar = () => {
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.product);
  const [punchOut, setPunchOut] = useState(true);
  const [attendanceid, setAttendanceid] =useState(null)
  const joblocation = `${data.location}`;

  useEffect(() => {
    const getAttendenceData = async () => {
      try {
        const res = await axios.get(
          `${BASE_URL}/attandance/outdutyattendance/${sessionStorage.getItem(
            "employee"
          )}/`
        );
        
        if (res.data.punchout === false) {
          setPunchOut(res.data.punchout);
        }
        setAttendanceid(res.data.id)
      } catch (err) {
        alert(err.message);
      }
    };

    getAttendenceData();
  }, []);

  useEffect(() => {
    dispatch(fetchProducts());
  }, []);


  // const [locationData, setLocationData] = useState(null);
  // const { coords, isGeolocationAvailable, isGeolocationEnabled } = useGeolocated({
  //   positionOptions: {
  //     enableHighAccuracy: true,
  //   },
  //   userDecisionTimeout: 5000,
  // });
  
  // const getLocation = async () => {
  //   try {
  //     const { latitude, longitude } = coords;
  //     const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyBwmmybQWla4yJB38xB3HvzLTQwyZf14JI`;
  //     const response = await fetch(url);
  //     const data = await response.json();
  //     if (data.status === "OK") {
  //       setLocationData(data.results[0].formatted_address);
  //     } else {
        
  //     }
  //   } catch (error) {
      
  //   }
  // };
  
  // useEffect(() => {
  //   if (joblocation === "Remote") {
  //     getLocation();
  //   }
  // }, [coords, isGeolocationAvailable, isGeolocationEnabled, joblocation]);



  const [locationData, setLocationData] = useState(null);
  const [buttonEnabled, setButtonEnabled] = useState(false);
  const NCR_CITIES = ["Delhi", "Noida", "Gurgaon", "Ghaziabad", "Faridabad", 'Gurugram'];
  const { coords, isGeolocationAvailable, isGeolocationEnabled } = useGeolocated({
    positionOptions: {
      enableHighAccuracy: true,
    },
    userDecisionTimeout: 5000,
  });
  
  const isOutsideNCR = (location) => {
    for (let i = 0; i < NCR_CITIES.length; i++) {
      if (location.toLowerCase().includes(NCR_CITIES[i].toLowerCase())) {
        return false;
      }
    }
    return true;
  };
  
  
  const getLocation = async () => {
    try {
      const { latitude, longitude } = coords;
      const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyBwmmybQWla4yJB38xB3HvzLTQwyZf14JI`;
      const response = await fetch(url);
      const data = await response.json();
      if (data.status === "OK") {
        const addressComponents = data.results[0].address_components;
        for (let component of addressComponents) {
          if (component.types.includes('locality')) {
            const city = component.long_name;
            console.log(city)
            setLocationData(city);
            setButtonEnabled(isOutsideNCR(city));
            break;
          }
        }
      }
    } catch (error) {
      console.error("Error in fetching location:", error);
    }
  };
  
  
  useEffect(() => {
      getLocation();
  }, [coords, isGeolocationAvailable, isGeolocationEnabled, joblocation]);
  
// In your render method:
// <button disabled={!buttonEnabled}>Your button</button>


console.log(buttonEnabled)

console.log(locationData)



  // Live time

  const locale = "en";
  const [today, setDate] = React.useState(new Date());
  React.useEffect(() => {
    const timer = setInterval(() => {
      setDate(new Date());
    }, 60 * 1000);
    return () => {
      clearInterval(timer);
    };
  }, []);

  const day = today.toLocaleDateString(locale, { weekday: "long" });
  const date = `${day}, ${today.getDate()} ${today.toLocaleDateString(locale, {
    month: "long",
  })}\n\n`;
  const datee = `${today.getDate()} ${today.toLocaleDateString(locale, {
    month: "long",
  })}\n\n`;
  const hour = today.getHours();
  const wish = `Good ${
    (hour < 12 && "Morning") || (hour < 17 && "Afternoon") || "Evening"
  }`;
  const time = today.toLocaleTimeString(locale, {
    hour: "numeric",
    hour12: true,
    minute: "numeric",
  });

  // Punchin modal

  const Punchin = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth() + 1; 
    const day = today.getDate();
    const formattedDate = `${year}-${month.toString().padStart(2, "0")}-${day
      .toString()
      .padStart(2, "0")}`;



    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => {
      if (!(locationData)) {

        return alert("Cann't find location");
      }
      setShow(true);
    };
    const [remarks, setRemarks] = useState("")
    const handleSubmit = async () => {
      try {
        const res = await axios.post(
          `${BASE_URL}/attandance/outdutypunchin/`,
          {
            date: formattedDate,
            employee: sessionStorage.getItem("employee"),
            in_time: "09:30:00",
            punchin_placename: locationData,
            punchout: "false",
            remarks: remarks,
          },
        );
        if (res.status === 200) {
          alert("Punchin Successfully")
          window.location.reload()
          setShow(false)
        }
      } catch (err) {
        alert(err.message);
      }
    };

    return (
      <>
        <Button className="modal-button" onClick={handleShow}>
          <h3>Punch In</h3>
        </Button>

        <Modal show={show} onHide={handleClose} dialogClassName="my-modal">
          <Modal.Header closeButton>
            <Modal.Title>Punch In</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <>
              <div className="flex-row" style={{ alinItems: "center" }}>
                <div className="punchIn-svg">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="30"
                    fill="currentColor"
                    class="bi bi-box-arrow-right"
                    viewBox="0 0 16 16"
                    style={{ color: "#6DD370", backgroundColor: "#F2F2F2" }}
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0v2z"
                    />
                    <path
                      fill-rule="evenodd"
                      d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z"
                    />
                  </svg>
                </div>
                <div className="punchin-date-time">
                  <p>Punch In date & Time:</p>
                  <p>
                    Today ({time}), {datee}
                  </p>
                </div>
              </div>
              <div className="punchin-note">
                <p>Punch In Note : </p>
                <textarea
                  className="punchin-textarea"
                  value={remarks.text}
                  name=""
                  id=""
                  rows="3"
                  placeholder="Type Location name and reason ..." onChange={(e) => setRemarks(e.target.value)} require
                ></textarea>
              </div>
            </>
          </Modal.Body>
          <Modal.Footer>
            <Button className="modal-cancel-button" onClick={handleClose}>
              Cancel
            </Button>
            <Button className="modal-punchin-button" onClick={handleSubmit}>
              Punch IN
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  };

  // Punchout modal
  const PunchOut = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth() + 1; // Months are zero-indexed, so add 1
    const day = today.getDate();

    const formattedDate = `${year}-${month.toString().padStart(2, "0")}-${day
      .toString()
      .padStart(2, "0")}`;
    const formattedTime = today.toLocaleTimeString([], {hour12: false, hour: '2-digit', minute: '2-digit', second: '2-digit' });
  


    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);

    const handleShow = () => {
      if (!(locationData)) {
        return alert("Cann't find location");
      }
      setShow(true);
    };



    const handleSubmit = async () => {
      try {
        const res = await axios.put(`${BASE_URL}/attandance/outdutypunchout/${attendanceid}/`, {
          employee: sessionStorage.getItem("employee"),
          out_time: formattedTime,
          punchout: true,
          punchout_placename: locationData,
          tl_assigned: sessionStorage.getItem('reportinghead'),
   
        }
        );
        if (res.status === 200) {
          alert("PunchOut Successfully")
          window.location.reload()
          setShow(false)
        }
      } catch (err) {
        alert(err.message);
      }
    };

    return (
      <>
        <Button className="modal-button" onClick={handleShow}>
          <h3>Punch Out</h3>
        </Button>

        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>
              <h2>Punch Out</h2>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <>
              <div className="flex-row" style={{ alinItems: "center" }}>
                <div className="punchIn-svg">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="30"
                    fill="currentColor"
                    class="bi bi-box-arrow-right"
                    viewBox="0 0 16 16"
                    style={{ color: "#6DD370", backgroundColor: "#F2F2F2" }}
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0v2z"
                    />
                    <path
                      fill-rule="evenodd"
                      d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z"
                    />
                  </svg>
                </div>

                <div className="punchin-date-time">
                  <h3>Punch Out date & Time:</h3>
                  <p>
                    Today ({time}), {datee}
                  </p>
                </div>
              </div>
              {/* <div className="lat-lng">
                  Location : {locationData}
                
              </div> */}

              
            </>
          </Modal.Body>
          <Modal.Footer>
            <Button className="modal-cancel-button" onClick={handleClose}>
              Cancel
            </Button>
            <Button className="modal-punchin-button" onClick={handleSubmit}>
              Punch Out
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  };

  return (
    <>
      <div className="dashbord-navbar">
        <div className="dashboard-navbar-left-section">
          <h1>
            {wish}, {data.name}
          </h1>
          <p>
            {date}
            {time}


            {buttonEnabled}
          </p>
        </div>

        <div
          className="dashboard-navbar-right-section"
        >


          {(buttonEnabled  || locationData === null )? (
             <div>{punchOut ? <Punchin />  : <PunchOut />}</div>
          ) : ""}


          <button
            className="dashbord-navbar-button"
            onClick={() => {
              sessionStorage.clear();
              window.location = "https://hrms.civilmantra.com/";
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="35"
              height="35"
              fill="currentColor"
              className="bi bi-box-arrow-right"
              viewBox="0 0 16 16"
            >
              <path
                fillRule="evenodd"
                d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0v2z"
              />
              <path
                fillRule="evenodd"
                d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z"
              />
            </svg>
          </button>
        </div>
      </div>
    </>
  );
};

export default Employee_Navbar;
