import React, { useState, useEffect } from 'react'

import Dashboardnavbarcopy from '../../layout/Dashboardnavbarcopy';
import Orgstructure from './Orgstructure'
import Attendance from './Attendance'
import EmployeeDetails from './EmployeeDetails'
import Requestmanagment from './Requestmanagment'
import TLRequestmanagment from './tlwfm/Requestmanagement'
import { RequestRequisitionTableAdmin } from '../RequisitionManagement/RequisitionManagementTables';
// import { RequestRequisitionTableAdmin } from './Requestoutdutytable';


const Workforcemanagement = () => {


  const [toggleState, setToggleState] = useState(1);


  const getSelectedTitle = () => {
    switch (toggleState) {
      case 1:
        return 'Organization Structure';
      case 2:
        return 'Employee Self-Service';
      case 3:
        return 'Attendance';
      case 4:
        return 'Request management';
      case 5:
        return 'Request management';

      default:
        return 'Organization Structure';
    }
  };

  return (
    <div>
      <Dashboardnavbarcopy name={getSelectedTitle()} url="Workforce Managment" />
      <div className="bloc-tabss2  wfm-margin">
        <button
          className={toggleState === 1 ? "dashboardcomponent-tabs  dashboardcomponent-active-tabs" : "dashboardcomponent-tabs "}
          onClick={() => setToggleState(1)}
        >
          Organization Structure
        </button>
        <button
          className={toggleState === 2 ? "dashboardcomponent-tabs  dashboardcomponent-active-tabs" : "dashboardcomponent-tabs "}
          onClick={() => setToggleState(2)}
        >
          Employee Self-Service
        </button>
        <button
          className={toggleState === 3 ? "dashboardcomponent-tabs  dashboardcomponent-active-tabs" : "dashboardcomponent-tabs "}
          onClick={() => setToggleState(3)}
        >
          Attendance
        </button>
        <button
          className={toggleState === 4 ? "dashboardcomponent-tabs  dashboardcomponent-active-tabs" : "dashboardcomponent-tabs "}
          onClick={() => setToggleState(4)}
        >
          HR Request Management
        </button>
        <button
          className={toggleState === 5 ? "dashboardcomponent-tabs  dashboardcomponent-active-tabs" : "dashboardcomponent-tabs "}
          onClick={() => setToggleState(5)}
        >
          Team Request Management
        </button>
        {
          sessionStorage.getItem('emp_code') === '1' ?
            <button
              className={toggleState === 6 ? "dashboardcomponent-tabs  dashboardcomponent-active-tabs" : "dashboardcomponent-tabs "}
              onClick={() => setToggleState(6)}
            >
              Requisition Management
            </button> : ''}

      </div>
      <div className="content-tabs">
        {toggleState === 1 ? (
          <Orgstructure />
        ) : null}

        {toggleState === 2 ? <EmployeeDetails /> : null}

        {toggleState === 3 ? <Attendance /> : null}

        {toggleState === 4 ? <Requestmanagment /> : null}
        {toggleState === 5 ? <TLRequestmanagment /> : null}
        {toggleState === 6 ? <RequestRequisitionTableAdmin /> : null}





      </div>
    </div>
  )
}

export default Workforcemanagement
