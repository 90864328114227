import React from 'react';

const TimelineItem = ({ heading, color }) => (
  <div className="jd-timeline-svg-heading">
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
      >
        <circle
          cx="20"
          cy="20"
          r="19.5"
          transform="rotate(90 20 20)"
          fill="#F8F8F8"
          stroke={color}
        />
        <circle
          cx="20"
          cy="20"
          r="9.5"
          transform="rotate(90 20 20)"
          fill={color}
          stroke={color}
        />
        {color === '#2576BC' && (
          <path
            d="M25 16L17.3077 23.01L15 20.9091"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        )}
        {color === '#F7BA1E' && (
          <path
            // d="M25 16L17.3077 23.01L15 20.9091"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        )}
      </svg>
    </div>
    <span className={`jd-timeline-heading ${color === '#2576BC' ? ' timeline-color' : ''}`}>
      {heading}
    </span>
  </div>
);


export default TimelineItem

