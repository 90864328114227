import { useState, useEffect } from "react";
import Docs from "../images/docs.svg";
import { DocumentAdd } from "../settings/model/Impreset";
import { BASE_URL } from "../../config/axios";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import "../../assets/css/components.css";

// const identityDocuments = [
//   {
//     name: "Aadhar Card",
//     upload: "06/12/23",
//     url: "",
//   },
//   {
//     name: "Driving License",
//     upload: "06/12/23",
//     url: "",
//   },
//   {
//     name: "Voter ID Card",
//     upload: "06/12/23",
//     url: "",
//   },
//   {
//     name: "Passport",
//     upload: "06/12/23",
//     url: "",
//   },
// ];


const Document = () => {
  const navigate = useNavigate();

  const [identityDocuments, setidentityDocuments] = useState([])


  const getDocuments = async () => {
    try {
      const res = await axios.get(`${BASE_URL}/userdata/userdocsfilter/${sessionStorage.getItem('email')}/`);
      setidentityDocuments(res.data)
    } catch (err) {
      // alert(err.message);
    }
  };


  useEffect(() => {
    getDocuments();
  }, []);

  const [toggleState, setToggleState] = useState(1);

  const downloadDocument = (url) => {
    // Implement download logic using provided URL
    // window.open(url, "_blank");

  };


  const filteredIdentityDocuments = identityDocuments.filter(
    (doc) => doc.document_type === "identity"
  );

  const filteredEmployeeDocuments = identityDocuments.filter(
    (doc) => doc.document_type === "employeeLetter"
  );

  const filteredPreExpDocuments = identityDocuments.filter(
    (doc) => doc.document_type === "previousExperience"
  );

  const filteredDegreeDocuments = identityDocuments.filter(
    (doc) => doc.document_type === "degree"
  );


  const currentUrl = window.location.href;
  const urlParts = currentUrl.split('/');


  const url1 = `letterhead`
  // const url2 = `letterheadPR`
  // const url3 = `letterheadPRP`

  console.log(url1)

  const handleLetterClick = () => {
    navigate(`letterhead/`)
    window.location.reload()
  };

  return (
    <div>
      <div className="expense-bloc-tabs">
        <div className="left-expense-bloc-tab">
          <button
            className={
              toggleState === 1
                ? "dashboardcomponent-tabs dashboardcomponent-active-tabs"
                : "dashboardcomponent-tabs white-tab"
            }
            onClick={() => setToggleState(1)}
          >
            Identity ({filteredIdentityDocuments.length})
          </button>
          <button
            className={
              toggleState === 2
                ? "dashboardcomponent-tabs dashboardcomponent-active-tabs"
                : "dashboardcomponent-tabs white-tab"
            }
            onClick={() => setToggleState(2)}
          >
            Employee Letters ({(sessionStorage.getItem("letter_type") != "None") ? filteredEmployeeDocuments.length + 1 : filteredEmployeeDocuments.length})
          </button>
          <button
            className={
              toggleState === 3
                ? "dashboardcomponent-tabs dashboardcomponent-active-tabs"
                : "dashboardcomponent-tabs white-tab"
            }
            onClick={() => setToggleState(3)}
          >
            Previous Experience ({filteredPreExpDocuments.length})
          </button>
          <button
            className={
              toggleState === 4
                ? "dashboardcomponent-tabs dashboardcomponent-active-tabs"
                : "dashboardcomponent-tabs white-tab"
            }
            onClick={() => setToggleState(4)}
          >
            Degree & Certificates ({filteredDegreeDocuments.length})
          </button>
        </div>
        <div className="right-expense-bloc-tab">

        </div>
        {/* <div><DocumentAdd /></div> */}
      </div>





      <div >
        {toggleState === 1 ? (
          <div className="document-content-tab">
            {filteredIdentityDocuments.length === 0 ? (
              <div className="no-documents-message">
                No documents available.
              </div>
            ) : (
              <div className="document-grid2">
                {filteredIdentityDocuments.map((doc, index) => (
                  <div className="document-item" key={index}>
                    <div className="document-icons">
                      <div className="document-name-div">
                        <img
                          src={Docs}
                          alt="Document Icon"
                          className="document-icon"
                        />
                        <div>
                          <div className="document-name">{doc.document_name}</div>
                          <div className="document-upload-date">
                            Doc Type <b>{doc.document_type}</b>
                            {/* Uploaded on {doc.upload} */}
                          </div>
                        </div>
                      </div>
                      <div>
                        <a href={doc.document_file} target="_blank" className="document-download-button">
                          View
                        </a>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        ) : null}



        {toggleState === 2 ? (
          <div className="document-content-tab">
            {filteredEmployeeDocuments.length === 0 && sessionStorage.getItem("letter_type") === "None" ? (
              <div className="no-documents-message">
                No documents available.
              </div>
            ) : (
              <div className="document-grid2">
                {(sessionStorage.getItem("letter_type") != "None") ? (
                  <>
                    {/* <div className="document-content-tab"> Increment Letter
                      <button
                        className="document-download-button"
                        onClick={() => navigate(url1)}
                      >
                        View
                      </button>
                    </div> */}
                    <div className="document-item">
                      <div className="document-icons">
                        <div className="document-name-div">
                          <img
                            src={Docs}
                            alt="Document Icon"
                            className="document-icon"
                          />
                          <div>
                            <div className="document-name">Increment Letter</div>
                            <div className="document-upload-date">
                              {/* Doc Type <b>Employee Letter</b> */}
                              {/* Uploaded on {doc.upload} */}
                            </div>
                          </div>
                        </div>
                        <button
                          className="document-download-button"
                          onClick={() => navigate(url1)}
                        >
                          View
                        </button>
                      </div>
                    </div>
                  </>
                ) : ""}
                {filteredEmployeeDocuments.map((doc, index) => (
                  <div className="document-item" key={index}>
                    <div className="document-icons">
                      <div className="document-name-div">
                        <img
                          src={Docs}
                          alt="Document Icon"
                          className="document-icon"
                        />
                        <div>
                          <div className="document-name">{doc.document_name}</div>
                          <div className="document-upload-date">
                            Doc Type <b>{doc.document_type}</b>
                            {/* Uploaded on {doc.upload} */}
                          </div>
                        </div>
                      </div>
                      <div>
                        <a href={doc.document_file} target="_blank" className="document-download-button">
                          View
                        </a>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        ) : null}
        {toggleState === 3 ? (
          <div className="document-content-tab">
            {filteredPreExpDocuments.length === 0 ? (
              <div className="no-documents-message">
                No documents available.
              </div>
            ) : (
              <div className="document-grid2">
                {filteredPreExpDocuments.map((doc, index) => (
                  <div className="document-item" key={index}>
                    <div className="document-icons">
                      <div className="document-name-div">
                        <img
                          src={Docs}
                          alt="Document Icon"
                          className="document-icon"
                        />
                        <div>
                          <div className="document-name">{doc.document_name}</div>
                          <div className="document-upload-date">
                            Doc Type <b>{doc.document_type}</b>
                            {/* Uploaded on {doc.upload} */}
                          </div>
                        </div>
                      </div>
                      <div>
                        <a href={doc.document_file} target="_blank" className="document-download-button">
                          View
                        </a>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        ) : null}
        {toggleState === 4 ? (
          <div className="document-content-tab">
            {filteredDegreeDocuments.length === 0 ? (
              <div className="no-documents-message">
                No documents available.
              </div>
            ) : (
              <div className="document-grid2">
                {filteredDegreeDocuments.map((doc, index) => (
                  <div className="document-item" key={index}>
                    <div className="document-icons">
                      <div className="document-name-div">
                        <img
                          src={Docs}
                          alt="Document Icon"
                          className="document-icon"
                        />
                        <div>
                          <div className="document-name">{doc.document_name}</div>
                          <div className="document-upload-date">
                            Doc Type <b>{doc.document_type}</b>
                            {/* Uploaded on {doc.upload} */}
                          </div>
                        </div>
                      </div>
                      <div>
                        <a href={doc.document_file} target="_blank" className="document-download-button">
                          View
                        </a>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Document;