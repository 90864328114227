import React from 'react';
import { PieChart, Tooltip, Pie, ResponsiveContainer } from 'recharts';

import "../../../assets/css/components.css";



const LeaveChart = ({ allattendanceStatus }) => {

  const data = [
    {
      name: "Absent",
      uv: allattendanceStatus.absent,
      fill: "#AC98C7",
    },
    {
      name: "On Time",
      uv: allattendanceStatus.ontime,
      fill: "#2576BC",
    },
    {
      name: "Late",
      uv: allattendanceStatus.late,
      fill: "#30C9C9",
    },
    {
      name: "Present",
      uv: allattendanceStatus.present,
      fill: "#18A118",
    },
    {
      name: "Half Day",
      uv: allattendanceStatus.halfday,
      fill: "#FF747C",
    },
    {
      name: "Leave",
      uv: allattendanceStatus.leave,
      fill: "#FF0000",
    },
    {
      name: "Out Duty",
      uv: allattendanceStatus.outduty,
      fill: "#F7BA1E",
    },
  ];

  return (
    <ResponsiveContainer className="piechart" height={290} width="90%">
      <div className="attendancechart-heading">
        <div className="repo-heading-a">Attendance</div>
        <div>
          {data.map((item) => {
            return (
              <div key={item.name} className="piechart-legend">
                <div
                  className="piechart-dot"
                  style={{ backgroundColor: item.fill }}
                ></div>
                <div className="piechart-subheading" style={{ color: item.fill }}>
                  {item.name} - {item.uv}
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <PieChart>
        <Pie
          data={data}
          // label={renderCustomizedLabel}
          outerRadius={140}
          innerRadius={90}
          dataKey="uv"
        ></Pie>
        {/* <Tooltip /> */}
      </PieChart>
    </ResponsiveContainer>
  );
};

export default LeaveChart;