import React, { useRef, useCallback, useState, useEffect } from 'react';
import axios from "axios";
import { BASE_URL } from "../../../config/axios";
import { useNavigate } from "react-router-dom";
import { useGeolocated } from "react-geolocated";
import { Input } from "antd";
import { formattedDate } from "../../Date";
import Webcam from 'react-webcam';

const Clockin = ({ handleClose }) => {
  const navigate = useNavigate();
  const webcamRef = useRef(null);

  const [locationData, setLocationData] = useState(null);
  const [imageSrc, setImageSrc] = useState(null);
  const { coords, isGeolocationAvailable, isGeolocationEnabled } = useGeolocated({
    positionOptions: {
      enableHighAccuracy: true,
    },
    userDecisionTimeout: 5000,
  });

  console.log(locationData);

  const getLocation = async () => {
    try {
      const { latitude, longitude } = coords;
      const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyBwmmybQWla4yJB38xB3HvzLTQwyZf14JI`;
      const response = await fetch(url);
      const data = await response.json();
      console.log(data);
      if (data.status === "OK") {
        setLocationData(data.results[0].formatted_address);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const today = new Date();
  const formattedDate = today.toISOString().split('T')[0];
  const formattedTime = today.toTimeString().split(' ')[0];

  const [formData, setFormData] = useState({
    employee: sessionStorage.getItem("emp_code"),
    date: formattedDate,
    punch_time: formattedTime,
    placename: locationData,
  });

  useEffect(() => {
    getLocation();
  }, [coords, isGeolocationAvailable, isGeolocationEnabled]);

  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      placename: locationData,
    }));
  }, [locationData]);

  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImageSrc(imageSrc);
    return imageSrc;
  }, [webcamRef]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const imageSrc = capture(); // Capture the image when submit is clicked

    const updatedFormValue = {
      ...formData,
      employee: sessionStorage.getItem("emp_code"),
      date: formattedDate,
      punch_time: formattedTime,
      placename: locationData,
    };

    const formDataToSend = new FormData();
    for (const key in updatedFormValue) {
      formDataToSend.append(key, updatedFormValue[key]);
    }
    // if (imageSrc) {
    //   const blob = await fetch(imageSrc).then((res) => res.blob());
    //   formDataToSend.append('image', blob, 'clockin_photo.jpg');
    // }

    if (imageSrc) {
      const blob = await fetch(imageSrc).then((res) => res.blob());
      const empCode = sessionStorage.getItem("emp_code");
      const filename = `${formattedDate}_${formattedTime}_${empCode}.jpg`;
      formDataToSend.append('image', blob, filename);
    }

    try {
      let res = await axios.post(`${BASE_URL}/wfm/clockinlog/`, formDataToSend, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      if (res.status === 200) {
        console.log(res);
        handleClose();
      }
    } catch (err) {
      alert(err);
    }
  };

  return (
    <div className="clockin-div">
      <form onSubmit={handleSubmit} className="register-form">
        <div className="flex-column">
          <label htmlFor="employee" className="form-labels">
            Employee
          </label>
          <input
            id="employee"
            type="text"
            name="employee"
            readOnly
            value={sessionStorage.getItem("emp_code")}
            className="form-input form-input-background"
          />
        </div>
        <div className="flex-column">
          <label htmlFor="date" className="form-labels">
            Date
          </label>
          <input
            id="date"
            type="date"
            name="date"
            readOnly
            value={formattedDate}
            className="form-input form-input-background"
          />
        </div>
        <div className="flex-column">
          <label htmlFor="punch_time" className="form-labels">
            Time
          </label>
          <input
            type="time"
            id="punch_time"
            name="punch_time"
            readOnly
            value={formattedTime}
            className="form-input form-input-background"
          />
        </div>
        <div className="flex-column">
          <label htmlFor="placename" className="form-labels">
            Location
          </label>
          <input
            id="placename"
            name="placename"
            readOnly
            value={locationData}
            className="form-input form-input-background"
          />
        </div>
        <div className="flex-column" style={{justifyContent:"center", alignItems:"center"}}>
          <Webcam
            className='Clockin-Webcam'
            audio={false}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            width={400}
            height={300}
          />

        </div>
        <div className="button-models">
          <button type="button" className="model-button" onClick={handleClose}>Close</button>
          {formattedTime && locationData ?
            <button type="submit" className="model-button model-button-submit">
              Submit
            </button> : "Loading..."
          }
        </div>
      </form>
    </div>
  );
};


export default Clockin;
