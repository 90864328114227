// import React from 'react'
import ReactToPrint from 'react-to-print';
import Logo from './frame-10000027081.png'
import { BASE_URL, FRONTEND_URL } from "../../config/axios";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import axios from "axios";
import Signature from './Picture 1.png'
import { formattedDate, formattedDateLong } from '../Date';
import numberToWords from 'number-to-words';
import { spaceChildren } from 'antd/es/button';

const ExpenseSlip = React.forwardRef((props, ref) => {

  const location = useLocation();
  const { i } = location.state;
  const [expenseData, setExpenseData] = useState([])
  const [personalData, setPersonalData] = useState({})
  const [imprestData, setImprestData] = useState({})
  const [personData, setPersonData] = useState([])


  const totalExpense = parseFloat(i.fooding) + parseFloat(i.transportation) + parseFloat(i.accomodation) + parseFloat(i.other);
  const totalExpenseWords = numberToWords.toWords(totalExpense);

  const [sum, setSum] = useState(0)
  const [dates, setDates] = useState(0)


  const [foodingTotal, setFoodingTotal] = useState(0);
  const [transportationTotal, setTransportationTotal] = useState(0);
  const [accomodationTotal, setAccomodationTotal] = useState(0);
  const [otherTotal, setOtherTotal] = useState(0);


  const date = formattedDate

  const getLetterData = async () => {
    try {
      const res = await axios.get(`${BASE_URL}/wfm/expensedetailsbyexpense/${i.id}`);
      const summ = res.data.reduce((acc, item) => acc + parseFloat(item.item_amount), 0);
      setExpenseData(res.data)
      setSum(summ)
      setDates(res.data.map(item => item.date).sort((a, b) => new Date(a) - new Date(b)));

      const ress = await axios.get(`${BASE_URL}/wfm/ourcompanydetailsbyemp/${i.empcode}/`)
      setPersonalData(ress.data)

      const resss = await axios.get(`${BASE_URL}/wfm/impresetbyid/${i.imprest}/`)
      setImprestData(resss.data)

      // setPersonData(resss.data.multiple_person_details.map(person => person.name).join(", "));
      setPersonData(resss.data.multiple_person_details.map(person => person.name));

      res.data.forEach(item => {
        const amount = parseFloat(item.item_amount);
        switch (item.expense_type) {
          case 'fooding':
            setFoodingTotal(prevTotal => prevTotal + amount);
            break;
          case 'transportation':
            setTransportationTotal(prevTotal => prevTotal + amount);
            break;
          case 'accomodation':
            setAccomodationTotal(prevTotal => prevTotal + amount);
            break;
          case 'other':
            setOtherTotal(prevTotal => prevTotal + amount);
            break;
          default:
            break;
        }
      });

    } catch (err) {
    }
  };

  // console.log(ExpenseData)


  useEffect(() => {
    getLetterData();

  }, []);
  console.log("items");
  console.log(i);

  // const dojdate = personalData?.joining_date

  // const doj_date = dojdate?.split("-")



  return (
    <div className='' ref={ref}>
      <div className='salaryslip'>
        <div className='salaryslip-header'>
          <div className='salaryslip-header-left'>
            <img src="https://cipl-aimantra.s3.ap-south-1.amazonaws.com/Logos/Cipl+Logo++(1).png" alt='logo' className='salaryslip-logo' />

            {/* <img src={Logo} alt='logo' className='salaryslip-logo' /> */}
            <div className='salaryslip-header-left-text'>
              Expense Slip For : {i.name}
            </div>
          </div>
          <div className='salaryslip-header-right'>
            <div className='salaryslip-header-right-top'>
              Civilmantra infracon private limited
            </div>
            <div className='salaryslip-header-right-bottom'>
              3rd Floor, Tower 3A,<br /> DLF Corporate Greens, Sector 74A,<br /> Gurugram, Haryana 122004 <br /> services@civilmantra.com
            </div>
          </div>
        </div>
        <div className='salaryslip-hr'></div>
        <div className='salaryslip-header-second'>
          <div className='salaryslip-header-second-section1'>
            <div className='salaryslip-heading-black'>Project Name</div>
            <div className='salaryslip-heading-black'>Employee Code</div>
            {/* <div className='salaryslip-heading-black'>Client</div> */}
            <div className='salaryslip-heading-black'>DEPARTMENT</div>


          </div>
          <div className='salaryslip-header-second-section2'>
            <div className='salaryslip-heading-black1'>{i.project_name}</div>
            <div className='salaryslip-heading-black1'>{i.empcode}</div>
            {/* <div className='salaryslip-heading-black1'>{"-"}</div> */}
            <div className='salaryslip-heading-black1'>{personalData.department_name}</div>
          </div>
          <div className='expense-header-second-section3'>
          </div>
          <div className='salaryslip-header-second-section4'>
            <div className='salaryslip-heading-black'>Name</div>
            {/* <div className='salaryslip-heading-black'>Date</div> */}
            <div className='salaryslip-heading-black'>Start Date</div>
            <div className='salaryslip-heading-black'>End Date</div>
          </div>
          <div className='salaryslip-header-second-section5'>
            <div className='salaryslip-heading-black1'>{personalData.name}</div>
            <div className='salaryslip-heading-black1'>{formattedDateLong(dates[0])}</div>
            <div className='salaryslip-heading-black1'>{formattedDateLong(dates[dates.length - 1])}</div>
            {/* <div className='salaryslip-heading-black1'>{"-"}</div> */}
          </div>
        </div>
        {/* <div className='salaryslip-hr'></div> */}
        {/* <div className='salaryslip-header-third'>
          <div className='salaryslip-header-third-section1'>
            <div className='salaryslip-heading-blue'>TOTAL DAYS</div>
            <div className='salaryslip-heading-blue'>TOTAL LEAVES</div>
            <div className='salaryslip-heading-blue'>TOTAL ABSENT</div>
            <div className='salaryslip-heading-blue'>WORKING DAYS</div>
          </div>
          <div className='salaryslip-header-third-section2'>
            <div className='salaryslip-heading-blueblack'>30.00</div>
            <div className='salaryslip-heading-blueblack'>30.00</div>
            <div className='salaryslip-heading-blueblack'>30.00</div>
            <div className='salaryslip-heading-blueblack'>30.00</div>

          </div>
          <div className='salaryslip-header-third-section3'>
            <div className='salaryslip-heading-blue'>WEEKLY OFF</div>
            <div className='salaryslip-heading-blue'>LEAVE WITHOUT PAY</div>
            <div className='salaryslip-heading-blue'>HOLIDAY</div>
            <div className='salaryslip-heading-blue'>NET PAID DAYS</div>

          </div>
          <div className='salaryslip-header-third-section4'>
            <div className='salaryslip-heading-blueblack'>30.00</div>
            <div className='salaryslip-heading-blueblack'>30.00</div>
            <div className='salaryslip-heading-blueblack'>30.00</div>
            <div className='salaryslip-heading-blueblack'>30.00</div>
          </div>
        </div> */}
        <div className='salaryslip-body'>
          <tr>
            <th className='salaryslip-heading-blue'>Imprest Asked : </th>
            {/* <th>Imprest Asked : </th> */}
            {/* <td colSpan={5} style={{ paddingLeft: "30px" }} className='form-text-trasformation-uppercase'>{totalExpenseWords}</td> */}
          </tr>

          <table className='salary-table'>
            <thead>
              <tr className='expenseslip-head'>
                {/* <th style={{ padding: "0px 5px" }}>Imprest name</th> */}
                <th style={{ padding: "0px 5px" }} className='expense-table-td'>Fooding</th>
                <th style={{ padding: "0px 5px" }} className='expense-table-td'>Traveling</th>
                <th style={{ padding: "0px 5px" }} className='expense-table-td'>Accomodation</th>
                <th style={{ padding: "0px 5px" }} className='expense-table-td'>Others</th>
                <th style={{ padding: "0px 5px" }} className='expense-table-td'>Grand Total</th>
                {/* <th style={{ width: "80px", padding: "0px 5px" }}>AMOUNT</th> */}


              </tr>
            </thead>
            <tbody>
              {/* {ExpenseData.map((i, index) => ( */}
              <React.Fragment>
                <tr>
                  {/* <td className='salaryslip-heading-blue'>{i.name}</td> */}
                  <td className='expense-table-td'>{i.fooding}</td>
                  <td className='expense-table-td'>{i.transportation}</td>
                  <td className='expense-table-td'>{i.accomodation}</td>
                  <td className='expense-table-td'>{i.other}</td>
                  {/* <td className='expense-table-td'>{(i.fooding + i.transportation + i.accomodation + i.other)}</td> */}
                  <td className='expense-table-td '>{totalExpense}</td>

                </tr>
                {/* <tr>
                      <td colSpan={6} className='salaryslip-hrr'></td>
                    </tr> */}
                {/* <tr>
                  <th >TOTAL EARNINGS</th>
                  <td className='salary-table-td'>30000</td>
                  <td className='salary-table-td'>3000</td>
                  <th>TOTAL DEDUCTIONS</th>
                  <td className='salary-table-td'> 88</td>
                  <td className='salary-table-td'> 88</td>
                  
                </tr> */}
              </React.Fragment>
              {/* ))} */}
              {/* <br /> */}

              <tr>
                <td colSpan={65} className='salaryslip-hrr'></td>
              </tr>
              {/* <tr>
                <td >Total</td>
                <td className='expense-table-td'>30000</td>
                <td className='expense-table-td'>28000</td>
                <td className='expense-table-td'>28000</td>
                <td className='expense-table-td'>200</td>

              </tr> */}
              {/* <td></td> */}


            </tbody>
          </table>
          {/* <br /> */}
          <tr style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>
              <th>Grand Total</th>
              <td colSpan={5} style={{ paddingLeft: "30px" }} className='form-text-trasformation-uppercase'>{totalExpense}</td>
            </div>
            <div>
              <th>IN WORDS</th>
              <td colSpan={5} style={{ paddingLeft: "30px" }} className='form-text-trasformation-uppercase'>{totalExpenseWords}</td>
            </div>
          </tr>
          <br />
          <table className='salary-table'>
            <thead>
              <tr className='expenseslip-head'>
                <th style={{ padding: "0px 5px" }} >Employee name</th>
                <th style={{ padding: "0px 5px" }} className='expense-table-td'>Fooding</th>
                <th style={{ padding: "0px 5px" }} className='expense-table-td'>Traveling</th>
                <th style={{ padding: "0px 5px" }} className='expense-table-td'>Accomodation</th>
                <th style={{ padding: "0px 5px" }} className='expense-table-td'>Others</th>
                <th style={{ padding: "0px 5px" }} className='expense-table-td'>Grand Total</th>
                {/* <th style={{ width: "80px", padding: "0px 5px" }}>AMOUNT</th> */}


              </tr>
            </thead>
            <tbody>
              {/* {ExpenseData.map((i, index) => ( */}
              <React.Fragment>
                <tr>
                  {/* <td className='salaryslip-heading-blue' style={{width:'200px'}}>{personData}</td> */}
                  <td className='salaryslip-heading-blue' style={{ width: '200px' }}>
                    {personData.map((name, index) => (
                      <React.Fragment key={index}>
                        {name},
                        {index !== personData.length - 1 && <br />}
                      </React.Fragment>
                    ))}
                  </td>
                  <td className='expense-table-td'>{foodingTotal}</td>
                  <td className='expense-table-td'>{transportationTotal}</td>
                  <td className='expense-table-td'>{accomodationTotal}</td>
                  <td className='expense-table-td'>{otherTotal}</td>
                  {/* <td className='expense-table-td'>{(i.fooding + i.transportation + i.accomodation + i.other)}</td> */}
                  <td className='expense-table-td '>{sum}</td>

                </tr>
                {/* <tr>
                      <td colSpan={6} className='salaryslip-hrr'></td>
                    </tr> */}
                {/* <tr>
                  <th >TOTAL EARNINGS</th>
                  <td className='salary-table-td'>30000</td>
                  <td className='salary-table-td'>3000</td>
                  <th>TOTAL DEDUCTIONS</th>
                  <td className='salary-table-td'> 88</td>
                  <td className='salary-table-td'> 88</td>
                  
                </tr> */}
              </React.Fragment>
              {/* ))} */}
              <br />

              <tr>
                <td colSpan={65} className='salaryslip-hrr'></td>
              </tr>
              {/* <tr>
                <td >Total</td>
                <td className='expense-table-td'>30000</td>
                <td className='expense-table-td'>28000</td>
                <td className='expense-table-td'>28000</td>
                <td className='expense-table-td'>200</td>

              </tr> */}
              {/* <td></td> */}
              {/* <tr>
                <th>GRAND TOTAL IN WORDS</th>
                <td colSpan={5} style={{ paddingLeft: "30px" }} className='form-text-trasformation-uppercase'>{totalExpenseWords}</td>
              </tr> */}
              {/* <br></br> */}

            </tbody>
          </table>
          <table className='salary-table'>

            <thead style={{ margin: "20px 0px" }}>
              <tr className='expenseslip-head' >
                <th style={{ padding: "0px 5px" }} className='expense-table-td'>Sr No.</th>
                <th style={{ padding: "0px 5px" }} className='expense-table-td'>Date</th>
                <th style={{ padding: "0px 5px" }} className='expense-table-td'>Name</th>
                <th style={{ padding: "0px 5px" }} className='expense-table-td'>Amount</th>
                <th style={{ padding: "0px 5px" }} className='expense-table-td'>Expense Type</th>
                <th style={{ padding: "0px 5px" }} className='expense-table-td'>Bills</th>
                {/* <th style={{ padding: "0px 5px" }}>Associate</th> */}
                {/* <th style={{ padding: "0px 5px" }}>Description</th> */}


              </tr>
            </thead>

            <tbody>

              {expenseData
                .sort((a, b) => Date(a.date) - Date(b.date))
                .map((j, index) => (
                  <React.Fragment key={index}>
                    <tr className="tr-border-bottom">
                      <td colSpan="8"></td>
                    </tr>
                    <tr>
                      <td className='expense-table-td'>{index + 1}</td>
                      <td className='expense-table-td'>{formattedDateLong(j.date)}</td>
                      <td className='expense-table-td'>{j.item_name}</td>
                      <td className='expense-table-td'>{j.item_amount}</td>
                      <td className='expense-table-td'>{j.expense_type}</td>
                      <td className='expense-table-td'>
                        {/* {j.bill} */}
                        {j.bill ? <a
                          href={j.bill}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="25"
                            height="25"
                            viewBox="0 0 16.933 16.933"
                            id="bill">
                            {/* <circle cx="" cy="" r="15" fill="#F6F7F9" /> */}
                            {/* <circle cx="8.5" cy="8.5" r="8.5" fill="#000" /> */}

                            <path
                              // style="line-height:normal;font-variant-ligatures:normal;font-variant-position:normal;font-variant-caps:normal;font-variant-numeric:normal;font-variant-alternates:normal;font-feature-settings:normal;text-indent:0;text-align:start;text-decoration-line:none;text-decoration-style:solid;text-decoration-color:#000;text-transform:none;text-orientation:mixed;shape-padding:0;isolation:auto;mix-blend-mode:normal"
                              d="M1.854 0C.819 0 0 .83 0 1.852c-.002 4.94.002 9.878.002 14.816 0 .221.256.345.429.207l1.488-1.19 1.488 1.19a.265.265 0 0 0 .33 0l1.488-1.19 1.49 1.19a.265.265 0 0 0 .33 0l1.489-1.19 1.488 1.19a.265.265 0 0 0 .33 0l1.487-1.19 1.488 1.19c.174.139.43.015.43-.207l.002-8.733h2.91a.26.26 0 0 0 .263-.263V1.854A1.85 1.85 0 0 0 15.083 0Zm0 .53h11.932c-.35.337-.556.8-.556 1.324l-.002 14.265-1.222-.98a.265.265 0 0 0-.33 0l-1.49 1.191-1.488-1.191a.264.264 0 0 0-.33 0L6.878 16.33 5.391 15.14a.265.265 0 0 0-.33 0L3.575 16.33 2.085 15.14a.264.264 0 0 0-.33 0l-1.224.98L.53 1.852c0-.77.634-1.322 1.324-1.322zm13.228 0c.73 0 1.324.586 1.324 1.324v5.554H13.76V1.854c0-.738.592-1.324 1.322-1.324zM3.707 3.439c-.133 0-.265.089-.265.266v.287c-.599.116-1.058.63-1.058 1.252 0 .42.217.672.48.799.264.127.552.147.816.174.265.027.506.06.639.125.133.064.181.15.181.32 0 .406-.34.743-.782.746-.384-.014-.658-.23-.784-.623-.106-.339-.614-.175-.503.162.152.475.48.844 1.011.96v.294c0 .354.53.354.53 0v-.282c.598-.116 1.058-.635 1.058-1.257 0-.388-.18-.654-.48-.799-.264-.126-.552-.146-.817-.173-.264-.028-.502-.056-.639-.126-.122-.062-.181-.142-.181-.32 0-.41.34-.742.783-.745.421.016.666.26.783.622.107.339.615.175.504-.162-.152-.474-.5-.857-1.012-.959v-.295c0-.177-.132-.266-.264-.266zm2.645.265c-.355 0-.355.527 0 .53h4.763c.353 0 .353-.53 0-.53zm0 1.852c-.355 0-.355.53 0 .53h4.763c.352 0 .353-.53 0-.53zm0 1.852c-.355 0-.355.527 0 .527h4.763c.352 0 .352-.527 0-.527zM2.649 9.26c-.356 0-.356.537 0 .529h8.466c.352 0 .352-.53 0-.53zm0 1.852c-.356 0-.356.53 0 .53h8.466c.352 0 .352-.53 0-.53zm0 1.852c-.356 0-.356.529 0 .53h8.466c.352 0 .352-.53 0-.53z"
                              fill="#2576BC"
                            // font-family="sans-serif"
                            // font-weight="400"
                            // overflow="visible"
                            >
                            </path>
                          </svg>
                        </a>
                          : " No bill"}
                      </td>
                      {/* <td className='expense-table-td'>{j.item_name}</td>
                    <td className='expense-table-td'>{j.item_name}</td> */}
                    </tr>
                  </React.Fragment>
                ))}
              {/* <br /> */}

              <tr>
                <td colSpan={8} className='salaryslip-hrr'></td>
              </tr>






            </tbody>

            <tr>
            </tr>


          </table>

          <tr>
            <th >TOTAL </th>
            <td className='expense-table-td'>{sum}</td>
          </tr>
          <tr>
            <th className='salaryslip-heading-blue'>IN WORDS</th>
            <td colSpan={5} style={{ paddingLeft: "30px" }} className='form-text-trasformation-uppercase'>{numberToWords.toWords(sum)}</td>
          </tr>

        </div>
        <div className='salaryslip-hr'></div>
        {/* <div className='salaryslip-bottom'>
          <img src={Signature} alt='signature' className='slaryslip-bg-signature' />
          <th>AUTHORISED SIGNATURE</th>
        </div> */}
        <h6 style={{ fontSize: '10px' }}><b>*This is Software generated Expense Slip no signature required*</b></h6>
      </div>
      <div className='slaryslip-bg'>


      </div>
    </div>
  )
});

const ExpenseSlipByExpense = () => {
  const ref = useRef();
  const navigate = useNavigate()
  const currentUrl = window.location.href;
  const urlParts = currentUrl.split('/');
  const backUrl = "/" + urlParts[3] + `/payrollmanagement`;

  const backroute = () => {
    navigate(backUrl)

  }
  return (

    <div>
      {/* <button className="model-button" onClick={backroute}>Back</button> */}
      <ReactToPrint
        bodyClass="print-agreement"
        content={() => ref.current}
        trigger={() => (
          <button>
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 20 18" fill="none">
              <path d="M17 5H3C1.34 5 0 6.34 0 8V12C0 13.1 0.9 14 2 14H4V16C4 17.1 4.9 18 6 18H14C15.1 18 16 17.1 16 16V14H18C19.1 14 20 13.1 20 12V8C20 6.34 18.66 5 17 5ZM13 16H7C6.45 16 6 15.55 6 15V11H14V15C14 15.55 13.55 16 13 16ZM17 9C16.45 9 16 8.55 16 8C16 7.45 16.45 7 17 7C17.55 7 18 7.45 18 8C18 8.55 17.55 9 17 9ZM15 0H5C4.45 0 4 0.45 4 1V3C4 3.55 4.45 4 5 4H15C15.55 4 16 3.55 16 3V1C16 0.45 15.55 0 15 0Z" fill="black" />
            </svg> Print
          </button>
        )}
      />

      <ExpenseSlip ref={ref} />
    </div>
  );
};


export default ExpenseSlipByExpense
