import React, { useState } from "react";
import Userdetailsmain from "./Userdetailsmain";
import Personaldetails from "./Personaldetails";
import Jobdetails from "./Jobdetails";
import Leave from "./Leave.jsx";
import Attendances from "./Attendances.jsx";
import Document from "./Document.jsx"
import Assets from "./Assets.jsx"
import Finance from "./Finance.jsx"
import Expenses from "./Expenses.jsx"
import Performance from "./Performance.jsx"
import '../../assets/css/components.css';
import Dashboardnavbarcopy from "../../layout/Dashboardnavbarcopy.jsx";
import MyClockins from "./Clockin.jsx";

const UserDetails = () => {
  const [toggleState, setToggleState] = useState(1);

  const getSelectedTitle = () => {
    switch (toggleState) {
      case 1:
        return 'About';
      case 2:
        return 'Job';
      case 3:
        return 'Requests';
      case 4:
        return 'Attendence';
      case 5:
        return 'Documents';
      case 6:
        return 'Assets';
      case 7:
        return 'Finances';
      case 8:
        return 'Expenses';
      case 9:
        return 'Performance';
      case 10:
        return 'Clock Ins';
      default:
        return 'About';
    }
  };


  return (
    <div>
      <Dashboardnavbarcopy name={getSelectedTitle()} url="Profile" />
      <div style={{ background: "#F7F8FA" }}>
        <Userdetailsmain />

        <div className="bloc-tabss wfm-marginn">
          <button
            className={
              toggleState === 1
                ? "dashboardcomponent-tabs dashboardcomponent-active-tabs dashboardcomponent-tabss"
                : "dashboardcomponent-tabs dashboardcomponent-tabss"
            }
            onClick={() => setToggleState(1)}
          >
            About
          </button>
          {/* <button
            className={
              toggleState === 2
                ? "dashboardcomponent-tabs dashboardcomponent-active-tabs"
                : "dashboardcomponent-tabs"
            }
            onClick={() => setToggleState(2)}
          >
            Job 
          </button> */}
          <button
            className={
              toggleState === 3
                ? "dashboardcomponent-tabs dashboardcomponent-active-tabs"
                : "dashboardcomponent-tabs"
            }
            onClick={() => setToggleState(3)}
          >
            Requests
          </button>
          <button
            className={
              toggleState === 4
                ? "dashboardcomponent-tabs dashboardcomponent-active-tabs"
                : "dashboardcomponent-tabs"
            }
            onClick={() => setToggleState(4)}
          >
            Attendance
          </button>
          <button
            className={
              toggleState === 10
                ? "dashboardcomponent-tabs dashboardcomponent-active-tabs"
                : "dashboardcomponent-tabs"
            }
            onClick={() => setToggleState(10)}
          >
            ClockIns
          </button>
          <button
            className={
              toggleState === 5
                ? "dashboardcomponent-tabs dashboardcomponent-active-tabs"
                : "dashboardcomponent-tabs"
            }
            onClick={() => setToggleState(5)}
          >
            Documents
          </button>
          <button
            className={
              toggleState === 6
                ? "dashboardcomponent-tabs dashboardcomponent-active-tabs"
                : "dashboardcomponent-tabs"
            }
            onClick={() => setToggleState(6)}
          >
            Assets
          </button>
          <button
            className={
              toggleState === 7
                ? "dashboardcomponent-tabs dashboardcomponent-active-tabs"
                : "dashboardcomponent-tabs"
            }
            onClick={() => setToggleState(7)}
          >
            Finances
          </button>
          <button
            className={
              toggleState === 8
                ? "dashboardcomponent-tabs dashboardcomponent-active-tabs"
                : "dashboardcomponent-tabs"
            }
            onClick={() => setToggleState(8)}
          >
            Expenses
          </button>
          <button
            className={
              toggleState === 9
                ? "dashboardcomponent-tabs dashboardcomponent-active-tabs"
                : "dashboardcomponent-tabs"
            }
            onClick={() => setToggleState(9)}
          >
            Performance
          </button>
        </div>

        <div className="content-tabss">
          {toggleState === 1 ? <Personaldetails /> : null}
          {toggleState === 2 ? <Jobdetails /> : null}
          {toggleState === 3 ? <Leave /> : null}
          {toggleState === 4 ? <Attendances /> : null}
          {toggleState === 10 ? <MyClockins /> : null}
          {toggleState === 5 ? <Document /> : null}
          {toggleState === 6 ? <Assets /> : null}
          {toggleState === 7 ? <Finance /> : null}
          {toggleState === 8 ? <Expenses /> : null}
          {toggleState === 9 ? <Performance /> : null}
        </div>
      </div>
    </div>
  );
};

export default UserDetails;