import React, { useState, useEffect } from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Clockin from './Clockin';
import Leave from './Leave';
import Outduty from './Outduty';
import AssignTask from './AssignTask';


const Applymodels = () => {

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [toggleState, setToggleState] = useState(1);

    return (
        <>
            <button className="model-button model-button-leave" onClick={handleShow} style={{ padding: "10px 20px" }}>
                Apply
            </button>

            <Modal
                show={show}
                onHide={handleClose}
                dialogClassName="request-leave"
            >
                {/* <Modal.Header closeButton>
          <Modal.Title>Request Leave</Modal.Title>
        </Modal.Header> */}
                <Modal.Body>
                    <div>

                        <div >
                            <div className="bloc-tabs-apply4">
                                <button
                                    className={toggleState === 1 ? "dashboardcomponent-tabs-apply dashboardcomponent-active-tabs" : "dashboardcomponent-tabs-apply"}
                                    onClick={() => setToggleState(1)}
                                >
                                    Clock in
                                </button>
                                <button
                                    className={toggleState === 2 ? "dashboardcomponent-tabs-apply dashboardcomponent-active-tabs" : "dashboardcomponent-tabs-apply"}
                                    onClick={() => setToggleState(2)}
                                >
                                    Apply Leave
                                </button>
                                <button
                                    className={toggleState === 3 ? "dashboardcomponent-tabs-apply dashboardcomponent-active-tabs" : "dashboardcomponent-tabs-apply"}
                                    onClick={() => setToggleState(3)}
                                >
                                    Assign Task
                                </button>
                                <button
                                    className={toggleState === 4 ? "dashboardcomponent-tabs-apply dashboardcomponent-active-tabs" : "dashboardcomponent-tabs-apply"}
                                    onClick={() => setToggleState(4)}
                                >
                                    Out Duty
                                </button>
                            </div>
                        </div>

                        <div className="content-tabs ">
                            {toggleState === 1 ? (
                                <Clockin handleClose={handleClose} />
                            ) : null}

                            {toggleState === 2 ? <Leave handleClose={handleClose} /> : null}

                            {toggleState === 3 ? <AssignTask handleClose={handleClose} /> : null}

                            {toggleState === 4 ? <Outduty handleClose={handleClose} /> : null}


                        </div>

                    </div>

                </Modal.Body>
            </Modal>


        </>
    )
}

export default Applymodels