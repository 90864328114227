import React, { useState, useEffect } from 'react'

import Dashboardnavbarcopy from '../../layout/Dashboardnavbarcopy';
import Attendance from './tlwfm/TlAttendance'
import EmployeeDetails from './tlwfm/EmployeeDetails'
import Requestmanagment from './tlwfm/Requestmanagement'
import TlOrgstructure from './tlwfm/TlOrgstructure';
import { RequestRequisitionTableRH } from '../RequisitionManagement/RequisitionManagementTables';

const TlWorkfm = () => {


  const [toggleState, setToggleState] = useState(1);


  const getSelectedTitle = () => {
    switch (toggleState) {
      case 1:
        return 'Organization Structure';
      case 2:
        return 'Employee Self-Service';
      case 3:
        return 'Attendance';
      case 4:
        return 'Request management';

      default:
        return 'Employee Self-Service';
    }
  };

  return (
    <div>
      <Dashboardnavbarcopy name={getSelectedTitle()} url="Workforce Managment" />
      <div className="bloc-tabss  wfm-margin">
        <button
          className={toggleState === 1 ? "dashboardcomponent-tabs dashboardcomponent-tabss dashboardcomponent-active-tabs" : "dashboardcomponent-tabs dashboardcomponent-tabss"}
          onClick={() => setToggleState(1)}
        >
          Organization Structure
        </button>
        <button
          className={toggleState === 2 ? "dashboardcomponent-tabs dashboardcomponent-tabss dashboardcomponent-active-tabs" : "dashboardcomponent-tabs dashboardcomponent-tabss"}
          onClick={() => setToggleState(2)}
        >
          Employee Self-Service
        </button>
        <button
          className={toggleState === 3 ? "dashboardcomponent-tabs dashboardcomponent-tabss dashboardcomponent-active-tabs" : "dashboardcomponent-tabs dashboardcomponent-tabss"}
          onClick={() => setToggleState(3)}
        >
          Attendance
        </button>
        <button
          className={toggleState === 4 ? "dashboardcomponent-tabs dashboardcomponent-tabss dashboardcomponent-active-tabs" : "dashboardcomponent-tabs dashboardcomponent-tabss"}
          onClick={() => setToggleState(4)}
        >
          Request Management
        </button>
        {/* <button
          className={toggleState === 5 ? "dashboardcomponent-tabs dashboardcomponent-tabss dashboardcomponent-active-tabs" : "dashboardcomponent-tabs dashboardcomponent-tabss"}
          onClick={() => setToggleState(5)}
        >
          Requisition Management
        </button> */}

      </div>
      <div className="content-tabs">

        {toggleState === 1 ? <TlOrgstructure /> : null}

        {toggleState === 2 ? <EmployeeDetails /> : null}

        {toggleState === 3 ? <Attendance /> : null}

        {toggleState === 4 ? <Requestmanagment /> : null}

        {toggleState === 5 ? <RequestRequisitionTableRH /> : null}



      </div>
    </div>
  )
}

export default TlWorkfm
