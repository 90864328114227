import React, { useState, useEffect } from "react";
import Dashboardnavbarcopy from "../layout/Dashboardnavbarcopy";
import axios from "axios";
import { BASE_URL } from "../config/axios";
import Select from 'react-select';
import { formatDate, formattedDate } from "./Date";
import Modal from "react-bootstrap/Modal";

const CompleteTask = ({ i, getAllTasks }) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [formData, setFormData] = useState({
        complete_date: formattedDate,
        status: "completed",
        remark: "",
    });


    useEffect(() => {
        setFormData(i);
    }, [i]);

    // !  ************** Validation start **************  ! //

    const [errors, setErrors] = useState({});
    const [inputState, setInputState] = useState({});

    const validateForm = () => {
        const newErrors = {};
        const requiredFields = ["remark"];
        requiredFields.forEach((field) => {
            if (!formData[field]) {
                newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
                    } is required !`;
            }
        });

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setInputState({
            ...inputState,
            [name]: value.trim() ? "green" : "",
        });

        setFormData({
            ...formData,
            [name]: value,
        });
    };

    // ?  ************** Validation End **************  ! //
    const [loading, setLoading] = useState(false); //loading logic

    const handleFormSubmitPut = async (e) => {
        e.preventDefault();
        setLoading(true); //loading logic

        const updatedformvalue = ({
            ...formData,
            status: "completed",

        });

        if (validateForm()) {

            try {

                let res = await axios.put(
                    `${BASE_URL}/wfm/task/${i.id}/`,
                    updatedformvalue
                );

                if (res.status === 200) {
                    await getAllTasks();
                    setShow(false);
                } else {
                    alert(res);
                }
            } catch (err) {
                alert(err);
            } finally {
                setLoading(false); //loading logic
            }
        }
    };

    return (
        <>

            <button className="model-edit-button" title="Complete" onClick={handleShow}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                >
                    <path
                        d="M15 4.5L6.75 12.75L3 9"
                        stroke="#145650"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            </button>

            <Modal show={show} onHide={handleClose} dialogClassName="request-leave">
                <Modal.Header closeButton>
                    <Modal.Title>
                        Completing Remark
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="parent-div">
                        <div className="flex-column">
                            {/* <label htmlFor="feedback" className="form-labels">
                                Closing Feedback<span className="required"></span>
                            </label> */}
                            <textarea
                                type="text"
                                id="remark"
                                name="remark"
                                maxLength={100}
                                placeholder="remark"
                                onChange={handleInputChange}
                                value={formData.remark}
                                className={`form-input-textarea ${errors.remark
                                    ? "error"
                                    : inputState.remark
                                        ? "success"
                                        : ""
                                    }`}
                            />
                            {errors.remarks && (
                                <span className="error-message">{errors.remarks}</span>
                            )}
                        </div>




                        <div className="button-models">
                            <button className="model-button" onClick={handleClose}>Close</button>
                            <button className="model-button model-button-submit" onClick={handleFormSubmitPut} disabled={loading}>
                                Complete
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};
const CloseTask = ({ i, getAllTasks }) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [formData, setFormData] = useState({
        close_date: formattedDate,
        status: "close",
        feedback: "",
    });


    useEffect(() => {
        setFormData(i);
    }, [i]);

    // !  ************** Validation start **************  ! //

    const [errors, setErrors] = useState({});
    const [inputState, setInputState] = useState({});

    const validateForm = () => {
        const newErrors = {};
        const requiredFields = ["feedback"];
        requiredFields.forEach((field) => {
            if (!formData[field]) {
                newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
                    } is required !`;
            }
        });

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setInputState({
            ...inputState,
            [name]: value.trim() ? "green" : "",
        });

        setFormData({
            ...formData,
            [name]: value,
        });
    };

    // ?  ************** Validation End **************  ! //
    const [loading, setLoading] = useState(false); //loading logic

    const handleFormSubmitPut = async (e) => {
        e.preventDefault();
        setLoading(true); //loading logic
        const updatedformvalue = ({
            ...formData,
            close_date: formattedDate,
            status: "close",

        });
        if (validateForm()) {

            try {

                let res = await axios.put(
                    `${BASE_URL}/wfm/task/${i.id}/`,
                    updatedformvalue
                );

                if (res.status === 200) {
                    await getAllTasks();
                    setShow(false);
                } else {
                    alert(res);
                }
            } catch (err) {
                alert(err);
            } finally {
                setLoading(false); //loading logic
            }
        }
    };

    return (
        <>

            <button className="model-delete-button" title="Close Task" onClick={handleShow}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                >
                    <path
                        d="M13.5 4.5L4.5 13.5"
                        stroke="#F72B50"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                    <path
                        d="M4.5 4.5L13.5 13.5"
                        stroke="#F72B50"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                </svg>
            </button>

            <Modal show={show} onHide={handleClose} dialogClassName="request-leave">
                <Modal.Header closeButton>
                    <Modal.Title>
                        Closing Feedback
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="parent-div">
                        <div className="flex-column">
                            {/* <label htmlFor="feedback" className="form-labels">
                                Closing Feedback<span className="required"></span>
                            </label> */}
                            <textarea
                                type="text"
                                id="feedback"
                                name="feedback"
                                maxLength={100}
                                placeholder="feedback"
                                onChange={handleInputChange}
                                value={formData.feedback}
                                className={`form-input-textarea ${errors.feedback
                                    ? "error"
                                    : inputState.feedback
                                        ? "success"
                                        : ""
                                    }`}
                            />
                            {errors.remarks && (
                                <span className="error-message">{errors.remarks}</span>
                            )}
                        </div>




                        <div className="button-models">
                            <button className="model-button" onClick={handleClose}>Close</button>
                            <button className="model-button model-button-submit" onClick={handleFormSubmitPut} disabled={loading}>
                                Close
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

const
    CriticalIssues = () => {


        // *********** Employee Details Api Start ***********
        const [allemployee, setAllemployee] = useState([]);
        const [filteredEmployee, setFilteredEmployee] = useState([]);

        const getAllEmployee = async () => {
            try {
                const res = await axios.get(`${BASE_URL}/wfm/taskbyemp/${sessionStorage.getItem('emp_code')}/`);
                setAllemployee(res.data);
                setFilteredEmployee(res.data);

                const uniqueDayStatus = [...new Set(res.data.map(entry => entry.status))];
                setStatus_list(uniqueDayStatus)
                const uniquePriorityStatus = [...new Set(res.data.map(entry => entry.priority))];
                setPriority_status(uniquePriorityStatus)
            } catch (err) {
                // alert(err.message);
            }
        };
        useEffect(() => {

            getAllEmployee();
        }, []);
        // *********** Employee Details Api End ***********

        const today = new Date();
        const today2 = new Date();
        today2.setDate(today2.getDate() + 30);

        const year = today.getFullYear();
        const month = today.getMonth() + 1;
        const day = today.getDate();

        const year2 = today2.getFullYear();
        const month2 = today2.getMonth() + 1;
        const day2 = today2.getDate();
        const formattedDatee = `${year}-${month.toString().padStart(2, "0")}-${day
            .toString()
            .padStart(2, "0")}`;

        // const [startdate, setStartDate] = useState(`${formattedDatee}`);
        const [startdate, setStartDate] = useState(``);
        const formattedDate = `${year2}-${month2.toString().padStart(2, "0")}-${day2
            .toString()
            .padStart(2, "0")}`;
        // const [enddate, setEndDate] = useState(`${formattedDate}`);
        const [enddate, setEndDate] = useState(``);
        // console.log(formattedDatee);


        // *********** Filter Logic ***********

        const [selectedDepartment, setSelectedDepartment] = useState("");
        // const [selectedStatus, setSelectedStatus] = useState("");
        const [selectedStatus, setSelectedStatus] = useState(['pending', 'inprocess', 'completed']);
        const [jobTitle, setJobTitle] = useState("");
        const [status_list, setStatus_list] = useState([])
        const [asignee, setAsignee] = useState("")
        const [asigning, setAsigning] = useState("")
        const [priority, setPriority] = useState("")
        const [priorityType, setPriorityType] = useState("")
        const [priority_status, setPriority_status] = useState([])


        function handlePriority(value) {

            if (value == 1) {
                return <button className="priority-button Color-tab-red">High</button>
                // return "High"
            }
            else if (value == 2) {
                return <button className="priority-button Color-tab-yellow">Medium</button>
                // return <td className="align-center Color-tab-blue">Medium</td>
                // return "Medium"
            }
            else if (value == 3) {
                return <button className="priority-button Color-tab-blue">Low</button>
                // return <td className="align-center Color-tab-yellow">Low</td>
            }
            else
                return <td className="align-center"></td>


            // return value;

        }

        const handleSearch = () => {
            // Filter employees based on selected criteria
            let filteredData = allemployee

            // if (selectedStatus !== '') {
            //     let filteredData = allemployee.filter((employee) => employee === selectedStatus);
            // }
            if (selectedStatus.length > 0) {
                filteredData = filteredData.filter(employee => selectedStatus.includes(employee.status));
            }
            if (selectedStatus.length === 0) {
                // filteredData = filteredData.filter(employee => selectedStatus.includes(['pending', 'inprocess', 'completed']));
                filteredData = filteredData.filter(employee => ['pending', 'inprocess', 'completed'].includes(employee.status));

            }
            // if (selectedStatus !== '') {
            //     filteredData = filteredData.filter(employee => employee.status === selectedStatus);
            // }
            if (asigning !== '') {

                if (asigning == 'by') {
                    filteredData = filteredData.filter(employee => employee.assignedby === sessionStorage.getItem('emp_code'));
                }
                else if (asigning == 'to') {
                    filteredData = filteredData.filter(employee => employee.concern_person === sessionStorage.getItem('emp_code'));
                }

            }
            if (priority !== '') {

                if (priority == '1') {
                    filteredData = filteredData.filter(employee => employee.priority === priority);
                }
                else if (priority == '2') {
                    filteredData = filteredData.filter(employee => employee.priority === priority);
                }
                else if (priority == '3') {
                    filteredData = filteredData.filter(employee => employee.priority === priority);
                }

            }
            if (asignee) {
                // Use includes for a case-insensitive search for the name only
                const lowercaseSelectedName = asignee.toLowerCase();
                filteredData = filteredData.filter(employee => {
                    const nameMatch = employee.assignedbyname.toLowerCase().includes(lowercaseSelectedName);
                    const task = employee.task.toLowerCase().includes(lowercaseSelectedName);

                    const concernnameMatch = employee.concern_personname.toLowerCase().includes(lowercaseSelectedName);
                    // const empMatch = employee.assignedbyname.toString().includes(asignee);
                    const empCodeMatch = employee.assignedby.toString().includes(asignee);
                    const empCodeMatchh = employee.concern_person.toString().includes(asignee);
                    // const empMatchh = employee.concern_personname.toString().includes(asignee);

                    return nameMatch || concernnameMatch || empCodeMatch || empCodeMatchh || task;
                });
            }

            if (startdate !== '') {
               
                // filteredData = filteredData.filter(employee => employee.due_date >= startdate );
                filteredData = filteredData.filter(employee => employee.due_date >= startdate && employee.assigndate >= startdate);
 
            }
            if (enddate !== '') {


                // filteredData = filteredData.filter(employee => employee.due_date <= enddate);
                filteredData = filteredData.filter(employee => employee.due_date <= enddate && employee.assigndate <= enddate);
           

            }



            setFilteredEmployee(filteredData);
        };

        // console.log(filteredEmployee)
        useEffect(() => {

            handleSearch();

        }, [selectedStatus, allemployee, asignee, startdate, enddate, asigning, priority]);

        const handlePending = async (e, id) => {
            e.preventDefault();
            try {
                let res = await axios.put(`${BASE_URL}/wfm/task/${id}/`, {
                    status: "inprocess",
                    // resume_list_action: false,
                });

                if (res.status === 200) {
                    await getAllEmployee();
                } else {
                    alert(res);
                }
            } catch (err) {
                alert(err);
            }
        };

        const handleAccepted = async (e, id) => {
            e.preventDefault();
            try {
                let res = await axios.put(`${BASE_URL}/wfm/task/${id}/`, {
                    status: "completed",
                    // resume_list_action: true,
                    complete_date: formattedDate,
                });

                if (res.status === 200) {
                    await getAllEmployee();
                } else {
                    alert(res);
                }
            } catch (err) {
                alert(err);
            }
        };

        const handleCompletedClose = async (e, id) => {
            e.preventDefault();
            try {
                let res = await axios.put(`${BASE_URL}/wfm/task/${id}/`, {
                    status: "close",
                    // close_date: formattedDatee,
                });

                if (res.status === 200) {
                    await getAllEmployee();
                } else {
                    alert(res);
                }
            } catch (err) {
                alert(err);
            }
        };
        const handleCompletedReassign = async (e, id) => {
            e.preventDefault();
            try {
                let res = await axios.put(`${BASE_URL}/wfm/task/${id}/`, {
                    status: "pending",
                    // resume_list_action: false,
                });

                if (res.status === 200) {
                    await getAllEmployee();
                } else {
                    alert(res);
                }
            } catch (err) {
                alert(err);
            }
        };


        const statusName = [
            {
                status: "pending",
                name: "Pending"
            },
            {
                status: "inprocess",
                name: "In process"
            },
            {
                status: "completed",
                name: "Completed"
            },
            {
                status: "close",
                name: "Close"
            }
        ]


        const options = statusName
            .map((e) => ({
                value: e.status,
                label: e.name,
            }));

        const handleChangecc = (selected) => {
            const multipleperson = selected.length > 0 ? selected.map((item) => item.value) : ""
            setSelectedStatus(multipleperson);
        };

        const createMarkup = (content) => {
            const linkRegex = /(?:https?|ftp):\/\/[^\s]+/g;
            return {
                __html: content.replace(linkRegex, (url) => {
                    return `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`;
                }),
            };
        };



        return (
            <>
                {/* Attendance History Container */}
                {/* <Dashboardnavbarcopy name={`${selectedStatus.toUpperCase()} Critical Issues`} url="Critical Issue" /> */}
                <Dashboardnavbarcopy name={`Critical Issues`} url="Critical Issue" />
                <div className="content-tabs">
                    <div className="attendance-subcont">
                        <div className="field-cont">
                            <input
                                className="attendance-input-field date-field"
                                placeholder="From Date"
                                type="date"
                                value={startdate}
                                onChange={(e) => setStartDate(e.target.value)}
                            />
                            <hr />
                            <input
                                className="attendance-input-field date-field"
                                placeholder="To Date"
                                type="date"
                                value={enddate}
                                onChange={(e) => setEndDate(e.target.value)}
                            />
                            <hr />

                            <Select
                                className="attendance-input-field"
                                width="200px"
                                options={options}
                                placeholder="Status"
                                isMulti
                                onChange={handleChangecc}
                            />

                            {/* <select
                            className="attendance-input-field date-field"
                            type="text"
                            value={selectedStatus}
                            onChange={(e) => setSelectedStatus(e.target.value)}
                        >
                            <option value="">Status</option>
                            {status_list.map(status => (<option value={status}>{status}</option>))};

                        </select> */}

                            {/* <select
                            className="attendance-input-field date-field"
                            multiple
                            value={selectedStatus}
                            onChange={(e) => setSelectedStatus(Array.from(e.target.selectedOptions, option => option.value))}
                        >
                            <option value="">Status</option>
                            {status_list.map(status => (
                                <option key={status} value={status}>
                                    {status}
                                </option>
                            ))}
                        </select> */}
                            {/* <select
                            className="attendance-input-field date-field"
                            multiple
                            value={selectedStatus}
                            // onChange={(e) => setSelectedStatus(Array.from(e.target.selectedOptions, option => option.value))}
                            onChange={(e) => {
                                const selectedOptions = Array.from(e.target.selectedOptions, option => option.value);
                                console.log(selectedOptions);
                                setSelectedStatus(selectedOptions);
                            }}
                        >
                            <option value="">Status</option>
                            <option value="pending">Pending</option>
                            <option value="inprocess">In progress</option>
                            <option value="completed">Completed</option>
                            <option value="close">Close</option>
                        </select> */}
                            <hr />

                            <select
                                className="attendance-input-field date-field"
                                type="text"
                                value={asigning}
                                onChange={(e) => setAsigning(e.target.value)}
                            >
                                <option value="null">Assigned</option>
                                <option value="by">By you</option>
                                <option value="to">To you</option>
                                {/* <option value="completed">Completed</option>
              <option value="close">Close</option> */}
                                {/* {status_list.map(status => (<option value={status}>{status}</option>))}; */}

                            </select>
                            <hr />

                            <select
                                className="attendance-input-field date-field"
                                type="text"
                                value={priority}
                                onChange={(e) => setPriority(e.target.value)}
                            >
                                <option value="">Priority</option>
                                {/* <option value="pending">Pending</option>
              <option value="inprocess">In progress</option>
              <option value="completed">Completed</option>
              <option value="close">Close</option> */}
                                {/* {priority_status.map(prior => (<option value={prior}>{prior === "1" ? "High" : prior === "2" ? "Medium" : "Low"}</option>))}; */}
                                {priority_status.sort((a, b) => a - b).map(prior => (<option value={prior}>{prior === "1" ? "High" : prior === "2" ? "Medium" : prior === "3" ? "Low" : "All"}</option>))};

                            </select>
                            <hr />

                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="40"
                                height="40"
                                viewBox="0 0 11 12"
                                fill="none"
                            >
                                <circle
                                    cx="5"
                                    cy="5"
                                    r="4.3"
                                    stroke="#707070"
                                    stroke-width="1.4"
                                />
                                <line
                                    x1="10.0101"
                                    y1="11"
                                    x2="8"
                                    y2="8.98995"
                                    stroke="#707070 "
                                    stroke-width="1.4"
                                    stroke-linecap="round"
                                />
                            </svg>

                            <input

                                className="attendance-input-field"
                                placeholder="By Task, Emp & Name"
                                type="text"
                                value={asignee}
                                onChange={(e) => setAsignee(e.target.value)}
                            />
                        </div>
                        <div className="btn-cont">
                            {/* <button className="attendance-btn" onClick={handleSearch}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="20"
                viewBox="0 0 11 12"
                fill="none"
              >
                <circle
                  cx="5"
                  cy="5"
                  r="4.3"
                  stroke="#FFFFFF"
                  stroke-width="1.4"
                />
                <line
                  x1="10.0101"
                  y1="11"
                  x2="8"
                  y2="8.98995"
                  stroke="#FFFFFF"
                  stroke-width="1.4"
                  stroke-linecap="round"
                />
              </svg>
              Search
            </button> */}
                        </div>
                    </div>


                    <table className="custom-tablee">
                        <thead>
                            <tr className="custom-table-head-tr">
                                <th className="align-left">Sr no.</th>
                                <th className="align-center">Task</th>
                                <th className="align-center">Assign By</th>
                                <th className="align-center">Assigned to</th>
                                <th className="align-center">Assigned Date</th>
                                <th className="align-center">Due date</th>
                                {/* {filteredEmployee.find(task => task.status === "complete") ?
                                    <th className="align-center">Remark</th> : ""
                                }
                                {filteredEmployee.find(task => task.status === "close") ?
                                    <th className="align-center">Close date</th> : ""
                                }
                                {filteredEmployee.find(task => task.status === "close") ?
                                    <th className="align-center">Feedback</th> : ""
                                } */}

                                <th className="align-center">Completion Remark</th>
                                <th className="align-center">Close Date</th>
                                <th className="align-center">Closing Feedback</th>
                                <th className="align-center">Priorty</th>
                                <th className="align-center">Status</th>
                                <th className="align-center">Action</th>
                            </tr>
                        </thead>
                        <tbody>

                            {filteredEmployee.sort((a, b) => new Date(a.due_date) - new Date(b.due_date))
                                .sort((a, b) => a.priority - b.priority)
                                .map((i, index) => (
                                    <React.Fragment key={index}>
                                        <tr className="tr-border-bottom">
                                            <td colSpan="8"></td>
                                        </tr>
                                        <tr className="custom-table-head-td2">
                                            <td className="align-left">{index + 1}</td>
                                            <td className="align-center" style={{ width: '3vw' }}> <div dangerouslySetInnerHTML={createMarkup(i.task)} /></td>
                                            {/* <td className="align-center">{i.assignedby}-{i.assignedbyname}</td> */}
                                            <td className="align-center"><img className="profile-img" src={`${i.assignedbypic}` ? `https://spacecreator-aimantra.s3.ap-south-1.amazonaws.com/${i.assignedbypic}` : 'https://images.unsplash.com/photo-1511367461989-f85a21fda167?q=80&w=1931&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'} alt="Assignerpic" /><p>{i.assignedby}-{i.assignedbyname}</p></td>
                                            <td className="align-center"><img className="profile-img" src={`${i.concern_personpic}` ? `https://spacecreator-aimantra.s3.ap-south-1.amazonaws.com/${i.concern_personpic}` : 'https://images.unsplash.com/photo-1511367461989-f85a21fda167?q=80&w=1931&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'} alt="Assignedpic" /><p>{i.concern_person}-{i.concern_personname}</p></td>
                                            {/* <td className="align-center">{i.concern_person}-{i.concern_person_name}</td> */}
                                            <td className="align-center">{formatDate(i.assigndate)} </td>
                                            <td className="align-center">{formatDate(i.due_date)}</td>
                                            {i.remark ?
                                                <td className="align-center">{i.remark}</td>
                                                : <td className="align-center">-</td>}
                                            {i.close_date ?
                                                <td className="align-center">{i.close_date ? formatDate(i.close_date) : '-'}</td>
                                                : <td className="align-center">-</td>}
                                            {i.feedback ?
                                                <td className="align-center">{i.feedback}</td>
                                                : <td className="align-center">-</td>}
                                            {/* <td className="align-center">{handlePriority(i.priority)}</td> */}
                                            <td className="align-center"> {handlePriority(i.priority)}</td>
                                            <td className="align-center ">{i.status}</td>
                                            {/* <td className="align-right ">{i.status}</td> */}
                                            <td className="align-center">
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    {(i.status === "pending" && i.concern_person === sessionStorage.getItem('emp_code')) ? (
                                                        <>
                                                            <CompleteTask i={i} getAllTasks={getAllEmployee} />
                                                            {/* <button
                                                            title="Accept"
                                                            className="model-edit-button"
                                                            onClick={(e) => handlePending(e, i.id)}
                                                        >
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width="18"
                                                                height="18"
                                                                viewBox="0 0 18 18"
                                                                fill="none"
                                                            >
                                                                <path
                                                                    d="M15 4.5L6.75 12.75L3 9"
                                                                    stroke="#145650"
                                                                    strokeWidth="2"
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                />
                                                            </svg>
                                                        </button> */}

                                                        </>
                                                    ) : (i.status === "inprocess" && i.concern_person === sessionStorage.getItem('emp_code')) ? (
                                                        <>
                                                            <button
                                                                title="Complete"
                                                                className="model-delete-button"
                                                                onClick={(e) => handleAccepted(e, i.id)}
                                                            >
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width="18"
                                                                    height="18"
                                                                    viewBox="0 0 18 18"
                                                                    fill="none"
                                                                >
                                                                    <path
                                                                        d="M15 4.5L6.75 12.75L3 9"
                                                                        stroke="#F72B50"
                                                                        strokeWidth="2"
                                                                        strokeLinecap="round"
                                                                        strokeLinejoin="round"
                                                                    />
                                                                </svg>
                                                            </button>

                                                        </>
                                                    ) : (i.status === "completed" && i.assignedby === sessionStorage.getItem('emp_code')) ? (
                                                        <>
                                                            <button
                                                                title="Reassign"
                                                                className="model-edit-button"
                                                                onClick={(e) => handleCompletedReassign(e, i.id)}
                                                            >
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width="18"
                                                                    height="18"
                                                                    viewBox="0 0 100 100"
                                                                    fill="none"
                                                                >
                                                                    <path
                                                                        fill-rule="evenodd"
                                                                        clip-rule="evenodd"
                                                                        d="M50 18.75C56.754 18.7526 63.3254 20.9433 68.73 24.994C74.1345 29.0447 78.0812 34.7373 79.979 41.2192C81.8767 47.7012 81.6234 54.6235 79.257 60.9494C76.8905 67.2752 72.5382 72.6642 66.8521 76.309C61.166 79.9538 54.452 81.6584 47.7159 81.1673C40.9797 80.6762 34.5839 78.016 29.4865 73.585C24.3891 69.1541 20.8645 63.1909 19.4404 56.5887C18.0164 49.9865 18.7697 43.1007 21.5875 36.9625C21.8942 36.2162 21.9004 35.3802 21.6047 34.6295C21.3091 33.8788 20.7345 33.2715 20.0013 32.9348C19.2681 32.5981 18.433 32.558 17.6709 32.8229C16.9088 33.0879 16.2787 33.6373 15.9125 34.3563C12.5313 41.7224 11.6277 49.9858 13.3371 57.9086C15.0464 65.8315 19.2767 72.9872 25.3942 78.3041C31.5118 83.6209 39.1873 86.8126 47.271 87.401C55.3547 87.9895 63.4115 85.9431 70.2346 81.5682C77.0576 77.1934 82.2796 70.7258 85.1184 63.134C87.9571 55.5423 88.2598 47.2351 85.981 39.4569C83.7023 31.6788 78.9648 24.8482 72.4782 19.9885C65.9916 15.1287 58.1051 12.5014 50 12.5V18.75Z"
                                                                        fill="black"
                                                                    />
                                                                    <path
                                                                        d="M50 27.9125V3.3375C49.9999 3.0406 49.9152 2.74988 49.7558 2.49938C49.5965 2.24888 49.369 2.04897 49.1001 1.92306C48.8313 1.79715 48.5321 1.75044 48.2376 1.78842C47.9432 1.8264 47.6656 1.94749 47.4375 2.1375L32.6875 14.425C32.5117 14.5716 32.3702 14.7551 32.2731 14.9625C32.176 15.1699 32.1257 15.396 32.1257 15.625C32.1257 15.854 32.176 16.0801 32.2731 16.2875C32.3702 16.4949 32.5117 16.6784 32.6875 16.825L47.4375 29.1125C47.6656 29.3025 47.9432 29.4236 48.2376 29.4616C48.5321 29.4996 48.8313 29.4529 49.1001 29.3269C49.369 29.201 49.5965 29.0011 49.7558 28.7506C49.9152 28.5001 49.9999 28.2094 50 27.9125Z"
                                                                        fill="black"
                                                                    />
                                                                </svg>
                                                            </button>

                                                        </>
                                                    ) : (i.status === "close") ? (i.assignedby === sessionStorage.getItem('emp_code')) ? <>
                                                        <button
                                                            className="model-edit-button"
                                                            onClick={(e) => handleCompletedReassign(e, i.id)}
                                                        >
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width="18"
                                                                height="18"
                                                                viewBox="0 0 100 100"
                                                                fill="none"
                                                            >
                                                                <path
                                                                    fill-rule="evenodd"
                                                                    clip-rule="evenodd"
                                                                    d="M50 18.75C56.754 18.7526 63.3254 20.9433 68.73 24.994C74.1345 29.0447 78.0812 34.7373 79.979 41.2192C81.8767 47.7012 81.6234 54.6235 79.257 60.9494C76.8905 67.2752 72.5382 72.6642 66.8521 76.309C61.166 79.9538 54.452 81.6584 47.7159 81.1673C40.9797 80.6762 34.5839 78.016 29.4865 73.585C24.3891 69.1541 20.8645 63.1909 19.4404 56.5887C18.0164 49.9865 18.7697 43.1007 21.5875 36.9625C21.8942 36.2162 21.9004 35.3802 21.6047 34.6295C21.3091 33.8788 20.7345 33.2715 20.0013 32.9348C19.2681 32.5981 18.433 32.558 17.6709 32.8229C16.9088 33.0879 16.2787 33.6373 15.9125 34.3563C12.5313 41.7224 11.6277 49.9858 13.3371 57.9086C15.0464 65.8315 19.2767 72.9872 25.3942 78.3041C31.5118 83.6209 39.1873 86.8126 47.271 87.401C55.3547 87.9895 63.4115 85.9431 70.2346 81.5682C77.0576 77.1934 82.2796 70.7258 85.1184 63.134C87.9571 55.5423 88.2598 47.2351 85.981 39.4569C83.7023 31.6788 78.9648 24.8482 72.4782 19.9885C65.9916 15.1287 58.1051 12.5014 50 12.5V18.75Z"
                                                                    fill="black"
                                                                />
                                                                <path
                                                                    d="M50 27.9125V3.3375C49.9999 3.0406 49.9152 2.74988 49.7558 2.49938C49.5965 2.24888 49.369 2.04897 49.1001 1.92306C48.8313 1.79715 48.5321 1.75044 48.2376 1.78842C47.9432 1.8264 47.6656 1.94749 47.4375 2.1375L32.6875 14.425C32.5117 14.5716 32.3702 14.7551 32.2731 14.9625C32.176 15.1699 32.1257 15.396 32.1257 15.625C32.1257 15.854 32.176 16.0801 32.2731 16.2875C32.3702 16.4949 32.5117 16.6784 32.6875 16.825L47.4375 29.1125C47.6656 29.3025 47.9432 29.4236 48.2376 29.4616C48.5321 29.4996 48.8313 29.4529 49.1001 29.3269C49.369 29.201 49.5965 29.0011 49.7558 28.7506C49.9152 28.5001 49.9999 28.2094 50 27.9125Z"
                                                                    fill="black"
                                                                />
                                                            </svg>
                                                        </button>
                                                    </>
                                                        : "Closed" : "waiting..."}
                                                    {(i.status !== "close" && i.assignedby === sessionStorage.getItem('emp_code')) ? (
                                                        <>
                                                            <CloseTask i={i} getAllTasks={getAllEmployee} />
                                                            {/* <button
                                                            title="Close"
                                                            className="model-delete-button"
                                                            onClick={(e) => handleCompletedClose(e, i.id)}
                                                        >
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width="18"
                                                                height="18"
                                                                viewBox="0 0 18 18"
                                                                fill="none"
                                                            >
                                                                <path
                                                                    d="M13.5 4.5L4.5 13.5"
                                                                    stroke="#F72B50"
                                                                    stroke-width="2"
                                                                    stroke-linecap="round"
                                                                    stroke-linejoin="round"
                                                                />
                                                                <path
                                                                    d="M4.5 4.5L13.5 13.5"
                                                                    stroke="#F72B50"
                                                                    stroke-width="2"
                                                                    stroke-linecap="round"
                                                                    stroke-linejoin="round"
                                                                />
                                                            </svg>
                                                        </button> */}
                                                        </>) : <></>}
                                                </div>


                                            </td>
                                        </tr>
                                    </React.Fragment>
                                ))}
                        </tbody>
                    </table>
                </div >
            </>
        );
    };





export default CriticalIssues;
