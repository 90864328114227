import React, { useEffect, useState } from "react";
import { LineChart, ComposedChart, Bar, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { BASE_URL } from "../../config/axios";
import axios from "axios";




const CareerSummaryBars = () => {
    const [statsduration, setStatsDuration] = useState("2024");


    function getRandomNumber(min, max) {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }


    const [jobAppChart, setJobAppChart] = useState({});


    const getJobappdata = async () => {
        try {
            const res = await axios.get(`${BASE_URL}/jd/pendingstats/`);
            // console.log(res.data)
            setJobAppChart(res.data);
        } catch (err) {
            alert(err.message);
        }
    };

    useEffect(() => {
        getJobappdata();
    }, []);

    // const handleChange = (event) => {
    //     setSelectedOption(event.target.value);
    // };
    const data = [
        // { name: '', Pending: 0, Approved: 0, Rejected: 0 },
        {
            name: 'Resume Shortlisting',
            Pending: jobAppChart.resume_list_pending_count,
            Approved: jobAppChart.resume_list_approved_count,
            Rejected: jobAppChart.resume_list_rejected_count,
            Total: jobAppChart.resume_list_total_count
        },
        {
            name: 'Interview Schedule',
            Pending: jobAppChart.candidate_screening_pending_count,
            Approved: jobAppChart.candidate_screening_approved_count,
            Rejected: jobAppChart.candidate_screening_rejected_count,
            Total: jobAppChart.candidate_screening_total_count
        },
        // { name: 'Interviews', Pending: jobAppChart.round4_pending_count, Approved: jobAppChart.round4_true_count, Rejected: getRandomNumber(10, 90), Total: jobAppChart.round4_pending_count + jobAppChart.round4_true_count },
        {
            name: 'Onboarding',
            Pending: jobAppChart.upcoming_onboarding_status_pending,
            Approved: jobAppChart.upcoming_onboarding_status_approved,
            Rejected: jobAppChart.upcoming_onboarding_status_rejected,
            Total: jobAppChart.upcoming_onboarding_status_total
        },
        // { name: '', Pending: 0, Approved: 0, Rejected: 0 },

    ];
    // const data13 = [
    //     {
    //         name: "Max",
    //         value: jobAppChart.total,
    //         fill: "#fff",
    //     },

    //     {
    //         name: "Resume Approved",
    //         value: jobAppChart.resume_list_approved_count,
    //         fill: "#30C9C9", //teal
    //     },
    //     {
    //         name: "Resume Pending",
    //         value: jobAppChart.resume_list_pending_count,
    //         fill: "#FF747C", //red
    //     },
    //     {
    //         name: "Scheduling Completed",
    //         value: jobAppChart.candidate_screening_approved_count,
    //         fill: "#a9ed74 ", //green
    //     },
    //     {
    //         name: "Scheduling Pending",
    //         value: jobAppChart.candidate_screening_pending_count,
    //         fill: "#F7BA1E", //yellow
    //     },
    //     {
    //         name: "Interviews Completed",
    //         value: jobAppChart.round4_true_count,
    //         fill: "#165DFF", //blue
    //     },
    //     {
    //         name: "Interviews Pending ",
    //         value: jobAppChart.round4_pending_count,
    //         fill: "#ff8940 ", //orange
    //     },

    //     // fill: "#FF747C", //red
    //     // fill: "#ff8940 ", //orange
    //     // fill: "#F7BA1E", //yellow
    //     // fill: "#30C9C9", //teal
    //     // fill: "#165DFF", //blue  
    //     // fill: "#a9ed74 ", //green



    // ];

    // const Agraphdata = [
    //     {
    //         name: "Max",
    //         value: jobAppChart.total,
    //         fill: "#fff",
    //     },
    //     // {
    //     //   name: "Candidate Screening Pending",
    //     //   value: jobAppChart.candidate_screening_pending_count,
    //     //   fill: "#F7BA1E", //yellow
    //     // },
    //     {
    //         name: "Final Rounds Completed",
    //         value: jobAppChart.round4_true_count,
    //         fill: "#165DFF", //blue
    //     },
    //     // {
    //     //   name: "Final round Pending ",
    //     //   value: jobAppChart.round4_pending_count,
    //     //   fill: "#ff8940 ", //orange
    //     // },
    //     {
    //         name: "Candidate Screenings Approved",
    //         value: jobAppChart.candidate_screening_approved_count,
    //         fill: "#a9ed74 ", //green
    //     },

    //     {
    //         name: "Resume Approved",
    //         value: jobAppChart.resume_list_approved_count,
    //         fill: "#30C9C9", //teal
    //     },
    //     // {
    //     //   name: "Resume Approval Pending",
    //     //   value: jobAppChart.resume_list_pending_count,
    //     //   fill: "#FF747C", //red
    //     // },
    //     // fill: "#FF747C", //red
    //     // fill: "#ff8940 ", //orange
    //     // fill: "#F7BA1E", //yellow
    //     // fill: "#30C9C9", //green
    //     // fill: "#165DFF", //blue
    //     // fill: "#bb52c7 ", //purple



    // ];

    // const Pgraphdata = [
    //     {
    //         name: "Max",
    //         value: jobAppChart.total,
    //         fill: "#fff",
    //     },
    //     {
    //         name: "Candidate Screening Pending",
    //         value: jobAppChart.candidate_screening_pending_count,
    //         fill: "#F7BA1E", //yellow

    //     },
    //     // {
    //     //   name: "Final Rounds Completed",
    //     //   value: jobAppChart.round4_true_count,
    //     //   fill: "#165DFF", //blue
    //     // },
    //     {
    //         name: "Final round Pending ",
    //         value: jobAppChart.round4_pending_count,
    //         fill: "#ff8940 ", //orange
    //     },
    //     // {
    //     //   name: "Candidate Screenings Approved",
    //     //   value: jobAppChart.candidate_screening_approved_count,
    //     //   fill: "#a9ed74 ", //green
    //     // },

    //     // {
    //     //   name: "Resume Approved",
    //     //   value: jobAppChart.resume_list_approved_count,
    //     //   fill: "#30C9C9", //teal
    //     // },
    //     {
    //         name: "Resume Approval Pending",
    //         value: jobAppChart.resume_list_pending_count,
    //         fill: "#FF747C", //red
    //     },
    //     // fill: "#FF747C", //red
    //     // fill: "#ff8940 ", //orange
    //     // fill: "#F7BA1E", //yellow
    //     // fill: "#30C9C9", //green
    //     // fill: "#165DFF", //blue
    //     // fill: "#bb52c7 ", //purple



    // ];
    return (
        <div className="schoolperformancechart balance-cont balance-chart-conta cont-col">
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }} className="bgwhite">
                <div>
                    <div className="invoice-heading card-heading">Career Stats</div>
                </div>
                {/* <div className="dashboard-priorities-select" >
                    <select
                        value={statsduration}
                        onChange={(e) => setStatsDuration(e.target.value)}
                        className="dashboard-sectiona-selection"
                    >
                        <option value="2024">2024</option>
                        <option value="2023">2023</option>
                        <option value="2022">2022</option>
                        <option value="2021">2021</option>
                    </select>
                </div> */}
            </div>

            <ResponsiveContainer height={230} width="100%">
                <ComposedChart
                    width={100}
                    height={200}
                    data={data}
                    margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                >
                    <CartesianGrid horizontal="true" vertical="" strokeDasharray="10 6" />
                    <XAxis dataKey="name" tickMargin={10} />
                    <YAxis />
                    <Tooltip />
                    {/* <Label /> */}
                    {/* <Line legendType="dot" type="monotone" dataKey="Total" stroke="pink" strokeWidth={5} Dot={10} /> */}
                    <Legend verticalAlign='top' height={30} margin={{ left: 100 }} />
                    <Bar legendType="dot" type="monotone" dataKey="Approved" fill="#2576BC" barSize={20} radius={7} />
                    <Bar legendType="dot" type="monotone" dataKey="Pending" fill="#F7BA1E" barSize={20} radius={7} />
                    <Bar legendType="dot" type="monotone" dataKey="Rejected" fill="#FF747C" barSize={20} radius={7} />

                    {/* <Bar dataKey="Total" barSize={20} fill="#CACACA" /> */}
                    {/* <Line legendType="dot" type="monotone" dataKey="Approved" stroke="#2576BC" strokeWidth={5} />
                    <Line legendType="dot" type="monotone" dataKey="Rejected" stroke="#FF747C" strokeWidth={5} /> */}

                </ComposedChart>
            </ResponsiveContainer>
        </div>
    )
}

export default CareerSummaryBars