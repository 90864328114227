import React, { useState } from "react";

import Modal from "react-bootstrap/Modal";
const Workanniversary = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <button className="models-button model-button-leave" onClick={handleShow}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <g clip-path="url(#clip0_1415_3177)">
            <path
              d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM17 13H13V17H11V13H7V11H11V7H13V11H17V13Z"
              fill="#2576BC"
            />
          </g>
          <defs>
            <clipPath id="clip0_1415_3177">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="request-leave">
        {/* <Modal.Header closeButton>
          <Modal.Title>Request Leave</Modal.Title>
        </Modal.Header> */}
        <Modal.Body>
          <div className="parent-div">
            <div className="bdy-div">
              <h6 className="announce-heading">Work Anniversary</h6>
              <div className="flex-column">
                <label htmlFor="date" className="form-labels announce-date">
                  Date
                </label>
                <input
                  id="date"
                  type="date"
                  className="form-input form- input-background"
                />
              </div>

              <div className="flex-column">
                <label htmlFor="date" className="form-labels announce-date">
                  image
                </label>
                <input
                  id="date"
                  type="text"
                  placeholder="title"
                  className="form-input form- input-background"
                />
              </div>

              <div className="flex-column">
                <label htmlFor="date" className="form-labels announce-date">
                  Name
                </label>
                <input
                  id="date"
                  type="text"
                  placeholder="name"
                  className="form-input form- input-background"
                />
              </div>

              <div className="flex-column">
                <label htmlFor="date" className="form-labels announce-date">
                  Department
                </label>
                <input
                  id="date"
                  type="text"
                  placeholder="name"
                  className="form-input form- input-background"
                />
              </div>

              <div className="button-models">
                <button className="model-button ">Cancel</button>
                <button
                  type="submit"
                  className="model-button model-button-leave"
                >
                  Add
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Workanniversary;
