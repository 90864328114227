import React, { useState, useEffect } from "react";
import Gif from './thumbsUp.gif'


const CompletePage = () => {


    return (
        <>
            <center>  <br /><br /><br /><br /><br /><br /><br /><br /><h3>
                <br /><b>Process Completed, </b> <br />You can close the tab 👍<br /><br />
                <img style={{ borderRadius: "20px", boxShadow: "0px 1px 38px 0px rgba(0, 0, 0, 0.10)" }} src={Gif} alt="" />
                {/* <img style={{ borderRadius: "20px", boxShadow: "0px 1px 38px 0px rgba(0, 0, 0, 0.10)" }} src={Gif} alt="" /> */}
            </h3></center>

        </>
    );
};

export default CompletePage;



