import React, { useEffect, useState } from "react";
import axios from "axios";
import { AUTH, BASE_URL } from "../../config/axios";
import Modal from "react-bootstrap/Modal";
import { ModalBody } from "react-bootstrap";

const Requestapproved = ({ i, getoutdutyname }) => {
  const handleFormSubmitPut = async (e) => {
    e.preventDefault();

    try {
      let res = await axios.put(`${BASE_URL}/wfm/leavedelete/${i.id}/`, {
        rh_leave_status: "approved",
      });

      if (res.status === 200) {
        await getoutdutyname();
        // setShow(false);
      } else {
        alert(res);
      }
    } catch (err) {
      alert(err);
    }
  };

  return (
    <>
      <button className="model-edit-button" onClick={handleFormSubmitPut}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M15 4.5L6.75 12.75L3 9"
            stroke="#145650"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </button>
    </>
  );
};

const RequestDelete = ({ i, getoutdutyname }) => {
  const handleFormSubmitPut = async (e) => {
    e.preventDefault();

    try {
      let res = await axios.put(`${BASE_URL}/wfm/leavedelete/${i.id}/`, {
        rh_leave_status: "rejected",
      });

      if (res.status === 200) {
        await getoutdutyname();
        // setShow(false);
      } else {
        alert(res);
      }
    } catch (err) {
      alert(err);
    }
  };

  return (
    <>
      <button className="model-delete-button" onClick={handleFormSubmitPut}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M13.5 4.5L4.5 13.5"
            stroke="#F72B50"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M4.5 4.5L13.5 13.5"
            stroke="#F72B50"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </button>
    </>
  );
};

const RequestOutdutyapproved = ({ i, getoutdutyname }) => {
  const [loading, setLoading] = useState(false); //loading logic

  const handleFormSubmitPut = async (e) => {
    e.preventDefault();
    setLoading(true); //loading logic

    try {
      let res = await axios.put(`${BASE_URL}/wfm/outdutydelete/${i.id}/`, {
        rh_leave_status: "approved",
      });

      if (res.status === 200) {
        await getoutdutyname();
        // setShow(false);
      } else {
        alert(res);
      }
    } catch (err) {
      alert(err);
    } finally {
      setLoading(false); //loading logic
    }
  };

  return (
    <>
      <button className="model-edit-button" onClick={handleFormSubmitPut} disabled={loading} >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M15 4.5L6.75 12.75L3 9"
            stroke="#145650"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </button>
    </>
  );
};

const RequestOutdutyDelete = ({ i, getoutdutyname }) => {
  const handleFormSubmitPut = async (e) => {
    e.preventDefault();

    try {
      let res = await axios.put(`${BASE_URL}/wfm/outdutydelete/${i.id}/`, {
        rh_leave_status: "rejected",
      });

      if (res.status === 200) {
        await getoutdutyname();
        // setShow(false);
      } else {
        alert(res);
      }
    } catch (err) {
      alert(err);
    }
  };

  return (
    <>
      <button className="model-delete-button" onClick={handleFormSubmitPut}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M13.5 4.5L4.5 13.5"
            stroke="#F72B50"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M4.5 4.5L13.5 13.5"
            stroke="#F72B50"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </button>
    </>
  );
};

const RequestImprestApproved = ({ i, getImprestname }) => {
  const handleFormSubmitPut = async (e) => {
    e.preventDefault();

    try {
      let res = await axios.put(`${BASE_URL}/wfm/impreset/${i.id}/`, {
        rh_leave_status: "approved",
      });

      if (res.status === 200) {
        await getImprestname();
        // setShow(false);
      } else {
        alert(res);
      }
    } catch (err) {
      alert(err);
    }
  };

  return (
    <>
      <button className="model-edit-button" onClick={handleFormSubmitPut}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M15 4.5L6.75 12.75L3 9"
            stroke="#145650"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </button>
    </>
  );
};

const RequestImprestUpdate = ({ i, getImprestname }) => {
  const handleFormSubmitPut = async (e) => {
    e.preventDefault();

    try {
      let res = await axios.put(`${BASE_URL}/wfm/impreset/${i.id}/`, {
        rh_leave_status: "rejected",
      });

      if (res.status === 200) {
        await getImprestname();
        // setShow(false);
      } else {
        alert(res);
      }
    } catch (err) {
      alert(err);
    }
  };

  return (
    <>
      <button className="model-delete-button" onClick={handleFormSubmitPut}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M13.5 4.5L4.5 13.5"
            stroke="#F72B50"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M4.5 4.5L13.5 13.5"
            stroke="#F72B50"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </button>
    </>
  );
};

const RequestExpenseApproved = ({ i, getExpensename }) => {
  const handleFormSubmitPut = async (e) => {
    e.preventDefault();

    try {
      let res = await axios.put(`${BASE_URL}/wfm/expense/${i.id}/`, {
        rh_leave_status: "approved",
      });

      if (res.status === 200) {
        await getExpensename();
        // setShow(false);
      } else {
        alert(res);
      }
    } catch (err) {
      alert(err);
    }
  };

  return (
    <>
      <button className="model-edit-button" onClick={handleFormSubmitPut}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M15 4.5L6.75 12.75L3 9"
            stroke="#145650"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </button>
    </>
  );
};

const RequestExpenseUpdate = ({ i, getExpensename }) => {
  const handleFormSubmitPut = async (e) => {
    e.preventDefault();

    try {
      let res = await axios.put(`${BASE_URL}/wfm/expense/${i.id}/`, {
        rh_leave_status: "rejected",
      });

      if (res.status === 200) {
        await getExpensename();
        // setShow(false);
      } else {
        alert(res);
      }
    } catch (err) {
      alert(err);
    }
  };

  return (
    <>
      <button className="model-delete-button" onClick={handleFormSubmitPut}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M13.5 4.5L4.5 13.5"
            stroke="#F72B50"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M4.5 4.5L13.5 13.5"
            stroke="#F72B50"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </button>
    </>
  );
};

const HRleaveUpdate = ({ getAnnouncement }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [age, setAge] = useState("");

  const [maxEndDate, setMaxEndDate] = useState(null);

  const [dataa, setdataa] = useState({
    empcode: "",
    date: "",
    end_date: "",
    rh_leave_status: "approved",
    remarks: "",
  });

  const getEndTime = (intime) => {
    const start = new Date(`2023-03-15T${intime}`);
    const end = new Date(start.getTime() + 2 * 60 * 60 * 1000); // Add 2 hours in milliseconds
    const hh = String(end.getHours()).padStart(2, "0");
    const mm = String(end.getMinutes()).padStart(2, "0");
    const ss = String(end.getSeconds()).padStart(2, "0");

    setdataa({ ...dataa, in_time: intime, out_time: `${hh}:${mm}:${ss}` });
  };

  const date = new Date();

  const [remarksError, setRemarksError] = useState("");

  let lastDateOfPreviousMonth;
  if (date.getDate() > 30) {
    lastDateOfPreviousMonth = new Date(date.getFullYear(), date.getMonth(), 0);
  } else {
    lastDateOfPreviousMonth = new Date(
      date.getFullYear(),
      date.getMonth() - 1,
      +1
    );
  }
  const formattedLastDate = lastDateOfPreviousMonth.toISOString().substr(0, 10);

  let handleSubmit = async (e) => {
    const enteredDate = dataa.date;
    const minDate = formattedLastDate;

    if (enteredDate >= minDate) {
      e.preventDefault();

      const updatedFormData = {
        ...dataa,
        leave_Duration: age,
      };
      try {
        let res = await axios.post(
          `${BASE_URL}/wfm/leaveapply/`,
          updatedFormData
        );

        if (res.status === 200) {
          handleClose();
        } else {
          alert(res);
        }
      } catch (err) {
        alert(err);
      }
      return;
    } else {
      alert("Please select a valid date ");
      return;
    }
  };

  return (
    <>
      <button className="models-button model-add" onClick={handleShow}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <g clip-path="url(#clip0_1415_3177)">
            <path
              d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM17 13H13V17H11V13H7V11H11V7H13V11H17V13Z"
              fill="#2576BC"
            />
          </g>
          <defs>
            <clipPath id="clip0_1415_3177">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="request-leave">
        {/* <Modal.Header closeButton>
          <Modal.Title>Request Leave</Modal.Title>
        </Modal.Header> */}
        <Modal.Body>
          <div>
            <form>
              <div className="flex-column model-age">
                <div className="flex-column">
                  <label htmlFor="EmpCode" className="form-labels">
                    Emp Code
                  </label>
                  <input
                    id="EmpCode"
                    type="number"
                    className="form-input form-input-background"
                    onChange={(e) =>
                      setdataa({
                        ...dataa,
                        empcode: e.target.value,
                      })
                    }
                  />
                </div>

                <div className="leave-duration-heading">Leave Duration </div>

                <div
                  onChange={(e) => setAge(e.target.value)}
                  className="leave-radio-button"
                >
                  <div className="leave-radio-button-inner-div">
                    <input
                      name="age"
                      type="radio"
                      value="single"
                      className="age-input"
                    />
                    <label className="age-label">Single Day</label>
                  </div>
                  <div className="leave-radio-button-inner-div">
                    <input
                      name="age"
                      type="radio"
                      value="multiple"
                      className="age-input"
                    />
                    <label className="age-label">Multi Day</label>
                  </div>
                  <div className="leave-radio-button-inner-div">
                    <input
                      name="age"
                      type="radio"
                      value="halfdayleave"
                      className="age-input"
                    />
                    <label className="age-label">Half Day</label>
                  </div>
                  <div className="leave-radio-button-inner-div">
                    <input
                      name="age"
                      type="radio"
                      value="shortleave"
                      className="age-input"
                    />
                    <label className="age-label">Short Day</label>
                  </div>
                </div>
              </div>

              {age === "single" && (
                <div className="flex-column">
                  <label htmlFor="date" className="form-labels">
                    Date
                  </label>
                  <input
                    id="date"
                    type="date"
                    className="form-input form-input-background"
                    onChange={(e) =>
                      setdataa({
                        ...dataa,
                        date: e.target.value,
                        end_date: e.target.value,
                      })
                    }
                    min={formattedLastDate}
                  />
                </div>
              )}

              {age === "multiple" && (
                <div className="flex-row multi-day-input">
                  <div className="flex-column multi-day">
                    <label className="form-labels">From Date</label>
                    <input
                      type="date"
                      className="multi-day-input1 form-input-background"
                      onChange={(e) => {
                        const startDate = e.target.value;
                        const lastDate = new Date(
                          new Date(startDate).getFullYear(),
                          new Date(startDate).getMonth() + 1,
                          1
                        )
                          .toISOString()
                          .split("T")[0];
                        setdataa({
                          ...dataa,
                          date: startDate,
                        });
                        setMaxEndDate(lastDate);
                      }}
                      min={formattedLastDate}
                    />
                  </div>
                  <div className="flex-column multi-day">
                    <label className="form-labels">To Date</label>
                    <input
                      type="date"
                      className="multi-day-input1 form-input-background"
                      onChange={(e) =>
                        setdataa({ ...dataa, end_date: e.target.value })
                      }
                      min={dataa.date}
                      max={maxEndDate}
                    />
                  </div>
                </div>
              )}

              {age === "halfdayleave" && (
                <div>
                  <div className="flex-column single-day-date">
                    <label className="form-labels">Date</label>
                    <input
                      type="date"
                      className="form-input form-input-background"
                      onChange={(e) =>
                        setdataa({
                          ...dataa,
                          date: e.target.value,
                          end_date: e.target.value,
                        })
                      }
                      min={formattedLastDate}
                    />
                  </div>
                  <div required className="leave-radio-button">
                    <div className="leave-radio-button-inner-div">
                      <input
                        name="halfday"
                        type="radio"
                        value="firsthalft"
                        className="age-input"
                      />
                      <label className="age-label">First Half</label>
                    </div>
                    <div className="leave-radio-button-inner-div">
                      <input
                        name="halfday"
                        type="radio"
                        value="secondhalft"
                        className="age-input"
                      />
                      <label className="age-label">Second Half</label>
                    </div>
                  </div>
                </div>
              )}

              {age === "shortleave" && (
                <div>
                  <div className="flex-column single-day-date">
                    <label className="form-labels">Date</label>
                    <input
                      type="date"
                      className="form-input form-input-background"
                      onChange={(e) =>
                        setdataa({
                          ...dataa,
                          date: e.target.value,
                          end_date: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="flex-row multi-day-input">
                    <div className="flex-column multi-day">
                      <label className="form-labels">Start Time</label>
                      <input
                        className="multi-day-input1 form-input-background"
                        type="time"
                        step="1"
                        min="09:30:00"
                        max="16:30:00"
                        onChange={(e) => getEndTime(e.target.value)}
                      />
                    </div>
                    <div className="flex-column multi-day">
                      <label className="form-labels">End Time</label>
                      <input
                        className="multi-day-input1 form-input-background"
                        type="time"
                        step="1"
                        min="09:30:00"
                        max="16:30:00"
                        value={dataa.out_time}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
              )}

              <div className="flex-column">
                <label className="form-labels">Notes</label>
                <textarea
                  className="form-input-textarea form-input-background"
                  placeholder="Reason"
                  type="text"
                  value={dataa.text}
                  onChange={(e) => {
                    if (e.target.value.length <= 50) {
                      setdataa({ ...dataa, remarks: e.target.value });
                      setRemarksError(""); // Clear error message if within the limit
                    } else {
                      setRemarksError(
                        "Ensure this field has no more than 50 characters."
                      );
                    }
                  }}
                />

                {remarksError && (
                  <div style={{ color: "red", marginTop: "5px" }}>
                    {remarksError}
                  </div>
                )}
              </div>
              <div className="button-models">
                <button className="model-button ">Cancel</button>
                <button
                  className="model-button model-button-leave"
                  onClick={handleSubmit}
                >
                  Leave
                </button>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

const HROutdutyUpdate = ({ getoutdutyname }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [age, setAge] = useState("");

  const [maxEndDate, setMaxEndDate] = useState(null);

  const [dataa, setdataa] = useState({
    empcode: "",
    date: "",
    end_date: "",
    rh_leave_status: "approved",
    remarks: "",
  });

  const getEndTime = (intime) => {
    const start = new Date(`2023-03-15T${intime}`);
    const end = new Date(start.getTime() + 2 * 60 * 60 * 1000); // Add 2 hours in milliseconds
    const hh = String(end.getHours()).padStart(2, "0");
    const mm = String(end.getMinutes()).padStart(2, "0");
    const ss = String(end.getSeconds()).padStart(2, "0");

    setdataa({ ...dataa, in_time: intime, out_time: `${hh}:${mm}:${ss}` });
  };

  const date = new Date();

  const [remarksError, setRemarksError] = useState("");

  let lastDateOfPreviousMonth;
  if (date.getDate() > 30) {
    lastDateOfPreviousMonth = new Date(date.getFullYear(), date.getMonth(), 0);
  } else {
    lastDateOfPreviousMonth = new Date(
      date.getFullYear(),
      date.getMonth() - 1,
      +1
    );
  }
  const formattedLastDate = lastDateOfPreviousMonth.toISOString().substr(0, 10);

  let handleSubmit = async (e) => {
    const enteredDate = dataa.date;
    const minDate = formattedLastDate;

    if (enteredDate >= minDate) {
      e.preventDefault();

      const updatedFormData = {
        ...dataa,
        outduty_Duration: age,
      };
      try {
        let res = await axios.post(`${BASE_URL}/wfm/outduty/`, updatedFormData);

        if (res.status === 200) {
          handleClose();
          await getoutdutyname();
        } else {
          alert(res);
        }
      } catch (err) {
        alert(err);
      }
      return;
    } else {
      alert("Please select a valid date ");
      return;
    }
  };

  return (
    <>
      <button className="models-button model-add" onClick={handleShow}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <g clip-path="url(#clip0_1415_3177)">
            <path
              d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM17 13H13V17H11V13H7V11H11V7H13V11H17V13Z"
              fill="#2576BC"
            />
          </g>
          <defs>
            <clipPath id="clip0_1415_3177">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="request-leave">
        {/* <Modal.Header closeButton>
          <Modal.Title>Request Leave</Modal.Title>
        </Modal.Header> */}
        <Modal.Body>
          <div>
            <form>
              <div className="flex-column model-age">
                <div className="flex-column">
                  <label htmlFor="EmpCode" className="form-labels">
                    Emp Code
                  </label>
                  <input
                    id="EmpCode"
                    type="number"
                    className="form-input form-input-background"
                    onChange={(e) =>
                      setdataa({
                        ...dataa,
                        empcode: e.target.value,
                      })
                    }
                  />
                </div>

                <div className="leave-duration-heading">OutDuty Duration </div>

                <div
                  onChange={(e) => setAge(e.target.value)}
                  className="leave-radio-button"
                >
                  <div className="leave-radio-button-inner-div">
                    <input
                      name="age"
                      type="radio"
                      value="single"
                      className="age-input"
                    />
                    <label className="age-label">Single Day</label>
                  </div>
                  <div className="leave-radio-button-inner-div">
                    <input
                      name="age"
                      type="radio"
                      value="multiple"
                      className="age-input"
                    />
                    <label className="age-label">Multi Day</label>
                  </div>
                  <div className="leave-radio-button-inner-div">
                    <input
                      name="age"
                      type="radio"
                      value="halfdayleave"
                      className="age-input"
                    />
                    <label className="age-label">Half Day</label>
                  </div>
                  <div className="leave-radio-button-inner-div">
                    <input
                      name="age"
                      type="radio"
                      value="shortleave"
                      className="age-input"
                    />
                    <label className="age-label">Short Day</label>
                  </div>
                </div>
              </div>

              {age === "single" && (
                <div className="flex-column">
                  <label htmlFor="date" className="form-labels">
                    Date
                  </label>
                  <input
                    id="date"
                    type="date"
                    className="form-input form-input-background"
                    onChange={(e) =>
                      setdataa({
                        ...dataa,
                        date: e.target.value,
                        end_date: e.target.value,
                      })
                    }
                    min={formattedLastDate}
                  />
                </div>
              )}

              {age === "multiple" && (
                <div className="flex-row multi-day-input">
                  <div className="flex-column multi-day">
                    <label className="form-labels">From Date</label>
                    <input
                      type="date"
                      className="multi-day-input1 form-input-background"
                      onChange={(e) => {
                        const startDate = e.target.value;
                        const lastDate = new Date(
                          new Date(startDate).getFullYear(),
                          new Date(startDate).getMonth() + 1,
                          1
                        )
                          .toISOString()
                          .split("T")[0];
                        setdataa({
                          ...dataa,
                          date: startDate,
                        });
                        setMaxEndDate(lastDate);
                      }}
                      min={formattedLastDate}
                    />
                  </div>
                  <div className="flex-column multi-day">
                    <label className="form-labels">To Date</label>
                    <input
                      type="date"
                      className="multi-day-input1 form-input-background"
                      onChange={(e) =>
                        setdataa({ ...dataa, end_date: e.target.value })
                      }
                      min={dataa.date}
                      max={maxEndDate}
                    />
                  </div>
                </div>
              )}

              {age === "halfdayleave" && (
                <div>
                  <div className="flex-column single-day-date">
                    <label className="form-labels">Date</label>
                    <input
                      type="date"
                      className="form-input form-input-background"
                      onChange={(e) =>
                        setdataa({
                          ...dataa,
                          date: e.target.value,
                          end_date: e.target.value,
                        })
                      }
                      min={formattedLastDate}
                    />
                  </div>
                  <div required className="leave-radio-button">
                    <div className="leave-radio-button-inner-div">
                      <input
                        name="halfday"
                        type="radio"
                        value="firsthalft"
                        className="age-input"
                      />
                      <label className="age-label">First Half</label>
                    </div>
                    <div className="leave-radio-button-inner-div">
                      <input
                        name="halfday"
                        type="radio"
                        value="secondhalft"
                        className="age-input"
                      />
                      <label className="age-label">Second Half</label>
                    </div>
                  </div>
                </div>
              )}

              {age === "shortleave" && (
                <div>
                  <div className="flex-column single-day-date">
                    <label className="form-labels">Date</label>
                    <input
                      type="date"
                      className="form-input form-input-background"
                      onChange={(e) =>
                        setdataa({
                          ...dataa,
                          date: e.target.value,
                          end_date: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="flex-row multi-day-input">
                    <div className="flex-column multi-day">
                      <label className="form-labels">Start Time</label>
                      <input
                        className="multi-day-input1 form-input-background"
                        type="time"
                        step="1"
                        min="09:30:00"
                        max="16:30:00"
                        onChange={(e) => getEndTime(e.target.value)}
                      />
                    </div>
                    <div className="flex-column multi-day">
                      <label className="form-labels">End Time</label>
                      <input
                        className="multi-day-input1 form-input-background"
                        type="time"
                        step="1"
                        min="09:30:00"
                        max="16:30:00"
                        value={dataa.out_time}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
              )}

              <div className="flex-column">
                <label className="form-labels">Notes</label>
                <textarea
                  className="form-input-textarea form-input-background"
                  placeholder="Reason"
                  type="text"
                  value={dataa.text}
                  onChange={(e) => {
                    if (e.target.value.length <= 50) {
                      setdataa({ ...dataa, remarks: e.target.value });
                      setRemarksError(""); // Clear error message if within the limit
                    } else {
                      setRemarksError(
                        "Ensure this field has no more than 50 characters."
                      );
                    }
                  }}
                />

                {remarksError && (
                  <div style={{ color: "red", marginTop: "5px" }}>
                    {remarksError}
                  </div>
                )}
              </div>
              <div className="button-models">
                <button className="model-button ">Cancel</button>
                <button
                  className="model-button model-button-leave"
                  onClick={handleSubmit}
                >
                  Outduty
                </button>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

const AdminRequestapproved = ({ i, getoutdutyname }) => {
  const [loading, setLoading] = useState(false); //loading logic

  const handleFormSubmitPut = async (e) => {
    e.preventDefault();
    setLoading(true); //loading logic

    try {
      let res = await axios.put(`${BASE_URL}/wfm/adminleaveapproval/${i.id}/`, {
        admin_leave_status: "approved",
      }, AUTH);

      if (res.status === 200) {
        await getoutdutyname();
        // setShow(false);
      } else {
        alert(res);
      }
    } catch (err) {
      console.log(err)
      alert(err.response.data);
    } finally {
      setLoading(false); // Ensure loading state is stopped even in case of error //loading logic
    }
  };

  return (
    <>
      <button className="model-edit-button" title='Approve Leave' onClick={handleFormSubmitPut} disabled={loading} >{/* //loading logic */}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M15 4.5L6.75 12.75L3 9"
            stroke="#145650"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </button>
    </>
  );
};

const AdminRequestDelete = ({ i, getoutdutyname }) => {
  const handleFormSubmitPut = async (e) => {
    e.preventDefault();

    try {
      let res = await axios.put(`${BASE_URL}/wfm/leaverhput/${i.id}/`, {
        admin_leave_status: "rejected",
      });

      if (res.status === 200) {
        await getoutdutyname();
        // setShow(false);
      } else {
        alert(res);
      }
    } catch (err) {
      alert(err);
    }
  };

  return (
    <>
      <button className="model-delete-button" title='Reject Leave' onClick={handleFormSubmitPut}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M13.5 4.5L4.5 13.5"
            stroke="#F72B50"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M4.5 4.5L13.5 13.5"
            stroke="#F72B50"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </button>
    </>
  );
};

const AdminOutdutyRequestapproved = ({ i, getoutdutyname }) => {
  const [loading, setLoading] = useState(false); //loading logic
  const handleFormSubmitPut = async (e) => {
    e.preventDefault();
    setLoading(true); //loading logic

    try {
      let res = await axios.put(`${BASE_URL}/wfm/adminoutdutyapproval/${i.id}/`, {
        admin_leave_status: "approved",
      }, AUTH);

      if (res.status === 200) {
        await getoutdutyname();
        // setShow(false);
      } else {
        alert(res);
      }
    } catch (err) {
      alert(err);
    } finally {
      setLoading(false); //loading logic
    }
  };

  return (
    <>
      <button className="model-edit-button" onClick={handleFormSubmitPut} disabled={loading}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M15 4.5L6.75 12.75L3 9"
            stroke="#145650"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </button>
    </>
  );
};

const AdminOutdutyRequestDelete = ({ i, getoutdutyname }) => {
  const handleFormSubmitPut = async (e) => {
    e.preventDefault();

    try {
      let res = await axios.put(`${BASE_URL}/wfm/odrhput/${i.id}/`, {
        admin_leave_status: "rejected",
      });

      if (res.status === 200) {
        await getoutdutyname();
        // setShow(false);
      } else {
        alert(res);
      }
    } catch (err) {
      alert(err);
    }
  };

  return (
    <>
      <button className="model-delete-button" onClick={handleFormSubmitPut}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M13.5 4.5L4.5 13.5"
            stroke="#F72B50"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M4.5 4.5L13.5 13.5"
            stroke="#F72B50"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </button>
    </>
  );
};

const AdminapprovedHR = ({ i, getImprestname }) => {
  const handleFormSubmitPut = async (e) => {
    e.preventDefault();

    try {
      let res = await axios.put(`${BASE_URL}/wfm/impreset/${i.id}/`, {
        admin_leave_status: "approved",
      }, AUTH);

      if (res.status === 200) {
        await getImprestname();
        // setShow(false);
      } else {
        alert(res);
      }
    } catch (err) {
      alert(err);
    }
  };

  return (
    <>
      <button className="model-edit-button" onClick={handleFormSubmitPut}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M15 4.5L6.75 12.75L3 9"
            stroke="#145650"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </button>
    </>
  );
};

const AdminDeleteHR = ({ i, getImprestname }) => {
  const handleFormSubmitPut = async (e) => {
    e.preventDefault();

    try {
      let res = await axios.put(`${BASE_URL}/wfm/impreset/${i.id}/`, {
        admin_leave_status: "rejected",
      });

      if (res.status === 200) {
        await getImprestname();
        // setShow(false);
      } else {
        alert(res);
      }
    } catch (err) {
      alert(err);
    }
  };

  return (
    <>
      <button className="model-delete-button" onClick={handleFormSubmitPut}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M13.5 4.5L4.5 13.5"
            stroke="#F72B50"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M4.5 4.5L13.5 13.5"
            stroke="#F72B50"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </button>
    </>
  );
};


const CompoffShortLeaveApproved = ({ i, getCompensatoryList }) => {
  const handleFormSubmitPut = async (e) => {
    e.preventDefault();

    try {
      let res = await axios.get(`${BASE_URL}/wfm/shortleaveandcompoffbyID/${i.id}/`);


      if (res.status === 200) {
        await getCompensatoryList();
        // setShow(false);
      } else {
        alert(res);
      }
    } catch (err) {
      alert(err);
    }
  };

  return (
    <>
      <button className="model-edit-button" title="CompOff TL Approve" onClick={handleFormSubmitPut}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M15 4.5L6.75 12.75L3 9"
            stroke="#145650"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </button>
    </>
  );
};

const CompoffShortLeaveReject = ({ i, getCompensatoryList }) => {
  const handleFormSubmitPut = async (e) => {
    e.preventDefault();

    try {
      let res = await axios.get(`${BASE_URL}/wfm/shortleaveandcompoffbyIDrejected/${i.id}/`);

      if (res.status === 200) {
        await getCompensatoryList();
        // setShow(false);
      } else {
        alert(res);
      }
    } catch (err) {
      alert(err);
    }
  };

  return (
    <>
      <button className="model-delete-button" title="CompOff TL Reject" onClick={handleFormSubmitPut}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M13.5 4.5L4.5 13.5"
            stroke="#F72B50"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M4.5 4.5L13.5 13.5"
            stroke="#F72B50"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </button>
    </>
  );
};


const ExpenseapprovedHR = ({ i, getexpansename }) => {
  const handleFormSubmitPut = async (e) => {
    e.preventDefault();

    try {
      let res = await axios.put(`${BASE_URL}/wfm/expense/${i.id}/`, {
        admin_leave_status: "approved",
      }, AUTH);

      if (res.status === 200) {
        await getexpansename();
        // setShow(false);
      } else {
        alert(res);
      }
    } catch (err) {
      alert(err);
    }
  };

  return (
    <>
      <button className="model-edit-button" onClick={handleFormSubmitPut}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M15 4.5L6.75 12.75L3 9"
            stroke="#145650"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </button>
    </>
  );
};

const ExpenseDeleteHR = ({ i, getexpansename }) => {
  const handleFormSubmitPut = async (e) => {
    e.preventDefault();

    try {
      let res = await axios.put(`${BASE_URL}/wfm/expense/${i.id}/`, {
        admin_leave_status: "rejected",
      });

      if (res.status === 200) {
        await getexpansename();
        // setShow(false);
      } else {
        alert(res);
      }
    } catch (err) {
      alert(err);
    }
  };

  return (
    <>
      <button className="model-delete-button" onClick={handleFormSubmitPut}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M13.5 4.5L4.5 13.5"
            stroke="#F72B50"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M4.5 4.5L13.5 13.5"
            stroke="#F72B50"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </button>
    </>
  );
};

const ImprestApprovedAccount = ({ i, getImprestname }) => {
  const handleFormSubmitPut = async (e) => {
    e.preventDefault();

    try {
      let res = await axios.put(`${BASE_URL}/wfm/impreset/${i.id}/`, {
        account_leave_status: "approved",
      }, AUTH);

      if (res.status === 200) {
        await getImprestname();
        // setShow(false);
      } else {
        alert(res);
      }
    } catch (err) {
      alert(err);
    }
  };

  return (
    <>
      <button className="model-edit-button" onClick={handleFormSubmitPut}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M15 4.5L6.75 12.75L3 9"
            stroke="#145650"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </button>
    </>
  );
};

const ImprestDeleteAccount = ({ i, getImprestname }) => {
  const handleFormSubmitPut = async (e) => {
    e.preventDefault();

    try {
      let res = await axios.put(`${BASE_URL}/wfm/impreset/${i.id}/`, {
        account_leave_status: "rejected",
      });

      if (res.status === 200) {
        await getImprestname();
        // setShow(false);
      } else {
        alert(res);
      }
    } catch (err) {
      alert(err);
    }
  };

  return (
    <>
      <button className="model-delete-button" onClick={handleFormSubmitPut}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M13.5 4.5L4.5 13.5"
            stroke="#F72B50"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M4.5 4.5L13.5 13.5"
            stroke="#F72B50"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </button>
    </>
  );
};

const ExpenseApprovedAccount = ({ i, getexpansename }) => {
  const handleFormSubmitPut = async (e) => {
    e.preventDefault();

    try {
      let res = await axios.put(`${BASE_URL}/wfm/expense/${i.id}/`, {
        account_leave_status: "approved",
      }, AUTH);

      if (res.status === 200) {
        await getexpansename();
        // setShow(false);
      } else {
        alert(res);
      }
    } catch (err) {
      alert(err);
    }
  };

  return (
    <>
      <button className="model-edit-button" onClick={handleFormSubmitPut}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M15 4.5L6.75 12.75L3 9"
            stroke="#145650"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </button>
    </>
  );
};

const ExpenseDeleteAccount = ({ i, getexpansename }) => {
  const handleFormSubmitPut = async (e) => {
    e.preventDefault();

    try {
      let res = await axios.put(`${BASE_URL}/wfm/expense/${i.id}/`, {
        account_leave_status: "rejected",
      });

      if (res.status === 200) {
        await getexpansename();
        // setShow(false);
      } else {
        alert(res);
      }
    } catch (err) {
      alert(err);
    }
  };

  return (
    <>
      <button className="model-delete-button" onClick={handleFormSubmitPut}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M13.5 4.5L4.5 13.5"
            stroke="#F72B50"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M4.5 4.5L13.5 13.5"
            stroke="#F72B50"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </button>
    </>
  );
};


const RequisitionApprovedRH = ({ i, getRequisitions }) => {
  const handleFormSubmitPut = async (e) => {
    e.preventDefault();

    try {
      let res = await axios.put(`${BASE_URL}/jd/requisition/${i.id}/`, {
        rh_status: "approved",
      });

      if (res.status === 200) {
        await getRequisitions();
        // setShow(false);
      } else {
        alert(res);
      }
    } catch (err) {
      alert(err);
    }
  };

  return (
    <>
      <button className="model-edit-button" title="Approve Request" onClick={handleFormSubmitPut}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M15 4.5L6.75 12.75L3 9"
            stroke="#145650"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </button>
    </>
  );
};

const RequisitionRejectRH = ({ i, getRequisitions }) => {
  const handleFormSubmitPut = async (e) => {
    e.preventDefault();

    try {
      let res = await axios.put(`${BASE_URL}/jd/requisition/${i.id}/`, {
        rh_status: "rejected",
      });

      if (res.status === 200) {
        await getRequisitions();
        // setShow(false);
      } else {
        alert(res);
      }
    } catch (err) {
      alert(err);
    }
  };

  return (
    <>
      <button className="model-delete-button" title="Reject Request" onClick={handleFormSubmitPut}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M13.5 4.5L4.5 13.5"
            stroke="#F72B50"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M4.5 4.5L13.5 13.5"
            stroke="#F72B50"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </button>
    </>
  );
};

const RequisitionApprovedAdmin = ({ i, getRequisitions }) => {
  const handleFormSubmitPut = async (e) => {
    e.preventDefault();

    try {
      let res = await axios.put(`${BASE_URL}/jd/requisition/${i.id}/`, {
        admin_status: "approved",
      });

      if (res.status === 200) {
        await getRequisitions();
        // setShow(false);
      } else {
        alert(res);
      }
    } catch (err) {
      alert(err);
    }
  };

  return (
    <>
      <button className="model-edit-button" title="Approve Request" onClick={handleFormSubmitPut}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M15 4.5L6.75 12.75L3 9"
            stroke="#145650"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </button>
    </>
  );
};

const RequisitionRejectAdmin = ({ i, getRequisitions }) => {
  const handleFormSubmitPut = async (e) => {
    e.preventDefault();

    try {
      let res = await axios.put(`${BASE_URL}/jd/requisition/${i.id}/`, {
        admin_status: "rejected",
      });

      if (res.status === 200) {
        await getRequisitions();
        // setShow(false);
      } else {
        alert(res);
      }
    } catch (err) {
      alert(err);
    }
  };

  return (
    <>
      <button className="model-delete-button" title="Reject Request" onClick={handleFormSubmitPut}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M13.5 4.5L4.5 13.5"
            stroke="#F72B50"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M4.5 4.5L13.5 13.5"
            stroke="#F72B50"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </button>
    </>
  );
};

const RequisitionApprovedTLAdmin = ({ i, getRequisitions }) => {
  const handleFormSubmitPut = async (e) => {
    e.preventDefault();

    try {
      let res = await axios.put(`${BASE_URL}/jd/requisition/${i.id}/`, {
        rh_status: "approved",
        admin_status: "approved",
      });

      if (res.status === 200) {
        await getRequisitions();
        // setShow(false);
      } else {
        alert(res);
      }
    } catch (err) {
      alert(err);
    }
  };

  return (
    <>
      <button className="model-edit-button" title="Approve Requests" onClick={handleFormSubmitPut}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M15 4.5L6.75 12.75L3 9"
            stroke="#145650"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </button>
    </>
  );
};

const RequisitionRejectTLAdmin = ({ i, getRequisitions }) => {
  const handleFormSubmitPut = async (e) => {
    e.preventDefault();

    try {
      let res = await axios.put(`${BASE_URL}/jd/requisition/${i.id}/`, {
        rh_status: "rejected",
        admin_status: "rejected",
      });

      if (res.status === 200) {
        await getRequisitions();
        // setShow(false);
      } else {
        alert(res);
      }
    } catch (err) {
      alert(err);
    }
  };

  return (
    <>
      <button className="model-delete-button" title="Reject Requests" onClick={handleFormSubmitPut}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M13.5 4.5L4.5 13.5"
            stroke="#F72B50"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M4.5 4.5L13.5 13.5"
            stroke="#F72B50"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </button>
    </>
  );
};

const RequisitionApprovedHR = ({ i, getRequisitions }) => {
  const handleFormSubmitPut = async (e) => {
    e.preventDefault();

    try {
      let res = await axios.put(`${BASE_URL}/jd/requisition/${i.id}/`, {
        hr_status: "approved",
      });

      if (res.status === 200) {
        await getRequisitions();
        // setShow(false);
      } else {
        alert(res);
      }
    } catch (err) {
      alert(err);
    }
  };

  return (
    <>
      <button className="model-edit-button" title="Approve Request" onClick={handleFormSubmitPut}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M15 4.5L6.75 12.75L3 9"
            stroke="#145650"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </button>
    </>
  );
};

const RequisitionRejectHR = ({ i, getRequisitions }) => {
  const handleFormSubmitPut = async (e) => {
    e.preventDefault();

    try {
      let res = await axios.put(`${BASE_URL}/jd/requisition/${i.id}/`, {
        hr_status: "rejected",
      });

      if (res.status === 200) {
        await getRequisitions();
        // setShow(false);
      } else {
        alert(res);
      }
    } catch (err) {
      alert(err);
    }
  };

  return (
    <>
      <button className="model-delete-button" title="Reject Request" onClick={handleFormSubmitPut}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M13.5 4.5L4.5 13.5"
            stroke="#F72B50"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M4.5 4.5L13.5 13.5"
            stroke="#F72B50"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </button>
    </>
  );
};

const ClockInApprovedTL = ({ i, getClockInList }) => {
  const handleFormSubmitPut = async (e) => {
    e.preventDefault();

    try {
      // let res = await axios.get(`${BASE_URL}/wfm/clockIbyID/${i.id}/`);
      let res = await axios.put(`${BASE_URL}/wfm/clockinrequestapproved/${i.id}/`, {
        rh_leave_status: "approved",
        admin_leave_status: "approved",
      });

      if (res.status === 200) {
        await getClockInList();
        // setShow(false);
      } else {
        alert(res);
      }
    } catch (err) {
      alert(err);
    }
  };

  return (
    <>
      <button className="model-edit-button" title="Clockin TL Approve" onClick={handleFormSubmitPut}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          fill="none"
          viewBox="0 0 18 18"
        >
          <path
            d="M15 4.5L6.75 12.75L3 9"
            stroke="#145650"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </button>
    </>
  );
};

const ClockInRejectTL = ({ i, getClockInList }) => {
  const handleFormSubmitPut = async (e) => {
    e.preventDefault();

    try {
      // let res = await axios.get(`${BASE_URL}/wfm/clockIbyIDrejected/${i.id}/`);
      let res = await axios.put(`${BASE_URL}/wfm/clockinrequestupdate/${i.id}/`, {
        rh_leave_status: "rejected",
        admin_leave_status: "rejected",
      });
      if (res.status === 200) {
        await getClockInList();
        // setShow(false);
      } else {
        alert(res);
      }
    } catch (err) {
      alert(err);
    }
  };

  return (
    <>
      <button className="model-delete-button" title="Clockin TL Reject" onClick={handleFormSubmitPut}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M13.5 4.5L4.5 13.5"
            stroke="#F72B50"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M4.5 4.5L13.5 13.5"
            stroke="#F72B50"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </button>
    </>
  );
};

const ClockInApprovedHR = ({ i, getClockInList }) => {
  const handleFormSubmitPut = async (e) => {
    e.preventDefault();

    try {
      // let res = await axios.get(`${BASE_URL}/wfm/clockIbyID/${i.id}/`);

      let res = await axios.put(`${BASE_URL}/wfm/clockIbyID/${i.id}/`, {
        hr_leave_status: "approved",
      });
      if (res.status === 200) {
        await getClockInList();
        // setShow(false);
      } else {
        alert(res);
      }
    } catch (err) {
      alert(err);
    }
  };

  return (
    <>
      <button className="model-edit-button" onClick={handleFormSubmitPut}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M15 4.5L6.75 12.75L3 9"
            stroke="#145650"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </button>
    </>
  );
};

const ClockInRejectHR = ({ i, getClockInList }) => {
  const handleFormSubmitPut = async (e) => {
    e.preventDefault();

    try {
      // let res = await axios.get(`${BASE_URL}/wfm/clockIbyIDrejected/${i.id}/`);
      let res = await axios.put(`${BASE_URL}/wfm/clockIbyIDrejected/${i.id}/`, {
        hr_leave_status: "rejected",
      });
      if (res.status === 200) {
        await getClockInList();
        // setShow(false);
      } else {
        alert(res);
      }
    } catch (err) {
      alert(err);
    }
  };

  return (
    <>
      <button className="model-delete-button" onClick={handleFormSubmitPut}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M13.5 4.5L4.5 13.5"
            stroke="#F72B50"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M4.5 4.5L13.5 13.5"
            stroke="#F72B50"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </button>
    </>
  );
};

export {
  Requestapproved,
  RequestDelete,
  RequestOutdutyapproved,
  RequestOutdutyDelete,
  HRleaveUpdate,
  HROutdutyUpdate,
  RequestImprestApproved,
  RequestImprestUpdate,
  RequestExpenseApproved,
  RequestExpenseUpdate,
  AdminRequestapproved,
  AdminRequestDelete,
  AdminOutdutyRequestapproved,
  AdminOutdutyRequestDelete,
  AdminapprovedHR,
  AdminDeleteHR,
  CompoffShortLeaveApproved,
  CompoffShortLeaveReject,
  ExpenseapprovedHR,
  ExpenseDeleteHR,
  ImprestApprovedAccount,
  ImprestDeleteAccount,
  ExpenseApprovedAccount,
  ExpenseDeleteAccount,
  RequisitionApprovedRH,
  RequisitionRejectRH,
  RequisitionApprovedAdmin,
  RequisitionRejectAdmin,
  RequisitionApprovedTLAdmin,
  RequisitionRejectTLAdmin,
  RequisitionApprovedHR,
  RequisitionRejectHR,
  ClockInApprovedTL,
  ClockInRejectTL,
  ClockInApprovedHR,
  ClockInRejectHR,
};
