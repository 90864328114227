import axios from "axios";
import React, { useEffect, useState } from "react";
import { PieChart, Pie, Sector, Cell } from "recharts";
import { datearray } from "../Date";
import { BASE_URL } from "../../config/axios";


const TaskStatsArc = () => {



    const [selectedOption, setSelectedOption] = useState('today');

    const [attendancearc, setAttendancearc] = useState({});


    const getJobapppdata = async (selectedOption) => {
        try {
            const res = await axios.get(`${BASE_URL}/wfm/pendingitems/${sessionStorage.getItem('emp_code')}/`

            );
            setAttendancearc(res.data);
        } catch (err) {
            alert(err.message);
        }
    };

    useEffect(() => {
        getJobapppdata(selectedOption);
    }, [selectedOption]);

    const handleChange = (event) => {
        setSelectedOption(event.target.value);
    };


    const data = [
        { name: "Completed", value: attendancearc.tasks_count_total - attendancearc.tasks - attendancearc.tasks_close, fill: "#06AD06" },
        { name: "Pending", value: attendancearc.tasks, fill: "#F7BA1E" },
        { name: "Closed", value: attendancearc.tasks_close, fill: "#FF747C" },
    ];


    const presentpercentage = Math.floor(attendancearc.present_count / attendancearc.total * 100)

    const AbsentCOLOURS = ["#FF747C", "#EFEFEF"];

    const LeaveCOLOURS = ["#F7BA1E", "#EFEFEF"];

    const PresentCOLORS = ["#06AD06", "#EFEFEF"];


    return (
        <>
            <div className="">


                <div>
                    <PieChart width={350} height={250} >
                        {/* <Pie
                                    cx={105}
                                    cy={120}
                                    startAngle={180}
                                    endAngle={0}
                                    innerRadius={60}
                                    cornerRadius={20}
                                    outerRadius={90}
                                    data={data}
                                    dataKey="value"
                                    // fill="fill"
                                    paddingAngle={3}
                                >
                                    {data.map((entry, index) => (
                                        <Cell
                                            key={`cell-${index}`}
                                            fill={PresentCOLORS[index % PresentCOLORS.length]}
                                        />
                                    ))}
                                </Pie> */}
                        <Pie
                            dataKey="value"
                            startAngle={180}
                            endAngle={0}
                            data={data}
                            cx={190}
                            cy={180}
                            innerRadius={110}
                            outerRadius={150}
                            cornerRadius={10}
                            fill="#8884d8"
                            label
                        />
                        <text x={190} y={130} dy={8} textAnchor="middle" className="task-Tab-heading">
                            Total Task:
                        </text>
                        <text x={190} y={160} dy={8} textAnchor="middle" className="task-Tab-heading">

                            {attendancearc.tasks_count_total}
                        </text>
                    </PieChart>
                </div>
                {/* <div className="hr-piechart-heading">
                            <div 
                                className="hr-piechart-dot"
                                style={{ backgroundColor: "#06AD06" }}
                            ></div>
                            <div className="hr-piechart-subheading">Present Employee</div>
                        </div> */}
                {/* <div className="hr-piechart-percentage">{presentpercentage} % ({attendancearc.present_count})</div> */}



            </div>
        </>
    );
};

export default TaskStatsArc;
