import axios from "axios";
import React, { useEffect, useState } from "react";
import { PieChart, Pie, Cell } from "recharts";
import { datearray } from "../Date";
import { BASE_URL } from "../../config/axios";





const EmployeeData = () => {

  const today = new Date();
  const currentYear = today.getFullYear();
  const currentMonth = (today.getMonth() + 1).toString().padStart(2, '0');


  let previousYear = currentYear;
  let previousMonth = today.getMonth();




  if (previousMonth === 0) {
    previousYear--;
    previousMonth = 12;
  }


  const [selectedOption, setSelectedOption] = useState('this month');

  const [empData, setEmpData] = useState({});

  const getJobapppdata = async (selectedOption) => {
    try {
      const res = await axios.get(
        selectedOption === "this month"
          ? `${BASE_URL}/wfm/employeestatus/${currentYear}/${currentMonth}/`
          : `${BASE_URL}/wfm/employeestatus/${previousYear}/${previousMonth}/`
      );

      setEmpData(res.data);
    } catch (err) {
      alert(err.message);
    }
  };

  useEffect(() => {
    getJobapppdata(selectedOption);
  }, [selectedOption]);

  const handleChange = (event) => {
    setSelectedOption(event.target.value);
  };



  return (
    <>
      <div className="empDataa">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div className="repo-heading-a">Employees data</div>
          <div className="radialchart-dropdown">
            <select style={{ border: "none" }} onChange={handleChange} value={selectedOption}>
              <option value="this month">This Month</option>
              <option value="last month">Last Month</option>
            </select>
          </div>
        </div>

        <div className="empData-felx">
          <div className="empData-dashboard">
            <div>
              <div className="empData-tab Color-tab-blue">
                <div className="empData-Tab-heading">Total Employees</div>
                <div className="empData-Tab-value">{empData.total_employees_count}</div>
              </div>
            </div>
          </div>

          <div className="empData-dashboard">
            <div>
              <div className="empData-tab Color-tab-green">
                <div className="empData-Tab-heading">Active Employees</div>
                <div className="empData-Tab-value">{empData.active_employees_count}</div>
              </div>
            </div>
          </div>

          <div className="empData-dashboard">
            <div>
              <div className="empData-tab Color-tab-teal">
                <div className="empData-Tab-heading">Onboard Employees</div>
                <div className="empData-Tab-value">{empData.joined_employees_count}</div>
              </div>
            </div>
          </div>

          <div className="empData-dashboard">
            <div>
              <div className="empData-tab Color-tab-yellow">
                <div className="empData-Tab-heading">Resigned Employees</div>
                <div className="empData-Tab-value">{empData.resigned_employees_count}</div>
              </div>
            </div>
          </div>

          <div className="empData-dashboard">
            <div>
              <div className="empData-tab Color-tab-red">
                <div className="empData-Tab-heading">Terminated Employees</div>
                <div className="empData-Tab-value">{empData.terminated_employees_count}</div>
              </div>
            </div>
          </div>







          {/* <div className="piechart-dashboard">
            <div>
              <PieChart width={120} height={80}>
                <Pie
                  data={dataa}
                  cx={60}
                  cy={70}
                  startAngle={180}
                  endAngle={0}
                  innerRadius={35}
                  cornerRadius={10}
                  outerRadius={50}
                  fill="#8884d8"
                  paddingAngle={3}
                  dataKey="value"
                >
                  {dataa.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={LateCOLORS[index % LateCOLORS.length]}
                    />
                  ))}
                </Pie>
              </PieChart>
            </div>
            <div className="piechart-heading">
              <div
                className="piechart-dot"
                style={{ backgroundColor: "#F7BA1E" }}
              ></div>
              <div className="piechart-subheading">Leave</div>
            </div>
            <div className="piechart-percentage">{leavepercentage} %</div>
          </div> */}

          {/* <div className="piechart-dashboard">
            <div>
              <PieChart width={120} height={80}>
                <Pie
                  data={dataaa}
                  cx={60}
                  cy={70}
                  startAngle={180}
                  endAngle={0}
                  innerRadius={35}
                  cornerRadius={10}
                  outerRadius={50}
                  fill="#8884d8"
                  paddingAngle={3}
                  dataKey="value"
                >
                  {dataaa.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={PresentCOLORS[index % PresentCOLORS.length]}
                    />
                  ))}
                </Pie>
              </PieChart>
            </div>
            <div className="piechart-heading">
              <div
                className="piechart-dot"
                style={{ backgroundColor: "#06AD06" }}
              ></div>
              <div className="piechart-subheading">Present</div>
            </div>
            <div className="piechart-percentage">{presentpercentage} %</div>
          </div> */}

        </div>
      </div>
    </>
  );
};

export default EmployeeData;
