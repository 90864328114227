import React, { useState, useEffect } from "react";
import Dashboardnavbarcopy from "../../layout/Dashboardnavbarcopy";
import { BankFormat, EmployeeDetails, MonthlySalary, PayRun } from "./PayrollManagementTables";
const PayrollManagement = () => {

    const [toggleState, setToggleState] = useState(1);

    const getSelectedTitle = () => {
        switch (toggleState) {
            case 1:
                return 'Pay Run';
            case 2:
                return 'Monthly Salary';
            case 3:
                return 'Employee Details';
            case 4:
                return 'Bank Format';

            default:
                return 'Monthly Salary';
        }
    };

    return (
        <>
            <div>
                <div>
                    <Dashboardnavbarcopy name={getSelectedTitle()} url="Pay Roll" />
                    <div className="bloc-tabss  wfm-margin">
                        <button
                            className={toggleState === 1 ? "dashboardcomponent-tabs dashboardcomponent-tabss dashboardcomponent-active-tabs" : "dashboardcomponent-tabs dashboardcomponent-tabss"}
                            onClick={() => setToggleState(1)}
                        >
                            Pay Run
                        </button>
                        <button
                            className={toggleState === 2 ? "dashboardcomponent-tabs dashboardcomponent-tabss dashboardcomponent-active-tabs" : "dashboardcomponent-tabs dashboardcomponent-tabss"}
                            onClick={() => setToggleState(2)}
                        >
                            Monthly Salary
                        </button>
                        <button
                            className={toggleState === 3 ? "dashboardcomponent-tabs dashboardcomponent-tabss dashboardcomponent-active-tabs" : "dashboardcomponent-tabs dashboardcomponent-tabss"}
                            onClick={() => setToggleState(3)}
                        >
                            Employee Details
                        </button>
                        <button
                            className={toggleState === 4 ? "dashboardcomponent-tabs dashboardcomponent-tabss dashboardcomponent-active-tabs" : "dashboardcomponent-tabs dashboardcomponent-tabss"}
                            onClick={() => setToggleState(4)}
                        >
                            Bank Format
                        </button>


                    </div>
                </div>

                <div className="content-tabs">
                    {toggleState === 1 ? <PayRun /> : null}
                    {toggleState === 2 ? <MonthlySalary /> : null}

                    {toggleState === 3 ? <EmployeeDetails /> : null}

                    {toggleState === 4 ? <BankFormat /> : null}
                </div>
            </div>
        </>
    );
}

export default PayrollManagement;
