import React, { useState } from 'react'
import { Requestoutdutytable, RequestLeavetable, RequestMisspunchtable, RequestCompositortable, RequestImpresttable, RequestExpensetable } from './tlwfm/TlRequestoutdutytable';
import { RequestRequisitionTableRH } from '../RequisitionManagement/RequisitionManagementTables';

const Requestmanagment = () => {
    const [toggleState, setToggleState] = useState(1);
    return (
        <>
            <div>
                <div >
                    <div className="bloc-tabs-with-white-bg">
                        <button
                            className={toggleState === 1 ? "dashboardcomponent-tabs  dashboardcomponent-active-tabs" : "dashboardcomponent-tabs white-tab"}
                            onClick={() => setToggleState(1)}
                        >
                            Out Duty
                        </button>
                        <button
                            className={toggleState === 2 ? "dashboardcomponent-tabs dashboardcomponent-active-tabs" : "dashboardcomponent-tabs white-tab"}
                            onClick={() => setToggleState(2)}
                        >
                            Leave
                        </button>
                        {/* <button
                            className={toggleState === 3 ? "dashboardcomponent-tabs dashboardcomponent-active-tabs" : "dashboardcomponent-tabs white-tab"}
                            onClick={() => setToggleState(3)}
                        >
                            Miss Punch
                        </button> */}
                        <button
                            className={toggleState === 4 ? "dashboardcomponent-tabs dashboardcomponent-active-tabs" : "dashboardcomponent-tabs white-tab"}
                            onClick={() => setToggleState(4)}
                        >
                            Compensatory leave
                        </button>
                        <button
                            className={toggleState === 5 ? "dashboardcomponent-tabs dashboardcomponent-active-tabs" : "dashboardcomponent-tabs white-tab"}
                            onClick={() => setToggleState(5)}
                        >
                            Imprest
                        </button>
                        {/* <button
                            className={toggleState === 6 ? "dashboardcomponent-tabs dashboardcomponent-active-tabs" : "dashboardcomponent-tabs white-tab"}
                            onClick={() => setToggleState(6)}
                        >
                            Expense
                        </button> */}
                        <button
                            className={toggleState === 7 ? "dashboardcomponent-tabs dashboardcomponent-active-tabs" : "dashboardcomponent-tabs white-tab"}
                            onClick={() => setToggleState(7)}
                        >
                            Requisition
                        </button>
                    </div>
                </div>

                <div className="content-tabs-without-margin">
                    {toggleState === 1 ? (<Requestoutdutytable />) : null}

                    {toggleState === 2 ? <RequestLeavetable /> : null}

                    {toggleState === 3 ? <RequestMisspunchtable /> : null}

                    {toggleState === 4 ? <RequestCompositortable /> : null}
                    {toggleState === 5 ? <RequestImpresttable /> : null}
                    {toggleState === 6 ? <RequestExpensetable /> : null}
                    {toggleState === 7 ? <RequestRequisitionTableRH /> : null}





                </div>
            </div>
        </>
    )
}

export default Requestmanagment
