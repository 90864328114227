import {
  RadialBarChart,
  RadialBar,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { useParams } from "react-router-dom";

import "../../../assets/css/components.css";
import React, { useState, useEffect } from "react";
import { BASE_URL } from "../../../config/axios";
import axios from "axios";



const OutDutyChart = () => {
  let { emp_code } = useParams()

  const [statData, setStatData] = useState({});
  const getStatdata = async () => {
    try {
      const res = await axios.get(`${BASE_URL}/wfm/requestsapplied/${emp_code}`);
      console.log(res.data)
      setStatData(res.data);
    } catch (err) {
      alert(err.message);
    }
  };

  const [graphData, setGraphData] = useState({});
  const getGraphdata = async () => {
    try {
      const res = await axios.get(`${BASE_URL}/wfm/requeststatus/${emp_code}`);
      console.log(res.data)
      setGraphData(res.data);
    } catch (err) {
      alert(err.message);
    }
  };


  useEffect(() => {
    getStatdata();
    getGraphdata();
  }, []);


  const data = [
    {
      name: "Short Day OutDuty",
      uv: statData.outduty_shortleave,
      fill: "#165DFF",
    },
    {
      name: "HalfDay OutDuty",
      uv: statData.outduty_halfdayleave,
      fill: "#F7BA1E",
    },
    {
      name: "Single Day OutDuty",
      uv: statData.outduty_single,
      fill: "#FF747C",
    },
    {
      name: "Multi Day OutDuty",
      uv: statData.outduty_multiple,
      fill: "#30C9C9",
    },
  ];
  return (
    <ResponsiveContainer width="90%" height={205} className="radialchart">
      <div className="leavechart-heading">
        <div className="repo-heading-a">Total Out Duties -  {statData.alloutduty}</div>
        {/* <div className="repo-heading-a">Out Duties Total Applied </div> */}
        <div>
          {data.map((item) => {
            return (
              <div key={item.name} className="radialchart-legend">
                <div
                  className="radialchart-dot"
                  style={{ backgroundColor: item.fill }}
                ></div>
                <div className="radialchart-subheading">
                  {item.name} - {item.uv}
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <RadialBarChart
        innerRadius={30}
        outerRadius={125}
        barSize={13}
        startAngle={90}
        endAngle={450}
        data={data}
      >
        <RadialBar
          minAngle={15}
          background
          clockWise="true"
          cornerRadius={10}
          dataKey="uv"
        ></RadialBar>
        {/* <Tooltip /> */}
      </RadialBarChart>
    </ResponsiveContainer>
  );
};

export default OutDutyChart;