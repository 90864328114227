import React, { useEffect, useState } from 'react'
import { BASE_URL } from "../../config/axios";
import axios from "axios";
import {Importantmessage, ImportantmessageUpdate, ImportantmessageDelete} from './model/Importantmessage';
import { formatDate, formattedDateLong } from '../Date';

const Importantmessagetable = () => {

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [message, setMessagename] = useState([]);

  const getmessage = async () => {
    try {
      const res = await axios.get(`${BASE_URL}/settings/importantnotificationAPI/`);
      setMessagename(res.data);
    } catch (err) {
      // alert(err.message);
    }
  };

  useEffect(() => {
    getmessage();
  }, []);

  return (
    <div className="custom-table-background">
    <div className="table-heading-flex">
          <div className="repo-heading">Important message</div>
          <div>
            <Importantmessage getmessage={getmessage} />
          </div>
        </div>
    <table className="custom-table">
      <thead>
        <tr className="custom-table-head-tr">
          <th className="align-left">Sr no.</th>
          <th className="align-center">Date</th>
          <th className="align-center">Message</th>
          <th className="align-right">Action</th>
        </tr>
      </thead>
      <tbody>
        {message.map((i, index) => (
          <React.Fragment key={index}>
            <tr className="tr-border-bottom">
              <td colSpan="6"></td>
            </tr>
            <tr className="custom-table-head-td">
              <td className="align-left">{index+1}</td>
              <td className="align-center">{formattedDateLong(i.date)}</td>
              <td className="align-center">{i.message}</td>
              <td className="align-right ">
              <ImportantmessageUpdate i={i} getmessage={getmessage} />
              <ImportantmessageDelete i={i} getmessage={getmessage} />
              </td>
            </tr>
          </React.Fragment>
        ))}
      </tbody>
    </table>
  </div> 
  )
}

export default Importantmessagetable
