// import React from 'react'
import Logo from './frame-10000027081.png'
import { BASE_URL, FRONTEND_URL } from "../../config/axios";
import React, { useEffect, useState } from "react";
import axios from "axios";
import Signature from './Picture 1.png'
import { formattedDate, formattedDateLong } from '../Date';



const data1 = [
  {
    name: "Max",
    food: 100.0,
    fill: "#fff",
  },
]


const data2 = [
  {
    name: "Max",
    uv: 100.0,
    fill: "#fff",
  },
]


const ExpenseSlipByYear = ({ emp }) => {
  const [ExpenseData, setExpenseData] = useState({})
  const [personalData, setPersonalData] = useState({})

  const date = formattedDate

  const getLetterData = async () => {
    try {
      const res = await axios.get(`${BASE_URL}/wfm/incrementbyemployee/${emp}`);
      const ress = await axios.get(`${BASE_URL}/wfm/ourcompanydetailsbyemp/${emp}/`);

      setExpenseData(res.data[0])
      setPersonalData(ress.data)

    } catch (err) {
    }
  };

  // console.log(ExpenseData)


  useEffect(() => {
    getLetterData();

  }, []);

  const dojdate = personalData?.joining_date

  const doj_date = dojdate?.split("-")



  return (
    <>
      <div className='salaryslip'>
        {/* <div className='salaryslip-header salaryslip-header-left'>
          <div className='salaryslip-header-left'>
            <img src="https://cipl-aimantra.s3.ap-south-1.amazonaws.com/Logos/Cipl+Logo++(1).png" alt='logo' className='salaryslip-logo' />

            <div className='salaryslip-header-left-text'>
              Expense Month of April 2022
            </div>
          </div>
          <div className='salaryslip-header-right'>
            <div className='salaryslip-header-right-top'>
              Civilmantra infracon private limited
            </div>
            <div className='salaryslip-header-right-bottom'>
              3rd Floor, Tower 3A,<br /> DLF Corporate Greens, Sector 74A,<br /> Gurugram, Haryana 122004 <br /> services@civilmantra.com
            </div>
          </div>
        </div> */}

        <div className='salaryslip-header'>
          <div className='salaryslip-header-left'>
            <img src="https://cipl-aimantra.s3.ap-south-1.amazonaws.com/Logos/Cipl+Logo++(1).png" alt='logo' className='salaryslip-logo' />
            <div className='salaryslip-header-left-text'>
              Expense Month of April 2022
            </div>
          </div>
          <div className='salaryslip-header-right'>
            <div className='salaryslip-header-right-top'>
              Civilmantra infracon private limited
            </div>
            <div className='salaryslip-header-right-bottom'>
              3rd Floor, Tower 3A,<br /> DLF Corporate Greens, Sector 74A,<br /> Gurugram, Haryana 122004 <br /> services@civilmantra.com
            </div>
          </div>
        </div>
        {/* <div className='toppading'>gap</div> */}
        <div className='page-content'>
          <div className='salaryslip-hr'></div>
          <div className='salaryslip-header-second'>
            <div className='salaryslip-header-second-section1'>
              <div className='salaryslip-heading-black'>Project Name</div>
              <div className='salaryslip-heading-black'>Project Code</div>
              <div className='salaryslip-heading-black'>Client</div>
              <div className='salaryslip-heading-black'>DEPARTMENT</div>


            </div>
            <div className='salaryslip-header-second-section2'>
              <div className='salaryslip-heading-black1'>DIVYA N. GHORI</div>
              <div className='salaryslip-heading-black1'>NANUBHAI GHORI</div>
              <div className='salaryslip-heading-black1'>30/08/2022</div>
              <div className='salaryslip-heading-black1'>UX/UI DESINGER</div>
            </div>
            <div className='salaryslip-header-second-section3'>
            </div>
            <div className='salaryslip-header-second-section4'>
              <div className='salaryslip-heading-black'>Name</div>
              <div className='salaryslip-heading-black'>Date</div>
              <div className='salaryslip-heading-black'>Start Time</div>
              <div className='salaryslip-heading-black'>End Time</div>
            </div>
            <div className='salaryslip-header-second-section5'>
              <div className='salaryslip-heading-black1'>DIVYA N. GHORI</div>
              <div className='salaryslip-heading-black1'>NANUBHAI GHORI</div>
              <div className='salaryslip-heading-black1'>30/08/2022</div>
              <div className='salaryslip-heading-black1'>UX/UI DESINGER</div>
            </div>
          </div>
          <p>================================1=================================</p>
          <br />
          <p>================================2=================================</p>
          <br />
          <p>================================3=================================</p>
          <br />
          <p>================================4=================================</p>
          <br />
          <p>================================5=================================</p>
          <br />
          <p>================================6=================================</p>
          <br />
          <p>===============================7=================================</p>
          <br />
          <p>================================8=================================</p>
          <br />
          <p>================================9=================================</p>
          <br />
          <p>================================10=================================</p>
          <br />
          <p>================================11=================================</p>
          <br />
          <p>================================12=================================</p>
          <br />
          <p>================================13=================================</p>
          <br />
          <p>================================14=================================</p>
          <br />
          <p>================================15=================================</p>
          <br />
          <p>================================16=================================</p>
          <br />
          <p>================================17=================================</p>
          <br />
          <p>================================18=================================</p>
          <br />
          <p>================================19=================================</p>
          <br />
          <p>================================20=================================</p>
          <br />
          <p>================================1=================================</p>
          <br />
          <p>================================2=================================</p>
          <br />
          <p>================================3=================================</p>
          <br />
          <p>================================4=================================</p>
          <br />
          <p>================================5=================================</p>
          <br />
          <p>================================6=================================</p>
          <br />
          <p>===============================7=================================</p>
          <br />
          <p>================================8=================================</p>
          <br />
          <p>================================9=================================</p>
          <br />
          <p>================================10=================================</p>
          <br />
          <p>================================11=================================</p>
          <br />
          <p>================================12=================================</p>
          <br />
          <p>================================13=================================</p>
          <br />
          <p>================================14=================================</p>
          <br />
          <p>================================15=================================</p>
          <br />
          <p>================================16=================================</p>
          <br />
          <p>================================17=================================</p>
          <br />
          <p>================================18=================================</p>
          <br />
          <p>================================19=================================</p>
          <br />
          <p>================================20=================================</p>
          <br />

          <var><div className='salaryslip-body'>
            <table className='salary-table'>
              <thead>
                <tr className='expenseslip-head'>
                  <th style={{ padding: "0px 5px" }}>Employee name</th>
                  <th style={{ padding: "0px 5px" }}>Fooding Exp</th>
                  <th style={{ padding: "0px 5px" }}>Traveling Exp</th>
                  <th style={{ padding: "0px 5px" }}>Personal Exp</th>
                  <th style={{ padding: "0px 5px" }}>Grand Total</th>
                  {/* <th style={{ width: "80px", padding: "0px 5px" }}>AMOUNT</th> */}


                </tr>
              </thead>
              <tbody>
                {data1.map((i, index) => (
                  <React.Fragment key={index}>
                    <tr>
                      <td className='salaryslip-heading-blue'>Name</td>
                      <td className='expense-table-td'>30000</td>
                      <td className='expense-table-td'>28000</td>
                      <td className='expense-table-td'>28000</td>
                      <td className='expense-table-td'>200</td>
                    </tr>
                    {/* <tr>
                      <td colSpan={6} className='salaryslip-hrr'></td>
                    </tr> */}
                    {/* <tr>
                  <th >TOTAL EARNINGS</th>
                  <td className='salary-table-td'>30000</td>
                  <td className='salary-table-td'>3000</td>
                  <th>TOTAL DEDUCTIONS</th>
                  <td className='salary-table-td'> 88</td>
                  <td className='salary-table-td'> 88</td>
                  
                </tr> */}
                  </React.Fragment>
                ))}
                <br />

                <tr>
                  <td colSpan={65} className='salaryslip-hrr'></td>
                </tr>
                <tr>
                  <td >Total</td>
                  {/* <td></td> */}
                  <td className='expense-table-td'>30000</td>
                  <td className='expense-table-td'>28000</td>
                  <td className='expense-table-td'>28000</td>
                  <td className='expense-table-td'>200</td>

                </tr>
                <tr>
                  <th>IN WORDS</th>
                  <td colSpan={5} style={{ paddingLeft: "30px" }}>Thirty Two Thousand Two Hundred Eleven Only.</td>
                </tr>
                <br></br>

              </tbody>
            </table>
            <table className='salary-table'>

              <thead style={{ margin: "20px 0px" }}>
                <tr className='expenseslip-head' >
                  <th style={{ padding: "0px 5px" }}>Sr No.</th>
                  <th style={{ padding: "0px 5px" }}>Period</th>
                  <th style={{ padding: "0px 5px" }}>Date</th>
                  <th style={{ padding: "0px 5px" }}>Amount</th>
                  <th style={{ padding: "0px 5px" }}>Expense Type</th>
                  <th style={{ padding: "0px 5px" }}>Associate</th>
                  <th style={{ padding: "0px 5px" }}>Bills</th>
                  <th style={{ padding: "0px 5px" }}>Description</th>


                </tr>
              </thead>

              <tbody>

                {data2.map((i, index) => (
                  <React.Fragment key={index}>
                    <tr className="tr-border-bottom">
                      <td colSpan="8"></td>
                    </tr>
                    <tr>
                      <td>{index + 1}</td>
                      <td className='expense-table-td'>30000</td>
                      <td className='expense-table-td'>28000</td>
                      <td className='expense-table-td'>28000</td>
                      <td className='expense-table-td'>200</td>
                      <td className='expense-table-td'>100</td>
                      <td className='expense-table-td'>100</td>
                      <td className='expense-table-td'>100</td>
                    </tr>
                  </React.Fragment>
                ))}
                <br />

                <tr>
                  <td colSpan={8} className='salaryslip-hrr'></td>
                </tr>






              </tbody>

              <tr>
              </tr>


            </table>
            {/* <br /> */}
            <tr>
              <th >TOTAL </th>
              <td className='expense-table-td'>30000</td>
            </tr>
            <tr>
              <th className='salaryslip-heading-blue'>IN WORDS</th>
              <td colSpan={5} style={{ paddingLeft: "30px" }}>Thirty Two Thousand Two Hundred Eleven Only.</td>
            </tr>
          </div></var>

          <div className='salaryslip-hr'></div>
        </div>

      </div>
      <div className='slaryslip-bg'>


      </div>
    </>
  )
}

export default ExpenseSlipByYear
