import React, { useEffect, useState } from 'react'
import {Starperformance, StarperformanceUpdate, StarperformanceDelete} from './model/Starperformance';
import { BASE_URL } from "./../../config/axios";
import axios from "axios";

const Starperformancetables = () => {

  const [performancename, setPerformancename] = useState([]);

  const getperformance = async () => {
    try {
      const res = await axios.get(`${BASE_URL}/wfm/performance/`);
      setPerformancename(res.data);
    } catch (err) {
      // alert(err.message);
    }
  };

  useEffect(() => {
    getperformance();
  }, []);

  return (
    <div className="custom-table-background">
      <div className="table-heading-flex">
          <div className="repo-heading">Starperformance</div>
          <div>
            <Starperformance  getperformance={getperformance}/>
          </div>
        </div>
      <table className="custom-table">
        <thead>
          <tr className="custom-table-head-tr">
            <th className="align-left">Emp. Code</th>
            <th className="align-center">Title</th>
            <th className="align-center">Date</th>
            <th className="align-center">Reason for recogntion</th>
            <th className="align-center">Recognized by</th>
            <th className="align-center">Image reward</th>
            <th className="align-center">Total awards</th>
            <th className="align-center">Action</th>
          </tr>
        </thead>
        <tbody>
          {performancename.map((i, index) => (
            <React.Fragment key={index}>
              <tr className="tr-border-bottom">
                <td colSpan="9"></td>
              </tr>
              <tr className="custom-table-head-td">
                <td className="align-left">{i.emp_code}</td>
                <td className="align-center">{i.title}</td>
                <td className="align-center">{i.date}</td>
                <td className="align-center">{i.reason_for_recogntion}</td>
                <td className="align-center">{i.recognized_by}</td>
               
                <td className="align-center "><img className='img-form' src={i.image_reward} alt="" /></td>
                <td className="align-center">{i.total_awards }</td>
                <td className="align-right ">
                <StarperformanceUpdate i={i} getperformance={getperformance}/> 
                <StarperformanceDelete i={i} getperformance={getperformance}/> 
                </td>
              </tr>
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default Starperformancetables
