import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  RadialBarChart,
  RadialBar,
} from "recharts";
import { BASE_URL } from "../../config/axios";




// const style = {
//   top: 0,
//   left: 350,
//   lineHeight: "24px"
// };

const Radicalchart = () => {
  const [chartData, setChartData] = useState({});
  const getChartdata = async () => {
    try {
      const res = await axios.get(`${BASE_URL}/wfm/pendingitems/${sessionStorage.getItem('emp_code')}`);
      console.log(res.data)
      setChartData(res.data);
    } catch (err) {
      alert(err.message);
    }
  };

  useEffect(() => {
    getChartdata();
  }, []);


  const data = [
    {
      name: "Max",
      value: 100.0,
      fill: "#fff",
    },
    {
      name: "Outduty Requests Pending",
      value: chartData.outduty_details,
      total: chartData.outduty_details_total,
      fill: "#165DFF",
    },
    {
      name: "Leave Requests Pending",
      value: chartData.leave_details,
      total: chartData.leave_details_total,
      fill: "#FF747C",
    },
    {
      name: "Task Pending",
      value: chartData.tasks,
      total: chartData.tasks_count_total,
      fill: "#F7BA1E",
    },
    {
      name: "Tasks Completed(Closed)",
      value: chartData.tasks_close,
      total: chartData.tasks_count_total,
      fill: "#30C9C9",
    },
  ];
  // fill: "#F7BA1E", //yellow

  return (

    <div className="piechartt">
      <div className="piechart-headings" style={{ display: "flex", justifyContent: "space-between" }}>
        <div className="repo-heading-a">All Requests</div>
      </div>
      <div className="piechart-flex">
        <div className="piechart-flex2">
          {data.map((item) => {
            if (item.name === "Max") return <></>;
            return (
              <div key={item.name} className="radialchart-legend">
                <div
                  className="radialchart-dot"
                  style={{ backgroundColor: item.fill }}
                ></div>
                <div className="radialchart-subheading">{item.name}-{item.value}/{item.total}</div>
              </div>
            );
          })}
        </div>
        <RadialBarChart
          width={200}
          height={150}
          innerRadius={1}
          outerRadius={70}
          barSize={18}
          startAngle={90}
          endAngle={450}
          data={data}
        >
          <RadialBar
            minAngle={15}
            background
            clockWise="true"
            cornerRadius={10}
            dataKey="value"
          ></RadialBar>
          {/* <Tooltip /> */}
        </RadialBarChart>
      </div>
    </div>

  );
};

export default Radicalchart;