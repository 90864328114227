import React, { useState, useEffect } from "react";
import axios from "axios";
import { BASE_URL } from "../../../config/axios";

const Leave = ({ handleClose }) => {

  const [loading, setLoading] = useState(false); //loading logic


  const [leavebalance, setLeavebalance] = useState({});

  const getLeavebalance = async () => {
    try {
      const res = await axios.get(`${BASE_URL}/wfm/leavebalance/${sessionStorage.getItem("emp_code")}/`);
      setLeavebalance(res.data);
      console.log(res.data)
    } catch (err) {
      // alert(err.message);
    }
  };

  useEffect(() => {
    getLeavebalance();
  }, []);

  const [age, setAge] = useState("");

  const [maxEndDate, setMaxEndDate] = useState(null);

  {/* Leave Type */ }

  const [dataa, setdataa] = useState({
    empcode: sessionStorage.getItem("emp_code"),
    date: "",
    end_date: "",
    leave_type: "previous leave",
    remarks: "",
  });

  const getEndTime = (intime) => {
    const start = new Date(`2024-03-25T${intime}`);
    const end = new Date(start.getTime() + 2 * 60 * 60 * 1000); // Add 2 hours in milliseconds
    const hh = String(end.getHours()).padStart(2, "0");
    const mm = String(end.getMinutes()).padStart(2, "0");
    const ss = String(end.getSeconds()).padStart(2, "0");

    setdataa({ ...dataa, in_time: intime, out_time: `${hh}:${mm}:${ss}` });
  };

  const date = new Date();

  const [remarksError, setRemarksError] = useState("");
  const [typeError, setTypeError] = useState("");

  let lastDateOfPreviousMonth;
  if (date.getDate() > 25) {
    lastDateOfPreviousMonth = new Date(date.getFullYear(), date.getMonth(), 0);
  } else {
    lastDateOfPreviousMonth = new Date(
      date.getFullYear(),
      date.getMonth() - 1,
      +1
    );
  }
  const formattedLastDate = lastDateOfPreviousMonth.toISOString().substr(0, 10);

  //******************Valedation Start********************* */

  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});
  const [priority, setPriority] = useState("3");

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = ["date", "leave_type", "remarks"];
    requiredFields.forEach((field) => {
      if (!dataa[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };



  let handleSubmit = async (e) => {
    // const enteredDate = dataa.date;
    // const minDate = formattedLastDate;

    e.preventDefault();
    setLoading(true); //loading logic

    const updatedFormData = {
      ...dataa,
      leave_Duration: age,
    };
    try {

      let res = await axios.post(
        `${BASE_URL}/wfm/leaveapply/`,
        updatedFormData
      );

      if (res.status === 200) {
        handleClose()
      } else {
        alert(res);
      }
    } catch (err) {
      // console.log(err.response.data);
      // alert(err.response.data[0]);
      alert(err);
    } finally {
      setLoading(false); //loading logic
    }
    return;

  };

  return (
    <div>
      <form>
        <div className="flex-column model-age">
          <div className="leaves">
            <label className="age-label-main">
              All Available Leaves:-{" "}
              {/* <span className="leave-duration">{leavebalance.leavebalance ? `${leavebalance.leavebalance} leave` : "You Have No Leave Remaning"}</span> */}
            </label>
            <br />
            <div className="flex-row" style={{ justifyContent: 'space-evenly' }}>
              <div className="flex-column">
                <label className="age-label-main">
                  Previous:{" "}
                  <span className="leave-duration">{leavebalance.leavebalance ? `${leavebalance.leavebalance} leave` : "No Remaining"}</span>
                </label>
                <label className="age-label-main">
                  Earned:{" "}
                  <span className="leave-duration">{leavebalance.earn_leave ? `${leavebalance.earn_leave} leave` : "No Remaining"}</span>
                </label>
              </div>

              <div className="flex-column">
              </div>
              <div className="flex-column">

                <label className="age-label-main">
                  Casual:{" "}
                  <span className="leave-duration">{leavebalance.casual_leave ? `${leavebalance.casual_leave} leave` : "No Remaining"}</span>
                </label>
                <label className="age-label-main">
                  Sick:{" "}
                  <span className="leave-duration">{leavebalance.sick_leave ? `${leavebalance.sick_leave} leave` : "No Remaining"}</span>
                </label>
              </div>
            </div>

            <br />

          </div>
          <div className="leave-duration-heading">Leave Duration </div>

          < div onChange={(e) => setAge(e.target.value)} className="leave-radio-button">
            <div className="leave-radio-button-inner-div">
              <input
                name="age"
                type="radio"
                value="single"
                className="age-input"
              />
              <label className="age-label">Single Day</label></div>
            <div className="leave-radio-button-inner-div">
              <input
                name="age"
                type="radio"
                value="multiple"
                className="age-input"
              />
              <label className="age-label">Multi Day</label>
            </div>
            <div className="leave-radio-button-inner-div">

              <input
                name="age"
                type="radio"
                value="halfdayleave"
                className="age-input"
              />
              <label className="age-label">Half Day</label></div>
            <div className="leave-radio-button-inner-div">
              <input
                name="age"
                type="radio"
                value="shortleave"
                className="age-input"
              />
              <label className="age-label">Short Day</label></div>
          </div>
        </div>

        {age === "single" && (
          <div className="flex-column">
            <label htmlFor="date" className="form-labels">
              Date
            </label>
            <input
              id="date"
              type="date"
              className="form-input form-input-background"
              onChange={(e) =>
                setdataa({
                  ...dataa,
                  date: e.target.value,
                  end_date: e.target.value,
                })
              }
              min={formattedLastDate}
            />
          </div>
        )}

        {age === "multiple" && (
          <div className="flex-row multi-day-input">
            <div className="flex-column multi-day">
              <label className="form-labels">From Date</label>
              <input
                type="date"
                className="multi-day-input1 form-input-background"
                onChange={(e) => {
                  const startDate = e.target.value;
                  const lastDate = new Date(
                    new Date(startDate).getFullYear(),
                    new Date(startDate).getMonth() + 1,
                    1
                  )
                    .toISOString()
                    .split("T")[0];
                  setdataa({
                    ...dataa,
                    date: startDate,

                  });
                  setMaxEndDate(lastDate);
                }}
                min={formattedLastDate}
              />
            </div>
            <div className="flex-column multi-day">
              <label className="form-labels">To Date</label>
              <input
                type="date"
                className="multi-day-input1 form-input-background"
                onChange={(e) =>
                  setdataa({ ...dataa, end_date: e.target.value })
                }
                min={dataa.date}
                max={maxEndDate}
              />
            </div>
          </div>
        )}

        {age === "halfdayleave" && (
          <div>
            <div className="flex-column single-day-date">
              <label className="form-labels">Date</label>
              <input
                type="date"
                className="form-input form-input-background"
                onChange={(e) =>
                  setdataa({
                    ...dataa,
                    date: e.target.value,
                    end_date: e.target.value,
                  })
                }
                min={formattedLastDate}
              />
            </div>
            <div

              required
              className="leave-radio-button"
            >
              <div className="leave-radio-button-inner-div">
                <input
                  name="halfday"
                  type="radio"
                  value="firsthalft"
                  className="age-input"
                />
                <label className="age-label">First Half</label>
              </div>
              <div className="leave-radio-button-inner-div">
                <input
                  name="halfday"
                  type="radio"
                  value="secondhalft"
                  className="age-input"
                />
                <label className="age-label">Second Half</label>
              </div>
            </div>
          </div>
        )}

        {age === "shortleave" && (
          <div>
            <div className="flex-column single-day-date">
              <label className="form-labels">Date</label>
              <input
                type="date"
                className="form-input form-input-background"
                onChange={(e) =>
                  setdataa({
                    ...dataa,
                    date: e.target.value,
                    end_date: e.target.value,
                  })
                }
              />
            </div>
            <div className="flex-row multi-day-input">
              <div className="flex-column multi-day">
                <label className="form-labels">Start Time</label>
                <input
                  className="multi-day-input1 form-input-background"
                  type="time"
                  step="1"
                  min="09:30:00"
                  max="16:30:00"
                  onChange={(e) => getEndTime(e.target.value)}
                />
              </div>
              <div className="flex-column multi-day">
                <label className="form-labels">End Time</label>
                <input
                  className="multi-day-input1 form-input-background"
                  type="time"
                  step="1"
                  min="09:30:00"
                  max="16:30:00"
                  value={dataa.out_time}
                  readOnly
                />
              </div>
            </div>
          </div>
        )}

        {/* Leave Type */}

        <div className="flex-column">
          <label className="form-labels">Leave Type</label>

          <select
            id="leave_type"
            name="leave_type"
            type="text"
            value={dataa.leave_type}
            className={`form-input `}
            onChange={(e) => {
              if (e.target.value.length >= 3) {
                setdataa({ ...dataa, leave_type: e.target.value })
                setTypeError(""); // Clear error message if within the limit
              } else {
                setTypeError(
                  "Ensure this field is selected."
                );
              }
            }
            }
          >
            {/* <option value="">Select Leave Type</option> */}
            <option value="previous leave">Previous Leave</option>
            <option value="casual leave">Casual Leave</option>
            <option value="sick leave">Sick Leave</option>
            <option value="earn leave">Earned Leave</option>
          </select>

          {typeError && (
            <div style={{ color: "red", marginTop: "5px" }}>{typeError}</div>
          )}
        </div>

        <div className="flex-column">
          <label className="form-labels">Notes</label>
          <textarea
            className="form-input-textarea form-input-background"
            placeholder="Reason"
            type="text"
            value={dataa.text}
            onChange={(e) => {
              if (e.target.value.length <= 50) {
                setdataa({ ...dataa, remarks: e.target.value });
                setRemarksError(""); // Clear error message if within the limit
              } else {
                setRemarksError(
                  "Ensure this field has no more than 50 characters."
                );
              }
            }}
          />

          {remarksError && (
            <div style={{ color: "red", marginTop: "5px" }}>{remarksError}</div>
          )}
        </div>
        <div className="button-models">
          <button className="model-button ">Cancel</button>
          {/* {!leavebalance.sick_leave && !leavebalance.casual_leave && !leavebalance.earn_leave && !leavebalance.leavebalance ?
            <button className="model-button model-button-cancel" disabled={true} > You Have 0 Leaves </button>
            : */}

          <button className="model-button model-button-leave" onClick={handleSubmit} disabled={loading} >{/* //loading logic */}
            Leave
          </button>
          {/* } */}
        </div>
      </form>
    </div>
  );
};

export default Leave;
