import { useState } from "react";
import axios from "axios";
import { BASE_URL } from "../../config/axios";
import "../../assets/css/website/login.css"
import Logo from "../../assets/images/website/resetpassword.png"
import { Link, useParams, useNavigate } from 'react-router-dom'


const Login = () => {

  let { uidb64, token } = useParams();



  // http://localhost:3000/forgotpassword




  const navigate = useNavigate();
  const [errMsg, setErrMsg] = useState("");

  const [formData, setFormData] = useState({
    new_password: "",
    confirm_new_password: ""
  });


  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});
  const [showPassword, setShowPassword] = useState(false);

  const validateForm = () => {
    const newErrors = {};

    const requiredFields = ['new_password', 'confirm_new_password'];

    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = `${field.charAt(0).toUpperCase() + field.slice(1)} is required!`;
      }
    });

    if (formData.new_password !== formData.confirm_new_password) {
      newErrors.confirm_new_password = "Passwords do not match";
    }

    // Password requirements
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/;
    if (!passwordRegex.test(formData.new_password)) {
      newErrors.new_password =
        "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };



  const [loading, setLoading] = useState(false); //loading logic


  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      setLoading(true); //loading logic



      try {
        let res = await axios.put(`${BASE_URL}/user/forgotpasswordchange/${uidb64}/${token}/`, formData);


        if (res.status === 200) {
          navigate("/");
        } else {
          setErrMsg("Invalid Username Or Password")
        }
      } catch (err) {
        setErrMsg("Invalid Username Or Password")
      } finally {
        setLoading(false); // Ensure loading state is stopped even in case of error //loading logic
      }
    } else {
      console.log('Form contains errors:', errors);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputState({
      ...inputState,
      [name]: value.trim() ? 'green' : '',
    });


    setFormData({
      ...formData,
      [name]: value,
    });
  };








  return (
    <>
      <div className='login-page'>
        <div>
          <Link to="/">
            <img src={Logo} alt='logo' className='login-page-img' /></Link>
        </div>
        <div className='login-page-right'>
          <div className='login-page-second-heading'>Reset Password</div>


          <form onSubmit={handleSubmit} className="register-form">

            <div className="flex-column">

              <label htmlFor="new_password" className='form-labels'>New Password:</label>
              <div style={{ position: 'relative' }}>
                <input
                  type={showPassword ? "text" : "password"}
                  placeholder="*********"
                  id="new_password"
                  name="new_password"
                  onChange={handleInputChange
                  }
                  value={formData.new_password}

                  className={`form-input ${errors.new_password ? 'error' : inputState.new_password ? 'success' : ''}`}
                />
                {errors.new_password && (
                  <span className="error-message">{errors.new_password}</span>
                )}
                <button
                  type="button"
                  style={{ position: 'absolute', right: 20, top: 20 }}
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? (<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 16 10" fill="none">
                    <path d="M15.4569 4.7975C15.435 4.74813 14.9056 3.57375 13.7287 2.39687C12.1606 0.82875 10.18 0 7.99999 0C5.81999 0 3.83937 0.82875 2.27124 2.39687C1.09437 3.57375 0.562494 4.75 0.543119 4.7975C0.51469 4.86144 0.5 4.93064 0.5 5.00062C0.5 5.0706 0.51469 5.1398 0.543119 5.20375C0.564994 5.25312 1.09437 6.42688 2.27124 7.60375C3.83937 9.17125 5.81999 10 7.99999 10C10.18 10 12.1606 9.17125 13.7287 7.60375C14.9056 6.42688 15.435 5.25312 15.4569 5.20375C15.4853 5.1398 15.5 5.0706 15.5 5.00062C15.5 4.93064 15.4853 4.86144 15.4569 4.7975ZM7.99999 9C6.07624 9 4.39562 8.30062 3.00437 6.92188C2.43352 6.35418 1.94786 5.70685 1.56249 5C1.94776 4.29309 2.43343 3.64574 3.00437 3.07812C4.39562 1.69938 6.07624 1 7.99999 1C9.92374 1 11.6044 1.69938 12.9956 3.07812C13.5676 3.6456 14.0543 4.29295 14.4406 5C13.99 5.84125 12.0269 9 7.99999 9ZM7.99999 2C7.40665 2 6.82663 2.17595 6.33328 2.50559C5.83994 2.83524 5.45542 3.30377 5.22836 3.85195C5.00129 4.40013 4.94188 5.00333 5.05764 5.58527C5.17339 6.16721 5.45912 6.70176 5.87867 7.12132C6.29823 7.54088 6.83278 7.8266 7.41472 7.94236C7.99667 8.05811 8.59987 7.9987 9.14804 7.77164C9.69622 7.54458 10.1648 7.16006 10.4944 6.66671C10.824 6.17336 11 5.59334 11 5C10.9992 4.2046 10.6828 3.44202 10.1204 2.87959C9.55797 2.31716 8.79539 2.00083 7.99999 2ZM7.99999 7C7.60443 7 7.21775 6.8827 6.88885 6.66294C6.55996 6.44318 6.30361 6.13082 6.15224 5.76537C6.00086 5.39991 5.96125 4.99778 6.03842 4.60982C6.11559 4.22186 6.30608 3.86549 6.58578 3.58579C6.86549 3.30608 7.22185 3.1156 7.60981 3.03843C7.99778 2.96126 8.39991 3.00087 8.76536 3.15224C9.13081 3.30362 9.44317 3.55996 9.66293 3.88886C9.8827 4.21776 9.99999 4.60444 9.99999 5C9.99999 5.53043 9.78928 6.03914 9.41421 6.41421C9.03913 6.78929 8.53043 7 7.99999 7Z" fill="#707070" />
                  </svg>) : (<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 16 16" fill="none">
                    <path d="M4.28016 11.82C2.7935 10.8467 1.8335 9.37999 1.8335 8.09332C1.8335 5.90665 4.5935 3.22665 8.00016 3.22665C9.3935 3.22665 10.6868 3.67332 11.7268 4.36665" stroke="#707070" strokeWidth="1.2" strokeLinecap="round" stroke-linejoin="round" />
                    <path d="M13.2332 5.74033C13.8272 6.49366 14.1732 7.32699 14.1732 8.09366C14.1732 10.2803 11.4066 12.9603 7.99992 12.9603C7.39325 12.9603 6.80058 12.8737 6.24658 12.7203" stroke="#707070" strokeWidth="1.2" strokeLinecap="round" stroke-linejoin="round" />
                    <path d="M6.51072 9.57821C6.11406 9.18554 5.89206 8.65021 5.89406 8.09221C5.89139 6.92888 6.83272 5.98354 7.99672 5.98154C8.55672 5.98021 9.09406 6.20221 9.49006 6.59821" stroke="#707070" strokeWidth="1.2" strokeLinecap="round" stroke-linejoin="round" />
                    <path d="M10.0732 8.46631C9.91717 9.32764 9.24317 10.003 8.38184 10.161" stroke="#707070" strokeWidth="1.2" strokeLinecap="round" stroke-linejoin="round" />
                    <path d="M13.2611 2.8335L2.74512 13.3495" stroke="#707070" strokeWidth="1.2" strokeLinecap="round" stroke-linejoin="round" />
                  </svg>)}
                </button>
              </div>
            </div>
            <div className="flex-column">

              <label htmlFor="confirm_new_password" className='form-labels'>Confirm New Password:</label>
              <div style={{ position: 'relative' }}>
                <input
                  type={showPassword ? "text" : "password"}
                  placeholder="*********"
                  id="confirm_new_password"
                  name="confirm_new_password"
                  onChange={handleInputChange
                  }
                  value={formData.confirm_new_password}

                  className={`form-input ${errors.confirm_new_password ? 'error' : inputState.confirm_new_password ? 'success' : ''}`}
                />
                {errors.confirm_new_password && (
                  <span className="error-message">{errors.confirm_new_password}</span>
                )}
                <button
                  type="button"
                  style={{ position: 'absolute', right: 20, top: 20 }}
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? (<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 16 10" fill="none">
                    <path d="M15.4569 4.7975C15.435 4.74813 14.9056 3.57375 13.7287 2.39687C12.1606 0.82875 10.18 0 7.99999 0C5.81999 0 3.83937 0.82875 2.27124 2.39687C1.09437 3.57375 0.562494 4.75 0.543119 4.7975C0.51469 4.86144 0.5 4.93064 0.5 5.00062C0.5 5.0706 0.51469 5.1398 0.543119 5.20375C0.564994 5.25312 1.09437 6.42688 2.27124 7.60375C3.83937 9.17125 5.81999 10 7.99999 10C10.18 10 12.1606 9.17125 13.7287 7.60375C14.9056 6.42688 15.435 5.25312 15.4569 5.20375C15.4853 5.1398 15.5 5.0706 15.5 5.00062C15.5 4.93064 15.4853 4.86144 15.4569 4.7975ZM7.99999 9C6.07624 9 4.39562 8.30062 3.00437 6.92188C2.43352 6.35418 1.94786 5.70685 1.56249 5C1.94776 4.29309 2.43343 3.64574 3.00437 3.07812C4.39562 1.69938 6.07624 1 7.99999 1C9.92374 1 11.6044 1.69938 12.9956 3.07812C13.5676 3.6456 14.0543 4.29295 14.4406 5C13.99 5.84125 12.0269 9 7.99999 9ZM7.99999 2C7.40665 2 6.82663 2.17595 6.33328 2.50559C5.83994 2.83524 5.45542 3.30377 5.22836 3.85195C5.00129 4.40013 4.94188 5.00333 5.05764 5.58527C5.17339 6.16721 5.45912 6.70176 5.87867 7.12132C6.29823 7.54088 6.83278 7.8266 7.41472 7.94236C7.99667 8.05811 8.59987 7.9987 9.14804 7.77164C9.69622 7.54458 10.1648 7.16006 10.4944 6.66671C10.824 6.17336 11 5.59334 11 5C10.9992 4.2046 10.6828 3.44202 10.1204 2.87959C9.55797 2.31716 8.79539 2.00083 7.99999 2ZM7.99999 7C7.60443 7 7.21775 6.8827 6.88885 6.66294C6.55996 6.44318 6.30361 6.13082 6.15224 5.76537C6.00086 5.39991 5.96125 4.99778 6.03842 4.60982C6.11559 4.22186 6.30608 3.86549 6.58578 3.58579C6.86549 3.30608 7.22185 3.1156 7.60981 3.03843C7.99778 2.96126 8.39991 3.00087 8.76536 3.15224C9.13081 3.30362 9.44317 3.55996 9.66293 3.88886C9.8827 4.21776 9.99999 4.60444 9.99999 5C9.99999 5.53043 9.78928 6.03914 9.41421 6.41421C9.03913 6.78929 8.53043 7 7.99999 7Z" fill="#707070" />
                  </svg>) : (<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 16 16" fill="none">
                    <path d="M4.28016 11.82C2.7935 10.8467 1.8335 9.37999 1.8335 8.09332C1.8335 5.90665 4.5935 3.22665 8.00016 3.22665C9.3935 3.22665 10.6868 3.67332 11.7268 4.36665" stroke="#707070" strokeWidth="1.2" strokeLinecap="round" stroke-linejoin="round" />
                    <path d="M13.2332 5.74033C13.8272 6.49366 14.1732 7.32699 14.1732 8.09366C14.1732 10.2803 11.4066 12.9603 7.99992 12.9603C7.39325 12.9603 6.80058 12.8737 6.24658 12.7203" stroke="#707070" strokeWidth="1.2" strokeLinecap="round" stroke-linejoin="round" />
                    <path d="M6.51072 9.57821C6.11406 9.18554 5.89206 8.65021 5.89406 8.09221C5.89139 6.92888 6.83272 5.98354 7.99672 5.98154C8.55672 5.98021 9.09406 6.20221 9.49006 6.59821" stroke="#707070" strokeWidth="1.2" strokeLinecap="round" stroke-linejoin="round" />
                    <path d="M10.0732 8.46631C9.91717 9.32764 9.24317 10.003 8.38184 10.161" stroke="#707070" strokeWidth="1.2" strokeLinecap="round" stroke-linejoin="round" />
                    <path d="M13.2611 2.8335L2.74512 13.3495" stroke="#707070" strokeWidth="1.2" strokeLinecap="round" stroke-linejoin="round" />
                  </svg>)}
                </button>
              </div>
            </div>
            <div>
              <button type="submit" className="login-button" disabled={loading}>
                Update Password
              </button></div>
            <p
              style={{ color: "red" }}
              className={errMsg ? "errmsg" : "offscreen"}
              aria-live="assertive"
            >
              {errMsg}
            </p>

          </form>

        </div>
      </div>
    </>
  )
}

export default Login