import OurCompanyDetails from "../career/update_details/OurCompanydetailsupdate"
import { FRONTEND_URL } from '../../config/axios';

const EmployeeDetailCard = ({ filteredEmployee, Viewdetails, departmentname, designationname, rhname, SubCompany, attendpolicy, weeklypolicy, worksite }) => {

    return (<div className='employee-cards'>

        {filteredEmployee.sort((a, b) => a.emp_code - b.emp_code).map((e, index) => {
            return (
                <div className='employee-card'>
                    <div className='employee-card-a'>
                        <div className='employee-card-image'>
                            <div className='employee-card-image-background'>
                                <img src={e.profilepic != "" ? `https://spacecreator-aimantra.s3.ap-south-1.amazonaws.com/${e.profilepic}` : 'https://images.unsplash.com/photo-1511367461989-f85a21fda167?q=80&w=1931&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'} alt="image" className="employee-card-imageee" />
                            </div>
                            <div className='employee-card-icons'>
                                <div className='employee-card-name'>{e.name}</div>
                                <div className='employee-card-designation'>{e.designation_name}</div>
                                <div className='employee-card-name'>Emp Code: {e.emp_code}</div>


                            </div>

                        </div>
                        <div className="flex-row">
                            <OurCompanyDetails e={e} departmentname={departmentname}
                                designationname={designationname}
                                rhname={rhname}
                                SubCompany={SubCompany}
                                attendpolicy={attendpolicy}
                                weeklypolicy={weeklypolicy}
                                worksite={worksite} />
                            <a href={`${FRONTEND_URL}/personaldetailss/${e.user_detail}/`} className="model-hue-button" style={{ marginRight: '10px' }} target="_blank" title="Edit Profile Details" rel="noopener noreferrer">
                                {/* <a href={`${FRONTEND_URL}/hr/personaldetailss/${e.user_detail}/`} className="model-hue-button" style={{ marginRight: '10px' }} target="_blank" title="Edit Profile Details" rel="noopener noreferrer"> */}
                                {/* <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="18"
                                    height="18"
                                    viewBox="0 0 18 18"
                                    fill="none"
                                >
                                    <path
                                        d="M15.9834 5.1589L12.8412 2.01734C12.7367 1.91285 12.6127 1.82996 12.4762 1.7734C12.3397 1.71685 12.1933 1.68774 12.0456 1.68774C11.8978 1.68774 11.7515 1.71685 11.615 1.7734C11.4785 1.82996 11.3545 1.91285 11.25 2.01734L2.57977 10.6876C2.47485 10.7917 2.39167 10.9156 2.33506 11.0521C2.27844 11.1886 2.24953 11.335 2.25001 11.4828V14.6251C2.25001 14.9234 2.36853 15.2096 2.57951 15.4206C2.79049 15.6315 3.07664 15.7501 3.37501 15.7501H15.1875C15.3367 15.7501 15.4798 15.6908 15.5853 15.5853C15.6907 15.4798 15.75 15.3368 15.75 15.1876C15.75 15.0384 15.6907 14.8953 15.5853 14.7898C15.4798 14.6843 15.3367 14.6251 15.1875 14.6251H8.10844L15.9834 6.75007C16.0879 6.6456 16.1708 6.52157 16.2274 6.38507C16.2839 6.24856 16.313 6.10225 16.313 5.95449C16.313 5.80673 16.2839 5.66042 16.2274 5.52391C16.1708 5.3874 16.0879 5.26337 15.9834 5.1589ZM6.51727 14.6251H3.37501V11.4828L9.56251 5.29531L12.7048 8.43757L6.51727 14.6251ZM13.5 7.64234L10.3584 4.50007L12.0459 2.81257L15.1875 5.95484L13.5 7.64234Z"
                                        fill="#145650"
                                    />
                                </svg> */}
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="18"
                                    height="18"
                                    data-name="Layer 1"
                                    viewBox="0 0 24 24"
                                    className='employee-detail-edit-svg'
                                    id="edit">
                                    <path d="M3.5,24h15A3.51,3.51,0,0,0,22,20.487V12.95a1,1,0,0,0-2,0v7.537A1.508,1.508,0,0,1,18.5,22H3.5A1.508,1.508,0,0,1,2,20.487V5.513A1.508,1.508,0,0,1,3.5,4H11a1,1,0,0,0,0-2H3.5A3.51,3.51,0,0,0,0,5.513V20.487A3.51,3.51,0,0,0,3.5,24Z"></path><path d="M9.455,10.544l-.789,3.614a1,1,0,0,0,.271.921,1.038,1.038,0,0,0,.92.269l3.606-.791a1,1,0,0,0,.494-.271l9.114-9.114a3,3,0,0,0,0-4.243,3.07,3.07,0,0,0-4.242,0l-9.1,9.123A1,1,0,0,0,9.455,10.544Zm10.788-8.2a1.022,1.022,0,0,1,1.414,0,1.009,1.009,0,0,1,0,1.413l-.707.707L19.536,3.05Zm-8.9,8.914,6.774-6.791,1.4,1.407-6.777,6.793-1.795.394Z">
                                    </path>
                                </svg>
                            </a>

                            <button className='employee-edit' onClick={() => Viewdetails(e.emp_code, e.user_detail)} title="View Profile">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none" className='employee-edit-svg'>
                                    <path d="M9 9.75C9.41421 9.75 9.75 9.41421 9.75 9C9.75 8.58579 9.41421 8.25 9 8.25C8.58579 8.25 8.25 8.58579 8.25 9C8.25 9.41421 8.58579 9.75 9 9.75Z" stroke="#2576BC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M9 4.5C9.41421 4.5 9.75 4.16421 9.75 3.75C9.75 3.33579 9.41421 3 9 3C8.58579 3 8.25 3.33579 8.25 3.75C8.25 4.16421 8.58579 4.5 9 4.5Z" stroke="#2576BC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M9 15C9.41421 15 9.75 14.6642 9.75 14.25C9.75 13.8358 9.41421 13.5 9 13.5C8.58579 13.5 8.25 13.8358 8.25 14.25C8.25 14.6642 8.58579 15 9 15Z" stroke="#2576BC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                </svg></button>
                        </div>
                    </div>
                    <div className='employee-card-b'>
                        <div className='employee-card-b-flex'>
                            <div><span className='employee-card-b-heading'>Department  :  </span><span className='employee-card-b-text'>{e.department_name}</span></div>
                            <div><span className='employee-card-b-heading'>Email  :  </span><span className='employee-card-b-text'>{e.user_detail}</span></div>

                        </div>
                        <div className='employee-card-b-flex'>
                            <div><span className='employee-card-b-heading'>Location  :  </span><span className='employee-card-b-text'>{e.location_name}</span></div>
                            <div><span className='employee-card-b-heading'>Mobile No :  </span><span className='employee-card-b-text'>{e.mobile_number}</span></div>
                        </div>

                    </div>


                </div>

            )
        })}




    </div>)
}
export default EmployeeDetailCard