import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { BASE_URL } from "../../../config/axios"
import axios from "axios";
import Docs from "../../images/docs.svg";
import Careernavbar from "./Careernavbar";
import TimelineItem from "../../Timeline";


const EducationDetail = () => {

  let { id, email } = useParams()


  const [inputFields, setInputFields] = useState([
    { college: "", institution_type: "", department: "", stream: "", startDate: "", endDate: "", grade: "", user: email },
  ]);

  const [errors, setErrors] = useState({});

  const handleChange = (index, e) => {
    const { name, value } = e.target;
    const updatedFields = [...inputFields];
    updatedFields[index][name] = value;
    setInputFields(updatedFields);
  };

  const handleAddInputField = () => {
    setInputFields([
      ...inputFields,
      { college: "", institution_type: "", department: "", stream: "", startDate: "", endDate: "", grade: "", user: email },
    ]);
  };

  const handleRemoveInputField = (index) => {
    const updatedFields = [...inputFields];
    updatedFields.splice(index, 1);
    setInputFields(updatedFields);
  };

  // const validateForm = () => {
  //   const newErrors = {};
  //   inputFields.forEach((field, index) => {
  //     const requiredFields = [
  //       "college",
  //       "department",
  //       "stream",
  //       "institution_type",
  //       "startDate",
  //       "endDate",
  //       "grade",
  //     ];
  //     requiredFields.forEach((reqField) => {
  //       if (!field[reqField]) {
  //         newErrors[`${reqField}_${index}`] = `${reqField.charAt(0).toUpperCase() + reqField.slice(1)
  //           } is required!`;
  //       }
  //     });
  //   });
  //   setErrors(newErrors);
  //   return Object.keys(newErrors).length === 0;
  // };


  const validateForm = () => {
    const newErrors = {};
    const bachelorDegreesWithMandatorySchool = [
      "B.A. (Bachelor of Arts)",
      "B.Com. (Bachelor of Commerce)",
      "B.Tech. (Bachelor of Technology)",
      "B.E. (Bachelor of Engineering )",
      "B.Arch. (Bachelor of Architecture)",
      "Diploma",
      "ITI",
      "Other"
    ];


    const masterDegreesWithMandatorySchool = [
      "M.Com. (Masters of Commerce)",
      "MBA (Master of Business Administration)",
      "M.Tech. (Master of Technology)",
      "M.E. (Master of Engineering )",
      "PhD"
    ];
  
    inputFields.forEach((field, index) => {
      const requiredFields = [
        "college",
        "department",
        "stream",
        "institution_type",
        "startDate",
        "endDate",
        "grade",
      ];
  
      // Basic validation for all fields
      requiredFields.forEach((reqField) => {
        if (!field[reqField]) {
          newErrors[`${reqField}_${index}`] = `${reqField.charAt(0).toUpperCase() + reqField.slice(1)} is required!`;
        }
      });
  
      // Additional validation for specific bachelor degrees
      if (bachelorDegreesWithMandatorySchool.includes(field.department)) {
        // Check if 10th and 12th records exist in the input fields
        const hasTenth = inputFields.some(f => f.department === "10th");
        const hasTwelfth = inputFields.some(f => f.department === "12th");
  
        if (!hasTenth) {
          newErrors[`10th_missing_${index}`] = "Completion of 10th is required for selected bachelor degree.";
        }
        if (!hasTwelfth) {
          newErrors[`12th_missing_${index}`] = "Completion of 12th is required for selected bachelor degree.";
        }
      }


      if (masterDegreesWithMandatorySchool.includes(field.department)){
        const hasTenth = inputFields.some(f => f.department === "10th");
        const hasTwelfth = inputFields.some(f => f.department === "12th");
  
        if (!hasTenth) {
          newErrors[`10th_missing_${index}`] = "Completion of 10th is required for selected master degree.";
        }
        if (!hasTwelfth) {
          newErrors[`12th_missing_${index}`] = "Completion of 12th is required for selected master degree.";
        }
      }
    });
  
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  

  console.log(inputFields);

  const navigate = useNavigate()
  const navigatee = useNavigate()


  const [loading, setLoading] = useState(false); //loading logic

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      setLoading(true); //loading logic
      // Cookies.set("userData", JSON.stringify(formData));
      try {
        let res = await axios.post(`${BASE_URL}/userdata/EmployeeEducationDetailsall/`, inputFields,)
        if (res.status === 200) {
          navigate(`/workexprience/${id}/${email}/`)

        } else {
          alert(res);
        }
      } catch (err) {
        alert(err);
      } finally {
        setLoading(false); //loading logic
      }
    }
  };

  const handleFormPut = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      setLoading(true); //loading logic
      try {
        // Assuming your backend supports updating existing education details via PUT
        await Promise.all(
          inputFields.map(async (educationDetail) => {
            if (educationDetail.id) {
              // Existing education detail, update it
              await axios.put(`${BASE_URL}/userdata/EmployeeEducationDetails/${educationDetail.id}/`, educationDetail);
            } else {
              // New education detail, create it
              await axios.post(`${BASE_URL}/userdata/EmployeeEducationDetails/`, educationDetail);
            }
          })
        );

        navigate(`/workexprience/${id}/${email}/`)
      } catch (err) {
        alert(err);
      } finally {
        setLoading(false); //loading logic
      }
    }
  };

  const backroute = () => {
    navigatee(`/positiondetails/${id}/${email}/`)

  }

  const [data, setData] = useState(false)

  useEffect(() => {
    // const storedUserData = Cookies.get("userData");

    const getUSerDetails = async () => {
      try {
        const res = await axios.get(`${BASE_URL}/userdata/EmployeeEducationDetailsallget/${email}/`);
        if (res.data.length > 0) {
          setInputFields(res.data);
          setData(true);
        }
      } catch (err) {
        // alert(err.message);
      }
    };

    getUSerDetails();
  }, [email]);



  return (
    // <div>

    //   <button type="button" onClick={handleAddInputField}>
    //     Add More Fields
    //   </button>

    // </div>
    <>
      <Careernavbar id={id} />
      <div className="jd">
        {/*  ************** timeline Seaction  ************** */}

        <div className="jd-timeline">
        <TimelineItem heading="Personal Information" color="#2576BC" />
          <div className="jd-timeline-border timeline-color"></div>

          <TimelineItem heading="Position Information" color="#2576BC" />
          <div className="jd-timeline-border timeline-color"></div>

          <TimelineItem heading="Education Detail" color="#2576BC" />
          <div className="jd-timeline-border"></div>
          <TimelineItem heading="Work Experience" color="#707070" />
          <div className="jd-timeline-border"></div>
          <TimelineItem heading="Documents Upload" color="#707070" />
        </div>

        {/* Education Details */}
        <div className="jd-heading-outer">
          <div className="jd-heading-outer-flex">
            <h2 className="jd-heading-main">Education Details*</h2>

            <h2 className="jd-heading-main-right">
              Please click here to add more{" "}
              <button
                type="button"
                onClick={handleAddInputField}
                className="jd-heading-main-right-button"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <g clipPath="url(#clip0_450_2151)">
                    <path
                      d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM17 13H13V17H11V13H7V11H11V7H13V11H17V13Z"
                      fill="#2576BC"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_450_2151">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </button>
            </h2>
          </div>
          <div>
            <div className="jd-heading-bottom-bold"></div>
            <div className="jd-heading-bottom-light"></div>
          </div>
        </div>

        <form className="register-form" >
          {inputFields.map((field, index) => (

            <>
              <div key={index} className="form-flex-wrap">
                <div className="flex-column">
                  <label htmlFor={`college_${index}`} className="form-labels">
                    College/Institution<span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    id={`college_${index}`}
                    maxLength={100}
                    name="college"
                    value={field.college}
                    onChange={(e) => {
                      handleChange(index, e)
                      // handleFileChange(e)
                    }}
                    className={`form-text-trasformation-uppercase form-input ${errors[`college_${index}`]
                      ? "error"
                      : field.college
                        ? "success"
                        : ""
                      }`}
                  />
                  {errors[`college_${index}`] && (
                    <span className="error-message">
                      {errors[`college_${index}`]}
                    </span>
                  )}
                </div>

                <div className="flex-column form-group-selectt">
                  <label htmlFor="department" className="form-labels">
                    Degree Type<span className="required">*</span>
                  </label>
                  <br />
                  <select
                    id="department"
                    name="department"
                    value={field.department}
                    onChange={(e) => handleChange(index, e)}
                    className={`form-input form-group-selection ${errors[`department_${index}`]
                      ? "error"
                      : field.department
                        ? "success"
                        : ""
                      }`}
                  
                  >
                    <option value="">Select a Degree Type</option>
                    <option value="10th">10th</option>
                    <option value="12th">12th</option>
                    <option value="B.A. (Bachelor of Arts)">B.A. (Bachelor of Arts)</option>
                    <option value="B.Com. (Bachelor of Commerce)">B.Com. (Bachelor of Commerce)</option>
                    <option value="M.Com. (Masters of Commerce)">M.Com. (Masters of Commerce)</option>
                    <option value="MBA (Master of Business Administration)">MBA (Master of Business Administration)</option>
                    <option value="M.Tech. (Master of Technology)">M.Tech. (Master of Technology)</option>
                    <option value="M.E. (Master of Engineering )">M.E. (Master of Engineering )</option>
                    <option value="B.Tech. (Bachelor of Technology)">B.Tech. (Bachelor of Technology)</option>
                    <option value="B.E. (Bachelor of Engineering )">B.E. (Bachelor of Engineering )</option>
                    <option value="B.Arch. (Bachelor of Architecture)">B.Arch. (Bachelor of Architecture)</option>
                    <option value="Diploma">Diploma</option>
                    <option value="PhD">PhD</option>
                    <option value="ITI">ITI</option>
                    <option value="Other">Other</option>
                  </select>
                  <div className="form-group-selection-arrow">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="25"
                      fill="currentColor"
                      class="bi bi-caret-down-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                    </svg>
                  </div>
                  <div id={`department-error-${index}`}>
                    {errors[`department_${index}`] && (
                      <span className="error-message">{errors[`department_${index}`]}</span>
                    )}
                  </div>
                </div>

                <div className="flex-column">
                  <label htmlFor={`stream_${index}`} className="form-labels">
                    Stream<span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    id={`stream_${index}`}
                    maxLength={30}
                    name="stream"
                    value={field.stream}
                    onChange={(e) => handleChange(index, e)}
                    className={`form-input ${errors[`stream_${index}`]
                      ? "error"
                      : field.stream
                        ? "success"
                        : ""
                      }`}
                  />
                  {errors[`stream_${index}`] && (
                    <span className="error-message">
                      {errors[`stream_${index}`]}
                    </span>
                  )}
                </div>

                <div className="flex-column form-group-selectt">
                  <label htmlFor="institution_type" className="form-labels">
                    Institution Type<span className="required">*</span>
                  </label>
                  <br />
                  <select
                    id="institution_type"
                    name="institution_type"
                    value={field.institution_type}
                    onChange={(e) => handleChange(index, e)}
                    className={`form-input form-group-selection ${errors[`institution_type_${index}`]
                      ? "error"
                      : field.institution_type
                        ? "success"
                        : ""
                      }`}
                  >
                    <option value="">Select a Institution Type</option>
                    <option value="Government">Government</option>
                    <option value="Private">Private</option>
                  </select>
                  <div className="form-group-selection-arrow">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="25"
                      fill="currentColor"
                      class="bi bi-caret-down-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                    </svg>
                  </div>
                  <div>
                    {errors[`institution_type_${index}`] && (
                      <span className="error-message">{errors[`institution_type_${index}`]}</span>
                    )}
                  </div>
                </div>

                <div className="flex-column">
                  <label htmlFor={`startDate_${index}`} className="form-labels">
                    Start Date<span className="required">*</span>
                  </label>
                  <input
                    type="date"
                    id={`startDate_${index}`}
                    name="startDate"
                    value={field.startDate}
                    onChange={(e) => handleChange(index, e)}
                    className={`form-input ${errors[`startDate_${index}`]
                      ? "error"
                      : field.startDate
                        ? "success"
                        : ""
                      }`}
                  />
                  {errors[`startDate_${index}`] && (
                    <span className="error-message">
                      {errors[`startDate_${index}`]}
                    </span>
                  )}
                </div>

                <div className="flex-column">
                  <label htmlFor={`endDate_${index}`} className="form-labels">
                    End Date<span className="required">*</span>
                  </label>
                  <input
                    type="date"
                    id={`endDate_${index}`}
                    name="endDate"
                    value={field.endDate}
                    onChange={(e) => handleChange(index, e)}
                    className={`form-input ${errors[`endDate_${index}`]
                      ? "error"
                      : field.endDate
                        ? "success"
                        : ""
                      }`}
                  />
                  {errors[`endDate_${index}`] && (
                    <span className="error-message">
                      {errors[`endDate_${index}`]}
                    </span>
                  )}
                </div>


                {/* Similar blocks for Department, Start Date, End Date, and Grade */}
                <div className="flex-column">
                  <label htmlFor={`grade_${index}`} className="form-labels">
                    Grade<span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    id={`grade_${index}`}
                    maxLength={30}
                    name="grade"
                    value={field.grade}
                    onChange={(e) => handleChange(index, e)}
                    className={`form-input ${errors[`grade_${index}`]
                      ? "error"
                      : field.grade
                        ? "success"
                        : ""
                      }`}
                  />
                  {errors[`grade_${index}`] && (
                    <span className="error-message">
                      {errors[`grade_${index}`]}
                    </span>
                  )}
                </div>

                {/* {(field.college != "") ? <div className="flex-column">
                  <label htmlFor="bestAchieve" className="form-labels">
                    Upload Certificate/Proof<span className="required">*</span>
                  </label>

                  <div className="" key={"index"}>
                    <div className="document-icons">
                      <div className="document-name-div">
                        <img
                          src={Docs}
                          alt="Document Icon"
                          className="document-icon"
                        />
                        <div>
                          <div className="document-name">{field.college}</div>
                          <input
                            id={`file_${index}`}
                            // id="file"
                            type="file"
                            name="document_file"
                            fileaddress="document_name"
                            filename={field.college}
                            accept=".pdf"
                            onChange={handleFileChange}
                            className="file-input"
                          />
                        </div>
                      </div>
                      <button
                        type="submit"
                        className="document-download-button"
                        onClick={(e, index) => handleSubmitdoc(e, index)}
                        disabled={loadingDoc}
                      >
                        Upload
                      </button>
                    </div>
                  </div>
                </div> : ""} */}

              </div>
              <div id={`department-error-${index}`}>
                    {errors[`10th_missing_${index}`] && (
                      <span className="error-message">{errors[`10th_missing_${index}`]}</span>
                    )} <br/>
                    {errors[`12th_missing_${index}`] && (
                      <span className="error-message">{errors[`12th_missing_${index}`]}</span>
                    )}
                  </div>
              <div>
                {index > 0 ? (
                  <button
                    type="button"
                    onClick={() => handleRemoveInputField(index)}
                    className="remove-button"
                  >
                    Remove
                  </button>
                ) : (
                  ""
                )}
              </div>
            </>
          ))}

          <div className="button-models">
            <button className="model-button" onClick={backroute}>Back</button>
            {data ? (<button onClick={handleFormPut} className="model-button model-button-submit" disabled={loading}>
              Next
            </button>) : (<button onClick={handleFormSubmit} className="model-button model-button-submit" disabled={loading}>
              Next
            </button>)}
          </div>
        </form>
      </div>

    </>
  );
};

export default EducationDetail;
