
import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { BASE_URL, FRONTEND_URL } from '../../config/axios'

const Assets = () => {


  const [assetname, setAssetname] = useState([])

  const getAsset = async () => {
    try {
      const res = await axios.get(`${BASE_URL}/wfm/assetbyemployee/${sessionStorage.getItem("emp_code")}/`);
      setAssetname(res.data)
    } catch (err) {
      // alert(err.message);
    }
  };


  useEffect(() => {
    getAsset();
  }, []);


  return (
    <>
      <div className="custom-table-background">
        <div className="table-heading-flex">
          <div className="repo-heading">Asset ({assetname.length})</div>
          {assetname.length < 1 ? "No System Alloted" : ""}
          <div></div>
        </div>

        <table className="custom-table">
          <thead>
            <tr className="custom-table-head-tr">
              <th className="align-left">Sr. No.</th>

              <th className="align-center">Date</th>
              <th className="align-center">Description</th>
              <th className="align-center">Quantity</th>
              <th className="align-center">Item</th>


            </tr>
          </thead>
          <tbody>
            {assetname.map((i, index) => (
              <React.Fragment key={index}>
                <tr className="tr-border-bottom">
                  <td colSpan="6"></td>
                </tr>
                <tr className="custom-table-head-td">

                  <td className="align-left">{index + 1}</td>

                  <td className="align-center">{i.date}</td>
                  <td className="align-center">{i.description}</td>
                  <td className="align-center">{i.quantity}</td>
                  <td className="align-center">{i.item}</td>

                </tr>
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </>
  )
}

export default Assets