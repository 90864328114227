import React, { useState } from 'react'
import { Requestoutdutytable, RequestLeavetable, RequestMisspunchtable, RequestCompositortable, RequestImpresttable, RequestExpensetable, RequestClockInTable } from './TlRequestoutdutytable';
import { RequestRequisitionTableRH } from '../../RequisitionManagement/RequisitionManagementTables';

const Requestmanagment = () => {
    const [toggleState, setToggleState] = useState(1);
    return (
        <>
            <div>
                <div >
                    <div className="bloc-tabs-with-white-bg">
                        <button
                            className={toggleState === 1 ? "dashboardcomponent-tabs  dashboardcomponent-active-tabs" : "dashboardcomponent-tabs white-tab"}
                            onClick={() => setToggleState(1)}
                        >
                            Team Out Duty
                        </button>
                        <button
                            className={toggleState === 2 ? "dashboardcomponent-tabs dashboardcomponent-active-tabs" : "dashboardcomponent-tabs white-tab"}
                            onClick={() => setToggleState(2)}
                        >
                            Team Leave
                        </button>
                        {/* <button
                            className={toggleState === 3 ? "dashboardcomponent-tabs dashboardcomponent-active-tabs" : "dashboardcomponent-tabs white-tab"}
                            onClick={() => setToggleState(3)}
                        >
                            Miss Punch
                        </button> */}
                        <button
                            className={toggleState === 4 ? "dashboardcomponent-tabs dashboardcomponent-active-tabs" : "dashboardcomponent-tabs white-tab"}
                            onClick={() => setToggleState(4)}
                        >
                            Compensatory Leave
                        </button>
                        <button
                            className={toggleState === 5 ? "dashboardcomponent-tabs dashboardcomponent-active-tabs" : "dashboardcomponent-tabs white-tab"}
                            onClick={() => setToggleState(5)}
                        >
                            Team Imprest
                        </button>
                        <button
                            className={toggleState === 6 ? "dashboardcomponent-tabs dashboardcomponent-active-tabs" : "dashboardcomponent-tabs white-tab"}
                            onClick={() => setToggleState(6)}
                        >
                            Team Expense
                        </button>
                        <button
                            className={toggleState === 7 ? "dashboardcomponent-tabs dashboardcomponent-active-tabs" : "dashboardcomponent-tabs white-tab"}
                            onClick={() => setToggleState(7)}
                        >
                            Team Requisition
                        </button>
                        <button
                            className={toggleState === 8 ? "dashboardcomponent-tabs dashboardcomponent-active-tabs" : "dashboardcomponent-tabs white-tab"}
                            onClick={() => setToggleState(8)}
                        >
                            Team Clock-In
                        </button>
                    </div>
                </div>

                <div className="content-tabs-without-margin">
                    {toggleState === 1 ? <Requestoutdutytable /> : null}
                    {toggleState === 2 ? <RequestLeavetable /> : null}

                    {toggleState === 3 ? <RequestMisspunchtable /> : null}

                    {toggleState === 4 ? <RequestCompositortable /> : null}
                    {toggleState === 5 ? <RequestImpresttable /> : null}
                    {toggleState === 6 ? <RequestExpensetable /> : null}

                    {toggleState === 7 ? <RequestRequisitionTableRH /> : null}
                    {toggleState === 8 ? <RequestClockInTable /> : null}




                </div>
            </div>
        </>
    )
}

export default Requestmanagment
