import { Outlet } from 'react-router-dom';
import { sidebarData } from './It_sidebar';
import DashboardSidebar from "../../layout/DashboardSidebar";
import React, {useState} from 'react'
import { Modal } from "react-bootstrap";


const It_Dashboard = () => {

    const [isexpand, setExpanded] = useState(false); 

    const [show, setShow] = useState(false); //Announcement Notify

    const handleClose = () => setShow(false);
  
    return (
      <>
        <div className="dashboard-main-section">
            <DashboardSidebar sidebarData={sidebarData}  isexpand={isexpand} setExpanded={setExpanded}/>
          <div className={isexpand ? "dashboard-main-content-sectionn" : "dashboard-main-content-sectionnn"}>
            <Outlet/>
          </div>
          <Modal show={show} onHide={handleClose} dialogClassName="model-annouce">
        <Modal.Header closeButton>

        </Modal.Header>
        <Modal.Body >
        <img src="https://cipl-aimantra.s3.ap-south-1.amazonaws.com/informationboard/Untitled+design+(10).png" alt="demo" className="model-annoucee" />

        </Modal.Body>
      </Modal>
        </div>
      </>
    );
  }; 
  
  
  export default It_Dashboard