import axios from "axios";
import React, { useEffect, useState } from "react";
import { PieChart, Pie, Cell } from "recharts";
import { datearray } from "../Date";
import { BASE_URL } from "../../config/axios";





const AttendanceArc = () => {



    const [selectedOption, setSelectedOption] = useState('today');

    const [attendancearc, setAttendancearc] = useState({});

    const dateData = new Date();
    const year = dateData.getFullYear();
    const month = dateData.getMonth() + 1;
    const day = dateData.getDate();
    const yday = dateData.getDate() - 1;

    const today = `${year}-${month.toString().padStart(2, "0")}-${day
        .toString()
        .padStart(2, "0")}`;

    const yesterday = `${year}-${month.toString().padStart(2, "0")}-${yday
        .toString()
        .padStart(2, "0")}`;


    const getJobapppdata = async (selectedOption) => {
        try {
            const res = await axios.get(
                selectedOption === "today"
                    // ? `${BASE_URL}/wfm/attendancefilterbyEmpByMonthStats/${sessionStorage.getItem('emp_code')}/${datearray[1]}/${datearray[0]}/`
                    // : `${BASE_URL}/wfm/attendancefilterbyEmpByYearStats/${sessionStorage.getItem('emp_code')}/${datearray[0]}/`
                    ? `${BASE_URL}/wfm/attendancecount/${today}/`
                    : `${BASE_URL}/wfm/attendancecount/${yesterday}/`
            );

            setAttendancearc(res.data);
        } catch (err) {
            alert(err.message);
        }
    };

    useEffect(() => {
        getJobapppdata(selectedOption);
    }, [selectedOption]);

    const handleChange = (event) => {
        setSelectedOption(event.target.value);
    };


    const data = [
        { name: "Group A", value: attendancearc.absent_count },
        { name: "Group B", value: attendancearc.total - attendancearc.absent_count },
    ];


    const leavepercentage = Math.floor(attendancearc.absent_count / attendancearc.total * 100)

    const dataa = [
        { name: "Group A", value: attendancearc.leave_count },
        { name: "Group B", value: attendancearc.total - attendancearc.leave_count },
    ];


    const latepercentage = Math.floor(attendancearc.leave_count / attendancearc.total * 100)


    const dataaa = [
        { name: "Group A", value: attendancearc.present_count },
        { name: "Group B", value: attendancearc.total - attendancearc.present_count },
    ];


    const presentpercentage = Math.floor(attendancearc.present_count / attendancearc.total * 100)

    const AbsentCOLOURS = ["#FF747C", "#EFEFEF"];

    const LeaveCOLOURS = ["#F7BA1E", "#EFEFEF"];

    const PresentCOLORS = ["#06AD06", "#EFEFEF"];


    return (
        <>
            <div className="hrdb-piechartt">
                <div>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <div className="repo-heading-a">Attendance</div>
                        <div className="radialchart-dropdown">
                            <select style={{ border: "none" }} onChange={handleChange} value={selectedOption}>
                                <option value="today">Today</option>
                                <option value="yesterday">Yesterday</option>
                            </select>
                        </div>
                    </div>
                    <div className="hr-piechart-flex">

                        <div className="hr-piechart-dashboard">
                            <div>
                                <PieChart width={220} height={150}>
                                    <Pie
                                        data={dataaa}
                                        cx={105}
                                        cy={120}
                                        startAngle={180}
                                        endAngle={0}
                                        innerRadius={70}
                                        cornerRadius={20}
                                        outerRadius={90}
                                        fill="#8884d8"
                                        paddingAngle={3}
                                        dataKey="value"
                                    >
                                        {dataaa.map((entry, index) => (
                                            <Cell
                                                key={`cell-${index}`}
                                                fill={PresentCOLORS[index % PresentCOLORS.length]}
                                            />
                                        ))}
                                    </Pie>
                                </PieChart>
                            </div>
                            <div className="hr-piechart-heading">
                                <div
                                    className="hr-piechart-dot"
                                    style={{ backgroundColor: "#06AD06" }}
                                ></div>
                                <div className="hr-piechart-subheading">Present Employee</div>
                            </div>
                            <div className="hr-piechart-percentage">{presentpercentage} % ({attendancearc.present_count})</div>
                        </div>


                        <div className="hr-piechart-dashboard">
                            <div>
                                <PieChart width={220} height={150}>
                                    <Pie
                                        data={dataa}
                                        cx={105}
                                        cy={120}
                                        startAngle={180}
                                        endAngle={0}
                                        innerRadius={70}
                                        cornerRadius={20}
                                        outerRadius={90}
                                        fill="#8884d8"
                                        paddingAngle={3}
                                        dataKey="value"
                                    >
                                        {dataa.map((entry, index) => (
                                            <Cell
                                                key={`cell-${index}`}
                                                fill={LeaveCOLOURS[index % LeaveCOLOURS.length]}
                                            />
                                        ))}
                                    </Pie>
                                </PieChart>
                            </div>
                            <div className="hr-piechart-heading">
                                <div
                                    className="hr-piechart-dot"
                                    style={{ backgroundColor: "#F7BA1E" }}
                                ></div>
                                <div className="hr-piechart-subheading">Leave Employee</div>
                            </div>
                            <div className="hr-piechart-percentage">{latepercentage} % ({attendancearc.leave_count})</div>
                        </div>



                        <div className="hr-piechart-dashboard">
                            <div>
                                <PieChart width={220} height={150}>
                                    <Pie
                                        data={data}
                                        cx={105}
                                        cy={120}
                                        startAngle={180}
                                        endAngle={0}
                                        innerRadius={70}
                                        cornerRadius={20}
                                        outerRadius={90}
                                        fill="#8884d8"
                                        paddingAngle={3}
                                        dataKey="value"
                                    >
                                        {data.map((entry, index) => (
                                            <Cell
                                                key={`cell-${index}`}
                                                fill={AbsentCOLOURS[index % AbsentCOLOURS.length]}
                                            />
                                        ))}
                                    </Pie>
                                </PieChart>
                            </div>
                            <div className="hr-piechart-heading">
                                <div
                                    className="hr-piechart-dot"
                                    style={{ backgroundColor: "#FF747C" }}
                                ></div>
                                <div className="hr-piechart-subheading">Absent Employee</div>
                            </div>
                            <div className="hr-piechart-percentage">{leavepercentage} % ({attendancearc.absent_count})</div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    );
};

export default AttendanceArc;
