import React, { useState, useEffect } from "react";
// import "../../assets/css/components.css";
import LeaveChart from "./userDetails-comp/LeaveChart";
import { BASE_URL } from "../../config/axios";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import { formatDate } from "../Date";
import { useParams } from "react-router-dom";
import OutDutyChart from "./userDetails-comp/OdChart";





const LeaveModelDelete = ({ i, getAllrequest }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleFormSubmitDelete = async (e) => {
    e.preventDefault();

    try {
      let res = await axios.delete(`${BASE_URL}/wfm/leavedelete/${i.id}/`);

      if (res.status === 200) {
        await getAllrequest();
        setShow(false);
      } else {
        alert(res);
      }
    } catch (err) {
      alert(err);
    }
  };

  return (
    <>
      <button className="model-delete-button" onClick={handleShow}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M15.1875 3.375H12.375V2.8125C12.375 2.36495 12.1972 1.93572 11.8807 1.61926C11.5643 1.30279 11.1351 1.125 10.6875 1.125H7.3125C6.86495 1.125 6.43572 1.30279 6.11926 1.61926C5.80279 1.93572 5.625 2.36495 5.625 2.8125V3.375H2.8125C2.66332 3.375 2.52024 3.43426 2.41475 3.53975C2.30926 3.64524 2.25 3.78832 2.25 3.9375C2.25 4.08668 2.30926 4.22976 2.41475 4.33525C2.52024 4.44074 2.66332 4.5 2.8125 4.5H3.375V14.625C3.375 14.9234 3.49353 15.2095 3.7045 15.4205C3.91548 15.6315 4.20163 15.75 4.5 15.75H13.5C13.7984 15.75 14.0845 15.6315 14.2955 15.4205C14.5065 15.2095 14.625 14.9234 14.625 14.625V4.5H15.1875C15.3367 4.5 15.4798 4.44074 15.5852 4.33525C15.6907 4.22976 15.75 4.08668 15.75 3.9375C15.75 3.78832 15.6907 3.64524 15.5852 3.53975C15.4798 3.43426 15.3367 3.375 15.1875 3.375ZM6.75 2.8125C6.75 2.66332 6.80926 2.52024 6.91475 2.41475C7.02024 2.30926 7.16332 2.25 7.3125 2.25H10.6875C10.8367 2.25 10.9798 2.30926 11.0852 2.41475C11.1907 2.52024 11.25 2.66332 11.25 2.8125V3.375H6.75V2.8125ZM13.5 14.625H4.5V4.5H13.5V14.625ZM7.875 7.3125V11.8125C7.875 11.9617 7.81574 12.1048 7.71025 12.2102C7.60476 12.3157 7.46168 12.375 7.3125 12.375C7.16332 12.375 7.02024 12.3157 6.91475 12.2102C6.80926 12.1048 6.75 11.9617 6.75 11.8125V7.3125C6.75 7.16332 6.80926 7.02024 6.91475 6.91475C7.02024 6.80926 7.16332 6.75 7.3125 6.75C7.46168 6.75 7.60476 6.80926 7.71025 6.91475C7.81574 7.02024 7.875 7.16332 7.875 7.3125ZM11.25 7.3125V11.8125C11.25 11.9617 11.1907 12.1048 11.0852 12.2102C10.9798 12.3157 10.8367 12.375 10.6875 12.375C10.5383 12.375 10.3952 12.3157 10.2898 12.2102C10.1843 12.1048 10.125 11.9617 10.125 11.8125V7.3125C10.125 7.16332 10.1843 7.02024 10.2898 6.91475C10.3952 6.80926 10.5383 6.75 10.6875 6.75C10.8367 6.75 10.9798 6.80926 11.0852 6.91475C11.1907 7.02024 11.25 7.16332 11.25 7.3125Z"
            fill="#F72B50"
          />
        </svg>
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="request-leave">
        <Modal.Header closeButton>
          <Modal.Title>Delete Leave</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="parent-div">
            Are You Sure You Want to delete Leave Request of Date {i.date}
            <div className="button-models">
              <button className="model-button " onClick={handleClose}>
                Cancel
              </button>
              <button
                onClick={handleFormSubmitDelete}
                className="model-button model-button-delete"
              >
                Delete
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}



const AllRequest = ({ title }) => {

  let { emp_code } = useParams()
  const [allrequest, setAllRequest] = useState([])


  const getAllrequest = async () => {
    try {
      const res = await axios.get(`${BASE_URL}/wfm/leavebyemployee/${emp_code}/`);
      const ress = await axios.get(`${BASE_URL}/wfm/odbyemployee/${emp_code}/`);

      const combinedData = res.data.concat(ress.data);
      setAllRequest(combinedData);

    } catch (err) {
    }
  };

  console.log(allrequest)
  useEffect(() => {
    getAllrequest();
  }, []);


  return (
    <div className="custom-table-background">
      <div className="table-heading-flex">
        <div className="repo-heading"> {title} Request
        </div>
        <div>
          {/* <Department getDepartment={getDepartment}/> */}
        </div>
      </div>
      <table className="custom-table">
        <thead>
          <tr className="custom-table-head-tr">
            <th className="align-left">Sr no.</th>
            <th className="align-leftt">Date</th>
            <th className="align-center">Type</th>
            <th className="align-center">Duration</th>
            <th className="align-center">Status</th>
            {/* <th className="align-right">Action</th> */}
          </tr>
        </thead>
        <tbody>
          {allrequest?.sort((a, b) => new Date(a.date) - new Date(b.date)).map((i, index) => (
            <React.Fragment key={index}>
              <tr className="tr-border-bottom">
                <td colSpan="9"></td>
              </tr>
              <tr className="custom-table-head-td">
                <td className="align-left">{index + 1}</td>
                <td className="align-leftt">{formatDate(i.date)} &<br /> {formatDate(i.end_date)}</td>
                <td className="align-center">{i.leave_Duration ? "Leave" : ""} {i.outduty_Duration ? "OutDuty" : ""}</td>
                <td className="align-center form-text-trasformation-uppercase">{i.leave_Duration ? i.leave_Duration : i.outduty_Duration}</td>
                {/* <td className="align-center">{i.rh_leave_status}</td> */}
                <td className="align-center">
                  <span request-status={i.rh_leave_status} className="request-status align-center" >{i.rh_leave_status === "pending" ? "Pending For Rh Approval ⌛" : i.rh_leave_status === "rejected" ? "Rh Rejected ✖, " : "Rh Approved ✔, "}</span>
                  <br />
                  <span request-status={i.admin_leave_status} className="request-status align-center" >{(i.rh_leave_status === "rejected" && i.admin_leave_status === "rejected") ? "Admin ✖, " : (i.rh_leave_status === "rejected" && i.admin_leave_status === "pending") ? "Pending For Admin Approval ⌛," : (i.rh_leave_status === "approved" && i.admin_leave_status === "pending") ? "Pending For Admin Approval ⌛," : (i.rh_leave_status === "approved" && i.admin_leave_status === "rejected") ? "Admin ✖, " : (i.rh_leave_status === "approved" && i.admin_leave_status === "approved") ? "Admin ✔, " : ""}</span>
                  {/* <span request-status={i.admin_leave_status} className="request-status align-center" >{(i.rh_leave_status === "rejected" && i.admin_leave_status === "rejected") ? "Admin Rejected ✖, " : (i.rh_leave_status === "approved" && i.admin_leave_status === "pending") ? "Pending For Admin Approval ⌛," : (i.rh_leave_status === "approved" && i.admin_leave_status === "rejected") ? "Admin Rejected ✖, " : (i.rh_leave_status === "approved" && i.admin_leave_status === "approved") ? "Admin Approved ✔, " : ""}</span> */}
                </td>
                {/* <td className="align-right ">
                   {i.rh_leave_status  ==="pending" ? <LeaveModelDelete i={i} getAllrequest={getAllrequest} /> : ""}
                </td> */}

              </tr>
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  )
}

const LeaveTable = ({ title }) => {

  let { emp_code } = useParams()


  const [allrequest, setAllRequest] = useState([])


  const getAllrequest = async () => {
    try {
      const res = await axios.get(`${BASE_URL}/wfm/leavebyemployee/${emp_code}/`);
      setAllRequest(res.data);

    } catch (err) {
    }
  };


  useEffect(() => {
    getAllrequest();
  }, []);


  return (
    <div className="custom-table-background">
      <div className="table-heading-flex">
        <div className="repo-heading"> {title} Request
        </div>
        <div>
          {/* <Department getDepartment={getDepartment}/> */}
        </div>
      </div>
      <table className="custom-table">
        <thead>
          <tr className="custom-table-head-tr">
            <th className="align-left">Sr no.</th>
            <th className="align-leftt">Date</th>
            <th className="align-center">Type</th>
            <th className="align-center">Duration</th>
            <th className="align-center">Status</th>
            {/* <th className="align-right">Action</th> */}
          </tr>
        </thead>
        <tbody>
          {allrequest?.sort((a, b) => new Date(a.date) - new Date(b.date)).map((i, index) => (
            <React.Fragment key={index}>
              <tr className="tr-border-bottom">
                <td colSpan="9"></td>
              </tr>
              <tr className="custom-table-head-td">
                <td className="align-left">{index + 1}</td>
                <td className="align-leftt">{formatDate(i.date)} &<br /> {formatDate(i.end_date)}</td>
                <td className="align-center">{i.leave_Duration ? "Leave" : ""} {i.outduty_Duration ? "OutDuty" : ""}</td>
                <td className="align-center form-text-trasformation-uppercase">{i.leave_Duration ? i.leave_Duration : i.outduty_Duration}</td>
                {/* <td className="align-center">{i.rh_leave_status}</td> */}
                <td className="align-center">
                  <span request-status={i.rh_leave_status} className="request-status align-center" >{i.rh_leave_status === "pending" ? "Pending For Rh Approval ⌛" : i.rh_leave_status === "rejected" ? "Rh Rejected ✖, " : "Rh Approved ✔, "}</span>
                  <br />
                  <span request-status={i.admin_leave_status} className="request-status align-center" >{(i.rh_leave_status === "rejected" && i.admin_leave_status === "rejected") ? "Admin Rejected ✖, " : (i.rh_leave_status === "approved" && i.admin_leave_status === "pending") ? "Pending For Admin Approval ⌛," : (i.rh_leave_status === "approved" && i.admin_leave_status === "rejected") ? "Admin Rejected ✖, " : (i.rh_leave_status === "approved" && i.admin_leave_status === "approved") ? "Admin Approved ✔, " : ""}</span>
                </td>
                {/* <td className="align-right ">
                   {i.rh_leave_status  ==="pending" ? <LeaveModelDelete i={i} getAllrequest={getAllrequest} /> : ""}
                </td> */}

              </tr>
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  )
}

const OutdutyTable = ({ title }) => {


  let { emp_code } = useParams()


  const [allrequest, setAllRequest] = useState([])


  const getAllrequest = async () => {
    try {
      const res = await axios.get(`${BASE_URL}/wfm/odbyemployee/${emp_code}/`);
      setAllRequest(res.data);

    } catch (err) {
    }
  };

  useEffect(() => {
    getAllrequest();
  }, []);


  return (
    <div className="custom-table-background">
      <div className="table-heading-flex">
        <div className="repo-heading"> {title} Request
        </div>
        <div>
          {/* <Department getDepartment={getDepartment}/> */}
        </div>
      </div>
      <table className="custom-table">
        <thead>
          <tr className="custom-table-head-tr">
            <th className="align-left">Sr no.</th>
            <th className="align-leftt">Date</th>
            <th className="align-center">Type</th>
            <th className="align-center">Duration</th>
            <th className="align-center">Status</th>
            {/* <th className="align-right">Action</th> */}
          </tr>
        </thead>
        <tbody>
          {allrequest?.sort((a, b) => new Date(a.date) - new Date(b.date)).map((i, index) => (
            <React.Fragment key={index}>
              <tr className="tr-border-bottom">
                <td colSpan="9"></td>
              </tr>
              <tr className="custom-table-head-td">
                <td className="align-left">{index + 1}</td>
                <td className="align-leftt">{formatDate(i.date)} &<br /> {formatDate(i.end_date)}</td>
                <td className="align-center">{i.leave_Duration ? "Leave" : ""} {i.outduty_Duration ? "OutDuty" : ""}</td>
                <td className="align-center form-text-trasformation-uppercase">{i.leave_Duration ? i.leave_Duration : i.outduty_Duration}</td>
                {/* <td className="align-center">{i.rh_leave_status}</td> */}
                <td className="align-center">
                  <span request-status={i.rh_leave_status} className="request-status align-center" >{i.rh_leave_status === "pending" ? "Pending For Rh Approval ⌛" : i.rh_leave_status === "rejected" ? "Rh Rejected ✖, " : "Rh Approved ✔, "}</span>
                  <br />
                  {/* <span request-status={i.admin_leave_status} className="request-status align-center" >{(i.rh_leave_status === "rejected" && i.admin_leave_status === "rejected") ? "Admin ✖, " : (i.rh_leave_status === "rejected" && i.admin_leave_status === "pending") ? "Pending For Admin Approval ⌛," : (i.rh_leave_status === "approved" && i.admin_leave_status === "pending") ? "Pending For Admin Approval ⌛," : (i.rh_leave_status === "approved" && i.admin_leave_status === "rejected") ? "Admin ✖, " : (i.rh_leave_status === "approved" && i.admin_leave_status === "approved") ? "Admin ✔, " : ""}</span> */}
                  <span request-status={i.admin_leave_status} className="request-status align-center" >{(i.rh_leave_status === "rejected" && i.admin_leave_status === "rejected") ? "Admin Rejected ✖, " : (i.rh_leave_status === "approved" && i.admin_leave_status === "pending") ? "Pending For Admin Approval ⌛," : (i.rh_leave_status === "approved" && i.admin_leave_status === "rejected") ? "Admin Rejected ✖, " : (i.rh_leave_status === "approved" && i.admin_leave_status === "approved") ? "Admin Approved ✔, " : ""}</span>
                </td>
                {/* <td className="align-right ">
                   {i.rh_leave_status  ==="pending" ? <LeaveModelDelete i={i} getAllrequest={getAllrequest} /> : ""}
                </td> */}

              </tr>
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  )
}




const Leave = () => {
  const [toggleState, setToggleState] = useState(2);
  let { emp_code } = useParams()

  const [statData, setStatData] = useState({});
  const getStatdata = async () => {
    try {
      const res = await axios.get(`${BASE_URL}/wfm/leavebalance/${emp_code}`);
      console.log(res.data)
      setStatData(res.data);
    } catch (err) {
      alert(err.message);
    }
  };

  const [graphData, setGraphData] = useState({});
  const getGraphdata = async () => {
    try {
      const res = await axios.get(`${BASE_URL}/wfm/requeststatus/${emp_code}`);
      console.log(res.data)
      setGraphData(res.data);
    } catch (err) {
      alert(err.message);
    }
  };


  useEffect(() => {
    getStatdata();
    getGraphdata();
  }, []);


  const getSelectedTitle = () => {
    switch (toggleState) {
      case 1:
        return 'All';
      case 2:
        return 'Leave';
      case 3:
        return 'Outduty';
      case 4:
        return 'Other';

      default:
        return 'All';
    }
  };

  return (
    <>
      {toggleState === 2
        ?
        (
          <div>

            <div className="attendance-top-cont">
              <div className="profile-leave-primary">

                <div className="profile-leave-status">
                  <span className="profile-leave-status-heading">Total Leave Requests</span>
                  <span>{graphData.allleaverequest} </span>
                </div>
                <div className="profile-leave-status">
                  <span className="profile-leave-status-heading">
                    Pending Requests
                  </span>
                  <span>{graphData.leave_details_pending} </span>
                </div>
                <div className="profile-leave-status">
                  <span className="profile-leave-status-heading">
                    Approved Requests
                  </span>
                  <span>{graphData.leave_details_approved} </span>
                </div>
                <div className="profile-leave-status">
                  <span className="profile-leave-status-heading">
                    Rejected Requests
                  </span>
                  <span>{graphData.leave_details_rejected} </span>
                </div>


              </div>

              <div className="profile-leave-secondary">
                <LeaveChart />
              </div>
            </div>

            <br />
            <div className="bloc-tabs-with-white-bg">
              <div className="field-container" style={{ display: 'flex', justifyContent: 'space-evenly' }}>

                <div className="bloc-tabs-lb">

                  <label className="age-label-main">
                    Previous Leaves:{" "}
                    <span className="age-label">{statData.leavebalance ? `${statData.leavebalance} leave` : "No Remaining"}</span>
                  </label>
                </div>
                <div className="bloc-tabs-lb">

                  <label className="age-label-main">
                    Earned Leaves:{" "}
                    <span className="age-label">{statData.earn_leave ? `${statData.earn_leave} leave` : "No Remaining"}</span>
                  </label>

                </div>
                <div className="bloc-tabs-lb">
                  <label className="age-label-main">
                    Casual Leaves:{" "}
                    <span className="age-label">{statData.casual_leave ? `${statData.casual_leave} leave` : "No Remaining"}</span>
                  </label>
                </div>

                <div className="bloc-tabs-lb">
                  <label className="age-label-main">
                    Sick Leaves:{" "}
                    <span className="age-label">{statData.sick_leave ? `${statData.sick_leave} leave` : "No Remaining"}</span>
                  </label>
                </div>

              </div>
              <div className="btn-cont">
              </div>
            </div>
          </div>
        )
        : (<div className="attendance-top-cont">
          <div className="profile-leave-primary">

            <div className="profile-leave-status">
              <span className="profile-leave-status-heading">Total OutDuty Requests</span>
              <span>{graphData.alloutdutyrequest} </span>
            </div>
            <div className="profile-leave-status">
              <span className="profile-leave-status-heading">
                Pending requests
              </span>
              <span>{graphData.outduty_details_pending} </span>
            </div>
            <div className="profile-leave-status">
              <span className="profile-leave-status-heading">
                Approved requests
              </span>
              <span>{graphData.outduty_details_approved} </span>
            </div>
            <div className="profile-leave-status">
              <span className="profile-leave-status-heading">
                Rejected requests
              </span>
              <span>{graphData.outduty_details_rejected} </span>
            </div>
          </div>

          <div className="profile-leave-secondary">
            <OutDutyChart />
          </div>
        </div>)}
      {/* <div className="attendance-heading">Request History</div> */}

      <div className="bloc-tabss">
        {/* <button
          className={
            "leave-tab " +
            (toggleState === 1
              ? "dashboardcomponent-tabs dashboardcomponent-active-tabs dashboardcomponent-tabss"
              : "dashboardcomponent-tabs dashboardcomponent-tabss")
          }
          onClick={() => setToggleState(1)}
        >
          All
        </button> */}
        <button
          className={
            "leave-tab " +
            (toggleState === 2
              ? "dashboardcomponent-tabs dashboardcomponent-active-tabs dashboardcomponent-tabss"
              : "dashboardcomponent-tabs dashboardcomponent-tabss")
          }
          onClick={() => setToggleState(2)}
        >
          Leave
        </button>
        <button
          className={
            "leave-tab " +
            (toggleState === 3
              ? "dashboardcomponent-tabs dashboardcomponent-active-tabs dashboardcomponent-tabss"
              : "dashboardcomponent-tabs dashboardcomponent-tabss")
          }
          onClick={() => setToggleState(3)}
        >
          Outduty
        </button>

        {/* <button
            className={
              "leave-tab " +
              (toggleState === 4
                ? "dashboardcomponent-tabs dashboardcomponent-active-tabs dashboardcomponent-tabss"
                : "dashboardcomponent-tabs dashboardcomponent-tabss")
            }
            onClick={() => setToggleState(4)}
          >
            Other
          </button> */}
      </div>

      <div className="content-tab-nomargin">
        {toggleState === 1 ? <AllRequest title={getSelectedTitle()} /> : null}
        {toggleState === 2 ? <LeaveTable title={getSelectedTitle()} /> : null}
        {toggleState === 3 ? <OutdutyTable title={getSelectedTitle()} /> : null}
        {/* {toggleState === 4 ? <LeaveTable title={getSelectedTitle()}/>  : null} */}
      </div>

    </>
  );
};

export default Leave;