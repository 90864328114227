import axios from "axios";
import React, { useEffect, useState } from "react";
import { PieChart, Pie, Cell } from "recharts";
import { datearray } from "../Date";
import { BASE_URL } from "../../config/axios";





const Halfarc = () => {
  const [selectedOption, setSelectedOption] = useState('monthly');
  const [halfarc, setHalfarc] = useState({});

  const getJobapppdata = async (selectedOption) => {
    try {
      const res = await axios.get(
        selectedOption === "monthly"
          ? `${BASE_URL}/wfm/attendancefilterbyEmpByMonthStats/${sessionStorage.getItem('emp_code')}/${datearray[1]}/${datearray[0]}/`
          : `${BASE_URL}/wfm/attendancefilterbyEmpByYearStats/${sessionStorage.getItem('emp_code')}/${datearray[0]}/`
      );
      
      setHalfarc(res.data);
    } catch (err) {
      alert(err.message);
    }
  };

  useEffect(() => {
    getJobapppdata(selectedOption);
  }, [selectedOption]);

  const handleChange = (event) => {
    setSelectedOption(event.target.value);
  };


  const data = [
    { name: "Group A", value: halfarc.absent },
    { name: "Group B", value: halfarc.total - halfarc.absent },
  ];


  const absentpercentage = Math.floor(halfarc.absent/ halfarc.total *100)
  
  const dataa = [
    { name: "Group A", value: halfarc.leave },
    { name: "Group B", value: halfarc.total - halfarc.leave },
  ];


  const leavepercentage = Math.floor(halfarc.leave/ halfarc.total *100)
  

  const dataaa = [
    { name: "Group A", value: halfarc.present },
    { name: "Group B", value: halfarc.total - halfarc.present },
  ];


  const presentpercentage = Math.floor(halfarc.present/ halfarc.total *100)

  const AbsentCOLORS = ["#FF747C", "#EFEFEF"];
  
  const LateCOLORS = ["#F7BA1E", "#EFEFEF"];
  
  const PresentCOLORS = ["#06AD06", "#EFEFEF"];


  return (
    <>
      <div className="piechartt">
      <div className="piechart-headings" style={{ display: "flex", justifyContent: "space-between" }}>
        <div className="repo-heading-a">Attendance</div>
        <div className="radialchart-dropdown">
          <select style={{border: "none"}} onChange={handleChange} value={selectedOption}>
            <option value="thisYear">This Year</option>
            <option value="monthly">Monthly</option>
          </select>
        </div>
      </div>
        <div className="piechart-flex">
          <div className="piechart-dashboard">
            <div className="single-piechart">
              <PieChart width={120} height={80}>
                <Pie
                  data={data}
                  cx={60}
                  cy={70}
                  startAngle={180}
                  endAngle={0}
                  innerRadius={35}
                  cornerRadius={10}
                  outerRadius={50}
                  fill="#8884d8"
                  paddingAngle={3}
                  dataKey="value"
                >
                  {data.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={AbsentCOLORS[index % AbsentCOLORS.length]}
                    />
                  ))}
                </Pie>
              </PieChart>
            </div>
            <div className="piechart-heading">
              <div
                className="piechart-dot"
                style={{ backgroundColor: "#FF747C" }}
              ></div>
              <div className="piechart-subheading">Absent</div>
            </div>
            <div className="piechart-percentage">{absentpercentage} % ({halfarc.absent})</div>
          </div>

          <div className="piechart-dashboard">
            <div>
              <PieChart width={120} height={80}>
                <Pie
                  data={dataa}
                  cx={60}
                  cy={70}
                  startAngle={180}
                  endAngle={0}
                  innerRadius={35}
                  cornerRadius={10}
                  outerRadius={50}
                  fill="#8884d8"
                  paddingAngle={3}
                  dataKey="value"
                >
                  {dataa.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={LateCOLORS[index % LateCOLORS.length]}
                    />
                  ))}
                </Pie>
              </PieChart>
            </div>
            <div className="piechart-heading">
              <div
                className="piechart-dot"
                style={{ backgroundColor: "#F7BA1E" }}
              ></div>
              <div className="piechart-subheading">Leave</div>
            </div>
            <div className="piechart-percentage">{leavepercentage} %</div>
          </div>

          <div className="piechart-dashboard">
            <div>
              <PieChart width={120} height={80}>
                <Pie
                  data={dataaa}
                  cx={60}
                  cy={70}
                  startAngle={180}
                  endAngle={0}
                  innerRadius={35}
                  cornerRadius={10}
                  outerRadius={50}
                  fill="#8884d8"
                  paddingAngle={3}
                  dataKey="value"
                >
                  {dataaa.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={PresentCOLORS[index % PresentCOLORS.length]}
                    />
                  ))}
                </Pie>
              </PieChart>
            </div>
            <div className="piechart-heading">
              <div
                className="piechart-dot"
                style={{ backgroundColor: "#06AD06" }}
              ></div>
              <div className="piechart-subheading">Present</div>
            </div>
            <div className="piechart-percentage">{presentpercentage} %</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Halfarc;
