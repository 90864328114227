import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import { BASE_URL } from "../../../config/axios";




const ExpenseDetailDelete = ({ id, getExpenses, date, getSpecificExpenses }) => {
  const [show, setShow] = useState(false);

  // console.log(id)

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleFormSubmitDelete = async (item_name) => {
    item_name.preventDefault();

    try {

      console.log("Delete Expense Detail Api")
      let res = await axios.delete(`${BASE_URL}/wfm/expensedetailsdelete/${id.id}/`);

      if (res.status === 200) {
        await getExpenses(date);
        getSpecificExpenses();
        setShow(false);
      } else {
        alert(res);
      }
    } catch (err) {
      alert(err);
    }
  };

  return (
    <>
      <button className="model-delete-button" onClick={handleFormSubmitDelete}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M15.1875 3.375H12.375V2.8125C12.375 2.36495 12.1972 1.93572 11.8807 1.61926C11.5643 1.30279 11.1351 1.125 10.6875 1.125H7.3125C6.86495 1.125 6.43572 1.30279 6.11926 1.61926C5.80279 1.93572 5.625 2.36495 5.625 2.8125V3.375H2.8125C2.66332 3.375 2.52024 3.43426 2.41475 3.53975C2.30926 3.64524 2.25 3.78832 2.25 3.9375C2.25 4.08668 2.30926 4.22976 2.41475 4.33525C2.52024 4.44074 2.66332 4.5 2.8125 4.5H3.375V14.625C3.375 14.9234 3.49353 15.2095 3.7045 15.4205C3.91548 15.6315 4.20163 15.75 4.5 15.75H13.5C13.7984 15.75 14.0845 15.6315 14.2955 15.4205C14.5065 15.2095 14.625 14.9234 14.625 14.625V4.5H15.1875C15.3367 4.5 15.4798 4.44074 15.5852 4.33525C15.6907 4.22976 15.75 4.08668 15.75 3.9375C15.75 3.78832 15.6907 3.64524 15.5852 3.53975C15.4798 3.43426 15.3367 3.375 15.1875 3.375ZM6.75 2.8125C6.75 2.66332 6.80926 2.52024 6.91475 2.41475C7.02024 2.30926 7.16332 2.25 7.3125 2.25H10.6875C10.8367 2.25 10.9798 2.30926 11.0852 2.41475C11.1907 2.52024 11.25 2.66332 11.25 2.8125V3.375H6.75V2.8125ZM13.5 14.625H4.5V4.5H13.5V14.625ZM7.875 7.3125V11.8125C7.875 11.9617 7.81574 12.1048 7.71025 12.2102C7.60476 12.3157 7.46168 12.375 7.3125 12.375C7.16332 12.375 7.02024 12.3157 6.91475 12.2102C6.80926 12.1048 6.75 11.9617 6.75 11.8125V7.3125C6.75 7.16332 6.80926 7.02024 6.91475 6.91475C7.02024 6.80926 7.16332 6.75 7.3125 6.75C7.46168 6.75 7.60476 6.80926 7.71025 6.91475C7.81574 7.02024 7.875 7.16332 7.875 7.3125ZM11.25 7.3125V11.8125C11.25 11.9617 11.1907 12.1048 11.0852 12.2102C10.9798 12.3157 10.8367 12.375 10.6875 12.375C10.5383 12.375 10.3952 12.3157 10.2898 12.2102C10.1843 12.1048 10.125 11.9617 10.125 11.8125V7.3125C10.125 7.16332 10.1843 7.02024 10.2898 6.91475C10.3952 6.80926 10.5383 6.75 10.6875 6.75C10.8367 6.75 10.9798 6.80926 11.0852 6.91475C11.1907 7.02024 11.25 7.16332 11.25 7.3125Z"
            fill="#F72B50"
          />
        </svg>
      </button>

      {/* <Modal show={show} onHide={handleClose} dialogClassName="request-leave">
        <Modal.Body>
          
            <div className="button-models">
              
              <button
                onClick={handleFormSubmitDelete}
                className="model-button model-button-delete"
              >
                Delete
              </button>
            </div>
         
        </Modal.Body>
      </Modal> */}
    </>
  )
}

const ExpenseAdd = ({ i, totall, status }) => {

  // ***********Model Code Api Start ***********

  const [initiated, setInitiated] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true)
    getExpenses(formdate)
    getSpecificExpenses()
    getInitiation(i)

  };


  // **********Table Visibility Api Start ***********


  const [showAdd, setShowAdd] = useState(false);
  const handleClosee = () => setShowAdd(false);
  const handleShoww = () => setShowAdd(true);






  const today = new Date();
  const year = today.getFullYear();
  const month = today.getMonth() + 1;
  const day = today.getDate();

  const formatttedDatee = `${day.toString().padStart(2, "0")}`;

  const formattedDate = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;



  const [monthh, setMonthh] = useState(`${month}`);


  const formatedmonth = `${monthh.toString().padStart(2, '0')}`
  const [yearr, setYearr] = useState(`${year}`);


  const getAllDatessOfMonth = (month, year) => {
    const numberOfDaysInMonth = new Date(year, month, 0).getDate();
    const datees = [];

    for (let i = 1; i <= numberOfDaysInMonth; i++) {
      const date = new Date(year, month - 1, i);
      const day = date.getDate();
      const formatttedDatee = `${day.toString().padStart(2, "0")}`;
      datees.push({ date: formatttedDatee });
    }

    return datees;
  };

  const datees = getAllDatessOfMonth(monthh, yearr); // get all dates for March 2023

  const [selecteddate, setSelecteddate] = useState(`${formatttedDatee}`)


  const handleselectvalue = (date) => {
    // console.log("date")
    // console.log(date)

    setSelecteddate(date)

    // console.log("selecteddate")
    // console.log(selecteddate)

    const formatdate = `${yearr}-${monthh}-${date}`

    // console.log("formatdate")
    // console.log(formatdate)

    getExpenses(formatdate)

    // console.log("expensenames")
    // console.log(expensenames)

  }


  const formdate = `${yearr}-${monthh}-${selecteddate}`



  // ! **********Expense Detail by Id Get Api Start ***********


  const [updatedlist, setUpdatedList] = useState([])


  const [sum, setSum] = useState("")

  const getSpecificExpenses = async () => {
    try {
      const res = await axios.get(`${BASE_URL}/wfm/expensedetailsbyexpense/${i.id}/`);


      const summ = res.data.reduce((acc, item) => acc + parseFloat(item.item_amount), 0);
      setSum(summ)
      const uniqueDatesSet = new Set();
      res.data.forEach(item => {
        uniqueDatesSet.add(item.date);
      });

      // console.log(uniqueDatesSet)
      setUpdatedList(Array.from(uniqueDatesSet));


    } catch (err) {
    }
  };



  // ! **********Expense Detail by Date Get Api Start ***********



  const [expensenames, setExpensenames] = useState([])
  const getExpenses = async (formdate) => {
    try {

      const res = await axios.get(`${BASE_URL}/wfm/expensedetailsbydate/${formdate}/${i.id}/`);
      setExpensenames(res.data)
    } catch (err) {
    }
  };

  const getInitiation = async (i) => {
    try {
      if (i.rh_leave_status === "approved") {
        setInitiated(true);
      }

    } catch (err) {
    }
  };






  // ! **********Expense Detail Add Api Start ***********

  const [formData, setFormData] = useState({
    item_name: "",
    expense_type: "",
    item_amount: "",
    bill: "",
  });



  //******************Valedation Start********************* */

  const [errors, setErrors] = useState({});
  const [inputState, setInputState] = useState({});

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = ["item_name", "item_amount", "expense_type"];
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = ` ${field.charAt(0).toUpperCase() + field.slice(1)
          } is required !`;
      }
    });

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputState({
      ...inputState,
      [name]: value.trim() ? "green" : "",
    });

    setFormData({
      ...formData,
      [name]: value,
    });
  };


  const handleFileChange = (e) => {
    const { name } = e.target;
    const file = e.target.files[0];


    setFormData({
      ...formData,
      [name]: file,
    });
  };


  //******************Valedation End*********************** */


  const handleExpenseSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      const formDataa = new FormData();
      formDataa.append("item_name", formData.item_name);
      formDataa.append("item_amount", formData.item_amount);
      formDataa.append("expense_type", formData.expense_type);
      formDataa.append("date", `${yearr}-${monthh}-${selecteddate}`);
      formDataa.append("bill", formData.bill);
      formDataa.append("expense", i.id)

      try {
        let res = await axios.post(`${BASE_URL}/wfm/expensedetailsadd/`, formDataa, {
          headers: {
            "Content-Type": "multipart/form-data",
          }
        });

        if (res.status === 200) {
          await getExpenses(formdate);
          await getSpecificExpenses();
          await handleClosee()
          // setShow(false);
        } else {
          alert(res);
        }
      } catch (err) {
        alert(err);
      }
    }
  };




  // console.log(updatedlist)




  return (
    <>
      <button className="models-button model-add" onClick={handleShow}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <g clip-path="url(#clip0_1415_3177)">
            <path
              d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM17 13H13V17H11V13H7V11H11V7H13V11H17V13Z"
              fill="#2576BC"
            />
          </g>
          <defs>
            <clipPath id="clip0_1415_3177">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </button>

      <Modal show={show} onHide={handleClose} dialogClassName="half-modal">
        {/* <Modal.Header closeButton>
          <Modal.Title>Request Leave</Modal.Title>
        </Modal.Header> */}
        <Modal.Body>
          <div className="parent-div">
            <div className="bdy-div">
              <h6 className="announce-heading">Expense Request</h6>
              <div className="flex-row" style={{ justifyContent: "center" }}>
                <div>
                  <select className="attendance-input-field date-field" type="text"
                    value={monthh}
                    onChange={(e) => setMonthh(e.target.value)}
                  >
                    {/* <option value="">All</option> */}
                    <option value="1">January</option>
                    <option value="2">February</option>
                    <option value="3">March</option>
                    <option value="4">April</option>
                    <option value="5">May</option>
                    <option value="6">June</option>
                    <option value="7">July</option>
                    <option value="8">August</option>
                    <option value="9">September</option>
                    <option value="10">October</option>
                    <option value="11">November</option>
                    <option value="12">December</option>





                  </select></div><div><input type="number"
                    maxLength={4} value={yearr}
                    onChange={(e) => setYearr(e.target.value)} className="attendance-input-field date-field" />
                </div>
              </div>

              <div className="expense-circle-outer">
                <div className="expense-circle-outer">
                  {datees.map((data) => {

                    const date = `${yearr}-${formatedmonth}-${data.date}`
                    const selectedformatdate = `${yearr}-${formatedmonth}-${selecteddate}`
                    // console.log(date)
                    // console.log(formattedDate + "==")
                    // console.log(data.date)
                    // console.log(selecteddate)
                    // console.log(data.date === selecteddate)
                    // console.log("formdate")
                    // console.log(formdate)
                    return (
                      <button className={`expsense-circle ${date === formattedDate ? 'expsense-circle-today' : date === selectedformatdate ? 'expsense-circle-selected' : updatedlist?.includes(date) ? 'expsense-circle-used' : 'expsense-circle-general'}`}
                        key={data.date}

                        onClick={() => handleselectvalue(data.date)} >
                        {data.date}
                      </button>
                    )
                  })}
                </div>
                <hr className="line" />
                <div className="amount-status">
                  <div>
                    <h6 className="utilize">
                      Utilize Amount
                    </h6 >
                    <p className="utilize-amount" style={{ color: 'red' }}>{sum}</p>
                  </div>

                  <div>
                    <h6 className="remaining">
                      Remaining Amount
                    </h6>
                    <p className="remaining-amount" >{Number(totall) - Number(sum)}</p>
                  </div>
                </div>
                <hr className="line" />




                <table className="custom-table">
                  <thead>
                    <tr className="custom-table-head-tr">
                      <th className="align-left">S. No.</th>
                      <th className="align-center">Item</th>
                      <th className="align-center">Type</th>
                      <th className="align-center">Amount</th>
                      <th className="align-center">Attach Bill</th>
                      <th className="align-right">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {expensenames.map((i, index) => (
                      <React.Fragment key={index}>
                        <tr className="tr-border-bottom">
                          <td colSpan="6"></td>
                        </tr>
                        <tr className="custom-table-head-td">

                          <td className="align-left">{index + 1}</td>
                          <td className="align-center">{i.item_name}</td>
                          <td className="align-center form-text-trasformation-uppercase">{i.expense_type ? i.expense_type : "-"}</td>
                          <td className="align-center">{i.item_amount}</td>
                          <td className="align-center">{i.bill ? (<a href={i.bill} target="blank" >View</a>) : "Bill Not Attached"}</td>
                          <td className="align-right ">

                            {initiated ? -
                              "Process Initiated" :
                              <ExpenseDetailDelete id={i} getExpenses={getExpenses} date={formdate} getSpecificExpenses={getSpecificExpenses} />
                              // : "Process Initiated"
                            }
                          </td>
                        </tr>
                      </React.Fragment>
                    ))}
                  </tbody>
                </table>

              </div>
              {/*               
                  {showAdd === true ? (<>


                    <div className="expense-req">
                      <div className="flex-column">
                        <label htmlFor="item_name" className="form-labels announce-date">
                          Item
                        </label>
                        <input
                          id="item_name"
                          type="text"
                          name="item_name"
                          placeholder="Item"
                          onChange={handleInputChange}
                          value={formData.item_name}
                          className={`form-inputs ${errors.item_name ? "error" : inputState.item_name ? "success" : ""
                            }`}
                        />
                        {errors.item_name && (
                          <span className="error-message">{errors.item_name}</span>
                        )}
                      </div>
                      <div className="flex-column">
                        <label htmlFor="item_amount" className="form-labels announce-date">
                          Amount
                        </label>
                        <input
                          id="item_amount"
                          type="number"
                          name="item_amount"
                          placeholder="Amount"
                          onChange={handleInputChange}
                          value={formData.title}
                          className={`form-inputs ${errors.item_amount ? "error" : inputState.item_amount ? "success" : ""
                            }`}
                        />
                        {errors.item_amount && (
                          <span className="error-message">{errors.item_amount}</span>
                        )}
                      </div>
                      <div className="flex-column">
                        <label htmlFor="bill" className="announce-date form-inputss">
                          Attach Bill
                        </label>
                        <label htmlFor="file-upload" className={` form-inputss custom-file-upload ${errors.title ? "error" : inputState.title ? "success" : ""}`}>
                          <svg className="pdf-input" xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 20 20" fill="none">
                            <path d="M17.8666 9.2081L10.2082 16.8664C9.27005 17.8046 7.99757 18.3317 6.67075 18.3317C5.34393 18.3317 4.07145 17.8046 3.13325 16.8664C2.19505 15.9282 1.66797 14.6558 1.66797 13.3289C1.66797 12.0021 2.19505 10.7296 3.13325 9.79144L10.7916 2.1331C11.4171 1.50763 12.2654 1.15625 13.1499 1.15625C14.0345 1.15625 14.8828 1.50763 15.5082 2.1331C16.1337 2.75857 16.4851 3.60689 16.4851 4.49144C16.4851 5.37598 16.1337 6.2243 15.5082 6.84977L7.84158 14.5081C7.52885 14.8208 7.10469 14.9965 6.66242 14.9965C6.22014 14.9965 5.79598 14.8208 5.48325 14.5081C5.17051 14.1954 4.99482 13.7712 4.99482 13.3289C4.99482 12.8867 5.17051 12.4625 5.48325 12.1498L12.5582 5.0831" stroke="#707070" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                          </svg>
                        </label>
                        <input
                          id="file-upload"
                          type="file"
                          name="bill"
                          onChange={handleFileChange}
                          style={{ display: 'none', position: "relative" }}
                        />
                        {errors.title && (
                          <span className="error-message">{errors.title}</span>
                        )}
                      </div>
                      <div className="flex-column">
                        <label htmlFor="bill" className=" announce-date  form-inputss">

                        </label>
                        <div className="flex-row action-div">

                          <button className="" onClick={handleClosee}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#707070" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M15 9L9 15" stroke="#707070" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M9 9L15 15" stroke="#707070" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                          </svg>
                          </button>
                          <button className="form-inputs-submit" onClick={handleExpenseSubmit}
                          >Submit</button>
                        </div>
                      </div>



                    </div>
                  </>) : (

                    <div >
                      <button
                        onClick={handleShoww}
                        className="jd-heading-main-right-button"
                        style={{ float: "right" }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <g clipPath="url(#clip0_450_2151)">
                            <path
                              d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM17 13H13V17H11V13H7V11H11V7H13V11H17V13Z"
                              fill="#2576BC"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_450_2151">
                              <rect width="24" height="24" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </button><br />
                    </div>

                  )
                  } */}

              {initiated ? (
                ""
              ) : (
                showAdd === true ? (
                  <div className="expense-req">
                    <div className="flex-column">
                      <label htmlFor="item_name" className="form-labels announce-date">
                        Item
                      </label>
                      <input
                        id="item_name"
                        type="text"
                        name="item_name"
                        placeholder="Item"
                        onChange={handleInputChange}
                        value={formData.item_name}
                        className={`form-inputs ${errors.item_name ? "error" : inputState.item_name ? "success" : ""}`}
                      />
                      {errors.item_name && (
                        <span className="error-message">{errors.item_name}</span>
                      )}
                    </div>

                    <div className="flex-column">
                      <label htmlFor="expense_type" className="form-labels announce-date">
                        Type
                      </label>

                      <select
                        id="expense_type"
                        name="expense_type"
                        // className="attendance-input-field date-field"
                        // type="text"
                        value={formData.expense_type}
                        onChange={handleInputChange}
                        className={` form-inputs ${errors.expense_type ? "error" : inputState.expense_type ? "success" : ""
                          }`}
                      >
                        <option value="">Type</option>
                        <option value="transportation">Tranportation</option>
                        <option value="accomodation">Accomodation</option>
                        <option value="fooding">Food</option>
                        <option value="other">Others</option>

                      </select>
                      {/* <input
                        id="item_name"
                        type="text"
                        name="item_name"
                        placeholder="Item"
                        onChange={handleInputChange}
                        value={formData.item_name}
                        className={`form-inputs ${errors.item_name ? "error" : inputState.item_name ? "success" : ""}`}
                      /> */}
                      {errors.item_name && (
                        <span className="error-message">{errors.item_name}</span>
                      )}
                    </div>

                    <div className="flex-column">
                      <label htmlFor="item_amount" className="form-labels announce-date">
                        Amount
                      </label>
                      <input
                        id="item_amount"
                        type="number"
                        name="item_amount"
                        placeholder="Amount"
                        onChange={handleInputChange}
                        value={formData.item_amount}
                        className={`form-inputs ${errors.item_amount ? "error" : inputState.item_amount ? "success" : ""}`}
                      />
                      {errors.item_amount && (
                        <span className="error-message">{errors.item_amount}</span>
                      )}
                    </div>

                    <div className="flex-column">
                      <label htmlFor="bill" className="announce-date form-inputss">
                        Attach Bill
                      </label>
                      <label htmlFor="file-upload" className={`form-inputss custom-file-upload ${errors.title ? "error" : inputState.title ? "success" : ""}`}>
                        <svg className="pdf-input" xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 20 20" fill="none">
                          <path d="M17.8666 9.2081L10.2082 16.8664C9.27005 17.8046 7.99757 18.3317 6.67075 18.3317C5.34393 18.3317 4.07145 17.8046 3.13325 16.8664C2.19505 15.9282 1.66797 14.6558 1.66797 13.3289C1.66797 12.0021 2.19505 10.7296 3.13325 9.79144L10.7916 2.1331C11.4171 1.50763 12.2654 1.15625 13.1499 1.15625C14.0345 1.15625 14.8828 1.50763 15.5082 2.1331C16.1337 2.75857 16.4851 3.60689 16.4851 4.49144C16.4851 5.37598 16.1337 6.2243 15.5082 6.84977L7.84158 14.5081C7.52885 14.8208 7.10469 14.9965 6.66242 14.9965C6.22014 14.9965 5.79598 14.8208 5.48325 14.5081C5.17051 14.1954 4.99482 13.7712 4.99482 13.3289C4.99482 12.8867 5.17051 12.4625 5.48325 12.1498L12.5582 5.0831" stroke="#707070" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                      </label>
                      <input
                        id="file-upload"
                        type="file"
                        name="bill"
                        onChange={handleFileChange}
                        style={{ display: 'none', position: "relative" }}
                      />
                      {errors.title && (
                        <span className="error-message">{errors.title}</span>
                      )}
                    </div>

                    <div className="flex-column">
                      <label htmlFor="bill" className="announce-date form-inputss">

                      </label>
                      <div className="flex-row action-div">
                        <button className="" onClick={handleClosee}>
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#707070" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M15 9L9 15" stroke="#707070" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M9 9L15 15" stroke="#707070" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                          </svg>
                        </button>
                        <button className="form-inputs-submit" onClick={handleExpenseSubmit}>
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div>
                    <button
                      title="Add Expense Details"
                      onClick={handleShoww}
                      className="jd-heading-main-right-button"
                      style={{ float: "right" }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <g clipPath="url(#clip0_450_2151)">
                          <path
                            d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM17 13H13V17H11V13H7V11H11V7H13V11H17V13Z"
                            fill="#2576BC"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_450_2151">
                            <rect width="24" height="24" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </button>
                    <br />
                  </div>
                )
              )}


              {/* {selecteddate} */}
              <div className="button-models">
                <button className="model-button" onClick={handleClose}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ExpenseAdd;
